import React, { Component } from 'react';
import { Col, FormGroup, Label } from "reactstrap";
import { FormHelperText, TextField } from "@material-ui/core";
import ListBoxComponent from './list-box.component';
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { getProperties } from "../../../crud/announcements.crud";
import { Button } from 'reactstrap';
import { getAllPlaces } from '../../../crud/propositions.crud';

export class Basis extends Component {

	state = {
		timeout: null,
		list_announcements: [],
		list_announcements_total: 0,
		term_announcement: "",
		user_id: null
	};

	findAnnouncement = async (term, skip) => {
		let validateIfId = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

		let params = {
			$skip: skip,
		}

		if (this.state.user_id) {
			params.user_id = this.state.user_id
		}

		if (validateIfId.test(term)) {
			params.id = term;
		} else {
			params.address = { city_ilike: term }
		}

		const { data } = await getProperties(params);

		return data;
	};

	findAnnouncementLoadMore = () => {
		const skip = this.state.list_announcements.length;

		if (skip >= this.state.list_announcements_total) return;

		this.findAnnouncement(this.state.term_announcement, skip).then(({ data, total }) => {
			this.setState({ list_announcements: [...this.state.list_announcements, ...data], list_announcements_total: total, });
		});
	};

	onFindAnnouncementsChange = (term) => {
		if (this.timeout) clearTimeout(this.timeout);

		this.timeout = setTimeout(() => {
			this.findAnnouncement(term).then(({ data, total }) => {
				this.setState({ list_announcements: data, term_announcement: term, list_announcements_total: total, });
			});
		}, 500);
	};

	checkUserId = (userId) => {
		if (userId && userId !== this.state.user_id) {
			this.setState({ user_id: userId });
			this.onFindAnnouncementsChange();
		}
	};

	componentDidUpdate = () => {
		const { userId } = this.props;

		this.checkUserId(userId);
	};

}

export class AnnouncementComponent extends Basis {

	render() {
		const { onBlur, onChange, value, name, label, error, onError, disabled, userId } = this.props;

		const filterOptions = createFilterOptions({
			stringify: (option) => `${option?.id} ${option?.address?.city} ${option?.address?.street}`
		});

		return (
			<React.Fragment>
				<Col md={12}>
					<FormGroup className="w-100">
						<Label className="col-form-label" style={{ color: "#343A40" }}>{label}</Label>

						<Autocomplete disabled={disabled} name={name} className="mb-2" style={{ background: "#FFF", height: "40px" }} disableClearable noOptionsText="No results." variant="outlined" onChange={onChange}
							onBlur={onBlur} options={this.state.list_announcements} defaultValue={value ?? undefined} value={value} clearOnBlur={false} getOptionSelected={(option, value) => option.id === value.id}
							getOptionLabel={(option) => option?.address ? `${option?.address?.city} - ${option?.address?.street}` : ""} onInputChange={(event, term) => this.onFindAnnouncementsChange(term)}
							ListboxComponent={ListBoxComponent(this.findAnnouncementLoadMore.bind(this, userId))} filterOptions={filterOptions}
							renderInput={(params) => (<TextField {...params} type="text" placeholder="Cidade..." variant="outlined" />)}
						/>

						{error ? <FormHelperText className="ml-3" error>{onError}</FormHelperText> : null}
					</FormGroup>
				</Col>
			</React.Fragment>
		);
	}
}

export class AnnouncementComponentNegotiation extends Basis {

	render() {
		const { onBlur, onChange, value, name, error, onError, disabled, userId } = this.props;

		const filterOptions = createFilterOptions({
			stringify: (option) => `${option?.id} ${option?.address?.city} ${option?.address?.street}`
		});

		return (
			<div className="w-100">
				<Autocomplete disabled={disabled} name={name} style={{ background: "#FFF", height: "40px" }} disableClearable noOptionsText="Sem resultados." variant="outlined" onChange={onChange}
					onBlur={onBlur} options={this.state.list_announcements} defaultValue={value ?? undefined} value={value} clearOnBlur={false} getOptionSelected={(option, value) => option.id === value.id}
					getOptionLabel={(option) => option?.address ? `${option?.address?.city} - ${option?.address?.street}` : ""} onInputChange={(event, term) => this.onFindAnnouncementsChange(term)}
					ListboxComponent={ListBoxComponent(this.findAnnouncementLoadMore.bind(this, userId))} filterOptions={filterOptions}
					renderInput={(params) => (<TextField {...params} type="text" placeholder="Busque por endereço ou ID" variant="outlined" />)}
				/>

				{error ? <FormHelperText className="ml-3" error>{onError}</FormHelperText> : null}
			</div>
		);
	}
}

export class AnnouncementFilterComponent extends Component {

	state = {
		timeout: null,
		list_announcements: [],
		term_announcement: ""
	};

	componentDidMount = () => {
		getAllPlaces().then(({ data }) => {
			const result = data.data;
			let announcementsArray = [];

			function findDuplicateCity(arr, val) {
				const result = arr.findIndex(item => val.toUpperCase() === item.toUpperCase());

				if (result !== -1) {
					return true;
				}

				return false;
			}

			result.map((announcement) => {
				if (announcement && !findDuplicateCity(announcementsArray, announcement)) {
					announcementsArray.push(announcement);
				}
			});

			this.setState({ list_announcements: announcementsArray });
		});
	};

	render() {
		const { onChange, value, onClick, label, layout } = this.props;

		return (
			<React.Fragment>
				{layout === 'row' ? (
					<Autocomplete style={{ width: 400, height: 36.23, marginLeft: 12 }} freeSolo disableClearable noOptionsText="No results." variant="outlined" clearOnBlur={false} onChange={onChange} getOptionSelected={(option, value) => option === value}
						options={this.state.list_announcements} defaultValue={value ?? undefined} value={value} renderInput={(params) => (
							<TextField {...params} className="form-control" style={{ width: 400, height: 36.23 }} type="text" placeholder="Pesquisar por cidade" variant="outlined" />
						)} />
				) : null}

				{!layout ? (
					<Col md={4}>
						<Label className="col-form-label" style={{ color: "#343A40" }}>{label}</Label>

						<FormGroup row>
							<Col>
								<Autocomplete freeSolo disableClearable noOptionsText="No results." variant="outlined" clearOnBlur={false} onChange={onChange} getOptionSelected={(option, value) => option === value}
									options={this.state.list_announcements} defaultValue={value ?? undefined} value={value} renderInput={(params) => (
										<TextField {...params} type="text" placeholder="Cidade..." variant="outlined" />
									)} />
							</Col>

							<Button onClick={onClick} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
								<i className="ri-close-circle-line align-middle"></i>
							</Button>
						</FormGroup>
					</Col>
				) : null}

			</React.Fragment>
		);
	}
}