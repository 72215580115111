/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { Container, Card, Col, Label, Input } from "reactstrap";
import BetterCircularProgress from '../../components/BetterCircularProgress';
import { Link, Redirect } from "react-router-dom";
import Breadcrumbs from '../../components/Breadcrumb';
import { Formik } from "formik";
import { validateContractTermination } from '../../helpers/FormValidators';
import { TextField } from "@material-ui/core";
import BetterAlerts from '../../components/BetterAlerts';
import { cancelTermination, confirmTermination, get, getSimulation } from '../../crud/termination.crud';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCheck, faCalendarXmark, faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { faArrowRightFromBracket, faCalendarCircleMinus, faFileExclamation, faFileSlash, faMoneyBillTransfer, faShieldExclamation, faUser } from '@fortawesome/pro-solid-svg-icons';
import { get as getContract } from '../../crud/contracts.crud';
import { formatBRL } from '../../helpers/Utils';
import moment from 'moment';

class ContractsConfirmTermination extends Component {

	state = {
		loading: false,
		errors: [],
		numeroParcelas: [
			{ id: 1, label: '01 parcela' },
			{ id: 2, label: '02 parcela' },
			{ id: 3, label: '03 parcela' },
			{ id: 4, label: '04 parcela' },
			{ id: 5, label: '05 parcela' },
			{ id: 6, label: '06 parcela' },
			{ id: 7, label: '07 parcela' },
			{ id: 8, label: '08 parcela' },
			{ id: 9, label: '09 parcela' },
			{ id: 10, label: '10 parcela' },
			{ id: 11, label: '11 parcelas' },
			{ id: 12, label: '12 parcelas' },
			{ id: 13, label: '13 parcelas' },
			{ id: 14, label: '14 parcelas' },
			{ id: 15, label: '15 parcelas' },
			{ id: 16, label: '16 parcelas' },
			{ id: 17, label: '17 parcelas' },
			{ id: 18, label: '18 parcelas' },
			{ id: 19, label: '19 parcelas' },
			{ id: 20, label: '20 parcelas' },
			{ id: 21, label: '21 parcelas' },
			{ id: 22, label: '22 parcelas' },
			{ id: 23, label: '23 parcelas' },
			{ id: 24, label: '24 parcelas' }
		],
		data: null,
		simulation: null
	}

	componentDidMount = () => {
		this.setState({ loading: true });

		get(this.props.match.params.id).then(async res => {
			if (res?.status == 200) {
				this.setState({ termination: res.data });

				if (res.data?.overdue_payments?.value > 0) {
					this.setState({ numeroParcelas: this.state.numeroParcelas.filter(objeto => objeto.id !== 1) })
				}

				const termination = res.data;

				getContract(termination.contract_id).catch(err => {
					alert('An error occurred, please contact the support.');
				}).then(async res => {
					if (res?.status == 200) {
						if (res.data) {
							let renovation = false;

							if (res.data.data?.entity?.details?.renovation_info) {
								renovation = true;
							}

							this.setState({ data: res.data.data, renovation });
						}
					}

					this.setState({ loading: false });
				});
			}
		}).catch(({ response }) => {
			if (response?.data?.message) {
				this.setState({ errors: [...this.state.errors, response?.data?.message] });
			} else {
				this.setState({ errors: [...this.state.errors, 'houve um erro, entre em contato com o suporte'] });
			}
		}).finally(() => {
			this.setState({ loading: false })
		});
	};

	onSubmit = (values) => {
		this.setState({ loading: true });

		values.type = this.state.termination.type;
		values.contract_id = this.state.termination.contract_id;

		if (values.judicial_choise === 'confirm') {
			values.cancel_details = null;

			confirmTermination(this.props.match.params.id, values).then(async res => {
				if (res?.status == 200) {
					this.props.history.goBack();
				}
			}).catch(({ response }) => {
				if (response?.data?.message) {
					this.setState({ errors: [...this.state.errors, response?.data?.message] });
					this.setState({ loading: false });
				} else {
					this.setState({ errors: [...this.state.errors, 'houve um erro, entre em contato com o suporte'] });
					this.setState({ loading: false });
				}
			}).finally(() => {
				this.setState({ loading: false })
			});
		} else if (values.judicial_choise === 'cancel') {
			cancelTermination(this.props.match.params.id, { cancel_details: values.cancel_details }).catch(err => {
				this.setState({ errors: ['Não foi possivel concluir a rescisão de contrato.'] });
				this.setState({ loading: false });
			}).then(res => {
				this.setState({ success: ['A rescisão de contrato foi cancelada.'] });
				this.setState({ loading: false });
				this.props.history.goBack();
			});
		}
	};

	getInitialValues = () => {
		return {
			type: '',
			exit_date: '',
			number_of_installments: this.state?.termination?.overdue_payments?.value > 0 ? 2 : 1,
			confirm_termination: false,
			cancel_details: null
		};
	};

	disabledConditions = values => {
		if (values.judicial_choise === 'cancel' && values.confirm_termination) {
			return false;
		}

		if (values.exit_date == '' || values.number_of_installments == '' || values.confirm_termination == false) {
			return true;
		} else {
			return false;
		}
	};

	getContractStatus = () => {
		if (this.state?.data?.entity) {
			const contract = this.state.data?.entity;

			if (!contract.is_terminated && contract.scheduled_termination_date === null) {
				return '?typeNegociacao=vigentes'
			}

			if (contract.is_terminated) {
				return '?typeNegociacao=encerrados'
			}

			if (contract.scheduled_termination_date !== null) {
				return '?typeNegociacao=encerrando'
			}

			return ''
		}
	}

	getBillingsGuaranteedValue = () => {
		// This calc is made in backend now
		// const percentage = Number(this.state.data?.entity?.plan?.percentage);

		const billingsValue = this.state.simulation?.retroactive_billings_guaranteed?.value;

		// if (this.state?.data?.entity?.coupon) {
		// 	const couponValue = Number(this.state?.data?.entity?.coupon?.discount_amount);

		// 	return Math.abs(billingsValue - (billingsValue * ((percentage - couponValue) / 100)));
		// }

		return Math.abs(billingsValue);
	}

	handleGetSimulation = async (end_date) => {
		let form = {};
		const id = this.state?.termination?.contract_id;

		if (end_date) {
			form.end_date = end_date;
			form.type = 'EXTRAJUDICIAL'
			form.termination_requested_by = this.state?.termination?.termination_requested_by
		}

		if (moment(end_date).isBefore(moment())) {
			form.retroactive = true;
		}

		await getSimulation(id, form).then(({ data }) => {
			this.setState({ simulation: data });
		}).catch(({ response }) => {
		}).finally(() => {

		});
	}

	calcValueTotal = () => {
		let valueToShow = null;

		valueToShow = this.state?.simulation?.termination_total -
			(this.state?.simulation?.termination_fine.value - this.state.termination?.termination_fine.value) -
			(this.state?.simulation?.contract_violation_fine.value - this.state.termination?.contract_violation_fine.value)

		return formatBRL(valueToShow);
	}

	render() {
		const RadioButton = ({ handleChange, handleBlur, values, field, name, text, style }) => {
			return (
				<Col md={12} style={{ marginLeft: "10px", ...style }}>
					<Input id={field} type="radio" name={name} value={field}
						onChange={handleChange} onBlur={handleBlur} color="primary"
						checked={values[name] === field}
					/>
					<Label style={{ padding: "3px", marginLeft: 2 }} for={field}>
						{text}
					</Label>
				</Col>
			)
		}

		const SimulacaoValues = ({ }) => {
			return (
				<div>
					<h2 className='p-termination-optional-title' style={{ padding: '8px 0px' }}>SIMULAÇÃO DE SAÍDA DO IMÓVEL</h2>

					<p className={'p-termination-optional'} style={{ marginTop: 24 }}>
						Esta simulação apresenta um quadro geral da situação atual do contrato, o valor ainda pode <a>sofrer alterações</a>.
						Mais funcionalidades serão liberadas ao confirmar a rescisão do contrato.
					</p>


					<div className={'containerAssets'}>
						{this.state?.simulation ? (
							<>
								{this.state.simulation?.termination_fine?.months == 0 ? null :
									<Asset titleAsset={'Aluguéis previstos'} textAsset={`${this.state.simulation?.termination_fine?.months} ${this.state.simulation?.termination_fine?.months > 1 ? 'meses' : 'mês'}`} icon={'fa fa-key'} />
								}

								{this.state.termination?.termination_fine?.value == 0 ? null :
									<Asset titleAsset={'Multa rescisória'} textAsset={`${this.state.termination?.termination_fine?.months * 30} dias: +${formatBRL(this.state.termination?.termination_fine?.value ?? 0)}`} icon={'fa fa-file-invoice-dollar'} />
								}

								{this.state.termination?.contract_violation_fine?.value == 0 ? null :
									<Asset titleAsset={'Multa quebra contratual'} textAsset={`3 aluguéis: +${formatBRL(this.state.termination?.contract_violation_fine?.value ?? 0)}`} newIcon={faFileSlash} />
								}

								{this.state.simulation?.rent_enjoyed?.value == 0 ? null :
									<Asset titleAsset={'Aluguel usufruído'} textAsset={`${this.state.simulation?.rent_enjoyed?.days_enjoyed} dias: + ${formatBRL(this.state.simulation?.rent_enjoyed?.value ?? 0)}`} icon={'fa fa-house-user'} />
								}

								{this.state.simulation?.repayments?.value == 0 ? null :
									<Asset titleAsset={'Reembolsos'} textAsset={`${this.state.simulation?.repayments?.type == 'addition' ? '+' : '-'} ${formatBRL(Math.abs(this.state.simulation?.repayments?.value ?? 0))}`} icon={'fa fa-hand-holding-usd'} />
								}

								{this.state.simulation?.current_rent?.value == 0 ? null :
									<Asset titleAsset={'Mês vigente'} textAsset={`${this.state.simulation?.current_rent?.days_enjoyed} dias: -${formatBRL(this.state.simulation?.current_rent?.value ?? 0)}`} icon={'fa-solid fa-calendar-circle-minus'} newIcon={faCalendarCircleMinus} />
								}

								{this.state.simulation?.retroactive_billings_rent?.value == 0 ? null :
									<Asset titleAsset={'Devolução de aluguel'} textAsset={`${this.state.simulation?.retroactive_billings_rent?.billings.length > 1 ? `${this.state.simulation?.retroactive_billings_rent?.billings.length} meses` : `${this.state.simulation?.retroactive_billings_rent?.billings.length} mês`}: - ${formatBRL(Math.abs(this.state.simulation?.retroactive_billings_rent?.value ?? 0))}`} newIcon={faMoneyBillTransfer} />
								}

								{this.state.simulation?.retroactive_billings_guaranteed?.value == 0 ? null :
									<Asset titleAsset={'Devolução de garantia'} textAsset={`${this.state.simulation?.retroactive_billings_guaranteed?.billings.length > 1 ? `${this.state.simulation?.retroactive_billings_guaranteed?.billings.length} meses` : `${this.state.simulation?.retroactive_billings_guaranteed?.billings.length} mês`}: ${formatBRL(this.getBillingsGuaranteedValue())}`} newIcon={faShieldExclamation} />
								}

								{this.state.simulation?.overdue_payments?.value == 0 ? null :
									<Asset titleAsset={'Atrasos devidos a Mell.ro'} textAsset={`${this.state.simulation?.overdue_payments?.months} ${this.state.simulation?.overdue_payments?.months > 1 ? 'meses' : 'mês'}: +${formatBRL(this.state.simulation?.overdue_payments?.value ?? 0)} `} icon={'fa fa-file-invoice-dollar'} newIcon={faFileExclamation} message={'Vamos reter os valores já garantidos das parcelas que o inquilino pagar.'} style={{ color: '#8590A5' }} />
								}

								<Asset titleAsset={'Vistoria'} textAsset={'Ainda será definido'} icon={'fa fa-check'} />
							</>
						) : null}
					</div>

					<hr className='termination-divisor' />

					<div style={{ marginTop: 16 }}>
						<p className='p-termination-total-label'>Valor total</p>
						<h2 className='p-termination-total'>{this.state?.simulation ? this.calcValueTotal() : 'Calculando...'}</h2>
					</div>

					{this.state.simulation?.retroactive_billings_guaranteed?.value > 0 || this.state.simulation?.retroactive_billings_rent?.value > 0 ?
						(
							<div style={{ marginTop: 30 }}>
								<p className='p-termination-total-label'>Valor total da devolução</p>
								<div className='d-flex align-items-center'>
									<h2 className='p-termination-total' style={{ color: '#FCB92C' }}>{formatBRL(this.state.simulation?.retroactive_billings_rent?.value + this.getBillingsGuaranteedValue())}</h2>
									<p className='p-termination-payer'>Responsável paga</p>
								</div>
							</div>
						) : null}
				</div>
			)
		}

		const Asset = ({ titleAsset, textAsset, icon, colorBackground, colorIcon, message, children, style, newIcon }) => {
			return (
				<div className={'assetPersonComissao'}>
					<div style={{ backgroundColor: colorBackground ?? '#E1E3F7' }} className={'containerFigure'}>
						{newIcon ? (
							<FontAwesomeIcon icon={newIcon} style={{ color: colorIcon ?? '#5664D2', fontSize: '13px' }} />
						) : <i className={icon} style={{ color: colorIcon ?? '#5664D2' }} />}

					</div>

					<div style={children ? { display: 'flex', alignItems: 'center' } : {}}>
						{titleAsset ? <h6>{titleAsset}</h6> : null}
						<h5>{textAsset ? textAsset : children}</h5>

						{message ? <p className={'info'} style={{ marginTop: 8, ...style }}>
							{message}
						</p> : null}
					</div>
				</div>
			)
		}

		return (
			<React.Fragment>
				<BetterCircularProgress loading={this.state.loading}>
					<div className="page-content container-page rescisao">
						<Container fluid>


							<div className='wrapper-100'>
								<div className='termination-breadcrumb' style={{ width: '92%', marginBottom: '10px' }}>
									<Label className="col-form-label" style={{ color: '#505D69', fontSize: '19px', fontFamily: 'Inter' }}>Confirmar rescisão</Label>

									<Breadcrumbs style={{ fontSize: '13px' }} breadcrumbItems={[{ link: '/negotiation/index', title: 'Negociações' }, { link: `/negotiation/index`, title: 'Negociações e contratos' }, { link: `/contracts/show/${this.state?.termination?.contract_id}`, title: `ID ${this.state?.termination?.id.substring(0, 4)}...` }, { link: `/contracts/termination/${this.state.data?.entity?.id}`, title: 'Confirmar rescisão' }]} />
								</div>
							</div>

							<BetterAlerts errors={this.state.errors} />

							{this.state.success ?
								<Redirect
									to={{
										pathname: `/negotiation/show/${this.state?.data?.entity?.proposition_id}${this.getContractStatus()}`,
										state: { success: ['Rescisão cancelada com sucesso!'] },
									}}
								/>
								: null
							}

							<Formik initialValues={this.getInitialValues()} validate={values => { }} onSubmit={(values) => this.onSubmit(values)}>
								{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
									<form noValidate={true} autoComplete="off" onSubmit={handleSubmit} style={{ display: 'flex', justifyContent: 'center', gap: '32px' }}>
										<div style={{ width: '60%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
											<Card className='card-termination'>
												<div >
													<p className='p-termination-1' style={{ padding: '8px 0px' }}>CONFIRMAÇÃO</p>

													<p className='p-termination-optional'>
														Só continue com a rescisão deste contrato caso nosso setor jurídico tenha entrado em contato confirmando e com a data de saída.
													</p>

													<div style={{ marginTop: "20px" }}>
														<Label className="col-form-label">Confirmar com o jurídico</Label>

														<div style={{ display: 'flex', width: '170px' }}>
															<RadioButton handleChange={handleChange} handleBlur={handleBlur} values={values} field='confirm' name='judicial_choise' text='Confirmar rescisão' style={{ color: '#1CBB8C' }} />

															<RadioButton handleChange={(e) => { handleChange(e); setFieldValue('cancel_details', '') }} style={{ color: '#FF3D60' }} handleBlur={handleBlur} values={values} field='cancel' name='judicial_choise' text='Cancelar rescisão' />
														</div>
													</div>
												</div>
											</Card>

											{values.judicial_choise === 'confirm' ? (
												<Card className='card-termination'>
													<p className='p-termination-1' style={{ padding: '8px 0px' }}>DETALHES DA RESCISÃO</p>
													<div style={{ marginTop: "20px" }}>
														<p className="p-termination-input-label">Defina a data de rescisão:</p>
														<TextField className="mb-2" style={{ background: "#FFF", height: "40px" }} name={'exit_date'} placeholder="00/00/0000"
															type="date" variant="outlined" value={values.exit_date} InputLabelProps={{ shrink: true }}
															onChange={async (e) => { await handleChange(e); this.handleGetSimulation(e.target.value) }} onBlur={handleBlur} />
														<p className="p-termination-optional" style={{ marginTop: '10px' }}>
															Decidida no processo judicial. Será usada para fazer o cálculo final de rescisão.
														</p>
													</div>

													<div style={{ marginTop: "20px" }}>
														<p className="p-termination-input-label">Defina a data da sentença/saída e entrega das chaves</p>
														<TextField className="mb-2" style={{ background: "#FFF", height: "40px" }} name={'sentence_date'} placeholder="00/00/0000"
															type="date" variant="outlined" value={values.sentence_date} InputLabelProps={{ shrink: true }}
															onChange={handleChange} onBlur={handleBlur} />

														<p className="p-termination-optional" style={{ marginTop: '10px' }}>
															Decidida no processo judicial. Será usada para definir o prazo da vistoria de saída.
														</p>
													</div>

													<div style={{ marginTop: "20px" }}>
														<p className="p-termination-input-label">Número de parcelas</p>
														<select style={{ background: "#FFF", height: "38px" }} name='number_of_installments'
															onChange={handleChange} handleBlur={handleBlur} value={values.number_of_installments} className="custom-select">
															{this.state.numeroParcelas.map((parcela, index) =>
																<option key={parcela.id} value={parcela.id}>{parcela.label}</option>
															)}
														</select>

														<p className="p-termination-optional" style={{ marginTop: '10px' }}>
															Primeira parcela terá seu vencimento para 10 dias após a data de saída e entrega da chaves
														</p>
													</div>
												</Card>
											) : values.judicial_choise === 'cancel' ? <Card className='card-termination'>
												<p className='p-termination-1' style={{ padding: '8px 0px' }}>DETALHES DA RESCISÃO</p>


												<div style={{ marginTop: "20px" }}>
													<p className="p-termination-input-label">Motivo de cancelamento da rescisão</p>
													<TextField className="mb-2" style={{ background: "#FFF", height: "40px" }} name='cancel_details' placeholder="Descreva o motivo"
														type="text" variant="outlined" value={values.cancel_details} onChange={(e) => { handleChange(e) }} onBlur={handleBlur} id="cancel_field" />

													<p className="p-termination-optional" style={{ marginTop: '10px' }}>
														O motivo do cancelamento será enviado por e-mail para todas as partes do contrato.
													</p>
												</div>


											</Card> : null}

											<Card className='card-termination' style={{ padding: '0px' }}>
												<div style={{ padding: '16px 24px 16px 24px' }}>

													<Col md={12} style={{ marginLeft: "10px" }}>
														<Input id='true' type="radio" name='confirm_termination' value='true'
															onChange={handleChange} onBlur={handleBlur} color="primary"
															checked={values.confirm_termination == 'true'}
														/>
														{values.judicial_choise === 'cancel' ?
															<Label style={{ padding: "3px", marginLeft: 2, marginBottom: 0 }} for='confirm_termination'>
																Confirmo que esta ação é <Label style={{ color: '#FF3D60', marginBottom: 0 }}>irreversível</Label>.
															</Label>
															:
															<Label style={{ padding: "3px", marginLeft: 2, marginBottom: 0 }} for='confirm_termination'>
																Confirmo em criar esse acordo e sei que esta ação é <Label style={{ color: '#FF3D60', marginBottom: 0 }}>irreversível</Label>.
															</Label>
														}
													</Col>
												</div>
											</Card>

											<Card className='card-termination' style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', padding: '16px' }}>

												<>
													<button className="go-back-1" type='button' onClick={() => window.location.href = `/contracts/show/${this.state?.termination?.contract_id}`}>
														<FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: '15px' }} />VOLTAR
													</button>

													<button disabled={this.disabledConditions(values)} className="continue-btn-1" style={{ backgroundColor: this.disabledConditions(values) ? '#DADBDD' : '', border: this.disabledConditions(values) ? '#DADBDD' : '0px', marginLeft: '12px' }} type='submit'>
														{values.judicial_choise === 'cancel' ? 'CONFIRMAR CANCELAMENTO' : 'CONFIRMAR RESCISÃO'}
														<FontAwesomeIcon icon={faCheck} style={{ fontSize: '15px' }} />
													</button>
												</>

											</Card>
										</div>

										<div style={{ width: '30%' }}>
											<Card className='card-termination' style={{ padding: '0px' }}>
												<div className='termination-right-card'>
													<p className='p-termination-1' style={{ padding: '8px 0px' }}>RESUMO</p>

													<div className='termination-right-wrapper'>
														<div className='termination-right-icon' style={{ background: '#EEE' }}>
															<FontAwesomeIcon icon={faCalendarXmark} />
														</div>
														<div className='termination-right-box'>
															<p className='p-termination-input-label'>{'Data de rescisão'}</p>
															<p className='p-termination-input-value' style={{ fontWeight: '600' }}>
																{values.exit_date ? moment(values.exit_date).format('DD/MM/YYYY') : '--'}
															</p>
														</div>
													</div>

													<div className='termination-right-wrapper'>
														<div className='termination-right-icon'>
															<FontAwesomeIcon icon={faCalendar} />
														</div>
														<div className='termination-right-box'>
															<p className='p-termination-input-label'>Data de requisição</p>
															<p className='p-termination-input-value' style={{ fontWeight: '600' }}>{moment(this.state?.termination?.created_at).format('DD/MM/YYYY HH:mm:ss')}</p>
														</div>
													</div>

													<div className='termination-right-wrapper'>
														<div className='termination-right-icon' style={{ background: values.termination_requested_by === '' ? '#EEE' : '' }}>
															<FontAwesomeIcon icon={faUser} style={{ color: values.termination_requested_by === '' ? '#fff' : '' }} />
														</div>
														<div className='termination-right-box'>
															<p className='p-termination-input-label'>Quem fez a requisição</p>
															<p className='p-termination-input-value' style={{ fontWeight: '600' }}>

																{this.state?.termination?.termination_requested_by === 'landlord' ? 'Responsável' : ''}
																{this.state?.termination?.termination_requested_by === 'tenant' ? 'Inquilino' : ''}
																{this.state?.termination?.termination_requested_by === '' ? '--' : ''}
															</p>
														</div>
													</div>

													<div className='termination-right-wrapper'>
														<div className='termination-right-icon' style={{ background: '#EEE' }}>
															<FontAwesomeIcon icon={faArrowRightFromBracket} />
														</div>
														<div className='termination-right-box'>
															<p className='p-termination-input-label'>Motivo da saída</p>
															<p className='p-termination-input-value' style={{ fontWeight: '600', maxWidth: '215px' }}>
																<>
																	Descumprimento de contrato
																	<small className='p-termination-1 '>
																		<>
																			<br />
																			{this.state?.termination?.details}
																		</>
																	</small>
																</>
															</p>
														</div>
													</div>

													{values.exit_date ? (
														<>
															<hr className='termination-divisor' />
															<div>

																<SimulacaoValues />
															</div>
														</>
													) : null}
												</div>
											</Card>
										</div>
									</form>
								)}
							</Formik>
						</Container>
					</div>
				</BetterCircularProgress>
			</React.Fragment>
		)
	}
}

export default ContractsConfirmTermination;