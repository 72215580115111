import axios from "axios";
import { Constants } from '../helpers/Constants';
import { parseQuery } from '../helpers/Utils';

export function query(params) {
	return axios.get(`${Constants.baseUrl}/terminations`, { params: parseQuery(params) });
}

export function get(id, params) {
	return axios.get(`${Constants.baseUrl}/terminations/${id}`, { params: parseQuery(params) });
}

export function getTemplate(contract_id, params) {
	return axios.get(`${Constants.baseUrl}/terminations/term-template/${contract_id}`, { params: parseQuery(params) });
}

export function getSimulation(contract_id, params) {
	return axios.get(`${Constants.baseUrl}/terminations/simulation/${contract_id}`, { params: parseQuery(params) });
}

export function createTermination(body) {
	return axios.post(`${Constants.baseUrl}/terminations`, body);
}


export function confirmTermination(id, body) {
	return axios.post(`${Constants.baseUrl}/terminations/confirm-termination/${id}`, body);
}

export function removeTermination(termination_id) {
	return axios.delete(`${Constants.baseUrl}/terminations/${termination_id}`);
}

export function cancelTermination(termination_id, body) {
	return axios.post(`${Constants.baseUrl}/terminations/cancel/${termination_id}`, body);
}

export function addFileTermination(contract_id, body) {
	return axios.post(`${Constants.baseUrl}/terminations/files/${contract_id}`, body);
}

export const getTerminationUsers = (params) => {
	return axios.get(`${Constants.baseUrl}/terminations/termination-users`, { params: parseQuery(params) });
};

export function getTerminationFile(terminationId, sign = false) {
	const options = {
		method: 'get',
		baseURL: `${Constants.baseUrl}/terminations/file/${terminationId}`,
	};

	if (sign) {
		options.baseURL = `${options.baseURL}?type=sign`
	}

	const interceptor = {
		response: (request) => {
			return request;
		},
		error: (error) => {
			if (error.response.status === 400) {
				return error.response.request.responseURL;
			}
			throw error;
		}
	}

	const id = axios.interceptors.response.use(
		interceptor.request,
		interceptor.error
	);

	return axios.request(options).finally(() => {
		axios.interceptors.response.eject(id);
	});
}

export function getClosureTermFile(terminationId) {
	const options = {
		method: 'get',
		baseURL: `${Constants.baseUrl}/terminations/closure-term-file/${terminationId}`,
	};

	const interceptor = {
		response: (request) => {
			return request;
		},
		error: (error) => {
			if (error.response.status === 400) {
				return error.response.request.responseURL;
			}
			throw error;
		}
	}

	const id = axios.interceptors.response.use(
		interceptor.request,
		interceptor.error
	);

	return axios.request(options).finally(() => {
		axios.interceptors.response.eject(id);
	});
}