import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatBRL, formatCNPJ, formatCPF } from "../../helpers/Utils";
import { Link } from "react-router-dom";
import moment from "moment";
import smile from '../../assets/images/icons-custom/face-smile.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faRedo } from "@fortawesome/pro-regular-svg-icons";

export default class DataTableReturnBillings extends Component {
	constructor() {
		super();

		this.state = {
			property: {},
			data: null,
			rows: 10,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: 'id',
			sortOrder: 1,
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters !== this.props.filters) {
			this.fetchData();
		}
	}

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = async (event) => {
		this.setState({
			loading: true,

		});
		if (event.data) {
			event.data = null;
		}

		this.setState({
			...this.state,
			rows: event.rows,
		});
		this.fetchData(event.rows, event.first);
	};

	fetchData = async (limit, skip) => {
		this.setState({ loading: true });

		let options = {
			$limit: limit ? limit : this.state.rows,
			$skip: skip != null ? skip : this.state.first,
			$sort: { [this.state.sortField]: this.state.sortOrder },
		};

		if (this.props.filters.due_date_gte) options.due_date_gte = this.props.filters.due_date_gte;
		if (this.props.filters.due_date_lte) options.due_date_lte = this.props.filters.due_date_lte;

		if (this.props.filters.shipment_batch) options.shipment_batch = this.props.filters.shipment_batch;

		if (this.props.filters?.searchAll) {
			const cpfRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
			const cnpjRegex = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;

			if (cpfRegex.test(this.props.filters?.searchAll)) {
				const cleanedCPF = this.props.filters?.searchAll.replace(/[.\-]/g, "");

				options.searchAll = cleanedCPF
			} else if (cnpjRegex.test(this.props.filters?.searchAll)) {
				const cleanedCNPJ = this.props.filters?.searchAll.replace(/[.\-/]/g, "");

				options.searchAll = cleanedCNPJ
			} else {
				options.searchAll = this.props.filters?.searchAll;
			}
		}

		return this.props.fetchEvent(options).then(async (res) => {
			// this.props.updateCounter(res?.data?.total);

			if (res.data.data.length == 0) {
				this.setState({
					...this.state,
					data: [],
					totalRecords: res.data.total,
					first: skip,
					last: skip + res.data.limit,
					loading: false
				});
			}
			this.setState({
				...this.state,
				data: res.data.data,
				totalRecords: res.data.total,
				first: skip,
				last: skip + res.data.limit,
				loading: false
			});

			if (this.props.tabActive === '1') {
				this.props.setState({
					totalApproved: res.data.total,
				})
			} else {
				this.props.setState({
					totalRejected: res.data.total,
				})
			}
		}).finally(() => {
			this.setState({ loading: false });
		});
	};

	sortTable = async (e) => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder, loading: true }, () => {
			let shipmentoDataTable = {
				rows: this.state.rows,
				totalRecords: this.state.totalRecords,
				first: this.state.first,
				last: this.state.last,
				loading: this.state.loading,
				sortField: this.state.sortField,
				sortOrder: this.state.sortOrder,
				data: this.state.data
			};
			localStorage.setItem("shipmentoDataTable", JSON.stringify(shipmentoDataTable));

			this.fetchData();
		});
	};

	getValue = (obj) => {
		return (
			<div style={{ textAlign: "center", width: '105px' }}>
				<p>{formatBRL(obj || 0)}</p>
			</div>
		);
	};

	getDateFormatted = (date) => {
		return (
			<p>
				{date ? moment(date).add(1, 'day').format("DD/MM/YYYY") : '-'}
			</p>
		);
	};

	getResponsable = (obj) => {
		let responsavel_id = '';
		let cpfCnpj = '';

		if (obj?.tenant_infos) {
			responsavel_id = obj?.tenant_id;
			cpfCnpj = obj?.tenant_infos?.cpfCnpj;
		} else {
			responsavel_id = obj?.landlord_id;
			cpfCnpj = obj?.landlord_infos?.cpfCnpj;
		}

		if (cpfCnpj.length === 11) {
			cpfCnpj = formatCPF(cpfCnpj);
		} else {
			cpfCnpj = formatCNPJ(cpfCnpj);
		}

		return (
			<div>
				<Link to={`/users/show/${responsavel_id}`} style={{ color: "#4AA3FF" }}>{cpfCnpj}</Link>
			</div>
		)
	}
	getContractFriendlyName = (obj) => {
		return (
			<div>
				<Link to={`/contracts/show/${obj?.contract_id}`} style={{ color: "#4AA3FF" }}>{obj?.contract_friendly_code}</Link>
			</div>
		)
	}

	export() {
		this.dt.exportCSV();
	}

	getOptions = (obj) => {
		return (
			<div className="d-flex justify-content-center p-2 align-items-center">
				<div>
					<Link to={`/billing/show/${obj.billing_id}`} style={{ width: 34 }}>
						<FontAwesomeIcon icon={faChevronRight} style={{ color: '#5664D2', fontSize: 14 }} />
					</Link>
				</div>
			</div>
		)
	}

	emptyMessageReturn() {
		return (
			<div className="boxImage">
				<img src={smile} alt="smile" width={48} height={48} />

				<p className="subtitleNoItems">Nenhum registro encontrado</p>
			</div>
		)
	}

	render() {
		return (
			<div style={{ position: 'relative' }}>
				<DataTable ref={(el) => this.dt = el} value={this.state.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={[10, 15, 30]} first={this.state.first} last={this.state.last}
					totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} responsive={true} loadingIcon={`fas fa-sync fa-spin`} onSort={(e) => this.sortTable(e)}
					paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" emptyMessage={this.emptyMessageReturn}
					currentPageReportTemplate="" sortField={this.state.sortField} sortOrder={this.state.sortOrder} pageLinkSize={3} id="custom-table-2">

					<Column field="shipment_batch" sortable body={(obj) => obj?.shipment_batch} header="Sequencial da Remessa" style={{ width: '100px', padding: '22px 16px 22px 16px' }} />

					<Column field="contract" body={(obj) => this.getContractFriendlyName(obj)} header="Contrato" style={{ width: '100px', padding: '22px 16px 22px 16px' }} />
					<Column field="document_id" body={(obj) => obj.document_id} header="Nº do Documento" style={{ width: '100px', padding: '22px 16px 22px 16px' }} />
					<Column sortable field="responsable_id" body={(obj) => this.getResponsable(obj)} style={{ width: '170px', padding: '22px 16px 22px 16px' }} header="Sacado" />

					<Column sortable field="due_date" body={(obj) => this.getDateFormatted(obj?.due_date)} style={{ width: '104px', padding: '22px 16px 22px 16px' }} header="Vencimento" />

					<Column field="securitized_face" sortable body={(obj) => formatBRL(obj.securitized_face)} header="Valor face" style={{ width: '320px', textAlign: "flex-start" }} />

					<Column header="" body={(obj) => this.getOptions(obj)} style={{ width: '34px', padding: 0 }} />
				</DataTable>

				<div className="paginator-new-items">
					<p>Registros por página</p>
					{/* <Link className="custom-see-all-table" to={`/pagamento/cobrancas/index`} >
						Ver todos
					</Link> */}
				</div>
			</div>
		);
	}
}
