import React, { Component } from 'react';
import { Col, FormGroup, Label } from "reactstrap";
import { FormHelperText, Select } from "@material-ui/core";

const list_duration = [
	{ key: "3", label: '3 Meses' },
	{ key: "6", label: '6 Meses' },
	{ key: "12", label: '12 Meses' },
	{ key: "18", label: '18 Meses' },
	{ key: "24", label: '24 Meses' },
	{ key: "30", label: '30 Meses' },
	{ key: "36", label: '36 Meses' }
];

export class ListDuration extends Component {
	render() {
		const { onBlur, onChange, value, name, error, onError } = this.props;

		return (
			<Col md={12}>
				<FormGroup className="w-100">
					<Label style={{ color: '#343A40' }}>Tempo de contrato</Label>
					<Select style={{ background: "#FFF", height: "40px", cursor: 'pointer' }} name={name} onChange={onChange} onBlur={onBlur} value={value} className="custom-select">
						<option key={null} value={null} hidden>Selecione uma duração</option>
						{list_duration.map(({ key, label }) => <option style={{ cursor: 'pointer' }} key={key} value={key}>{label}</option>)}
					</Select>

					{error ? <FormHelperText error>{onError}</FormHelperText> : null}
				</FormGroup>
			</Col>
		);
	}
}

