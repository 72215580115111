import React, { Component } from 'react';
import { Container, Card, CardBody, Col, Alert, Row } from "reactstrap";
import Breadcrumbs from '../../components/Breadcrumb';
import BetterCircularProgress from '../../components/BetterCircularProgress';
import { Redirect } from "react-router-dom";
import DataTableLaudoHistorico from '../../components/datatables/DataTableLaudoHistorico';

class NegotiationLaudoHistorico extends Component {

	state = {
		loading: false,
		data: null,
		insurance: null,
		errors: false,
		crudUrl: '/negotiation',
		contract: false,
		iptu: '',
		condo: '',
		rent: '',
		start_date: '',
		message: '',
		parties: [],
		contract_id: '',
		contract_link: '',
		plans: [],
		flags: {
			show_button_subscribe_landlord: false,
			show_button_subscribe_tenant: false,
			contract_created: false,
			contract_signed_landlord: false,
			contract_signed_tenant: false,
		},
		submittingLaudo: false,
		laudo: null,
	};

	render() {
		return (
			<React.Fragment>
				<BetterCircularProgress loading={this.state.loading}>
					<div className="page-content container-page">
						<Container fluid>
							<Breadcrumbs title="" breadcrumbItems={[{ link: '/negotiation/index', title: 'Negotiation' }, { link: `/negotiation/edit/${this.props.match.params.id}`, title: 'Visualizar' }, { link: '', title: 'Histórico' }]} />
							{this.state.errors && !this.state.message ?
								<Redirect to="/negotiation/index" />
								: null
							}

							{this.state.success && !this.state.message ?
								<Redirect to={{ pathname: '/negotiation/index', state: { success: ['Proposition deleted successfuly!'] } }} />
								: null
							}

							{this.state.success && this.state.message ? <Alert color="success">{this.state.message}</Alert> : null}
							{this.state.errors && this.state.message ? <Alert color="danger">{this.state.message}</Alert> : null}

							<Row className="container-center-input">
								<Col lg={12}>
									<Card>
										<CardBody>
											<DataTableLaudoHistorico propositionId={this.props.match.params.id} />
										</CardBody>
									</Card>
								</Col>
							</Row>
						</Container>
					</div>
				</BetterCircularProgress>
			</React.Fragment>
		);
	}
}

export default NegotiationLaudoHistorico;