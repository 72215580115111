import React, { Component } from "react";
import { Container, Card, CardBody } from "reactstrap";
import { getDashboard, getDashboardBaseLayout, getDashboardTypes, getMetrics, updateDashboard } from '../../crud/dashboard.crud';
import RGL, { WidthProvider } from "react-grid-layout";
import { FormControl, ListSubheader, MenuItem, Select } from "@material-ui/core";
import _ from 'lodash';
import Swal from 'sweetalert2';
import BetterCircularProgress from '../../components/BetterCircularProgress';
import { formatBRL } from '../../helpers/Utils';
import { faChevronDown, faPuzzle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from "moment";

import TradingIndicatorSection from "../../components/dashboard/sections/TradingIndicatorSection";
import ConversionIndicatorSection from "../../components/dashboard/sections/ConversionIndicatorSection";
import CommissionIndicatorSection from "../../components/dashboard/sections/CommissionIndicatorSection";
import UserIndicatorSection from "../../components/dashboard/sections/UserIndicatorSection";
import AnnouncementIndicatorSection from "../../components/dashboard/sections/AnnouncementIndicatorSection";
import ContractIndicatorSection from "../../components/dashboard/sections/ContractIndicatorSection";
import FinancialTransactionsIndicatorSection from "../../components/dashboard/sections/FinancialTransactionIndicatorSection";
import BadDebtIndicatorSection from "../../components/dashboard/sections/BadDebtIndicatorSection";
import PaymentIndicatorSection from "../../components/dashboard/sections/PaymentIndicatorSection";
import PaymentFailureIndicatorSection from "../../components/dashboard/sections/PaymentFailureIndicatorSection";
import DueDateDistIndicatorSection from "../../components/dashboard/sections/DueDateDistIndicatorSection";
import LegalChargeIndicatorSection from "../../components/dashboard/sections/LegalChargeIndicatorSection";
import AgreementIndicatorSection from "../../components/dashboard/sections/AgreementIndicatorSection";
import BadDebtTableIndicatorSection from "../../components/dashboard/sections/BadDebtTableIndicator";
import WebhookErrorIndicatorSection from "../../components/dashboard/sections/WebhookErrorIndicatorSection";
import ExtrajudicialIndicatorSection from "../../components/dashboard/sections/ExtrajudicialIndicatorSection";
import PropertyPerLandlordIndicatorSection from "../../components/dashboard/sections/PropertyPerLandlordIndicatorSection";
import ContractToBeJudializedSection from "../../components/dashboard/sections/ContractsToBeJudicializedSection";

const ReactGridLayout = WidthProvider(RGL);

class Dashboard extends Component {

	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			showModalWidgets: false,
			widgets: [],
			layout: [],
			baseLayout: [],
			metrics: null,
			seeMoreExtrajudicial: true,
			tradingIndicatorsFilters: {
				"user_type": "apenas_usuarios",
				"time_frame": "ultimos_12_meses"
			},
			tradingIndicatorsAvailableLabels: [
				{ label: "NEGOCIAÇÕES COM CHAT ATIVO", isActive: true },
				{ label: "NEG. COM APROVAÇÃO FINANCEIRA SISTÊMICA", isActive: true },
				{ label: "NEG. COM APROVAÇÃO FINANCEIRA HUMANIZADA", isActive: true },
				{ label: "NEGOCIAÇÃO COM REPROVAÇÃO FINANCEIRA", isActive: true }
			],
			usersIndicatorsAvailableLabels: [
				{ label: "Não verificados", isActive: true },
				{ label: "Verificados", isActive: true },
				{ label: "Em contrato", isActive: true },
				{ label: "Total", isActive: true },
				{ label: "Inativos", isActive: true }
			],
			announcementsIndicatorsAvailableLabels: [
				{ label: "EM CONTRATO ATIVO", isActive: true },
				{ label: "PUBLICADOS E ATIVOS", isActive: true },
				{ label: "PUBLICADOS PELO RASTREADOR DE IMÓVEIS", isActive: true },
				{ label: "REGIÕES RASTREADAS", isActive: true },
				{ label: "IMÓVEIS INATIVOS", isActive: true }
			],
			dropdownKey: null,
			anchorEl: null,
			selectedWidget: '',
		};
	};

	removeWidget = (key) => {
		this.setState({ ...this.state, layout: this.state.layout.filter(item => item['i'] !== key) });
	};

	addWidget = (key) => {
		let layout = _.cloneDeep(this.state.layout);
		layout.push(this.state.baseLayout.find(item => item.i === key));
		this.setState({ ...this.state, layout: layout });
	};

	checkIfWidgetIsActive = (key) => {
		return this.state.layout?.some(item => item.i === key);
	};

	onLayoutChange = (layout) => {
		this.setState({ ...this.state, layout });
		updateDashboard(layout);
	};

	componentDidMount = () => {
		getMetrics().catch(err => {
			return Swal.fire('Ops', 'Ocorreu um erro ao buscar as métricas.', 'error');
		}).then(res => {
			if (res?.status === 200) {
				this.setState({ metrics: res.data });

				getDashboardTypes().catch(err => {
					return Swal.fire('Ops', 'Ocorreu um erro ao buscar os tipos da dashboard.', 'error');
				}).then(res => {
					if (res?.status === 200) {
						this.setState({ widgets: res.data });

						getDashboardBaseLayout().catch(err => {
							return Swal.fire('Ops', 'Ocorreu um erro ao buscar o layout base da dashboard.', 'error');
						}).then(res => {
							if (res?.status === 200) {
								this.setState({ baseLayout: res.data });

								getDashboard().catch(err => {
									return Swal.fire('Ops', 'Ocorreu um erro ao buscar a dashboard.', 'error');
								}).then(res => {
									if (res?.status === 200) {
										this.setState({ layout: res.data.widgets, loading: false });
									}
								});
							}
						});
					}
				});
			}
		});
	};

	getMonthlyEntries = () => {
		const total = (this?.state?.agreementInfos?.feesWonThisMonth || 0) + (this?.state?.extrajudicialInfos?.feesWonThisMonth || 0);

		return formatBRL(total).replace('R$', '')
	}

	getTotalPendingValue = () => {
		const total = (this?.state?.agreementInfos?.totalPendingValue || 0) + (this?.state?.extrajudicialInfos?.totalPendingValue || 0);

		return formatBRL(total).replace('R$', '')
	}

	toggleMaximize = (itemName) => {
		this.setState(prevState => ({ layout: prevState.layout.map(item => item.i === itemName ? { ...item, w: item.w === 6 ? 12 : 6 } : item) }));
	};

	handleAddWidget = () => {
		const selectedWidget = this.state.selectedWidget;
		if (!selectedWidget) return;

		const count = this.state.layout.filter(obj => obj.i.startsWith(selectedWidget)).length;
		const newI = `${selectedWidget}_${count + 1}`;

		let oldItem = this.state.layout.find(obj => obj.i.startsWith(selectedWidget))

		if (!oldItem) {
			oldItem = this.state.baseLayout.find(obj => obj.i.startsWith(selectedWidget))
		}

		const oldW = oldItem.w;
		const oldH = oldItem.h;

		const newItem = {
			w: oldW,
			h: oldH,
			x: 0,
			y: 0,
			i: newI,
			moved: false,
			static: false,
			isResizable: false
		};

		this.setState((prevState) => {
			const updatedLayout = prevState.layout.map(item => {
				if (item.x === 0) {
					return { ...item, x: 6 };
				}

				if (item.x === 6) {
					return { ...item, y: item.y + newItem.h, x: 0 };
				}

				return item;
			});

			return { layout: [newItem, ...updatedLayout], selectedWidget: '' };
		});
	};

	changeWidgetValue = (key) => {
		this.setState((prevState) => {
			const updatedLayout = prevState.layout.filter(item => item.i !== key);
			return { layout: updatedLayout };
		});
	};

	handleSelectChange = (event) => {
		this.setState({ selectedWidget: event.target.value });
	};

	getLastUpdateTime() {
		const now = moment();

		const startOfDay = moment().startOf('day');

		const duration = moment.duration(now.diff(startOfDay));

		const hours = Math.floor(duration.asHours());
		const minutes = duration.minutes();

		return `Última atualização à ${hours}h ${minutes}m`;
	}

	render() {
		return (
			<React.Fragment>
				<BetterCircularProgress loading={this.state.loading}>
					<div id="dashboard" className="page-content">
						<Container fluid>
							<div className="header-geral">
								<div>
									<h1>Dashboard geral</h1>

									<div className="last-update-box">
										<p>{this.getLastUpdateTime()}</p>
									</div>
								</div>

								<div>
									<h3>Dashboard geral</h3>
								</div>
							</div>

							<div className="row">
								<div className="col-sm-12">
									<Card style={{ margin: '16px 10px' }}>
										<CardBody>
											<div className="widget-select" style={{ display: 'flex', alignItems: 'center' }}>
												<FormControl variant="outlined" style={{ minWidth: 200, marginRight: '8px', width: '100%' }}>
													<Select labelId="select-widget-label" value={this.state.selectedWidget} displayEmpty
														onChange={this.handleSelectChange} IconComponent={() => <FontAwesomeIcon icon={faChevronDown} />}
														variant="outlined" className="custom-select" MenuProps={{ PaperProps: { className: 'custom-menu-item' } }}>
														<MenuItem value="">
															<em style={{ color: '#8590A5', fontFamily: 'Inter', fontStyle: 'normal' }}>Selecione um indicador para adicionar ao dashboard</em>
														</MenuItem>

														<ListSubheader>Aquisição</ListSubheader>

														{this.state.widgets.slice(0, 4).map((obj, index) => (
															Object.keys(obj).map((key) => (
																<MenuItem key={index} value={key} className="custom-menu-item">
																	{obj[key]}
																	<span className="counter-green" style={{}}>{this.state.layout.filter(obj => obj.i.startsWith(key))?.length}</span>
																</MenuItem>
															))
														))}

														<ListSubheader>Registro</ListSubheader>

														{this.state.widgets.slice(4, 6).map((obj, index) => (
															Object.keys(obj).map((key) => (
																<MenuItem key={index} value={key} className="custom-menu-item">
																	{obj[key]}
																	<span className="counter-green" style={{}}>{this.state.layout.filter(obj => obj.i.startsWith(key))?.length}</span>
																</MenuItem>
															))
														))}

														<ListSubheader>Negociações</ListSubheader>

														{this.state.widgets.slice(6, 9).map((obj, index) => (
															Object.keys(obj).map((key) => (
																<MenuItem key={index} value={key} className="custom-menu-item">
																	{obj[key]}
																	<span className="counter-green" style={{}}>{this.state.layout.filter(obj => obj.i.startsWith(key))?.length}</span>
																</MenuItem>
															))
														))}

														<ListSubheader>Financeiro</ListSubheader>

														{this.state.widgets.slice(9, 13).map((obj, index) => (
															Object.keys(obj).map((key) => (
																<MenuItem key={index} value={key} className="custom-menu-item">
																	{obj[key]}
																	<span className="counter-green" style={{}}>{this.state.layout.filter(obj => obj.i.startsWith(key))?.length}</span>
																</MenuItem>
															))
														))}

														<ListSubheader>Jurídico</ListSubheader>

														{this.state.widgets.slice(13, 16).map((obj, index) => (
															Object.keys(obj).map((key) => (
																<MenuItem key={index} value={key} className="custom-menu-item">
																	{obj[key]}
																	<span className="counter-green" style={{}}>{this.state.layout.filter(obj => obj.i.startsWith(key))?.length}</span>
																</MenuItem>
															))
														))}

														<ListSubheader>Técnico</ListSubheader>

														{this.state.widgets.slice(15, 18).map((obj, index) => (
															Object.keys(obj).map((key) => (
																<MenuItem key={index} value={key} className="custom-menu-item">
																	{obj[key]}
																	<span className="counter-green" style={{}}>{this.state.layout.filter(obj => obj.i.startsWith(key))?.length}</span>
																</MenuItem>
															))
														))}
													</Select>
												</FormControl>

												<button type='button' className="buttonPrimary w-100" style={{ width: '100%', maxWidth: '127px' }} onClick={this.handleAddWidget}>
													ADICIONAR
													<FontAwesomeIcon icon={faPuzzle} />
												</button>
											</div>
										</CardBody>
									</Card>
								</div>
							</div>

							<ReactGridLayout className="layout" layout={this.state.layout} cols={12} rowHeight={30} onLayoutChange={this.onLayoutChange}>
								{this.state.layout.filter(obj => obj.i.startsWith('TRADING_INDICATORS')).map(item => (
									<div key={item.i}>
										<TradingIndicatorSection metrics={this.state.metrics?.TRADING_INDICATORS} changeWidgetValue={this.changeWidgetValue} toggleMaximize={this.toggleMaximize} itemKey={item.i} layout={item} />
									</div>
								))}

								{this.state.layout.filter(obj => obj.i.startsWith('CONVERSION_INDICATORS')).map(item => (
									<div key={item.i}>
										<ConversionIndicatorSection metrics={this.state.metrics?.CONVERSION_INDICATORS} changeWidgetValue={this.changeWidgetValue} toggleMaximize={this.toggleMaximize} itemKey={item.i} layout={item} />
									</div>
								))}

								{this.state.layout.filter(obj => obj.i.startsWith('COMMISSION_INDICATORS')).map(item => (
									<div key={item.i}>
										<CommissionIndicatorSection metrics={this.state.metrics?.AFFILIATE_INDICATORS} changeWidgetValue={this.changeWidgetValue} toggleMaximize={this.toggleMaximize} itemKey={item.i} layout={item} />
									</div>
								))}

								{this.state.layout.filter(obj => obj.i.startsWith('PROPERTY_PER_LANDLORD')).map(item => (
									<div key={item.i}>
										<PropertyPerLandlordIndicatorSection metrics={this.state.metrics?.PROPERTY_PER_LANDLORD} changeWidgetValue={this.changeWidgetValue} toggleMaximize={this.toggleMaximize} itemKey={item.i} layout={item} history={this.props.history} />
									</div>
								))}

								{this.state.layout.filter(obj => obj.i.startsWith('USER_INDICATORS')).map(item => (
									<div key={item.i}>
										<UserIndicatorSection metrics={this.state.metrics?.USER_INDICATORS} changeWidgetValue={this.changeWidgetValue} toggleMaximize={this.toggleMaximize} itemKey={item.i} layout={item} />
									</div>
								))}

								{this.state.layout.filter(obj => obj.i.startsWith('ANNOUNCEMENT_INDICATORS')).map(item => (
									<div key={item.i}>
										<AnnouncementIndicatorSection metrics={this.state.metrics?.ANNOUNCEMENT_INDICATORS} changeWidgetValue={this.changeWidgetValue} toggleMaximize={this.toggleMaximize} itemKey={item.i} layout={item} count={this.state.layout.filter(obj => obj.i.startsWith('ANNOUNCEMENT_INDICATORS'))?.length ?? 0} />
									</div>
								))}

								{this.state.layout.filter(obj => obj.i.startsWith('CONTRACT_INDICATORS')).map(item => (
									<div key={item.i}>
										<ContractIndicatorSection metrics={this.state.metrics?.CONTRACT_INDICATORS} changeWidgetValue={this.changeWidgetValue} toggleMaximize={this.toggleMaximize} itemKey={item.i} layout={item} count={this.state.layout.filter(obj => obj.i.startsWith('CONTRACT_INDICATORS'))?.length ?? 0} />
									</div>
								))}

								{this.state.layout.filter(obj => obj.i.startsWith('FINANCIAL_TRANSACTIONS_GRAPHIC')).map(item => (
									<div key={item.i}>
										<FinancialTransactionsIndicatorSection metrics={this.state.metrics?.FINANCIAL_TRANSACTIONS_GRAPHIC} changeWidgetValue={this.changeWidgetValue} toggleMaximize={this.toggleMaximize} itemKey={item.i} layout={item} />
									</div>
								))}


								{this.state.layout.filter(obj => obj.i.startsWith('BAD_DEBT_INDICATORS')).map(item => (
									<div key={item.i}>
										<BadDebtIndicatorSection metrics={this.state.metrics?.BAD_DEBT_INDICATORS} changeWidgetValue={this.changeWidgetValue} toggleMaximize={this.toggleMaximize} itemKey={item.i} layout={item} />
									</div>
								))}

								{this.state.layout.filter(obj => obj.i.startsWith('PAYMENTS_GRAPHIC')).map(item => (
									<div key={item.i}>
										<PaymentIndicatorSection metrics={this.state.metrics?.PAYMENT_GRAPHIC_METRIC} changeWidgetValue={this.changeWidgetValue} toggleMaximize={this.toggleMaximize} itemKey={item.i} layout={item} />
									</div>
								))}

								{this.state.layout.filter(obj => obj.i.startsWith('FALHA_PAGAMENTOS')).map(item => (
									<div key={item.i}>
										<PaymentFailureIndicatorSection changeWidgetValue={this.changeWidgetValue} itemKey={item.i} count={this.state.layout.filter(obj => obj.i.startsWith('FALHA_PAGAMENTOS'))?.length ?? 0} />
									</div>
								))}

								{this.state.layout.filter(obj => obj.i.startsWith('INADIMPLENTES')).map(item => (
									<div key={item.i}>
										<BadDebtTableIndicatorSection changeWidgetValue={this.changeWidgetValue} itemKey={item.i} count={this.state.layout.filter(obj => obj.i.startsWith('INADIMPLENTES'))?.length ?? 0} />
									</div>
								))}

								{this.state.layout.filter(obj => obj.i.startsWith('DUE_DATE_GRAPHIC_DIST')).map(item => (
									<div key={item.i}>
										<DueDateDistIndicatorSection metrics={this.state.metrics?.DUE_DATE_GRAPHIC_DIST} changeWidgetValue={this.changeWidgetValue} toggleMaximize={this.toggleMaximize} itemKey={item.i} layout={item} />
									</div>
								))}

								{this.state.layout.filter(obj => obj.i.startsWith('EXTRAJUDICIAL_INDICATORS')).map(item => (
									<div key={item.i}>
										<ExtrajudicialIndicatorSection metrics={[...this.state.metrics?.LEGAL_CHARGES_INDICATORS, ...this.state.metrics?.AGREEMENT_INDICATORS]} changeWidgetValue={this.changeWidgetValue} toggleMaximize={this.toggleMaximize} itemKey={item.i} layout={item} />
									</div>
								))}

								{this.state.layout.filter(obj => obj.i.startsWith('LEGAL_CHARGES_INDICATORS')).map(item => (
									<div key={item.i}>
										<LegalChargeIndicatorSection metrics={this.state.metrics?.LEGAL_CHARGES_INDICATORS} changeWidgetValue={this.changeWidgetValue} toggleMaximize={this.toggleMaximize} itemKey={item.i} layout={item} />
									</div>
								))}

								{this.state.layout.filter(obj => obj.i.startsWith('AGREEMENT_INDICATORS')).map(item => (
									<div key={item.i}>
										<AgreementIndicatorSection metrics={[...this.state.metrics?.AGREEMENT_INDICATORS, ...this.state.metrics?.LEGAL_CHARGES_INDICATORS]} changeWidgetValue={this.changeWidgetValue} toggleMaximize={this.toggleMaximize} itemKey={item.i} layout={item} />
									</div>
								))}

								{this.state.layout.filter(obj => obj.i.startsWith('ERROS_WEBHOOK')).map(item => (
									<div key={item.i}>
										<WebhookErrorIndicatorSection changeWidgetValue={this.changeWidgetValue} itemKey={item.i} count={this.state.layout.filter(obj => obj.i.startsWith('ERROS_WEBHOOK'))?.length ?? 0} />
									</div>
								))}

								{this.state.layout.filter(obj => obj.i.startsWith('CONTRACTS_TO_BE_JUDIALIZED')).map(item => (
									<div key={item.i}>
										<ContractToBeJudializedSection changeWidgetValue={this.changeWidgetValue} itemKey={item.i} count={this.state.layout.filter(obj => obj.i.startsWith('CONTRACTS_TO_BE_JUDIALIZED'))?.length ?? 0} />
									</div>
								))}
							</ReactGridLayout>
						</Container>
					</div>
				</BetterCircularProgress>
			</React.Fragment>
		);
	}
}

export default Dashboard;
