import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";
import { FiltersModuleTradingIndicators } from "../filters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical, faEyeSlash, faFileCheck, faFileCircleCheck, faFileExclamation, faGripVertical, faMaximize, faMessageSmile, faMinimize } from '@fortawesome/pro-solid-svg-icons';
import { Checkbox, ListItemText, Menu, MenuItem } from "@material-ui/core";
import { formatBRL } from "../../../helpers/Utils";

export default class TradingIndicatorSection extends Component {
	constructor() {
		super();

		this.state = {
			metrics: [],
			tradingIndicatorsFilters: {
				"user_type": "apenas_usuarios",
				"time_frame": "ultimos_12_meses"
			},
			tradingIndicatorsAvailableLabels: [
				{ label: "NEGOCIAÇÕES COM CHAT ATIVO", isActive: true },
				{ label: "NEG. COM APROVAÇÃO FINANCEIRA SISTÊMICA", isActive: true },
				{ label: "NEG. COM APROVAÇÃO FINANCEIRA HUMANIZADA", isActive: true },
				{ label: "NEGOCIAÇÃO COM REPROVAÇÃO FINANCEIRA", isActive: true }
			],
			dropdownKey: null,
			anchorEl: null,
		};
	}

	componentDidMount() {
		if (this?.props?.metrics) {
			this.setState({ metrics: this.props.metrics })
		}
	}

	render() {
		return (
			<Card>
				<CardBody className="dashboard-item">
					<div className="box-header">
						<div className="">
							<h3 className="title">Indicadores de negociação</h3>
							<div className="box-info">
								Aquisição
							</div>

							{this.props.layout?.w === 12 ? (
								<div className="box-filter" style={{ marginLeft: 16 }}>
									<FiltersModuleTradingIndicators setState={this.setState.bind(this)} state={this.state.tradingIndicatorsFilters} />
								</div>
							) : null}
						</div>

						<div>
							<FontAwesomeIcon icon={this.props.layout?.w === 6 ? faMaximize : faMinimize} style={{ color: '#5664D2', padding: '12px', cursor: 'pointer' }} onClick={() => this.props.toggleMaximize(this.props?.itemKey)} />
							<FontAwesomeIcon icon={faEllipsisVertical} style={{ color: '#5664D2', padding: '12px', cursor: 'pointer' }} onClick={(e) => {
								this.setState({ dropdownKey: this.props.itemKey });
								this.setState({ anchorEl: e.currentTarget });
							}} />
							<FontAwesomeIcon icon={faEyeSlash} style={{ color: '#5664D2', padding: '12px', cursor: 'pointer' }} onClick={() => this.props.changeWidgetValue(this.props.itemKey)} />
							<FontAwesomeIcon icon={faGripVertical} style={{ color: '#DADBDD', padding: '12px', cursor: 'pointer' }} />
						</div>
					</div>

					<Menu
						anchorEl={this.state.anchorEl}
						open={this.state.dropdownKey === this.props.itemKey ? true : false}
						onClose={() => { this.setState({ dropdownKey: null }) }}
						className="dashboard-item"
						id="dashboard-drop"
					>
						{this.state.tradingIndicatorsAvailableLabels.map((item) => (
							<MenuItem key={item.label} value={item.label}>
								<Checkbox checked={item.isActive} onChange={() => {
									this.setState(prevState => ({
										tradingIndicatorsAvailableLabels: prevState.tradingIndicatorsAvailableLabels.map(i =>
											i.label === item.label
												? { ...i, isActive: !i.isActive }
												: i
										)
									}));
								}} />
								<ListItemText primary={item.label} />
							</MenuItem>
						))}
					</Menu>


					{this.props.layout?.w === 6 ? (
						<div className="box-filter">
							<FiltersModuleTradingIndicators setState={this.setState.bind(this)} state={this.state.tradingIndicatorsFilters} />
						</div>
					) : null}

					<div style={{ position: 'relative' }}>
						<hr className="divisor" />
					</div>

					<div className="card-wrapper">
						<div className="card-box" style={{ display: this.state.tradingIndicatorsAvailableLabels.find(obj => obj?.label === 'NEGOCIAÇÕES COM CHAT ATIVO')?.isActive ? '' : 'none' }}>
							<h3>NEGOCIAÇÕES<br />
								COM CHAT ATIVO</h3>

							<h2>
								{this.state.metrics?.find(obj => obj?.filters?.includes(this.state.tradingIndicatorsFilters.user_type) && obj.filters?.includes(this.state.tradingIndicatorsFilters.time_frame))?.value?.find(obj => obj?.name === 'TRADING_INDICATORS_ACTIVE_CHAT')?.count ?? 0}
								<FontAwesomeIcon icon={faMessageSmile} />
							</h2>

							<div className="card-value">
								{formatBRL(this.state.metrics?.find(obj => obj?.filters?.includes(this.state.tradingIndicatorsFilters.user_type) && obj.filters?.includes(this.state.tradingIndicatorsFilters.time_frame))?.value?.find(obj => obj?.name === 'TRADING_INDICATORS_ACTIVE_CHAT')?.sum ?? 0)}
							</div>
						</div>
						<div className="card-box" style={{ display: this.state.tradingIndicatorsAvailableLabels.find(obj => obj?.label === 'NEG. COM APROVAÇÃO FINANCEIRA SISTÊMICA')?.isActive ? '' : 'none' }}>
							<h3>NEG. COM APROVAÇÃO<br />
								FINANCEIRA SISTÊMICA</h3>

							<h2 className="text-green">
								{this.state.metrics?.find(obj => obj?.filters?.includes(this.state.tradingIndicatorsFilters.user_type) && obj.filters?.includes(this.state.tradingIndicatorsFilters.time_frame))?.value?.find(obj => obj?.name === 'TRADING_INDICATORS_APPROVED_SYSTEM')?.count ?? 0}
								<FontAwesomeIcon icon={faFileCheck} />
							</h2>

							<div className="card-value card-value-green">
								{formatBRL(this.state.metrics?.find(obj => obj?.filters?.includes(this.state.tradingIndicatorsFilters.user_type) && obj.filters?.includes(this.state.tradingIndicatorsFilters.time_frame))?.value?.find(obj => obj?.name === 'TRADING_INDICATORS_APPROVED_SYSTEM')?.sum ?? 0)}
							</div>
						</div>
						<div className="card-box" style={{ display: this.state.tradingIndicatorsAvailableLabels.find(obj => obj?.label === 'NEG. COM APROVAÇÃO FINANCEIRA HUMANIZADA')?.isActive ? '' : 'none' }}>
							<h3>NEG. COM APROVAÇÃO<br />
								FINANCEIRA HUMANIZADA</h3>

							<h2 className="text-blue">
								{this.state.metrics?.find(obj => obj?.filters?.includes(this.state.tradingIndicatorsFilters.user_type) && obj.filters?.includes(this.state.tradingIndicatorsFilters.time_frame))?.value?.find(obj => obj?.name === 'TRADING_INDICATORS_APPROVED')?.count ?? 0}
								<FontAwesomeIcon icon={faFileCircleCheck} />
							</h2>

							<div className="card-value card-value-blue">
								{formatBRL(this.state.metrics?.find(obj => obj?.filters?.includes(this.state.tradingIndicatorsFilters.user_type) && obj.filters?.includes(this.state.tradingIndicatorsFilters.time_frame))?.value?.find(obj => obj?.name === 'TRADING_INDICATORS_APPROVED')?.sum ?? 0)}
							</div>
						</div>
						<div className="card-box" style={{ display: this.state.tradingIndicatorsAvailableLabels.find(obj => obj?.label === 'NEGOCIAÇÃO COM REPROVAÇÃO FINANCEIRA')?.isActive ? '' : 'none' }}>
							<h3>NEGOCIAÇÃO COM<br />
								REPROVAÇÃO FINANCEIRA</h3>

							<h2 className="text-red ">
								{this.state.metrics?.find(obj => obj?.filters?.includes(this.state.tradingIndicatorsFilters.user_type) && obj.filters?.includes(this.state.tradingIndicatorsFilters.time_frame))?.value?.find(obj => obj?.name === 'TRADING_INDICATORS_CREDIT_REFUSED')?.count ?? 0}
								<FontAwesomeIcon icon={faFileExclamation} />
							</h2>

							<div className="card-value card-value-red">
								{formatBRL(this.state.metrics?.find(obj => obj?.filters?.includes(this.state.tradingIndicatorsFilters.user_type) && obj.filters?.includes(this.state.tradingIndicatorsFilters.time_frame))?.value?.find(obj => obj?.name === 'TRADING_INDICATORS_CREDIT_REFUSED')?.sum ?? 0)}
							</div>
						</div>
					</div>
				</CardBody>
			</Card>
		)
	}
}


