import React, { Component } from "react";
import {
    Container,
    Card,
    CardBody,
    Row,
    Col,
    NavItem,
    TabContent,
    NavLink,
    Nav,
    TabPane
} from "reactstrap";
import DataTableTemplatesSMS from "../../components/datatables/DataTableTemplatesSMS";
import DataTableTemplatesWhatsapp from "../../components/datatables/DataTableTemplatesWhatsapp";

import { getAllTemplates as getAllTemplatesSMS } from "../../crud/templates.crud";
import { getNumber as getWhatsappNumber, getTemplates as getAllTemplatesWhatsapp } from "../../crud/whatsapp.crud";

import BetterAlerts from "../../components/BetterAlerts";
import Breadcrumbs from "../../components/Breadcrumb";
import classnames from 'classnames';
import DataTableNumberTemplate from "../../components/datatables/DataTableNumbersWhatsapp";

class TemplateIndex extends Component {
    state = {
        errors: [],
        success: [],
        data: [],
        activeTab: "1"
    };

    fetchDataSMS = () => {
        return getAllTemplatesSMS({ $limit: 100 })
            .then((res) => {
                if (res?.status === 200) {
                    return res.data;
                }
                return {};
            })
            .catch((err) => {
                alert("An error occurred, please contact the support.");
            });
    };

    fetchDataWhatsapp = () => {
        return getAllTemplatesWhatsapp()
            .then((res) => {
                if (res?.status === 200) {
                    return res.data;
                }
                return {};
            })
            .catch((err) => {
                alert("An error occurred, please contact the support.");
            });
    };

    fetchDataWhatsappNumber = () => {
        return getWhatsappNumber()
            .then((res) => {
                if (res?.status === 200) {
                    return res.data;
                }
                return {};
            })
            .catch((err) => {
                alert("An error occurred, please contact the support.");
            });
    };


    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    };
    render() {
        return (
            <React.Fragment>
                <div className="page-content container-page">
                    <Container fluid>
                        <Breadcrumbs title="Templates" breadcrumbItems={[]} />
                        <BetterAlerts errors={this.state.errors} success={this.state.success} />
                        <Card>
                            <CardBody>
                                <Nav tabs className="nav-tabs-custom" role="tablist">
                                    <NavItem>
                                        <NavLink
                                            id="SMS"
                                            className={classnames({ active: this.state.activeTab === "1" })}
                                            onClick={() => {
                                                this.toggleTab("1");
                                            }}
                                        >
                                            SMS
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            id="Whatsapp"
                                            className={classnames({ active: this.state.activeTab === "2" })}
                                            onClick={() => {
                                                this.toggleTab("2");
                                            }}
                                        >
                                            Whatsapp
                                        </NavLink>
                                    </NavItem>
                                </Nav>

                                <TabContent activeTab={this.state.activeTab} className="border border-top-0 p-4">
                                    <TabPane tabId="1" role="tabpanel">
                                        <Row className="container-center-input">
                                            <Col lg={12}>
                                                <DataTableTemplatesSMS
                                                    noDelete
                                                    fetchEvent={this.fetchDataSMS}
                                                    crudButtons={true}
                                                    crudUrl="/templates/sms"
                                                    idRow={`id`}
                                                    noShow
                                                />
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="2" role="tabpanel">
                                        <Row className="container-center-input">
                                            <Col lg={12}>
                                                <DataTableNumberTemplate
                                                    noDelete
                                                    fetchEvent={this.fetchDataWhatsappNumber}
                                                    crudButtons={true}
                                                    crudUrl="/templates/number"
                                                    idRow={`id`}
                                                    noShow
                                                />
                                            </Col>
                                        </Row>
                                        <div />
                                        <Row className="container-center-input">
                                            <Col lg={12}>
                                                <DataTableTemplatesWhatsapp
                                                    noDelete
                                                    fetchEvent={this.fetchDataWhatsapp}
                                                    crudButtons={true}
                                                    crudUrl="/templates/whatsapp"
                                                    idRow={`id`}
                                                    noShow
                                                />
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default TemplateIndex;
