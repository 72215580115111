import React, { Component } from 'react';
import { Container, Col, Row, Label, } from "reactstrap";
import Breadcrumbs from '../../components/Breadcrumb';
import BetterCircularProgress from '../../components/BetterCircularProgress';
import { Link, Redirect, Router, withRouter } from "react-router-dom";
import { get as getProposition, getPropositionUsers, getUsersWithLaudoStatus, } from '../../crud/propositions.crud';
import { get as getUser } from '../../crud/users.crud';
import { get as getContract, } from '../../crud/contracts.crud';
import { get as getInsurance } from '../../crud/insurance.crud';
import { ocorrenciasGet } from './helpers';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCheck, faChevronRight, faFileImport, faUser } from '@fortawesome/pro-regular-svg-icons';
import { TextareaAutosize, TextField } from '@material-ui/core';
import Swal from 'sweetalert2';
import { Formik } from 'formik';
import { UserFilterComponent } from './components/user.component';
import { getOccurrences, storeOccurrences, updateOccurrence } from '../../crud/occurrences.crud';

class OcorrenciaShow extends Component {
	state = {
		loading: false,
		data: null,
		contract: false,
		status: '',
		createOccurrence: false,
		totalOcorrencias: null,
		participantUsers: [],
		ocorrencia: {},
	};

	componentDidMount = async () => {
		this.setState({ loading: true });

		await this.fetchData();
	};

	fetchData = async () => {
		await getProposition(this.props.match.params.negotiationId).then(async ({ data }) => {
			getUsersWithLaudoStatus(this.props.match.params.negotiationId).then(({ data }) => {
				this.setState({ participantUsers: data });
			}).catch(({ message }) => {
				this.setState({ errors: true, message })
			});

			getOccurrences({ id: this.props.match.params.id }).then(({ data }) => {
				this.setState({ ocorrencia: data.data[0] })
			}).catch(({ message }) => {
				this.setState({ errors: true, message })
			});

			if (data.data.contract?.id) {
				await getContract(data.data.contract.id).then(({ data }) => {
					let contract = data.data.entity

				}).catch(({ message }) => {
					this.setState({ errors: true, message })
				});
			}

			// Getting proposition users
			getPropositionUsers({ proposition_id: this.props.match.params.id, $sort: { ['created_at']: 1 } }).then((res) => {
				this.setState({ propositionUsers: res.data.data });
			})

			this.setState({ data: data.data });
		}).catch(({ message }) => {
			this.setState({ errors: true, message })
		}).finally(() => {
			this.setState({ loading: false });
		});
	}

	getInitialValues = () => {
		return {
			status: this.state.ocorrencia.status ?? '',
			context: this.state.ocorrencia.context ?? '',
			reason: this.state.ocorrencia.reason ?? '',
			description: this.state.ocorrencia.description ?? '',
			type: this.state.ocorrencia.type ?? '',
			result: this.state.ocorrencia.result ?? '',
			administrator: this.state.ocorrencia.administrator ?? {}
		};
	};

	sendOccurrence = (data, resetForm) => {
		data.contract_id = this.state.data?.contract?.id ?? '';

		updateOccurrence(data, this.props.match.params.id).then((res) => {
			resetForm();
			this.fetchData();
		}).catch((err) => {
			return Swal.fire('Ops', 'Ocorreu um erro ao fazer upload da ocorrencia.', 'error');
		});
	}

	getStatus = (obj) => {
		let status = obj?.status || '';
		let backgroundColor = '#4AA3FF'

		switch (status) {
			case 'Finalizada':
				backgroundColor = '#1CBB8C';
				break;
			case 'Em andamento':
				backgroundColor = '#5664D2';
				break;
			case 'Aberta':
				backgroundColor = '#4AA3FF';
				break;
			default:
				break;
		}
		return (
			<div className="tag" style={{ textAlign: "center", backgroundColor }}>
				{status}
			</div>
		);
	}

	getType = (obj) => {
		let backgroundColorContext = '#E1E3F7'
		let colorContext = '#5664D2';

		switch (obj?.context) {
			case 'Geral':
				backgroundColorContext = '#E1E3F7';
				colorContext = '#5664D2';
				break;
			case 'Financeiro':
				backgroundColorContext = '#FEF2D9';
				colorContext = '#EFA200';
				break;
			case 'Jurídico':
				backgroundColorContext = '#FFC9D2';
				colorContext = '#852032';
				break;
			case 'Contrato':
				backgroundColorContext = '#D6F3EA';
				colorContext = '#1CBB8C';
				break;
			default:
				break;
		}

		return (
			<div className='d-flex' style={{ gap: 4 }}>
				{obj?.context && (
					<div className="tag" style={{ textAlign: "center", fontWeight: '600', backgroundColor: backgroundColorContext, color: colorContext }}>
						{obj?.context}
					</div>
				)}

				{obj?.reason && (
					<div className="tag" style={{ textAlign: "center", backgroundColor: '#DADBDD', color: '#505D69' }}>
						{obj?.reason}
					</div>
				)}

				{obj?.type && (
					<div className="tag" style={{ textAlign: "center", backgroundColor: '#DADBDD', color: '#505D69' }}>
						{obj?.type}
					</div>
				)}

				{obj?.result && (
					<div className="tag" style={{ textAlign: "center", backgroundColor: '#4AA3FF' }}>
						{obj?.result}
					</div>
				)}
			</div>
		);
	}

	getContextColors = (obj) => {
		let backgroundColorContext = '#E1E3F7'
		let colorContext = '#5664D2';

		switch (obj?.context) {
			case 'Geral':
				backgroundColorContext = '#E1E3F7';
				colorContext = '#5664D2';
				break;
			case 'Financeiro':
				backgroundColorContext = '#FEF2D9';
				colorContext = '#EFA200';
				break;
			case 'Jurídico':
				backgroundColorContext = '#FFC9D2';
				colorContext = '#852032';
				break;
			case 'Contrato':
				backgroundColorContext = '#D6F3EA';
				colorContext = '#1CBB8C';
				break;
			default:
				break;
		}

		return { backgroundColorContext, colorContext }
	}

	render() {
		const getNomeNegociacao = () => {
			let text = '';

			this.state.participantUsers.map((participante, index) => {
				let firstName = participante.name.split(' ');
				firstName = firstName[0];

				let lastName = index + 1 == this.state.participantUsers.length;

				if (text == '') {
					text = firstName;
				} else {
					if (lastName) {
						text = text + ' e ' + firstName;
					} else {
						text = text + ', ' + firstName;
					}
				}
			})

			text = text + ` em ${address?.neighbourhood ? `${address?.neighbourhood},` : ''} ${address?.city} - ${address?.state}`

			return text;
		};

		const OcorrenciaCardHistorico = ({ ocorrencia }) => {
			return (
				<Col md={12} className="box-filtros send-file form" style={{ padding: 0, backgroundColor: '#fff', margin: 0, justifyContent: 'flex-start', border: 'none', boxShadow: 'none' }}>
					<div className='formulario' style={{ flex: 1 }}>
						<div style={{ color: '#8590A5' }} className='atualVersionHeader'>
							<span>Histórico</span>

							<span>Atualizada em: <b>{moment(ocorrencia?.updated_at).format('DD/MM/YY HH:mm:ss')}</b></span>
						</div>

						<hr className='lineBox mt-0' />

						<Row className="p-0 m-0" style={{ alignItems: 'center', gap: 8 }}>
							<div style={{ maxWidth: 150 }}>
								<Label>Contexto</Label>

								<div className="tag" style={{ textAlign: "center", fontWeight: '600', backgroundColor: this.getContextColors(ocorrencia).backgroundColorContext, color: this.getContextColors(ocorrencia).colorContext }}>
									{ocorrencia.context}
								</div>
							</div>

							{ocorrencia?.reason && (
								<>
									<div className='divisionArrow' style={{ paddingTop: 20 }}>
										<FontAwesomeIcon icon={faChevronRight} />
									</div>

									<div style={{ maxWidth: 200 }}>
										<Label>Motivo</Label>

										<div className="tag" style={{ textAlign: "center", backgroundColor: '#DADBDD', color: '#505D69' }}>
											{ocorrencia.reason}
										</div>
									</div>
								</>
							)}

							{ocorrencia?.type && (
								<>
									<div className='divisionArrow' style={{ paddingTop: 20 }}>
										<FontAwesomeIcon icon={faChevronRight} />
									</div>

									<div style={{ maxWidth: 300 }}>
										<Label>Tipo</Label>

										<div className="tag" style={{ textAlign: "center", backgroundColor: '#DADBDD', color: '#505D69' }}>
											{ocorrencia.type}
										</div>
									</div>
								</>
							)}

							{ocorrencia?.result && (
								<>
									<div className='divisionArrow' style={{ paddingTop: 20 }}>
										<FontAwesomeIcon icon={faChevronRight} />
									</div>

									<div style={{ maxWidth: 300 }}>
										<Label>Resultado</Label>


										<div className="tag" style={{ textAlign: "center", backgroundColor: '#4AA3FF' }}>
											{ocorrencia.result}
										</div>
									</div>
								</>
							)}
						</Row>

						<div>
							{ocorrencia.description}
						</div>

						<hr className='lineBox' />

						<div className='d-flex justify-content-between' style={{ alignItems: 'end' }}>
							<Row className="p-0 m-0 d-flex" style={{ alignItems: 'center', gap: 8 }}>
								<div style={{ maxWidth: 200, marginRight: 15 }}>
									<Label>Status</Label>

									{this.getStatus(ocorrencia)}
								</div>

								<div style={{ maxWidth: 150 }}>
									<Label>Admin responsável</Label>

									<div>
										<Link to={`/users/show/${ocorrencia?.administrator?.id}`}>{ocorrencia?.administrator?.name}</Link>
									</div>
								</div>
							</Row>
						</div>
					</div>
				</Col>
			)
		}

		const negotiation = this?.state?.data;
		const property = negotiation?.property;
		const address = property?.address;

		return (
			<BetterCircularProgress loading={this.state.loading}>
				<div className="page-content container-page userShow userNew userIndex anuncio negociacao">
					<Container fluid>
						<div className='header'>
							<h1>Ocorrência</h1>

							<Breadcrumbs breadcrumbItems={[{ link: '/negotiation/index', title: 'Negociações e contratos' }, { link: `/negotiation/show/${this.props.match.params.negotiationId}`, title: `ID ${this.props.match.params.negotiationId.substring(0, 6)}...` }, { link: `/ocorrencias/show/${this.props.match.params.id}`, title: `Ocorrência ID ${this.props.match.params.id}` }]} />
						</div>

						<div className='boxUser'>
							<div className='headerUser'>
								{property?.images?.[0]?.medium?.url ?
									<div className='userPhoto' style={{ backgroundImage: `url(${property?.images?.[0]?.medium?.url})` }} />
									:
									<div className='userPhoto noPhotoAnnouncement' />
								}

								<div className='fotoParticipantes'>
									{this.state.participantUsers.map((participante, index) => (
										<div className="boxUserTableUsers" style={{ marginLeft: index > 0 ? `-15%` : '0%' }}>
											{participante?.profile_image?.medium?.url ?
												<div className='userPhoto' style={{ backgroundImage: `url(${participante?.profile_image?.medium?.url})` }} />
												:
												<div className='userPhotoNone' >
													<FontAwesomeIcon icon={faUser} />
												</div>
											}
										</div>
									))}
								</div>

								<div className='tagsAssets groupAlign' style={{ marginLeft: ((this.state.participantUsers.length) * 22) }}>
									<h3>{getNomeNegociacao()}</h3>

									{this.state?.ocorrencia && (
										<div className='tagGroup'>
											{this.getStatus(this.state?.ocorrencia)}
											{this.getType(this.state?.ocorrencia)}
										</div>
									)}
								</div>
							</div>

							<div className='bodyAssets' style={{ border: 'none', marginBottom: 0 }}>
								<div className='boxInformation'>
									<p>Criada em</p>
									<b>{moment(this?.state?.ocorrencia?.created_at).format('DD/MM/YY HH:mm:ss')}</b>
								</div>

								<div className='boxInformation'>
									<p>Atualizado em</p>
									<b>{moment(this.state?.ocorrencia?.updated_at).format('DD/MM/YY HH:mm:ss')}</b>
								</div>

								<div className='boxInformation'>
									<p>Admin responsável</p>
									<Link to={`/users/show/${this.state?.ocorrencia?.administrator?.id}`}>{this.state?.ocorrencia?.administrator?.name}</Link>
								</div>
							</div>
						</div>

						<div className='box'>
							<Formik initialValues={this.getInitialValues()} onSubmit={(values, { resetForm }) => this.sendOccurrence(values, resetForm)} enableReinitialize={true}>
								{({ values, handleChange, handleBlur, handleSubmit, setFieldValue, resetForm }) =>
									<form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>
										<Col md={12} className="box-filtros send-file form" style={{ padding: 0, backgroundColor: '#fff', margin: 0, justifyContent: 'flex-start', border: 'none', boxShadow: 'none' }}>
											<div className='formulario' style={{ flex: 1 }}>
												<div className='atualVersionHeader'>
													<span>Versão atual</span>

													<span>Atualizada em: <b>{moment(this.state?.ocorrencia?.updated_at).format('DD/MM/YY HH:mm:ss')}</b></span>
												</div>

												<hr className='lineBox mt-0' />

												<h4>CLASSIFICAÇÃO DA OCORRÊNCIA</h4>

												<Row className="p-0 m-0" style={{ alignItems: 'center', gap: 8 }}>
													<div style={{ maxWidth: 150, width: '100%' }}>
														<Label>Contexto</Label>

														<select onChange={({ target }) => setFieldValue('context', target.value)} value={values.context} className="custom-select" style={{ width: 150, flex: 'none' }}>
															<option value=""></option>
															{ocorrenciasGet(values)?.context.map((option) => <option value={option}>{option}</option>)}
														</select>
													</div>

													{ocorrenciasGet(values)?.reason?.length > 0 && (
														<>
															<div className='divisionArrow' style={{ paddingTop: 20 }}>
																<FontAwesomeIcon icon={faChevronRight} />
															</div>

															<div style={{ maxWidth: 200, width: '100%' }}>
																<Label>Motivo</Label>

																<select onChange={({ target }) => setFieldValue('reason', target.value)} value={values.reason} className="custom-select" style={{ width: 200, flex: 'none' }}>
																	<option value=""></option>
																	{ocorrenciasGet(values)?.reason.map((option) => <option value={option}>{option}</option>)}
																</select>
															</div>
														</>
													)}

													{ocorrenciasGet(values)?.type?.length > 0 && (
														<>
															<div className='divisionArrow' style={{ paddingTop: 20 }}>
																<FontAwesomeIcon icon={faChevronRight} />
															</div>

															<div style={{ maxWidth: 300, width: '100%' }}>
																<Label>Tipo</Label>

																<select onChange={({ target }) => setFieldValue('type', target.value)} value={values.type} className="custom-select" style={{ width: 300, flex: 'none' }}>
																	<option value=""></option>
																	{ocorrenciasGet(values)?.type.map((option) => <option value={option}>{option}</option>)}
																</select>
															</div>
														</>
													)}

													{ocorrenciasGet(values)?.result?.length > 0 && (
														<>
															<div className='divisionArrow' style={{ paddingTop: 20 }}>
																<FontAwesomeIcon icon={faChevronRight} />
															</div>

															<div style={{ maxWidth: 300, width: '100%' }}>
																<Label>Resultado</Label>

																<select onChange={({ target }) => setFieldValue('result', target.value)} value={values.result} className="custom-select" style={{ width: 300, flex: 'none' }}>
																	<option value=""></option>
																	{ocorrenciasGet(values)?.result.map((option) => <option value={option}>{option}</option>)}
																</select>
															</div>
														</>
													)}
												</Row>

												<h4>OCORRÊNCIA</h4>

												<div>
													<Label style={{ width: '100%' }}>Descrição</Label>

													<TextareaAutosize placeholder='Descreva' style={{ padding: '7px 14px', width: '100%' }} rowsMin={5} name="description" type="text" variant="outlined" value={values.description} onBlur={handleBlur} onChange={handleChange} multiline />
												</div>

												<hr className='lineBox' />

												<div className='d-flex justify-content-between' style={{ alignItems: 'end' }}>
													<Row className="p-0 m-0" style={{ alignItems: 'center', gap: 8 }}>
														<div style={{ maxWidth: 200, width: '100%' }}>
															<Label>Status da ocorrência</Label>

															<select onChange={({ target }) => setFieldValue('status', target.value)} value={values.status} className="custom-select" style={{ width: 200, flex: 'none' }}>
																<option value=""></option>
																{ocorrenciasGet(values)?.status.map((option) => <option value={option}>{option}</option>)}
															</select>
														</div>

														<div style={{ maxWidth: 150, width: '100%' }}>
															<Label>Admin responsável</Label>

															<UserFilterComponent value={values.administrator} onChange={(_, value) => setFieldValue('administrator', value)} />
														</div>
													</Row>

													<div style={{ display: 'flex', marginLeft: 'auto', gap: 8 }}>
														<Link className="btnSecundary w-100" to={`/negotiation/show/${this.props.match.params.negotiationId}`} style={{ width: '100%', maxWidth: '96px' }}>
															<FontAwesomeIcon icon={faArrowLeft} />
															VOLTAR
														</Link>

														<button disabled={!values.status || !values.administrator?.id} type='submit' className="buttonPrimary w-100" style={{ width: '100%', maxWidth: '136px' }}>
															SALVAR
															<FontAwesomeIcon icon={faCheck} />
														</button>
													</div>
												</div>
											</div>
										</Col>
									</form>
								}
							</Formik>
						</div>


						{this.state.ocorrencia?.history?.length > 0 && this.state.ocorrencia.history.map((ocorrencia, index) => (
							<div className='box'>
								<div style={{ marginTop: 16, display: 'flex', flexDirection: 'row', rowGap: 16 }}>
									<Col md={12} className="box-filtros send-file form" style={{ padding: 0, backgroundColor: '#fff', margin: 0, justifyContent: 'flex-start', border: 'none', boxShadow: 'none' }}>
										<OcorrenciaCardHistorico key={index} ocorrencia={ocorrencia} />
									</Col>
								</div>
							</div>
						))}
					</Container>
				</div>
			</BetterCircularProgress>
		);
	}
}

export default withRouter(OcorrenciaShow);