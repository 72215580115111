import React, { Component, createRef } from 'react';
import { Container, Card, CardBody, Row, Col, Nav, NavItem, NavLink } from "reactstrap";
import Breadcrumbs from '../../../components/Breadcrumb';
import { getReadjustmentIndices as getAllByParams } from '../../../crud/billings.crud';
import DataTableIndices from '../../../components/datatables/DataTableIndices';
import BetterCircularProgress from '../../../components/BetterCircularProgress';
import { faCheck, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import BetterAlerts from '../../../components/BetterAlerts';
import { Link } from 'react-router-dom';

class IndicesIndex extends Component {
	dataTableRef = createRef();

	state = {
		is_filter_open: false,
		export: false,
	};

	fetchByFilter = (data) => {
		var params = { ...data };

		return getAllByParams(params).catch((err) => console.log(err));
	};

	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({ activeTab: tab }, () => { this.dataTableRef.current.fetchData(); });
		}
	};

	render() {
		return (
			<React.Fragment>
				<div className="page-content container-page userIndex">
					<Container fluid>
						<BetterAlerts errors={this.state.errors} success={this.state.success} />

						<Card className='card-general mx-auto p-0' style={{ maxWidth: 736, width: '100%', backgroundColor: 'transparent', boxShadow: 'none', border: 'none', paddingTop: 16 }}>
							<div className='header m-0 p-0'>
								<h1 className='m-0 p-0'>Índices de renovação</h1>

								<Breadcrumbs breadcrumbItems={[{ link: '/pagamento/indices/index', title: 'Financeiro' }, { link: '/pagamento/indices/index', title: 'Índices de renovação' }]} />
							</div>
						</Card>

						<Row>
							<Col lg={12} className='d-flex justify-content-center'>
								<Card className='card-general' style={{ maxWidth: 736, width: '100%' }}>
									<div className='datatable-box'>
										<BetterCircularProgress loading={this.state.loading}>
											<DataTableIndices ref={this.dataTableRef} filters={this.state.filters} noDelete fetchEvent={this.fetchByFilter} crudButtons={true}
												crudUrl="/pagamento/indices/index" idRow={`id`} export={this.state.export} />
										</BetterCircularProgress>
									</div>
								</Card>
							</Col>
						</Row>

						<Row>
							<Col lg={12} className='d-flex justify-content-center'>
								<Card className='card-general' style={{ maxWidth: 736, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'end', gap: 8 }}>
									<button className='btnSecundary' style={{ maxWidth: 102 }} onClick={() => window.history.back()}>
										<i className="fa fa-arrow-left" />
										VOLTAR
									</button>

									<button onClick={() => this.dataTableRef.current.saveIndice()} className="buttonPrimary w-100" style={{ width: '100%', maxWidth: '102px' }}>
										SALVAR
										<FontAwesomeIcon icon={faCheck} />
									</button>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}

export default IndicesIndex;