import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatBRL } from "../../../helpers/Utils";
import moment from "moment";

export default class DataTableHistorico extends Component {
	constructor() {
		super();

		this.state = {
			property: {},
			data: null,
			rows: 10,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: 'created_at',
			sortOrder: 1,
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		let DataTableHistorico = JSON.parse(localStorage.getItem("DataTableHistorico"));

		if (DataTableHistorico) {
			this.setState(
				{
					data: DataTableHistorico.data,
					rows: DataTableHistorico.rows,
					totalRecords: DataTableHistorico.totalRecords,
					first: DataTableHistorico.first,
					last: DataTableHistorico.last,
					sortField: DataTableHistorico.sortField,
					sortOrder: DataTableHistorico.sortOrder,
					loading: false
				},
			);
		} else {
			this.fetchData();
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters !== this.props.filters) {
			this.fetchData();
		}
	}

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = async (event) => {
		this.setState({
			loading: true,

		});
		if (event.data) {
			event.data = null;
		}

		let DataTableHistorico = {
			rows: event.rows,
			totalRecords: this.state.totalRecords,
			data: this.data,
			first: event.first,
			last: event.rows + event.first,
			loading: this.state.loading,
			sortField: this.state.sortField,
			sortOrder: this.state.sortOrder,
		};

		localStorage.setItem("DataTableHistorico", JSON.stringify(DataTableHistorico));

		this.setState({
			...this.state,
			rows: event.rows,
		});
		this.fetchData(event.rows, event.first);
	};

	fetchData = async (limit, skip) => {
		this.setState({ loading: true });

		let options = {
			$limit: limit ? limit : this.state.rows,
			$skip: skip != null ? skip : this.state.first,
			$sort: { [this.state.sortField]: this.state.sortOrder },
			user_id: this.props.user_id,
			destination: 'affiliate'
		};

		return this.props.fetchEvent(options).then(async (res) => {
			if (res.data.data.length == 0) {
				this.setState({
					...this.state,
					data: [],
					totalRecords: res.data.total,
					first: skip,
					last: skip + res.data.limit,
					loading: false
				});
			}

			await res.data.data.map(async (obj, i) => {
				this.setState({
					...this.state,
					data: res.data.data,
					totalRecords: res.data.total,
					first: skip,
					last: skip + res.data.limit,
					loading: false
				});
			});
		}).finally(() => {
			this.setState({ loading: false });
		});
	};

	sortTable = async (e) => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder, loading: true }, () => {
			let DataTableHistorico = {
				rows: this.state.rows,
				totalRecords: this.state.totalRecords,
				first: this.state.first,
				last: this.state.last,
				loading: this.state.loading,
				sortField: this.state.sortField,
				sortOrder: this.state.sortOrder,
				data: this.state.data
			};
			localStorage.setItem("DataTableHistorico", JSON.stringify(DataTableHistorico));

			this.fetchData();
		});
	};

	getValue = (obj) => {
		return (
			<div style={{ textAlign: "center" }}>
				<p>{formatBRL(obj || 0)}</p>
			</div>
		);
	};

	getType = (obj) => {
		switch (obj.type) {
			case 'new_commission':
				return 'Nova comissão!';

			case 'account_deposit':
				return 'Depósito na sua conta';

			case 'withdraw_cash':
				return 'Saque de saldo';

			default:
				return obj?.type ?? '--';
		}
	};

	render() {
		return (
			<DataTable ref={(el) => this.dt = el} value={this.state.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={[10, 25, 50]} first={this.state.first} last={this.state.last}
				totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} responsive={true} loadingIcon={`fas fa-sync fa-spin`} onSort={(e) => this.sortTable(e)}
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" emptyMessage="Nenhum registro encontrado!"
				currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" sortField={this.state.sortField} sortOrder={this.state.sortOrder}>
				<Column style={{ width: 159 }} sortable field="created_at" body={(obj) => <p>{moment(obj?.created_at).format("DD/MM/YYYY")}</p>} header="Data" />
				<Column field="type" body={(obj) => this.getType(obj)} header="Evento" />
				<Column style={{ width: 105 }} field="response.value" body={(obj) => this.getValue(obj?.response?.value)} header="Valor" />
			</DataTable>
		);
	}
}
