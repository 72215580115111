import React, { Component } from 'react';
import { Container, Card, CardBody, Table, Nav, NavItem, NavLink, Label } from "reactstrap";
import BetterCircularProgress from '../../components/BetterCircularProgress';
import { Link } from "react-router-dom";
import moment from 'moment';
import Breadcrumbs from '../../components/Breadcrumb';
import 'moment/locale/pt-br';
import BetterAlerts from '../../components/BetterAlerts';
import DataTablePropertyInspectionItems from '../../components/datatables/DataTablePropertyInspectionItems';
import { getVistoria, getVistoriaFile, getVistoriasByContract, getVistoriasItems } from '../../crud/property-inspection.crud';
import { get as getContract } from '../../crud/contracts.crud';
import arrowDownToLine from '../../assets/images/icons-custom/arrow-down-to-line.svg';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages, faLink } from '@fortawesome/pro-solid-svg-icons';
import DataTableRepaymentsInspection from '../../components/datatables/DataTableRepaymentsInspection';
import heic2any from 'heic2any';

moment.locale('pt-br');

class PropertyInspectionIndex extends Component {

	state = {
		loading: false,
		data: null,
		landlord: null,
		tenant: null,
		repayments: [],
		success: [],
		errors: [],
		transfer: null,
		vistoria: null,
		contract: null,
		disableVistoria: false,
		entryInspection: null,
		exitInspection: null,
		activeTab: '1',
		postsTotal: 0,
		entryItems: [],
		exitItems: [],
		medias: [],
		lastUpdateEntry: null,
		lastUpdateExit: null
	}

	componentDidMount = async () => {
		this.setState({ loading: true });
		this.setState({ success: this.props.location?.state?.success ? [this.props.location.state.success] : [] });

		try {
			const res = await getVistoriasByContract(this.props.match.params.id);
			if (res?.status === 200) {
				const data = res.data.data;

				const entryInspection = data.find(obj => obj.type === 'ENTRY');
				this.setState({ entryInspection });

				if (entryInspection) {
					const entryRes = await getVistoriasItems(entryInspection.id, 1, { '$sort[created_at]': -1 });
					if (entryRes?.status === 200) {
						const entryItems = entryRes.data?.data;
						this.setState({ entryItems });

						let entryMedias = [];
						for (const item of entryItems) {
							entryMedias.push(...item?.medias);
						}

						const convertedEntryMedias = await this.convertImages(entryMedias);
						this.setState({
							medias: [...this.state.medias, ...convertedEntryMedias],
							lastUpdateEntry: entryItems.length > 0 ? entryItems[0].submitted_at : null
						});
					}
				}

				const exitInspection = data.find(obj => obj.type === 'EXIT');
				this.setState({ exitInspection });

				if (exitInspection) {
					const exitRes = await getVistoriasItems(exitInspection.id, 1, { '$sort[created_at]': -1 });
					if (exitRes?.status === 200) {
						const exitItems = exitRes.data?.data;
						this.setState({ exitItems });

						let exitMedias = [];
						for (const item of exitItems) {
							exitMedias.push(...item?.medias);
						}

						const convertedExitMedias = await this.convertImages(exitMedias);
						this.setState({
							medias: [...this.state.medias, ...convertedExitMedias],
							lastUpdateExit: exitItems.length > 0 ? exitItems[0].submitted_at : null
						});
					}
				}

				const contractRes = await getContract(this.props.match.params.id);
				this.setState({ contract: contractRes.data.data.entity });
			}
		} catch (error) {
			console.error('Erro ao carregar vistorias e contratos:', error);
		} finally {
			this.setState({ loading: false });
		}
	}

	convertImages = async (medias) => {
		const convertedImages = await Promise.all(
			medias.map(async (media) => {
				try {
					if (media?.Location.endsWith('.heic') || media?.Location.endsWith('.heif')) {
						const response = await fetch(media?.Location);
						const blob = await response.blob();
						const convertedBlob = await heic2any({ blob });
						const url = URL.createObjectURL(convertedBlob);
						return { ...media, Location: url }; // Atualiza a URL convertida
					} else {
						return media; // Se não for .heic ou .heif, retorna a mídia original
					}
				} catch (error) {
					console.error('Erro ao converter imagem:', error);
					return media; // Fallback para a mídia original em caso de erro
				}
			})
		);
		return convertedImages;
	}

	getContractStatus = () => {
		if (this.state?.contract) {
			const contract = this.state.contract;

			if (!contract.is_terminated && contract.scheduled_termination_date === null) {
				return '?typeNegociacao=vigentes'
			}

			if (contract.is_terminated) {
				return '?typeNegociacao=encerrados'
			}

			if (contract.scheduled_termination_date !== null) {
				return '?typeNegociacao=encerrando'
			}

			return ''
		}
	}

	getLink = (id) => {
		if (id) {
			const currentUrl = window.location.href;
			const announcementId = this.state.contract.property_announcement_id;
			const vistoriaId = id;
			const contractId = this.state.contract?.id;

			const localEnvironment = currentUrl.toLowerCase().includes('localhost');
			const devEnvironment = currentUrl.toLowerCase().includes('dev-admin');

			let preUrl = (localEnvironment || devEnvironment) ? 'https://dev-app.mellro.com' : 'https://mellro.com';
			let complement = `/perfil/gerenciamento/vistoria/${announcementId}/${vistoriaId}?contractId=${contractId}`;


			navigator.clipboard.writeText(preUrl + complement);
			this.setState({ success: [...this.state.success, 'Link da vistoria copiado!'] });
		}
	};

	showLink = (id) => {
		const currentUrl = window.location.href;
		const announcementId = this.state.contract.property_announcement_id;
		const vistoriaId = id;
		const contractId = this.state.contract.id;

		const localEnvironment = currentUrl.toLowerCase().includes('localhost');
		const devEnvironment = currentUrl.toLowerCase().includes('dev-admin');

		let preUrl = (localEnvironment || devEnvironment) ? 'https://dev-app.mellro.com' : 'https://mellro.com';
		let complement = `/perfil/gerenciamento/vistoria/${announcementId}/${vistoriaId}?contractId=${contractId}`;

		const url = preUrl + complement;


		return url.slice(0, 20 - 3) + '...';;
	};

	handleDownloadVistoria = async () => {
		this.setState({ disableVistoria: true })
		try {
			await getVistoriaFile(this.state?.vistoria?.id).then((data) => { console.log(data); window.open(data?.request?.responseURL, '_blank'); this.setState({ disableVistoria: false }) });
		} catch (err) {
			console.log(err);
			this.setState({ errors: ['Pdf da vistoria não encontrado, entre em contato com o suporte.'] });
		}
	}

	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	};

	render() {

		return (
			<React.Fragment>
				<BetterCircularProgress loading={this.state.loading}>
					<div className="page-content container-page">
						{this.state?.contract ? (
							<Container fluid>


								<div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '24px' }}>
									<div style={{ width: '90%', margin: '0 auto', justifyContent: 'space-between', alignItems: 'center', display: 'flex' }} className="inspection-breadcrumb" >
										<Label className="col-form-label" style={{ color: '#505D69', fontSize: '24px', fontFamily: 'Inter' }}>Vistoria</Label>

										<Breadcrumbs breadcrumbItems={[{ link: '/negotiation/index', title: 'Negociações' }, { link: `/negotiation/index`, title: 'Negociações e contratos' }, { link: `/negotiation/show/${this.state?.contract?.proposition_id}${this.getContractStatus()}`, title: `ID ${this.props.match.params.id.substring(0, 4)}...` }, { link: `/contracts/friendly-agreement/${this.props.match.params.id}`, title: 'Vistoria' }]} />
									</div>
								</div>

								<BetterAlerts success={this.state.success} errors={this.state.errors} />

								<Card style={{ width: '90%', margin: '0 auto' }}>
									<div style={{ display: 'flex', flexDirection: 'row', flex: 1, padding: '24px' }}>

										<div >
											<Nav tabs className="nav-tabs-custom" role="tablist">
												<NavItem>
													<NavLink style={{ paddingLeft: '2rem', paddingRight: '2rem', display: 'flex' }} id="info-tab" className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggleTab('1'); }} >Entrada
														<p className='inspection-count' style={{ marginLeft: '8px', color: '#8590A5', fontWeight: 600 }}>{this.state.entryItems.length}</p>
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink style={{ paddingLeft: '2rem', paddingRight: '2rem', display: 'flex' }} id="announcements-tab" className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggleTab('2'); }}>Saída
														<p className='inspection-count' style={{ marginLeft: '8px', color: '#8590A5', fontWeight: 600 }}>{this.state.exitItems.length}</p></NavLink>
												</NavItem>

											</Nav>
										</div>
									</div>

									{this.state.activeTab === '1' ? (
										<>
											<CardBody>
												<div>
													<div className='inspection-header'>
														<div style={{ display: 'flex' }}>
															<div className='media-box' style={{ width: 33.6 * this.state.medias.slice(0, 5).length, height: 68 }}>
																{this.state.medias.slice(0, 5).map((media, i) => (
																	<>
																		<img src={media.Location} style={{ left: 16.8 * i + 1 }} />
																	</>
																))}

																{this.state.medias.length > 5 ? (
																	<div className='medias-more'>
																		<p>+{this.state.medias.length - 5}</p>
																	</div>
																) : null}

																{this.state.medias.length === 0 ? (
																	<div className='inspection-inactive' style={{ marginRight: '10px' }}>
																		<FontAwesomeIcon icon={faImages} />
																	</div>
																) : null}
															</div>
															<div>
																<p className='inspection-p'>Link para vistoria</p>
																<button onClick={() => { this.getLink(this.state.entryInspection?.id) }} className="copy-inspection">
																	{this.showLink(this.state.entryInspection?.id)}
																	<FontAwesomeIcon icon={faLink} style={{ marginLeft: '8px' }} />
																</button>
																<div className='inspection-status-box' style={{ backgroundColor: moment().isBefore(this.state.entryInspection?.end_date) ? '#EFA200' : '#1CBB8C' }}>
																	<p style={{ marginBottom: '0px' }}>{moment().isBefore(this.state.entryInspection?.end_date) ? "Aberta" : 'Finalizada'}</p>
																</div>
															</div>
														</div>
														<div style={{ display: 'flex' }}>
															<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginLeft: '24px' }}>
																<p className='inspection-p'>Postagens</p>
																<p className='inspection-p-2'>{this.state.entryItems.length} <FontAwesomeIcon icon={faImages} /></p>
															</div>

															<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginLeft: '24px' }}>
																<p className='inspection-p'>Período de vistoria</p>
																<p className='inspection-p-2'>{moment(this.state?.entryInspection?.start_date, 'YYYY-MM-DD').format('DD/MM/YYYY')} a {moment(this.state?.entryInspection?.end_date, 'YYYY-MM-DD').subtract(1, 'days').format('DD/MM/YYYY')} </p>
															</div>

															<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginLeft: '24px' }}>
																<p className='inspection-p'>Atualizado em</p>
																<p className='inspection-p-2'>{this.state?.lastUpdateEntry ? moment(this.state.lastUpdateEntry).format('DD/MM/YYYY HH:mm:ss') : moment(this.state?.entryInspection?.updatedAt, 'YYYY-MM-DD').format('DD/MM/YYYY HH:mm:ss')}</p>
															</div>
														</div>
													</div>

												</div>

												<DataTablePropertyInspectionItems propertyInspectionId={this.state?.entryInspection?.id} endDate={this.state?.entryInspection?.end_date} />
											</CardBody>


										</>
									) : null}

									{this.state.activeTab === '2' ? (
										<CardBody>
											<div>
												{this.state?.exitInspection?.id ? (
													<div className='inspection-header'>
														<div style={{ display: 'flex' }}>
															<div>
																<div className='media-box' style={{ width: 33.6 * this.state.medias.slice(0, 5).length, height: 68 }}>
																	{this.state.medias.slice(0, 5).map((media, i) => (
																		<>
																			<img src={media.Location} style={{ left: 16.8 * i + 1 }} />
																		</>
																	))}

																	{this.state.medias.length > 5 ? (
																		<div className='medias-more'>
																			<p>+{this.state.medias.length - 5}</p>
																		</div>
																	) : null}

																	{this.state.medias.length === 0 ? (
																		<div className='inspection-inactive' style={{ marginRight: '10px' }}>
																			<FontAwesomeIcon icon={faImages} />
																		</div>
																	) : null}
																</div>
															</div>
															<div>
																<p className='inspection-p'>Link para vistoria</p>
																<button onClick={() => { this.getLink(this.state.exitInspection?.id) }} className="copy-inspection">
																	{this.showLink(this.state.exitInspection?.id)}
																	<FontAwesomeIcon icon={faLink} style={{ marginLeft: '8px' }} />
																</button>
																<div className='inspection-status-box' style={{ backgroundColor: moment().isBefore(this.state.exitInspection?.end_date) ? '#EFA200' : '#1CBB8C' }}>
																	<p style={{ marginBottom: '0px' }}>{moment().isBefore(this.state.exitInspection?.end_date) ? "Aberta" : 'Finalizada'}</p>
																</div>
															</div>
														</div>
														<div style={{ display: 'flex' }}>
															<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginLeft: '24px' }}>
																<p className='inspection-p'>Postagens</p>
																<p className='inspection-p-2'>{this.state.exitItems.length} <FontAwesomeIcon icon={faImages} /></p>
															</div>

															<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginLeft: '24px' }}>
																<p className='inspection-p'>Período de vistoria</p>
																<p className='inspection-p-2'>{moment(this.state?.exitInspection?.start_date, 'YYYY-MM-DD').format('DD/MM/YYYY')} a {moment(this.state?.exitInspection?.end_date, 'YYYY-MM-DD').subtract(1, 'days').format('DD/MM/YYYY')} </p>
															</div>

															<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginLeft: '24px' }}>
																<p className='inspection-p'>Atualizado em</p>
																<p className='inspection-p-2'>{this.state?.lastUpdateExit ? moment(this.state.lastUpdateExit).format('DD/MM/YYYY HH:mm:ss') : moment(this.state?.exitInspection?.updatedAt, 'YYYY-MM-DD').format('DD/MM/YYYY HH:mm:ss')}</p>
															</div>
														</div>
													</div>
												) : (
													<div className='inspection-header'>
														<div style={{ display: 'flex' }}>
															<div style={{ marginRight: '24px' }}>
																<div className='inspection-inactive'>
																	<FontAwesomeIcon icon={faImages} />
																</div>
															</div>
															<div>
																<p className='inspection-p'>Link para vistoria</p>
																<button onClick={() => { }} className="copy-inspection">
																	Vistoria inativa
																	<FontAwesomeIcon icon={faLink} style={{ marginLeft: '8px' }} />
																</button>
																<div className='inspection-status-box' style={{ backgroundColor: '#8590A5' }}>
																	<p style={{ marginBottom: '0px' }}>Inativa</p>
																</div>
															</div>
														</div>
														<div style={{ display: 'flex' }}>
															<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginLeft: '24px' }}>
																<p className='inspection-p' >Postagens</p>
																<p className='inspection-p-2' style={{ color: '#FF3D60' }}>0 <FontAwesomeIcon icon={faImages} /></p>
															</div>

															<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginLeft: '24px' }}>
																<p className='inspection-p'>Período de vistoria</p>
																<p className='inspection-p-2'>-- a --</p>
															</div>

															<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginLeft: '24px' }}>
																<p className='inspection-p'>Atualizado em</p>
																<p className='inspection-p-2'>--</p>
															</div>
														</div>
													</div>
												)}
											</div>

											<DataTablePropertyInspectionItems propertyInspectionId={this.state?.exitInspection?.id} setPostsTotal={this.setState} endDate={this.state?.exitInspection?.end_date} />
										</CardBody>
									) : null}
								</Card>


								{this.state.activeTab === '2' && !this.state.exitInspection || this.state.activeTab === '1' && !this.state?.entryInspection ? null : (
									<div style={{ display: '100%', display: 'flex', justifyContent: 'center' }}>
										<Card className='repayments-inspection-box' style={{ marginTop: '24px', width: '90%' }}>
											<h3>REEMBOLSOS</h3>
											<DataTableRepaymentsInspection contractId={this.props.match.params.id} />
										</Card>
									</div>
								)}

								{/* <Card>
									<CardBody>
										<Link to={`/contracts/show/${this.state.vistoria?.contract_id}`} className="btn btn-secondary btn-bold ml-2">
											<i className="fa fa-arrow-left mr-2"></i>Voltar
										</Link>

										{moment().isAfter(moment(this.state?.vistoria?.end_date).subtract(1, 'days')) ? (
											<button disabled={this?.state?.disableVistoria} onClick={async () => { await this.handleDownloadVistoria(); }} className="btn btn-primary btn-bold ml-2">
												Baixar vistoria de {this.state?.vistoria?.type === 'ENTRY' ? 'entrada' : 'saída'}	<img src={arrowDownToLine} alt="download" className='ml-2' width={12} height={20} />
											</button>
										) : null}

										<button onClick={this.getLink} className="btn btn-primary btn-bold ml-2">
											Copiar link vistoria<i className="fa fa-link ml-2"></i>
										</button>
									</CardBody>
								</Card> */}
							</Container>
						) : null}
					</div>
				</BetterCircularProgress>
			</React.Fragment>
		)
	}
}

export default PropertyInspectionIndex;