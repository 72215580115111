import React, { forwardRef } from "react";

const ListBoxComponent = (findMore) =>
  forwardRef((listProps, ref) => {
    return (
      <ul
        ref={ref}
        {...listProps}
        onScroll={(props) => {
          const { scrollTop, scrollHeight, offsetHeight } = props.target;
          if (
            [offsetHeight + 1, offsetHeight, offsetHeight - 1].includes(scrollHeight - scrollTop)
          ) {
            findMore();
            props.currentTarget = props.target;
          }
        }}
      />
    );
  });

export default ListBoxComponent;