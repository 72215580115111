import React, { Component } from "react";
import { Container, Card, CardBody, Col, FormGroup, Label, Button, Row } from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import { Formik } from "formik";
import { validateFormCoupon } from "../../helpers/FormValidators";
import BetterAlerts from "../../components/BetterAlerts";
import Breadcrumbs from "../../components/Breadcrumb";
import BetterCircularProgress from "../../components/BetterCircularProgress";
import { safeJsonParser } from '../negotiation/helpers';
import { UserComponent } from "../negotiation/components/user.component";
import { formatBRL, formatBRLInput, limparMoeda } from "../../helpers/Utils";
import { TextField } from "@material-ui/core";
import Swal from 'sweetalert2';
import { get as getPlans } from "../../crud/plans.crud";
import moment from "moment";
import { deleteCouponUser, get, storeCouponUser, update } from '../../crud/coupons.crud';

class CouponsEdit extends Component {
	state = {
		timeout: null,
		submitted: false,
		loading: false,
		success: false,
		errors: [],
		load_from_announcement: false,
		data: {
			code: '',
			plan: {},
			discount_type: '',
			discount_value: null,
			users: [],
			application_limit: '',
			application_per_user_limit: '',
			application_start_date: moment().format('YYYY-MM-DD HH:mm:ss'),
			application_end_date: moment().format('YYYY-MM-DD HH:mm:ss'),
			expiration_date: moment().format('YYYY-MM-DD HH:mm:ss'),
		},
		coupon: null,
		plan_list: [],
		coupon_user_list: [],
		submittingCouponUser: false,
		old_coupon_users: []
	};

	componentDidMount = async () => {
		this.setState({ loading: true });
		let plan_list = [];

		await getPlans().then(({ data }) => {
			plan_list = data.data;
		});

		get(this.props.match.params.id).catch(err => {
			return Swal.fire('Ops', 'Ocorreu um erro ao buscar o cupom.', 'error');
		}).then(res => {
			if (res?.status === 200) {
				if (res.data) {
					let plan = null;

					if (plan_list.length > 0) {
						plan_list.forEach((obj, i) => {
							if (obj.id == res.data.plan_id) plan = obj;
						});
					}

					this.setState({
						old_coupon_users: res.data.users ?? []
					})

					let coupon_users = [];
					let added_users = [];

					if (res.data.users) {
						res.data.users.forEach((obj, i) => {
							if (!added_users.includes(obj.user_id)) {
								coupon_users.push(obj);
								added_users.push(obj.user_id);
							}
						});
					}

					console.log({ coupon_users });

					this.setState({
						data: {
							code: res.data.code,
							plan: JSON.stringify(plan),
							discount_type: res.data.discount_type,
							discount_value: res.data.discount_type === 'FIXED_VALUE' ? formatBRL(res.data.discount_amount) : res.data.discount_amount,
							users: [],
							application_limit: res.data.application_limit,
							application_per_user_limit: res.data.application_per_user_limit,
							application_start_date: res.data.application_start_date ? moment(res.data.application_start_date).format('YYYY-MM-DD HH:mm:ss') : '',
							application_end_date: res.data.application_end_date ? moment(res.data.application_end_date).format('YYYY-MM-DD HH:mm:ss') : '',
							expiration_date: res.data.expiration_date ? moment(res.data.expiration_date).format('YYYY-MM-DD HH:mm:ss') : '',
						},
						coupon: res.data,
						plan_list,
						coupon_user_list: coupon_users ?? [],
					});
				}
			}
		}).finally(() => this.setState({ loading: false }));
	};

	onSubmit = (values) => {
		if (!values.plan) return Swal.fire('Ops', 'Preencha o plano antes de continuar.', 'error');
		if (!values.discount_type) return Swal.fire('Ops', 'Preencha o tipo de desconto antes de continuar.', 'error');
		if (!values.discount_value) return Swal.fire('Ops', 'Preencha o desconto antes de continuar.', 'error');

		let plan = JSON.parse(values.plan);

		if (plan.name === 'WARRANTY' && values.discount_type === 'PERCENTAGE' && values.discount_value == 3) {
			return Swal.fire('Ops', 'Só é possível fornecer 3% de desconto no plano com garantia de danos.', 'error');
		}

		let data = {
			plan_id: plan.id,
			code: values.code,
			discount_type: values.discount_type,
			discount_amount: values.discount_type === 'FIXED_VALUE' ? limparMoeda(values.discount_value) : values.discount_value,
		}


		if (this.state.old_coupon_users.length < this.state.coupon_user_list.length) {
			data = {
				...data,
				type: 'PERSONAL'
			}
		}

		if (values.application_start_date) data.application_start_date = moment(values.application_start_date).format('YYYY-MM-DD HH:mm:ss');
		if (values.application_end_date) data.application_end_date = moment(values.application_end_date).format('YYYY-MM-DD HH:mm:ss');
		if (values.expiration_date) data.expiration_date = moment(values.expiration_date).format('YYYY-MM-DD HH:mm:ss');
		if (values.application_per_user_limit) data.application_per_user_limit = values.application_per_user_limit;
		if (values.application_limit) data.application_limit = values.application_limit;
		if (values.users?.length > 0) data.users = [values.users.id];

		this.setState({ loading: true });

		update(this.props.match.params.id, data).catch(err => {
			Swal.fire('Ops', 'Ocorreu um erro ao editar o cupon. Por favor, tente novamente.', 'error');
		}).then(res => {
			if (res?.status == 200) {
				this.setState({ submitted: true, success: !(Boolean(res.data.error)), errors: res.data.error ? [res.data.error] : [] });
			}
		}).finally(() => {
			this.setState({ loading: false });
		});
	};

	onChangeUser = (value, setFieldValue) => {
		let exists = false;
		this.state.coupon_user_list.forEach((obj, i) => { if (obj.id == value.id) exists = true; });

		if (exists) return Swal.fire('Ops', 'O usuário selecionado já está na lista.', 'error');

		this.setState({ submittingCouponUser: true });

		storeCouponUser({ coupon_id: this.props.match.params.id, user_id: value.id }).catch(err => {
			Swal.fire('Ops', 'Ocorreu um erro ao adicionar o usuário. Por favor, tente novamente.', 'error');
		}).then(res => {
			if (res?.status === 200) {
				if (res.data.data.code) {
					Swal.fire("Ops!", res.data.data.message);
				} else {
					let coupon_user_list = [...this.state.coupon_user_list];
					coupon_user_list.push(res.data.data);
					this.setState({ coupon_user_list });
				}
			}
		}).finally(() => {
			this.setState({ submittingCouponUser: false });
		});
	}

	removeCouponUser = (index) => {
		this.setState({ submittingCouponUser: true });

		deleteCouponUser(this.state.coupon_user_list[index].id).catch(err => {
			Swal.fire('Ops', 'Ocorreu um erro ao remover o usuário. Por favor, tente novamente.', 'error');
		}).then(res => {
			if (res?.status == 200) {
				let coupon_user_list = [...this.state.coupon_user_list];
				coupon_user_list.splice(index, 1);
				this.setState({ coupon_user_list });
			}
		}).finally(() => {
			this.setState({ submittingCouponUser: false });
		});
	}

	render() {
		return (
			<React.Fragment>
				<div className="page-content container-page">
					<Container fluid>
						<Breadcrumbs title="Cupons > Criar cupom" breadcrumbItems={[]} />
						<BetterAlerts errors={this.state.errors} />

						{this.state.success ? (
							<Redirect
								to={{
									pathname: "/coupons/index",
									state: { success: ["Cupom criado com sucesso!"] },
								}}
							/>
						) : null}
						<BetterCircularProgress loading={this.state.loading}>
							<Formik initialValues={this.state.data} validate={(values) => validateFormCoupon(values)} onSubmit={this.onSubmit} enableReinitialize={true}>
								{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
									return (
										<form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>
											<Card>
												<CardBody>
													<Row>
														<Col md={12} className="d-flex">
															<p className="col-form-label mb-1" style={{ color: "#5664D2", fontSize: "13px", fontWeight: 600, lineHeight: '16px' }} >
																Preencha os campos abaixo para criar um cupom de desconto
															</p>
														</Col>
													</Row>

													<Row style={{ marginTop: '24px' }}>
														<Col md={12} className="d-flex">
															<p style={{ color: "#505D69", fontSize: "23px", fontWeight: '600', lineHeight: '28px' }} >
																Geral <span style={{ color: "#8590A5", fontSize: "13px", fontWeight: '600', lineHeight: '16px' }}>(Obrigatório)</span>
															</p>
														</Col>
													</Row>

													<Row>
														<Col md={6}>
															<Label className="col-form-label">Código do cupom *</Label>
															<TextField name="code" type="text" variant="outlined" value={values.code} onBlur={handleBlur} onChange={handleChange}
																helperText={touched.code && errors.code} error={Boolean(touched.code && errors.code)}
																placeholder="Crie o nome do cupom" />
														</Col>

														<Col md={6}>
															<FormGroup row>
																<Label className="col-md-4 col-form-label">Plano *</Label>
																<Col md={12}>
																	<select
																		style={{ background: "#FFF", height: "40px" }}
																		name="plan"
																		onChange={(e, value) => {
																			let data = this.state.data
																			data.plan = e.target.value
																			values.plan = safeJsonParser(data.plan);
																			this.setState({ ...this.state, data: data })
																			handleChange(e)
																		}}
																		onBlur={handleBlur}
																		value={values.plan}
																		className="custom-select">
																		<option key={null} value={null} hidden>Selecione o plano</option>
																		{this.state?.plan_list.map((plan) => {
																			if (['WARRANTY_GUARANTEE_DAMAGE', 'WARRANTY'].includes(plan.name)) {
																				return (
																					<option key={plan.id} value={JSON.stringify(plan)}>{plan.friendly_name}</option>
																				);
																			}
																		})}
																	</select>
																</Col>
															</FormGroup>
														</Col>

														{/* <Col md={6}>
															<FormGroup row>
																<Label className="col-md-4 col-form-label">Tipo do desconto *</Label>
																<Col md={12}>
																	<select
																		style={{ background: "#FFF", height: "40px" }}
																		name="discount_type"
																		onChange={(e, value) => {
																			handleChange(e);
																			setFieldValue('discount_value', null);
																		}}
																		onBlur={handleBlur}
																		value={values.discount_type}
																		className="custom-select">
																		<option key={null} value={null} hidden>Selecione o tipo do desconto</option>
																		<option key={'FIXED_VALUE'} value='FIXED_VALUE'>Valor fixo</option>
																		<option key={'PERCENTAGE'} value='PERCENTAGE'>Porcentagem</option>
																	</select>
																</Col>
															</FormGroup>
														</Col> */}

														{values.discount_type == 'FIXED_VALUE' ?
															<Col md={6}>
																<Label className="col-form-label">Desconto *</Label>
																<TextField name="discount_value" type="text" variant="outlined" value={values.discount_value} onBlur={handleBlur} onChange={(e) => handleChange(formatBRLInput(e))}
																	helperText={touched.discount_value && errors.discount_value} error={Boolean(touched.discount_value && errors.discount_value)}
																	placeholder="Preencha o desconto" />
															</Col>
															:
															<Col md={6}>
																<FormGroup row>
																	<Label className="col-md-4 col-form-label">Desconto *</Label>
																	<Col md={12}>
																		<select
																			style={{ background: "#FFF", height: "40px" }}
																			name="discount_value"
																			onChange={(e, value) => { handleChange(e); }}
																			onBlur={handleBlur}
																			value={values.discount_value}
																			className="custom-select">
																			<option key={null} value={null} hidden>Selecione o desconto</option>
																			<option key={'1'} value={1}>1%</option>
																			<option key={'2'} value={2}>2%</option>
																			{/*<option key={'3'} value={3}>3%</option> */}
																		</select>
																	</Col>
																</FormGroup>
															</Col>
														}
													</Row>

													<Row style={{ marginTop: '24px' }}>
														<Col md={12} className="d-flex">
															<p style={{ color: "#505D69", fontSize: "23px", fontWeight: '600', lineHeight: '28px' }} >
																Restrições de uso <span style={{ color: "#8590A5", fontSize: "13px", fontWeight: '600', lineHeight: '16px' }}>(Opcional)</span>
															</p>
														</Col>
													</Row>

													<Row>
														<Col md={6}>
															<Row>
																<UserComponent
																	label="Usuário(s)"
																	name="users"
																	onBlur={handleBlur}
																	onChange={(e, value) => this.onChangeUser(value, setFieldValue)}
																	value={values.users}
																/>
															</Row>
															{this.state.coupon_user_list.length > 0 ?
																<div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
																	{this.state.coupon_user_list.map((obj, i) => {
																		return (
																			<div style={{
																				height: '24px', borderRadius: '12px', display: 'flex', flexDirection: 'row',
																				alignItems: 'center', backgroundColor: '#d6d6d6', width: 'fit-content', padding: '0 8px',
																				marginBottom: '4px', marginRight: '4px'
																			}}>
																				<p style={{ marginBottom: '0' }}>{obj.user.name}</p>
																				{!obj.applied &&
																					<i className="fa fa-times" onClick={() => this.removeCouponUser(i)} />
																				}
																			</div>
																		)
																	})}
																	{this.state.submittingCouponUser &&
																		<div style={{
																			height: '24px', borderRadius: '12px', display: 'flex', flexDirection: 'row',
																			alignItems: 'center', backgroundColor: '#d6d6d6', width: 'fit-content', padding: '0 8px',
																			marginBottom: '4px', marginRight: '4px'
																		}}>
																			<i className="fas fa-spinner fa-spin" />
																		</div>
																	}
																</div>
																:
																<p style={{ fontWeight: '400', fontSize: '12px', lineHeight: '15px', color: '#8590A5' }}>Se precisa criar um cupom que pode ser usado por qualquer usuário, não preencha esse campo.</p>
															}
														</Col>

														<Col md={6}>
															<Label className="col-form-label">Limites de uso por usuário</Label>
															<TextField name="application_per_user_limit" type="text" variant="outlined" value={values.application_per_user_limit} onBlur={handleBlur} onChange={handleChange}
																helperText={touched.application_per_user_limit && errors.application_per_user_limit} error={Boolean(touched.application_per_user_limit && errors.application_per_user_limit)} />
															<p style={{ marginTop: '16px', fontWeight: '400', fontSize: '12px', lineHeight: '15px', color: '#8590A5' }}>Se precisa criar um cupom de uso ilimitado por usuário, não preencha esse campo. </p>
														</Col>

														<Col md={6}>
															<Label className="col-form-label">Limites de aplicação</Label>
															<TextField name="application_limit" type="text" variant="outlined" value={values.application_limit} onBlur={handleBlur} onChange={handleChange}
																helperText={touched.application_limit && errors.application_limit} error={Boolean(touched.application_limit && errors.application_limit)} />
															<p style={{ marginTop: '16px', fontWeight: '400', fontSize: '12px', lineHeight: '15px', color: '#8590A5' }}>Se precisa criar um cupom de uso ilimitado, não preencha esse campo. </p>
														</Col>
													</Row>

													<Row style={{ marginTop: '24px' }}>
														<Col md={12} className="d-flex">
															<p style={{ color: "#505D69", fontSize: "23px", fontWeight: '600', lineHeight: '28px' }} >
																Datas <span style={{ color: "#8590A5", fontSize: "13px", fontWeight: '600', lineHeight: '16px' }}>(Opcional)</span>
															</p>
														</Col>
													</Row>

													<Row>
														<Col md={6}>
															<Label className="col-form-label">Data de inicial de aplicação</Label>
															<TextField
																className="mb-2"
																style={{ background: "#FFF", height: "40px" }}
																name={'application_start_date'}
																type="datetime-local"
																variant="outlined"
																value={values.application_start_date}
																InputLabelProps={{
																	shrink: true,
																}}
																onChange={handleChange}
																onBlur={handleBlur}
															/>
															<p style={{ marginTop: '8px', fontWeight: '400', fontSize: '12px', lineHeight: '15px', color: '#8590A5' }}>
																Preencha se o cupom precisa começar a valer a partir de uma data específica.
															</p>
														</Col>

														<Col md={6}>
															<Label className="col-form-label">Validade de aplicação</Label>
															<TextField
																className="mb-2"
																style={{ background: "#FFF", height: "40px" }}
																name={'application_end_date'}
																type="datetime-local"
																variant="outlined"
																value={values.application_end_date}
																InputLabelProps={{
																	shrink: true,
																}}
																onChange={handleChange}
																onBlur={handleBlur}
															/>
															<p style={{ marginTop: '8px', fontWeight: '400', fontSize: '12px', lineHeight: '15px', color: '#8590A5' }}>
																Preencha se o cupom só pode ser aplicado até uma data específica.
															</p>
														</Col>

														<Col md={6}>
															<Label className="col-form-label">Data de expiração</Label>
															<TextField
																className="mb-2"
																style={{ background: "#FFF", height: "40px" }}
																name={'expiration_date'}
																type="datetime-local"
																variant="outlined"
																value={values.expiration_date}
																InputLabelProps={{
																	shrink: true,
																}}
																onChange={handleChange}
																onBlur={handleBlur}
															/>
															<p style={{ marginTop: '8px', fontWeight: '400', fontSize: '12px', lineHeight: '15px', color: '#8590A5' }}>
																Preencha se o cupom/desconto precisa expirar em uma data específica.
															</p>
														</Col>
													</Row>
												</CardBody>
											</Card>

											<Card>
												<CardBody className="container-space-between">
													<Link to='/coupons/index' className="btn btn-danger btn-bold"><i className="fa fa-arrow-left mr-2"></i>Voltar</Link>

													<Button color="success" type="submit" className="waves-effect waves-light mr-10" >
														<i className="ri-save-3-line align-middle mr-2"></i>Salvar cupom
													</Button>
												</CardBody>
											</Card>
										</form>
									);
								}}
							</Formik>
						</BetterCircularProgress>
					</Container>
				</div>
			</React.Fragment >
		);
	}
}

export default CouponsEdit;
