/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { Container, Card, CardBody, Col, Label, Input } from "reactstrap";
import BetterCircularProgress from '../../components/BetterCircularProgress';
import { Link, Redirect } from "react-router-dom";
import Breadcrumbs from '../../components/Breadcrumb';
import { Formik } from "formik";
import { TextField } from "@material-ui/core";
import BetterAlerts from '../../components/BetterAlerts';
import { createFriendlyAgreement, get, getFriendlyAgreement } from '../../crud/billings.crud';
import { listRepayments } from '../../crud/billings.crud';
import moment from 'moment';
import { formatBRL } from '../../helpers/Utils';
import { formatBRLInput, formatDate, limparMoeda } from '../negotiation/helpers';
import { store } from "../../crud/doc-users.crud";
import { faArrowLeft, faCalendarDays, faCheck, faChevronDown, faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faUser as faUserSolid } from '@fortawesome/pro-solid-svg-icons';
import { get as getContract } from '../../crud/contracts.crud';
import { get as getUser } from '../../crud/users.crud';

class FriendlyAgreement extends Component {
	state = {
		loading: false,
		errors: [],
		success: false,
		billings: [],
		billingsAgreement: [],
		overdueBillingsLastAgreement: [],
		repayments: [],
		friendlyAgreement: null,
		number_of_installments: [
			{ id: 1, label: '01' },
			{ id: 2, label: '02' },
			{ id: 3, label: '03' },
			{ id: 4, label: '04' },
			{ id: 5, label: '05' },
			{ id: 6, label: '06' },
			{ id: 7, label: '07' },
			{ id: 8, label: '08' },
			{ id: 9, label: '09' },
			{ id: 10, label: '10' },
			{ id: 11, label: '11' },
			{ id: 12, label: '12' }
		],
		overdueBillings: [],
		installmentsDetails: [],
		editableInstallments: [{
			number: 1,
			value: 0
		}],
		payment_type: '',
		file: null,
		agreementSum: false,
		contract: null,
		lastAgreementSettled: false
	}

	componentDidMount = async () => {
		const contractId = this.props.match.params.id;
		let friendlyAgreementLet = null;

		this.setState({ loading: true });

		try {
			const friendlyAgreementResponse = await getFriendlyAgreement({ contract_id: contractId });
			const friendlyAgreements = friendlyAgreementResponse.data?.data || [];

			if (friendlyAgreements.length > 0) {
				friendlyAgreementLet = friendlyAgreements[0];

				try {
					const userResponse = await getUser(friendlyAgreementLet.created_by);
					const userData = userResponse.data?.data;
					this.setState({ friendlyAgreement: { ...friendlyAgreements[0], user: userData } });
				} catch (err) {
					this.setState({ errors: [...this.state.errors, 'Erro ao buscar o usuário criador do contrato.'] });
				}
			}

			const billingResponse = await get({ contract_id: contractId, $limit: 99 });
			if (billingResponse?.status === 200) {
				let billingsAgreementVar = [];
				let billingsOverdueVar = [];

				for (const billing of billingResponse.data.data) {
					if (billing.type === "agreement") {
						billingsAgreementVar.unshift(billing);
					}

					if (friendlyAgreementLet?.overdue_billings.includes(billing?.id)) {
						billingsOverdueVar.push(billing);
					}
				}

				this.setState({ billingsAgreement: billingsAgreementVar, overdueBillingsLastAgreement: billingsOverdueVar });
				if (billingsAgreementVar.length > 0) {
					this.setState({ lastAgreementSettled: billingsAgreementVar.every(obj => obj.is_paid == true) });
				}
			}

			const overdueBillingsResponse = await get({
				contract_id: contractId,
				due_date_lte: moment().format('YYYY-MM-DD'),
				$sort: { due_date: '1' },
				is_paid: false,
				$limit: 99
			});

			if (overdueBillingsResponse?.status === 200) {
				this.setState({ billings: overdueBillingsResponse.data.data });
			}

			const repaymentsResponse = await listRepayments({ contract_id: contractId });
			if (repaymentsResponse?.status === 200) {
				this.setState({ repayments: repaymentsResponse.data.data });
			}

			const contractResponse = await getContract(contractId);
			if (contractResponse?.status === 200) {
				this.setState({ contract: contractResponse.data?.data?.entity });
			}

		} catch (error) {
			if (error?.response?.data?.message) {
				this.setState({ errors: [...this.state.errors, error.response.data.message] });
			} else {
				this.setState({ errors: [...this.state.errors, 'Houve um erro, entre em contato com o suporte'] });
			}
		} finally {
			this.setState({ loading: false });
		}
	};

	onSubmit = async (values) => {
		this.setState({ loading: true });

		let number_of_installmentsLet = values?.number_of_installments;

		if (values?.number_of_installments == 'custom') {
			number_of_installmentsLet = values?.custom_installment;
		}

		if (!values?.confirm_agreement) {
			this.setState({ errors: [...this.state.errors, 'É necessário confirmar a intenção de gerar um acordo amigável antes de prosseguir!'], overdueBillings: [] });
			this.setState({ loading: false });
			return;
		}

		if (values?.start_date && moment(values?.start_date).isBefore(moment().add(2, 'days'))) {
			this.setState({ errors: [...this.state.errors, 'A data da primeira parcela deve ser pelo menos 2 dias após a data atual!'], overdueBillings: [] });
			this.setState({ loading: false });
			return;
		}

		if (!values?.start_date) {
			this.setState({ errors: [...this.state.errors, 'É necessário adicionar a data da primeira parcela para prosseguir!'], overdueBillings: [] });
			this.setState({ loading: false });
			return;
		}

		if (this.state.installmentsDetails.some(objeto => parseFloat(objeto.value) < 0)) {
			this.setState({ errors: [...this.state.errors, 'Valores negativos encontrados nas parcelas! Verifique as informações e tente novamente.'], overdueBillings: [] });
			this.setState({ loading: false });
			return;
		}

		if (this.state.installmentsDetails.some(objeto => parseFloat(objeto.value) === 0)) {
			this.setState({ errors: [...this.state.errors, 'O valor das parcelas não pode ser zero!'], overdueBillings: [] });
			this.setState({ loading: false });
			return;
		}

		let data = {
			"overdue_billings": this.state.overdueBillings.map(bill => bill.id),
			"contract_id": this.props.match.params.id,
			"start_date": values?.start_date,
			"installments": parseInt(number_of_installmentsLet),
			"payment_type": values?.payment_type
		}

		this.state.billingsAgreement.map((bill) => {
			if (bill?.is_paid == false) {
				data.overdue_billings.push(bill.id);
			}
		})

		if (values?.payment_type === 'EDITED_VALUES') {
			data.installments_details = this.state.installmentsDetails
		}

		await createFriendlyAgreement(data).catch(err => {
			this.setState({ errors: [...this.state.errors, 'Erro ao gerar acordo amigável, contate o suporte!'] });
		}).then(res => {
			if (res.status === 200) {
				if (this.state?.file) {
					let formData = new FormData();

					formData.append('file', this.state.file);

					formData.append('description', 'Termo de confissão de dívida.');

					formData.append('category', 'Termo de confissão de dívida.');

					formData.append('contract_id', this.props.match.params.id);


					store(formData).then((res) => {

					}).catch((err) => {
					}).finally(() => {
						this.setState({ success: true })
					})
				} else {
					this.setState({ success: true })
				}
			}
		})
	};

	getInitialValues = () => {
		return {
			start_date: '',
			custom_installment: null,
			number_of_installments: 1,
			overdue_billings: [],
			payment_type: "DIVIDED_EQUALLY",
			installments_details: null,
			confirm_agreement: false,
			first_installment: 'R$ 0,00',
			first_installment_date: ''
		};
	};

	disabledConditions = values => {
		if (values.start_date == '' || values.number_of_installments == '' || values.confirm_agreement == false || (this.state.overdueBillings.length === 0 && !this.state.friendlyAgreement)) {
			return true;
		} else {
			return false;
		}
	};

	getPaymentInfos = billing => {
		if (billing.type === 'monthly') {
			return `Aluguel mensal - ${moment(billing.due_date).add(3, 'hours').format('DD/MM/YYYY')}`
		}

		if (billing.type === 'termination') {
			return `Rescisão - ${moment(billing.due_date).add(3, 'hours').format('DD/MM/YYYY')}`
		}
	}

	removeBillingOfAgreement = (billing, values) => {
		const result = this.state.overdueBillings.filter(obj => obj.id !== billing.id);

		this.setState({ overdueBillings: result })

		values.overdueBillings = result;
		this.generatePreview(values)
	}

	removeFile = () => {
		this.setState({ file: null })
	}

	getInstallmentValue = (values) => {
		if (this.state.installmentsDetails.length > 0 && values.start_date) {
			if (values.payment_type === 'EDITED_VALUES' && this.state.installmentsDetails[1]) {
				return formatBRL(this.state.installmentsDetails[1].value)
			} else {
				return formatBRL(this.state.installmentsDetails[0].value)
			}
		} else {
			return 'R$ 0,00'
		}
	}

	generatePreview = ({ number_of_installments, first_installment = '', payment_type, overdueBillings, start_date, first_installment_date, custom_installment }) => {
		let totalValue = 0;
		let arr = [];
		let editableInstallments = [{ number: 1, value: limparMoeda(first_installment), due_date: first_installment_date }];
		let overdueBillingsSelected = overdueBillings ? overdueBillings : this.state.overdueBillings;
		let number_of_installmentsLet = number_of_installments

		if (number_of_installmentsLet == 'custom') {
			number_of_installmentsLet = custom_installment;
		}

		try {
			for (let billing of overdueBillingsSelected) {
				let repaymentArr = this.state.repayments.filter(obj => obj.billing_id === billing.id);
				let repaymentValue = 0;

				for (const repayment of repaymentArr) {
					if (repayment?.type === 'addition') {
						repaymentValue = repaymentValue + parseFloat(repayment?.amount)
					}
					if (repayment?.type === 'repayment') {
						repaymentValue = repaymentValue - parseFloat(repayment?.amount)
					}
				}

				if (moment(start_date, 'YYYY-MM-DD').isAfter(moment(billing.due_date))) {
					const dateUsedOnFine = first_installment_date ? first_installment_date : start_date;

					totalValue = totalValue + this.calcMonthFine(billing, dateUsedOnFine);
				} else {
					totalValue = totalValue + billing?.values.value;
				}

				if (repaymentValue !== 0) {
					totalValue = totalValue + repaymentValue;
				}
			}

			if (this.state.friendlyAgreement) {
				for (const bill of this.state?.billingsAgreement) {
					if (bill?.is_paid == false) {
						if (moment().isAfter(moment(bill.due_date))) {
							//const dateUsedOnFine = first_installment_date ? first_installment_date : start_date;

							totalValue = totalValue + this.calcMonthFine(bill, start_date);
						} else {
							totalValue = totalValue + bill?.values.value;
						}
					}
				}
			}

			if (payment_type === 'EDITED_VALUES') {
				let editableTotalValue = 0;

				for (const i of editableInstallments) {
					editableTotalValue = editableTotalValue + i.value;
					arr.push(i);
				}

				totalValue = totalValue - editableTotalValue;

				const parcelasCorrigidas = this.dividirEmParcelas(totalValue, number_of_installmentsLet - editableInstallments.length);

				for (let i = editableInstallments.length; i < number_of_installmentsLet; i++) {
					const obj = {
						number: i + 1,
						value: parcelasCorrigidas[i - editableInstallments.length].toFixed(2),
						due_date: moment(start_date).add(i - 1, 'months').format('YYYY-MM-DD')
					};

					arr.push(obj);
				}
			} else {
				const parcelasCorrigidas = this.dividirEmParcelas(totalValue, number_of_installmentsLet);

				for (let i = 0; i < number_of_installmentsLet; i++) {
					const obj = {
						number: i + 1,
						value: parcelasCorrigidas[i].toFixed(2),
					};

					arr.push(obj);
				}
			}
		} catch (err) {
		}

		const sum = arr.reduce((soma, objeto) => { return soma + parseFloat(objeto.value); }, 0);

		this.setState({ installmentsDetails: arr, agreementSum: sum });
	}

	dividirEmParcelas(valorTotal, numeroParcelas) {
		const valorParcela = Math.floor(valorTotal / numeroParcelas * 100) / 100;

		const resto = Math.round((valorTotal - valorParcela * numeroParcelas) * 100) / 100;

		const parcelas = Array.from({ length: numeroParcelas }, (_, index) => {
			let valorAtual = index === numeroParcelas - 1 ? valorParcela + resto : valorParcela;
			valorAtual = Math.floor(valorAtual * 100) / 100;
			return parseFloat(valorAtual.toFixed(2));
		});

		return parcelas;
	}

	calcMonthFine = (billing, dateUsedOnFine) => {
		let billingValue = parseFloat(billing.values.value);
		let diffInDays = moment(dateUsedOnFine).diff(moment(billing.due_date).add(3, 'hours').add(2, 'days'), 'days');
		let fineValue = billingValue * 0.1;
		let interestValue = 0.01 / 30 * diffInDays * billingValue;
		let finalBillingValue = billingValue + fineValue + interestValue;

		return finalBillingValue;
	}

	getMonthYear = (date) => {
		let partesData = date.split('-');

		let ano = parseInt(partesData[0]);
		let mes = parseInt(partesData[1]);

		let meses = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];

		let nomeMes = meses[mes - 1];

		return `${nomeMes}/${ano}`;
	}

	getStatus = (date, billing) => {
		const stylesDefaultTag = { color: '#fff', width: '104px', height: '24px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontFamily: 'Inter', fontSize: '13px', fontWeight: '400' };

		if (billing?.type === 'monthly' && billing?.termination_id) {
			return (
				<div className="tag" style={{ backgroundColor: '#5664D2' }}>
					Acerto de rescisão
				</div>
			)
		}

		if (billing?.receipts?.rent?.paid === true) {
			return (
				<div style={{ backgroundColor: '#1CBB8C', ...stylesDefaultTag }}>
					Pago
				</div>
			);
		} else if (moment().isSameOrBefore(billing?.due_date, 'day') && billing?.boleto) {
			return (
				<div style={{ backgroundColor: '#FCB92C', ...stylesDefaultTag }}>
					Em aberto
				</div>
			);
		} else if (billing?.bad_debt == true) {
			return (
				<div style={{ backgroundColor: '#FF3D60', ...stylesDefaultTag }}>
					Inadimplente
				</div>
			);
		} else if (moment().isAfter(moment(billing?.due_date).add(1, 'd'), 'day')) {
			return (
				<div style={{ backgroundColor: '#FF3D60', ...stylesDefaultTag }}>
					Atrasado
				</div>
			);
		} else {
			return (
				<div style={{ backgroundColor: '#DADBDD', ...stylesDefaultTag, color: '#505D69' }}>
					Pendente
				</div>
			);
		}
	}

	getTotalDebt = (values) => {
		let total = 0;

		if (values.start_date) {
			for (const bill of this.state.installmentsDetails) {
				total = total + parseFloat(bill.value)
			}
		} else {
			for (const bill of this.state.overdueBillings) {
				total = total + this.calcMonthFine(bill)
			}

			if (this.state.friendlyAgreement) {
				for (const bill of this.state?.billingsAgreement) {
					if (bill?.is_paid == false) {
						total = total + bill?.values?.value;
					}
				}
			}
		}

		return formatBRL(total)
	}

	handleAddOverdueBillings = async (billing_id, values) => {
		const billing = this.state.billings.find(obj => obj.id === billing_id);

		this.setState({ overdueBillings: [...this.state.overdueBillings, billing] });

		values.overdueBillings = [...this.state.overdueBillings, billing]
		this.generatePreview(values);
	}

	getContractStatus = () => {
		if (this.state?.contract) {
			const contract = this.state.contract;

			if (!contract.is_terminated && contract.scheduled_termination_date === null) {
				return '?typeNegociacao=vigentes'
			}

			if (contract.is_terminated) {
				return '?typeNegociacao=encerrados'
			}

			if (contract.scheduled_termination_date !== null) {
				return '?typeNegociacao=encerrando'
			}

			return ''
		}
	}

	calcularJurosTotal = (data) => {
		const valorSemJuros = this.state.overdueBillingsLastAgreement?.reduce((sum, item) => sum + item.values.value, 0);
		const valorComJuros = data?.reduce((valorComJurosParcelasSoma, item) => valorComJurosParcelasSoma + Number(item.value), 0);

		return valorComJuros - valorSemJuros;
	};

	getTypeBilling = (billing) => {
		if (billing.type === 'monthly') {
			return 'Aluguel mensal'
		}

		if (billing.type === 'termination') {
			return 'Rescisão'
		}

		if (billing.type === 'agreement') {
			return 'Acordo amigável'
		}
	}

	render() {
		const stylesDefaultTag = { color: '#fff', width: '104px', height: '24px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontFamily: 'Inter', fontSize: '13px', fontWeight: '400' };

		const RadioButton = ({ handleChange, handleBlur, values, field, name, text, style }) => {
			const isHTML = (str) => /<\/?[a-z][\s\S]*>/i.test(str);

			return (
				<Col md={12} style={{ marginLeft: "10px" }}>
					<Input id={field} type="radio" name={name} value={field}
						onChange={handleChange} onBlur={handleBlur} color="primary"
						checked={values[name] === field}
					/>
					<Label style={{ padding: "3px", marginLeft: 2, ...style }} for={field} >
						{isHTML(text) ? (
							<span dangerouslySetInnerHTML={{ __html: text }} />
						) : (
							text
						)}
					</Label>
				</Col>
			)
		}

		return (
			<React.Fragment>
				{this.state.success ? <Redirect to={{ pathname: `/negotiation/show/${this.state.contract?.proposition_id}`, state: { success: ["Acordo amigável criado com sucesso!"] }, }} /> : null}

				<BetterCircularProgress loading={this.state.loading}>
					<div className="page-content container-page friendly-agreement userShow">
						<Container fluid>
							<BetterAlerts errors={this.state.errors} />

							<div className='wrapper-100'>
								<div className='friendly-agreement-breadcrumb'>
									<Label className="col-form-label" style={{ color: '#505D69', fontSize: '24px', fontFamily: 'Inter' }}>Criar acordo</Label>

									<Breadcrumbs breadcrumbItems={[{ link: '/negotiation/index', title: 'Negociações' }, { link: `/negotiation/index`, title: 'Negociações e contratos' }, { link: `/negotiation/show/${this.state?.contract?.proposition_id}${this.getContractStatus()}`, title: `ID ${this.props.match.params.id.substring(0, 4)}...` }, { link: `/contracts/friendly-agreement/${this.props.match.params.id}`, title: 'Acordo amigável' }]} />
								</div>
							</div>

							<Formik initialValues={this.getInitialValues()} onSubmit={(values) => this.onSubmit(values)}>
								{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
									<form noValidate={true} autoComplete="off" onSubmit={handleSubmit} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
										{!this.state.lastAgreementSettled && (
											<Card className='card-friendly-agreement'>
												<div style={{ display: 'flex', flexDirection: 'column', width: '100%' }} className='p-4'>
													<div className='boxUser m-0 p-0' style={{ border: 'none', boxShadow: 'none' }}>
														<p className='p-type-header m-0 p-0'>ACORDO ANTERIOR</p>

														<p style={{ fontSize: 13, color: '#FF3D60', margin: '24px 0' }}>
															Atenção! Este usuário tem um acordo anterior já firmado. Ao firmar um novo acordo os valores pendentes do acordo anterior serão somados e renegociados dentro do novo acordo.
														</p>

														<div className='bodyAssets p-0' style={{ border: 'none' }}>
															<div className='boxInformation'>
																<p>Criada em</p>
																<b>{moment(this.state?.friendlyAgreement?.created_at).format('DD/MM/YY HH:mm:ss')}</b>
															</div>

															<div className='boxInformation'>
																<p>Criado por</p>

																<Link to={`/users/show/${this.state?.friendlyAgreement?.user?.id}`}>
																	<b style={{ color: '#4AA3FF' }}>
																		{this.state?.friendlyAgreement?.user?.name}
																		<FontAwesomeIcon icon={faUserSolid} style={{ marginLeft: 8.5, color: '#4AA3FF' }} />
																	</b>
																</Link>
															</div>
														</div>

														<div style={{ marginTop: 24 }}>
															<p style={{ color: '#505D69', fontSize: 16, fontWeight: '600', marginBottom: 12 }}>
																Contém no acordo
															</p>

															<div className='tabelaBillingsAcordo'>
																{this.state.overdueBillingsLastAgreement.map((billing) => (
																	<div className='billingColumn'>
																		<div>
																			{this.getTypeBilling(billing)}
																		</div>

																		<div style={{ textAlign: 'end' }}>
																			<h5>Vencido em</h5>
																			<p>{moment(billing.due_date).add(3, 'hours').format('DD/MM/YY')}</p>
																		</div>

																		<div style={{ textAlign: 'end' }}>
																			<h5>Valor total</h5>
																			<p>{formatBRL(billing?.values?.value)}</p>
																		</div>
																	</div>
																))}
															</div>

															<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '25px', gap: 16 }}>
																<div>
																	<p className='p-type-bold'>Total em atraso</p>
																	<p style={{ color: '#8590A5', fontSize: '19.5px', fontWeight: '600', fontFamily: 'Inter' }}>{formatBRL(this.state.overdueBillingsLastAgreement.reduce((sum, item) => sum + item.values.start_value, 0))}</p>
																</div>

																<div>
																	<p className='p-type-bold'>Juros</p>
																	<p style={{ color: '#FF3D60', fontSize: '19.5px', fontWeight: '600', fontFamily: 'Inter' }}>{formatBRL(this.calcularJurosTotal(this.state.friendlyAgreement?.installments_details))}</p>
																</div>

																<div>
																	<p className='p-type-bold'>Total do acordo</p>
																	<p style={{ color: '#EFA200', fontSize: '19.5px', fontWeight: '600', fontFamily: 'Inter' }}>{formatBRL(this.state.friendlyAgreement?.installments_details.reduce((sum, item) => sum + Number(item.value), 0))}</p>
																</div>
															</div>
														</div>

														<div style={{ marginTop: 24 }}>
															<p style={{ color: '#505D69', fontSize: 16, fontWeight: '600', marginBottom: 12 }}>
																Prévia das parcelas
															</p>

															<div className='tabelaBillingsAcordo'>
																{this.state?.billingsAgreement?.map((billing) => (
																	<div className='billingColumn'>
																		<div>
																			<div>
																				Parcela {billing?.number}
																			</div>

																			<div style={{ marginTop: 4 }}>
																				{this.getStatus(billing.due_date, billing)}
																			</div>
																		</div>

																		<div>
																			<h5>Vence em</h5>
																			<p>{moment(billing?.due_date).add(3, 'hours').format('DD/MM/YY')}</p>
																		</div>

																		<div>
																			<h5>Valor total</h5>
																			<p>{formatBRL(parseFloat(billing?.values?.value))}</p>
																		</div>
																	</div>
																))}
															</div>

															<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '25px', gap: 16 }}>
																<div>
																	<p className='p-type-bold'>Total pago</p>
																	<p className='p-type-green' style={{ fontSize: '19.5px', fontWeight: '600', fontFamily: 'Inter' }}>
																		{formatBRL(this.state?.billingsAgreement.reduce((sum, item) => sum + (item?.is_paid === true ? parseFloat(item?.values?.value) : 0), 0))}
																	</p>
																</div>

																<div>
																	<p className='p-type-bold'>Total a pagar</p>
																	<p style={{ color: '#EFA200', fontSize: '19.5px', fontWeight: '600', fontFamily: 'Inter' }}>
																		{formatBRL(this.state?.billingsAgreement.reduce((sum, item) => sum + (item?.is_paid == false ? parseFloat(item?.values?.value) : 0), 0))}
																	</p>
																</div>
															</div>
														</div>
													</div>
												</div>
											</Card>
										)}

										<Card className='card-friendly-agreement'>
											<div style={{ display: 'flex', flexDirection: 'column', width: '100%' }} className='p-4'>
												<div>
													<p className='p-type-header'>SELECIONAR FATURAS</p>

													<Label className="col-form-label">Busque e selecione faturas para o acordo</Label>
													<div style={{ position: 'relative' }}>
														<select style={{ background: "#FFF", height: "38px" }} name='overdue_billings'
															onChange={(e) => { this.handleAddOverdueBillings(e.target.value, values); }} handleBlur={handleBlur} value={values.overdue_billings} className="custom-select">
															<option key="" value="">Selecione uma ou mais faturas</option>
															{this.state.billings.map((billing, index) => {
																if (billing.type == "agreement" || this.state.friendlyAgreement?.overdue_billings.includes(billing?.id)) return
																else if (!this.state.overdueBillings.some(obj => obj.id === billing.id)) return <option key={billing.id} value={billing.id}>{this.getPaymentInfos(billing)}</option>
																else return;
															})}
														</select>

														<FontAwesomeIcon icon={faChevronDown} style={{ position: 'absolute', right: '18px', top: '50%', transform: 'translateY(-50%)', pointerEvents: 'none' }} />
													</div>

													<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginTop: '15px' }}>
														{(this.state.billingsAgreement.length > 0 && this.state?.billingsAgreement.reduce((sum, item) => sum + (item?.is_paid == false ? parseFloat(item?.values?.value) : 0), 0) > 0) && (
															<div className='overdue-billing-box'>
																<div style={{ width: '70%', display: 'flex', alignItems: 'center' }}>
																	<p className='p-type-1 m-0 p-0'>Acordo anterior</p>

																	<div style={{ marginLeft: '8px', backgroundColor: '#FF3D60', ...stylesDefaultTag }}>
																		Inadimplente
																	</div>
																</div>

																<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end', marginRight: 100 }}>
																	<p className='p-type-1'>Valor pendente total</p>
																	<p className='p-type-bold'>
																		{formatBRL(this.state?.billingsAgreement.reduce((sum, item) => sum + (item?.is_paid == false ? parseFloat(item?.values?.value) : 0), 0))}
																	</p>
																</div>
															</div>
														)}

														{this.state.overdueBillings.map((obj, i) => (
															<div className='overdue-billing-box'>
																<div style={{ width: '70%' }}>
																	<p className='p-type-1'>Aluguel mensal</p>
																	<div style={{ display: 'flex', alignItems: 'center' }}>
																		<p className='p-type-blue '>	{this.getMonthYear(obj.due_date)}</p>
																		<div style={{ marginLeft: '8px' }}>
																			{this.getStatus(obj.due_date, obj)}
																		</div>
																	</div>
																</div>

																<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end' }}>
																	<p className='p-type-1'>Vence em</p>
																	<p className='p-type-bold'>{moment(obj.due_date).add(3, 'hours').format('DD/MM/YYYY')}</p>
																</div>

																<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end' }}>
																	<p className='p-type-1'>Valor total</p>
																	<p className='p-type-bold'>{formatBRL(obj.values.value)}</p>
																</div>

																<div>
																	{!obj.applied && <FontAwesomeIcon icon={faXmark} style={{ color: '#343A40', fontSize: '20px', cursor: 'pointer', marginLeft: '16px', marginRight: '16px' }} onClick={() => this.removeBillingOfAgreement(obj, values)} />}
																</div>
															</div>
														))}
													</div>
												</div>

												<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '25px' }}>
													<div style={{ width: '45%' }}>
														<p className='p-type-1'>Nesse valor já está incluso multa e juros dos pagamentos atrasados selecionados calculando a data da primeira parcela.</p>
													</div>

													<div>
														<p className='p-type-bold'>Débito total</p>
														<p className='p-type-green'>{this.getTotalDebt(values)}</p>
													</div>
												</div>
											</div>
										</Card>

										<Card className='card-friendly-agreement'>
											<div className="p-4" style={{ borderRadius: '6px' }}>
												<p className='p-type-header' style={{ marginBottom: '24px' }}>GERAL</p>

												<p className={'p-type-1'} style={{ marginBottom: '12px', color: '#343A40' }}>
													Quantidade de parcelas
												</p>

												<div style={{ display: 'flex', width: '100%', marginBottom: '16px', alignItems: 'center' }}>
													{this.state.number_of_installments.map((i, index) =>
														<div>
															<Col md={12} style={{ marginLeft: "10px", display: 'flex', alignItems: 'center' }}>
																<Input id={i.id} type="radio" name='number_of_installments' value={i.id}
																	onChange={(e) => {
																		setFieldValue('number_of_installments', i.id);
																		this.generatePreview({
																			number_of_installments: i.id,
																			payment_type: values.payment_type,
																			start_date: values.start_date,
																			first_installment_date: values.first_installment_date,
																			first_installment: values.first_installment
																		})
																	}}
																	onBlur={handleBlur} color="primary"
																	checked={values['number_of_installments'] === i.id}
																	style={{ marginBottom: 6 }}
																/>

																<Label style={{ padding: "3px", marginLeft: 2, marginBottom: 0 }} for={i.id}>{i.label}</Label>
															</Col>
														</div>
													)}

													<div>
														<Col md={12} style={{ marginLeft: "10px", display: 'flex', alignItems: 'center' }}>
															<Input type="radio" name='number_of_installments' value={'custom'}
																onChange={(e) => setFieldValue('number_of_installments', 'custom')}
																onBlur={handleBlur} color="primary" style={{ marginBottom: 6 }}
																checked={values['number_of_installments'] === 'custom'}
															/>

															<TextField name="custom_installment" placeholder="até 36" disabled={values['number_of_installments'] != 'custom'}
																type="text" variant="outlined" value={values.custom_installment}
																onBlur={handleBlur} onChange={e => {
																	const value = e.target.value;

																	if (/^\d*$/.test(value)) {
																		let numericValue = parseInt(value, 10);

																		if (numericValue > 36) {
																			numericValue = 36;
																		}

																		// Limita ate 36
																		if (numericValue <= 36) {
																			handleChange({
																				target: {
																					name: "custom_installment",
																					value: numericValue || value
																				}
																			});

																			this.generatePreview({
																				number_of_installments: numericValue,
																				payment_type: values.payment_type,
																				start_date: values.start_date,
																				first_installment_date: values.first_installment_date,
																				first_installment: values.first_installment
																			});
																		}
																	}
																}}
															/>
														</Col>
													</div>

												</div>

												<p className={'p-type-1'} style={{ marginBottom: '12px', color: '#343A40' }}>
													Definir valores
												</p>

												<div style={{ display: 'flex', width: '100%', marginBottom: '20px' }}>
													<Col md={3} style={{ marginLeft: "10px" }}>
														<Input id='payment_type' type="radio" name='payment_type' value="DIVIDED_EQUALLY"
															onChange={handleChange} onClick={() => { this.setState({ payment_type: 'DIVIDED_EQUALLY' }); this.generatePreview({ ...values, payment_type: 'DIVIDED_EQUALLY' }) }} onBlur={handleBlur} color="primary"
															checked={values['payment_type'] === "DIVIDED_EQUALLY"}
														/>

														<Label style={{ padding: "3px", marginLeft: 2 }} onClick={() => { this.setState({ payment_type: 'DIVIDED_EQUALLY' }); this.generatePreview(values) }} for='payment_type'>
															Dividir valores igualmente
														</Label>
													</Col>

													<Col md={12} style={{ marginLeft: "10px" }}>
														<Input id='payment_type' type="radio" name='payment_type' value="EDITED_VALUES"
															onChange={handleChange} onClick={() => {
																this.setState({ payment_type: 'EDITED_VALUES' }); this.generatePreview({ ...values, payment_type: 'EDITED_VALUES' }); if (values.number_of_installments === 1) {
																	setFieldValue('number_of_installments', 2)
																}
															}} onBlur={handleBlur} color="primary"
															checked={values['payment_type'] === "EDITED_VALUES"}
														/>

														<Label style={{ padding: "3px", marginLeft: 2 }} onClick={() => {
															this.setState({ payment_type: 'EDITED_VALUES' }); this.generatePreview(values); if (values.number_of_installments === 1) { setFieldValue('number_of_installments', 2) }
														}} for='payment_type'>
															Definir a primeira parcela
														</Label>
													</Col>
												</div>

												{values.payment_type === 'EDITED_VALUES' && (
													<>
														<p style={{ fontFamily: 'Inter', fontSize: '16px', lineHeight: '19px', color: '#505D69', fontWeight: '600', margin: '10px 0px' }}>Defina a primeira parcela</p>

														<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
															<div style={{ width: '30%' }}>
																<Label className="col-form-label">Parcela 01</Label>
																<TextField name="first_installment" type="text" variant="outlined" value={values.first_installment} onBlur={handleBlur} onChange={(e) => { handleChange(formatBRLInput(e)); this.generatePreview({ ...values, first_installment: e.target.value }) }}
																	helperText={touched.first_installment && errors.first_installment} error={Boolean(touched.first_installment && errors.first_installment)} />
															</div>

															<div style={{ width: '50%' }}>
																<Label className="col-form-label">Data da parcela 01</Label>

																<TextField name="first_installment_date" placeholder="00/00/0000"
																	type="date" variant="outlined" value={values.first_installment_date} onBlur={handleBlur} onChange={(e) => { handleChange(e); this.generatePreview({ ...values, first_installment_date: e.target.value }) }}
																	inputProps={{
																		min: formatDate({ date: moment().add(1, 'days'), format: 'YYYY-MM-DD' })
																	}}
																	helperText={touched.first_installment_date && errors.first_installment_date} error={Boolean(touched.first_installment_date && errors.first_installment_date)} />
															</div>
														</div>
													</>
												)}

												{(values.payment_type !== 'DIVIDED_EQUALLY') && <p style={{ fontFamily: 'Inter', fontSize: '16px', lineHeight: '19px', color: '#505D69', fontWeight: '600', marginBottom: '10px', marginTop: '30px' }}>Defina as outras parcelas</p>}

												<div style={{ width: '100%' }}>
													{(values.payment_type === 'DIVIDED_EQUALLY') && <h3 style={{ marginTop: '10px', color: '#505D69', fontFamily: 'Inter', fontWeight: '600' }}>Defina as parcelas</h3>}

													<Label className="col-form-label">{values.payment_type === 'DIVIDED_EQUALLY' ? 'Data da primeira parcela' : 'Data da parcela 02'}</Label>

													<TextField className="mb-2" style={{ background: "#FFF", height: "40px" }} name={'start_date'} placeholder="00/00/0000"
														type="date" variant="outlined" value={values.start_date} InputLabelProps={{ shrink: true }}
														inputProps={{
															min: formatDate({ date: moment().add(2, 'days'), format: 'YYYY-MM-DD' })
														}}
														onChange={(e) => { handleChange(e); this.generatePreview({ ...values, start_date: e.target.value }) }} onBlur={handleBlur} />

													<Label className="col-form-label" style={{ color: '#505D69', fontFamily: 'Inter', fontWeight: 400 }}>
														<FontAwesomeIcon color='#EFA200' icon={faCalendarDays} style={{ paddingRight: '14.5px' }} />
														Será usado o mesmo dia do mês para o vencimento das outras parcelas
													</Label>

													<br />

													<Label className="col-form-label" style={{ color: '#505D69', fontFamily: 'Inter', fontWeight: 400 }}>
														<FontAwesomeIcon color='#EFA200' icon={faCircleExclamation} style={{ paddingRight: '14.5px' }} />
														O prazo máximo para assinatura da confissão de dívida será 2 dias antes do primeiro vencimento
													</Label>
												</div>


												{(this.state.overdueBillings.length > 0 || this.state.friendlyAgreement) && values.start_date !== '' ? (
													<div>
														<h3 style={{ marginTop: '10px', color: '#505D69', fontFamily: 'Inter', fontWeight: '600' }}>Prévia das parcelas</h3>

														<div style={{ width: '100%' }}>
															{this.state.installmentsDetails.map((obj, i) => (
																<div style={{ width: '100%', display: 'flex', backgroundColor: i % 2 !== 0 ? '#F1F5F7' : '', justifyContent: 'space-between', alignItems: 'center', padding: '16px', border: '1px solid  #DADBDD', borderRadius: i === this.state.installmentsDetails.length - 1 ? ' 0px 0px 4px 4px' : '' }}>
																	<div style={{ display: 'flex', flexDirection: 'column' }}>
																		<p className={'p-type-1'} style={{ marginBottom: '0px', color: '#343A40' }}>Parcela {obj.number}</p>
																	</div>

																	<div style={{ display: 'flex' }}>
																		<div>
																			<p className={'p-type-1'} style={{ marginBottom: '0px', color: '#343A40' }}>Vence em {obj?.due_date ? moment(obj.due_date).format('DD/MM/YYYY') : moment(values.start_date).add(i, 'months').format('DD/MM/YYYY')}</p>
																		</div>

																		<div style={{ marginLeft: '20px' }}>
																			<p className={'p-type-1'} style={{ marginBottom: '0px', color: '#343A40', fontWeight: '600' }}>{formatBRL(obj.value)}</p>
																		</div>
																	</div>
																</div>
															))}
														</div>
													</div>
												) : (
													<div>
														<h3 style={{ marginTop: '10px', color: '#505D69', fontFamily: 'Inter', fontWeight: '600' }}>Prévia das parcelas</h3>

														<div style={{ width: '100%' }} className='no-prev-box'>
															<p className='p-type-3'>Nenhuma fatura selecionada</p>
														</div>
													</div>
												)}
											</div>
										</Card>

										<Card className='card-friendly-agreement'>
											<div className='p-4'>
												<RadioButton handleChange={handleChange} handleBlur={handleBlur} values={values} field='true' name='confirm_agreement' text='Confirmo em criar esse acordo e sei que esta ação é <span style="color: #FF3D60">irreversível.</span>' style={{ marginBottom: '0px' }} />
											</div>
										</Card>

										<Card className='card-friendly-agreement p-4' style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
											<div style={{ display: 'flex', alignItems: 'center' }}>
												{values.payment_type == 'EDITED_VALUES' && (
													<>
														<div>
															<p className='p-type-bold'>Entrade de</p>
															<p className='p-type-green'>{values.first_installment}</p>
														</div>

														<p className='plus-divisor' style={{ color: '#8590A5', margin: '0px 20px' }}>+</p>
													</>
												)}

												<div>
													<p className='p-type-bold'>Parcelas de</p>
													<p className='p-type-green'>{this.getInstallmentValue(values)}</p>
												</div>

												<p className='plus-divisor' style={{ color: '#8590A5', margin: '0px 20px' }}></p>

												<div>
													<p className='p-type-bold'>Por</p>
													<p className='p-type-2'>{values?.number_of_installments == 'custom' ? values?.custom_installment : values?.number_of_installments} meses</p>
												</div>

												<p className='plus-divisor' style={{ color: '#8590A5', margin: '0px 20px' }}></p>

												{values.start_date && (
													<div>
														<p className='p-type-bold'>Prazo assinatura</p>
														<p className='p-type-2' style={{ color: '#EFA200' }}>{moment(values.start_date).subtract(2, 'days').format('DD/MM')} <b style={{ fontSize: '13px' }}>{moment(values.start_date).subtract(2, 'days').format('/YYYY')}</b></p>
													</div>
												)}
											</div>

											<CardBody className="row" style={{ flex: 'none' }}>
												<button className="go-back-1" type='button' onClick={() => window.location.href = `/negotiation/show/${this.state?.contract?.proposition_id}${this.getContractStatus()}`}>
													<FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: '15px' }} />VOLTAR
												</button>

												<button disabled={this.disabledConditions(values)} className="continue-btn-1" style={{ backgroundColor: this.disabledConditions(values) ? '#DADBDD' : '', border: this.disabledConditions(values) ? '#DADBDD' : '0px', marginLeft: '12px' }} type='submit'>
													ENVIAR CONFISSÃO
													<FontAwesomeIcon icon={faCheck} style={{ fontSize: '15px' }} />
												</button>
											</CardBody>
										</Card>
									</form>
								)}
							</Formik>
						</Container>
					</div>
				</BetterCircularProgress>
			</React.Fragment>
		)
	}
}

export default FriendlyAgreement;