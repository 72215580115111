import React, { Component } from 'react';
import { Container, Card, CardBody, Alert, Button } from "reactstrap";
import BetterCircularProgress from '../../components/BetterCircularProgress';
import { get as getContract } from '../../crud/contracts.crud';
import { get as getBillings } from '../../crud/billings.crud';
import { Link, Redirect } from "react-router-dom";
import Breadcrumbs from '../../components/Breadcrumb';
import moment from 'moment';
import BetterAlerts from '../../components/BetterAlerts';
import { restoreContract } from '../../crud/contracts.crud';

class ContractRestore extends Component {

	state = {
		loading: false,
		success: false,
		submitted: false,
		errors: [],
		message: null,
		data: null,
		canRestore: false,
	}

	componentDidMount = () => {
		this.setState({ loading: true });

		if (this.props.location.state) {
			if (this.props.location.state.success) {
				this.setState({ success: this.props.location.state.success });
			}
		}

		getContract(this.props.match.params.id).catch(err => {
			alert('An error occurred, please contact the support.');
		}).then(async res => {
			if (res?.status == 200) {
				if (res.data) {
					this.setState({ data: res.data.data });
					let contract = res.data.data;

					const params = { contract_id: contract?.entity?.id, $sort: { ['due_date']: -1 }, type: 'termination' };
					await getBillings(params).then(resultBilling => {
						if (resultBilling?.status == 200) {
							if (resultBilling?.data?.data?.length > 0) {
								if (resultBilling.data.data[0].type == 'termination' && !resultBilling.data.data[0].is_paid) {
									if (contract?.entity?.approved_end_date
										& moment(contract.entity?.approved_end_date).add(45, 'days').isSameOrAfter(moment())) {
										this.setState({ canRestore: true })
									}
								}
							}
						}
					})
				}
			}
			this.setState({ loading: false });
		});
	};

	handleRestoreContract = () => {
		this.setState({ submitted: true });

		restoreContract(this.props.match.params.id).catch(err => {
			alert('An error occurred, please contact the support.');
		}).then(async res => {
			if (res?.status == 200) {
				// this.setState({ submitted: false, success: true }); 
				this.setState({ submitted: false, success: !Boolean(res.data.error), errors: res.data.error ? [res.data.error] : [] });
			}
		}).finally(() => this.setState({ submitted: false }));
	};

	render() {
		return (
			<React.Fragment>
				<BetterCircularProgress loading={this.state.loading}>
					<div className="page-content container-page">
						<Container fluid>
							<Breadcrumbs breadcrumbItems={[{ link: '/contracts/index', title: 'Contrato' }, { link: `/contracts/show/${this.state.data?.entity?.id}`, title: 'Visualizar' }, { link: `/contracts/restore/${this.state.data?.entity?.id}`, title: 'Restaurar' }]} />

							<BetterAlerts errors={this.state.errors} />

							{this.state.success ?
								<Redirect to={{ pathname: `/contracts/show/${this.props.match.params.id}`, state: { success: ['Contrato restaurado com sucesso!'] } }} />
								: null
							}

							<Card>
								<CardBody className="row" style={{ height: '70vh' }}>
									<div className='col-lg-12'>
										<h1 style={{ fontSize: '26px', color: '#505D69', fontWeight: '600', marginBottom: '24px' }}>Restaurar contrato</h1>

										<p style={{ color: '#505D69', fontSize: '13px', marginBottom: '10px', fontWeight: '600' }}>Ao restaurar este contrato ele voltará ao vigor, isso significa:</p>

										<ul>
											<li style={{ color: '#505D69', fontSize: '13px', marginBottom: 0, fontWeight: '600' }}>Todas as <span style={{ color: '#5664D2' }}>cobranças</span> deletadas serão <span style={{ color: '#5664D2' }}>restauradas</span>;</li>
											<li style={{ color: '#505D69', fontSize: '13px', marginBottom: 0, fontWeight: '600' }}>Enquanto estiver em vigor, <span style={{ color: '#5664D2' }}>todo mês</span> uma <span style={{ color: '#5664D2' }}>nova cobrança será enviada</span> aos inquilinos;</li>
										</ul>

										<p style={{ color: '#505D69', fontSize: '13px', marginBottom: '10px', fontWeight: '600' }}>Você tem certeza que deseja restaurar este contrato?</p>
									</div>

									<div className='col-lg-12 align-self-end'>
										<Link className="btn btn-danger ml-3" to={`/contracts/show/${this.state.data?.entity?.id}`}>
											<i className="ri-close-line align-middle mr-2"></i> Cancelar
										</Link>

										{moment(this.state.data?.entity?.approved_end_date).add(45, 'days').isSameOrAfter(moment()) ? (
											<Button className="btn btn-success ml-3" onClick={() => this.handleRestoreContract()} >
												Restaurar contrato <i className={`mr-2 align-middle ${this.state.submitted ? "fas fa-sync fa-spin" : "ri-open-arm-line align-middle ml-2"}`}></i>
											</Button>
										) : null}
									</div>
								</CardBody>
							</Card>
						</Container>
					</div>
				</BetterCircularProgress>
			</React.Fragment >
		)
	}
}

export default ContractRestore;