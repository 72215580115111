import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { get as getUser } from '../../crud/users.crud';
import { get as getContract } from '../../crud/contracts.crud';
import { formatBRL } from "../../helpers/Utils";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import moment from "moment";
import { Button } from "reactstrap";
import { createRentBoleto, getById as getBillingById } from "../../crud/billings.crud";
import smile from '../../assets/images/icons-custom/face-smile.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faFileContract } from "@fortawesome/pro-solid-svg-icons";
import { faChevronRight, faRedo } from "@fortawesome/pro-regular-svg-icons";

export default class DataTableInadimplente extends Component {
	constructor() {
		super();

		this.state = {
			property: {},
			data: null,
			rows: 6,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: 'due_date',
			sortOrder: 1,
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters !== this.props.filters) {
			this.fetchData();
		}
	}

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = async (event) => {
		this.setState({
			loading: true,

		});
		if (event.data) {
			event.data = null;
		}

		let inadimplenteDataTable = {
			rows: event.rows,
			totalRecords: this.state.totalRecords,
			data: this.data,
			first: event.first,
			last: event.rows + event.first,
			loading: this.state.loading,
			sortField: this.state.sortField,
			sortOrder: this.state.sortOrder,
		};

		localStorage.setItem("inadimplenteDataTable", JSON.stringify(inadimplenteDataTable));

		this.setState({
			...this.state,
			rows: event.rows,
		});
		this.fetchData(event.rows, event.first);
	};

	fetchData = async (limit, skip) => {
		this.setState({ loading: true });

		let filter = {
			due_date_lte: moment().subtract(1, 'days').format('YYYY-MM-DD')
		};

		if (this.props.filters) {
			console.log(this.props.filters);
			const { billing_type, billing_status } = this.props.filters;

			if (billing_type !== 'all') {
				filter = {
					...filter,
					type: billing_type
				}
			}

			if (billing_status === 'overdue') {
				filter = {
					...filter,
					due_date_gte: moment().subtract(20, 'days').format('YYYY-MM-DD')
				}
			}

			if (billing_status === 'bad_debt') {
				filter.due_date_lte = moment().subtract(20, 'days').format('YYYY-MM-DD');
				filter.bad_debt = true;
			}
		}

		let options = {
			$limit: limit ? limit : this.state.rows,
			$skip: skip != null ? skip : this.state.first,
			$sort: { [this.state.sortField]: this.state.sortOrder },
			...filter
		};

		return this.props.fetchEvent(options).then(async (res) => {
			this.props.updateCounter(res?.data?.total);

			if (res.data.data.length == 0) {
				this.setState({
					...this.state,
					data: [],
					totalRecords: res.data.total,
					first: skip,
					last: skip + res.data.limit,
					loading: false
				});
			}

			await res.data.data.map(async (obj, i) => {
				if (obj.contract_id) {
					obj.loading_contract = true;
					await getContract(obj.contract_id).catch(err => {
						obj.contract = null;
						obj.loading_contract = false;
					}).then(res => {
						if (res?.data?.data) {
							obj.contract = res.data.data;
						}
					});
				}

				this.setState({
					...this.state,
					data: res.data.data,
					totalRecords: res.data.total,
					first: skip,
					last: skip + res.data.limit,
					loading: false
				});
			});
		}).finally(() => {
			this.setState({ loading: false });
		});
	};

	sortTable = async (e) => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder, loading: true }, () => {
			let inadimplenteDataTable = {
				rows: this.state.rows,
				totalRecords: this.state.totalRecords,
				first: this.state.first,
				last: this.state.last,
				loading: this.state.loading,
				sortField: this.state.sortField,
				sortOrder: this.state.sortOrder,
				data: this.state.data
			};
			localStorage.setItem("inadimplenteDataTable", JSON.stringify(inadimplenteDataTable));

			this.fetchData();
		});
	};

	getValue = (obj) => {
		return (
			<div style={{ textAlign: "center" }}>
				<p>{formatBRL(obj || 0)}</p>
			</div>
		);
	};

	getUserName = (obj, type) => {
		if (type === "landlord" && obj?.landlord) {
			return (
				<div style={{ textAlign: "start" }}>
					<Link style={{ color: "var(--Azul-500, #4AA3FF)", fontFamily: "Lato", fontSize: "14px", fontStyle: "normal", fontWeight: 400, lineHeight: "normal" }} to={`/users/show/${obj.landlord?.id}`}>
						{obj.landlord?.name}
					</Link>
				</div>
			);
		}

		if (type === "tenant" && obj?.tenant) {
			return (
				<div style={{ textAlign: "start" }}>
					<Link style={{ color: "var(--Azul-500, #4AA3FF)", fontFamily: "Lato", fontSize: "14px", fontStyle: "normal", fontWeight: 400, lineHeight: "normal" }} to={`/users/show/${obj.tenant?.id}`}>{obj.tenant?.name}</Link>
				</div>
			);
		}

		if (type === "contract" && obj?.contract) {
			return (
				<div style={{ textAlign: "-webkit-center" }}>
					<FontAwesomeIcon icon={faFileContract} style={{ color: "#4AA3FF", marginRight: 10 }} />
					<Link style={{ color: "var(--Azul-500, #4AA3FF)", fontFamily: "Lato", fontSize: "14px", fontStyle: "normal", fontWeight: 400, lineHeight: "normal" }} to={`/negotiation/show/${obj?.contract?.entity?.proposition_id}`}>{obj?.contract?.entity?.friendly_code}</Link>
					<div className="d-flex flex-row" style={{ width: obj?.contract?.entity?.plan?.name === 'WARRANTY' ? '94px' : '84px', fontSize: '13px', borderRadius: 2, height: '24px', marginTop: 4, alignItems: 'center', backgroundColor: obj?.contract?.entity?.plan?.name === 'WARRANTY' ? '#5664D2' : '#4AA3FF' }}>
						<p className="col-form-label text-white" style={{ fontFamily: 'Inter', padding: 0 }}>{obj?.contract?.entity?.plan?.name === 'WARRANTY' ? 'Pagamento' : 'Completa'}</p>
					</div>
				</div>
			);
		}

		return (
			<div style={{ textAlign: "start" }}>
				Não encontrado
			</div>
		);
	};

	getCity = (obj) => {
		if (obj?.property) {
			return (
				<div style={{ textAlign: "center" }}>
					<Link to={`/announcements/show/${obj.property?.id}`}>{obj.property?.address?.city}</Link>
				</div>
			);
		} else {
			return (
				<div style={{ textAlign: "center" }}>
					Não encontrado
				</div>
			)
		}
	};

	getNeighbourhood = (obj) => {
		return (
			<div style={{ textAlign: "center" }}>
				<Link to={`/announcements/show/${obj.property?.id}`}>
					{obj.property?.address?.neighbourhood}
				</Link>
			</div>
		);
	};

	getStatus = (obj) => {

		const dueDate = moment(obj?.due_date).add(3, "hours").format('YYYYMMDD');
		const currentDate = moment().add(3, "hours").format('YYYYMMDD');

		if (obj?.receipts?.rent?.paid === true) {
			return (
				<div style={{ gap: 4, display: 'flex', flexDirection: 'column' }}>
					<div className="bg-success d-flex flex-row" style={{ width: '98px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center' }}>
						<p className="col-form-label text-white">{obj?.receipts?.rent?.paid ? 'Paga' : 'N/A'}</p>
					</div>
					{this.getStatusWarranty(obj)}
				</div>
			)
		} else if (obj?.bad_debt) {
			return (
				<div style={{ gap: 4, display: 'flex', flexDirection: 'column' }}>
					<div className="bg-danger d-flex flex-row" style={{ width: '120px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center' }}>
						<p className="col-form-label text-white">Inadimplente</p>
					</div>
					{this.getStatusWarranty(obj)}
				</div>
			)
		} else if (currentDate > dueDate) {
			return (
				<div style={{ gap: 4, display: 'flex', flexDirection: 'column' }}>
					<div className="bg-danger d-flex flex-row" style={{ width: '100px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center' }}>
						<p className="col-form-label text-white">Atrasado</p>
					</div>
					{this.getStatusWarranty(obj)}
				</div>
			)
		} else if (moment().isBefore(obj?.due_date)) {
			return (
				<div style={{ gap: 4, display: 'flex', flexDirection: 'column' }}>
					<div className="bg-warning d-flex flex-row" style={{ width: '131px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center' }}>
						<p className="col-form-label text-white">Em aberto</p>
					</div>
					{this.getStatusWarranty(obj)}
				</div>
			)
		} else {
			return (
				<div className="bg-secondary d-flex flex-row" style={{ width: '98px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center' }}>
					<p className="col-form-label text-white">Aguardando</p>
				</div>
			)
		}
	};

	getStatusWarranty = (obj) => {
		if (obj?.receipts?.rent?.paid_for_insurer == true) {
			return (
				<div className="d-flex flex-row" style={{ width: '85px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center', backgroundColor: '#4AA3FF' }}>
					<p className="col-form-label text-white">{obj?.receipts?.rent?.paid_for_insurer ? 'Garantido' : 'N/A'}</p>
				</div>
			)
		}
	};

	getDateFormatted = (date) => {
		return (
			<p>
				{date ? moment(date).add(1, 'day').format("DD/MM/YYYY") : '-'}
			</p>
		);
	};

	getStatusProposition = (obj) => {
		let status = '';
		switch (obj.status) {
			case 'FIRST_PROPOSAL':
				status = 'Criada Admin';
				break;
			case 'AWAITING_SIGN':
				status = 'Aguardando sinal';
				break;
			case 'FINALIZED':
				status = 'Finalizado';
				break;
			default:
				status = obj.status;
				break;
		}
		return (
			<div style={{ textAlign: "center" }}>
				<p>{status}</p>
			</div>
		);
	}

	onBoletoCreation = async (obj) => {
		this.setState({ ...this.state, loading: true, });

		createRentBoleto(obj.id, {
			contract_id: obj.contract_id,
			landlord_id: obj.landlord_id,
		}).then((data) => {
			setTimeout(() => {
				getBillingById(obj.id).then((data) => {
					navigator.clipboard.writeText(data.data?.boleto?.pdf_url);
					this.setState({ ...this.state, loading: false, });
				});
			}, 5000);
		}).catch(err => {
			this.setState({ ...this.state, boletoError: true, loading: false, });

			setTimeout(() => {
				this.setState({ ...this.state, boletoError: false, });
			}, 3000);
		}).finally(() => {
			this.setState({ ...this.state, loading: false, });
		})
	}

	columnOptionsCrud = (obj) => {
		if (this.props.crudUrl) {
			return (
				<div style={{ textAlign: "center" }}>
					<Button className="mr-2" style={{ background: 'transparent', borderColor: 'transparent' }} onClick={() => this.onBoletoCreation(obj)}>
						<FontAwesomeIcon icon={faRedo} style={{ color: '#5664D2' }} />
					</Button>
				</div>
			);
		} else {
			return "a";
		}
	};


	seeFile = (obj) => {
		if (obj?.boleto) {
			return (
				<a href={obj?.boleto?.pdf_url} target="_blank" style={{ background: 'transparent', borderColor: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => { navigator.clipboard.writeText(obj?.boleto?.pdf_url) }}>
					<FontAwesomeIcon icon={faFile} style={{ color: '#5664D2' }} />
				</a>
			)
		}
	}

	seeBill = (obj) => {
		if (this.props.crudUrl) {
			return (
				<div style={{ textAlign: "center" }}>
					<Link to={`/billing/show/${obj.id}`} >
						<FontAwesomeIcon icon={faChevronRight} style={{ color: "#5664D2" }} />
					</Link>
				</div>
			);
		} else {
			return "a";
		}
	};

	export() {
		this.dt.exportCSV();
	}

	emptyMessageReturn() {
		return (
			<div className="boxImage">
				<img src={smile} alt="smile" width={48} height={48} />

				<p className="subtitleNoItems">{this?.props?.type === 'agreement' ? 'Nenhum acordo em inadimplência encontrado' : 'Nenhuma cobrança em inadimplência encontrada'}</p>
			</div>
		)
	}

	getStatusProposition = (obj) => {
		let status = '';
		switch (obj?.details?.warranty_type?.trim()) {
			case 'SECURITY_DEPOSIT':
			case 'SECURITY_DEPOSITY':
				status = 'Caução';
				break;
			case 'FREE':
				status = 'Gratuito';
				break;
			case 'WARRANTY':
				status = 'Garantia';
				break;
			case 'WARRANTY_GUARANTEE_DAMAGE':
				status = 'Garantia de danos';
				break;

			default:
				status = obj?.status;
				break;
		}
		if (!status) {
			switch (obj?.property?.warranty?.type.trim()) {
				case 'SECURITY_DEPOSIT':
				case 'SECURITY_DEPOSITY':
					status = 'Caução';
					break;
				case 'FREE':
					status = 'Gratuito';
					break;
				case 'WARRANTY':
					status = 'Garantia';
					break;
				case 'WARRANTY_GUARANTEE_DAMAGE':
					status = 'Garantia de danos';
					break;

				default:
					status = obj?.status;
					break;
			}
		}

		return (
			<div style={{ textAlign: 'center' }}>
				<p>{status}</p>
			</div>
		);
	};

	render() {
		return (
			<div style={{ position: 'relative' }}>
				<DataTable ref={(el) => this.dt = el} value={this.state.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={[6, 15, 30]} first={this.state.first} last={this.state.last}
					totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} responsive={true} loadingIcon={`fas fa-sync fa-spin`} onSort={(e) => this.sortTable(e)}
					paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" emptyMessage={this.emptyMessageReturn}
					currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" sortField={this.state.sortField} sortOrder={this.state.sortOrder} id="custom-table-1" pageLinkSize={3}>

					{this.props.labels.find(obj => obj.label === 'Vence em').isActive ? <Column sortable field="due_date" body={(obj) => this.getDateFormatted(obj?.due_date)} header="Vence em" style={{ width: '106px', padding: 16, height: 80 }} /> : null}

					{this.props.labels.find(obj => obj.label === 'Status').isActive ? <Column field="status" body={(obj) => this.getStatus(obj)} header="Status" style={{ width: '136px', padding: 14, height: 80 }} /> : null}

					{this.props.labels.find(obj => obj.label === 'Responsável').isActive ? <Column field="landlord.name" body={(obj) => this.getUserName(obj, 'landlord')} header="Responsável" style={{ width: '239px', textAlign: 'start', padding: 16, height: 80 }} /> : null}

					{this.props.labels.find(obj => obj.label === 'Inquilino').isActive ? <Column field="tenant.name" body={(obj) => this.getUserName(obj, 'tenant')} header="Inquilino" style={{ width: '239px', textAlign: 'start', padding: 16, height: 80 }} /> : null}

					{this.props.labels.find(obj => obj.label === 'Contrato').isActive ? <Column field="contract" body={(obj) => this.getUserName(obj, 'contract')} header="Contrato" style={{ width: '126px', padding: 16, height: 80 }} /> : null}

					{this.props.labels.find(obj => obj.label === 'Valor aluguel').isActive ? <Column sortable field="receipts.rent.value" body={(obj) => this.getValue(obj?.receipts?.rent?.value)} header="Valor aluguel" style={{ width: '124px', padding: 16, height: 80 }} /> : null}

					<Column header="" body={(obj) => this.seeFile(obj)} style={{ width: '34px', padding: 0 }} />
					<Column header="" body={(obj) => this.columnOptionsCrud(obj)} style={{ width: '34px', padding: 0 }} />
					<Column header="" body={(obj) => this.seeBill(obj)} style={{ width: '34px', padding: 0 }} />
				</DataTable>

				<div className="paginator-new-items">
					<p>Registros por página</p>
					<Link className="custom-see-all-table" to={{
						pathname: '/pagamento/cobrancas/index',
						state: { filters: this.props.filters }
					}}>
						Ver todos
					</Link>
				</div>
			</div>
		);
	}
}
