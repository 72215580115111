import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment';
import 'moment/locale/pt-br';
import { laudosQuery } from '../../crud/credit-consut.crud';
moment.locale('pt-br');

export default class DataTableLaudoHistorico extends Component {
	constructor() {
		super();

		this.state = {
			data: [],
			rows: 10,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: true,
			sortField: 'created_at',
			sortOrder: 1,
			page: 1
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		this.fetchData();
	}

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = async (event) => {
		if (event.data) {
			event.data = null;
		}

		this.setState({
			...this.state,
			rows: event.rows,
			loading: true
		});

		this.fetchData(event.rows, event.first);
	};

	fetchData = (limit, skip) => {
		let params = {
			$sort: { [this.state.sortField]: this.state.sortOrder },
			$limit: limit ? limit : this.state.rows,
			$skip: skip != null ? skip : this.state.first,
			$sort: { [this.state.sortField]: this.state.sortOrder },
			proposition_id: this.props.propositionId
		}

		laudosQuery(params).then(res => {
			this.setState({
				...this.state,
				data: res.data.data,
				totalRecords: res.data.total,
				first: res.data.skip,
				last: res.data.skip + res.data.limit,
			});
		}).finally(() => {
			this.setState({
				loading: false
			});
		});
	};

	sortTable = e => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder }, () => {
			let laudoHistoricoDataTable = {
				rows: this.state.rows,
				totalRecords: this.state.totalRecords,
				first: this.state.first,
				last: this.state.last,
				loading: this.state.loading,
				sortField: this.state.sortField,
				sortOrder: this.state.sortOrder,
			};

			localStorage.setItem('laudoHistoricoDataTable', JSON.stringify(laudoHistoricoDataTable));

			this.fetchData();
		});
	};

	getMessage = (obj) => {
		if (obj?.fc_laudo?.parecer?.sintese?.length > 0) {
			return obj?.fc_laudo?.parecer?.sintese[0].parecer[0].parecer
		} else {
			return 'Laudo não recebido ou não executado.';
		}
	};

	render() {
		return (
			<>
				<DataTable ref={(el) => this.dt = el} value={this.state.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={[10]}
					first={this.state.first} last={this.state.last} responsive={true} className="ml-auto"
					totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} loadingIcon={`fas fa-sync fa-spin`}
					paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
					currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" emptyMessage="Nenhum registro encontrado!"
					onSort={(e) => this.sortTable(e)} sortField={this.state.sortField} sortOrder={this.state.sortOrder}>
					<Column body={(obj, i) => moment(obj.created_at).format('DD/MM/YYYY HH:mm:ss')} header="Data"></Column>
					<Column body={(obj, i) => this.getMessage(obj)} header="Mensagem"></Column>
				</DataTable>
			</>
		);
	}
}