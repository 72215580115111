import axios from "axios";
import { Constants } from '../helpers/Constants';
import { parseQuery } from '../helpers/Utils';

export function getPropertiesById(id) {
	return axios.get(`${Constants.baseUrl}/announcements/properties/${id}`);
}

export function getProperties(params) {
	return axios.get(`${Constants.baseUrl}/announcements/properties`, { params: parseQuery({ ...params }) });
}

export function getTotalImoveis(params) {
	return axios.get(`${Constants.baseUrl}/announcements/properties/getIndicadores`, { params: parseQuery({ ...params }) });
}

export function getRaw(params) {
	return axios.get(`${Constants.baseUrl}/announcements/raw`, { params: parseQuery(params) });
}

export function getRawById(id) {
	return axios.get(`${Constants.baseUrl}/announcements/raw/${id}`);
}

export function deleteRawById(id) {
	return axios.delete(`${Constants.baseUrl}/announcements/properties/${id}`);
}

export function updateProperties(id, data) {
	return axios.patch(`${Constants.baseUrl}/announcements/properties/${id}`, data);
}

export function postImage(id, data) {
	return axios.post(`${Constants.baseUrl}/announcements/properties/${id}/images`, data);
}

export function deleteImage(id, imageId) {
	return axios.delete(`${Constants.baseUrl}/announcements/properties/${id}/images/${imageId}`,);
}

export function updateRaw(id, data) {
	return axios.patch(`${Constants.baseUrl}/announcements/raw/${id}`, data);
}

export function admitAnnouncement(id) {
	return axios.post(`${Constants.baseUrl}/announcements/raw/${id}/admit`);
}

export function getAllCities() {
	return axios.get(`${Constants.baseUrl}/announcements/get-all-cities`);
}

export function getComissioningParticipants(params) {
	return axios.get(`${Constants.baseUrl}/announcements/comissioning`, { params: parseQuery(params) });
}

export function createCommissioningParticipants(data) {
	return axios.post(`${Constants.baseUrl}/announcements/comissioning`, data);
}

export function removeCommissioningParticipants(id) {
	return axios.delete(`${Constants.baseUrl}/announcements/comissioning/${id}`);
}

export function updateCommissioningParticipants(id, data) {
	return axios.patch(`${Constants.baseUrl}/announcements/comissioning/${id}`, data);
}