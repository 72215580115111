import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Link } from "react-router-dom";
import { Column } from 'primereact/column';
import _ from 'lodash';
import moment from 'moment';
import { formatBRL, formatTelefone } from '../../helpers/Utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileContract, faFilePen, faUserCheck } from '@fortawesome/pro-solid-svg-icons';
import { getAffiliateBalance, getByFilterUsersAffiliate, getContractAffiliate } from '../../crud/affiliate.crud';
import { Button } from "reactstrap";
import { faCheck, faFileExport } from '@fortawesome/pro-regular-svg-icons';

export default class DataTableUsers extends Component {
	constructor() {
		super();

		this.state = {
			data: [],
			rows: 10,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: 'id',
			sortOrder: 1,
			csvData: [],
			fetchingCsvData: false,
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		let userDataTable = JSON.parse(localStorage.getItem('userDataTable'));

		if (userDataTable) {
			this.setState({
				rows: userDataTable.rows,
				totalRecords: userDataTable.totalRecords,
				first: userDataTable.first,
				last: userDataTable.last,
				loading: userDataTable.loading,
				sortField: userDataTable.sortField,
				sortOrder: userDataTable.sortOrder,
			}, () => {
				this.fetchData();
			});
		} else {
			this.fetchData();
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters != this.props.filters) {
			this.onFilterChanged();
		}
	}

	onFilterChanged = _.debounce(() => {
		if (!this.state.loading) {
			this.fetchData();
		}
	}, 700);

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = (event) => {
		if (event.data) {
			event.data = null;
		}

		let userDataTable = {
			rows: event.rows,
			totalRecords: this.state.totalRecords,
			first: event.first,
			last: event.rows + event.first,
			loading: this.state.loading,
			sortField: this.state.sortField,
			sortOrder: this.state.sortOrder,
		}

		localStorage.setItem('userDataTable', JSON.stringify(userDataTable));

		this.setState({
			...this.state,
			rows: event.rows,
			loading: true
		});

		this.fetchData(event.rows, event.first);
	};

	fetchData = async (limit, skip) => {
		this.setState({ loading: true });

		let data = {
			$limit: limit ? limit : this.state.rows,
			$skip: skip != null ? skip : this.state.first,
			$sort: { [this.state.sortField]: this.state.sortOrder }
		};

		return this.props.fetchEvent(data).then(async res => {
			this.props.handleSetIndicadorAtual(res.data.total);

			if (res.data.data.length == 0) {
				this.setState({
					...this.state,
					data: [],
					totalRecords: res.data.total,
					first: skip,
					last: skip + res.data.limit,
					loading: false
				});
			}

			await res.data.data.map(async (obj, i) => {
				if (obj.id && this.props.activeTab === '3') {
					await getByFilterUsersAffiliate({ affiliate_id: obj.id }).catch(err => {
						obj.totalAffiliates = null;
					}).then(res => {
						if (res?.data?.data) {
							obj.totalAffiliates = res.data.total;
						}
					});

					await getContractAffiliate({ user_id: obj.id, affiliate_actived: true }).catch(err => {
						obj.totalContractsAffiliates = null;
					}).then(res => {
						if (res?.data?.data) {
							obj.totalContractsAffiliates = res.data.total;
						}
					});

					await getAffiliateBalance(obj.id).then(res => {
						if (res?.status === 200) {
							if (res.data) {
								console.log(res.data.data)
								obj.totalBalance = formatBRL(res.data?.data?.balance);
							}
						}
					}).catch(err => {
						console.log(err, 'erro balanço de afiliado');
					});
				}

				this.setState({
					...this.state,
					data: res.data.data,
					totalRecords: res.data.total,
					first: skip,
					last: skip + res.data.limit,
					loading: false
				});
			});
		}).finally(() => {
			this.setState({ loading: false });
		});
	}

	columnOpcoesCrud = (rowData) => {
		let idRow = this.props.idRow ? this.props.idRow : 'id';

		if (this.props.crudUrl) {
			return (
				<Link to={`${this.props.crudUrl}/show/${rowData[idRow]}`} className='showButton'>
					<i className='fa fa-chevron-right' />
				</Link>
			);
		} else {
			return null;
		}
	};

	negociacoesAtivas = ({ negotiation_active }) => {
		return (
			<div className='negotiation-active'>
				{negotiation_active}
				<FontAwesomeIcon icon={faFilePen} />
			</div>
		);
	}

	duracaoContrato = ({ durationContract }) => {
		return (
			<div className='negotiation-active'>
				{durationContract}
				<FontAwesomeIcon icon={faFileContract} />
			</div>
		);
	}

	sortTable = e => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder }, () => {
			let userDataTable = {
				rows: this.state.rows,
				totalRecords: this.state.totalRecords,
				first: this.state.first,
				last: this.state.last,
				loading: this.state.loading,
				sortField: this.state.sortField,
				sortOrder: this.state.sortOrder,
			};

			localStorage.setItem('userDataTable', JSON.stringify(userDataTable));

			this.fetchData();
		});
	};

	afiliadosActive = ({ totalAffiliates }) => {
		if (totalAffiliates > 0) {
			return (
				<div className='negotiation-active'>
					{totalAffiliates}
					<FontAwesomeIcon icon={faUserCheck} />
				</div>
			);
		} else {
			return (
				<div className='negotiation-disabled'>
					--
					<FontAwesomeIcon icon={faUserCheck} />
				</div>
			);
		}
	}

	totalContractsAffiliatesActives = ({ totalContractsAffiliates }) => {
		return (
			<div className='negotiation-active'>
				{totalContractsAffiliates ?? 0}
				<FontAwesomeIcon icon={faFilePen} />
			</div>
		);
	}


	export() {
		let data = {
			$limit: 100,
		};

		this.setState({ fetchingCsvData: true });

		this.props.fetchEvent(data).then(async res => {
			let csvData = [['Data de fim de cadastro', 'Nome do proprietário', 'Quantidade de imóveis anunciados ativos', 'Quantidade de imóveis com contrato ativo', 'Quantidade de imóveis sem contrato ativo', 'Email', 'Telefone', 'Último login']];

			for (const obj of res.data.data) {
				csvData.push([
					obj?.sys_flags?.verified_at ? moment(obj?.sys_flags?.verified_at).format('DD/MM/YYYY HH:mm:ss') : '',
					obj?.name ?? '',
					obj?.activeProperties ?? '',
					obj?.activeContractsCount ?? '',
					obj?.availableProperties ?? '',
					obj?.email,
					obj?.phone,
					obj?.updated_at ? moment(obj?.updated_at).format('DD/MM/YYYY HH:mm:ss') : '',
				])
			}
			console.log(csvData);

			csvData = csvData.map(e => e.join(",")).join("\n");

			this.downloadCSV(csvData);
		})
	}

	downloadCSV = (csvContent) => {
		const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
		const url = URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		a.download = 'data.csv';
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		URL.revokeObjectURL(url);

		this.setState({ fetchingCsvData: false });
	};

	render() {
		const width = [
			// inquilino
			{
				name: 281,
				email: 281,
				phone: 134,
				negotiation_active: 162,
				durationContract: 106,
				updated_at: 169,
			},
			// resposanveis
			{
				name: 281,
				email: 281,
				phone: 134,
				negotiation_active: 162,
				durationContract: 106,
				updated_at: 169,
			},
			// comissionados
			{
				name: 281,
				email: 281,
				phone: 134,
				negotiation_active: 162,
				durationContract: 106,
				updated_at: 169,
			},
			// donos
			{
				name: 281,
				email: 281,
				phone: 134,
				negotiation_active: 162,
				durationContract: 106,
				updated_at: 169,
			},
			// usuarios
			{
				updated_at: 127,
				name: 383.5,
				email: 383.5,
				phone: 102,
				negotiation_active: 0,
				durationContract: 0,
			},
			// afiliado
			{
				updated_at: 169,
				name: 512,
				email: 0,
				phone: 134,
				negotiation_active: 162,
				durationContract: 106,
				totalValue: 108,
				indicados: 105,
				contracts: 106
			},
			// nao confirmado
			{
				updated_at: 127,
				name: 383.5,
				email: 383.5,
				phone: 102,
				negotiation_active: 0,
				durationContract: 0,
			},
			// admin
			{
				updated_at: 127,
				name: 341,
				email: 341,
				phone: 134,
				access_user: 149,
				negotiation_active: 0,
				durationContract: 0,
			},
		]

		const header = (
			<div style={{ float: 'right', marginBottom: 32 }}>
				<button onClick={() => this.export()} className="btnSecundary" style={{ width: 'max-content', margin: '0px 8px', padding: '8px 12px' }} disabled={this.state.fetchingCsvData}>
					EXPORTAR
					<FontAwesomeIcon icon={faFileExport} />
				</button>
			</div>
		)


		return (
			<>
				<DataTable ref={(el) => this.dt = el} value={this.state.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={[10, 25, 50]} sortOrder={this.state.sortOrder}
					first={this.state.first} last={this.state.last} responsive={true} emptyMessage="Nenhum registro encontrado!" sortField={this.state.sortField}
					totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} loadingIcon={`fas fa-sync fa-spin`}
					paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" onSort={(e) => this.sortTable(e)}
					currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" header={this.props.activeTab === '2' ? header : null} >

					{this.props.activeTab == '6' &&
						<Column style={{ width: width[this.props.activeTab - 1].updated_at }} sortable field="updated_at" body={({ updated_at }) => moment(updated_at).format('DD/MM/YYYY HH:mm:ss')} header="Último login" />
					}

					<Column style={{ width: width[this.props.activeTab - 1].name }} sortable field="name" header="Nome" />

					{this.props.activeTab !== '6' &&
						<Column style={{ width: width[this.props.activeTab - 1].email }} sortable field="email" header="Email" />
					}

					<Column style={{ width: width[this.props.activeTab - 1].phone }} field="phone" header="Telefone" body={({ phone }) => phone ? formatTelefone(phone) : 'Sem telefone'} />

					{this.props.activeTab !== '5' && this.props.activeTab !== '6' && this.props.activeTab !== '7' && this.props.activeTab !== '8' &&
						<Column style={{ width: width[this.props.activeTab - 1].negotiation_active }} field="negotiation_active" header="Negociações ativas" body={body => this.negociacoesAtivas(body)} />
					}

					{this.props.activeTab !== '5' && this.props.activeTab !== '6' && this.props.activeTab !== '7' && this.props.activeTab !== '8' &&
						<Column style={{ width: width[this.props.activeTab - 1].durationContract }} field="durationContract" header={this.props.activeTab == 2 ? "Contratos" : "Duração de contrato"} body={body => this.duracaoContrato(body)} />
					}

					{this.props.activeTab === '8' &&
						<Column style={{ width: width[this.props.activeTab - 1].access_user }} sortable field="roles" body={({ roles }) => {
							if (roles && roles.includes('external_auth')) {
								return (
									<div className='p-2'>
										<FontAwesomeIcon icon={faCheck} style={{ color: '#5664D2' }} />
									</div>
								)
							}
						}} header="Acessar usuários" />
					}

					{this.props.activeTab !== '6' &&
						<Column style={{ width: width[this.props.activeTab - 1].updated_at }} sortable field="updated_at" body={({ updated_at }) => moment(updated_at).format('DD/MM/YYYY HH:mm:ss')} header="Último login" />
					}

					{this.props.activeTab === '6' &&
						<Column style={{ width: width[this.props.activeTab - 1].totalValue }} field="totalBalance" header="Valor total" body={body => body.totalBalance} />
					}

					{this.props.activeTab === '6' &&
						<Column style={{ width: width[this.props.activeTab - 1].indicados }} field="indicados" header="Indicados" body={body => this.afiliadosActive(body)} />
					}

					{this.props.activeTab === '6' &&
						<Column style={{ width: width[this.props.activeTab - 1].contracts }} field="contracts" header="Contratos" body={body => this.totalContractsAffiliatesActives(body)} />
					}

					<Column header="" body={this.columnOpcoesCrud} style={{ width: '34px', padding: 0 }} />
				</DataTable>
			</>
		);
	}
}