import React, { Component } from 'react';
import {
	Container, Row, Col, CardBody, Button, Media, Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
	Input, Nav, NavItem, NavLink, TabContent, TabPane
} from "reactstrap";
import classnames from 'classnames';
import { Link } from "react-router-dom";
import socketIOClient from "socket.io-client";
import moment from 'moment';
import SimpleBar from "simplebar-react";
import Breadcrumbs from '../../components/Breadcrumb';
import noProfile from '../../assets/images/users/no-profile.png';
import { Constants } from '../../helpers/Constants';
import { CircularProgress } from "@material-ui/core";
import Swal from 'sweetalert2';
import { get as getUser } from '../../crud/users.crud';
import { getWhatsappTemplates, sendSingleWhatsappMessage } from '../../crud/chat.crud';

var socket;

class Chat extends Component {
	constructor(props) {
		super(props);

		this.scrollableNodeRef = React.createRef();

		this.state = {
			userList: [],
			chats: [],
			labels: [],
			contacts: [],
			messages: [],
			other2: false,
			settings: false,
			settings2: false,
			activeTab: '1',
			curMessage: "",
			user: null,
			activeChat: null,
			fetchingMessages: false,
			fetchingMoreMessages: false,
			chatSearch: '',
			messagesSearch: '',
			showArchivedChats: false,
			hasArchivedChat: false,
			userSend: null,
			listWhatsappTemplates: [],
			orphanChats: [],
			openAdminChatId: null,
		}

		this.toggleTab = this.toggleTab.bind(this);
		this.UserChatOpen = this.UserChatOpen.bind(this);
		this.addMessage = this.addMessage.bind(this);

		const obj = JSON.parse(localStorage.getItem("authUser"));

		socket = socketIOClient(Constants.chatUrl, {
			transports: ['websocket'],
			path: '/api/chat/ws',
			auth: { authorization: `Bearer ${obj.access_token}` }
		});

		socket.on('user-data', (userData) => {
			let contacts = this.sortContacts(userData.status, userData.id);
			let chats = this.updateChatList(null, userData.status);

			this.setState({ contacts, chats, userList: userData.status });
		});

		socket.on('user-catch-by', (room) => {
			let orphanChats = [];

			this.state.orphanChats.map((obj, i) => {
				if (obj.roomId != room.roomId) {
					orphanChats.push(obj);
				}
			});

			this.setState({ orphanChats });
		});

		socket.on('message-status-updated', (message) => {
			if (this.state.activeChat) {
				let activeChat = { ...this.state.activeChat };

				if (activeChat.roomId == message.roomId) {
					activeChat.history.history.map((msg, idxMessage) => {
						if (msg.messageId) {
							if (message.messageId == msg.messageId) {
								if (msg.status && msg.status.length > 0) {
									msg.status[0].status = message.status;
								}
							}
						} else {
							msg.status[0].status = message.status;
						}
					})
				}

				this.setState({ activeChat });
			}
		})

		socket.on('update-rooms-list', (room) => {
			let chats = [...this.state.chats];

			if (room.orphanClientChat) {
				let list = [...this.state.orphanChats];

				list.push(room);

				this.setState({ orphanChats: list });
				return;
			}

			if (room.archived) this.setState({ hasArchivedChat: true });

			if (chats.filter(e => e.roomId === room.roomId).length < 1) {
				room.readedLastMessage = this.checkReadedMessage(room.latestMessage[0]);

				chats.push(room);
			}

			if (this.props.location?.state?.roomId) {
				if (this.props.location?.state?.roomId == room.roomId) {
					socket.emit('update-room', {
						roomId: this.props.location.state.roomId,
						pageSize: 1,
						pageLimit: 25
					});
				}
			}

			chats = this.updateChatList(chats, null);

			this.setState({ chats });

			if (this.state.openAdminChatId != null) {
				let openChat = false;

				room.participants.map((part, i) => {
					if (part.userId == this.state.openAdminChatId) {
						openChat = true;
					}
				});

				if (openChat) {
					this.setState({ openAdminChatId: null });

					socket.emit('update-room', {
						roomId: room.roomId,
						pageSize: 1,
						pageLimit: 25
					});
				}
			}
		});

		socket.on('update-room', (room) => {
			let chats = [...this.state.chats];

			chats.map((chat, i) => {
				if (chat.roomId == room.roomId) {
					room.clientChat = chat.clientChat;
					chat.readedLastMessage = true;
				}
			});

			room.participants.map((obj, i) => {
				if (obj.role === 'client') {
					room.userSend = obj;
				} else {
					if (obj.userId != this.state.user.id) {
						room.userSend = obj;
					}
				}
			});

			if (room.userSend) {
				getUser(room.userSend.userId).catch(err => { }).then(res => {
					if (res?.status == 200) {
						if (res.data) {
							this.setState({ userSend: res.data.data });
						}
					}
				});
			}

			let activeChat;

			if (room.history.currentPage > 1) {
				activeChat = { ...this.state.activeChat };

				let aux = room.history.history.reverse().concat(this.state.activeChat.history.history);

				activeChat.history.history = aux;
				activeChat.history.nextPage = room.history.nextPage;
			} else {
				room.history.history.reverse();
				activeChat = room;

				this.readAllChatMessages(room);
			}

			let scrollHeightBefore = null;

			if (this.scrollableNodeRef) {
				scrollHeightBefore = this.scrollableNodeRef.current.scrollHeight;
			}

			this.setState({ chats, activeChat, fetchingMessages: false, fetchingMoreMessages: false }, () => {
				if (scrollHeightBefore) this.scrollableNodeRef.current.scrollTop = this.scrollableNodeRef.current.scrollHeight - scrollHeightBefore;

				if (this.state.activeChat) {
					this.scrollableNodeRef.current.addEventListener('scroll', (e) => {
						if (e.target.scrollTop == 0) {
							if (this.state.activeChat) {
								if (this.state.activeChat.history.nextPage) {
									this.setState({ fetchingMoreMessages: true });

									socket.emit('update-room', {
										roomId: this.state.activeChat.roomId,
										pageSize: this.state.activeChat.history.nextPage,
										pageLimit: 25
									});
								}
							}
						}
					});
				}

				if (room.history.currentPage == 1) {
					this.messagesEnd.scrollIntoView({ behavior: "smooth" });
				}
			});
		});

		socket.on('new-client-message', (message) => {
			let chats = [...this.state.chats];

			if (this.state.activeChat?.roomId == message.roomId) {
				let chat = { ...this.state.activeChat };

				chat.history.history.push(message);

				if (chat.history.history.length > 25) {
					chat.history.history.shift();
				}

				chats.map((chat, i) => {
					if (chat.roomId == message.roomId) {
						chat.archived = false;
						chat.latestMessage = message;
					}
				});

				this.setState({ activeChat: chat }, () => {
					this.messagesEnd.scrollIntoView({ behavior: "smooth" });
				});
			} else {
				chats.map((chat, i) => {
					if (chat.roomId == message.roomId) {
						chat.readedLastMessage = false;
						chat.archived = false;
						chat.latestMessage = message;
					}
				});
			}

			this.setState({ chats });
		});

		socket.on('add-message', (message) => {
			let chats = [...this.state.chats];

			if (this.state.activeChat?.roomId == message.roomId) {
				let chat = { ...this.state.activeChat };

				chat.history.history.push(message);

				if (chat.history.history.length > 25) {
					chat.history.history.shift();
				}

				chats.map((chat, i) => {
					if (chat.roomId == message.roomId) {
						chat.archived = false;
						chat.latestMessage = message;
					}
				});

				this.setState({ activeChat: chat }, () => {
					this.messagesEnd.scrollIntoView({ behavior: "smooth" });
					this.readIncomingMessage(message);
				});
			} else {
				chats.map((chat, i) => {
					if (chat.roomId == message.roomId) {
						chat.readedLastMessage = false;
						chat.archived = false;
						chat.latestMessage[0] = (message);
					}
				});
			}

			this.setState({ chats });
		});
	};

	componentDidMount = () => {
		const obj = JSON.parse(localStorage.getItem("authUser"));

		this.setState({ user: obj.user });

		// TODO uncomment when templates are working
		getWhatsappTemplates().catch(err => { console.error(err) }).then(res => {
			if (res?.status === 200) {
				this.setState({ listWhatsappTemplates: res.data.data });
			}
		});

		if (this.props.location?.state?.user_id) {
			socket.emit('join', {
				userId: this.props.location.state.user_id
			})
		}
	};

	readAllChatMessages = (room) => {
		room.history.history.map((message, idxMessage) => {
			message.status.map((status, idxStatus) => {
				if (status.userId === this.state.user?.id) {
					socket.emit('message-status-change', {
						messageId: message.messageId,
						status: 'readed',
					});
				}
			});
		});
	};

	readIncomingMessage = (message) => {
		socket.emit('message-status-change', {
			messageId: message.messageId,
			status: 'readed',
		});
	}

	checkReadedMessage = (message) => {
		let allReaded = true;

		if (message) {
			message.status.map((obj, i) => {
				if (obj.status !== 'readed' && obj.userId == this.state.user.id) {
					allReaded = false;
				}
			});
		}

		return allReaded;
	}

	updateChatList = (chatList, userList) => {
		let chats = chatList != null ? chatList : [...this.state.chats];
		let users = userList != null ? userList : [...this.state.userList];

		if (chats.length > 0) {
			chats.map((chat, iChat) => {
				if (chat.archived) {
					this.updateContactsArchived(chat);
				}

				chat.participants.map((part, iPart) => {
					users.map((cont, iCont) => {
						if (part.userId == cont.userId) {
							part.lastSee = cont.lastSee;
							part.status = cont.status;
							part.updatedAt = cont.updatedAt;
							part.image = cont.profile_image?.small?.url ? cont.profile_image.small.url : null
						}
					});

					if (part.role === 'client') {
						chat.userSend = part;
					} else {
						if (this.state.user.id != part.userId) {
							chat.userSend = part;
						}
					}
				});

				if (!chat.userSend) {
					chat.userSend = chat.participants[0];
				}
			});
		}

		return chats;
	};

	updateContactsArchived = chat => {
		let contacts = [...this.state.contacts];

		chat.participants.map((part, iPart) => {
			if (part.userId != this.state.user.id) {
				contacts.map((cont, iCont) => {
					cont.child.map((obj, i) => {
						if (obj.userId == part.userId) {
							obj.archived = true;
						}
					})
				});
			}
		});

		this.setState({ contacts });
	};

	sortContacts = (userList, currentUserId) => {
		let contacts = [];

		userList.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));

		userList.map((obj, i) => {
			if (obj.userId != currentUserId) {
				let upper = obj.name.charAt(0).toUpperCase();
				let added = false;

				if (contacts.length > 0) {
					if (contacts[contacts.length - 1].category == upper) {
						added = true;
						contacts[contacts.length - 1].child.push(obj);
					}
				}

				if (!added) {
					let cat = {
						category: upper,
						child: [obj]
					}

					contacts.push(cat);
				}
			}
		});

		if (this.state.chats.length > 0) {
			this.state.chats.map((chat, idxChat) => {
				if (chat.archived) {
					chat.participants.map((part, iPart) => {
						if (part.userId != this.state.user.id) {
							contacts.map((cont, iCont) => {
								cont.child.map((obj, i) => {
									if (obj.userId == part.userId) {
										obj.archived = true;
									}
								})
							});
						}
					});
				}
			})
		}

		return contacts;
	};

	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	};

	UserChatOpen = (id, name, status, image) => {
		let openedChat = null;

		this.state.chats.map((chat, i) => {
			if (chat.participants.filter(e => e.userId === id).length > 0) {
				openedChat = chat;
			}
		});

		if (openedChat) {
			socket.emit('update-room', {
				roomId: openedChat.roomId,
				pageSize: 1,
				pageLimit: 25
			})
		} else {
			this.setState({ openAdminChatId: id }, () => {
				socket.emit('join', {
					userId: id
				});
			});
		}
	};

	addMessage() {
		if (this.state.curMessage === '') {
			Swal.fire('Ops', 'Não é possível enviar uma mensagem vazia', 'error');
			return;
		}

		let chat = { ...this.state.activeChat };
		let chats = [...this.state.chats];
		console.log({ chat });
		console.log({ state: this.state });

		let message = {
			content: this.state.curMessage,
			date: new Date(),
			messageType: 'text',
			userId: this.state.user.id,
			status: [{ userId: this.state.user.id, userName: this.state.user.name, status: 'sended' }]
		};

		if (chat.clientChat) {
			socket.emit('new-message', {
				roomId: this.state.activeChat.roomId,
				content: this.state.curMessage,
				messageType: 'text',
				contact: `${this.state.userSend.phone}`
			});
		} else {
			socket.emit('new-message', {
				roomId: this.state.activeChat.roomId,
				content: this.state.curMessage,
				messageType: 'text',
				contact: `${this.state.userSend.phone}`
			});
		}

		chats.map((obj, i) => {
			if (obj.roomId == this.state.activeChat.roomId) {
				obj.latestMessage = message;
			}
		});

		chat.history.history.push(message);

		if (chat.history.history.length > 25) {
			chat.history.history.shift();
		}

		this.setState({ curMessage: '', activeChat: chat }, () => {
			this.messagesEnd.scrollIntoView({ behavior: "smooth" });
		});
	};

	getTimeLastSee = (lastTime) => {
		if (lastTime) {
			let minutes = moment().diff(lastTime, 'minutes');

			if (minutes > 0) {
				let hours = Math.floor(minutes / 60);

				if (hours > 1) {
					let days = Math.floor(hours / 24);

					if (days > 1) {
						let months = Math.floor(days / 30);

						if (months > 1) {
							return months + ' m';
						}

						return days + ' d';
					}

					return hours + ' h';
				}

				return minutes + ' min';
			}

			return 'online';
		}

		return 'offline'
	};

	openChat = (index) => {
		this.setState({ fetchingMessages: true });

		let chat = this.state.chats[index];

		socket.emit('update-room', {
			roomId: chat.roomId,
			pageSize: 1,
			pageLimit: 25
		});
	};

	joinOrphanChat = (index) => {
		let orphanChats = [...this.state.orphanChats];
		let chats = [...this.state.chats];
		let roomId = orphanChats[index].roomId;
		let chat = { ...this.state.orphanChats[index] };

		orphanChats.splice(index, 1);
		chat.orphanClientChat = false;
		chats.push(chat);
		chats = this.updateChatList(chats, null);

		this.setState({ orphanChats, fetchingMessages: true, chats }, () => {
			socket.emit('join', {
				roomId
			});

			socket.emit('update-room', {
				roomId,
				pageSize: 1,
				pageLimit: 25
			});
		});
	};

	archiveChat = () => {
		socket.emit('status-room', {
			roomId: this.state.activeChat.roomId,
			archived: true,
		});

		let chats = [...this.state.chats];

		chats.map((chat, i) => {
			if (chat.roomId == this.state.activeChat.roomId) {
				chat.archived = true;
			}
		});

		this.setState({ chats, activeChat: null, hasArchivedChat: true });
	};

	isShowChat = (chat) => {
		if (!this.state.showArchivedChats) {
			if (chat.archived) {
				return false;
			}
		}

		if (this.state.chatSearch.trim() !== '') {
			if (!chat?.userSend?.userName.toLowerCase().includes(this.state.chatSearch.toLowerCase())) {
				return false;
			}
		}

		return true;
	};

	isShowMessage = (message) => {
		if (this.state.messagesSearch.trim() !== '') {
			if (message.messageType === 'template') {
				return true;
			}

			if (!message.content.toLowerCase().includes(this.state.messagesSearch.toLowerCase())) {
				return false;
			}
		}

		return true;
	}

	setLastMessageUnreaded = () => {
		let activeChat = { ...this.state.activeChat };
		let chats = [...this.state.chats];

		chats.map((chat, i) => {
			if (chat.roomId == activeChat.roomId) {
				chat.readedLastMessage = false;
			}
		});

		activeChat.readedLastMessage = false;

		this.setState({ activeChat, chats });

		socket.emit('message-status-change', {
			messageId: activeChat.history.history[activeChat.history.history.length - 1].messageId,
			status: 'sended'
		});
	};

	getParsedMessage = (message) => {
		let messageContent = '';

		this.state.listWhatsappTemplates.map((template, idxTemplate) => {
			if (template.name === message.content.templateName) {
				messageContent = template.components[0].text;
				message.content.components.map((comp, idxComp) => {
					messageContent = messageContent.replace(`{{${(idxComp + 1)}}}`, comp);

				});
			}
		});

		return messageContent;

	}

	getMessageContent = (message) => {
		if (message.messageType === 'template') {
			if (this.state.listWhatsappTemplates.length > 0) {
				return <p className="mb-0">{this.getParsedMessage(message)}</p>
			} else {
				return <p className="mb-0">{message.content.templateName}</p>;
			}
		}

		if (message.messageType === 'image') {
			return <a rel='noopener noreferrer' target='_blank' href={message.content.link}><img alt='user_image' style={{ width: '120px' }} src={message.content.link} /></a>
		}

		if (message.messageType === 'text') {
			return <p className="mb-0">{message.content}</p>;
		}

		return <p className="mb-0"><i>A mensagem de tipo <b>{message.messageType}</b> não é suportado</i></p>;
	}

	getLastMessageContent = (message) => {
		if (message) {
			if (message.messageType === 'text') {
				return message.content;
			}
			if (message.messageType === 'template') {
				return this.getParsedMessage(message);
			}

			return message.messageType;
		}

		return '';
	};

	handleKeyPress = (target) => {
		if (target.charCode === 13) {
			this.addMessage();
		}
	}

	getMessageStatusIcon = (message) => {
		if (this.state.user.id == message.userId) {
			if (message.status) {
				if (message.status.length > 0) {
					if (message.status[0].status === 'sended') {
						return <i className="ri-check-fill align-middle mr-1"></i>;
					}

					if (message.status[0].status === 'readed') {
						return <i className="ri-check-double-fill align-middle mr-1"></i>;
					}
				}
			} else {
				return <i className="ri-check-fill align-middle mr-1"></i>;
			}
		}

		return null;
	};

	getUserChannel = user => {
		if (user?.contacts?.length > 0) {
			if (user.contacts[0].channelName) {
				return user.contacts[0].channelName;
			}
		}

		return '(Admin)';
	};

	render() {
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						<Breadcrumbs title="Chat" breadcrumbItems={[]} />
						<div className="d-lg-flex mb-4">
							<div className="chat-leftsidebar">
								<div style={{ display: 'flex', alignItems: 'center', height: '70px' }} className="p-3 border-bottom">
									<Media>
										<div className="align-self-center mr-3">
											<img src={this.state.user?.profile_image?.small?.url ? this.state.user.profile_image.small.url : noProfile} className="avatar-xs rounded-circle" alt="" />
										</div>
										<Media body>
											<h5 style={{ marginTop: '8px' }} className="font-size-15 mb-1">{this.state.user?.name}</h5>
										</Media>
									</Media>
								</div>
								<CardBody className="border-bottom py-2">
									<div className="search-box chat-search-box">
										<div className="position-relative">
											<Input value={this.state.chatSearch} onChange={(e) => { this.setState({ chatSearch: e.target.value }) }} type="text" className="form-control" placeholder="Search..." />
											<i className="ri-search-line search-icon"></i>
										</div>
									</div>
								</CardBody>

								<div className="chat-leftsidebar-nav">
									<Nav pills justified>
										<NavItem>
											<NavLink className={classnames({ active: this.state.activeTab === '1' })}
												onClick={() => { this.toggleTab('1'); }} >
												<i className="ri-message-2-line font-size-20"></i>
												<span className="mt-2 d-none d-sm-block">Chat</span>
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink className={classnames({ active: this.state.activeTab === '2' })}
												onClick={() => { this.toggleTab('2'); }} >
												<i className="ri-price-tag-2-line font-size-20"></i>
												<span className="mt-2 d-none d-sm-block">Etiquetas</span>
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink className={classnames({ active: this.state.activeTab === '3' })}
												onClick={() => { this.toggleTab('3'); }} >
												<i className="ri-contacts-book-2-line font-size-20"></i>
												<span className="mt-2 d-none d-sm-block">Contatos</span>
											</NavLink>
										</NavItem>
									</Nav>
								</div>

								<TabContent activeTab={this.state.activeTab} className="py-4">
									<TabPane tabId="1">
										<div>
											<h5 className="font-size-14 px-3 mb-3">Recent</h5>
											<ul className="list-unstyled chat-list" >
												<SimpleBar style={{ maxHeight: "345px" }}>
													{this.state.chats.map((chat, key) =>
														this.isShowChat(chat) ?
															<li key={key} className={"active"}>
																<Link className='chat-item' onClick={() => { this.openChat(key) }} to="#">
																	<Media>
																		{chat.image ?
																			<div className={chat.status === "online" ? "user-img mr-3 online" : chat.status !== "Intermediate" ? "user-img mr-3" : "user-img mr-3 away"}>
																				<div className="avatar-xs align-self-center">
																					<span className="avatar-title rounded-circle bg-light text-body">
																						{chat.userSend?.userName}
																					</span>
																					<span className="user-status"></span>
																				</div>
																			</div>
																			:
																			<div className={"user-img align-self-center mr-3 chat status"}>
																				<img src={chat?.userSend?.image ? chat.userSend.image : noProfile} className="rounded-circle avatar-xs" alt="" />
																				<span className={`user-status ${chat?.userSend?.lastSee ? this.getTimeLastSee(chat.userSend?.lastSee) === 'online' ? 'bg-success' : '' : ''}`}></span>
																			</div>
																		}

																		<Media body className="overflow-hidden">
																			<h5 style={{ color: '#505D69', marginTop: '8px', fontWeight: chat.readedLastMessage ? '400' : '700' }} className="text-truncate font-size-14 mb-1">{`${chat?.userSend?.userName} ${this.getUserChannel(chat.userSend)}`}</h5>
																			<p style={{ color: chat.readedLastMessage ? '#8590A5' : '#505D69', marginTop: '8px', fontWeight: chat.readedLastMessage ? '400' : '700' }} className="text-truncate mb-0">{this.getLastMessageContent(chat.latestMessage[0])}</p>
																		</Media>
																		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'space-between' }} className="font-size-11">
																			{chat?.userSend?.status ? this.getTimeLastSee(chat.userSend.lastSee) : ''}

																			{!chat.readedLastMessage ? <i style={{ marginTop: '5px', marginRight: 0, color: '#FF3D60' }} className='mdi mdi-circle align-middle mr-1'></i> : null}
																			{/* {chat.archived ? <i className='ri-volume-off-vibrate-fill' style={{ marginTop: '5px', fontSize: '14px' }} /> : null} */}
																		</div>
																	</Media>
																</Link>
															</li>
															: null
													)}
													{this.state.orphanChats.map((chat, key) => {
														return (
															<li key={key} className={"active"}>
																<Link to="#" onClick={() => { this.joinOrphanChat(key) }}>
																	<Media>
																		<div className={"user-img align-self-center mr-3 chat status"}>
																			<img src={chat?.participants[0]?.image ? chat?.participants[0]?.image : noProfile} className="rounded-circle avatar-xs" alt="" />
																			<span className={`user-status ${chat?.participants[0]?.lastSee ? this.getTimeLastSee(chat?.participants[0]?.lastSee) === 'online' ? 'bg-success' : '' : ''}`}></span>
																		</div>

																		<Media body className="overflow-hidden">
																			<h5 style={{ marginTop: '8px' }} className="text-truncate font-size-14 mb-1">{chat.participants[0]?.userName}</h5>
																			<p className="text-truncate mb-0">Pendente</p>
																		</Media>
																	</Media>
																</Link>
															</li>
														)
													})}
													{(this.state.hasArchivedChat && !this.state.showArchivedChats) ?
														<li style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '27px', cursor: 'pointer' }} >
															<p onClick={() => this.setState({ showArchivedChats: true })} style={{ fontSize: '13px', textDecorationLine: 'underline' }}>Conversas arquivadas</p>
														</li>
														: null
													}
												</SimpleBar>
											</ul>
										</div>
									</TabPane>

									<TabPane tabId="2">
										<h5 className="font-size-14 px-3 mb-3">Etiquetas</h5>
										<ul className="list-unstyled chat-list">
											<SimpleBar style={{ maxHeight: "345px" }}>
												{this.state.labels.map((group, key) =>
													<li key={key} >
														<Link to="#" onClick={() => { this.UserChatOpen(group.id, group.name, group.status, group.image) }}>
															<Media className="align-items-center">
																<div className="avatar-xs mr-3">
																	<span className="avatar-title rounded-circle bg-light text-body">
																		{group.image}
																	</span>
																</div>

																<Media body>
																	<h5 className="font-size-14 mb-0">{group.name}</h5>
																</Media>
															</Media>
														</Link>
													</li>
												)}
											</SimpleBar>
										</ul>
									</TabPane>

									<TabPane tabId="3">
										<SimpleBar style={{ maxHeight: "345px" }}>
											{this.state.contacts.map((contact, key) =>
												<div key={key} className={(key + 1 !== 1) ? "mt-4" : ""}>
													<div className="p-3">
														{contact.category}
													</div>

													<ul className="list-unstyled chat-list">
														{contact.child.map((obj, key) =>
															<li key={key}>
																<Link to="#" onClick={() => { this.UserChatOpen(obj.userId, obj.name, obj.status, null) }}>
																	<h5 className="font-size-14 mb-0">{`${obj.name}${obj.archived ? ' (Arquivado)' : ''}`}</h5>
																</Link>
															</li>
														)}
													</ul>
												</div>
											)}
										</SimpleBar>
									</TabPane>
								</TabContent>
							</div>

							<div className="w-100 user-chat mt-4 mt-sm-0">
								{this.state.activeChat ?
									<div className="p-3 px-lg-4 user-chat-border">
										<Row>
											<Col md={4} xs={6}>
												<h5 className="font-size-15 mb-1 text-truncate">{this.state.activeChat ? this.state.activeChat.userSend?.userName : ''}</h5>
												<p className="text-muted text-truncate mb-0">
													<i className={'online' === this.getTimeLastSee(this.state.activeChat?.userSend?.lastSee) ?
														"mdi mdi-circle text-success align-middle mr-1"
														:
														null
													}></i>
													{this.state.activeChat?.userSend?.lastSee ? this.state.activeChat.userSend.lastSee : ''}
												</p>
											</Col>
											<Col md={8} xs={6}>
												<ul className="list-inline user-chat-nav text-right mb-0">
													<li className="d-none d-sm-inline-block">
														<div className="search-box mr-2">
															<div className="position-relative">
																<Input value={this.state.messagesSearch} onChange={(e) => { this.setState({ messagesSearch: e.target.value }) }} type="text" className="form-control" placeholder="Search..." />
																<i className="mdi mdi-magnify search-icon"></i>
															</div>
														</div>
													</li>
													<li className="list-inline-item m-0 d-none d-sm-inline-block">
														<Dropdown isOpen={this.state.settings2} toggle={() => this.setState({ settings2: !this.state.settings2 })}>
															<DropdownToggle className="btn nav-btn" tag="i" >
																<i className="mdi mdi-cog"></i>
															</DropdownToggle>
															<DropdownMenu right>
																<DropdownItem><a style={{ color: '#000' }} href={`/users/show/${this.state.activeChat.userSend?.userId}`}>Ver usuário</a></DropdownItem>
															</DropdownMenu>
														</Dropdown>
													</li>

													<li className="list-inline-item">
														<Dropdown isOpen={this.state.other2} toggle={() => this.setState({ other2: !this.state.other2 })}>
															<DropdownToggle className="btn nav-btn " tag="i">
																<i className="mdi mdi-dots-horizontal"></i>
															</DropdownToggle>
															<DropdownMenu right>
																<DropdownItem onClick={() => this.archiveChat()}>Arquivar conversa</DropdownItem>
																<DropdownItem onClick={() => this.setLastMessageUnreaded()}>Marcar como não lido</DropdownItem>
																<DropdownItem href="#">Tags do contrato</DropdownItem>
															</DropdownMenu>
														</Dropdown>
													</li>
												</ul>
											</Col>
										</Row>
									</div>
									: null
								}

								<div className="px-lg-2">
									<div className="chat-conversation p-3 chat-conversation-height">
										<ul className="list-unstyled mb-0 pr-3">
											<SimpleBar scrollableNodeProps={{ ref: this.scrollableNodeRef }} style={{ maxHeight: "450px" }}>
												{this.state.fetchingMoreMessages ?
													<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
														<CircularProgress color="inherit" style={{ color: "#116fbf" }} size={25} />
													</div>
													: null
												}
												{this.state.activeChat?.history?.history.map((message, key) =>
													this.isShowMessage(message) ?
														<li key={key} className={message.userId == this.state.userSend?.id ? "" : "right"} style={{ paddingRight: "20px" }}>
															<div className="conversation-list">
																{/* {message.userId == this.state.user.id ?
																	<div className="chat-avatar">
																		<img src={this.state.activeChat?.userSend?.image ? this.state.activeChat.userSend.image : noProfile} alt="profile" />
																	</div>
																	: null
																} */}

																<div className="ctext-wrap">
																	<div className="conversation-name">{message.userName}</div>
																	<div className="ctext-wrap-content">
																		{this.getMessageContent(message)}
																	</div>
																	<p className="chat-time mb-0">{this.getMessageStatusIcon(message)} {moment(message.date).format('DD/MM/YYYY HH:mm')}</p>
																</div>
															</div>
														</li>
														: null
												)}
												<div style={{ float: "left", clear: "both" }}
													ref={(el) => { this.messagesEnd = el; }}>
												</div>
											</SimpleBar>
										</ul>
									</div>
								</div>

								{this.state.activeChat ?
									<div className="px-lg-3">
										<div className="p-3 chat-input-section ">
											<Row>
												<Col>
													<div className="position-relative">
														<Input type="text" value={this.state.curMessage} className="form-control chat-input" placeholder="Enter Message..."
															onChange={(e) => { this.setState({ curMessage: e.target.value }) }}
															onKeyPress={this.handleKeyPress} />
													</div>
												</Col>
												<Col xs={{ size: 'auto' }}>
													<Button onClick={this.addMessage} type="submit" color="primary" className="chat-send w-md waves-effect waves-light"><span className="d-none d-sm-inline-block mr-2">Send</span> <i className="mdi mdi-send"></i></Button>
												</Col>
											</Row>
										</div>
									</div>
									: null
								}
							</div>
						</div>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}

export default Chat;