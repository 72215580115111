import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";
import { FiltersModuleFinancialTransactionIndicators, FiltersModulePaymentsIndicators, findMetricInfo, findMetricInfoGraphic, sumColumnValues, sumColumnValuesWithoutBRL } from "../filters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical, faEyeSlash, faGripVertical, faMaximize, faMinimize } from '@fortawesome/pro-solid-svg-icons';
import { Checkbox, ListItemText, Menu, MenuItem } from "@material-ui/core";
import { Chart } from "react-google-charts";
import { formatBRL } from "../../../helpers/Utils";

export default class PaymentIndicatorSection extends Component {
	constructor() {
		super();

		this.state = {
			metrics: [
				[
					"Month",
					"Pago",
					"Inadimplente",
					"Atrasado",
					"Total"
				],
				["2024/01", 1000, 400, 200, 150],
				["2024/02", 1170, 460, 250, 180],
				["2024/03", 660, 1120, 300, 200],
				["2024/04", 1030, 540, 350, 220],
				["2024/05", 800, 600, 400, 240],
				["2024/06", 950, 650, 450, 260],
				["2024/07", 1200, 700, 500, 280],
				["2024/08", 1100, 750, 550, 300],
				["2024/09", 1300, 800, 600, 320],
				["2024/10", 1400, 850, 650, 340],
				["2024/11", 1500, 900, 700, 360],
				["2024/12", 1600, 950, 750, 380],
			],
			paymentsIndicatorsFilters: {
				"billing_type": "all",
				"time_period": "12_months",
				"graphic_data": "per_contract"
			},
			paymentsIndicatorsAvailableLabels: [
				{ label: "Pago", isActive: true, key: '', color: '#1CBB8C' },
				{ label: "Atrasado", isActive: true, key: '', color: '#FCB92C' },
				{ label: "Inadimplente", isActive: true, key: '', color: '#FF3D60' },
				{ label: "Total", isActive: true, key: '', color: '#D9D9D9' }
			],
			dropdownKey: null,
			anchorEl: null,
			maximeGraphic: false
		};
	}

	componentDidMount() {
		if (this?.props?.metrics) {
			this.setState({ metrics: this.props.metrics })
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.paymentsIndicatorsFilters !== this.state.paymentsIndicatorsFilters) {

			this.setState({
				financialTransactionsIndicatorsAvailableLabels: [
					{ label: "Pago", isActive: true, key: '', color: '#1CBB8C' },
					{ label: "Atrasado", isActive: true, key: '', color: '#FCB92C' },
					{ label: "Inadimplente", isActive: true, key: '', color: '#FF3D60' },
					{ label: "Total", isActive: true, key: '', color: '#D9D9D9' }
				]
			})

		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.layout?.w !== this.props.layout?.w) {
			setTimeout(() => window.dispatchEvent(new Event('resize')), 200);
		}
	}

	render() {
		const options = {
			chartArea: { width: "85%" },
			isStacked: true,
			colors: this.state.paymentsIndicatorsAvailableLabels.filter(labelObj => labelObj.isActive).length > 0 ? this.state.paymentsIndicatorsAvailableLabels.filter(labelObj => labelObj.isActive).map(labelObj => labelObj.color) : ['transparent'],
			legend: {
				position: "none",
				textStyle: {
					color: '#8590A5',
					fontSize: 12
				},
			},
			hAxis: {
				textStyle: {
					color: '#8590A5',
					fontSize: 12,
				},
			},
			vAxis: {
				textStyle: {
					color: '#8590A5',
					fontSize: 12,
				},
			},
		};

		return (
			<Card>
				<CardBody className="dashboard-item">
					<div className="box-header">
						<div className="">
							<h3 className="title">Gráfico de pagamentos</h3>
							<div className="box-info">
								Negociações
							</div>

							{this.props.layout?.w === 12 ? (
								<div className="box-filter" style={{ marginLeft: 16 }}>
									<FiltersModuleFinancialTransactionIndicators setState={this.setState.bind(this)} state={this.state.paymentsIndicatorsFilters} />
								</div>
							) : null}
						</div>

						<div>
							<FontAwesomeIcon icon={this.props.layout?.w === 6 ? faMaximize : faMinimize} style={{ color: '#5664D2', padding: '12px', cursor: 'pointer' }} onClick={() => this.props.toggleMaximize(this.props?.itemKey)} />
							<FontAwesomeIcon icon={faEllipsisVertical} style={{ color: '#5664D2', padding: '12px', cursor: 'pointer' }} onClick={(e) => {
								this.setState({ dropdownKey: this.props.itemKey });
								this.setState({ anchorEl: e.currentTarget });
							}} />
							<FontAwesomeIcon icon={faEyeSlash} style={{ color: '#5664D2', padding: '12px', cursor: 'pointer' }} onClick={() => this.props.changeWidgetValue(this.props.itemKey)} />
							<FontAwesomeIcon icon={faGripVertical} style={{ color: '#DADBDD', padding: '12px', cursor: 'pointer' }} />
						</div>
					</div>

					<Menu
						anchorEl={this.state.anchorEl}
						open={this.state.dropdownKey === this.props.itemKey ? true : false}
						onClose={() => { this.setState({ dropdownKey: null }) }}
						className="dashboard-item"
						id="dashboard-drop"
					>
						{this.state.paymentsIndicatorsAvailableLabels.map((item) => (
							<MenuItem key={item.label} value={item.label}>
								<Checkbox checked={item.isActive} onChange={() => {
									this.setState(prevState => ({
										paymentsIndicatorsAvailableLabels: prevState.paymentsIndicatorsAvailableLabels.map(i =>
											i.label === item.label
												? { ...i, isActive: !i.isActive }
												: i
										)
									}));
								}} />
								<ListItemText primary={item.label} />
							</MenuItem>
						))}
					</Menu>

					{this.props.layout?.w === 6 ? (
						<div className="box-filter">
							<FiltersModulePaymentsIndicators setState={this.setState.bind(this)} state={this.state.paymentsIndicatorsFilters} />
						</div>
					) : null}

					<div style={{ position: 'relative' }}>
						<hr className="divisor" />
					</div>

					<div className="chart-wrapper graphic">

						<div className="chart-graphic-wrapper">
							<Chart
								chartType="ColumnChart"
								key={this.state.maximeGraphic ? 'expanded' : 'collapsed'}
								width={this.props.layout?.w === 12 ? '100%' : '100%'}
								height="230px"
								data={findMetricInfoGraphic(this.state.metrics, [this.state.paymentsIndicatorsFilters.graphic_data, this.state.paymentsIndicatorsFilters.billing_type, this.state.paymentsIndicatorsFilters.time_period], 0, this.state.paymentsIndicatorsAvailableLabels)}
								options={options}
							/>
						</div>

						<div className="chart-graphic-legend">
							<div className="box-pie-legend" style={{ display: this.state.paymentsIndicatorsAvailableLabels.find(obj => obj.color === '#1CBB8C').isActive ? '' : 'none' }}>
								<div className="bg-green">
									<p>
										{sumColumnValues(findMetricInfoGraphic(this.state.metrics, [this.state.paymentsIndicatorsFilters.graphic_data, this.state.paymentsIndicatorsFilters.billing_type, this.state.paymentsIndicatorsFilters.time_period]), 1, this.state.paymentsIndicatorsFilters.graphic_data === 'per_values' ? true : false)}

									</p>
								</div>
								<div className="box-pie-legend-text" style={{ minWidth: this.state.paymentsIndicatorsFilters.graphic_data === 'per_values' ? '105px' : false, minWidth: 100, maxWidth: 130 }}>
									<p>Pago</p>
								</div>
							</div>

							<div className="box-pie-legend" style={{ display: this.state.paymentsIndicatorsAvailableLabels.find(obj => obj.color === '#FCB92C').isActive ? '' : 'none' }}>
								<div className="bg-yellow">
									<p>
										{sumColumnValues(findMetricInfoGraphic(this.state.metrics, [this.state.paymentsIndicatorsFilters.graphic_data, this.state.paymentsIndicatorsFilters.billing_type, this.state.paymentsIndicatorsFilters.time_period]), 2, this.state.paymentsIndicatorsFilters.graphic_data === 'per_values' ? true : false)}

									</p>
								</div>
								<div className="box-pie-legend-text" style={{ minWidth: this.state.paymentsIndicatorsFilters.graphic_data === 'per_values' ? '105px' : false, minWidth: 100, maxWidth: 130 }}>
									<p>Atrasado</p>
								</div>
							</div>

							<div className="box-pie-legend" style={{ display: this.state.paymentsIndicatorsAvailableLabels.find(obj => obj.color === '#FF3D60').isActive ? '' : 'none' }}>
								<div className="bg-red">
									<p>
										{sumColumnValues(findMetricInfoGraphic(this.state.metrics, [this.state.paymentsIndicatorsFilters.graphic_data, this.state.paymentsIndicatorsFilters.billing_type, this.state.paymentsIndicatorsFilters.time_period]), 3, this.state.paymentsIndicatorsFilters.graphic_data === 'per_values' ? true : false)}

									</p>
								</div>
								<div className="box-pie-legend-text" style={{ minWidth: this.state.paymentsIndicatorsFilters.graphic_data === 'per_values' ? '105px' : false, minWidth: 100, maxWidth: 130 }}>
									<p>Inadimplente</p>
								</div>
							</div>

							<div className="box-pie-legend" style={{ display: this.state.paymentsIndicatorsAvailableLabels.find(obj => obj.color === '#D9D9D9').isActive ? '' : 'none' }}>
								<div className="bg-gray">
									<p>
										{this.state.paymentsIndicatorsFilters.graphic_data === 'per_values' ? formatBRL(sumColumnValuesWithoutBRL(findMetricInfoGraphic(this.state.metrics, [this.state.paymentsIndicatorsFilters.graphic_data, this.state.paymentsIndicatorsFilters.billing_type, this.state.paymentsIndicatorsFilters.time_period]), 2) + sumColumnValuesWithoutBRL(findMetricInfoGraphic(this.state.metrics, [this.state.paymentsIndicatorsFilters.graphic_data, this.state.paymentsIndicatorsFilters.billing_type, this.state.paymentsIndicatorsFilters.time_period]), 3) + sumColumnValuesWithoutBRL(findMetricInfoGraphic(this.state.metrics, [this.state.paymentsIndicatorsFilters.graphic_data, this.state.paymentsIndicatorsFilters.billing_type, this.state.paymentsIndicatorsFilters.time_period]), 1)) : sumColumnValuesWithoutBRL(findMetricInfoGraphic(this.state.metrics, [this.state.paymentsIndicatorsFilters.graphic_data, this.state.paymentsIndicatorsFilters.billing_type, this.state.paymentsIndicatorsFilters.time_period]), 2) + sumColumnValuesWithoutBRL(findMetricInfoGraphic(this.state.metrics, [this.state.paymentsIndicatorsFilters.graphic_data, this.state.paymentsIndicatorsFilters.billing_type, this.state.paymentsIndicatorsFilters.time_period]), 3) + sumColumnValuesWithoutBRL(findMetricInfoGraphic(this.state.metrics, [this.state.paymentsIndicatorsFilters.graphic_data, this.state.paymentsIndicatorsFilters.billing_type, this.state.paymentsIndicatorsFilters.time_period]), 1)}
									</p>
								</div>
								<div className="box-pie-legend-text" style={{ minWidth: this.state.paymentsIndicatorsFilters.graphic_data === 'per_values' ? '105px' : false, minWidth: 100, maxWidth: 130 }}>
									<p>Total</p>
								</div>
							</div>
						</div>
					</div>
				</CardBody>
			</Card>
		)
	}
}

