import React, { Component } from 'react';
import { Container, Card, CardBody, Col, FormGroup, Label, Button } from "reactstrap";
import BetterCircularProgress from '../../components/BetterCircularProgress';
import { Link, Redirect } from "react-router-dom";
import { Formik } from "formik";
import BetterAlerts from '../../components/BetterAlerts';
import { validateSmsConfig } from '../../helpers/FormValidators';
import { TextField } from "@material-ui/core";
import Breadcrumbs from '../../components/Breadcrumb';
import { getAllTemplates, storeVariable, updateTemplate } from '../../crud/templates.crud';

class HSMConfig extends Component {

	state = {
		submitted: false,
		success: false,
		errors: [],
		loading: false,
		hsm_crawler_proprietarios: null,
		tallos_integration: null,
		template_nao_convertidos: null,
		crawler_approved: null,
		crawler_not_converted: null,
	};

	componentDidMount = async () => {
		this.setState({ loading: true });

		let crawler_approved = null;
		let crawler_not_converted = null;

		await getAllTemplates({ category: 'crawler_approved' }).catch(err => { }).then(res => {
			if (res?.data?.total > 0) {
				crawler_approved = res.data.data[0];
			}
		});

		await getAllTemplates({ category: 'crawler_not_converted' }).catch(err => { }).then(res => {
			if (res?.data?.total > 0) {
				crawler_not_converted = res.data.data[0];
			}
		});

		this.setState({ loading: false, crawler_approved, crawler_not_converted });
	};

	getInitialValues = () => {
		return {
			crawler_approved: this.state?.crawler_approved?.text ?? '',
			crawler_not_converted: this.state?.crawler_not_converted?.text ?? '',
		};
	};

	onSubmit = async (values) => {
		this.setState({ submitted: true });

		let error = null;
		const regex = /\{([^}]+)\}/;

		if (values.crawler_approved != this.state?.crawler_approved?.text) {
			let variableId = this.state.crawler_approved.variables;

			const oldMatch = regex.exec(this.state?.crawler_approved?.text);
			const newMatch = regex.exec(values.crawler_approved);
			const oldParam = oldMatch ? oldMatch[1] : null;
			const newParam = newMatch ? newMatch[1] : null;

			if (oldParam != newParam && newParam) {
				storeVariable().catch(err => { }).then(res => {
					if (res?.data?.data?.id) {
						variableId = res?.data?.data?.id;
					}
				});
			}

			await updateTemplate(this.state?.crawler_approved?.id, {
				category: 'crawler_approved',
				text: values.crawler_approved,
				variables: variableId
			}).catch(err => {
				console.log(err);
			}).then(res => {
				if (res?.status == 200) {
					error = res.data.error;
				}
			});
		}

		if (values.crawler_not_converted != this.state?.crawler_not_converted?.text) {
			let variableId = this.state.crawler_not_converted.variables;

			const oldMatch = regex.exec(this.state?.crawler_not_converted?.text);
			const newMatch = regex.exec(values.crawler_not_converted);
			const oldParam = oldMatch ? oldMatch[1] : null;
			const newParam = newMatch ? newMatch[1] : null;

			if (oldParam != newParam && newParam) {
				storeVariable().catch(err => { }).then(res => {
					if (res?.data?.data?.id) {
						variableId = res?.data?.data?.id;
					}
				});
			}

			await updateTemplate(this.state?.crawler_not_converted?.id, {
				category: 'crawler_not_converted',
				text: values.crawler_not_converted,
				variables: variableId
			}).catch(err => {
				console.log(err);
			}).then(res => {
				if (res?.status == 200) {
					error = res.data.error;
				}
			});
		}

		this.setState({ loading: false, submitted: false, success: !Boolean(error), errors: error ? [error] : [] });
	};

	render() {
		return (
			<React.Fragment>
				<BetterCircularProgress loading={this.state.loading}>
					<div className="page-content container-page">
						<Container fluid >
							<Breadcrumbs title="Configurar SMS" breadcrumbItems={[]} />
							<BetterAlerts errors={this.state.errors} />

							{this.state.success ?
								<Redirect to={{ pathname: '/crawlerConfig/index', state: { success: ['HSM alterado com sucesso!'] } }} />
								: null
							}

							<Formik initialValues={this.getInitialValues()} validate={values => validateSmsConfig(values)} onSubmit={(values) => this.onSubmit(values)}>
								{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
									<form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>
										<Card>
											<CardBody>
												<div className="row">
													<Col md={12}>
														<p style={{ fontSize: '16px', fontWeight: '600', color: '#252B3B' }}>Envio de templates <span style={{ marginLeft: '8px', color: '#505D69', fontSize: '13px', fontWeight: '400' }}>Digite abaixo os textos de template que o crawler deve disparar.</span></p>
													</Col>

													<Col md={12}>
														<FormGroup row>
															<Label className="col-md-12 col-form-label">SMS Crawler aprovação proprietários *</Label>
															<Col md={12} className="container-center-input">
																<TextField name="crawler_approved" type="text" variant="outlined" value={values.crawler_approved} onBlur={handleBlur} onChange={handleChange}
																	helperText={touched.crawler_approved && errors.crawler_approved} error={Boolean(touched.crawler_approved && errors.crawler_approved)}
																	placeholder='Digite o texto para ser enviado por SMS' />
															</Col>
														</FormGroup>
													</Col>

													<Col md={12}>
														<FormGroup row>
															<Label className="col-md-12 col-form-label">SMS Crawler não convertidos *</Label>
															<Col md={12} className="container-center-input">
																<TextField name="crawler_not_converted" type="text" variant="outlined" value={values.crawler_not_converted} onBlur={handleBlur} onChange={handleChange}
																	helperText={touched.crawler_not_converted && errors.crawler_not_converted} error={Boolean(touched.crawler_not_converted && errors.crawler_not_converted)}
																	placeholder='Digite o texto para ser enviado por SMS' />
															</Col>
														</FormGroup>
													</Col>
												</div>
											</CardBody>
										</Card>

										<Card>
											<CardBody className="container-space-between">
												<Link to='/crawlerConfig/index' className="btn btn-danger btn-bold"><i className="fa fa-arrow-left mr-2"></i>Voltar</Link>

												<Button color="success" type="submit" className="waves-effect waves-light mr-10">
													<i className={`mr-2 align-middle ${this.state.submitted ? "fas fa-sync fa-spin" : "ri-save-3-line"}`}></i>Salvar
												</Button>
											</CardBody>
										</Card>
									</form>
								)}
							</Formik>
						</Container>
					</div>
				</BetterCircularProgress>
			</React.Fragment>
		);
	}
}

export default HSMConfig;