
import axios from "axios";
import { Constants, baseUrl } from '../helpers/Constants';
import { parseQuery } from '../helpers/Utils';

export function getDashboard() {
	return axios.get(`${Constants.dashboardUrl}/dashboard`);
};

export function updateDashboard(widgets) {
	return axios.post(`${Constants.dashboardUrl}/dashboard`, { widgets });
};

export function getDashboardBaseLayout() {
	return axios.get(`${Constants.dashboardUrl}/dashboard/base-layout`);
};

export function getDashboardTypes() {
	return axios.get(`${Constants.dashboardUrl}/dashboard/types`);
};

export function getMetrics() {
	return axios.get(`${Constants.dashboardUrl}/metrics`);
};

export function getPropertiesPerLandlord(params) {
	return axios.get(`${Constants.baseUrl}/announcements/properties/property-per-landlord`, { params: parseQuery({ ...params }) });
}