import React, { Component } from 'react';
import { Container, Card, CardBody, Row, Col, FormGroup, Label, Button, Input, ButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from "reactstrap";
import Breadcrumbs from '../../components/Breadcrumb';
import { getAllByFilter } from '../../crud/coupons.crud';
import _ from 'lodash';
import { PlansFilterComponent } from '../negotiation/components/plans.component';
import { UserFilterComponentObsolete } from '../negotiation/components/user.component';
import DataTableCoupons from '../../components/datatables/DataTableCoupons';
import { Link } from "react-router-dom";
import { get as getPlans } from "../../crud/plans.crud";

class CouponsIndex extends Component {

	state = {
		filters: {
			user: '',
			code: '',
			applied: '',
			expiration_date: '',
			plan: '',
			discount: '',
		},
		flags: {
			show_user: false,
			show_code: '',
			show_applied: false,
			show_expiration_date: false,
			show_plan: false,
			show_discount: '',
		},
		plan_list: [],
		is_filter_open: false,
		export: false,
	}

	componentDidMount = () => {
		let filters = this.state.filters;
		let flags = this.state.flags;

		if (localStorage.getItem('couponsFilters')) {
			filters = JSON.parse(localStorage.getItem('couponsFilters'));
		};

		if (localStorage.getItem('couponsFlags')) {
			flags = JSON.parse(localStorage.getItem('couponsFlags'));
		};

		getPlans().then(({ data }) => {
			this.setState({ plan_list: data.data });
		});

		this.setState({ filters, flags });
	};

	saveFilters = () => {
		localStorage.setItem('couponsFilters', JSON.stringify(this.state.filters));
		localStorage.setItem('couponsFlags', JSON.stringify(this.state.flags));
	};

	fetchByFilter = (data) => {
		let params = {
			...data,
			user_id: this.state.flags.show_user ? this.state.filters.user.id : undefined,
		};

		if (this.state.flags.show_discount && this.state.discount != '') params.discount_amount = this.state.filters.discount;
		if (this.state.flags.show_code && this.state.filters.code.trim() != '') params.code = this.state.filters.code;
		if (this.state.flags.show_expiration_date && this.state.filters.expiration_date.trim() != '') params.expiration_date = this.state.filters.expiration_date;

		if (this.state.flags.show_plan && this.state.filters.plan && this.state.plan_list?.length > 0) {
			let plan_id = '';

			this.state.plan_list.forEach((obj, i) => {
				if (obj.name === this.state.filters.plan) plan_id = obj.id;
			});

			if (plan_id) params.plan_id = plan_id;
		}

		if (this.state.flags.show_applied && this.state.filters.applied) {
			if (this.state.filters.applied === 'Sim') {
				params.application_count_gte = 1;
			} else {
				params.application_count_lt = 1;
			}
		}

		return getAllByFilter(params);
	};

	render() {
		return (
			<React.Fragment>
				<div className="page-content container-page">
					<Container fluid>
						<Breadcrumbs breadcrumbItems={[{ link: '/coupons/index', title: 'Cupom' }]} />

						<Row className="align-right mb-30">
							<ButtonDropdown className="mr-10 wm-100" isOpen={this.state.is_filter_open} toggle={() => this.setState({ is_filter_open: !this.state.is_filter_open })} >
								<DropdownToggle color="primary" caret>
									<i className="ri-filter-3-line align-middle mr-2"></i> {"ADD FILTRO"}
								</DropdownToggle>
								<DropdownMenu className="btn-index-action dropdown-menu-right">
									{!this.state.flags.show_expiration_date ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_expiration_date: true } })}>Data de expiração</DropdownItem> : null}
									{!this.state.flags.show_code ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_code: true } })}>Código</DropdownItem> : null}
									{!this.state.flags.show_user ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_user: true } })}>Usuário</DropdownItem> : null}
									{!this.state.flags.show_plan ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_plan: true } })}>Plano</DropdownItem> : null}
									{!this.state.flags.show_applied ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_applied: true } })}>Aplicado</DropdownItem> : null}
									{!this.state.flags.show_discount ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_discount: true } })}>Desconto</DropdownItem> : null}
								</DropdownMenu>
							</ButtonDropdown>

							<Link to={`/coupons/new`} className="btn-index-action button btn btn-success waves-effect waves-light mr-10">
								<i className="ri-add-line align-middle mr-2"></i> CRIAR CUPOM
							</Link>
						</Row>

						<Row className="container-center-input">
							{this.state.flags.show_user ?
								<UserFilterComponentObsolete
									label="Usuário"
									onChange={(e, value) => {
										this.setState({ filters: { ...this.state.filters, user: value } });
										this.saveFilters();
									}}
									value={this.state.filters.user}
									onClick={
										() => this.setState(
											{
												flags: { ...this.state.flags, show_user: false },
												filters: { ...this.state.filters, user: {} }
											},
											() => this.saveFilters()
										)
									}
								/>
								: false
							}

							{this.state.flags.show_plan ?
								<PlansFilterComponent
									label="Plano"
									onChange={(e) => {
										this.setState({ filters: { ...this.state.filters, plan: e.target.value } });
										this.saveFilters();
									}}
									value={this.state.filters.plan}
									onClick={
										() => this.setState(
											{
												flags: { ...this.state.flags, show_plan: false },
												filters: { ...this.state.filters, plan: undefined }
											},
											() => this.saveFilters()
										)
									}
								/>
								: false
							}

							{this.state.flags.show_discount ?
								<Col md={6}>
									<Label className="col-form-label">Desconto</Label>
									<FormGroup row>
										<div className="col-10 col-sm-8 container-center-input">
											<Input className="form-control" type="text" value={this.state.filters.discount}
												onChange={(e) => this.setState({ filters: { ...this.state.filters, discount: e.target.value } }, () => this.saveFilters())} />
										</div>
										<Button onClick={() => this.setState({ flags: { ...this.state.flags, show_discount: false }, filters: { ...this.state.filters, discount: '' } }, () => this.saveFilters())} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
											<i className="ri-close-circle-line align-middle"></i>
										</Button>
									</FormGroup>
								</Col>
								: false
							}

							{this.state.flags.show_code ?
								<Col md={6}>
									<Label className="col-form-label">Código</Label>
									<FormGroup row>
										<div className="col-10 col-sm-8 container-center-input">
											<Input className="form-control" type="text" value={this.state.filters.code}
												onChange={(e) => this.setState({ filters: { ...this.state.filters, code: e.target.value } }, () => this.saveFilters())} />
										</div>
										<Button onClick={() => this.setState({ flags: { ...this.state.flags, show_code: false }, filters: { ...this.state.filters, code: '' } }, () => this.saveFilters())} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
											<i className="ri-close-circle-line align-middle"></i>
										</Button>
									</FormGroup>
								</Col>
								: false
							}

							{this.state.flags.show_expiration_date ?
								<Col md={6}>
									<Label htmlFor="expiration_date" className="col-form-label">Data de expiração</Label>
									<FormGroup row>
										<div className="col-10 col-sm-8 container-center-input">
											<Input className="form-control" type="date" id="expiration_date" value={this.state.expiration_date}
												onChange={(e) => this.setState({ filters: { ...this.state.filters, expiration_date: e.target.value } }, () => this.saveFilters())} />
										</div>
										<Button onClick={() => this.setState({ flags: { ...this.state.flags, show_expiration_date: false }, filters: { ...this.state.filters, expiration_date: '' } }, () => this.saveFilters())} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
											<i className="ri-close-circle-line align-middle"></i>
										</Button>
									</FormGroup>
								</Col>
								: false
							}

							{this.state.flags.show_applied ?
								<Col md={4}>
									<Label className="col-form-label" style={{ color: '#343A40' }}>
										Aplicado
									</Label>
									<FormGroup row>
										<Col>
											<select
												value={this.state.filters.applied}
												onChange={(e) => this.setState({ filters: { ...this.state.filters, applied: e.target.value } }, () => this.saveFilters())}
												className="custom-select">
												<option value=""></option>
												<option value="Sim">Sim</option>
												<option value="Não">Não</option>
											</select>
										</Col>
										<Button onClick={() => this.setState({ flags: { ...this.state.flags, show_applied: false }, filters: { ...this.state.filters, applied: '' } }, () => this.saveFilters())} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
											<i className="ri-close-circle-line align-middle"></i>
										</Button>
									</FormGroup>
								</Col>
								: false
							}

							<Col lg={12}>
								<Card>
									<CardBody>
										<DataTableCoupons filters={this.state.filters} noDelete fetchEvent={this.fetchByFilter} crudButtons={true}
											crudUrl="/coupons" idRow={`id`} noShow export={this.state.export} />
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		)
	}
}

export default CouponsIndex;