import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";
import { FiltersModuleBadDebtsIndicators, findMetricInfo } from "../filters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical, faEyeSlash, faGripVertical, faMaximize, faMinimize } from '@fortawesome/pro-solid-svg-icons';
import { Checkbox, ListItemText, Menu, MenuItem } from "@material-ui/core";

export default class BadDebtIndicatorSection extends Component {
	constructor() {
		super();

		this.state = {
			metrics: [],
			badDebtIndicatorsFilters: {
				"billing_type": "all",
				"time_frame": "always"
			},
			badDebtIndicatorsAvailableLabels: [
				{ label: "PAGAMENTOS ATRASADOS", isActive: true },
				{ label: "PAGAMENTOS INADIMPLENTES", isActive: true },
				{ label: "PAGAMENTOS JUDICIALIZADOS", isActive: true },
			],
			dropdownKey: null,
			anchorEl: null,
		};
	}

	componentDidMount() {
		if (this?.props?.metrics) {
			this.setState({ metrics: this.props.metrics })
		}
	}

	render() {
		return (
			<Card>
				<CardBody className="dashboard-item">
					<div className="box-header">
						<div className="">
							<h3 className="title">Indicadores de inadimplência</h3>
							<div className="box-info">
								Financeiro
							</div>
						</div>

						<div>
							<FontAwesomeIcon icon={this.props.layout?.w === 6 ? faMaximize : faMinimize} style={{ color: '#5664D2', padding: '12px', cursor: 'pointer' }} onClick={() => this.props.toggleMaximize(this.props?.itemKey)} />
							<FontAwesomeIcon icon={faEllipsisVertical} style={{ color: '#5664D2', padding: '12px', cursor: 'pointer' }} onClick={(e) => {
								this.setState({ dropdownKey: this.props.itemKey });
								this.setState({ anchorEl: e.currentTarget });
							}} />
							<FontAwesomeIcon icon={faEyeSlash} style={{ color: '#5664D2', padding: '12px', cursor: 'pointer' }} onClick={() => this.props.changeWidgetValue(this.props.itemKey)} />
							<FontAwesomeIcon icon={faGripVertical} style={{ color: '#DADBDD', padding: '12px', cursor: 'pointer' }} />
						</div>
					</div>

					<Menu
						anchorEl={this.state.anchorEl}
						open={this.state.dropdownKey === this.props.itemKey ? true : false}
						onClose={() => { this.setState({ dropdownKey: null }) }}
						className="dashboard-item"
						id="dashboard-drop"
					>
						{this.state.badDebtIndicatorsAvailableLabels.map((item) => (
							<MenuItem key={item.label} value={item.label}>
								<Checkbox checked={item.isActive} onChange={() => {
									this.setState(prevState => ({
										badDebtIndicatorsAvailableLabels: prevState.badDebtIndicatorsAvailableLabels.map(i =>
											i.label === item.label
												? { ...i, isActive: !i.isActive }
												: i
										)
									}));
								}} />
								<ListItemText primary={item.label} />
							</MenuItem>
						))}
					</Menu>


					<div className="box-filter">
						<FiltersModuleBadDebtsIndicators setState={this.setState.bind(this)} state={this.state.badDebtIndicatorsFilters} />
					</div>

					<div style={{ position: 'relative' }}>
						<hr className="divisor" />
					</div>

					<div className="card-wrapper" style={{ marginBottom: 58 }}>
						<div className="card-box" style={{ display: this.state.badDebtIndicatorsAvailableLabels.find(obj => obj?.label === 'PAGAMENTOS ATRASADOS')?.isActive ? '' : 'none' }}>
							<h3>PAGAMENTOS ATRASADOS</h3>

							<h2>
								<span style={{ fontSize: 13, marginTop: 8 }}>R$</span>
								{findMetricInfo(this.state.metrics, [this.state.badDebtIndicatorsFilters.billing_type, this.state.badDebtIndicatorsFilters.time_frame], 'BAD_DEBT_INDICATOR_OVERDUE', 'sum').replace('R$', '')}
							</h2>

							<div className="card-value card-value-yellow">
								{findMetricInfo(this.state.metrics, [this.state.badDebtIndicatorsFilters.billing_type, this.state.badDebtIndicatorsFilters.time_frame], 'BAD_DEBT_INDICATOR_OVERDUE', 'count')} contratos
							</div>
						</div>
						<div className="card-box" style={{ display: this.state.badDebtIndicatorsAvailableLabels.find(obj => obj?.label === 'PAGAMENTOS INADIMPLENTES')?.isActive ? '' : 'none' }}>
							<h3>PAGAMENTOS INADIMPLENTES</h3>

							<h2 >
								<span style={{ fontSize: 13, marginTop: 8 }}>R$</span>
								{findMetricInfo(this.state.metrics, [this.state.badDebtIndicatorsFilters.billing_type, this.state.badDebtIndicatorsFilters.time_frame], 'BAD_DEBT_INDICATOR_BAD_DEBT', 'sum').replace('R$', '')}
							</h2>

							<div className="card-value card-value-red">
								{findMetricInfo(this.state.metrics, [this.state.badDebtIndicatorsFilters.billing_type, this.state.badDebtIndicatorsFilters.time_frame], 'BAD_DEBT_INDICATOR_BAD_DEBT', 'count')} faturas
							</div>
						</div>
						<div className="card-box" style={{ display: this.state.badDebtIndicatorsAvailableLabels.find(obj => obj?.label === 'PAGAMENTOS JUDICIALIZADOS')?.isActive ? '' : 'none' }}>
							<h3>PAGAMENTOS JUDICIALIZADOS</h3>

							<h2>
								<span style={{ fontSize: 13, marginTop: 8 }}>R$</span>
								{findMetricInfo(this.state.metrics, [this.state.badDebtIndicatorsFilters.billing_type, this.state.badDebtIndicatorsFilters.time_frame], 'BAD_DEBT_INDICATOR_EXTRAJUDICIAL', 'sum').replace('R$', '')}
							</h2>

							<div className="card-value card-value-brown">
								{findMetricInfo(this.state.metrics, [this.state.badDebtIndicatorsFilters.billing_type, this.state.badDebtIndicatorsFilters.time_frame], 'BAD_DEBT_INDICATOR_EXTRAJUDICIAL', 'count')} contratos
							</div>
						</div>

					</div>
				</CardBody>
			</Card>
		)
	}
}


