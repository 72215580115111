import axios from "axios";
import { Constants } from '../helpers/Constants';
import { parseQuery } from '../helpers/Utils';

export function getAllJobs() {
	return axios.get(`${Constants.baseUrl}/crawler/olx/job`);
}

export function getJob(params) {
	return axios.get(`${Constants.baseUrl}/crawler/olx/job`, { params: parseQuery(params) });
}

export function disableJob(params) {
	return axios.patch(`${Constants.baseUrl}/crawler/olx/job`, { ...params, disable: true });
}

export function enableJob(params) {
	return axios.patch(`${Constants.baseUrl}/crawler/olx/job`, { ...params, disable: false });
}

export function deleteJob(params) {
	return axios.delete(`${Constants.baseUrl}/crawler/olx/job`, { params: parseQuery(params) });
}

export function createJob(data) {
	return axios.post(`${Constants.baseUrl}/crawler/olx/job`, data);
}

export function getConfig(params) {
	return axios.get(`${Constants.baseUrl}/crawler/config/get`, { params: parseQuery(params) });
}

export function storeConfig(data) {
	return axios.post(`${Constants.baseUrl}/crawler/config`, data);
}