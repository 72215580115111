import axios from "axios";
import { Constants } from '../helpers/Constants';
import { parseQuery } from '../helpers/Utils';
import moment from "moment";

export function getCalcDebts(id, params) {
	return axios.get(`${Constants.baseUrl}/billings/calc-debts/${id}`, { params: parseQuery(params) });
}

export function get(params) {
	return axios.get(`${Constants.baseUrl}/billings`, { params: parseQuery(params) });
}

export function getTotalAdmin(data) {
	return axios.post(`${Constants.baseUrl}/billings/total-billing`, data);
}

export function getById(id) {
	return axios.get(`${Constants.baseUrl}/billings/${id}`);
}

export function update(id, data) {
	return axios.patch(`${Constants.baseUrl}/billings/${id}`, data);
}

export function createRentBoleto(id, data) {
	return axios.post(`${Constants.baseUrl}/billings/${id}/recreate-rent-boleto`, data);
}

export function uploadBoleto(url) {
	return axios.patch(url);
}

export function resendEmail(data) {
	return axios.post(`${Constants.baseUrl}/billings/recreate-billing-notification`, data);
}

//repayments
export const createRepayment = (data) => {
	return axios.post(`${Constants.baseUrl}/repayment/`, data);
}

export const createImageRepayment = (id, data) => {
	return axios.post(`${Constants.baseUrl}/repayment/${id}/images`, data);
}

export const responseRepayment = (id, data) => {
	return axios.patch(`${Constants.baseUrl}/repayment/${id}`, data);
}

export const listRepayments = (params) => {
	return axios.get(`${Constants.baseUrl}/repayment`, {
		params: parseQuery(params),
	});
}

export const getRepayment = (id) => {
	return axios.get(`${Constants.baseUrl}/repayment/${id}`);
}

//Falha Pagamento
export const getFalhaPagamento = (params) => {
	return axios.get(`${Constants.baseUrl}/payment/asaas/transfers?fail_reason_ne=null`, {
		params: parseQuery(params),
	});
}

//Inadimplente
export const getInadimplente = (params) => {
	return axios.get(`${Constants.baseUrl}/billings?is_paid=false`, {
		params: parseQuery(params),
	});
}

// Atualizar para garantido
export const updateToInsured = (id) => {
	return axios.patch(`${Constants.baseUrl}/billings/mark-as-paid-by-insurer/${id}`);
}

// Boletos
export const getBoleto = (params) => {
	return axios.get(`${Constants.baseUrl}/boleto`, {
		params: parseQuery(params),
	});
}

//Indices
export const getReadjustmentIndices = (params => {
	return axios.get(`${Constants.baseUrl}/readjustment-indices`, {
		params: parseQuery(params),
	});
})

export const updateIndice = ((id, data) => {
	return axios.patch(`${Constants.baseUrl}/readjustment-indices/${id}`, data);
})

export const createFriendlyAgreement = (data) => {
	return axios.post(`${Constants.baseUrl}/friendly-agreement`, data);
}

//Acordos Inadimplentes
export const getAcordosInadimplentes = (params) => {
	return axios.get(`${Constants.baseUrl}/friendly-agreement/bad-debts`, {
		params: parseQuery(params),
	});
}

export const getFriendlyAgreement = (params) => {
	return axios.get(`${Constants.baseUrl}/friendly-agreement`, {
		params: parseQuery(params),
	});
}

export const getFriendlyAgreementParcela = (id) => {
	return axios.get(`${Constants.baseUrl}/friendly-agreement/${id}`);
}

export const getFriendlyAgreementLog = (params) => {
	return axios.get(`${Constants.baseUrl}/friendly-agreement-query-agreement-logs`, { params: parseQuery(params) });
};

export const getFriendlyAgreementUsers = (params) => {
	return axios.get(`${Constants.baseUrl}/friendly-agreement/users`, { params: parseQuery(params) });
};

export function cancelFriendlyAgreement(id) {
	return axios.delete(`${Constants.baseUrl}/friendly-agreement/${id}`);
}

export const getWebhookErrors = (params) => {
	return axios.get(`${Constants.baseUrl}/webhook/asaas/errors`, {
		params: parseQuery(params),
	});
}

export const getAcordos = (params) => {
	return axios.get(`${Constants.baseUrl}/billings`, {
		params: parseQuery({ ...params, type: 'agreement' }),
	});
}

export const getAgreementInfos = (params) => {
	return axios.get(`${Constants.baseUrl}/friendly-agreement/get-infos?$paranoid=false`, {
		params: parseQuery(params),
	});
}

export const getWebhookError = (id) => {
	return axios.get(`${Constants.baseUrl}/webhook/asaas/errors/${id}`);
}

export const removeWebhookError = (id) => {
	return axios.delete(`${Constants.baseUrl}/webhook/asaas/errors/${id}`);
}

export const getAgreementBillsInfos = (params) => {
	return axios.get(`${Constants.baseUrl}/billings/agreement-infos`, {
		params: parseQuery(params),
	});
}

export const getBillingSplitData = (billing_id) => {
	return axios.get(`${Constants.baseUrl}/payment/asaas/transfers/get-split-data/${billing_id}`);
}


export const getSplitData = (id) => {
	return axios.get(`${Constants.baseUrl}/payment/asaas/transfers/get-split-data/${id}`);
}

export const getSecShipment = (params) => {
	return axios.get(`${Constants.baseUrl}/sec-shipment`, {
		params: parseQuery(params),
	});
}

export const getSecReturn = (params) => {
	return axios.get(`${Constants.baseUrl}/sec-return`, {
		params: parseQuery(params),
	});
}

export const getSecReturnById = (id) => {
	return axios.get(`${Constants.baseUrl}/sec-return/${id}`);
}

export const createNewShipment = (data) => {
	return axios.post(`${Constants.baseUrl}/sec-shipment`, data);
}

export const deleteSecShipment = (id) => {
	return axios.delete(`${Constants.baseUrl}/sec-shipment/${id}`);
}

export const getSecBillings = (params) => {
	return axios.get(`${Constants.baseUrl}/sec-billings`, {
		params: parseQuery(params),
	});
}

export const getSecShipmentById = (id) => {
	return axios.get(`${Constants.baseUrl}/sec-shipment/${id}`);
}

export const getReturnPreview = (params) => {
	return axios.get(`${Constants.baseUrl}/sec-return/preview/`, {
		params: parseQuery(params),
	});
}

export const uploadRetFile = (data) => {
	return axios.post(`${Constants.baseUrl}/sec-return/file/`, data);
}

export const joinRetFile = (data) => {
	return axios.post(`${Constants.baseUrl}/sec-return/`, data);
}

export const downloadRemFile = (id) => {
	return axios.get(`${Constants.baseUrl}/sec-shipment/file/${id}`, {
		responseType: 'blob'
	});
}

// Anticipations
export function getAnticipations(params) {
	return axios.get(`${Constants.baseUrl}/anticipations`, { params: parseQuery(params) });
}

export function createAnticipation(data) {
	return axios.post(`${Constants.baseUrl}/anticipations`, data);
}

export function calcAnticipationValues(data) {
	return axios.post(`${Constants.baseUrl}/anticipations/calc`, data);
}

export function getAnticipation(id) {
	return axios.get(`${Constants.baseUrl}/anticipations/${id}`);
}

export function getAnticipationsCounters(id) {
	return axios.get(`${Constants.baseUrl}/anticipations/counters/${id}`);
}

export function uploadAnticipation(id, data) {
	return axios.post(`${Constants.baseUrl}/anticipations/file/${id}`, data);
}

export function getAnticipationsFile(id) {
	return axios.get(`${Constants.baseUrl}/anticipations/file/${id}`);
}