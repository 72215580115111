import React, { Component } from 'react';
import { Container, Card, CardBody, Col, FormGroup, Label, Button } from "reactstrap";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from "@material-ui/core";
import _ from 'lodash';
import { getByFilter as getUsers } from '../../crud/users.crud';
import { store as storeVoucher } from '../../crud/vouchers.crud';
import { Link, Redirect } from "react-router-dom";
import { Formik } from "formik";
import BetterAlerts from '../../components/BetterAlerts';
import { validateFormVouchers } from '../../helpers/FormValidators';
import Breadcrumbs from '../../components/Breadcrumb';

class VouchersNew extends Component {

    state = {
        submitted: false,
        success: false,
        errors: [],
        list_users: [],
        termo_user: '',
    }

    onUserInputChange = (termo) => {
        let filters = { ...this.state.filters };

        if (termo === '') {
            filters.user = '';
        }

        this.setState({ termo_user: termo, filters });
        this.onChangeUser();
    }

    onChangeUser = _.debounce(() => {
        this.buscarUsuarios(this.state.termo_user);
    }, 500);

    buscarUsuarios = (termo) => {
        if (termo.trim() === '') {
            this.setState({ list_users: [] });
        } else {
            getUsers({ $limit: 20, $skip: 0, email_ilike: termo }).then(res => {
                this.setState({ list_users: res.data.data });
            });
        }
    }

    onSubmit = (values) => {
        this.setState(({ submitted: true }));

        let data = {
            code: values.code,
            expiration_date: values.expiration_date,
            owner_id: values.user.id,
        };

        storeVoucher(data).catch((err) => {
            this.setState({ submitted: false, success: false, errors: err.response.data.error ? [err.response.data.error] : [] })
        }).then(res => {
            if (res?.status === 200) {
                this.setState({ submitted: false, success: !Boolean(res.data.error), errors: res.data.error ? [res.data.error] : [] })
            }
        });
    };

    getInitialValues = () => {
        const date = new Date();
        let month = "";
        if (date.getMonth() < 10) {
            month = "0" + date.getMonth();
        } else {
            month = date.getMonth();
        }
        
        const initialDate = (date.getFullYear() + 1)+ "-" + month + "-" + date.getDate();
        
        return {
            code: "VOUDEMELLRO",
            user: "",
            expiration_date: initialDate,
        };
    };

    render() {
        return (
            <React.Fragment>
                <div className="page-content container-page">
                    <Container fluid>
                        <Breadcrumbs title="Criar voucher" breadcrumbItems={[]} />
                        <BetterAlerts errors={this.state.errors} />

                        {this.state.success ?
                            <Redirect to={{ pathname: '/vouchers/index', state: { success: ['Voucher successfuly added!'] } }} />
                            : null
                        }

                        <Formik initialValues={this.getInitialValues()} validate={values => validateFormVouchers(values)} onSubmit={(values) => this.onSubmit(values)}>
                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
                                <form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>
                                    <Card>
                                        <CardBody>
                                            <div className="row">
                                                <Col md={6}>
                                                    <FormGroup row>
                                                        <Label className="col-md-4 col-form-label">Código</Label>
                                                        <Col md={8}>
                                                            <TextField name="code" type="text" variant="outlined" value={values.code} onBlur={handleBlur} onChange={handleChange}
                                                                helperText={touched.code && errors.code} error={Boolean(touched.code && errors.code)} />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>

                                                <Col md={6}>
                                                    <FormGroup row>
                                                        <Label className="col-md-4 col-form-label">Usuário</Label>
                                                        <Col md={8} >
                                                            <div className="form-group fg-line mb-0">
                                                                <Autocomplete disableClearable noOptionsText="No results." value={values.user}
                                                                    onChange={(e, value) => { setFieldValue("user", value); this.setState({ list_users: [] }) }}
                                                                    onInputChange={(event, termo) => this.onUserInputChange(termo)} options={this.state.list_users}
                                                                    getOptionLabel={(option) => option ? option.name + ' - ' + option.email : ''} onBlur={() => setFieldTouched('user', true)}
                                                                    renderInput={(params) => <TextField {...params} variant="outlined" helperText={touched.user && errors.user} error={Boolean(touched.user && errors.user)} />} />
                                                            </div>
                                                        </Col>
                                                    </FormGroup>
                                                </Col>

                                                <Col md={6}>
                                                    <FormGroup row>
                                                        <Label className="col-md-4 col-form-label">Data de validade</Label>
                                                        <Col md={8} className="container-center-input">
                                                            <TextField name="expiration_date" type="date" variant="outlined" value={values.expiration_date} onBlur={handleBlur} onChange={handleChange}
                                                                helperText={touched.expiration_date && errors.expiration_date} error={Boolean(touched.expiration_date && errors.expiration_date)} />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </div>
                                        </CardBody>
                                    </Card>

                                    <Card>
                                        <CardBody className="container-space-between">
                                            <Link to='/vouchers/index' className="btn btn-danger btn-bold"><i className="fa fa-arrow-left mr-2"></i>Voltar</Link>

                                            <Button color="success" type="submit" className="waves-effect waves-light mr-10">
                                                <i className="ri-save-3-line align-middle mr-2"></i>Salvar
                                            </Button>
                                        </CardBody>
                                    </Card>
                                </form>
                            )}
                        </Formik>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default VouchersNew;