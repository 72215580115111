import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { get as getContract } from '../../crud/contracts.crud';
import { formatBRL } from "../../helpers/Utils";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import moment from "moment";
import { Button } from "reactstrap";
import { createRentBoleto, getById as getBillingById } from "../../crud/billings.crud";
import smile from '../../assets/images/icons-custom/face-smile.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faRedo } from "@fortawesome/pro-regular-svg-icons";
import { faFileContract } from "@fortawesome/pro-solid-svg-icons";

export default class DataTableFalhaPagamento extends Component {
	constructor() {
		super();

		this.state = {
			property: {},
			data: null,
			rows: 6,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: 'id',
			sortOrder: 1,
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters !== this.props.filters) {
			this.fetchData();
		}
	}

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = async (event) => {
		this.setState({
			loading: true,

		});
		if (event.data) {
			event.data = null;
		}

		let falhaPagamentoDataTable = {
			rows: event.rows,
			totalRecords: this.state.totalRecords,
			data: this.data,
			first: event.first,
			last: event.rows + event.first,
			loading: this.state.loading,
			sortField: this.state.sortField,
			sortOrder: this.state.sortOrder,
		};

		localStorage.setItem("falhaPagamentoDataTable", JSON.stringify(falhaPagamentoDataTable));

		this.setState({
			...this.state,
			rows: event.rows,
		});
		this.fetchData(event.rows, event.first);
	};

	fetchData = async (limit, skip) => {
		this.setState({ loading: true });

		let options = {
			$limit: limit ? limit : this.state.rows,
			$skip: skip != null ? skip : this.state.first,
			$sort: { [this.state.sortField]: this.state.sortOrder },
		};

		if (this.props.filters.billing_type == 'rents') {
			options.billing = {
				type: 'monthly'
			}
		}

		if (this.props.filters.billing_type == 'agreements') {
			options.billing = {
				type: 'agreement'
			}
		}

		return this.props.fetchEvent(options).then(async (res) => {
			this.props.updateCounter(res?.data?.total);

			if (res.data.data.length == 0) {
				this.setState({
					...this.state,
					data: [],
					totalRecords: res.data.total,
					first: skip,
					last: skip + res.data.limit,
					loading: false
				});
			}

			await res.data.data.map(async (obj, i) => {
				if (obj.contract_id) {
					obj.loading_contract = true;
					await getContract(obj.contract_id).catch(err => {
						obj.contract = null;
						obj.loading_contract = false;
					}).then(res => {
						if (res?.data?.data) {
							obj.contract = res.data.data;
						}
					});
				}

				this.setState({
					...this.state,
					data: res.data.data,
					totalRecords: res.data.total,
					first: skip,
					last: skip + res.data.limit,
					loading: false
				});
			});
		}).finally(() => {
			this.setState({ loading: false });
		});
	};

	sortTable = async (e) => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder, loading: true }, () => {
			let falhaPagamentoDataTable = {
				rows: this.state.rows,
				totalRecords: this.state.totalRecords,
				first: this.state.first,
				last: this.state.last,
				loading: this.state.loading,
				sortField: this.state.sortField,
				sortOrder: this.state.sortOrder,
				data: this.state.data
			};
			localStorage.setItem("falhaPagamentoDataTable", JSON.stringify(falhaPagamentoDataTable));

			this.fetchData();
		});
	};

	getValue = (obj) => {
		return (
			<div style={{ textAlign: "center", width: '105px' }}>
				<p>{formatBRL(obj || 0)}</p>
			</div>
		);
	};

	getUserName = (obj, type) => {
		if (type === "landlord" && obj?.landlord) {
			return (
				<div style={{ textAlign: "center" }}>
					<Link style={{ color: "var(--Azul-500, #4AA3FF)", fontFamily: "Lato", fontSize: "14px", fontStyle: "normal", fontWeight: 400, lineHeight: "normal" }} to={`/users/show/${obj.landlord?.id}`}>
						{obj.landlord?.name}
					</Link>
				</div>
			);
		}

		if (type === "tenant" && obj?.tenant) {
			return (
				<div style={{ textAlign: "center" }}>
					<Link style={{ color: "var(--Azul-500, #4AA3FF)", fontFamily: "Lato", fontSize: "14px", fontStyle: "normal", fontWeight: 400, lineHeight: "normal" }} to={`/users/show/${obj.tenant?.id}`}>{obj.tenant?.name}</Link>
				</div>
			);
		}

		if (type === "contract" && obj?.contract) {
			return (
				<div style={{ textAlign: "-webkit-center" }}>
					<FontAwesomeIcon icon={faFileContract} style={{ color: "#4AA3FF", marginRight: 10 }} />
					<Link style={{ color: "var(--Azul-500, #4AA3FF)", fontFamily: "Lato", fontSize: "14px", fontStyle: "normal", fontWeight: 400, lineHeight: "normal" }} to={`/negotiation/show/${obj?.contract?.entity?.proposition_id}`}>{obj?.contract?.entity?.friendly_code}</Link>
					<div className="d-flex flex-row" style={{ width: obj?.contract?.entity?.plan?.name === 'WARRANTY' ? '94px' : '84px', fontSize: '13px', borderRadius: 2, height: '24px', marginTop: 4, alignItems: 'center', backgroundColor: obj?.contract?.entity?.plan?.name === 'WARRANTY' ? '#5664D2' : '#4AA3FF' }}>
						<p className="col-form-label text-white" style={{ fontFamily: 'Inter', padding: 0 }}>{obj?.contract?.entity?.plan?.name === 'WARRANTY' ? 'Pagamento' : 'Completa'}</p>
					</div>
				</div>
			);
		}

		return (
			<div style={{ textAlign: "center" }}>
				Não encontrado
			</div>
		);
	};

	getCity = (obj) => {
		if (obj?.property) {
			return (
				<div style={{ textAlign: "center" }}>
					<Link to={`/announcements/show/${obj.property?.id}`}>{obj.property?.address?.city}</Link>
				</div>
			);
		} else {
			return (
				<div style={{ textAlign: "center" }}>
					Não encontrado
				</div>
			)
		}
	};

	getNeighbourhood = (obj) => {
		return (
			<div style={{ textAlign: "center" }}>
				<Link to={`/announcements/show/${obj.property?.id}`}>
					{obj.property?.address?.neighbourhood}
				</Link>
			</div>
		);
	};

	getStatus = (obj) => {
		if (obj?.receipts?.rent?.paid === true && (obj?.receipts?.rent?.paid_for_insurer === false || !obj?.receipts?.rent?.paid_for_insurer)) {
			return (
				<div className="bg-success d-flex flex-row" style={{ width: '98px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center' }}>
					<p className="col-form-label text-white">{obj?.receipts?.rent?.paid ? 'Fatura paga' : 'N/A'}</p>
				</div>
			)
		} else if (obj?.receipts?.rent?.paid_for_insurer == true && obj?.receipts?.rent?.paid == true) {
			return (
				<div className="d-flex flex-row" style={{ width: '140px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center', backgroundColor: '#5664D2' }}>
					<p className="col-form-label text-white">{obj?.receipts?.rent?.paid_for_insurer ? 'Garantido e pago' : 'N/A'}</p>
				</div>
			)
		} else if (obj?.receipts?.rent?.paid_for_insurer == true && obj?.receipts?.rent?.paid == false) {
			return (
				<div className="d-flex flex-row" style={{ width: '140px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center', backgroundColor: '#F06595' }}>
					<p className="col-form-label text-white">{obj?.receipts?.rent?.paid_for_insurer ? 'Garantido e atrasado' : 'N/A'}</p>
				</div>
			)
		} else if (obj?.values?.current_value !== obj?.values?.start_value || (!obj?.receipts?.rent?.paid && !obj?.receipts?.rent?.paid_for_insurer)) {
			return (
				<div className="bg-danger d-flex flex-row" style={{ width: '120px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center' }}>
					<p className="col-form-label text-white">Fatura atrasada</p>
				</div>
			)
		} else if (moment().isBefore(obj?.due_date)) {
			<div className="bg-warning d-flex flex-row" style={{ width: '131px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center' }}>
				<p className="col-form-label text-white">Fatura em aberto</p>
			</div>
		} else {
			return (
				<div className="bg-secondary d-flex flex-row" style={{ width: '98px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center' }}>
					<p className="col-form-label text-white">Aguardando</p>
				</div>
			)
		}
	};

	getDateFormatted = (date) => {
		return (
			<p>
				{date ? moment(date).add(1, 'day').format("DD/MM/YYYY") : '-'}
			</p>
		);
	};

	getStatusProposition = (obj) => {
		let status = '';
		switch (obj.status) {
			case 'FIRST_PROPOSAL':
				status = 'Criada Admin';
				break;
			case 'AWAITING_SIGN':
				status = 'Aguardando sinal';
				break;
			case 'FINALIZED':
				status = 'Finalizado';
				break;
			default:
				status = obj.status;
				break;
		}
		return (
			<div style={{ textAlign: "center" }}>
				<p>{status}</p>
			</div>
		);
	}

	onBoletoCreation = async (obj) => {
		this.setState({ ...this.state, loading: true, });

		createRentBoleto(obj.id, {
			contract_id: obj.contract_id,
			landlord_id: obj.landlord_id,
		}).then((data) => {
			setTimeout(() => {
				getBillingById(obj.id).then((data) => {
					navigator.clipboard.writeText(data.data?.boleto?.pdf_url);
					this.setState({ ...this.state, loading: false, });
				});
			}, 5000);
		}).catch(err => {
			this.setState({ ...this.state, boletoError: true, loading: false, });

			setTimeout(() => {
				this.setState({ ...this.state, boletoError: false, });
			}, 3000);
		}).finally(() => {
			this.setState({ ...this.state, loading: false, });
		})
	}

	columnOptionsCrud = (obj) => {
		if (this.props.crudUrl) {
			return (
				<div style={{ textAlign: "center" }}>
					{/* {obj?.boleto?.pdf_url ?
						<Button href={obj?.billing?.boleto?.pdf_url} target="_blank" className="mr-2" style={{ background: 'transparent', borderColor: 'transparent' }} onClick={() => { navigator.clipboard.writeText(obj?.boleto?.pdf_url) }}>
							<i className="far fa-file" style={{ color: '#5664D2' }} />
						</Button>
						: null} */}

					{!this?.billing?.is_paid ?
						<button style={{ background: 'transparent', borderColor: 'transparent' }} onClick={() => this.onBoletoCreation(obj?.billing)}>
							<FontAwesomeIcon icon={faRedo} style={{ color: '#4AA3FF' }} />
						</button> : null}
				</div>
			);
		} else {
			return "a";
		}
	};

	seeFailure = (obj) => {
		if (this.props.crudUrl) {
			return (
				<div style={{ textAlign: "center" }}>
					<Link to={`/billing/show/${obj?.billing?.id}?transfer=${obj?.id}`} >
						<FontAwesomeIcon icon={faChevronRight} style={{ color: "#5664D2" }} />
					</Link>
				</div>
			);
		} else {
			return "a";
		}
	};

	getReceiver = (obj) => {
		if (obj?.transfer?.destination === 'landlord') {
			return (
				<div style={{ textAlign: "start" }}>
					<Link style={{ color: "var(--Azul-500, #4AA3FF)", fontFamily: "Lato", fontSize: "14px", fontStyle: "normal", fontWeight: 400, lineHeight: "normal" }} to={`/users/show/${obj.landlord?.id}`}>
						{obj.landlord?.name}
					</Link>
				</div>
			)
		} else if (obj?.transfer?.destination === 'tenant') {
			return (
				<div style={{ textAlign: "start" }}>
					<Link style={{ color: "var(--Azul-500, #4AA3FF)", fontFamily: "Lato", fontSize: "14px", fontStyle: "normal", fontWeight: 400, lineHeight: "normal" }} to={`/users/show/${obj.tenant?.id}`}>{obj.tenant?.name}</Link>
				</div>
			)
		} else {
			return (
				<div style={{ textAlign: "start" }}>
					<Link style={{ color: "var(--Azul-500, #4AA3FF)", fontFamily: "Lato", fontSize: "14px", fontStyle: "normal", fontWeight: 400, lineHeight: "normal" }} to={`/users/index`}>
						Não encontrado
					</Link>
				</div>
			)
		}
	}

	export() {
		this.dt.exportCSV();
	}

	emptyMessageReturn() {
		return (
			<div className="boxImage">
				<img src={smile} alt="smile" width={48} height={48} />

				<p className="subtitleNoItems">Nenhuma falha nos pagamentos encontrada</p>
			</div>
		)
	}

	render() {
		return (
			<div style={{ position: 'relative', maxHeight: '630px' }}>
				<DataTable ref={(el) => this.dt = el} value={this.state.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={[6, 15, 30]} first={this.state.first} last={this.state.last}
					totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} responsive={true} loadingIcon={`fas fa-sync fa-spin`} onSort={(e) => this.sortTable(e)}
					paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" emptyMessage={this.emptyMessageReturn}
					currentPageReportTemplate="" sortField={this.state.sortField} sortOrder={this.state.sortOrder} pageLinkSize={3} id="custom-table-1">

					{this.props.labels.find(obj => obj.label === 'Vence em').isActive ? <Column sortable field="billing.due_date" body={(obj) => this.getDateFormatted(obj?.transfer?.billing?.due_date)} style={{ width: '104px', padding: '22px 16px 22px 16px' }} header="Vence em" /> : null}

					{this.props.labels.find(obj => obj.label === 'Pago em').isActive ? <Column sortable field="billing.paid_at" body={(obj) => this.getDateFormatted(obj?.transfer?.billing?.paid_at)} style={{ width: '104px', padding: '22px 16px 22px 16px' }} header="Pago em" /> : null}

					{this.props.labels.find(obj => obj.label === 'Status').isActive ? <Column field="billing.status" body={(obj) => this.getStatus(obj?.billing)} header="Status" style={{ width: '160px', padding: '22px 16px 22px 16px' }} /> : null}

					{this.props.labels.find(obj => obj.label === 'Recebedores').isActive ? <Column field="landlord.name" body={(obj) => this.getReceiver(obj, 'landlord')} header="Recebedores" style={{ width: '427px', textAlign: "flex-start" }} /> : null}

					{this.props.labels.find(obj => obj.label === 'Contrato').isActive ? <Column field="contract" body={(obj) => this.getUserName(obj, 'contract')} header="Contrato" style={{ width: '126px', padding: '22px 16px 22px 16px' }} /> : null}

					{this.props.labels.find(obj => obj.label === 'Valor aluguel').isActive ? <Column sortable field="billing.receipts.rent.value" body={(obj) => this.getValue(obj?.billing?.receipts?.rent?.value)} style={{ width: '104px', padding: '22px 16px 22px 16px' }} header="Valor aluguel" /> : null}

					<Column header="" body={(obj) => this.columnOptionsCrud(obj?.transfer)} style={{ width: '34px', padding: 0 }} />

					<Column header="" body={(obj) => this.seeFailure(obj?.transfer)} style={{ width: '34px', padding: 0 }} />
				</DataTable>

				<div className="paginator-new-items">
					<p>Registros por página</p>
					<Link className="custom-see-all-table" to={`/pagamento/cobrancas/index`} >
						Ver todos
					</Link>
				</div>
			</div>
		);
	}
}
