import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatBRL } from "../../../helpers/Utils";
import { Link } from "react-router-dom";
import moment from "moment";

export default class DataTableComissionados extends Component {
	constructor() {
		super();

		this.state = {
			property: {},
			data: null,
			rows: 10,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: 'id',
			sortOrder: 1,
		};
	}

	componentDidMount() {
		this.fetchData();
	}

	fetchData = async () => {
		this.setState({ loading: true });

		return this.props.fetchEvent(this.props.billingId).then(async (res) => {
			if (res.data.data.length == 0) {
				this.setState({ ...this.state, data: [], totalRecords: res.data.total, loading: false });
			}

			this.props.handleSetRecebedores(res.data.data);

			const userIdSet = new Set();
			const recebedores = [];

			const resData = await res.data.data;

			resData.map(async (obj, i) => {
				if (this.props.type === 'termination' && ['affiliate', 'commissioned'].includes(obj.user_type)) {
					return;
				}

				if (!userIdSet.has(obj.user.id)) {
					userIdSet.add(obj.user.id);

					recebedores.push(obj);
				}
			});

			this.setState({ data: recebedores, totalRecords: res.data.total });
		}).finally(() => {
			this.setState({ loading: false });
		});
	};

	getValue = (obj) => {
		return (
			<div style={{ textAlign: "center" }}>
				<p>{formatBRL(obj || 0)}</p>
			</div>
		);
	};

	getUserName = (obj, type) => {
		if (obj?.user?.name == 'Mell.ro') {
			return (
				<div style={{ textAlign: "center" }}>--</div>
			);
		}

		if (type === "landlord" && obj?.user?.name) {
			return (
				<div style={{ textAlign: "center" }}>
					<Link to={`/users/show/${obj.user?.id}`}>
						{obj.user?.name}
					</Link>
				</div>
			);
		}


		return (
			<div style={{ textAlign: "center" }}>
				Não encontrado
			</div>
		);
	};

	getCity = (obj) => {
		if (obj?.property) {
			return (
				<div style={{ textAlign: "center" }}>
					<Link to={`/announcements/show/${obj.property?.id}`}>{obj.property?.address?.city}</Link>
				</div>
			);
		} else {
			return (
				<div style={{ textAlign: "center" }}>
					Não encontrado
				</div>
			)
		}
	};

	getNeighbourhood = (obj) => {
		return (
			<div style={{ textAlign: "center" }}>
				<Link to={`/announcements/show/${obj.property?.id}`}>
					{obj.property?.address?.neighbourhood}
				</Link>
			</div>
		);
	};

	getUserType = (userType) => {
		if (userType === 'tenant') {
			return (
				<div className="tag" style={{ backgroundColor: '#5664D2' }}>
					Inquilino
				</div>
			)
		}

		if (userType === 'landlord') {
			return (
				<div className="tag" style={{ backgroundColor: '#5664D2' }}>
					Proprietário
				</div>
			)
		}

		if (userType === 'admin') {
			return (
				<div className="tag" style={{ backgroundColor: '#5664D2' }}>
					Administrador
				</div>
			)
		}

		if (userType === 'commissioned') {
			return (
				<div className="tag" style={{ backgroundColor: '#1CBB8C' }}>
					Comissionado
				</div>
			)
		}

		if (userType === 'mellro') {
			return (
				<div className="tag" style={{ backgroundColor: '#4AA3FF' }}>
					Mell.ro
				</div>
			)
		}

		if (userType === 'affiliate') {
			return (
				<div className="tag" style={{ backgroundColor: '#1CBB8C' }}>
					Afiliado
				</div>
			)
		}
	}

	getStatus = (obj) => {
		if (obj?.receipts?.rent?.paid === true && (obj?.receipts?.rent?.paid_for_insurer === false || !obj?.receipts?.rent?.paid_for_insurer)) {
			return (
				<div className="bg-success d-flex flex-row" style={{ width: '98px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center' }}>
					<p className="col-form-label text-white">{obj?.receipts?.rent?.paid ? 'Fatura paga' : 'N/A'}</p>
				</div>
			);
		} else if (obj?.receipts?.rent?.paid_for_insurer == true && obj?.receipts?.rent?.paid == true) {
			return (
				<div className="d-flex flex-row" style={{ width: '140px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center', backgroundColor: '#5664D2' }}>
					<p className="col-form-label text-white">{obj?.receipts?.rent?.paid_for_insurer ? 'Garantido e pago' : 'N/A'}</p>
				</div>
			);
		} else if (obj?.receipts?.rent?.paid_for_insurer == true && obj?.receipts?.rent?.paid == false) {
			return (
				<div className="d-flex flex-row" style={{ width: '140px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center', backgroundColor: '#F06595' }}>
					<p className="col-form-label text-white">{obj?.receipts?.rent?.paid_for_insurer ? 'Garantido e atrasado' : 'N/A'}</p>
				</div>
			);
		} else if (moment().isSameOrBefore(obj.due_date, 'day') && obj.boleto) {
			return (
				<div className="bg-warning d-flex flex-row" style={{ width: '131px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center' }}>
					<p className="col-form-label text-white">Fatura em aberto</p>
				</div>
			);
		} else if (moment().isAfter(moment(obj.due_date).add(1, 'd'), 'day')) {
			return (
				<div className="bg-danger d-flex flex-row" style={{ width: '120px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center' }}>
					<p className="col-form-label text-white">Fatura atrasada</p>
				</div>
			);
		} else {
			return (
				<div className="bg-secondary d-flex flex-row" style={{ width: '98px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center' }}>
					<p className="col-form-label text-white">Aguardando</p>
				</div>
			);
		}
	};

	getAnticipatedDate = (obj) => {
		const anticipation = this.props.billingData?.anticipated_values?.find((ant) => ant.user_id == obj.user?.id);


		if (anticipation && anticipation.anticipated_at) {
			return (
				<Link to={`/anticipations/show/${anticipation.anticipation_id}`}>
					<div style={{ textDecoration: 'underline' }}>
						{moment(anticipation.anticipated_at, 'YYYY-MM-DD HH:mm:ss').add(3, 'hours').format('DD/MM/YYYY')}
					</div>
				</Link>
			);
		} else if (anticipation) {
			return (
				<div style={{}}>
					Aguardando
				</div>
			);
		} else {
			return (
				<div style={{}}>
					--
				</div>
			);
		}
	}

	sortTable = async ({ sortField, sortOrder }) => {
		let data = this.state.data;

		data = data.sort((a, b) => {
			if (a[sortField] < b[sortField]) return -1
			if (a[sortField] > b[sortField]) return -1

			return 0
		});

		this.setState({ sortField: sortField, sortOrder: sortOrder, data });
	};

	getDepositDate = (obj) => {
		if (obj?.deposit_date) {
			return `${moment(obj.deposit_date, 'DD/MM/YYYY HH:mm:ss').subtract(3, 'hours').format('DD/MM/YYYY')}`
		} else if (this.props.billingData?.anticipated) {
			const anticipation = this.props.billingData?.anticipated_values?.find((ant) => ant.user_id == obj.user?.id);

			if (anticipation && anticipation.anticipated_at) {
				return (
					<div style={{}}>
						{moment(anticipation.anticipated_at, 'YYYY-MM-DD HH:mm:ss').add(3, 'hours').format('DD/MM/YYYY')}
					</div>
				);
			} else {
				return '--'
			}
		} else {
			return '--'
		}
	}

	render() {
		return (
			<DataTable ref={(el) => this.dt = el} value={this.state.data} rows={this.state.rows} first={this.state.first} last={this.state.last}
				lazy={true} loading={this.state.loading} responsive={true} loadingIcon={`fas fa-sync fa-spin`} onSort={(e) => this.sortTable(e)}
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" emptyMessage="Nenhum registro encontrado!"
				currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" sortField={this.state.sortField} sortOrder={this.state.sortOrder}>
				{this.props.export ? this.export() : false}
				<Column style={{ width: 143 }} sortable field="user_type" body={(obj) => this.getUserType(obj?.user_type)} header="Tipo" />
				<Column style={{ width: 212 }} sortable field="user.name" body={(obj) => this.getUserName(obj, 'landlord')} header="Usuário" />
				<Column style={{ width: 169 }} sortable field="deposit_date" body={(obj) => this.getDepositDate(obj)} header="Depositado em" />
				<Column style={{ width: 59 }} sortable field="percentage" body={(obj) => `${parseFloat(obj?.percentage).toFixed(2).replace('.', ',')} %`} header="%" />
				<Column style={{ width: 105 }} sortable field="value" body={(obj) => formatBRL(obj?.value)} header="Valor" />
				<Column style={{ width: 105 }} field="anticipated_at" body={(obj) => this.getAnticipatedDate(obj)} header="Antecipado" />
			</DataTable>
		);
	}
}
