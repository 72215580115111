import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatBRL } from "../../../helpers/Utils";
import moment from "moment";
import { removeContractAffiliate } from "../../../crud/affiliate.crud";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faUserMagnifyingGlass, faChevronRight, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { faFilter, faMagnifyingGlass, faRotateRight } from '@fortawesome/pro-regular-svg-icons';
import { Link } from "react-router-dom";
import { Col, Input } from "reactstrap";

export default class DataTableContrato extends Component {
	constructor() {
		super();

		this.state = {
			property: {},
			data: null,
			dataPaginate: null,
			rows: 10,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: 'created_at',
			sortOrder: 1,
			newFilters: [],
			filters: [],
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		this.fetchData();
	}

	refreshTable() {
		this.setState({
			dataPaginate: null,
			data: null,
			newFilters: [],
			filters: { searchAll: '' },
		}, () => {
			this.fetchData(this.state.rows, this.state.first);
		});
	}

	onPage = async (event) => {
		const { data } = this.state;

		this.setState({ loading: true });

		if (data) {

			this.setState({
				...this.state,
				dataPaginate: data.slice(event.first, event.first + event.rows),
				rows: event.rows,
				first: event.first,
				last: event.first + event.rows,
				loading: false
			})
		} else {
			this.fetchData(event.rows, event.first);
		}
	};

	fetchData = async (limit, skip) => {
		this.setState({ loading: true });

		let options = {
			affiliate_id_arraycts: this.props.user_id,
			affiliate_actived: true,
		};

		return this.props.fetchEvent(options).then(async (res) => {
			this.props.handleSetContratosAtivos(res.data.total);

			this.setState({
				...this.state,
				data: res?.data?.data || [],
				dataPaginate: res?.data?.data?.slice(skip, limit),
				totalRecords: res?.data?.total,
				first: skip,
				last: limit,
				loading: false
			});

		}).finally(() => {
			this.setState({ loading: false });
		});
	};

	sortTable = (e) => {
		const { data } = this.state;

		const sortedData = [...data].sort((a, b) => {
			let value1 = a[e.sortField];
			let value2 = b[e.sortField];

			switch (e.sortField) {
				case 'first_payment':
					value1 = a?.first_payment?.paid_at || '';
					value2 = b?.first_payment?.paid_at || '';
					break;

				case 'user.name':
					value1 = a?.user?.name || '';
					value2 = b?.user?.name || '';
					break;

				case 'affiliate_recurrence':
					value1 = a?.affiliate_users?.find(affiliate => affiliate.affiliate_id === this.props.user_id)?.affiliate_recurrence || false;
					value2 = b?.affiliate_users?.find(affiliate => affiliate.affiliate_id === this.props.user_id)?.affiliate_recurrence || false;
					break;

				case 'affiliate_period':
					value1 = a?.affiliate_users?.find(affiliate => affiliate.affiliate_id === this.props.user_id)?.affiliate_period || '';
					value2 = b?.affiliate_users?.find(affiliate => affiliate.affiliate_id === this.props.user_id)?.affiliate_period || '';
					break;

				case 'affiliate_percentage':
					value1 = Number(a?.affiliate_users?.find(affiliate => affiliate.affiliate_id === this.props.user_id)?.affiliate_percentage || 0);
					value2 = Number(b?.affiliate_users?.find(affiliate => affiliate.affiliate_id === this.props.user_id)?.affiliate_percentage || 0);
					break;

				case 'affiliate_finalValue':
					value1 = a?.contract?.details?.values?.base_rent * parseFloat(a?.affiliate_users?.find(affiliate => affiliate.affiliate_id === this.props.user_id)?.affiliate_percentage / 100) * parseFloat(a?.contract?.plan?.percentage / 100);
					value2 = b?.contract?.details?.values?.base_rent * parseFloat(b?.affiliate_users?.find(affiliate => affiliate.affiliate_id === this.props.user_id)?.affiliate_percentage / 100) * parseFloat(b?.contract?.plan?.percentage / 100);
					break;

				default:
					value1 = a[e.sortField] || '';
					value2 = b[e.sortField] || '';
					break;
			}

			if (e.sortOrder === 1) {
				return typeof value1 === 'string' ? value1.localeCompare(value2) : value1 - value2;
			} else {
				return typeof value1 === 'string' ? value2.localeCompare(value1) : value2 - value1;
			}
		})

		this.setState({
			sortField: e.sortField,
			sortOrder: e.sortOrder,
			data: sortedData,
			dataPaginate: sortedData.slice(this.state.first, this.state.last)
		});
	};

	searchTable = (e) => {
		const { data } = this.state;
		let filteredData = [];

		if (e === 'canceled') {
			filteredData = data.filter(obj => !obj.first_payment);
		} else if (e === 'terminated') {
			filteredData = data.filter(obj => (obj?.contract?.is_terminated && obj?.first_payment));
		} else if (e === 'scheduled_termination') {
			filteredData = data.filter(obj => ((obj?.contract?.scheduled_termination_date || obj?.contract?.termination_status) && !obj?.contract?.is_terminated));
		} else if (e === 'awaiting_payment') {
			filteredData = data.filter(obj => obj.first_payment?.is_paid === false);
		} else if (e === 'actived') {
			filteredData = data.filter(obj => obj.first_payment?.is_paid && !obj.contract?.is_terminated && !obj.contract?.scheduled_termination_date && !obj.contract?.termination_status);
		} else if (e !== '') {
			filteredData = data.filter(obj => {
				return obj?.user?.name?.toLowerCase().includes(e.toLowerCase()) ||
					obj?.user?.email?.toLowerCase().includes(e.toLowerCase()) ||
					obj?.user?.phone?.toLowerCase().includes(e.toLowerCase());
			})
		} else {
			filteredData = data;
		}

		this.setState({
			dataPaginate: filteredData.slice(this.state.first, this.state.last),
			totalRecords: filteredData.length
		})
	}

	getUserName = (obj) => {
		if (obj?.user) {
			return (
				<div className='negotiation-active'>
					<FontAwesomeIcon icon={faUserMagnifyingGlass} style={{ marginRight: '8.5px', color: '#4AA3FF' }} />
					<a href={`/users/show/${obj?.user?.id}`} >{obj?.user?.name}</a>
				</div>
			);
		}

		return (
			<div style={{ textAlign: "center" }}>
				Não encontrado
			</div>
		);
	};

	handleRemove = id => {
		removeContractAffiliate({
			user_id: id,
			affiliate_id: this.props.user_id
		}).then(({ data }) => {
			this.fetchData();

			this.props.handleSetErrors(['O indicado foi removido. O afiliado não receberá mais comissões desse indicado.'])
		}).catch(() => {

		}).finally(() => {
			this.setState({ ...this.state, loading: false, newIndicado: '' });
		});
	};


	columnOptionsCrud = (obj) => {
		if (this.props.crudUrl) {
			return (
				<div onClick={() => this.handleRemove(obj.id)} className='showButton' style={{ textAlign: 'center', cursor: 'pointer' }}>
					<FontAwesomeIcon icon={faClose} style={{ color: '#5664D2' }} />
				</div>
			);
		}
	};

	getStatus = (obj) => {
		let status = '';
		let color = '';

		if (!obj?.first_payment) {
			status = 'Cancelado';
			color = '#FF3D60';
		} else if (obj?.contract?.is_terminated) {
			status = 'Rescindido';
			color = '#FF3D60';
		} else if (obj?.contract?.scheduled_termination_date || obj?.contract?.termination_status) {
			status = 'Rescisão agendada';
			color = '#FF3D60';
		} else if (!obj?.first_payment?.is_paid) {
			status = 'Aguardando pagamento';
			color = '#FCB92C';
		} else {
			status = 'Vigente';
			color = '#1CBB8C';
		}

		return (
			<div className='tag' style={{ backgroundColor: color }}>
				{status}
			</div>
		);
	}

	getAffiliateInfos = (obj, infoRequest) => {
		const { affiliate_users, first_payment } = obj;

		const affiliate = affiliate_users?.find(affiliate => affiliate.affiliate_id === this.props.user_id);

		switch (infoRequest) {
			case 'first_payment':
				return first_payment?.paid_at ? moment(first_payment?.paid_at).format('DD/MM/YYYY HH:mm:ss') : '--';
			case 'period':
				return affiliate?.affiliate_period === "12_MONTHS" ? '12 meses' : 'Todo contrato';
			case 'recurrence':
				return affiliate?.affiliate_recurrence === true ? 'Recorrência' : '1º pagamento';
			case 'percentage':
				return `${affiliate?.affiliate_percentage}%`;
			case 'value':
				return formatBRL(obj.contract?.details?.values?.base_rent * parseFloat(affiliate?.affiliate_percentage / 100) * parseFloat(obj.contract?.plan?.percentage / 100));
			default:
				return '';
		}
	}

	FiltersModule = (index) => {
		const getFilterChoised = (filter) => {
			let component = <></>;

			if (filter == 'status') {
				component = (
					<div className='d-flex'>
						<select onChange={(e) => this.setState({ filters: { ...this.state.filters, status: e.target.value } }, () => this.searchTable(e.target.value))} style={{ width: 400, margin: '0 16px' }} className="custom-select">
							<option value="" hidden>Selecionar</option>
							<option value="awaiting_payment">Aguardando pagamento</option>
							<option value="canceled">Cancelado</option>
							<option value="actived">Vigente</option>
							<option value="terminated">Rescindido</option>
							<option value="scheduled_termination">Rescisão agendada</option>
						</select>
					</div>
				)
			} else {
				component = <></>;
			}

			return component;
		}

		const onSetFilter = (filtro) => {
			let stateFilter = this.state.newFilters.slice();
			stateFilter[index] = filtro;

			this.setState({ newFilters: stateFilter })
		}

		const getSelectOptions = (nameFiltro) => {
			const stateFilter = this.state.newFilters;

			if (stateFilter.includes(nameFiltro)) {
				return 'd-none'
			} else {
				return ''
			}
		}

		const removeFilter = (index) => {
			let stateFilter = this.state.newFilters.slice();
			stateFilter.splice(index, 1);

			this.setState({ newFilters: stateFilter });
			this.searchTable(stateFilter.length > 0 ? stateFilter[stateFilter.length - 1] : '');
		}

		return (
			<Col md={12} className="d-flex m-0 p-0 filtroUnit">
				<div className='d-flex'>
					<select onChange={({ target }) => onSetFilter(target.value)} value={this.state.newFilters[index]} className="custom-select" style={{ width: 300 }}>
						<option value="">Selecione um filtro</option>
						<option className={getSelectOptions('status')} value="status">Status</option>
					</select>

					{this.state.newFilters[index] != '' && (
						<div className='divisionArrow'>
							<FontAwesomeIcon icon={faChevronRight} />
						</div>
					)}

					{getFilterChoised(this.state.newFilters[index])}
				</div>

				<div onClick={() => removeFilter(index, this.state.newFilters[index])}>
					<FontAwesomeIcon icon={faTimes} />
				</div>
			</Col>
		)
	}

	columnOpcoesCrud = (obj) => {
		return (
			<Link to={`/negotiation/show/${obj?.contract?.proposition_id}`} className='showButton'>
				<i className='fa fa-chevron-right' />
			</Link>
		);
	};

	render() {
		return <>
			<div className="box-filtros" style={{ border: 'none', boxShadow: 'none', padding: 0 }}>
				<Col md={12} className="d-flex p-0" >
					<div className='generalSearch'>
						<Input
							className="inputCustomNew" type="text"
							value={this.state.filters.searchAll}
							placeholder="Busque por nome, email ou telefone"
							onChange={(e) => this.setState({ filters: { ...this.state.filters, searchAll: e.target.value } }, () => this.searchTable(e.target.value))}
						/>

						<FontAwesomeIcon icon={faMagnifyingGlass} />
					</div>

					<button onClick={() => this.setState({ newFilters: [...this.state.newFilters, ''] })} className="btnSecundary" style={{ width: 'max-content', margin: '0px 8px', padding: '8px 12px' }}>
						FILTRO
						<FontAwesomeIcon icon={faFilter} />
					</button>

					<button onClick={() => this.refreshTable()} className="btnSecundary" style={{ width: 'max-content', margin: '0px 0px 0px 8px', padding: '8px 12px' }}>
						ATUALIZAR
						<FontAwesomeIcon icon={faRotateRight} />
					</button>
				</Col>

				{this.state.newFilters.map((index) => {
					return <this.FiltersModule key={index} index={index} />

				})}
			</div>

			<DataTable id="overflowAuto" ref={(el) => this.dt = el} value={this.state.dataPaginate} paginator={true} rows={this.state.rows} rowsPerPageOptions={[10, 25, 50]} first={this.state.first} last={this.state.last}
				totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} responsive={true} loadingIcon={`fas fa-sync fa-spin`} onSort={(e) => this.sortTable(e)}
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" emptyMessage="Nenhum registro encontrado!"
				currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" sortField={this.state.sortField} sortOrder={this.state.sortOrder}>
				<Column field="status" style={{ width: 132 }} body={(obj) => this.getStatus(obj)} header="Status" />
				<Column field="first_payment" className="textAlignEnd" sortable style={{ width: 159, textAlign: 'end' }} body={(obj) => this.getAffiliateInfos(obj, 'first_payment')} header="Primeiro pagamento" />
				<Column field="affiliate_recurrence" sortable style={{ width: 118 }} body={(obj) => this.getAffiliateInfos(obj, 'recurrence')} header="Recorrência" />
				<Column field="user.name" sortable style={{ width: 335 }} body={(obj) => this.getUserName(obj)} className='p-3' header="Envolvido" />
				<Column field="affiliate_period" sortable style={{ width: 123 }} body={(obj) => this.getAffiliateInfos(obj, 'period')} header="Período" />
				<Column field="affiliate_percentage" className="textAlignEnd" sortable style={{ width: 59, textAlign: 'end' }} body={(obj) => this.getAffiliateInfos(obj, 'percentage')} header="%" />
				<Column field="affiliate_finalValue" className="textAlignEnd" sortable style={{ width: 105, textAlign: 'end' }} body={(obj) => this.getAffiliateInfos(obj, 'value')} header="Valor" />
				<Column header="" body={(obj) => this.columnOpcoesCrud(obj)} style={{ width: '34px', textAlign: 'center' }} />
			</DataTable>
		</>
	}
}
