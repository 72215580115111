import React, { Component } from "react";
import { Container, Card, CardBody, Col, FormGroup, Label, Input, Button } from "reactstrap";
import BetterCircularProgress from "../../../components/BetterCircularProgress";
import { getTemplate } from "../../../crud/templates.crud";
import Breadcrumbs from "../../../components/Breadcrumb";

const ignoreFields = new Set([
  "created_at",
  "updated_at",
  "created_by",
  "deleted_at",
  "createdAt",
  "updatedAt",
  "deletedAt",
]);

class TemplateSMSShow extends Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
  }

  state = {
    loading: true,
    templateComponent: [],
  };

  goBack() {
    this.props.history.goBack();
  }

  componentDidMount = () => {
    this.setState({ loading: true });
    getTemplate(this.props.match.params.id)
      .catch((err) => {
        alert("An error occurred, please contact the support.");
      })
      .then(async (res) => {
        if (res?.status === 200) {
          this.objectSetComponent(res.data.data);
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  objectToComponentsJob = (object) => {
    let i = 0;
    const component = [];
    if (!object) return [];
    for (const [key, value] of Object.entries(object)) {
      if (ignoreFields.has(key)) {
        continue;
      }
      const tittle = key.charAt(0).toUpperCase() + key.slice(1) + ": ";
      i++;
      if (typeof value === "object" && !Array.isArray(value)) {
        component.push(
          <Col md={6} key={i}>
            <FormGroup row>
              <Label className="col-md-4 col-form-label">{tittle}</Label>
              <Container fluid>{this.objectToComponentsJob(value)}</Container>
            </FormGroup>
          </Col>,
        );
        continue;
      }
      component.push(
        <Col md={6} key={i}>
          <FormGroup row>
            <Label className="col-md-4 col-form-label">{tittle}</Label>
            <Col md={8} className="container-center-input">
              <Input
                className={`form-control`}
                type={String(value).length > 45 ? "textarea" : "text"}
                value={Array.isArray(value) ? value.join(" | ") : value}
                disabled
              />
            </Col>
          </FormGroup>
        </Col>,
      );
    }
    return component;
  };

  objectSetComponent = (object) => {
    const component = this.objectToComponentsJob(object);
    this.setState({ templateComponent: component });
  };

  render() {
    return (
      <React.Fragment>
        <BetterCircularProgress loading={this.state.loading}>
          <div className="page-content container-page">
            <Container fluid>
              <Breadcrumbs title="Template Details" breadcrumbItems={[]} />
              <Card>
                {(() => {
                  console.log(this.state.templateComponent);
                  return this.state.templateComponent;
                })()}
              </Card>
              <Card>
                <CardBody className="container-space-between">
                  <Button onClick={this.goBack} className="btn btn-danger btn-bold">
                    <i className="fa fa-arrow-left mr-2"></i>Voltar
                  </Button>
                </CardBody>
              </Card>
            </Container>
          </div>
        </BetterCircularProgress>
      </React.Fragment>
    );
  }
}

export default TemplateSMSShow;
