import React, { Component } from 'react';
import { Col, FormGroup, Label, Button } from "reactstrap";

export class StatusFilterComponent extends Component {
	state = {
		list_status: [
			'AWAITING_TENANT_LOCK',
			'AWAITING_CREDIT_REVIEW',
			'APPROVED',
			'CREDIT_REFUSED',
		]
	};
	render() {
		const {
			onChange,
			value,
			onClick,
			label,
		} = this.props;

		return (
			<React.Fragment>
				<Col md={4}>
					<Label className="col-form-label" style={{ color: "#343A40" }}>
						{label}
					</Label>
					<FormGroup row>
						<Col>
							<select
								onChange={onChange}
								value={value}
								className="custom-select">
								<option key={null} value={null} hidden>Selecione o status</option>
								{[...this.state.list_status]
									.map((status, i) => {
										return (
											<option key={status} value={JSON.stringify(status)}>{status}</option>
										);
									})}
							</select>
						</Col>
						<Button
							onClick={onClick}
							color="light"
							type="button"
							className="waves-effect waves-light btn-rounded btn-close-input"
						>
							<i className="ri-close-circle-line align-middle"></i>
						</Button>
					</FormGroup>
				</Col>
			</React.Fragment>
		);
	}
}
