import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Link } from "react-router-dom";
import { Column } from 'primereact/column';
import _ from 'lodash';
import { get as getUser } from '../../crud/users.crud';
import { CircularProgress } from "@material-ui/core";
import moment from 'moment';

export default class DataTableVouchers extends Component {
    constructor() {
        super();

        this.state = {
            data: [],
            rows: 10,
            totalRecords: 0,
            first: 0,
            last: 0,
            loading: false,
            sortField: 'id',
            sortOrder: '1',
        };

        this.onPage = this.onPage.bind(this);
    }

    componentDidMount() {
        let vouchersDataTable = JSON.parse(localStorage.getItem('vouchersDataTable'));

        if (vouchersDataTable) {
            this.setState({
                rows: vouchersDataTable.rows,
                totalRecords: vouchersDataTable.totalRecords,
                first: vouchersDataTable.first,
                last: vouchersDataTable.last,
                loading: vouchersDataTable.loading,
                sortField: vouchersDataTable.sortField,
                sortOrder: vouchersDataTable.sortOrder,
            }, () => {
                this.fetchData();
            });
        } else {
            this.fetchData();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.filters != this.props.filters) {
            this.onFilterChanged();
        }
    }

    onFilterChanged = _.debounce(() => {
        if (!this.state.isLoading) {
            this.fetchData();
        }
    }, 700);

    refreshTable() {
        this.onPage(this.state);
    }

    onPage = async (event) => {
        if (event.data) {
            event.data = null;
        }

        let vouchersDataTable = {
            rows: event.rows,
            totalRecords: this.state.totalRecords,
            first: event.first,
            last: event.rows + event.first,
            loading: this.state.loading,
            sortField: this.state.sortField,
            sortOrder: this.state.sortOrder,
        }

        localStorage.setItem('vouchersDataTable', JSON.stringify(vouchersDataTable));

        this.setState({
            ...this.state,
            rows: event.rows,
            loading: true
        });

        this.fetchData(event.rows, event.first);
    };

    fetchData = (limit, skip) => {
        let data = {
            $limit: limit ? limit : this.state.rows,
            $skip: skip != null ? skip : this.state.first,
            $sort: { [this.state.sortField]: this.state.sortOrder }
        };

        this.props.fetchEvent(data).then(async res => {
            await res.data.data.map(async (obj, i) => {
                if (obj.owner_id) {
                    obj.loading_user = true;
                    await getUser(obj.owner_id).catch(err => {
                        obj.loading_user = false;
                        obj.user = null;
                    }).then(res => {
                        if (res?.data?.data) {
                            obj.user = res.data.data;
                        }
                    });
                }

                this.setState({
                    ...this.state,
                    data: res.data.data,
                    totalRecords: res.data.total,
                    first: res.data.skip,
                    last: res.data.skip + res.data.limit,
                    loading: false
                });
            });

            this.setState({
                ...this.state,
                data: res.data.data,
                totalRecords: res.data.total,
                first: res.data.skip,
                last: res.data.skip + res.data.limit,
                loading: false
            });
        });
    };

    columnOpcoesCrud = (rowData, column) => {
        let idRow = this.props.idRow ? this.props.idRow : 'id';

        if (this.props.crudUrl) {
            return (
                <div style={{ textAlign: 'center' }}>
                    <Link to={`${this.props.crudUrl}/show/${rowData[idRow]}`} className="btn btn-primary btn-table-action mr-2"><i className="fas fa-eye"></i></Link>
                </div>
            );
        } else {
            return null;
        }
    };

    export() {
        this.dt.exportCSV();
    };

    getUserName = (obj) => {
        if (obj.user) {
            return (
                <div style={{ textAlign: 'center' }}>
                    <Link to={`/users/show/${obj.user.id}`} >{obj.user.name}</Link>
                </div>
            )
        } else {
            if (obj.loading_user) {
                return (
                    <div style={{ textAlign: 'center' }}>
                        <CircularProgress color="inherit" style={{ color: "#4AA3FF" }} size={15} />
                    </div>
                )
            }
        }
    };

    getIsApplied = (obj) => {
        if (obj.is_applied) {
            return (
                <div style={{ textAlign: 'center' }}>
                    <p>Sim</p>
                </div>
            )
        } else {
            return (
                <div style={{ textAlign: 'center' }}>
                    <p>Não</p>
                </div>
            )
        }
    };

    sortTable = e => {
        this.setState({ sortField: e.sortField, sortOrder: e.sortOrder }, () => {
            let vouchersDataTable = {
                rows: this.state.rows,
                totalRecords: this.state.totalRecords,
                first: this.state.first,
                last: this.state.last,
                loading: this.state.loading,
                sortField: this.state.sortField,
                sortOrder: this.state.sortOrder,
            };

            localStorage.setItem('vouchersDataTable', JSON.stringify(vouchersDataTable));

            this.fetchData();
        });
    };

    getDateFormatted = (date) => {
        return (
            <p>{moment(date).format('DD/MM/YYYY HH:mm:ss')}</p>
        )
    }

    render() {
        const header = (
            <div style={{ float: 'right' }}>
                <Button className="tableHeaderItem" type="button" style={{ marginRight: '5px', marginLeft: '5px' }} icon="fas fa-file-csv" iconPos="left" label="CSV" onClick={() => this.export()} />
                <Button className="tableHeaderItem" icon={!this.state.loading ? "fas fa-sync" : "fas fa-sync fa-spin"} disabled={this.state.loading ? "disabled" : ""} onClick={() => this.refreshTable()} />
            </div>
        );

        return (
            <DataTable ref={(el) => this.dt = el} value={this.state.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={[10, 25, 50]}
                first={this.state.first} last={this.state.last} header={this.props.noHeader ? null : header} responsive={true}
                totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} loadingIcon={`fas fa-sync fa-spin`}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" emptyMessage="Nenhum registro encontrado!"
                onSort={(e) => this.sortTable(e)} sortField={this.state.sortField} sortOrder={this.state.sortOrder} >
                <Column sortable field="code" header="Código" />
                <Column field="user" body={(obj, i) => this.getUserName(obj)} header="Usuário"></Column>
                <Column sortable field="is_applied" body={(obj, i) => this.getIsApplied(obj)} header="Foi aplicado" />
                <Column sortable field="expiration_date" body={(obj, i) => this.getDateFormatted(obj.expiration_date)} header="Expiration date" />
                <Column header="Ações" body={this.columnOpcoesCrud} style={{ width: '130px' }} />
            </DataTable>
        );
    }
}