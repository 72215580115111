import axios from "axios";
import { Constants } from '../helpers/Constants';
import { parseQuery } from '../helpers/Utils';

export function getAllByFilter(params) {
    return axios.get(`${Constants.baseUrl}/credit/consult/personal-vouchers`, { params: parseQuery(params) });
}

export function getById(id) {
    return axios.get(`${Constants.baseUrl}/credit/consult/personal-vouchers/${id}`);
}

export function store(data) {
    return axios.post(`${Constants.baseUrl}/credit/consult/personal-vouchers`, data);
} 