import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatBRL, formatCNPJ, formatCPF } from "../../helpers/Utils";
import { Link } from "react-router-dom";
import moment from "moment";
import smile from '../../assets/images/icons-custom/face-smile.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";

export default class DataTableAnticipatedBillings extends Component {
	constructor() {
		super();

		this.state = {
			property: {},
			data: null,
			rows: 10,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: 'id',
			sortOrder: 1,
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters !== this.props.filters) {
			this.fetchData();
		}
	}

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = async (event) => {
		this.setState({
			loading: true,

		});
		if (event.data) {
			event.data = null;
		}

		this.setState({
			...this.state,
			rows: event.rows,
		});
		this.fetchData(event.rows, event.first);
	};

	fetchData = async (limit, skip) => {
		this.setState({ loading: true });

		let options = {
			$limit: limit ? limit : this.state.rows,
			$skip: skip != null ? skip : this.state.first,
			$sort: { [this.state.sortField]: this.state.sortOrder },
		};

		return this.props.fetchEvent(options).then(async (res) => {
			// this.props.updateCounter(res?.data?.total);

			if (res.data.data.length == 0) {
				this.setState({
					...this.state,
					data: [],
					totalRecords: res.data.total,
					first: skip,
					last: skip + res.data.limit,
					loading: false
				});
			}

			this.props.setState({ total_billings: res.data.total })

			this.setState({
				...this.state,
				data: res.data.data,
				totalRecords: res.data.total,
				first: skip,
				last: skip + res.data.limit,
				loading: false
			});
		}).finally(() => {
			this.setState({ loading: false });
		});
	};

	sortTable = async (e) => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder, loading: true }, () => {

			this.fetchData();
		});
	};

	getValue = (obj) => {
		return (
			<div style={{ textAlign: "center", width: '105px' }}>
				<p>{formatBRL(obj || 0)}</p>
			</div>
		);
	};

	getStatusSec = (status) => {

		if (status === 'SECURITIZED') {
			return (
				<div className="d-flex flex-row" style={{ width: '140px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center', backgroundColor: '#4AA3FF' }}>
					<p className="col-form-label text-white" style={{ marginBottom: 0 }}>Securitizado</p>
				</div>
			)
		} else if (status === 'WAITING_CNAB') {
			return (
				<div className="d-flex flex-row" style={{ width: '140px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center', backgroundColor: '#8590A5' }}>
					<p className="col-form-label text-white" style={{ marginBottom: 0 }}>Esperando CNAB</p>
				</div>
			)
		} else if (status === 'REJECTED') {
			return (
				<div className="d-flex flex-row" style={{ width: '140px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center', backgroundColor: '#FF3D60' }}>
					<p className="col-form-label text-white" style={{ marginBottom: 0 }}>Rejeitada</p>
				</div>
			)
		} else {
			return (
				<div className="d-flex flex-row" style={{ width: '140px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center', backgroundColor: '#8590A5' }}>
					<p className="col-form-label text-white" style={{ marginBottom: 0 }}>Esperando CNAB</p>
				</div>
			)
		}
	};

	getStatus = (obj) => {
		return (
			<div className="d-flex" style={{ gap: 8, flexDirection: 'column' }}>
				<div className="d-flex flex-row" style={{ width: '140px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center', backgroundColor: '#5664D2' }}>
					<p className="col-form-label text-white" style={{ marginBottom: 0 }}>Antecipado</p>
				</div>

				{!obj.is_paid && obj.boleto && moment().isBefore(obj.due_date) ? (
					<div className="d-flex flex-row" style={{ width: '140px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center', backgroundColor: '#5664D2' }}>
						<p className="col-form-label text-white" style={{ marginBottom: 0 }}>Antecipado</p>
					</div>
				) : null}

				{!obj.is_paid && !obj?.boleto && moment().isBefore(obj.due_date) ? (
					<div className="d-flex flex-row" style={{ width: '140px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center', backgroundColor: '#8590A5' }}>
						<p className="col-form-label text-white" style={{ marginBottom: 0 }}>Aguardando</p>
					</div>
				) : null}

				{!obj.is_paid && obj.boleto && moment().isAfter(obj.due_date) ? (
					<div className="d-flex flex-row" style={{ width: '140px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center', backgroundColor: '#FF3D60' }}>
						<p className="col-form-label text-white" style={{ marginBottom: 0 }}>Atrasado</p>
					</div>
				) : null}


				{obj.is_paid ? (
					<div className="d-flex flex-row" style={{ width: '140px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center', backgroundColor: '#1CBB8C' }}>
						<p className="col-form-label text-white" style={{ marginBottom: 0 }}>Pago</p>
					</div>
				) : null}
			</div>
		)
	}

	getDateFormatted = (date) => {
		return (
			<p>
				{date ? moment(date).add(3, 'hours').format("DD/MM/YYYY") : '-'}
			</p>
		);
	};

	getResponsable = (obj) => {
		return (
			<div>
				<Link to={`/users/show/${obj?.landlord_id}`} style={{ color: "#4AA3FF" }}>{obj.landlord_infos?.cpfCnpj ? obj.landlord_infos.cpfCnpj.length === 11 ? formatCPF(obj?.landlord_infos?.cpfCnpj) : formatCNPJ(obj.landlord_infos.cpfCnpj) : obj?.landlord_infos?.name}</Link>
			</div>
		)
	}

	getContractFriendlyName = (obj) => {
		return (
			<div>
				<Link to={`/negotiation/show/${obj?.contract?.entity?.proposition_id}`} style={{ color: "#4AA3FF" }}>{obj?.contract?.entity?.friendly_code ?? '--'}</Link>
			</div>
		)
	}

	export() {
		this.dt.exportCSV();
	}

	getOptions = (obj) => {
		return (
			<div className="d-flex justify-content-center p-2 align-items-center">
				<div>
					<Link to={`/billing/show/${obj.billing_id}`} style={{ width: 34 }}>
						<FontAwesomeIcon icon={faChevronRight} style={{ color: '#5664D2', fontSize: 14 }} />
					</Link>
				</div>
			</div>
		)
	}

	emptyMessageReturn() {
		return (
			<div className="boxImage">
				<img src={smile} alt="smile" width={48} height={48} />

				<p className="subtitleNoItems">Nenhum registro encontrado</p>
			</div>
		)
	}

	getRetainedValue = (obj) => {
		let retainedValue = 0;

		for (const value of obj.anticipated_values) {
			retainedValue += parseFloat(value.retained_value)
		}

		return formatBRL(retainedValue);
	}

	render() {
		return (
			<div style={{ position: 'relative' }}>
				<DataTable ref={(el) => this.dt = el} value={this.state.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={[10, 15, 30]} first={this.state.first} last={this.state.last}
					totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} responsive={true} loadingIcon={`fas fa-sync fa-spin`} onSort={(e) => this.sortTable(e)}
					paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" emptyMessage={this.emptyMessageReturn}
					currentPageReportTemplate="" sortField={this.state.sortField} sortOrder={this.state.sortOrder} pageLinkSize={3} id="custom-table-2">

					<Column sortable field="contract_id" body={(obj) => this.getContractFriendlyName(obj)} header="Contrato" style={{ width: '100px', padding: '22px 16px 22px 16px' }} />

					<Column sortable field="due_date" body={(obj) => this.getDateFormatted(obj?.due_date)} style={{ width: '118px', padding: '22px 16px 22px 16px' }} header="Vencimento" />

					<Column field="status" body={(obj) => this.getStatus(obj)} header="Status cobrança" style={{ width: '162px', padding: '22px 16px 22px 16px' }} />

					<Column field="status" body={(obj) => this.getStatusSec(obj?.sec_billing[0]?.status)} header="Status SEC" style={{ width: '162px', padding: '22px 16px 22px 16px' }} />

					<Column field="batch" body={(obj) => obj?.sec_billing[0]?.shipment_batch ?? '--'} header="Sequencial da Remessa" style={{ width: '100px', padding: '22px 16px 22px 16px' }} />

					<Column field="retained_value" body={(obj) => this.getRetainedValue(obj)} header="Valor retido" style={{ width: '143px', textAlign: "flex-start" }} />
				</DataTable>

				<div className="paginator-new-items">
					<p>Registros por página</p>
				</div>
			</div>
		);
	}
}
