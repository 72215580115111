import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import _ from 'lodash';
import { formatBRL } from '../../helpers/Utils';
import moment from 'moment';
import { get as getUser } from '../../crud/users.crud';
import { Link } from "react-router-dom";
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';

export default class DataTableAnticipations extends Component {
	constructor() {
		super();

		this.state = {
			data: [],
			rows: 10,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: 'id',
			sortOrder: '1',
			fetchingCsvData: false,
			csvData: [],
			rowsPerPageOptions: [10, 25, 50],
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		let DataTableAnticipations = JSON.parse(localStorage.getItem('DataTableAnticipations'));

		if (DataTableAnticipations) {
			this.setState({
				rows: DataTableAnticipations.rows,
				totalRecords: DataTableAnticipations.totalRecords,
				first: DataTableAnticipations.first,
				last: DataTableAnticipations.last,
				loading: DataTableAnticipations.loading,
				sortField: DataTableAnticipations.sortField,
				sortOrder: DataTableAnticipations.sortOrder,
			}, () => {
				this.fetchData();
			});
		} else {
			this.fetchData();
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters != this.props.filters) {
			this.onFilterChanged();
		}
	}

	onFilterChanged = _.debounce(() => {
		if (!this.state.isLoading) {
			this.fetchData();
		}
	}, 700);

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = async (event) => {
		if (event.data) {
			event.data = null;
		}

		// let DataTableAnticipations = {
		// 	rows: event.rows,
		// 	totalRecords: this.state.totalRecords,
		// 	first: event.first,
		// 	last: event.rows + event.first,
		// 	loading: this.state.loading,
		// 	sortField: this.state.sortField,
		// 	sortOrder: this.state.sortOrder,
		// };

		// localStorage.setItem('DataTableAnticipations', JSON.stringify(DataTableAnticipations));

		this.setState({
			...this.state,
			rows: event.rows,
			loading: true
		});

		this.fetchData(event.rows, event.first);
	};

	fetchData = (limit, skip) => {
		let data = {
			$limit: limit ? limit : this.state.rows,
			$skip: skip != null ? skip : this.state.first,
			$sort: { [this.state.sortField]: this.state.sortOrder }
		};

		if (this.props.userId) {
			data.user_id = this.props.userId;
		}

		this.props.fetchEvent(data).then(async (res) => {
			if (res?.data) {
				for (const ant of res.data.data) {
					const responsable = await getUser(ant.created_by);

					ant.created_by_user = responsable?.data?.data;
				}

				if (this.state.rowsPerPageOptions.includes(res?.data?.total) === false) {
					const indice = this.state.rowsPerPageOptions.findIndex(valor => res?.data?.total <= valor);
					const newRowsPerPageOptions = this.state.rowsPerPageOptions
					newRowsPerPageOptions.splice(indice === -1 ? this.state.rowsPerPageOptions.length : indice, 0, res?.data?.total);
					this.setState({ rowsPerPageOptions: newRowsPerPageOptions })
				}

				this.props.setTotalAnticipations(res?.data?.total);

				this.setState({
					...this.state,
					data: res?.data?.data,
					totalRecords: res?.data?.total,
					first: res?.data?.data?.skip,
					last: res?.data?.data?.skip + data?.data?.limit,
					loading: false
				});
			}
		});
	};

	getUserName = (obj) => {
		return (
			<Link to={`/users/show/${obj?.id}`}>
				<FontAwesomeIcon icon={faUserMagnifyingGlass} style={{ color: '#4AA3FF', marginRight: 8 }} />
				{obj?.name}
			</Link>
		)
	}

	getValor = valor => {
		return (
			<div style={{ textAlign: 'center' }}>
				<p>{valor ? formatBRL(valor) : ''}</p>
			</div>
		);
	};

	sortTable = e => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder }, () => {
			// let DataTableAnticipations = {
			// 	rows: this.state.rows,
			// 	totalRecords: this.state.totalRecords,
			// 	first: this.state.first,
			// 	last: this.state.last,
			// 	loading: this.state.loading,
			// 	sortField: this.state.sortField,
			// 	sortOrder: this.state.sortOrder,
			// };

			// localStorage.setItem('DataTableAnticipations', JSON.stringify(DataTableAnticipations));

			this.fetchData();
		});
	};

	columnOptionsCrud = (obj) => {
		if (this.props.crudUrl) {
			return (
				<div style={{ textAlign: "center" }}>
					<Link to={`/anticipations/show/${obj.id}`} style={{ width: 34 }}>
						<FontAwesomeIcon icon={faChevronRight} style={{ color: '#5664D2', fontSize: 14 }} />
					</Link>
				</div>
			);
		} else {
			return "a";
		}
	};

	render() {
		return (
			<div style={{ position: 'relative' }}>
				<DataTable ref={(el) => this.dt = el} value={this.state?.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={this.state.rowsPerPageOptions}
					first={this.state.first} last={this.state.last} header={null} responsive={true} className="ml-auto"
					totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} loadingIcon={`fas fa-sync fa-spin`}
					paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
					currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" emptyMessage="Nenhum registro encontrado!"
					onSort={(e) => this.sortTable(e)} sortField={this.state.sortField} sortOrder={this.state.sortOrder} id="custom-table-1">
					<Column field="created_by" body={(obj) => this.getUserName(obj.created_by_user)} header="Solicitante" style={{ width: '283px', padding: '16px' }} />
					<Column sortable field="created_at" body={(obj) => moment(obj.created_at).format('DD/MM/YYYY HH:mm:ss')} header="Data de registro" style={{ width: '159px', padding: '16px' }} />
					<Column sortable field="start_date" body={(obj) => moment(obj.start_date).format('DD/MM/YYYY')} header="Início da retenção" style={{ width: '152px', padding: '16px' }} />
					<Column sortable field="anticipation_period" header="Período antecipado" style={{ width: '161px', padding: '16px' }} />
					<Column sortable field="total_retained_value" body={(obj) => formatBRL(obj.total_retained_value)} header="Valor total retido" style={{ width: '283px', padding: '16px' }} />
					<Column header="" body={(obj) => this.columnOptionsCrud(obj)} style={{ width: '34px', padding: '16px' }} />
				</DataTable>

				<div className="paginator-new-items">
					<p>Registros por página</p>
					<Link className="custom-see-all-table" to={`/pagamento/cobrancas/index`} >
						Ver todos
					</Link>
				</div>
			</div>
		);
	}
}