import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Link } from "react-router-dom";
import { Column } from 'primereact/column';
import _ from 'lodash';
import { getRawById } from '../../crud/announcements.crud';
import moment from 'moment';
import { formatBRL, limparMoeda } from '../../helpers/Utils';
import { faUserMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class DataTableUserAnnouncements extends Component {
	constructor() {
		super();

		this.state = {
			data: [],
			rows: 10,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: 'id',
			sortOrder: 1,
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		this.onPage(this.state);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters != this.props.filters) {
			this.onFilterChanged();
		}
	}

	onFilterChanged = _.debounce(() => {
		if (!this.state.isLoading) {
			this.setState({
				loading: true,
				rows: 10,
				totalRecords: 0,
				first: 0,
				last: 0,
			}, () => { this.fetchData() });
		}
	}, 700);

	fetchData = (limit, skip) => {
		let data = {
			$limit: limit ? limit : this.state.rows,
			$skip: skip != null ? skip : this.state.first,
			$sort: { [this.state.sortField]: this.state.sortOrder }
		};

		this.props.fetchEvent(data).then(async res => {
			await res.data.data.map(async (obj, i) => {
				if (obj.metadata?.raw_announcement_id) {
					obj.loading_crawler_data = true;
					await getRawById(obj.metadata?.raw_announcement_id).catch(err => {
						obj.crawler_data = null
						obj.loading_crawler_data = false;
					}).then(res => {
						if (res?.data?.data) {
							obj.crawler_data = res.data.data;
						}
					});
				}

				this.setState({
					...this.state,
					data: res.data.data,
					totalRecords: res.data.total,
					first: res.data.skip,
					last: res.data.skip + res.data.limit,
					loading: false
				});
			});

			this.setState({
				...this.state,
				data: res.data.data,
				totalRecords: res.data.total,
				first: res.data.skip,
				last: res.data.skip + res.data.limit,
				loading: false
			});
		});
	};

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = async (event) => {
		if (event.data) {
			event.data = null;
		}

		this.setState({
			...this.state,
			rows: event.rows,
			loading: true
		});

		this.fetchData(event.rows, event.first);
	};

	columnOpcoesCrud = (rowData) => {
		let idRow = this.props.idRow ? this.props.idRow : 'id';

		if (this.props.crudUrl) {
			return (
				<Link to={`${this.props.crudUrl}/show/${rowData[idRow]}`} className='showButton'>
					<i className='fa fa-chevron-right' />
				</Link>
			);
		} else {
			return null;
		}
	};

	getIsPublished = (obj) => {
		if (obj.is_rented) {
			return (<div className='tag' style={{ backgroundColor: '#4AA3FF', margin: 'auto' }}>Alugado</div>)
		} else if (obj.is_published) {
			return (<div className='tag' style={{ margin: 'auto' }}>Publicado</div>)
		}
		else {
			return (<div className='tag' style={{ backgroundColor: '#FCB92C', margin: 'auto' }}>Inativo</div>)
		}
	};

	getDateFormatted = (date) => {
		return (
			<p>{moment(date).format('DD/MM/YYYY HH:mm:ss')}</p>
		)
	};

	getRatings = (positive_ratings) => {
		if (positive_ratings > 0) {
			return (
				<div className='negotiation-active'>
					{positive_ratings ?? 0}
					<FontAwesomeIcon icon={faUserMagnifyingGlass} style={{ marginLeft: '8.5px' }} />
				</div>
			);
		} else {
			return (
				<div className='negotiation-disabled'>
					--
					<FontAwesomeIcon icon={faUserMagnifyingGlass} style={{ marginLeft: '8.5px' }} />
				</div>
			);
		}
	};

	getWarranty = (key) => {
		switch (key) {
			case 'FREE':
				return 'Grátis';

			case 'WARRANTY':
				return 'Pagamento';


			case 'WARRANTY_GUARANTEE_DAMAGE':
				return 'Completa';

			default:
				return 'Não identificado';
		}
	};

	getInNumber = (value) => {
		value = formatBRL(value);
		value = limparMoeda(value);
		value = Number(value);

		return value;
	};

	getValuesFormated = (values) => {
		let rent = this.getInNumber(values?.rent ?? 0);
		let condo = this.getInNumber(values?.condo ?? 0);
		let iptu = this.getInNumber(values?.iptu ?? 0);

		return formatBRL(rent + condo + iptu);
	};

	sortTable = e => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder }, () => {
			this.fetchData();
		});
	};

	render() {
		const header = (
			<div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row' }}>
				{this.props.titleTable &&
					<>
						<h3 style={{ margin: 0 }}>{this.props.titleTable}</h3>

						<div style={{ marginLeft: 8 }} className='indicadorUsuarios'>{this.state?.totalRecords ?? 0}</div>
					</>
				}
			</div>
		);

		const paginatorRight = (
			<button onClick={() => { this.fetchData(this.state?.totalRecords); this.setState({ rows: this.state?.totalRecords }) }} className='btnSecundary' style={{ marginLeft: 24 }}>
				Ver todos
			</button>
		);

		return (
			<DataTable ref={(el) => this.dt = el} value={this.state.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={[10, 25, 50, this.state?.totalRecords]}
				first={this.state.first} last={this.state.last} header={this.props.noHeader ? null : header} responsive={true}
				totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} loadingIcon={`fas fa-sync fa-spin`}
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
				currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" emptyMessage="Nenhum registro encontrado!"
				onSort={(e) => this.sortTable(e)} sortField={this.state.sortField} sortOrder={this.state.sortOrder} paginatorRight={paginatorRight}>
				<Column style={{ width: 117 }} field="is_published" body={(obj, i) => this.getIsPublished(obj)} header="Status" />
				<Column style={{ width: 132 }} sortable field="created_at" body={(obj, i) => this.getDateFormatted(obj.created_at)} header="Anunciado em" />
				<Column style={{ width: 292 }} field="address.neighbourhood" header="Endereço" body={({ address }) => `${address.street} ${address.number}, ${address?.complement ?? ''} ${address.neighbourhood ? `, ${address.neighbourhood}` : ''},  - ${address.city}/${address.state}`} />
				<Column style={{ width: 102 }} field="warranty.type" body={({ warranty }, i) => `${this.getWarranty(warranty.type)}`} header="Garantia" />
				<Column style={{ width: 124 }} field="positive_ratings" header="Interessados" body={({ positive_ratings }, i) => this.getRatings(positive_ratings)} />
				<Column style={{ width: 131 }} sortable field="updated_at" body={(obj, i) => this.getDateFormatted(obj.updated_at ?? obj.created_at)} header="Atualizado em" />
				<Column style={{ width: 108 }} sortable field="values.rent" body={(obj, i) => this.getValuesFormated(obj.values)} header="Valor total" />
				<Column header="" body={this.columnOpcoesCrud} style={{ width: '34px', padding: 0 }} />
			</DataTable>
		);
	}
}