import React, { Component } from 'react';
import { Container, Card, CardBody, Col } from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import moment from 'moment';
import BetterCircularProgress from '../../../components/BetterCircularProgress';
import Breadcrumbs from '../../../components/Breadcrumb';
import BetterAlerts from '../../../components/BetterAlerts';
import { get, remove } from '../../../crud/negativacao.crud';
import { formatBRL } from '../../../helpers/Utils';


class NegativacaoShow extends Component {

	state = {
		loading: false,
		historicoList: [],
		pagamentoRecebidoList: [],
		success: [],
		data: {}
	};

	componentDidMount = () => {
		this.setState({ loading: true });

		get(this.props.match.params.id).catch(err => {
			alert('Ocorreu um erro, entre em contato com o suporte.');
		}).then(res => {
			if (res?.status === 200) {
				if (res.data) {
					this.setState({
						historicoList: res.data.data.eventsHistory?.data,
						pagamentoRecebidoList: res.data.data.paymentsReceived?.data,
						data: res.data.data.creditNegative
					});
				}
			}
		}).finally(() => {
			this.setState({ loading: false });
		});
	};

	handleCancelNegativation = () => {
		remove(this.props.match.params.negativationId).catch(err => {
			alert('Ocorreu um erro, entre em contato com o suporte.');
		}).then(res => {
			this.setState({ success: ["Negativação cancelada com sucesso!"] })
		}).finally(() => {
			this.setState({ loading: false });
		});
	};

	Text = ({ title, text, data, titleColor }) => (
		<div style={{ display: 'flex', flexDirection: 'column', rowGap: 6, marginBottom: 24 }}>
			<p className='p-0 m-0' style={{ color: titleColor, fontFamily: 'Inter', fontSize: 13, fontWeight: '600' }}>{title}</p>
			<p className='p-0 m-0' style={{ color: '#505D69', fontFamily: 'Inter', fontSize: 13, fontWeight: '400' }}>{text}</p>
			<p className='p-0 m-0' style={{ color: '#505D69', fontFamily: 'Inter', fontSize: 13, fontWeight: '600' }}>{moment(data).format('DD/MM/YYYY')}</p>
		</div>
	)

	getStatus = (obj) => {
		if (obj?.status === 'PENDING') {
			return (
				<div className="bg-secondary  d-flex flex-row" style={{ width: '98px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center' }}>
					<p className="col-form-label text-white">Pendente</p>
				</div>
			);
		} else if (obj?.status === 'AWAITING_APPROVAL') {
			return (
				<div className="d-flex flex-row" style={{ width: '140px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center', backgroundColor: '#FCB92C' }}>
					<p className="col-form-label text-white">Aguardando aprovação</p>
				</div>
			);
		} else if (obj?.status === 'AWAITING_CANCELLATION') {
			return (
				<div className="d-flex flex-row" style={{ width: '140px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center', backgroundColor: '#FF3D60' }}>
					<p className="col-form-label text-white">Aguardando cancelamento</p>
				</div>
			);
		} else if (obj?.status === 'PROCESSED') {
			return (
				<div className=" d-flex flex-row" style={{ width: '131px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center', backgroundColor: '#4AA3FF' }} >
					<p className="col-form-label text-white">Em negativação</p>
				</div>
			);
		} else if (obj?.status === 'PAID') {
			return (
				<div className="d-flex flex-row" style={{ width: '120px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center', backgroundColor: '#1CBB8C' }}>
					<p className="col-form-label text-white">Recuperado</p>
				</div>
			);
		} else if (obj?.status === 'PARTIALLY_PAID') {
			return (
				<div className=" d-flex flex-row" style={{ width: '120px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center', backgroundColor: '#5664D2' }}>
					<p className="col-form-label text-white">Pagamento iniciado</p>
				</div>
			);
		} else if (obj?.status === 'DENIED') {
			return (
				<div className="d-flex flex-row" style={{ width: '120px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center', backgroundColor: '#852032' }}>
					<p className="col-form-label text-white">Negado</p>
				</div>
			);
		} else if (obj?.status === 'CANCELLED') {
			return (
				<div className=" d-flex flex-row" style={{ width: '120px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center', backgroundColor: '#FF3D60' }}>
					<p className="col-form-label text-white">Cancelada</p>
				</div>
			);
		} else {
			return (
				<div className="bg-secondary d-flex flex-row" style={{ width: '98px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center' }}>
					<p className="col-form-label text-white">Aguardando</p>
				</div>
			);
		}
	};

	render() {
		return (
			<React.Fragment>
				<BetterCircularProgress loading={this.state.loading}>
					<div className="page-content container-page">
						<Container fluid>
							<Breadcrumbs breadcrumbItems={[{ link: '', title: 'Contrato' }, { link: ``, title: 'Visualizar' }, { link: ``, title: 'Negativação' }]} />
							<BetterAlerts errors={this.state.errors} success={this.state.success} />

							{this.state.success.length > 0 ?
								<Redirect to={{ pathname: `/pagamento/negativacao/index`, state: { success: ['Negativação cancelada com sucesso!'] } }} />
								: null
							}

							<Card>
								<CardBody>
									<Col md={12}>
										<div style={{ gap: 24, display: 'flex', alignItems: 'center', marginBottom: 24 }}>
											<p className='mb-0'>Status</p>


											{this.state?.data?.status && this.getStatus(this.state.data)}

										</div>
									</Col>

									<Col md={12} className="d-flex pl-0">
										<Col md={6} className="rounded">
											<h3 style={{ color: '#505D69', fontFamily: 'Inter', fontSize: 16, fontWeight: '600', marginBottom: 24 }}>Histórico de eventos</h3>

											<div style={{ background: "#F1F5F7", padding: 12 }}>
												{this.state.historicoList.map((text, index) =>
													<this.Text title={text?.status} key={index} text={text?.description} data={text?.eventDate} titleColor={'#5664D2'} />
												)}
											</div>
										</Col>
										<Col md={6} className="rounded ml-3">
											<h3 style={{ color: '#505D69', fontFamily: 'Inter', fontSize: 16, fontWeight: '600', marginBottom: 24 }}>Pagamentos recebidos</h3>

											<div style={{ background: "#F1F5F7", padding: 12 }}>
												{this.state.pagamentoRecebidoList.map((text, index) =>
													<this.Text title={formatBRL(text?.value)} key={index} text={text?.description} data={text?.paymentDate} titleColor={'#1CBB8C'} />
												)}
											</div>
										</Col>
									</Col>
									<div className="row mt-4">
										<Col md={12}>
											<Link to='/pagamento/negativacao/index' className="btn btn-secondary btn-bold">
												<i className="fa fa-arrow-left mr-2"></i>Voltar
											</Link>

											<button onClick={this.handleCancelNegativation} style={{ backgroundColor: '#FF3D60', color: '#FFF' }} className="btn btn-bold ml-4">
												Cancelar negativação <i style={{ color: '#FFF' }} className="fa fa-times"></i>
											</button>
										</Col>
									</div>
								</CardBody>
							</Card>
						</Container>
					</div>
				</BetterCircularProgress>
			</React.Fragment>
		);
	}
}

export default NegativacaoShow;