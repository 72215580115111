import React, { Component } from 'react';
import { Container, Card, CardBody, Col, Label, Button } from "reactstrap";
import BetterCircularProgress from '../../../components/BetterCircularProgress';
import { get as getUser } from '../../../crud/users.crud';
import { updateIncome } from '../../../crud/income-users.crud';
import { Link, Redirect } from "react-router-dom";
import { Formik } from "formik";
import Breadcrumbs from '../../../components/Breadcrumb';
import { TextField } from "@material-ui/core";
import { formatBRLInput, formatBRL, limparMoeda } from '../../../helpers/Utils';

class IncomeUserEdit extends Component {

	state = {
		loading: false,
		activeTab: '1',
		data: null,
		success: false,
		error: false,
	}

	componentDidMount = () => {
		this.setState({ loading: true });

		getUser(this.props.match.params.id).catch(err => {
			alert('An error occurred, please contact the support.');
		}).then(res => {
			if (res?.status === 200) {
				if (res.data) {
					this.setState({ data: res.data.data });
				}
			}
		}).finally(() => {
			this.setState({ ...this.state, loading: false })
		});
	};

	onSubmit = (values) => {
		this.setState({ loading: true });

		let data = {
			type: values.origem,
			gross_income: limparMoeda(values.valor),
		};

		updateIncome(this.props.match.params.id, data).then((res) => {
			if (res?.status === 200) {
				this.setState({ loading: false, success: true });
			}

			this.setState({ loading: false });
		}).catch(err => {
			this.setState({ loading: false, error: true });
		});
	};

	getInitialValues = () => {
		return {
			valor: this.state.data?.employment?.gross_income ? formatBRL(this.state.data?.employment?.gross_income) : '',
			origem: this.state.data?.employment?.type ? this.state.data?.employment?.type : '',
		};
	};

	getOrigensRenda = () => {
		return [
			{ key: 'clt', label: 'Funcionário Privado ou Público Registrado (CLT)' },
			{ key: 'public_agent', label: 'Funcionário Público (Estatutário)' },
			{ key: 'legal_person', label: 'Empresário' },
			{ key: 'liberal_professional', label: 'Profissional liberal' },
			{ key: 'autonomous', label: 'Autônomo' },
			{ key: 'retired', label: 'Aposentado ou Pensionista' },
			{ key: 'intern', label: 'Estagiário ou Bolsista' },
		];
	};

	render() {
		return (
			<React.Fragment>
				<BetterCircularProgress loading={this.state.loading}>
					<div className="page-content container-page">
						<Container fluid>
							<Breadcrumbs breadcrumbItems={[{ link: '/users/index', title: 'Usuários' }, { link: `/users/show/${this.state.data?.id}`, title: this.state.data?.name }, { link: `/users/income/${this.state.data?.id}`, title: 'Informar renda' }]} />

							<Card>
								<CardBody>
									{this.state.error ?
										<Redirect to={{ pathname: `/users/show/${this.props.match.params.id}`, state: { errors: ['Erro ao adicionar a renda informada.'] } }} />
										: null
									}

									{this.state.success ?
										<Redirect to={{ pathname: `/users/show/${this.props.match.params.id}`, state: { success: ['Renda informada adicionada com sucesso!'] } }} />
										: null
									}
									<Formik initialValues={this.getInitialValues()} onSubmit={(values) => this.onSubmit(values)}>
										{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
											return (
												<>
													<div className="row">
														<Col md={12}>
															<Label className="col-md-4 col-form-label" style={{ color: '#343A40', fontSize: '13px' }}>Origem da renda principal:</Label>
															<Col md={4}>
																<select
																	style={{ background: "#FFF", height: "40px" }}
																	name='origem'
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.origem}
																	className="custom-select">
																	<option key={null} value={null} hidden></option>
																	{this.getOrigensRenda().map((obj) => {
																		return (
																			<option key={obj.key} value={obj.key}>{obj.label}</option>
																		);
																	})}
																</select>
															</Col>
														</Col>

														<Col md={12} style={{ marginTop: "10px" }}>
															<Label className="col-md-4 col-form-label" style={{ color: '#343A40', fontSize: '13px' }}>Valor da renda principal:</Label>
															<Col md={4}>
																<TextField
																	style={{ background: "#FFF", height: "40px" }}
																	name='valor'
																	variant="outlined"
																	value={values.valor}
																	onBlur={handleBlur}
																	placeholder="R$ 0,00"
																	onChange={(e) => handleChange(formatBRLInput(e))}
																/>
															</Col>
														</Col>
													</div>

													<div className="row mt-4 ml-1">
														<Col md={6}>
															<Link to={`/users/show/${this.props.match.params.id}`} className="btn btn-secondary btn-bold"><i className="fa fa-arrow-left mr-2"></i>Voltar</Link>

															<Button
																color="success"
																type="submit"
																className="waves-effect waves-light ml-3"
																style={{ width: '158px' }}
																onClick={handleSubmit}>
																Salvar
															</Button>
														</Col>
													</div>
												</>
											);
										}}
									</Formik>
								</CardBody>
							</Card>
						</Container>
					</div>
				</BetterCircularProgress>
			</React.Fragment>
		)
	}
}

export default IncomeUserEdit;