import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Button } from 'reactstrap';
import { Column } from 'primereact/column';
import _ from 'lodash';
import moment from 'moment';
import { get as getBillings, listRepayments, uploadBoleto } from '../../crud/billings.crud';
import { Alert } from "reactstrap";
import { formatBRL } from '../../helpers/Utils';
import { Link } from 'react-router-dom';
import 'moment/locale/pt-br';

moment.locale('pt-br');
export default class DataTableContractsPayment extends Component {
	constructor() {
		super();

		this.state = {
			data: [],
			rows: 10,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: 'due_date',
			sortOrder: 1,
			boletoExists: false,
			boletoError: false,
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		this.setState({ loading: true });

		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters != this.props.filters) {
			this.onFilterChanged();
		}
	}

	onFilterChanged = _.debounce(() => {
		if (!this.state.isLoading) {
			this.fetchData();
		}
	}, 700);

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = async (event) => {
		if (event.data) event.data = null;

		this.setState({ rows: event.rows, loading: true });

		this.fetchData(event.rows, event.first);
	};

	fetchData = (limit, skip) => {
		let params = {
			contract_id: this.props.contractId,
			$limit: limit,
			$skip: skip != null ? skip : this.state.first,
			$sort: { [this.state.sortField]: this.state.sortOrder }
		};

		params.type = this.props.paymentType;

		if (this.props.paymentType === 'agreement') {
			params.friendly_agreement_id = this.props.billing?.friendly_agreement_id;
			params.$limit = 50;
		}

		getBillings(params).then(res => {
			res.data.data.map((element, index) => {
				let paramsReembolso = { billing_id: element?.id, $limit: 50 };

				let number = 0;

				res.data.data.map((billingMap) => {
					if (billingMap.type === 'termination') {
						number++;
						billingMap.billingNumeroParcela = number;
					}
				});

				listRepayments(paramsReembolso).then(({ data }) => {
					let amount = 0;

					if (data.data.length > 0) {
						data.data.map((reembolso, index) => {
							if (reembolso?.status == "accepted") {
								if (reembolso?.type == "addition") {
									amount += Number(reembolso?.amount);
								} else if (reembolso?.type == "repayment") {
									amount -= Number(reembolso?.amount);
								}
							}
						});
					}

					element.receipts.rent.value += amount;
				}).catch(({ response }) => {
					console.log(response)
				}).finally(() => {
					this.setState({
						...this.state,
						data: res.data.data,
						totalRecords: res.data.total,
						first: res.data.skip,
						last: res.data.skip + res.data.limit,
					});
				});
			});
		}).finally(() => {
			this.setState({ loading: false })
		});
	};

	getValueIptu = (obj) => {
		return (
			<div>
				<p className="col-form-label">{obj?.receipts?.iptu?.value ? formatBRL(obj?.receipts?.iptu?.value) : 'R$ -'}</p>
			</div>
		);
	};

	getValueCondo = (obj) => {
		return (
			<div>
				<p className="col-form-label">{obj?.receipts?.condo?.value ? formatBRL(obj?.receipts?.condo?.value) : 'R$ -'}</p>
			</div>
		);
	};

	getValueRent = (obj) => {
		return (
			<div>
				<p className="col-form-label">{obj.type == 'termination' || obj.type == 'warranty_refund' ? formatBRL(obj.values.value) : obj?.receipts?.rent?.value ? formatBRL(obj?.receipts?.rent?.value) : 'R$ -'}</p>
			</div>
		);
	};

	getStatus = (obj) => {
		const momentDate = moment(obj?.due_date).add(3, 'hours');

		const daysToAdd = momentDate.day() === 6 ? 2 : momentDate.day() === 0 ? 1 : 0;

		const dueDate = moment(obj?.due_date).add(daysToAdd, 'days').add(3, "hours").format('YYYYMMDD');
		const currentDate = moment().format('YYYYMMDD');

		const friendlyAgreement = this.props?.agreement;

		if ((friendlyAgreement && friendlyAgreement?.overdue_billings.includes(obj?.id)) || obj?.receipts?.rent?.agreement_settled) {
			return (
				<div className="tag" style={{ backgroundColor: obj?.receipts?.rent?.paid ? '#1CBB8C' : '#4AA3FF' }}>
					{obj?.receipts?.rent?.paid && obj?.receipts?.rent?.agreement_settled ? 'Acordo quitado' : 'Acordo vigente'}
				</div>
			)
		}

		if (obj?.type === 'monthly' && obj?.termination_id) {
			return (
				<div className="tag" style={{ backgroundColor: '#5664D2' }}>
					Acerto de rescisão
				</div>
			)
		}

		if (obj?.receipts?.rent?.paid === true && (obj?.receipts?.rent?.paid_for_insurer === false || !obj?.receipts?.rent?.paid_for_insurer)) {
			return (
				<div className="tag" style={{ backgroundColor: '#1CBB8C' }}>
					Pago
				</div>
			);
		} else if (obj?.receipts?.rent?.paid_for_insurer == true && obj?.receipts?.rent?.paid == true) {
			return (
				<div className='d-flex' style={{ gap: 4 }}>
					<div className="tag" style={{ backgroundColor: '#1CBB8C' }}>
						Pago
					</div>

					<div className="tag" style={{ backgroundColor: '#4AA3FF', marginTop: 4 }}>
						Garantido
					</div>
				</div>
			);
		} else if (obj?.receipts?.rent?.paid_for_insurer == true && obj?.receipts?.rent?.paid == false) {
			return (
				<div className='d-flex' style={{ gap: 4 }}>
					<div className="tag" style={{ backgroundColor: '#FF3D60' }}>
						Atrasado
					</div>

					<div className="tag" style={{ backgroundColor: '#4AA3FF', marginTop: 4 }}>
						Garantido
					</div>
				</div>
			);
		} else if (moment().isSameOrBefore(obj?.due_date, 'day') && obj?.boleto) {
			return (
				<div className="tag" style={{ backgroundColor: '#FCB92C' }}>
					Em aberto
				</div>
			);
		} else if (moment().isAfter(moment(obj?.due_date).add(1, 'd'), 'day')) {
			return (
				<div className="tag" style={{ backgroundColor: '#FF3D60' }}>
					Atrasado
				</div>
			);
		} else {
			return (
				<div className="tag" style={{ backgroundColor: '#8590A5' }}>
					Aguardando
				</div>
			);
		}
	};

	getDate = (obj) => {
		return (
			<div>
				<p className="col-form-label">{obj?.due_date ? moment(obj?.due_date).add(3, "hours").format('DD/MM/YYYY') : ''}</p>
			</div>
		);
	};

	getPaymentType = (billing) => {
		let terminationExists = false;

		if (this.props.terminations && this.props.terminations.length > 0) {
			terminationExists = true;
		}

		if (billing.type === 'monthly' && billing.number === 0) {
			return (
				<div>
					<p className="col-form-label">Primeiro Aluguel</p>
				</div>
			)
		} else if (billing.type === 'agreement') {
			return (
				<div>
					<p className="col-form-label">Acordo - Parcela {billing.number}</p>
				</div>
			)
		} else if (billing.type === 'termination' && terminationExists) {
			return (
				<div>
					<p className="col-form-label">{`Parcela ${billing.billingNumeroParcela} Rescisão: ${billing.user_id == billing.landlord_id ? 'Responsável' : 'Inquilino'}`}</p>
				</div>
			)
		} else if (billing.type === 'termination' && !terminationExists) {
			return (
				<div>
					<p className="col-form-label">{`Vistoria`}</p>
				</div>
			)
		} else {
			return (
				<div>
					<p className="col-form-label">Aluguel mensal</p>
				</div>
			)
		}

	}

	columnOpcoesCrud = (obj) => {
		return (
			<Link to={`/billing/show/${obj.id}`} className='showButton'>
				<i className='fa fa-chevron-right' />
			</Link>
		)
	};

	sortTable = e => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder }, () => {
			let contractsDataTable = {
				rows: this.state.rows,
				totalRecords: this.state.totalRecords,
				first: this.state.first,
				last: this.state.last,
				loading: this.state.loading,
				sortField: this.state.sortField,
				sortOrder: this.state.sortOrder,
			};

			localStorage.setItem('contractsDataTable', JSON.stringify(contractsDataTable));

			this.fetchData();
		});
	};

	render() {
		return (
			<DataTable ref={(el) => this.dt = el} value={this.state.data} totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} loadingIcon={`fas fa-sync fa-spin`}
				emptyMessage="Nenhum registro encontrado!" onSort={(e) => this.sortTable(e)} sortField={this.state.sortField} sortOrder={this.state.sortOrder}>
				<Column style={{ width: 188 }} body={(obj, i) => `${obj.billingNumeroParcela ? obj.billingNumeroParcela : (i.rowIndex + 1)} de ${this.state.data.length}`} field="created_at" sortable header="Parcela" />
				<Column style={{ width: 136 }} body={(obj) => this.getStatus(obj)} field="status" header="Status" />
				<Column style={{ width: 104 }} body={(obj) => obj?.paid_at ? moment(obj?.paid_at).add(3, "hours").format('DD/MM/YYYY HH:mm:ss') : ''} field="paid_at" header="Pago em" />
				<Column style={{ width: 118 }} body={(obj) => this.getDate(obj)} field="due_date" header="Vencimento" />
				<Column style={{ width: 108 }} body={(obj) => this.getValueRent(obj)} field="receipts.receipts.values" header="Valor Total" />
				<Column style={{ width: 34, justifyContent: 'center' }} body={(obj) => this.columnOpcoesCrud(obj)} />
			</DataTable>
		);
	}
}