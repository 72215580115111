import axios from "axios";
import { Constants } from '../helpers/Constants';
import { parseQuery } from '../helpers/Utils';

export function getTypes() {
    return axios.get(`${Constants.baseUrl}/hsm/config/types`);
}

export function get(params) {
    return axios.get(`${Constants.baseUrl}/hsm/config`, { params: parseQuery(params) });
}

export function store(data) {
    return axios.post(`${Constants.baseUrl}/hsm/config`, data);
}