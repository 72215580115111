import React, { Component, createRef } from 'react';
import { Container, Card, Row, Col, Label, Input, Nav, NavItem, NavLink } from "reactstrap";
import Breadcrumbs from '../../../components/Breadcrumb';
import BetterAlerts from '../../../components/BetterAlerts';
import classnames from 'classnames';
import BetterCircularProgress from '../../../components/BetterCircularProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronRight, faFilter, faMagnifyingGlass, faTimes, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { faArrowLeft, faArrowRotateRight, faFileMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { Link } from "react-router-dom";
import { getReturnPreview as getAllByParams, getSecReturnById, joinRetFile } from '../../../crud/billings.crud';
import { formatBRL } from '../../../helpers/Utils';
import { Redirect } from "react-router-dom";
import DataTableReturnBillings from '../../../components/datatables/DataTableReturnBilings';

class ReturnPreview extends Component {
	dataTableRef = createRef();

	state = {
		flags: {
			created_at_lte: null,
			created_at_gte: null,
			status: null,
			landlord: null
		},
		filters: {
			created_at_lte: null,
			created_at_gte: null,
			status: null,
			landlord: null
		},
		is_filter_open: false,
		errors: [],
		success: [],
		activeTab: '1',
		filtersCount: 0,
		newFilters: [],
		indicadores: {},
		totalAtual: 0,
		list_users: [],
		termo_user: '',
		shipment_billings: [],
		shipment_id: null,
		return_id: null,
		secReturn: null,
		totalApproved: null,
		totalRejected: null,
	};

	componentDidMount = () => {
		if (this.props?.location?.state?.success) {
			this.setState({ success: this.props.location.state.success });
		}

		getSecReturnById(this.props.match.params.id).then(({ data, status }) => {
			if (status == 200) {
				this.setState({ secReturn: data })
			}
		})

		getAllByParams({ status: 'REJECTED', return_id: this.props.match.params.id }).then(({ data, status }) => {
			if (status == 200) {
				this.setState({ totalRejected: data.total })
			}
		})
	};

	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({ activeTab: tab }, () => {
				this.setState({ totalAtual: 0 });
				this.dataTableRef?.current?.fetchData();
			});
		}
	};

	saveFilters = () => {
		localStorage.setItem('shipmentFilter', JSON.stringify(this.state.filters));
		localStorage.setItem('shipmentFlags', JSON.stringify(this.state.flags));
	};

	fetchByFilter = (data) => {
		var params = {
			...data,
			return_id: this.props.match.params.id
		};

		if (this.state.activeTab === '1') {
			params = {
				...params,
				status: 'APPROVED'
			}
		} else if (this.state.activeTab === '2') {
			params = {
				...params,
				status: 'REJECTED'
			}
		}

		return getAllByParams(params);
	};

	joinRetFile = async () => {
		const return_id = this.props.match.params.id;

		await joinRetFile({ return_id }).catch((error) => {
			this.setState({ errors: error.response.data.errors })
		}).then(({ data, status }) => {
			if (status == 200) {
				this.setState({ success: ["Arquivo conciliado com sucesso!"] })
			}
		})
	}

	calcFaceVolume = () => {
		let volume = 0;

		this.state.shipment_billings.map((billing) => {
			volume += billing.value;
		})

		return formatBRL(volume);
	}

	FiltersModule = ({ filtroModular, index }) => {
		const getFilterChoised = (filter) => {
			let component = <></>

			if (filter == 'due_date') {
				component = (
					<>
						<div className='d-flex'>
							<Label htmlFor="due_date_gte" className="col-form-label">De</Label>
							<Input className="form-control" type="date" id="due_date_gte" value={this.state.filters.due_date_gte} style={{ width: 200, margin: '0 16px' }}
								onChange={(e) => this.setState({ filters: { ...this.state.filters, due_date_gte: e.target.value } }, () => this.saveFilters())} />
						</div>

						<div className='d-flex'>
							<Label htmlFor="due_date_lte" className="col-form-label">Até</Label>
							<Input className="form-control" type="date" id="due_date_lte" value={this.state.filters.due_date_lte} style={{ width: 200, margin: '0 16px' }}
								onChange={(e) => this.setState({ filters: { ...this.state.filters, due_date_lte: e.target.value } }, () => this.saveFilters())} />
						</div>
					</>
				)
			} else if (filter == 'shipment_batch') {
				component = (
					<div className='d-flex'>
						<Input className="form-control" type="text" value={this.state.filters.shipment_batch} style={{ width: 400, margin: '0 16px' }} placeholder="Pesquisar por lote de remessa"
							onChange={(e) => this.setState({ filters: { ...this.state.filters, shipment_batch: e.target.value } }, () => this.saveFilters())} />
					</div>
				)
			} else {
				component = <></>
			}

			return component;
		}

		const onSetFilter = (filtro) => {
			let stateFilter = this.state.newFilters.slice();
			stateFilter[index] = filtro;

			this.setState({ newFilters: stateFilter })
		}

		const getSelectOptions = (nameFiltro) => {
			const stateFilter = this.state.newFilters;

			if (stateFilter.includes(nameFiltro)) {
				return 'd-none'
			} else {
				return ''
			}
		}

		const removeFilter = (filterUnit, filterName) => {
			let stateFilter = this.state.newFilters;

			stateFilter.splice(filterUnit, 1);

			switch (filterName) {
				case 'due_date':
					this.setState({
						flags: { ...this.state.flags, due_date_gte: false, due_date_lte: false },
						filters: { ...this.state.filters, due_date_gte: null, due_date_lte: null }
					}, () => this.saveFilters())

					break;

				case 'shipment_batch':
					this.setState({
						flags: { ...this.state.flags, shipment_batch: false },
						filters: { ...this.state.filters, shipment_batch: null }
					}, () => this.saveFilters())

					break;

				default:
					break;
			}

			this.setState({ newFilters: stateFilter })
		}

		return (
			<Col md={12} className="d-flex m-0 p-0 filtroUnit">
				<div className='d-flex'>
					<select onChange={({ target }) => onSetFilter(target.value)} value={this.state.newFilters[index]} className="custom-select" style={{ width: 300 }}>
						<option value="">Selecione um filtro</option>
						<option className={getSelectOptions('due_date')} value="due_date">Vencimento</option>
						<option className={getSelectOptions('shipment_batch')} value="shipment_batch">Lote</option>
					</select>

					{this.state.newFilters[index] != '' && (
						<div className='divisionArrow'>
							<FontAwesomeIcon icon={faChevronRight} />
						</div>
					)}

					{getFilterChoised(this.state.newFilters[index])}
				</div>

				<div onClick={() => removeFilter(index, this.state.newFilters[index])}>
					<FontAwesomeIcon icon={faTimes} />
				</div>
			</Col>
		)
	}


	render() {

		return (
			<React.Fragment>
				<div className="page-content container-page userIndex sec-shipment">
					{this.state.success.length > 0 && this.state.success[0] === 'Arquivo conciliado com sucesso!' ? (
						<Redirect
							to={{
								pathname: `/pagamento/securitizacao/retorno/show/${this.props.match.params.id}`,
								state: { success: ["Conciliação com arquivo de retorno foi um sucesso!"] },
							}}
						/>
					) : null}

					<Container fluid>
						<div className='header'>
							<h1>Upload de retorno</h1>

							<Breadcrumbs breadcrumbItems={[{ link: '/pagamento/cobrancas/index', title: 'Pagamentos' }, { link: '/announcements/securitizacao/index', title: 'Remessas e Retornos' }, { link: '', title: 'Upload de retorno' }]} />
						</div>

						<BetterAlerts errors={this.state.errors} success={this.state.success} />

						<Col md={12} className="box-filtros">
							<Col md={12} className="d-flex m-0 p-0">
								<div className='generalSearch'>
									<Input className="inputCustomNew" type="text" value={this.state.filters.searchAll} placeholder="Busque por contrato, CPF ou CNPJ do sacado"
										onChange={(e) => this.setState({ filters: { ...this.state.filters, searchAll: e.target.value } }, () => this.saveFilters())} />

									<FontAwesomeIcon icon={faMagnifyingGlass} />
								</div>

								<button onClick={() => this.setState({ newFilters: [...this.state.newFilters, ''] })} className="btnSecundary" style={{ width: 'max-content', margin: '0px 8px', padding: '8px 12px' }}>
									FILTRO
									<FontAwesomeIcon icon={faFilter} />
								</button>

								<button onClick={() => this.dataTableRef.current.refreshTable()} className="btnSecundary" style={{ width: 'max-content', margin: '0px 8px', padding: '8px 12px' }}>
									ATUALIZAR
									<FontAwesomeIcon icon={faArrowRotateRight} />
								</button>
							</Col>

							{this.state.newFilters.map((filtroModular, index) => {
								return <this.FiltersModule filtroModular={filtroModular} key={index} index={index} />
							})}
						</Col>

						<Row>
							<Col lg={12}>
								<Card className='card-general'>
									<h3 style={{ marginBottom: 40 }}>Prévia cobranças do retorno</h3>

									<Nav tabs className="nav-tabs-custom" role="tablist" style={{ marginBottom: '32px' }}>
										<NavItem>
											<NavLink id="announcements-tab" className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggleTab('1'); }}>
												<div className='tabName'>Validadas
													<div className='indicadorUsuarios'>
														{this.state?.totalApproved ?? <FontAwesomeIcon spin icon={faSpinner} />}
													</div>
												</div>
											</NavLink>
										</NavItem>

										<NavItem>
											<NavLink id="announcements-tab" className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggleTab('2'); }}>
												<div className='tabName'>Rejeitadas
													<div className='indicadorUsuarios'>
														{this.state?.totalRejected ?? <FontAwesomeIcon spin icon={faSpinner} />}
													</div>
												</div>
											</NavLink>
										</NavItem>
									</Nav>
									<div className='datatable-box'>
										<BetterCircularProgress loading={this.state.loading}>
											<DataTableReturnBillings filters={this.state.filters} noDelete fetchEvent={this.fetchByFilter} crudButtons={true}
												crudUrl="/pagamento/negativacao/index" idRow={`id`} export={this.state.export} ref={this.dataTableRef} state={this.state.shipment_billings} setState={this.setState.bind(this)} tabActive={this.state.activeTab} />
										</BetterCircularProgress>
									</div>
								</Card>
							</Col>
						</Row>

						<Row>
							<Col lg={12}>
								<Card className='card-general'>
									<div className='datatable-box'>
										<BetterCircularProgress loading={this.state.loading}>
											<div className='d-flex justify-content-between align-items-center'>
												<div className='d-flex'>
													<div>
														<h3 className='sec-shipment-subtitle-label' style={{ fontWeight: 'normal' }}>Arquivo</h3>
														<div>
															<FontAwesomeIcon icon={faFileMagnifyingGlass} style={{ color: '#4AA3FF', marginRight: 8 }} />
															<Link style={{ color: "#4AA3FF", fontSize: 13, fontWeight: 600, fontFamily: 'Inter' }}>{this.state?.file?.initial_name ?? "ARQUIVO.RET"}</Link>
														</div>
													</div>

													<div style={{ marginLeft: 34 }}>
														<h3 className='sec-shipment-subtitle-label'>Cobranças inclusas</h3>
														<h1 className='sec-shipment-subtitle-value'>{this.state.secReturn?.total_billings}</h1>
													</div>

													<div style={{ marginLeft: 34 }}>
														<h3 className='sec-shipment-subtitle-label'>Volume face</h3>
														<h1 className='sec-shipment-subtitle-value' style={{ color: '#1CBB8C' }}>{formatBRL(this.state?.secReturn?.face_volume ?? 0)}</h1>
													</div>
												</div>

												<div className='d-flex'>
													<button onClick={() => this.props.history.goBack()} className="btnSecundary" style={{ width: 'max-content', margin: '0px 8px', padding: '8px 12px' }}>
														<FontAwesomeIcon icon={faArrowLeft} />
														VOLTAR
													</button>

													<button onClick={async () => await this.joinRetFile()} className="buttonPrimary w-100" style={{ width: 'max-content', margin: '0px 8px', padding: '8px 12px' }}>
														CONCILIAR .RET
														<FontAwesomeIcon icon={faCheck} />
													</button>
												</div>
											</div>
										</BetterCircularProgress>
									</div>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}

export default ReturnPreview;