import React, { Component } from 'react';
import { Container, Card, CardBody, Alert, Row, Col, DropdownMenu, Input, DropdownItem, Button, FormGroup, DropdownToggle, ButtonDropdown, Label } from "reactstrap";
import BetterCircularProgress from '../../components/BetterCircularProgress';
import { adicionarCasasDecimais, formatBRL } from '../../helpers/Utils';
import Breadcrumbs from '../../components/Breadcrumb';
import moment from 'moment';
import { getAllByFilter, getInfos } from '../../crud/doc-users.crud';
import Swal from 'sweetalert2';
import DataTableConbracasJuridicas from '../../components/datatables/DataTableConbracasJuridicas';
import { UserFilterComponent } from '../users/components/user.component';
import BetterAlerts from '../../components/BetterAlerts';
import { Link } from 'react-router-dom';

class docUserIndex extends Component {

	state = {
		filters: {
			sentence_date_lte: undefined,
			sentence_date_gte: undefined,
			status: undefined,
			created_by: undefined,
			payments_value_lte: undefined,
			friendly_contract_code: undefined
		},
		flags: {
			show_status: false,
			show_sentence_date_lte: false,
			show_sentence_date_gte: false,
			show_created_by: false,
			show_contract: false,
			show_payments_value_lte: false,
		},
		is_filter_open: false,
		export: false,
		extrajudicialInfos: {
			"totalPendingValue": 0,
			"openDocs": 0,
			"wonDocs": 0,
			"feesWon": 0,
			"lostDocs": 0,
			"feesLost": 0
		}
	};

	componentDidMount = async () => {
		this.setState({ loading: true });

		getAllByFilter().catch(err => {
			return Swal.fire('Ops', 'Ocorreu um erro ao buscar a cobrança jurídica.', 'error');
		}).then(res => {
			if (res?.status === 200) {
				console.log(res.data.data);
				if (res.data) {
					this.setState({ data: res.data.data })
				}
			}
		}).finally(() => this.setState({ loading: false }));
	};

	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	};

	formatDate(date) {
		const newDate = date?.split('/')[1] + '/' + date?.split('/')[0] + '/' + date?.split('/')[2];
		return newDate;
	}

	fetchByFilter = (data) => {
		let params = {
			...data,
			friendly_contract_code: this.state.flags.show_contract ? this.state.filters.friendly_contract_code : undefined,
			payments_value_lte: this.state.flags.show_payments_value_lte && this.state.flags.show_payments_value_lte !== '' ? adicionarCasasDecimais(parseFloat(this.state.filters.payments_value_lte)) : undefined,
			created_by: this.state.flags.show_created_by ? this.state.filters.created_by : undefined,
			status: this.state.flags.show_status ? this.state.filters.status === 'OPEN' ? 'null' : this.state.filters.status : undefined,
			sentence_date_gte: this.state.flags.show_sentence_date_gte ? this.state.filters.sentence_date_gte?.replace(/[\\"]/g, "") : undefined,
			sentence_date_lte: this.state.flags.show_sentence_date_lte ? this.state.filters.sentence_date_lte : undefined,
		};

		let paramsInfo = {
			friendly_contract_code: this.state.flags.show_contract ? this.state.filters.friendly_contract_code : undefined,
			payments_value_lte: this.state.flags.show_payments_value_lte && this.state.flags.show_payments_value_lte !== '' ? adicionarCasasDecimais(parseFloat(this.state.filters.payments_value_lte)) : undefined,
			created_by: this.state.flags.show_created_by ? this.state.filters.created_by : undefined,
			status: this.state.flags.show_status ? this.state.filters.status === 'OPEN' ? 'null' : this.state.filters.status : undefined,
			sentence_date_gte: this.state.flags.show_sentence_date_gte ? this.state.filters.sentence_date_gte?.replace(/[\\"]/g, "") : undefined,
			sentence_date_lte: this.state.flags.show_sentence_date_lte ? this.state.filters.sentence_date_lte : undefined,
		};


		getInfos({ category: 'Cobranças jurídicas', ...paramsInfo }).then(res => {
			if (res.status === 200) {
				this.setState({ extrajudicialInfos: res.data })
			}
		})

		return getAllByFilter(params);
	};

	render() {
		return (
			<React.Fragment>
				<div className="page-content container-page">
					<Container fluid>
						<Breadcrumbs breadcrumbItems={[{ link: '/doc-user/index', title: 'Dashboard' }]} />
						<Row className="align-right mb-30">

							<ButtonDropdown className="mr-10 wm-100" isOpen={this.state.is_filter_open} toggle={() => this.setState({ is_filter_open: !this.state.is_filter_open })} >
								<DropdownToggle color="primary" caret>
									<i className="ri-filter-2-fill align-middle mr-2"></i> {"ADD FILTRO"}
								</DropdownToggle>
								<DropdownMenu className="col-sm btn-index-action dropdown-menu-right">
									{!this.state.flags.show_contract ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_contract: true } })}>Contrato</DropdownItem> : null}
									{!this.state.flags.show_payments_value_lte ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_payments_value_lte: true } })}>Valor até</DropdownItem> : null}
									{!this.state.flags.show_created_by ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_created_by: true } })}>Usuário que fez upload</DropdownItem> : null}
									{!this.state.flags.show_status ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_status: true } })}>Status</DropdownItem> : null}
									{!this.state.flags.show_sentence_date_gte ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_sentence_date_gte: true } })}>Sentença depois de</DropdownItem> : null}
									{!this.state.flags.show_sentence_date_lte ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_sentence_date_lte: true } })}>Sentença antes de</DropdownItem> : null}
								</DropdownMenu>

							</ButtonDropdown>
						</Row>
						<BetterAlerts errors={this.state.errors} success={this.state.success} />


						<Row className="container-center-input">
							<Col lg={12}>
								<Card>
									<h3 className="title" style={{ color: '#505D69', fontSize: '22px', fontWeight: '600', padding: '1.25rem', marginTop: '10px' }}>Cobranças jurídicas</h3>
									<div style={{ padding: '0.6rem', display: 'flex' }}>
										{this.state.flags.show_contract ?
											<Col style={{ flex: '0 0 14%', maxWidth: '14%' }}>
												<Label className="col-form-label">Contrato</Label>
												<FormGroup row style={{ flexGrow: 0 }}>
													<div className="col-10 col-sm-8 container-center-input">
														<Input className="form-control" type="text" value={this.state.filters.friendly_contract_code}
															style={{ height: "40px", }}
															placeholder='Digite...'
															onChange={(e) => this.setState({ filters: { ...this.state.filters, friendly_contract_code: e.target.value } })} />
													</div>
													<Button onClick={() => this.setState({ flags: { ...this.state.flags, show_contract: false }, filters: { ...this.state.filters, friendly_contract_code: '' } })} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
														<i className="ri-close-circle-line align-middle"></i>
													</Button>
												</FormGroup>
											</Col>
											: false
										}

										{this.state.flags.show_payments_value_lte ?
											<Col style={{ flex: '0 0 14%', maxWidth: '14%' }}>
												<Label className="col-form-label">Valor até</Label>
												<FormGroup row style={{ flexGrow: 0 }}>
													<div className="col-10 col-sm-8 container-center-input">
														<Input className="form-control" type="text" value={this.state.filters.payments_value_lte ? formatBRL(this.state.filters.payments_value_lte) : ''}
															style={{ height: "40px", }}
															placeholder='R$'
															onChange={(e) => this.setState({ filters: { ...this.state.filters, payments_value_lte: Number(e.target.value.toString().replace(/[^0-9-]/g, '')) / 10 ** 2 } })} />
													</div>
													<Button onClick={() => this.setState({ flags: { ...this.state.flags, show_payments_value_lte: false }, filters: { ...this.state.filters, payments_value_lte: undefined } })} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
														<i className="ri-close-circle-line align-middle"></i>
													</Button>
												</FormGroup>
											</Col>
											: false
										}

										{this.state.flags.show_created_by ?
											<Col style={{ flex: '0 0 18%', maxWidth: '18%' }}>
												<Label className="col-form-label" style={{ color: "#343A40" }}>Usuário que fez upload</Label>

												<FormGroup row >
													<Col style={{ flexGrow: 0 }}>
														<UserFilterComponent
															label="Usuário que fez upload"
															onChange={(e, value) => {
																this.setState({ filters: { ...this.state.filters, created_by: value.id } });
															}}
															value={this.state.filters.created_by}
															onClick={
																() => this.setState(
																	{
																		flags: { ...this.state.flags, show_created_by: false },
																		filters: { ...this.state.filters, created_by: {} }
																	},
																)
															}
															style={{ width: '160px' }}
														/>
													</Col>
													<Button onClick={
														() => this.setState(
															{
																flags: { ...this.state.flags, show_created_by: false },
																filters: { ...this.state.filters, created_by: undefined }
															},
														)
													} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
														<i className="ri-close-circle-line align-middle"></i>
													</Button>
												</FormGroup>
											</Col>
											: false
										}

										{this.state.flags.show_status ?
											<Col style={{ flex: '0 0 18%', maxWidth: '18%' }}>
												<Label className="col-form-label" style={{ color: "#343A40" }}>Status</Label>

												<FormGroup row style={{ width: '300px' }}>
													<Col style={{ flexGrow: 0 }}>
														<select style={{ width: '160px', height: "40px", }} onChange={(e, value) => {
															this.setState({ filters: { ...this.state.filters, status: e.target.value } });
														}} value={this.state.filters.status} className="custom-select">
															<option key={null} value={null} hidden>Selecione o status</option>
															<option key='WON' value='WON'>Ganho</option>
															<option key='OPEN' value='OPEN'>Em aberto</option>
															<option key='LOST' value='LOST'>Perdido</option>
														</select>
													</Col>

													<Button onClick={
														() => this.setState(
															{
																flags: { ...this.state.flags, show_status: false },
																filters: { ...this.state.filters, status: undefined }
															},
														)
													} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
														<i className="ri-close-circle-line align-middle"></i>
													</Button>
												</FormGroup>
											</Col>
											: false
										}

										{this.state.flags.show_sentence_date_gte ?
											<Col style={{ flex: '0 0 18%', maxWidth: '18%' }}>
												<Label className="col-form-label" style={{ color: "#343A40" }}>
													Sentença depois de
												</Label>
												<FormGroup row style={{ flexGrow: 0 }}>
													<Col style={{ flexGrow: 0 }}>
														<Input className="mb-2" type="date" variant="outlined" style={{ background: "#FFF", height: "40px", width: '160px' }} value={this.state.filters.sentence_date_gte}
															onChange={(e) => this.setState({ filters: { ...this.state.filters, sentence_date_gte: e.target.value } })} />
													</Col>
													<Button onClick={() => this.setState({ flags: { ...this.state.flags, show_sentence_date_gte: false }, filters: { ...this.state.filters, sentence_date_gte: '' } })} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
														<i className="ri-close-circle-line align-middle"></i>
													</Button>
												</FormGroup>
											</Col>
											: false
										}

										{this.state.flags.show_sentence_date_lte ?
											<Col style={{ flex: '0 0 18%', maxWidth: '18%' }}>
												<Label className="col-form-label" style={{ color: "#343A40" }}>
													Sentença antes de
												</Label>
												<FormGroup row style={{ flexGrow: 0 }}>
													<Col style={{ width: '275px', flexGrow: 0 }}>
														<Input className="mb-2" type="date" variant="outlined" style={{ background: "#FFF", height: "40px", width: '160px' }} value={this.state.filters.sentence_date_lte}
															onChange={(e) => this.setState({ filters: { ...this.state.filters, sentence_date_lte: e.target.value } })} />
													</Col>
													<Button onClick={() => this.setState({ flags: { ...this.state.flags, show_sentence_date_lte: false }, filters: { ...this.state.filters, sentence_date_lte: '' } })} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
														<i className="ri-close-circle-line align-middle"></i>
													</Button>
												</FormGroup>
											</Col>
											: false
										}
									</div>
									<CardBody style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center', flex: 'inherit' }} id="dashboard">
										<div className="dashboardCard" style={{ width: '16%' }}>
											<h3 className="" style={{ color: '#505D69' }}><small>R$</small> {formatBRL(this.state?.extrajudicialInfos?.totalPendingValue).replace('R$', '')}</h3>
											<p className="">Valor pendente total</p>
										</div>
										<div className="dashboardCard" style={{ width: '16%' }}>
											<h3 className="" style={{ color: '#FCB92C' }}>{this.state?.extrajudicialInfos?.openDocs}</h3>
											<p className="">Cobranças em aberto</p>
										</div>
										<div className="dashboardCard" style={{ width: '16%' }}>
											<h3 className="" style={{ color: '#1CBB8C' }}>{this.state?.extrajudicialInfos?.wonDocs}</h3>
											<p className="">Ganhos</p>
										</div>
										<div className="dashboardCard" style={{ width: '16%' }}>
											<h3 className="" style={{ color: '#1CBB8C' }}><small style={{ color: '#505D69' }}>R$</small> {formatBRL(this.state?.extrajudicialInfos?.feesWon).replace('R$', '')}</h3>
											<p className="">Honorários ganhos</p>
										</div>
										<div className="dashboardCard" style={{ width: '16%' }}>
											<h3 className="" style={{ color: '#FF3D60' }}>{this.state?.extrajudicialInfos?.lostDocs}</h3>
											<p className="">Perdidos</p>
										</div>
										<div className="dashboardCard" style={{ width: '16%' }}>
											<h3 className="" style={{ color: '#FF3D60' }}><small style={{ color: '#505D69' }}>R$</small> {formatBRL(this.state?.extrajudicialInfos?.feesLost).replace('R$', '')}</h3>
											<p className="">Honorários perdidos</p>
										</div>
									</CardBody>
									<CardBody>
										<DataTableConbracasJuridicas filters={this.state.filters} noDelete fetchEvent={this.fetchByFilter} crudButtons={true}
											crudUrl="/doc-user" idRow={`id`} export={this.state.export} rows={10} />
									</CardBody>
									<CardBody className="row">
										<Link to={'/dashboard'} className="btn btn-secondary btn-bold ml-2"><i className="fa fa-arrow-left mr-2"></i>Voltar</Link>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		)
	}
}

export default docUserIndex;