import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";
import { FiltersModuleConversionIndicators, findMetricInfo, getChartColors, transformDataForChart } from "../filters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical, faEyeSlash, faGripVertical, faMaximize, faMinimize } from '@fortawesome/pro-solid-svg-icons';
import { Checkbox, ListItemText, Menu, MenuItem } from "@material-ui/core";
import { Chart } from "react-google-charts";

export default class ConversionIndicatorSection extends Component {
	constructor() {
		super();

		this.state = {
			metrics: [],
			conversionIndicatorsFilters: {
				"conversion_and_revenue": "conversion",
				"time_frame": "ultimos_12_meses"
			},
			conversionIndicatorsAvailableLabels: [
				{ label: "Convertidos por admins", isActive: true, key: 'CONVERSION_INDICATORS_BY_ADMIN', color: '#1CBB8C' },
				{ label: "Convertidos de afiliados", isActive: true, key: 'CONVERSION_INDICATORS_BY_AFFILIATE', color: '#4AA3FF' },
				{ label: "Convertidos de comissionados", isActive: true, key: 'CONVERSION_INDICATORS_BY_COMMISSIONED', color: '#5664D2' },
				{ label: "Convertidos orgânicos", isActive: true, key: 'CONVERSION_INDICATORS_BY_ORGANIC', color: '#FCB92C' },
				{ label: "Indicados não convertidos", isActive: true, key: 'CONVERSION_INDICATORS_NOT_CONVERTED', color: '#D9D9D9' }
			],
			dropdownKey: null,
			anchorEl: null,
			maximeGraphic: false
		};
	}

	componentDidMount() {
		if (this?.props?.metrics) {
			this.setState({ metrics: this.props.metrics })
		}
	}

	render() {
		return (
			<Card>
				<CardBody className="dashboard-item">
					<div className="box-header">
						<div className="">
							<h3 className="title">Indicadores de conversão ativa</h3>
							<div className="box-info">
								Aquisição
							</div>

							{this.props.layout?.w === 12 ? (
								<div className="box-filter" style={{ marginLeft: 16 }}>
									<FiltersModuleConversionIndicators setState={this.setState.bind(this)} state={this.state.conversionIndicatorsFilters} />
								</div>
							) : null}
						</div>

						<div>
							<FontAwesomeIcon icon={this.props.layout?.w === 6 ? faMaximize : faMinimize} style={{ color: '#5664D2', padding: '12px', cursor: 'pointer' }} onClick={() => this.props.toggleMaximize(this.props?.itemKey)} />
							<FontAwesomeIcon icon={faEllipsisVertical} style={{ color: '#5664D2', padding: '12px', cursor: 'pointer' }} onClick={(e) => {
								this.setState({ dropdownKey: this.props.itemKey });
								this.setState({ anchorEl: e.currentTarget });
							}} />
							<FontAwesomeIcon icon={faEyeSlash} style={{ color: '#5664D2', padding: '12px', cursor: 'pointer' }} onClick={() => this.props.changeWidgetValue(this.props.itemKey)} />
							<FontAwesomeIcon icon={faGripVertical} style={{ color: '#DADBDD', padding: '12px', cursor: 'pointer' }} />
						</div>
					</div>

					<Menu
						anchorEl={this.state.anchorEl}
						open={this.state.dropdownKey === this.props.itemKey ? true : false}
						onClose={() => { this.setState({ dropdownKey: null }) }}
						className="dashboard-item"
						id="dashboard-drop"
					>
						{this.state.conversionIndicatorsAvailableLabels.map((item) => (
							<MenuItem key={item.label} value={item.label}>
								<Checkbox checked={item.isActive} onChange={() => {
									this.setState(prevState => ({
										conversionIndicatorsAvailableLabels: prevState.conversionIndicatorsAvailableLabels.map(i =>
											i.label === item.label
												? { ...i, isActive: !i.isActive }
												: i
										)
									}));
								}} />
								<ListItemText primary={item.label} />
							</MenuItem>
						))}
					</Menu>

					{this.props.layout?.w === 6 ? (
						<div className="box-filter" style={{ marginLeft: 16 }}>
							<FiltersModuleConversionIndicators setState={this.setState.bind(this)} state={this.state.conversionIndicatorsFilters} />
						</div>
					) : null}

					<div style={{ position: 'relative' }}>
						<hr className="divisor" />
					</div>

					<div className="chart-wrapper">
						{this.state.metrics.find(obj => obj.filters?.includes(this.state.conversionIndicatorsFilters.time_frame))?.value ? (
							<div className="chart-pie-wrapper">
								<Chart
									key={this.state.maximeGraphic ? 'maximized' : 'minimized'}
									chartType="PieChart"
									width={'267px'}
									height="267px"
									data={transformDataForChart(this.state.metrics.find(obj => obj.filters?.includes(this.state.conversionIndicatorsFilters.time_frame))?.value, this.state.conversionIndicatorsFilters.conversion_and_revenue, this.state.conversionIndicatorsAvailableLabels)}
									options={{
										title: '',
										pieHole: 0.4,
										slices: getChartColors(this.state.conversionIndicatorsAvailableLabels),
										legend: 'none',
									}}
								/>
							</div>
						) : null}
						<div className="chart-pie-legend">
							<div className="box-pie-legend" style={{ display: this.state.conversionIndicatorsAvailableLabels.find(obj => obj?.label === 'Convertidos por admins')?.isActive ? '' : 'none' }}>
								<div className="bg-green">
									<p>
										{findMetricInfo(this.state.metrics, [this.state.conversionIndicatorsFilters.time_frame], 'CONVERSION_INDICATORS_BY_ADMIN', this.state.conversionIndicatorsFilters.conversion_and_revenue === 'conversion' ? 'count' : 'sum')}
									</p>
								</div>
								<div className="box-pie-legend-text">
									<p>Convertidos por admins</p>
								</div>
							</div>

							<div className="box-pie-legend" style={{ display: this.state.conversionIndicatorsAvailableLabels.find(obj => obj?.label === 'Convertidos de afiliados')?.isActive ? '' : 'none' }}>
								<div className="bg-blue">
									<p>
										{findMetricInfo(this.state.metrics, [this.state.conversionIndicatorsFilters.time_frame], 'CONVERSION_INDICATORS_BY_AFFILIATE', this.state.conversionIndicatorsFilters.conversion_and_revenue === 'conversion' ? 'count' : 'sum')}
									</p>
								</div>
								<div className="box-pie-legend-text">
									<p>Convertidos de afiliados</p>
								</div>
							</div>

							<div className="box-pie-legend" style={{ display: this.state.conversionIndicatorsAvailableLabels.find(obj => obj?.label === 'Convertidos de comissionados')?.isActive ? '' : 'none' }}>
								<div className="bg-purple">
									<p>
										{findMetricInfo(this.state.metrics, [this.state.conversionIndicatorsFilters.time_frame], 'CONVERSION_INDICATORS_BY_COMMISSIONED', this.state.conversionIndicatorsFilters.conversion_and_revenue === 'conversion' ? 'count' : 'sum')}
									</p>
								</div>
								<div className="box-pie-legend-text">
									<p>Convertidos de comissionados</p>
								</div>
							</div>

							<div className="box-pie-legend" style={{ display: this.state.conversionIndicatorsAvailableLabels.find(obj => obj?.label === 'Convertidos orgânicos')?.isActive ? '' : 'none' }}>
								<div className="bg-yellow">
									<p>
										{findMetricInfo(this.state.metrics, [this.state.conversionIndicatorsFilters.time_frame], 'CONVERSION_INDICATORS_BY_ORGANIC', this.state.conversionIndicatorsFilters.conversion_and_revenue === 'conversion' ? 'count' : 'sum')}
									</p>
								</div>
								<div className="box-pie-legend-text">
									<p>Convertidos orgânicos</p>
								</div>
							</div>

							<div className="box-pie-legend" style={{ display: this.state.conversionIndicatorsAvailableLabels.find(obj => obj?.label === 'Indicados não convertidos')?.isActive ? '' : 'none' }}>
								<div className="bg-gray">
									<p>
										{findMetricInfo(this.state.metrics, [this.state.conversionIndicatorsFilters.time_frame], 'CONVERSION_INDICATORS_NOT_CONVERTED', this.state.conversionIndicatorsFilters.conversion_and_revenue === 'conversion' ? 'count' : 'sum')}
									</p>
								</div>
								<div className="box-pie-legend-text">
									<p>Indicados não convertidos</p>
								</div>
							</div>
						</div>
					</div>
				</CardBody>
			</Card>
		)
	}
}


