import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Link } from "react-router-dom";
import { Column } from 'primereact/column';
import moment from 'moment';

export default class DataTablePublicLinks extends Component {
    constructor() {
        super();

        this.state = {
            data: [],
            rows: 10,
            totalRecords: 0,
            first: 0,
            last: 0,
            loading: false,
            sortField: 'id',
            sortOrder: '1',
        };

        this.onPage = this.onPage.bind(this);
    }

    componentDidMount() {
        let publicLinksDataTable = JSON.parse(localStorage.getItem('publicLinksDataTable'));

        if (publicLinksDataTable) {
            this.setState({
                rows: publicLinksDataTable.rows,
                totalRecords: publicLinksDataTable.totalRecords,
                first: publicLinksDataTable.first,
                last: publicLinksDataTable.last,
                loading: publicLinksDataTable.loading,
                sortField: publicLinksDataTable.sortField,
                sortOrder: publicLinksDataTable.sortOrder,
            }, () => {
                this.fetchData();
            });
        } else {
            this.fetchData();
        }
    }

    refreshTable() {
        this.onPage(this.state);
    }

    onPage = async (event) => {
        if (event.data) {
            event.data = null;
        }

        let publicLinksDataTable = {
            rows: event.rows,
            totalRecords: this.state.totalRecords,
            first: event.first,
            last: event.rows + event.first,
            loading: this.state.loading,
            sortField: this.state.sortField,
            sortOrder: this.state.sortOrder,
        }

        localStorage.setItem('publicLinksDataTable', JSON.stringify(publicLinksDataTable));

        this.setState({
            ...this.state,
            rows: event.rows,
            loading: true
        });

        this.fetchData(event.rows, event.first);
    };

    fetchData = (limit, skip) => {
        let data = {
            $limit: limit ? limit : this.state.rows,
            $skip: skip != null ? skip : this.state.first,
            $sort: { [this.state.sortField]: this.state.sortOrder }
        };

        this.props.fetchEvent(data).then(res => {
            this.setState({
                ...this.state,
                data: res.data.data,
                totalRecords: res.data.total,
                first: res.data.skip,
                last: res.data.skip + res.data.limit,
                loading: false
            });
        });
    };

    columnOpcoesCrud = (rowData, column) => {
        let idRow = this.props.idRow ? this.props.idRow : 'id';

        if (this.props.crudUrl) {
            return (
                <div style={{ textAlign: 'center' }}>
                    <Link to={`${this.props.crudUrl}/show/${rowData[idRow]}`} className="btn btn-primary btn-table-action mr-2"><i className="fas fa-eye"></i></Link>
                </div>
            );
        } else {
            return null;
        }
    };

    export() {
        this.dt.exportCSV();
    };

    sortTable = e => {
        this.setState({ sortField: e.sortField, sortOrder: e.sortOrder }, () => {
            let publicLinksDataTable = {
                rows: this.state.rows,
                totalRecords: this.state.totalRecords,
                first: this.state.first,
                last: this.state.last,
                loading: this.state.loading,
                sortField: this.state.sortField,
                sortOrder: this.state.sortOrder,
            };

            localStorage.setItem('publicLinksDataTable', JSON.stringify(publicLinksDataTable));

            this.fetchData();
        });
    };

    getLink = (link) => {
        return (
            <a className="link" rel="noopener noreferrer" target="_blank" href={link}>{link}</a>
        )
    };

    getDateFormatted = (date) => {
        return (
            <p>{moment(date).format('DD/MM/YYYY HH:mm:ss')}</p>
        )
    }

    render() {
        const header = (
            <div style={{ float: 'right' }}>
                <Button className="tableHeaderItem" type="button" style={{ marginRight: '5px', marginLeft: '5px' }} icon="fas fa-file-csv" iconPos="left" label="CSV" onClick={() => this.export()} />
                <Button className="tableHeaderItem" icon={!this.state.loading ? "fas fa-sync" : "fas fa-sync fa-spin"} disabled={this.state.loading ? "disabled" : ""} onClick={() => this.refreshTable()} />
            </div>
        );

        return (
            <DataTable ref={(el) => this.dt = el} value={this.state.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={[10, 25, 50]}
                first={this.state.first} last={this.state.last} header={this.props.noHeader ? null : header} responsive={true}
                totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} loadingIcon={`fas fa-sync fa-spin`}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" emptyMessage="Nenhum registro encontrado!"
                onSort={(e) => this.sortTable(e)} sortField={this.state.sortField} sortOrder={this.state.sortOrder}>
                <Column sortable field="host" header="Host" />
                <Column sortable field="path" header="path" />
                <Column field="short_link" body={(obj, i) => this.getLink(obj.short_link)} header="Short link" />
                <Column field="long_link" body={(obj, i) => this.getLink(obj.long_link)} header="Long link" />
                <Column sortable field="access_data.total" header="Total de acessos" />
                <Column sortable field="access_data.last_accessed_at" body={(obj, i) => this.getDateFormatted(obj.last_accessed_at)} header="Último acesso em" />
                <Column sortable field="created_at" body={(obj, i) => this.getDateFormatted(obj.created_at)} header="Criado em" />
                <Column header="Ações" body={this.columnOpcoesCrud} style={{ width: '130px' }} />
            </DataTable>
        );
    }
}