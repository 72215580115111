let url = '';
let chatUrl = '';
let dashboardUrl = '';

if (process.env.NODE_ENV === 'development') {
	url = process.env.REACT_APP_LOCAL_BACKEND_URL;
	chatUrl = `${process.env.REACT_APP_LOCAL_CHAT_URL}`;
	dashboardUrl = `${process.env.REACT_APP_LOCAL_DASHBOARD_URL}`;
} else {
	url = process.env.REACT_APP_PROD_URL;
	chatUrl = `${process.env.REACT_APP_PROD_CHAT_URL}`;
	dashboardUrl = `${process.env.REACT_APP_PROD_DASHBOARD_URL}`;
}

export const Constants = {
	google_api_key: 'AIzaSyA28uelbhq7R01vogC5m7nBjPdZnyx4s-0',
	url: url,
	baseUrl: url,
	funil_proprietarios_project_id: 1,
	chatUrl,
	dashboardUrl
};
