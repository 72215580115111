import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import _ from 'lodash';
import moment from 'moment';
import { get as getUser } from '../../../crud/users.crud';
import { formatBRL, formatBRLInput, limparMoeda } from '../../../helpers/Utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faEye, faPen, faPencil, faSave, faUserMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Button } from "reactstrap";
import Modal from 'react-bootstrap/Modal';
import { Formik } from "formik";
import { Checkbox, TextField } from "@material-ui/core";
import { formatDate } from "../../../pages/negotiation/helpers";
import { update as updateDocUser } from "../../../crud/doc-users.crud";
import { createFormikValidation } from '../../../helpers/FormValidators';

export default class DataTableDocList extends Component {
	constructor() {
		super();

		this.state = {
			data: [],
			rows: 10,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: 'id',
			sortOrder: 1,
			editField: null
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		let userDocsTable = JSON.parse(localStorage.getItem('userDocsTable'));

		if (userDocsTable) {
			this.setState({
				rows: userDocsTable.rows,
				totalRecords: userDocsTable.totalRecords,
				first: userDocsTable.first,
				last: userDocsTable.last,
				loading: userDocsTable.loading,
				sortField: userDocsTable.sortField,
				sortOrder: userDocsTable.sortOrder,
			}, () => {
				this.fetchData();
			});
		} else {
			this.fetchData();
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters != this.props.filters) {
			this.onFilterChanged();
		}
	}

	onFilterChanged = _.debounce(() => {
		if (!this.state.isLoading) {
			this.fetchData();
		}
	}, 700);

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = (event) => {
		if (event.data) {
			event.data = null;
		}

		let userDocsTable = {
			rows: event.rows,
			totalRecords: this.state.totalRecords,
			first: event.first,
			last: event.rows + event.first,
			loading: this.state.loading,
			sortField: this.state.sortField,
			sortOrder: this.state.sortOrder,
		}

		localStorage.setItem('userDocsTable', JSON.stringify(userDocsTable));

		this.setState({
			...this.state,
			rows: event.rows,
			loading: true
		});

		this.fetchData(event.rows, event.first);
	};

	fetchData = (limit, skip) => {
		let data = {
			$limit: limit ? limit : this.state.rows,
			$skip: skip != null ? skip : this.state.first,
			$sort: { [this.state.sortField]: this.state.sortOrder }
		};

		if (!this.props.contract_id) {
			this.setState({ ...this.state, data: [], totalRecords: 0, first: 0, last: 0, });
			return;
		}

		this.props.fetchEvent(data).then(async res => {
			res?.data?.data?.map(async (obj, i) => {
				if (obj.created_by) {
					await getUser(obj.created_by).catch(err => {
					}).then(res => {
						if (res?.data?.data) {
							obj.user = res.data.data;
						}
					});
				}

				this.setState({
					...this.state,
					data: res.data.data,
					totalRecords: res.data.total,
					first: res.data.skip,
					last: res.data.skip + res.data.limit,
					loading: false
				});
			});

			this.setState({
				...this.state,
				data: res.data.data,
				totalRecords: res.data.total,
				first: res.data.skip,
				last: res.data.skip + res.data.limit,
				loading: false
			});
		}).catch((err) => {
		});
	}

	sortTable = e => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder }, () => {
			let userDocsTable = {
				rows: this.state.rows,
				totalRecords: this.state.totalRecords,
				first: this.state.first,
				last: this.state.last,
				loading: this.state.loading,
				sortField: this.state.sortField,
				sortOrder: this.state.sortOrder,
			};

			localStorage.setItem('userDocsTable', JSON.stringify(userDocsTable));

			this.fetchData();
		});
	};

	getFileNameByUrl = (url) => {
		if (url) {
			const parts = url?.split("/");
			return parts[parts?.length - 1];
		} else {
			return 'Sem arquivo'
		}
	};

	getDocumentLink = (obj) => {
		if (obj?.category === 'Termo Aditivo') {
			return (
				<a target="_blank" rel="noreferrer" style={{ wordWrap: 'break-word' }} href={obj.file_desc?.secure_url ?? obj.file_desc?.url}>{`Termo-aditivo-${moment(obj?.created_at).format('DD-MM-YYYY')}`}</a>
			)
		}

		return (
			<a target="_blank" rel="noreferrer" style={{ wordWrap: 'break-word' }} href={obj.file_desc?.secure_url ?? obj.file_desc?.url}>{this.getFileNameByUrl(obj.file_desc?.secure_url ?? obj.file_desc?.url)}</a>
		)
	}

	getUserName = (obj) => (
		<div className='negotiation-active' style={{ textAlign: 'center' }}>
			<FontAwesomeIcon icon={faUserMagnifyingGlass} style={{ marginRight: '8.5px' }} />

			<Link to={`/users/show/${obj?.created_by}`} >{obj?.user?.name}</Link>
		</div>
	);

	getDescription = (obj, handleChange, values) => {
		if (this.state?.editField?.id === obj?.id) {
			return (
				<div className="rounded border p-3" style={{ borderColor: '#DADBDD', backgroundColor: '#fff' }}>
					<textarea className='border-0' style={{ outline: 'none', width: '100%', minHeight: '112px', resize: 'none' }} value={values.new_description} name="new_description" onChange={handleChange} />
				</div>
			)
		}
		return (
			<div>
				<p className='mb-0'>{obj?.description}</p>
			</div>
		)
	}

	getComission = (obj, handleChange, values) => {
		if (this.state?.editField?.id === obj?.id) {
			return (
				<div className="rounded border p-3" style={{ borderColor: '#DADBDD', backgroundColor: '#fff' }}>
					<input
						className="border-0"
						style={{ outline: 'none', width: '100%' }}
						value={values.new_payment_value}
						name="new_payment_value"
						onChange={(e => handleChange(formatBRLInput(e)))}
					/>
				</div>
			)
		}
		return (
			<div>
				<p className='mb-0'>{formatBRL(obj?.payments_value)}</p>
			</div>
		)
	}

	showModal = (obj) => {
		this.handleShow();
		this.setState({ selectedDocId: obj.id })
	}

	handleClose = () => this.setState({ showModal: false });
	handleShow = () => this.setState({ showModal: true });

	columnOptionsCrud = (obj, setFieldValue, values) => {
		return (
			<div style={{ textAlign: "start", paddingLeft: 12, display: 'flex' }}>
				{this.state.editField?.id === obj.id ? (
					<Button className="mr-2" style={{ background: 'transparent', borderColor: 'transparent', padding: 0, outline: 'none', border: 'none', boxShadow: 'none' }} onClick={() => {
						this.updateDoc(values);
					}}>
						<FontAwesomeIcon icon={faSave} style={{ color: '#1CBB8C', fontSize: '18px' }} />
					</Button>
				) : (
					<Button className="mr-2" style={{ background: 'transparent', borderColor: 'transparent', padding: 0, outline: 'none', border: 'none', boxShadow: 'none' }} onClick={() => {
						this.setState({ editField: obj });
						setFieldValue('new_description', obj?.description);
						setFieldValue('new_payment_value', formatBRL(obj?.payments_value))
					}}>
						<FontAwesomeIcon icon={faPen} style={{ color: '#5664D2', fontSize: '18px' }} />
					</Button>
				)}

				{obj?.status === 'OPEN' || obj?.status === null ? (
					<Button className="mr-2" style={{ background: 'transparent', borderColor: 'transparent', padding: 0, outline: 'none', border: 'none', boxShadow: 'none' }} onClick={() => this.showModal(obj)}>
						<FontAwesomeIcon icon={faCircleCheck} style={{ color: '#5664D2', fontSize: '18px' }} />
					</Button>
				) : <Link to={`/doc-user/show/${obj.id}`} className="btn btn-table-action mr-2 p-0">
					<FontAwesomeIcon icon={faEye} style={{ color: "#5664D2", fontSize: '18px', padding: 0, outline: 'none', border: 'none', boxShadow: 'none' }} />
				</Link>}
			</div>
		);

	};

	updateDoc = (values) => {
		const data = {
			"description": values.new_description,
			"payments_value": limparMoeda(values.new_payment_value)
		}

		updateDocUser(this.state.editField?.id, data).then(res => {
			if (res.status === 200) {
				this.fetchData();
				this.setState({ editField: null })
			}
		}).catch(err => {
			alert('Houve um erro, contate o suporte!')
		})
	}

	handleSubmit = (values) => {
		const data = {
			"description": values.description,
			"status": this.state.decision,
			"sentence_date": moment(values.sentence_date).format('YYYY-MM-DD'),
			"final_value": limparMoeda(values.final_value),
			"fees": {
				"type": values.fees_type,
				"value": typeof values.fees_value === 'string' ? limparMoeda(values.fees_value) : values.fees_value
			}
		}

		updateDocUser(this.state.selectedDocId, data).then(res => {
			if (res.status === 200) {
				this.fetchData();
				this.handleClose();

			}
		}).catch(err => {
			alert('Houve um erro, contate o suporte!')
		})
	};

	getInitialValues = () => {
		return {
			description: '',
			status: '',
			sentence_date: '',
			final_value: null,
			fees_type: null,
			fees_value: null,
			confirmation: false
		};
	};

	getInitialValuesTable = () => {
		return {

		}
	}

	validateCobranca = (values) => {
		let map = new Map();

		map.set('description', 'descrição');
		map.set('sentence_date', 'data');
		map.set('final_value', 'valor final');
		map.set('fees_type', 'honorários');
		map.set('fees_value', 'honorários');

		let errors = createFormikValidation(map, values);

		if (!values.confirmation) {
			errors.confirmation = 'É necesário confimar a ação antes de prosseguir!';
		}

		return errors;
	};

	rowClassName(rowData, index) {
		return { 'even-row': index % 2 === 0 };
	}

	render() {
		return (
			<>

				<Modal centered show={this.state?.showModal} onHide={this.handleClose}>

					<Formik initialValues={this.getInitialValues()} validate={values => this.validateCobranca(values)} onSubmit={(values) => this.handleSubmit(values)}>
						{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
							<form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>
								<Modal.Header closeButton={false}>
									<Modal.Title>Concluir cobrança jurídica</Modal.Title>
									{this.state?.errors ? (
										<div style={{ backgroundColor: '#FF3D60' }}>
										</div>
									) : null}
									<button onClick={(() => this.handleClose())} style={{ backgroundColor: 'transparent', color: '#8590A5', display: 'flex', alignItems: 'center', border: 'none', fontWeight: '900', fontSize: '18px' }}>
										X
									</button>
								</Modal.Header>
								<Modal.Body>
									<div>
										<div style={{ display: 'flex' }}>
											<div style={{ width: '50%' }}>
												<p style={{ marginBottom: '5px' }}>Decisão*</p>
												<div style={{ display: 'flex' }}>
													<button style={{ width: '66px', height: '36px', borderRadius: '4px', padding: '8px 12px 8px 12px', border: 'none', marginRight: '12px', backgroundColor: this.state.decision === 'WON' ? '#5664D2' : '#F1F5F7', color: this.state.decision === 'WON' ? '#FFFFFF' : '#505D69' }} onClick={(e) => { e.preventDefault(); this.setState({ decision: 'WON' }) }}>Ganho</button>
													<button style={{ width: '66px', height: '36px', borderRadius: '4px', padding: '8px 12px 8px 12px', border: 'none', backgroundColor: this.state.decision === 'LOST' ? '#5664D2' : '#F1F5F7', color: this.state.decision === 'LOST' ? '#FFFFFF' : '#505D69' }} onClick={(e) => { e.preventDefault(); this.setState({ decision: 'LOST' }) }}>Perdido</button>
												</div>

											</div>
											<div style={{ width: '50%' }}>
												<p style={{ marginBottom: '5px' }}>Data da sentença*</p>
												<div style={{ display: 'flex' }}>
													<TextField className="mb-2" style={{ background: "#FFF", height: "40px" }} name={'sentence_date'} placeholder="00/00/0000"
														type="date" variant="outlined" value={values.sentence_date} InputLabelProps={{ shrink: true }} inputProps={{
															min: formatDate({ date: moment(), format: 'YYYY-MM-DD' })
														}}
														onChange={async e => { handleChange(e); }} onBlur={handleBlur} helperText={touched.sentence_date && errors.sentence_date}
														error={Boolean(touched.sentence_date && errors.sentence_date)} />
												</div>

											</div>
										</div>

										<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
											<div style={{ width: '48%' }}>
												<p style={{ marginBottom: '5px' }}>Valor final*</p>
												<div style={{ display: 'flex' }}>
													<TextField name="final_value" placeholder={'R$ 0.000.000,00'} className={'input'} type="text" variant="outlined" value={values.final_value}
														onBlur={handleBlur} onChange={(e) => handleChange(formatBRLInput(e))}
														helperText={touched.final_value && errors.final_value}
														error={Boolean(touched.final_value && errors.final_value)}
													/>
												</div>

											</div>
											<div style={{ width: '48%' }}>
												<p style={{ marginBottom: '5px' }}>Tipo de honorários*</p>
												<div style={{ display: 'flex', flexDirection: 'column' }}>
													<select
														style={{ background: "#FFF", height: "40px" }}
														name="fees_type"
														onChange={(e, value) => {
															handleChange(e);
															setFieldValue('fees_value', null)
														}}
														onBlur={handleBlur}
														value={values.fees_type}
														helperText={touched.fees_type && errors.fees_type}
														error={Boolean(touched.fees_type && errors.fees_type)}
														className="custom-select">
														<option key={null} value={null} hidden>Selecione</option>
														<option key="FIXED_VALUE" value="FIXED_VALUE">Valor fixo</option>
														<option key="PERCENTAGE" value="PERCENTAGE">Porcentagem</option>

													</select>
													{touched.fees_type && errors.fees_type ? (
														<>
															<p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">{errors.fees_type}</p>
														</>
													) : null}
												</div>

											</div>
										</div>

										{values?.fees_type && values?.fees_type === 'FIXED_VALUE' ? (
											<div style={{ display: 'flex', flexDirection: 'column', marginTop: '12px' }}>
												<p style={{ marginBottom: '5px' }}>Valor fixo dos honorários*</p>
												<div style={{ display: 'flex' }}>
													<TextField name="fees_value" placeholder={'R$ 0,00'} className={'input'} type="text" variant="outlined" value={values.fees_value}
														onBlur={handleBlur} onChange={(e) => handleChange(formatBRLInput(e))}
														helperText={touched.fees_value && errors.fees_value}
														error={Boolean(touched.fees_value && errors.fees_value)}
													/>
												</div>
											</div>
										) : null}

										{values?.fees_type && values?.fees_type === 'PERCENTAGE' ? (
											<div style={{ display: 'flex', flexDirection: 'column', marginTop: '12px' }}>
												<p style={{ marginBottom: '5px' }}>Porcentagem dos honorários*</p>
												<div style={{ display: 'flex' }}>
													<TextField name="fees_value" placeholder={'%'} className={'input'} type="number" variant="outlined" value={values.fees_value}
														onBlur={handleBlur} onChange={(e) => {
															if (e.target.value >= 0 && e.target.value <= 100) {
																handleChange(e)
															}
														}}
														helperText={touched.fees_value && errors.fees_value}
														error={Boolean(touched.fees_value && errors.fees_value)}
													/>
												</div>
											</div>
										) : null}

										<div style={{ display: 'flex', flexDirection: 'column', marginTop: '12px' }}>
											<p style={{ marginBottom: '5px' }}>Descrição*</p>
											<div style={{ display: 'flex' }}>
												<TextField name="description" placeholder={'Digite uma descrição sobre o encerramento jurídica'} className={'input'} type="text" variant="outlined" value={values.description}
													onBlur={handleBlur} onChange={(e) => handleChange(e)}
													helperText={touched.description && errors.description}
													error={Boolean(touched.description && errors.description)}
												/>
											</div>
										</div>

										<div style={{ display: 'flex', marginTop: '12px', alignItems: 'center' }}>

											<div style={{ display: 'flex', flexDirection: 'column' }}>
												<Checkbox name="confirmation" style={{ paddingLeft: '0px' }} color="primary" checked={values.confirmation} onBlur={handleBlur} onChange={() => setFieldValue('confirmation', true)} helperText={touched.confirmation && errors.confirmation}
													error={Boolean(touched.confirmation && errors.confirmation)} />

											</div>
											<p style={{ marginLeft: '0px', marginBottom: '0px' }}>Confirmo que desejo concluir esta cobrança jurídica.</p>
										</div>

										{touched.confirmation && errors.confirmation ? (
											<>
												<p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" style={{ color: '#f44336' }}>{errors.confirmation}</p>
											</>
										) : null}

									</div>
								</Modal.Body>
								<Modal.Footer>
									<button onClick={this.handleClose} style={{ backgroundColor: '#F1F5F7', fontFamily: 'Inter', border: 'none', color: '#505D69', padding: '0.47rem 0.75rem', borderRadius: '4px' }}>
										Cancelar
									</button>
									<Button type="submit" variant="primary" style={{ backgroundColor: '#5664D2', fontFamily: 'Inter', border: 'none' }}>
										Continuar
									</Button>
								</Modal.Footer>
							</form>
						)}
					</Formik>

				</Modal>

				<Formik initialValues={this.getInitialValuesTable()} >
					{({ values, handleChange, setFieldValue }) => (
						<form noValidate={true} autoComplete="off" >
							<DataTable ref={(el) => this.dt = el} value={this.state.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={[10, 25, 50]} sortOrder={this.state.sortOrder}
								first={this.state.first} last={this.state.last} responsive={true} loading={this.state.loading} loadingIcon={`fas fa-sync fa-spin`}
								totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} onSort={(e) => this.sortTable(e)} sortField={this.state.sortField}
								paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
								currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" emptyMessage="Nenhum documento vinculado" rowClassName={this.rowClassName}>
								<Column style={{ width: 116, padding: '16px' }} sortable field="created_at" body={({ created_at }, _) => <div >{moment(created_at).format('DD/MM/YYYY HH:mm:ss')}</div>} header="Cadastrado" />
								<Column style={{ width: 221, padding: '16px' }} sortable field="file_desc.url" header="ARQUIVO" body={(obj, i) => this.getDocumentLink(obj)} />
								<Column style={{ width: 124, padding: '16px' }} sortable field="category" header="Categoria" body={(obj, i) => obj.category || 'Documento interno'} />
								<Column style={{ width: 337, padding: '16px' }} sortable field="description" header="Descrição" body={(obj, i) => this.getDescription(obj, handleChange, values)} />
								<Column style={{ width: 159, padding: '16px' }} sortable field="user.name" header="Admin responsável" body={(obj, i) => this.getUserName(obj)} />
								<Column style={{ width: 136, padding: '16px' }} sortable field="payments_value" header="$ comissão" body={(obj, i) => this.getComission(obj, handleChange, values)} />
								<Column header="Ações" body={(obj) => this.columnOptionsCrud(obj, setFieldValue, values)} style={{ width: '90px' }} />
							</DataTable>
						</form>)}
				</Formik>
			</>
		);
	}
}