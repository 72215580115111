import React, { Component } from 'react';
import { Container, Card, CardBody, Row, Col } from "reactstrap";
import Breadcrumbs from '../../components/Breadcrumb';
import { getAllByFilter } from '../../crud/publicLinks.crud';
import DataTablePublicLinks from '../../components/datatables/DataTablePublicLinks';

class PublicLinksIndex extends Component {

    fetchByFilter = (data) => {
        let params = { ...data };

        return getAllByFilter(params);
    };

    render() {
        return (
            <React.Fragment>
                <div className="page-content container-page">
                    <Container fluid>
                        <Breadcrumbs title="Links" breadcrumbItems={[]} />

                        <Row className="container-center-input">
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <DataTablePublicLinks noDelete fetchEvent={this.fetchByFilter} crudButtons={true} crudUrl="/public-links"
                                            idRow={`id`} noShow />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default PublicLinksIndex;