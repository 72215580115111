import React, { Component } from 'react';
import { Col, FormGroup, Label, TabContent, TabPane, Nav, NavItem, NavLink, Row, Input } from "reactstrap";
import BetterCircularProgress from '../../components/BetterCircularProgress';
import classnames from 'classnames';
import { getPropertiesById, updateProperties as updateAnnouncement, postImage, deleteImage, getComissioningParticipants, removeCommissioningParticipants, createCommissioningParticipants, updateCommissioningParticipants } from '../../crud/announcements.crud';
import { Link, Redirect } from "react-router-dom";
import { Formik } from "formik";
import BetterAlerts from '../../components/BetterAlerts';
import BetterSelect from '../../components/BetterSelect';
import Breadcrumbs from '../../components/Breadcrumb';
import { getAppliedByAnnouncement } from '../../crud/coupons.crud';
import { formatCep, getStates, buscaCep, formatBRLInput, formatBRL, limparMoeda, getLatLngFromCEP, formatCepInput, findPessoalInformation } from '../../helpers/Utils';
import { Checkbox, FormHelperText, InputAdornment, Select, TextareaAutosize, TextField } from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faPen, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { UserComponentDefault as UserComponent } from '../negotiation/components/user.component';
import { Filter } from 'glin-profanity';


class AnnouncementsEdit extends Component {

	state = {
		loading: false,
		submitted: false,
		success: false,
		errors: [],
		activeTab: '1',
		data: null,
		coupon: null,
		oldParticipants: null,
		addParticipantArray: [],
		comissionamento: false,
		tabChanged: [],
		initialImages: null
	}

	componentDidMount = async () => {
		this.setState({ loading: true });

		await getPropertiesById(this.props.match.params.id).catch(err => {
			alert('An error occurred, please contact the support.');
		}).then(res => {
			if (res?.status == 200) {
				if (res.data) {
					this.setState({ data: res.data.data, comissionamento: res.data.data.comissioning, initialImages: res.data.data?.images });
				}
			}
		});

		await getAppliedByAnnouncement(this.props.match.params.id).catch(err => {
			alert('An error occurred, please contact the support.');
		}).then(res => {
			if (res?.status == 200) {
				if (res.data) {
					this.setState({ coupon: res.data });
				}
			}
		});

		await getComissioningParticipants({ announcement_id: this.props.match.params.id, $sort: { 'user_role': 'admin' } }).catch(err => {
			console.log(err);
		}).then(res => {
			if (res?.status == 200) {
				if (res.data.data) {
					this.setState({ oldParticipants: res.data.data });
					for (const participant of res.data.data) {
						this.setState({
							addParticipantArray: [...this.state.addParticipantArray, {
								"id": participant.id,
								"user_id": participant.user_id,
								"user": participant.user,
								"announcement_id": participant.announcement_id,
								"user_role": participant.user_role,
								"additional_values": participant.additional_values,
								"commission_percentage": participant.commission_percentage,
								"commission_value": this.transformPercentageCommissioned(participant.commission_percentage),
								"commission_status": participant.commission_status
							}]
						});
					}
				}
			}
		});

		const tabUrl = new URLSearchParams(this.props.location.search).get("tab")

		if (tabUrl) {
			this.setState({ activeTab: tabUrl });
		}

		this.setState({ loading: false });
	};

	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	};

	verifyValuesComissioned = () => {
		const { addParticipantArray } = this.state;
		const { data } = this.state;

		let error = [];

		if (addParticipantArray.length === 0) {
			this.setState({ comissionamento: false });
			return error;
		}

		let totalFirstValue = 0;
		let totalPercentage = 0;
		let planPercentage = 0;
		let totalRent = data?.values?.base_rent;
		let totalAdmin = 0;
		let totalLandlord = 0;

		planPercentage = this.valorGarantia(data?.warranty?.type);

		if (this.state.coupon) {
			planPercentage = (planPercentage - (Number(this.state.coupon?.discountValue) / 100)).toFixed(2);
		}

		let liquidRent = (totalRent - (totalRent * planPercentage)).toFixed(2);

		addParticipantArray.map((participant, index) => {
			if (participant.commission_percentage < 0 || participant.commission_percentage > 100) {
				error.push(`A porcentagem de comissão do participante ${participant.user.name} é inválida (deve ser entre 0 e 100).`);
			};

			if (parseFloat(participant.additional_values.first_month_value) < 0 || parseFloat(participant.additional_values.first_month_value) > liquidRent) {
				error.push(`O valor do primeiro aluguel do participante ${participant.user.name} é inválido (valor total a ser distríbuído entre os participantes: ${formatBRL(liquidRent)}).`);
			};

			totalFirstValue += parseFloat(participant.additional_values.first_month_value);
			totalPercentage += Number(participant.commission_percentage);

			if (participant.user_role === 'admin') {
				totalAdmin++;
			};

			if (participant.user_role === 'landlord') {
				totalLandlord++;
			};
		});

		if (totalAdmin > 1) {
			error.push(`Só pode haver um administrador.`);
		};

		if (totalLandlord > 1) {
			error.push(`Só pode haver um dono.`);
		};

		if (totalLandlord === 0 && totalAdmin === 0) {
			error.push(`Deve haver pelo menos um Dono ou um Administrador.`);
		};

		if (totalFirstValue > liquidRent) {
			error.push(`O valor total do primeiro aluguel distribuído entre os participantes é maior que o valor líquido do aluguel(${formatBRL(liquidRent)}).`);
		};

		if (totalFirstValue < liquidRent) {
			error.push(`O valor total do primeiro aluguel distribuído entre os participantes é menor que o valor líquido do aluguel (${formatBRL(liquidRent)}).`);
		};

		if (totalPercentage > 100) {
			error.push(`A porcentagem total não pode ser maior que 100%`);
		};

		if (totalPercentage < 100) {
			error.push(`A porcentagem total não pode ser menor que 100%`);
		};

		return error;
	}

	onSubmit = async (values) => {
		this.setState(({ submitted: true }));

		if (this.state.addParticipantArray?.length > 0) {
			this.setState({ comissionamento: true });
		} else {
			this.setState({ comissionamento: false });
		}

		let data = { ...this.state.data };
		data.is_rented = values.is_rented;
		data.comissioning = this.state.comissionamento;
		data.is_published = values.is_published;
		data.reference_point = values.reference_point;
		delete data.expiration;

		data.condominium_details = {
			barbecue: values.cd_barbecue,
			building_laundry: values.cd_building_laundry,
			elevator: values.cd_elevator,
			gourmet_area: values.cd_gourmet_area,
			gym: values.cd_gym,
			party_hall: values.cd_party_hall,
			playground: values.cd_playground,
			pool: values.cd_pool,
			sauna: values.cd_sauna,
			sports_court: values.cd_sports_court,
		};
		data.details = {
			air_conditioning: values.det_air_conditioning,
			barbecue: values.det_barbecue,
			bathroom_cabinet: values.det_bathroom_cabinet,
			decorated: values.det_decorated,
			extra_reversible_room: values.det_extra_reversible_room,
			fridge: values.det_fridge,
			garden: values.det_garden,
			gas_shower: values.det_gas_shower,
			glass_shower_box: values.det_glass_shower_box,
			gourmet_balcony: values.det_gourmet_balcony,
			hidro: values.det_hidro,
			kitchen_cabinet: values.det_kitchen_cabinet,
			penthouse: values.det_penthouse,
			pet_friendly: values.det_pet_friendly,
			pool: values.det_pool,
			room_cabinets: values.det_room_cabinets,
			service_bathroom: values.det_service_bathroom,
			service_room: values.det_service_room,
			sofa: values.det_sofa,
			stove: values.det_stove,
			washing_machine: values.det_washing_machine,
			electric_shower: values.det_electric_shower,
			bed: values.det_bed
		};
		data.tenant_pays = {
			condo: values.tp_condo,
			iptu: values.tp_iptu,
			other: values.tp_other,
		};

		data.description = values.description;

		if (values.war_security_deposit_months || values.war_type) {
			data.warranty = {};

			values.war_security_deposit_months ? data.warranty.security_deposit_months = values.war_security_deposit_months : delete data.warranty.war_security_deposit_months;
			values.war_type ? data.warranty.type = values.war_type : delete data.warranty.war_type;
		} else {
			delete data.warranty;
		}

		if (values.val_condo || values.val_iptu || values.val_rent) {
			data.values = {};

			values.val_condo ? data.values.condo = limparMoeda(values.val_condo) : delete data.values.val_condo;
			values.val_iptu ? data.values.iptu = limparMoeda(values.val_iptu) : delete data.values.val_iptu;
			values.val_rent ? data.values.base_rent = limparMoeda(values.val_rent) : delete data.values.val_rent;
		} else {
			delete data.values;
		}

		if (values.ad_city || values.ad_neighbourhood || values.ad_number || values.ad_state ||
			values.ad_street || values.ad_zip_code || values.ad_complement) {
			data.address = {};

			values.ad_city ? data.address.city = values.ad_city : delete data.address.city;
			values.ad_neighbourhood ? data.address.neighbourhood = values.ad_neighbourhood : delete data.address.neighbourhood;
			values.ad_number ? data.address.number = values.ad_number : delete data.address.number;
			values.ad_complement ? data.address.complement = values.ad_complement : delete data.address.complement;
			values.ad_state ? data.address.state = values.ad_state : delete data.address.state;
			values.ad_street ? data.address.street = values.ad_street : delete data.address.street;
			values.ad_zip_code ? data.address.zip_code = values.ad_zip_code : delete data.address.zip_code;
		} else {
			delete data.address;
		}

		if (values.inf_area || values.inf_bathrooms || values.inf_car_slots || values.inf_rooms || values.inf_type) {
			data.info = {};

			values.inf_area ? data.info.area = values.inf_area : delete data.info.area;
			values.inf_bathrooms ? data.info.bathrooms = values.inf_bathrooms : delete data.info.bathrooms;
			values.inf_car_slots ? data.info.car_slots = values.inf_car_slots : delete data.info.car_slots;
			values.inf_rooms ? data.info.rooms = values.inf_rooms : delete data.info.rooms;
			values.inf_type ? data.info.type = values.inf_type : delete data.info.type;
		} else {
			delete data.info;
		}

		if (values.loc_latitude || values.loc_longitude) {
			data.location = {};

			values.loc_latitude ? data.location.latitude = values.loc_latitude : delete data.location.latitude;
			values.loc_longitude ? data.location.longitude = values.loc_longitude : delete data.location.longitude;
		} else {
			delete data.location;
		}

		if (values.ld_latitude || values.ld_longitude) {
			data.location_deltas = {};

			values.ld_latitude ? data.location_deltas.latitude = values.ld_latitude : delete data.location_deltas.latitude;
			values.ld_longitude ? data.location_deltas.longitude = values.ld_longitude : delete data.location_deltas.longitude;
		} else {
			delete data.location_deltas;
		}

		if (this.verifyValuesComissioned().length > 0) {
			this.setState({ errors: this.verifyValuesComissioned(), submitted: false });
			window.scrollTo(0, 0);
			return;
		}

		await this.onSubmitComissionado(values)

		await updateAnnouncement(this.props.match.params.id, data).catch(err => {
			this.setState({ submitted: false, success: false, errors: err.response.data.error ? [err.response.data.error] : [] });
		}).then(res => {
			if (res?.status == 200) {
				this.setState({ submitted: false, success: !Boolean(res.data.error), errors: res.data.error ? [res.data.error] : [], loading: false });
			}
		});
	};

	onSubmitComissionado = async (values) => {
		this.setState({ loading: true });

		if (!this.state.comissionamento) {
			return;
		} else {
			let { addParticipantArray } = this.state;
			addParticipantArray.map(async (participant, index) => {
				if (participant.id) {
					await updateCommissioningParticipants(participant.id, participant).catch((err) => { console.error(err) });
				} else {
					await createCommissioningParticipants([participant]).catch((err) => { console.error(err) });
				}
			});
		}
	};

	getInitialValues = () => {
		return {
			is_rented: this.state.data?.is_rented ? this.state.data?.is_rented : false,
			is_published: this.state.data?.is_published ? this.state.data?.is_published : false,
			reference_point: this.state.data?.reference_point ? this.state.data?.reference_point : '',
			ad_city: this.state.data?.address?.city ? this.state.data?.address?.city : false,
			ad_neighbourhood: this.state.data?.address?.neighbourhood ? this.state.data?.address?.neighbourhood : false,
			ad_number: this.state.data?.address?.number ? this.state.data?.address?.number : false,
			ad_complement: this.state.data?.address?.complement ? this.state.data?.address?.complement : '',
			ad_state: this.state.data?.address?.state ? this.state.data?.address?.state : false,
			ad_street: this.state.data?.address?.street ? this.state.data?.address?.street : false,
			ad_zip_code: this.state.data?.address?.zip_code ? formatCep(this.state.data?.address?.zip_code) : false,
			cd_barbecue: this.state.data?.condominium_details?.barbecue ? this.state.data?.condominium_details?.barbecue : false,
			cd_building_laundry: this.state.data?.condominium_details?.building_laundry ? this.state.data?.condominium_details?.building_laundry : false,
			cd_elevator: this.state.data?.condominium_details?.elevator ? this.state.data?.condominium_details?.elevator : false,
			cd_gourmet_area: this.state.data?.condominium_details?.gourmet_area ? this.state.data?.condominium_details?.gourmet_area : false,
			cd_gym: this.state.data?.condominium_details?.gym ? this.state.data?.condominium_details?.gym : false,
			cd_party_hall: this.state.data?.condominium_details?.party_hall ? this.state.data?.condominium_details?.party_hall : false,
			cd_playground: this.state.data?.condominium_details?.playground ? this.state.data?.condominium_details?.playground : false,
			cd_pool: this.state.data?.condominium_details?.pool ? this.state.data?.condominium_details?.pool : false,
			cd_sauna: this.state.data?.condominium_details?.sauna ? this.state.data?.condominium_details?.sauna : false,
			cd_sports_court: this.state.data?.condominium_details?.sports_court ? this.state.data?.condominium_details?.sports_court : false,

			det_air_conditioning: this.state.data?.details?.air_conditioning ? this.state.data?.details?.air_conditioning : false,
			det_barbecue: this.state.data?.details?.barbecue ? this.state.data?.details?.barbecue : false,
			det_bathroom_cabinet: this.state.data?.details?.bathroom_cabinet ? this.state.data?.details?.bathroom_cabinet : false,
			det_decorated: this.state.data?.details?.decorated ? this.state.data?.details?.decorated : false,
			det_extra_reversible_room: this.state.data?.details?.extra_reversible_room ? this.state.data?.details?.extra_reversible_room : false,
			det_fridge: this.state.data?.details?.fridge ? this.state.data?.details?.fridge : false,
			det_garden: this.state.data?.details?.garden ? this.state.data?.details?.garden : false,
			det_gas_shower: this.state.data?.details?.gas_shower ? this.state.data?.details?.gas_shower : false,
			det_glass_shower_box: this.state.data?.details?.glass_shower_box ? this.state.data?.details?.glass_shower_box : false,
			det_gourmet_balcony: this.state.data?.details?.gourmet_balcony ? this.state.data?.details?.gourmet_balcony : false,
			det_hidro: this.state.data?.details?.hidro ? this.state.data?.details?.hidro : false,
			det_kitchen_cabinet: this.state.data?.details?.kitchen_cabinet ? this.state.data?.details?.kitchen_cabinet : false,
			det_penthouse: this.state.data?.details?.penthouse ? this.state.data?.details?.penthouse : false,
			det_pet_friendly: this.state.data?.details?.pet_friendly ? this.state.data?.details?.pet_friendly : false,
			det_pool: this.state.data?.details?.pool ? this.state.data?.details?.pool : false,
			det_room_cabinets: this.state.data?.details?.room_cabinets ? this.state.data?.details?.room_cabinets : false,
			det_service_bathroom: this.state.data?.details?.service_bathroom ? this.state.data?.details?.service_bathroom : false,
			det_service_room: this.state.data?.details?.service_room ? this.state.data?.details?.service_room : false,
			det_sofa: this.state.data?.details?.sofa ? this.state.data?.details?.sofa : false,
			det_stove: this.state.data?.details?.stove ? this.state.data?.details?.stove : false,
			det_washing_machine: this.state.data?.details?.washing_machine ? this.state.data?.details?.washing_machine : false,
			det_electric_shower: this.state.data?.details?.electric_shower ? this.state.data?.details?.electric_shower : false,
			det_bed: this.state.data?.details?.bed ? this.state.data?.details?.bed : false,

			inf_area: this.state.data?.info?.area ? this.state.data?.info?.area : '',
			inf_bathrooms: this.state.data?.info?.bathrooms ? this.state.data?.info?.bathrooms : false,
			inf_car_slots: this.state.data?.info?.car_slots ? this.state.data?.info?.car_slots : 0,
			inf_rooms: this.state.data?.info?.rooms ? this.state.data?.info?.rooms : false,
			inf_type: this.state.data?.info?.type ? this.state.data?.info?.type : false,
			tp_condo: this.state.data?.tenant_pays?.condo ? this.state.data?.tenant_pays?.condo : false,
			tp_iptu: this.state.data?.tenant_pays?.iptu ? this.state.data?.tenant_pays?.iptu : false,
			tp_other: this.state.data?.tenant_pays?.other ? this.state.data?.tenant_pays?.other : false,
			val_condo: formatBRL(this.state.data?.values?.condo ? this.state.data?.values?.condo : 0),
			val_iptu: formatBRL(this.state.data?.values?.iptu ? this.state.data?.values?.iptu : 0),
			val_rent: formatBRL(this.state.data?.values?.rent ? this.state.data?.values?.rent : 0),
			war_security_deposit_months: this.state.data?.warranty?.security_deposit_months ? this.state.data?.warranty?.security_deposit_months : 0,
			war_type: this.state.data?.warranty?.type ? this.state.data?.warranty?.type : false,
			loc_latitude: this.state.data?.location?.latitude ? this.state.data?.location?.latitude : '',
			loc_longitude: this.state.data?.location?.longitude ? this.state.data?.location?.longitude : '',
			ld_latitude: this.state.data?.location_deltas?.latitude ? this.state.data?.location_deltas?.latitude : '',
			ld_longitude: this.state.data?.location_deltas?.longitude ? this.state.data?.location_deltas?.longitude : '',
			images: this.state.data?.property?.images ? this.state.data?.property?.images : '',

			description: this.state.data?.description ? this.state.data?.description : '',
		};
	};

	handleZipCodeChange = (e, setFieldValue) => {
		if (e.target.value.length > 8) {
			getLatLngFromCEP(e.target.value, (coords) => {
				setFieldValue('loc_latitude', coords.lat);
				setFieldValue('loc_longitude', coords.lng);
			});
		}

		return formatCepInput(e);
	};

	onAnnouncement = (obj) => {
		const is_published = !obj.is_published;

		const data = {
			// ...obj,
			is_published
		};

		updateAnnouncement(this.props.match.params.id, data).catch(err => {
			this.setState({ submitted: false, success: false, errors: err.response.data.error ? [err.response.data.error] : [] });
		}).then(res => {
			if (res?.status == 200) {
				this.setState({ success: true });
			}
		});
	};

	onAddPhoto = (imagem) => {
		const imageForm = new FormData();
		imageForm.set("image", imagem);

		postImage(this.props.match.params.id, imageForm).catch(err => {
			this.setState({ submitted: false, success: false, errors: err.response.data.error ? [err.response.data.error] : [] });
		}).then(res => {
			if (res?.status == 200) {
				this.setState({ data: res.data.data })
			}
		});
	};

	onDeleteImage = (imageId) => {
		deleteImage(this.props.match.params.id, imageId).catch(err => {
			this.setState({ submitted: false, success: false, errors: err.response.data.error ? [err.response.data.error] : [] });
		}).then(res => {
			if (res?.status == 200) {
				this.setState({ data: res.data.data })
			}
		});
	};

	getDetalhesImovelType = () => {
		return {
			externa: [
				{ key: "det_penthouse", label: "Apartamento com cobertura" },
				{ key: "det_barbecue", label: "Churrasqueira" },
				{ key: "det_garden", label: "Jardim" },
				{ key: "det_pool", label: "Piscina" },
				{ key: "det_gourmet_balcony", label: "Varanda gourmet" },
			],
			cozinha: [
				{ key: "det_kitchen_cabinet", label: "Armários de cozinha" },
				{ key: "det_fridge", label: "Geladeira" },
				{ key: "det_stove", label: "Fogão" },
			],
			banheiro: [
				{ key: "det_bathroom_cabinet", label: "Armários de banheiro" },
				{ key: "det_glass_shower_box", label: "Box de vidro" },
				{ key: "det_gas_shower", label: "Chuveiro a gás" },
				{ key: "det_electric_shower", label: "Chuveiro elétrico" },
				{ key: "det_hidro", label: "Hidromassagem" },
			],
			servico: [
				{ key: "det_service_bathroom", label: "Banheiro de serviço" },
				{ key: "det_service_room", label: "Quarto de serviço" },
				{ key: "det_washing_machine", label: "Máquina de lavar" },
			],
			mobilia: [
				{ key: "det_room_cabinets", label: "Armário nos quartos" },
				{ key: "det_bed", label: "Cama" },
				{ key: "det_decorated", label: "Mobiliado" },
				{ key: "det_sofa", label: "Sofá" },
			],
			outros: [
				{ key: "det_air_conditioning", label: "Ar condicionado" },
				{ key: "det_extra_reversible_room", label: "Quarto reversível", },
			],
		}
	};

	getDetalhesCondominioType = () => {
		return {
			esq: [
				{ key: "cd_gym", label: "Academia" },
				{ key: "cd_barbecue", label: "Churrasqueira" },
				{ key: "cd_elevator", label: "Elevador" },
				{ key: "cd_gourmet_area", label: "Espaço gourmet" },
			],
			cent: [
				{ key: "cd_building_laundry", label: "Lavanderia" },
				{ key: "cd_pool", label: "Piscina" },
				{ key: "cd_sports_court", label: "Quadra de esportes" },
				{ key: "cd_playground", label: "Playground" },
			],
			dir: [
				{ key: "cd_party_hall", label: "Salão de festas" },
				{ key: "cd_sauna", label: "Sauna" },
			]
		}
	};

	getConviteStatus = (user) => {
		if (user.user_type === 'admin') {
			return null;
		} else if (user.commission_status === 'AWAITING_APPROVAL') {
			return (
				<span style={{ color: `#FCB92C` }}>Aguardando resposta</span>
			)
		} else if (user.commission_status === 'APPROVED') {
			return (
				<span style={{ color: `#1CBB8C` }}>Convite aceito</span>
			)
		} else if (user.commission_status === 'REFUSED') {
			return (
				<span style={{ color: `#ff3d60` }}>Convite recusado</span>
			)
		} else {
			return (
				<span style={{ color: `#ccc` }}>Aguardando resposta</span>
			)
		}
	}

	valorGarantia = (tipoGarantia) => {
		switch (tipoGarantia) {
			case 'WARRANTY':
				return 0.07;
			case 'WARRANTY_GUARANTEE_DAMAGE':
				return 0.1;
			default:
				return 0.1;
		}
	};

	handleChangePrimeiroAluguelValue = (e, participant,) => {
		const { addParticipantArray } = this.state;
		const value = parseFloat(e.target.value.replace(",", "."));
		let receiptFirstValue = false;

		if (value > 0) {
			receiptFirstValue = true;
		} else {
			receiptFirstValue = false;
		}

		let index = this.state.addParticipantArray.findIndex(obj => obj.user_id === participant.user_id);

		let updatedObject = {
			...participant,
			additional_values: {
				first_month_payment: receiptFirstValue,
				first_month_value: e.target.value
			}
		};

		addParticipantArray[index] = updatedObject;
		this.setState({ addParticipantArray });
	}

	handleSetFunction = (e, participant) => {
		const { addParticipantArray } = this.state;

		let index = this.state.addParticipantArray.findIndex(obj => obj.user_id === participant.user_id);
		let updatedObject = { ...participant, user_role: e.target.value };
		addParticipantArray[index] = updatedObject;
		this.setState({ addParticipantArray });
	}

	handlePercentageChange = (e, participant) => {
		const { addParticipantArray } = this.state;
		let value = e.target.value.replace(",", ".");

		let liquidRent = this.state.data?.values?.rent;
		let percentage = 0;

		percentage = this.valorGarantia(this.state.data?.warranty?.type);

		if (this.state.coupon) {
			percentage = (percentage - (Number(this.state.coupon.discountValue) / 100)).toFixed(2);
		}

		liquidRent = (liquidRent - (liquidRent * percentage)).toFixed(2);

		const commissionPercentage = (value / liquidRent) * 100;

		let index = this.state.addParticipantArray.findIndex(obj => obj.user_id === participant.user_id);
		let updatedObject = {
			...participant,
			commission_percentage: commissionPercentage,
			commission_value: value
		};
		addParticipantArray[index] = updatedObject;
		this.setState({ addParticipantArray });
	}

	transformPercentageCommissioned = (commission_percentage) => {
		let liquidRent = this.state.data?.values?.rent;
		let percentage = this.valorGarantia(this.state.data?.warranty?.type);

		if (this.state.coupon) {
			percentage = (percentage - (Number(this.state.coupon.discountValue) / 100)).toFixed(2);
		}

		liquidRent = (liquidRent - (liquidRent * percentage));

		return parseFloat((commission_percentage / 100) * liquidRent).toFixed(2);
	}

	removeParticipant = async (participant) => {
		const { addParticipantArray } = this.state;
		const index = addParticipantArray.findIndex(commissioned => commissioned.user_id === participant.user_id);

		if (participant.id) {
			await removeCommissioningParticipants(participant.id)
				.catch((err) => {
					console.error(err)
					this.setState({ errors: err.response.data.error ? "Houve um erro ao deletar o usuário" : [] });
					return;
				});
		}

		if (index !== -1) {
			addParticipantArray.splice(index, 1);
			this.setState({ addParticipantArray });
		} else {
			let lastIndex = addParticipantArray.length - 1;
			addParticipantArray.splice(lastIndex, 1);
			this.setState({ addParticipantArray });
		}
	}

	addParticipant = () => {
		const defaultObj = {
			"id": null,
			"user_id": null,
			"user": {
				"id": null,
				"name": null,
			},
			"announcement_id": this.props.match.params.id,
			"user_role": "commissioned",
			"additional_values": {
				"first_month_payment": false,
				"first_month_value": 0
			},
			"commission_percentage": 0,
			"commission_value": 0,
			"commission_status": "AWAITING_APPROVAL"
		}
		const prevArr = [...this.state.addParticipantArray];
		prevArr.push(defaultObj);
		this.setState({ addParticipantArray: prevArr });
	}

	render() {
		const filter = new Filter({
			wordBoundaries: true,
			languages: ['portuguese'],
			caseSensitive: true
		});

		return (
			<BetterCircularProgress loading={this.state.loading}>
				{this.state.data ?
					<div className="page-content container-page userNew container-fluid anuncio">
						<BetterAlerts errors={this.state.errors} />

						{this.state.success ?
							<Redirect to={{ pathname: `/announcements/show/${this.props.match.params.id}`, state: { success: ['Imóvel atualizado com sucesso!'] } }} />
							: null
						}

						<Formik initialValues={this.getInitialValues()} validate={values => { }} onSubmit={(values) => this.onSubmit(values)}>
							{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
								<form noValidate={true} autoComplete="off" onSubmit={handleSubmit} className="form" style={{ flexDirection: 'column', alignItems: 'center' }}>
									<div style={{ width: '736px' }}>
										<div className='header'>
											<h1>Editar imóvel</h1>

											<Breadcrumbs breadcrumbItems={[{ link: '/announcements/index', title: 'Imóveis' }, { link: `#`, title: `ID ${this.props.match.params.id}` }]} />
										</div>

										<div className='box'>
											<Nav tabs className="nav-tabs-custom" role="tablist" style={{ marginBottom: 24 }}>
												<NavItem>
													<NavLink id="announcements-tab" className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggleTab('1'); }}>
														<div className='tabName'>
															Endereço

															{this.state.tabChanged.includes('address') ? (
																<div className='indicadorEdit'>
																	<FontAwesomeIcon icon={faPen} />
																</div>
															) : null}
														</div>
													</NavLink>
												</NavItem>

												<NavItem>
													<NavLink id="negotiations-tab" className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggleTab('2'); }}>
														<div className='tabName'>
															Informações

															{this.state.tabChanged.includes('infos') ? (
																<div className='indicadorEdit'>
																	<FontAwesomeIcon icon={faPen} />
																</div>
															) : null}
														</div>
													</NavLink>
												</NavItem>

												<NavItem>
													<NavLink id="negotiations-tab" className={classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.toggleTab('3'); }}>
														<div className='tabName'>
															Detalhes

															{this.state.tabChanged.includes('details') ? (
																<div className='indicadorEdit'>
																	<FontAwesomeIcon icon={faPen} />
																</div>
															) : null}
														</div>
													</NavLink>
												</NavItem>

												<NavItem>
													<NavLink id="negotiations-tab" className={classnames({ active: this.state.activeTab === '7' })} onClick={() => { this.toggleTab('7'); }}>
														<div className='tabName'>
															Descrição

															{this.state.tabChanged.includes('description') ? (
																<div className='indicadorEdit'>
																	<FontAwesomeIcon icon={faPen} />
																</div>
															) : null}
														</div>
													</NavLink>
												</NavItem>

												<NavItem>
													<NavLink id="negotiations-tab" className={classnames({ active: this.state.activeTab === '4' })} onClick={() => { this.toggleTab('4'); }}>
														<div className='tabName'>
															Fotos

															{this.state?.data?.images !== this.state.initialImages ? (
																<div className='indicadorEdit'>
																	<FontAwesomeIcon icon={faPen} />
																</div>
															) : null}
														</div>
													</NavLink>
												</NavItem>

												<NavItem>
													<NavLink id="negotiations-tab" className={classnames({ active: this.state.activeTab === '5' })} onClick={() => { this.toggleTab('5'); }}>
														<div className='tabName'>
															Valores

															{this.state.tabChanged.includes('values') ? (
																<div className='indicadorEdit'>
																	<FontAwesomeIcon icon={faPen} />
																</div>
															) : null}
														</div>
													</NavLink>
												</NavItem>

												{!this.state.data?.is_rented &&
													<NavItem>
														<NavLink id="negotiations-tab" className={classnames({ active: this.state.activeTab === '6' })} onClick={() => { this.toggleTab('6'); }}>
															<div className='tabName'>
																Comissões

																{this.state.oldParticipants.length !== this.state.addParticipantArray.length ? (
																	<div className='indicadorEdit'>
																		<FontAwesomeIcon icon={faPen} />
																	</div>
																) : null}
															</div>
														</NavLink>
													</NavItem>
												}
											</Nav>

											<TabContent activeTab={this.state.activeTab}>
												<TabPane tabId="1" role="tabpanel">
													<div className='formulario'>
														<Col lg={12} className="pl-0 marginInputMobileRua">
															<Label>Endereço</Label>

															<TextField name="ad_street" placeholder='Nome da rua' type="text" variant="outlined" value={values.ad_street} onBlur={handleBlur} onChange={(e) => {
																handleChange(e);
																const arr = [...this.state.tabChanged]
																arr.push('address')
																this.setState({ tabChanged: arr })
															}}
																helperText={touched.ad_street && errors.ad_street} error={Boolean(touched.ad_street && errors.ad_street)} />
														</Col>

														<Row className="p-0 m-0">
															<Col lg={6} className="p-0 m-0">
																<Label>CEP</Label>

																<TextField name="ad_zip_code" placeholder='88888-888' type="text" variant="outlined" value={values.ad_zip_code} onBlur={handleBlur} onChange={(e) => handleChange(buscaCep(e, setFieldValue, "ad_street", "ad_neighbourhood", "ad_city", "ad_state"))}
																	helperText={touched.ad_zip_code && errors.ad_zip_code} error={Boolean(touched.ad_zip_code && errors.ad_zip_code)} inputProps={{ maxLength: 9 }} />
															</Col>

															<Col lg={6} className="marginInputMobile">
																<Label>Estado</Label>

																<BetterSelect name="ad_state" blankOption value={values.ad_state} onBlur={handleBlur} onChange={(e) => {
																	handleChange(e);
																	const arr = [...this.state.tabChanged]
																	arr.push('address')
																	this.setState({ tabChanged: arr })
																}}
																	helperText={touched.ad_state && errors.ad_state} error={Boolean(touched.ad_state && errors.ad_state)} listItem={getStates()}>
																</BetterSelect>
															</Col>
														</Row>

														<Row className="p-0 m-0">
															<Col lg={3} md={12} className="p-0 m-0">
																<Label>Número</Label>

																<TextField name="ad_number" type="text" placeholder='00000' variant="outlined" value={values.ad_number} onBlur={handleBlur} onChange={(e) => {
																	handleChange(e);
																	const arr = [...this.state.tabChanged]
																	arr.push('address')
																	this.setState({ tabChanged: arr })
																}}
																	helperText={touched.ad_number && errors.ad_number} error={Boolean(touched.ad_number && errors.ad_number)} />
															</Col>

															<Col lg={9} md={12} className="marginInputMobile">
																<Label>Complemento</Label>

																<TextField name="ad_complement" placeholder='Opcional' type="text" variant="outlined" value={values.ad_complement} onBlur={handleBlur} onChange={(e) => {
																	handleChange(e);
																	const arr = [...this.state.tabChanged]
																	arr.push('address')
																	this.setState({ tabChanged: arr })
																}}
																	helperText={touched.ad_complement && errors.ad_complement} error={Boolean(touched.ad_complement && errors.ad_complement)} />
															</Col>
														</Row>

														<Row className="p-0 m-0">
															<Col lg={6} md={12} className="p-0 m-0">
																<Label>Bairro</Label>

																<TextField name="ad_neighbourhood" placeholder='Nome do bairro' type="text" variant="outlined" value={values.ad_neighbourhood} onBlur={handleBlur} onChange={(e) => {
																	handleChange(e);
																	const arr = [...this.state.tabChanged]
																	arr.push('address')
																	this.setState({ tabChanged: arr })
																}}
																	helperText={touched.ad_neighbourhood && errors.ad_neighbourhood} error={Boolean(touched.ad_neighbourhood && errors.ad_neighbourhood)} />
															</Col>

															<Col lg={6} md={12} className="marginInputMobile">
																<Label>Cidade</Label>

																<TextField name="ad_city" placeholder='Nome da cidade' type="text" variant="outlined" value={values.ad_city} onBlur={handleBlur} onChange={(e) => {
																	handleChange(e);
																	const arr = [...this.state.tabChanged]
																	arr.push('address')
																	this.setState({ tabChanged: arr })
																}}
																	helperText={touched.ad_city && errors.ad_city} error={Boolean(touched.ad_city && errors.ad_city)} />
															</Col>
														</Row>
													</div>
												</TabPane>

												<TabPane tabId="2" role="tabpanel">
													<div className='formulario'>
														<Row className="p-0 m-0">
															<Col lg={6} className="p-0 m-0">
																<Label>Area (m²)</Label>

																<TextField
																	name="inf_area"
																	type="text"
																	variant="outlined"
																	value={values.inf_area}
																	onBlur={handleBlur}
																	onChange={(e) => {
																		const value = e.target.value;
																		// Validação para área mínima de 10m²
																		if (value && parseInt(value) < 10) {
																			errors.inf_area = "Deve ser igual ou maior do que 10m²";
																		} else {
																			delete errors.inf_area;
																		}
																		handleChange(e);
																		const arr = [...this.state.tabChanged]
																		arr.push('infos')
																		this.setState({ tabChanged: arr })
																	}}
																	helperText={touched.inf_area && errors.inf_area}
																	error={Boolean(touched.inf_area && errors.inf_area)}
																	style={{ background: '#fff' }}
																/>
															</Col>

															<Col lg={6} className="marginInputMobile">
																<Label>Tipo de imóvel</Label>

																<BetterSelect name="inf_type" blankOption value={values.inf_type} onBlur={handleBlur} onChange={(e) => {
																	handleChange(e);
																	const arr = [...this.state.tabChanged]
																	arr.push('infos')
																	this.setState({ tabChanged: arr })
																}}
																	helperText={touched.inf_type && errors.inf_type} error={Boolean(touched.inf_type && errors.inf_type)}>
																	<option value="house">Casa</option>
																	<option value="apartment">Apartamento</option>
																	<option value="studio">Studio</option>
																	<option value="condo_house">Casa em condomínio</option>
																</BetterSelect>
															</Col>
														</Row>

														<Row className="p-0 m-0">
															<Col lg={4} className="p-0 m-0">
																<Label>Quartos</Label>

																<TextField name="inf_rooms" type="text" variant="outlined" value={values.inf_rooms} onBlur={handleBlur} onChange={(e) => {
																	handleChange(e);
																	const arr = [...this.state.tabChanged]
																	arr.push('infos')
																	this.setState({ tabChanged: arr })
																}}
																	helperText={touched.inf_rooms && errors.inf_rooms} error={Boolean(touched.inf_rooms && errors.inf_rooms)}
																	style={{ background: '#fff' }} />
															</Col>

															<Col lg={4} className="marginInputMobile">
																<Label>Banheiros</Label>

																<TextField name="inf_bathrooms" type="text" variant="outlined" value={values.inf_bathrooms} onBlur={handleBlur} onChange={(e) => {
																	handleChange(e);
																	const arr = [...this.state.tabChanged]
																	arr.push('infos')
																	this.setState({ tabChanged: arr })
																}}
																	helperText={touched.inf_bathrooms && errors.inf_bathrooms} error={Boolean(touched.inf_bathrooms && errors.inf_bathrooms)}
																	style={{ background: '#fff' }} />
															</Col>

															<Col lg={4} className="marginInputMobile">
																<Label>Garagens</Label>

																<TextField name="inf_car_slots" type="text" variant="outlined" value={values.inf_car_slots} onBlur={handleBlur} onChange={(e) => {
																	handleChange(e);
																	const arr = [...this.state.tabChanged]
																	arr.push('infos')
																	this.setState({ tabChanged: arr })
																}}
																	helperText={touched.inf_car_slots && errors.inf_car_slots} error={Boolean(touched.inf_car_slots && errors.inf_car_slots)}
																	style={{ background: '#fff' }} />
															</Col>
														</Row>
														<FormGroup className="mb-0 checkBox-admin">
															<Checkbox name="det_pet_friendly" id='det_pet_friendly-check' color="primary" checked={values.det_pet_friendly == 'true'} onBlur={handleBlur}
																onChange={(e) => {
																	values.det_pet_friendly == 'true' ? setFieldValue('det_pet_friendly', 'false') : setFieldValue('det_pet_friendly', 'true')
																	const arr = [...this.state.tabChanged]
																	arr.push('infos')
																	this.setState({ tabChanged: arr })
																}}
															/>
															<Label htmlFor='det_pet_friendly-check'>Permite animais</Label>
														</FormGroup>
													</div>
												</TabPane>

												<TabPane tabId="3" role="tabpanel">
													<div className='formulario formularioDetalhes'>
														<Row className="p-0 m-0">
															<Col lg={4} className="p-0 m-0">
																<Label className="titleLabel">ÁREA EXTERNA</Label>

																{this.getDetalhesImovelType().externa?.map((element, index) => (
																	<div key={index} className='d-flex w-100' style={{ alignItems: 'center' }} >
																		<Checkbox name={element?.key} id={element?.key} color="primary" checked={values?.[element?.key]} onBlur={handleBlur}
																			onChange={(e) => {
																				handleChange(e)
																				const arr = [...this.state.tabChanged]
																				arr.push('details')
																				this.setState({ tabChanged: arr })
																			}} />
																		<Label className='mb-0 labelBody' style={{ padding: "3px 3px 0 3px" }} htmlFor={element?.key}>{element?.label}</Label>
																	</div>
																))}
															</Col>

															<Col lg={4} className="marginInputMobile">
																<Label className="titleLabel">COZINHA</Label>

																{this.getDetalhesImovelType().cozinha?.map((element, index) => (
																	<div key={index} className='d-flex w-100' style={{ alignItems: 'center' }} >
																		<Checkbox name={element?.key} id={element?.key} color="primary" checked={values?.[element?.key]} onBlur={handleBlur} onChange={(e) => {
																			handleChange(e)
																			const arr = [...this.state.tabChanged]
																			arr.push('details')
																			this.setState({ tabChanged: arr })
																		}} />
																		<Label className='mb-0 labelBody' style={{ padding: "3px 3px 0 3px" }} htmlFor={element?.key}>{element?.label}</Label>
																	</div>
																))}
															</Col>

															<Col lg={4} className="marginInputMobile">
																<Label className="titleLabel">BANHEIRO</Label>

																{this.getDetalhesImovelType().banheiro?.map((element, index) => (
																	<div key={index} className='d-flex w-100' style={{ alignItems: 'center' }} >
																		<Checkbox name={element?.key} id={element?.key} color="primary" checked={values?.[element?.key]} onBlur={handleBlur} onChange={(e) => {
																			handleChange(e)
																			const arr = [...this.state.tabChanged]
																			arr.push('details')
																			this.setState({ tabChanged: arr })
																		}} />
																		<Label className='mb-0 labelBody' style={{ padding: "3px 3px 0 3px" }} htmlFor={element?.key}>{element?.label}</Label>
																	</div>
																))}
															</Col>
														</Row>

														<Row className="p-0 m-0">
															<Col lg={4} className="p-0 m-0">
																<Label className="titleLabel">SERVIÇO</Label>

																{this.getDetalhesImovelType().servico?.map((element, index) => (
																	<div key={index} className='d-flex w-100' style={{ alignItems: 'center' }} >
																		<Checkbox name={element?.key} id={element?.key} color="primary" checked={values?.[element?.key]} onBlur={handleBlur} onChange={(e) => {
																			handleChange(e)
																			const arr = [...this.state.tabChanged]
																			arr.push('details')
																			this.setState({ tabChanged: arr })
																		}} />
																		<Label className='mb-0 labelBody' style={{ padding: "3px 3px 0 3px" }} htmlFor={element?.key}>{element?.label}</Label>
																	</div>
																))}
															</Col>

															<Col lg={4} className="marginInputMobile">
																<Label className="titleLabel">MOBÍLIA</Label>

																{this.getDetalhesImovelType().mobilia?.map((element, index) => (
																	<div key={index} className='d-flex w-100' style={{ alignItems: 'center' }} >
																		<Checkbox name={element?.key} id={element?.key} color="primary" checked={values?.[element?.key]} onBlur={handleBlur} onChange={(e) => {
																			handleChange(e)
																			const arr = [...this.state.tabChanged]
																			arr.push('details')
																			this.setState({ tabChanged: arr })
																		}} />
																		<Label className='mb-0 labelBody' style={{ padding: "3px 3px 0 3px" }} htmlFor={element?.key}>{element?.label}</Label>
																	</div>
																))}
															</Col>

															<Col lg={4} className="marginInputMobile">
																<Label className="titleLabel">OUTROS</Label>

																{this.getDetalhesImovelType().outros?.map((element, index) => (
																	<div key={index} className='d-flex w-100' style={{ alignItems: 'center' }} >
																		<Checkbox name={element?.key} id={element?.key} color="primary" checked={values?.[element?.key]} onBlur={handleBlur} onChange={(e) => {
																			handleChange(e)
																			const arr = [...this.state.tabChanged]
																			arr.push('details')
																			this.setState({ tabChanged: arr })
																		}} />
																		<Label className='mb-0 labelBody' style={{ padding: "3px 3px 0 3px" }} htmlFor={element?.key}>{element?.label}</Label>
																	</div>
																))}
															</Col>
														</Row>

														<div>
															<Label className="titleLabel">CONDOMÍNIO</Label>
															<Row className="p-0 m-0">
																<Col lg={4} className="p-0 m-0">
																	{this.getDetalhesCondominioType().esq?.map((element, index) => (
																		<div key={index} className='d-flex w-100' style={{ alignItems: 'center' }} >
																			<Checkbox name={element?.key} id={element?.key} color="primary" checked={values?.[element?.key]} onBlur={handleBlur} onChange={(e) => {
																				handleChange(e)
																				const arr = [...this.state.tabChanged]
																				arr.push('details')
																				this.setState({ tabChanged: arr })
																			}} />
																			<Label className='mb-0 labelBody' style={{ padding: "3px 3px 0 3px" }} htmlFor={element?.key}>{element?.label}</Label>
																		</div>
																	))}
																</Col>

																<Col lg={4} className="marginInputMobile">
																	{this.getDetalhesCondominioType().cent?.map((element, index) => (
																		<div key={index} className='d-flex w-100' style={{ alignItems: 'center' }} >
																			<Checkbox name={element?.key} id={element?.key} color="primary" checked={values?.[element?.key]} onBlur={handleBlur} onChange={(e) => {
																				handleChange(e)
																				const arr = [...this.state.tabChanged]
																				arr.push('details')
																				this.setState({ tabChanged: arr })
																			}} />
																			<Label className='mb-0 labelBody' style={{ padding: "3px 3px 0 3px" }} htmlFor={element?.key}>{element?.label}</Label>
																		</div>
																	))}
																</Col>

																<Col lg={4} className="marginInputMobile">
																	{this.getDetalhesCondominioType().dir?.map((element, index) => (
																		<div key={index} className='d-flex w-100' style={{ alignItems: 'center' }} >
																			<Checkbox name={element?.key} id={element?.key} color="primary" checked={values?.[element?.key]} onBlur={handleBlur} onChange={(e) => {
																				handleChange(e)
																				const arr = [...this.state.tabChanged]
																				arr.push('details')
																				this.setState({ tabChanged: arr })
																			}} />
																			<Label className='mb-0 labelBody' style={{ padding: "3px 3px 0 3px" }} htmlFor={element?.key}>{element?.label}</Label>
																		</div>
																	))}
																</Col>
															</Row>
														</div>
													</div>
												</TabPane>

												<TabPane tabId="7" role="tabpanel">
													<div className='formulario formularioDetalhes' style={{ gap: 8 }}>

														<Label>Descrição</Label>

														<TextareaAutosize name="description" type="text" style={{ color: '#8590A5', borderColor: '#DADBDD', padding: '7px 14px', resize: 'none' }} color="neutral" placeholder='Descreva o imóvel...' variant="outlined" value={values.description} maxLength={2200} onBlur={handleBlur} onChange={(e) => {
															handleChange(e);
															const arr = [...this.state.tabChanged]
															arr.push('description')
															this.setState({ tabChanged: arr })

															if (filter.checkProfanity(e.target.value).containsProfanity) {
																errors.description = 'Não é permitido termos ofensivos.'
															} else {
																delete errors.description
															}

															if (findPessoalInformation(e.target.value)) {
																errors.description = 'Não é permitido informações pessoais.'
															}
														}}
															helpertext={touched.description && errors.description} error={Boolean(touched.description && errors.description)} />
														{touched.description && errors.description ?
															<FormHelperText style={{ color: '#FF3D60' }}>{errors.description}</FormHelperText>
															: null}
													</div>
												</TabPane>

												<TabPane tabId="4" role="tabpanel">
													<div className='formulario'>
														<label htmlFor={'files'} className='button-primary' style={{ width: 129, height: 38, cursor: 'pointer' }}>
															<input type="file" id={'files'} name={'files'} style={{ display: "none" }} accept="image/*" onChange={(event) => { this.onAddPhoto(event.currentTarget.files[0]) }} />
															NOVA FOTO

															<FontAwesomeIcon icon={faCamera} />
														</label>

														<div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', rowGap: 12, gap: 12 }}>
															{this.state?.data?.images?.map((element, index) => (
																<div key={index} style={{ width: '200px', height: '200px', borderRadius: '5px', position: 'relative' }}>
																	<div onClick={() => this.onDeleteImage(element.id)} className="closeTag">
																		<i className='fa fa-times' />
																	</div>

																	<img src={element?.secure_url} style={{ width: '100%', height: '100%', borderRadius: '5px' }} alt="Img" onClick={() => window.open(element?.secure_url)} />
																</div>
															))}
														</div>
													</div>
												</TabPane>

												<TabPane tabId="5" role="tabpanel">
													<div className='formulario'>
														<FormGroup className="w-100" style={{ display: 'flex', gap: '42px', alignItems: 'baseline' }}>
															<Label className="col-form-label label-principalRadio">
																Garantia
															</Label>

															<Label className="pl-2 input-radio-group">
																<Input type="radio" name={'war_type'} id={`tipo-1`} value={'WARRANTY'} onChange={handleChange} onBlur={handleBlur} color="primary" checked={['WARRANTY'].includes(values.war_type)} />
																<Label className='radioLabel' style={{ padding: "3px" }} for={`tipo-1`}>
																	Pagamento
																</Label>
															</Label>

															<Label className="pl-2 input-radio-group">
																<Input type="radio" name={'war_type'} id={`tipo-2`} value={'WARRANTY_GUARANTEE_DAMAGE'} onChange={handleChange} onBlur={handleBlur} color="primary" checked={['WARRANTY_GUARANTEE_DAMAGE'].includes(values.war_type)} />
																<Label className='radioLabel' style={{ padding: "3px" }} for={`tipo-2`}>
																	Pagamento com danos
																</Label>
															</Label>

															<Label className="pl-2 input-radio-group">
																<Input type="radio" name={'war_type'} id={`tipo-3`} value={'FREE'} onChange={handleChange} onBlur={handleBlur} color="primary" checked={['FREE'].includes(values.war_type)} />
																<Label className='radioLabel' style={{ padding: "3px" }} for={`tipo-3`}>
																	Grátis
																</Label>
															</Label>
														</FormGroup>

														<Col lg={4} className="p-0 marginInputMobileRua">
															<Label>Valor do aluguel</Label>

															<TextField name="val_rent" type="text" variant="outlined" value={values.val_rent} onBlur={handleBlur}
																onChange={(e) => {
																	handleChange(formatBRLInput(e))
																	const arr = [...this.state.tabChanged]
																	arr.push('values')
																	this.setState({ tabChanged: arr })
																}}
																helperText={touched.val_rent && errors.val_rent} error={Boolean(touched.val_rent && errors.val_rent)} />
														</Col>

														<Row className="p-0 m-0">
															<Col lg={4} className="p-0 m-0">
																<Label>Valor IPTU</Label>

																<TextField name="val_iptu" type="text" variant="outlined" disabled={values.tp_iptu == 'false'} value={values.val_iptu} onBlur={handleBlur} onChange={(e) => {
																	handleChange(formatBRLInput(e))
																	const arr = [...this.state.tabChanged]
																	arr.push('values')
																	this.setState({ tabChanged: arr })
																}}
																	helperText={touched.val_iptu && errors.val_iptu} error={Boolean(touched.val_iptu && errors.val_iptu)} />
															</Col>

															<Col lg={8} className="m-0" style={{ paddingLeft: 32, display: 'flex', alignItems: 'end' }}>
																<FormGroup className="w-100" style={{ display: 'flex', gap: '24px', alignItems: 'baseline', marginBottom: 0, paddingTop: 35 }}>
																	<Label className="pl-2 input-radio-group">
																		<Input type="radio" name={'tp_iptu'} id={`tp_iptu-1`} value={'false'} onChange={(e) => {
																			handleChange(e)
																			const arr = [...this.state.tabChanged]
																			arr.push('values')
																			this.setState({ tabChanged: arr })
																		}} onBlur={handleBlur} color="primary" checked={values.tp_iptu == 'false' || values.tp_iptu == false} />
																		<Label style={{ padding: "3px" }} for={`tp_iptu-1`}>
																			Proprietário paga
																		</Label>
																	</Label>

																	<Label className="pl-2 input-radio-group">
																		<Input type="radio" name={'tp_iptu'} id={`tp_iptu-2`} value={'true'} onChange={(e) => {
																			handleChange(e)
																			const arr = [...this.state.tabChanged]
																			arr.push('values')
																			this.setState({ tabChanged: arr })
																		}} onBlur={handleBlur} color="primary" checked={values.tp_iptu == 'true'} />
																		<Label style={{ padding: "3px" }} for={`tp_iptu-2`}>
																			Inquilino Paga
																		</Label>
																	</Label>
																</FormGroup>
															</Col>
														</Row>


														<Row className="p-0 m-0">
															<Col lg={4} className="p-0 m-0">
																<Label>Valor Condomínio</Label>

																<TextField name="val_condo" type="text" variant="outlined" disabled={values.tp_iptu == 'false'} value={values.val_condo} onBlur={handleBlur} onChange={(e) => {
																	handleChange(formatBRLInput(e))
																	const arr = [...this.state.tabChanged]
																	arr.push('values')
																	this.setState({ tabChanged: arr })
																}}
																	helperText={touched.val_condo && errors.val_condo} error={Boolean(touched.val_condo && errors.val_condo)} />
															</Col>

															<Col lg={8} className="m-0" style={{ paddingLeft: 32, display: 'flex', alignItems: 'end' }}>
																<FormGroup className="w-100" style={{ display: 'flex', gap: '24px', alignItems: 'baseline', marginBottom: 0, paddingTop: 35 }}>
																	<Label className="pl-2 input-radio-group">
																		<Input type="radio" name={'tp_condo'} id={`tipo-1-condo`} value={'false'} onChange={(e) => {
																			handleChange(e)
																			const arr = [...this.state.tabChanged]
																			arr.push('values')
																			this.setState({ tabChanged: arr })
																		}} onBlur={handleBlur} color="primary" checked={values.tp_condo == 'false' || values.tp_condo == false} />
																		<Label style={{ padding: "3px" }} for={`tipo-1-condo`}>
																			Proprietário paga
																		</Label>
																	</Label>

																	<Label className="pl-2 input-radio-group">
																		<Input type="radio" name={'tp_condo'} id={`tipo-2-condo`} value={'true'} onChange={(e) => {
																			handleChange(e)
																			const arr = [...this.state.tabChanged]
																			arr.push('values')
																			this.setState({ tabChanged: arr })
																		}} onBlur={handleBlur} color="primary" checked={values.tp_condo == 'true'} />
																		<Label style={{ padding: "3px" }} for={`tipo-2-condo`}>
																			Inquilino Paga
																		</Label>
																	</Label>
																</FormGroup>
															</Col>
														</Row>

														<Col lg={4} className="p-0 marginInputMobileRua">
															<Label>QTD de depósitos</Label>

															<TextField name="war_security_deposit_months" type="text" variant="outlined" value={values.war_security_deposit_months} onBlur={handleBlur} onChange={(e) => {
																handleChange(e)
																const arr = [...this.state.tabChanged]
																arr.push('values')
																this.setState({ tabChanged: arr })
															}}
																helperText={touched.war_security_deposit_months && errors.war_security_deposit_months} error={Boolean(touched.war_security_deposit_months && errors.war_security_deposit_months)} />
														</Col>
													</div>
												</TabPane>

												<TabPane tabId="6" role="tabpanel">
													<div className='formulario'>
														<button onClick={() => this.addParticipant()} type="button" className='button-primary' style={{ width: 197, height: 38 }}>
															NOVO COMISSIONADO
															<i className="fa fa-check" />
														</button>

														{this.state.addParticipantArray.length > 0 ? (<>
															{this.state.addParticipantArray.map((participant, i) => (
																<>
																	<div className="p-4" style={{ background: '#F1F5F7', height: 'auto', borderRadius: '5px' }}>
																		<Row>
																			<Col md={7} className="d-flex" style={{ alignItems: 'center' }}>
																				<FormGroup className="w-100">
																					<Label className="col-form-label" >Usuário</Label>
																					<UserComponent
																						name="user"
																						onBlur={handleBlur}
																						onChange={(e, value) => {
																							this.state?.addParticipantArray.map((val) => {
																								const updatedObject = { ...val, user_id: value.id, user: { name: value.name } };
																								const updatedArray = [...this.state?.addParticipantArray];
																								updatedArray[i] = updatedObject;
																								this.setState({ addParticipantArray: updatedArray })
																							})
																						}}
																						value={participant?.user}
																					/>
																				</FormGroup>
																			</Col>

																			<Col md={4}>
																				<FormGroup className="w-100">
																					<Label className="col-form-label" >Função</Label>
																					<Select style={{ background: "#FFF", height: "40px" }} name='user_role' onBlur={handleBlur} value={participant.user_role}
																						className="custom-select"
																						onChange={(e) => this.handleSetFunction(e, participant)}>
																						<option key="admin" value='admin' style={{ cursor: 'pointer' }}>Administrador</option>
																						<option key="commissioned" value='commissioned' style={{ cursor: 'pointer' }}>Comissionado</option>
																						<option key="landlord" value='landlord' style={{ cursor: 'pointer' }}>Dono</option>
																					</Select>
																				</FormGroup>
																			</Col>

																			<Col md={1}>
																				<FontAwesomeIcon onClick={() => this.removeParticipant(participant)} icon={faTimes} className="close-icon" />
																			</Col>

																			<Col lg={5}>
																				<FormGroup className="w-100">
																					<Label className="col-form-label" style={{ color: "#343A40" }}>
																						Comissão do primeiro aluguel
																					</Label>
																					<TextField style={{ background: "#FFF", height: "40px" }} name="first_month_value" variant="outlined"
																						value={participant.additional_values.first_month_value}
																						InputProps={{ startAdornment: <InputAdornment position='start'>R$</InputAdornment> }}
																						onBlur={handleBlur} placeholder="Digite o valor..."
																						onChange={(e) => this.handleChangePrimeiroAluguelValue(e, participant)}
																					/>
																				</FormGroup>
																			</Col>

																			<Col lg={4} className="">
																				<FormGroup>
																					<Label className="col-form-label" style={{ color: "#343A40" }}>
																						R$ do aluguel mensal
																					</Label>
																					<TextField
																						style={{ background: "#FFF", height: "40px" }}
																						name="commission_percentage" variant="outlined"
																						value={participant.commission_value || ""}
																						onBlur={handleBlur}
																						placeholder="1.000.000.000,00"
																						onChange={(e) => this.handlePercentageChange(e, participant)}
																						InputProps={{
																							startAdornment: <InputAdornment position="start">R$</InputAdornment>,
																						}}
																					/>
																				</FormGroup>
																			</Col>

																			<Col lg={3} className="p-0 m-0 d-flex align-items-center">
																				<Label className="col-form-label" style={{ color: "#343A40", paddingTop: '35px' }}>
																					Equivale a  <br />
																					<b style={{ color: "#1CBB8C" }}>
																						{parseFloat(participant.commission_percentage).toFixed(3)}%
																					</b>
																				</Label>
																			</Col>
																		</Row>
																	</div>
																</>
															))}
														</>) : null}
													</div>
												</TabPane>
											</TabContent>
										</div>
									</div>

									<div className='box-footer' style={{ width: '100%', maxWidth: '736px' }}>
										<Link to={`/announcements/show/${this.props.match.params.id}`} className='button-secundary'>
											<i className="fa fa-arrow-left" />
											VOLTAR
										</Link>

										<button type="submit" className='button-primary' disabled={this.state.submitted} style={{ width: 95 }}>
											{this.state.submitted ?
												<>
													<i className="fas fa-sync fa-spin" />
												</> :
												<>
													Salvar
													<i className="fa fa-check" />
												</>
											}
										</button>
									</div>
								</form>
							)}
						</Formik>
					</div> : null}
			</BetterCircularProgress>
		)
	}
}

export default AnnouncementsEdit;