import React, { Component } from 'react';
import {
	Container, Card, CardBody, Row, Col,
	Button, ButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, Label, FormGroup, Input
} from "reactstrap";
import Breadcrumbs from '../../components/Breadcrumb';
import { getAllByParams } from '../../crud/propositions.crud';
import DataTableGeradorContrato from '../../components/datatables/DataTableGeradorContrato';
import BetterAlerts from '../../components/BetterAlerts';
import { UserFilterComponent } from '../negotiation/components/user.component';
import { AnnouncementFilterComponent } from '../negotiation/components/announcement.component';
import { PlansFilterComponent } from '../negotiation/components/plans.component';
import { StatusFilterComponent } from './components/status.component';

class GeradorContratoIndex extends Component {

	state = {
		filters: {
			landlord: {},
			tenant: {},
			warranty_type: {},
			negotiation_status: undefined,
			announcement: undefined,
			created_after: undefined,
			created_before: undefined,
		},
		flags: {
			show_landlord: false,
			show_tenant: false,
			show_plan: false,
			show_negotiation_status: false,
			show_announcement: false,
			show_created_after: false,
			show_created_before: false,
		},
		is_filter_open: false,
		export: false
	};

	componentDidMount = () => {
		let filters = this.state.filters;
		let flags = this.state.flags;

		if (localStorage.getItem('geradorContratoFilters')) {
			filters = JSON.parse(localStorage.getItem('geradorContratoFilters'));
		};

		if (localStorage.getItem('geradorContratoFlags')) {
			flags = JSON.parse(localStorage.getItem('geradorContratoFlags'));
		};

		if (this.props.location.state) {
			if (this.props.location.state.success) {
				this.setState({ success: this.props.location.state.success });
			}
		}

		this.setState({ filters, flags });
	};

	saveFilters = () => {
		localStorage.setItem('geradorContratoFilters', JSON.stringify(this.state.filters));
		localStorage.setItem('geradorContratoFlags', JSON.stringify(this.state.flags));
	};

	fetchByFilter = (data) => {
		let params = {
			...data,
			details: {
				...(data.details ? data.details : {}),
				place: this.state.flags.show_announcement ? this.state.filters.announcement : undefined,
				warranty_type: this.state.flags.show_warranty_type ? this.state.filters.warranty_type : undefined,
			},
			landlord_id: this.state.flags.show_landlord ? this.state.filters.landlord?.id : undefined,
			tenant_id: this.state.flags.show_tenant ? this.state.filters.tenant?.id : undefined,
			status: this.state.flags.show_negotiation_status ? this.state.filters.negotiation_status?.replace(/[\\"]/g, "") : undefined,
			// status:'AWAITING_CREDIT_REVIEW',
			created_at_gte: this.state.flags.show_created_after ? this.state.filters.created_after?.replace(/[\\"]/g, "") : undefined,
			created_at_lte: this.state.flags.show_created_before ? this.state.filters.created_before : undefined,
		};

		this.saveFilters();
		return getAllByParams(params);
	};


	render() {
		return (
			<React.Fragment>
				<div className="page-content container-page">
					<Container fluid>
						<Breadcrumbs title="Gerador de Contrato 2.0" breadcrumbItems={[]} />
						<Row className="align-right mb-30">
							<ButtonDropdown className="mr-10 wm-100" isOpen={this.state.is_filter_open} toggle={() => this.setState({ is_filter_open: !this.state.is_filter_open })} >
								<DropdownToggle color="primary" caret>
									<i className="ri-filter-2-fill align-middle mr-2"></i> {"ADD FILTRO"}
								</DropdownToggle>
								<DropdownMenu className="col-sm btn-index-action dropdown-menu-right">
									{!this.state.flags.show_landlord ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_landlord: true } })}>Proprietário</DropdownItem> : null}
									{!this.state.flags.show_tenant ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_tenant: true } })}>Inquilino</DropdownItem> : null}
									{!this.state.flags.show_announcement ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_announcement: true } })}>Imóvel</DropdownItem> : null}
									{!this.state.flags.show_warranty_type ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_warranty_type: true } })}>Plano</DropdownItem> : null}
									{!this.state.flags.show_negotiation_status ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_negotiation_status: true } })}>Status da negociação</DropdownItem> : null}
									{!this.state.flags.show_created_after ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_created_after: true } })}>Criado depois</DropdownItem> : null}
									{!this.state.flags.show_created_before ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_created_before: true } })}>Criado antes</DropdownItem> : null}
								</DropdownMenu>
								<Button color="primary" type="button" style={{ marginRight: '5px', marginLeft: '8px', borderRadius: '3px' }}
									onClick={() => this.setState({ export: true })} >
									EXPORTAR
								</Button>
							</ButtonDropdown>
						</Row>
						<BetterAlerts errors={this.state.errors} success={this.state.success} />
						<Row className="container-center-input">
							{this.state.flags.show_landlord ?
								<UserFilterComponent label="Proprietário" value={this.state.filters.landlord}
									onChange={(e, value) => {
										this.setState({ filters: { ...this.state.filters, landlord: value } });
										this.saveFilters();
									}}
									onClick={() => this.setState({
										flags: { ...this.state.flags, show_landlord: false },
										filters: { ...this.state.filters, landlord: {} }
									}, () => this.saveFilters())} />
								: false
							}

							{this.state.flags.show_tenant ?
								<UserFilterComponent label="Inquilino" value={this.state.filters.tenant}
									onChange={(e, value) => {
										this.setState({ filters: { ...this.state.filters, tenant: value } });
										this.saveFilters();
									}}
									onClick={() => this.setState({
										flags: { ...this.state.flags, show_tenant: false },
										filters: { ...this.state.filters, tenant: {} }
									}, () => this.saveFilters())}
								/>
								: false
							}

							{this.state.flags.show_announcement ?
								<AnnouncementFilterComponent label="Imóvel" value={this.state.filters.announcement}
									onChange={(e, value) => {
										this.setState({ filters: { ...this.state.filters, announcement: value } });
										this.saveFilters();
									}}
									onClick={() => this.setState({
										flags: { ...this.state.flags, show_announcement: false },
										filters: { ...this.state.filters, announcement: undefined }
									}, () => this.saveFilters())} />
								: false
							}

							{this.state.flags.show_warranty_type ?
								<PlansFilterComponent label="Plano" value={this.state.filters.warranty_type}
									onChange={(e) => {
										this.setState({ filters: { ...this.state.filters, warranty_type: e.target.value } });
										this.saveFilters();
									}}
									onClick={() => this.setState({
										flags: { ...this.state.flags, show_warranty_type: false },
										filters: { ...this.state.filters, warranty_type: undefined }
									}, () => this.saveFilters())} />
								: false
							}
							{this.state.flags.show_negotiation_status ?
								<StatusFilterComponent label="Status negociação" value={this.state.filters.negotiation_status}
									onChange={(e, value) => {
										this.setState({ filters: { ...this.state.filters, negotiation_status: e.target.value } });
										this.saveFilters();
									}}
									onClick={() => this.setState({
										flags: { ...this.state.flags, show_negotiation_status: false },
										filters: { ...this.state.filters, negotiation_status: undefined }
									}, () => this.saveFilters())}
								/>
								: false
							}

							{this.state.flags.show_created_after ?
								<Col md={4}>
									<Label className="col-form-label" style={{ color: "#343A40" }}>Criado depois de</Label>
									<FormGroup row>
										<Col>
											<Input className="mb-2" type="date" variant="outlined" style={{ background: "#FFF", height: "40px" }} value={this.state.filters.created_after}
												onChange={(e) => this.setState({ filters: { ...this.state.filters, created_after: e.target.value } }, () => this.saveFilters())} />
										</Col>
										<Button onClick={() => this.setState({ flags: { ...this.state.flags, show_created_after: false }, filters: { ...this.state.filters, created_after: '' } }, () => this.saveFilters())} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
											<i className="ri-close-circle-line align-middle"></i>
										</Button>
									</FormGroup>
								</Col>
								: false
							}

							{this.state.flags.show_created_before ?
								<Col md={4}>
									<Label className="col-form-label">Criado antes de</Label>
									<FormGroup row>
										<Col>
											<Input className="mb-2" type="date" variant="outlined" style={{ background: "#FFF", height: "40px" }} value={this.state.filters.created_before}
												onChange={(e) => this.setState({ filters: { ...this.state.filters, created_before: e.target.value } }, () => this.saveFilters())} />
										</Col>
										<Button onClick={() => this.setState({ flags: { ...this.state.flags, show_created_before: false }, filters: { ...this.state.filters, created_before: '' } }, () => this.saveFilters())} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
											<i className="ri-close-circle-line align-middle"></i>
										</Button>
									</FormGroup>
								</Col>
								: false
							}
						</Row>

						<Row className="container-center-input">
							<Col lg={12}>
								<Card>
									<CardBody>
										<DataTableGeradorContrato filters={this.state.filters} noDelete fetchEvent={this.fetchByFilter} crudButtons={true}
											crudUrl="/gerador-contrato" idRow={`id`} export={this.state.export} />
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}

export default GeradorContratoIndex;