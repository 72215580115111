import axios from "axios";
import { Constants } from '../helpers/Constants';
import { parseQuery } from '../helpers/Utils';

export function getAllByFilter(params) {
    return axios.get(`${Constants.baseUrl}/public-links`, { params: parseQuery(params) });
}

export function getById(id) {
    return axios.get(`${Constants.baseUrl}/public-links/${id}`);
}

export function welcomeLandlord(data) {
    return axios.post(`${Constants.baseUrl}/public-links/welcome-landlord`, data);
}
