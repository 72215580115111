import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { get } from "../../crud/propositions.crud";
import { formatBRL } from "../../helpers/Utils";
import { Link } from "react-router-dom";
import { faBellRing, faBuilding } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { get as getUser } from '../../crud/users.crud';
import { get as getContract } from '../../crud/contracts.crud';
import { getOccurrences } from "../../crud/occurrences.crud";
import { get as getProposition } from '../../crud/propositions.crud';
export default class DataTableNegotiation extends Component {
	constructor() {
		super();

		this.state = {
			property: {},
			data: [],
			rows: 10,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: "status",
			sortOrder: 1,
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		let negotiationDataTable = JSON.parse(localStorage.getItem("negotiationDataTable"));

		if (negotiationDataTable) {
			this.setState(
				{
					data: negotiationDataTable.data,
					rows: negotiationDataTable.rows,
					totalRecords: negotiationDataTable.totalRecords,
					first: negotiationDataTable.first,
					last: negotiationDataTable.last,
					sortField: negotiationDataTable.sortField,
					sortOrder: negotiationDataTable.sortOrder,
					loading: false
				},
			);
		} else {
			this.fetchData();
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters !== this.props.filters) {
			this.fetchData();
		}
	}

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = async (event) => {
		this.setState({
			loading: true,

		});
		if (event.data) {
			event.data = null;
		}

		let negotiationDataTable = {
			rows: event.rows,
			totalRecords: this.state.totalRecords,
			data: this.data,
			first: event.first,
			last: event.rows + event.first,
			loading: this.state.loading,
			sortField: this.state.sortField,
			sortOrder: this.state.sortOrder,
		};

		localStorage.setItem("negotiationDataTable", JSON.stringify(negotiationDataTable));

		this.setState({
			...this.state,
			rows: event.rows,
		});
		this.fetchData(event.rows, event.first);
	};

	fetchData = async (limit, skip) => {
		this.setState({ loading: true });

		let options = {
			$limit: limit ? limit : this.state.rows,
			$skip: skip != null ? skip : this.state.first,
			$sort: { [this.state.sortField]: this.state.sortOrder },
		};

		// Delay para executar a filtragem da tabela
		const delay = 2000;

		const delayExecution = (ms) => {
			return new Promise(resolve => setTimeout(resolve, ms));
		};

		await delayExecution(delay);

		return this.props.fetchEvent(options).then(async (res) => {
			this.props.handleSetIndicadorAtual(res.data.total);

			if (res.data.data.length == 0) {
				this.setState({
					data: [],
					totalRecords: res.data.total,
					first: skip,
					last: skip + res.data.limit,
					loading: false
				});
			}

			if (this.props.activeTab == '1') {
				const data = await Promise.all(
					res.data.data.map(async (obj, i) => {
						return new Promise(function (resolve, reject) {
							get(obj.id).then(({ data }) => resolve(data.data)).catch(() => resolve(obj));
						});
					})
				);

				this.setState({
					data: data,
					totalRecords: res.data.total,
					first: skip,
					last: skip + res.data.limit,
					loading: false
				});
			}

			if (['2', '3', '4', '5', '6', '7'].includes(this.props.activeTab)) {
				await res.data.data.map(async (obj, i) => {
					if (obj.landlord_id) {
						obj.loading_landlord = true;
						await getUser(obj.landlord_id).catch(err => {
							obj.landlord = null;
							obj.loading_landlord = false;
						}).then(res => {
							if (res?.data?.data) {
								obj.landlord = res.data.data;
							}
						});
					}

					if (obj.tenant_id) {
						obj.loading_tenant = true;
						await getUser(obj.tenant_id).catch(err => {
							obj.tenant = null;
							obj.loading_tenant = false;
						}).then(res => {
							if (res?.data?.data) {
								obj.tenant = res.data.data;
							}
						});
					}

					if (this.props.activeTab === '7') {
						await getOccurrences({ contract_id: obj?.id, $limit: 1 }).then((res) => {
							obj.ultimaOcorrencia = res.data.data[0];
							obj.ocorrenciasTotal = res.data.total;
						}).catch((err) => {
							obj.ocorrenciasTotal = 0;
						});

						await getProposition(obj?.proposition_id).then((res) => {
							obj.proposition = res.data.data;
						}).catch((err) => {
							obj.proposition = {};
						});
					}

					this.setState({
						data: res.data.data,
						totalRecords: res.data.total,
						first: skip,
						last: skip + res.data.limit,
						loading: false
					});
				});
			}


		}).catch((err) => {
			console.log(err);
		}).finally(() => {
			this.setState({ loading: false });
		});
	};

	sortTable = async (e) => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder, loading: true }, () => {
			let negotiationDataTable = {
				rows: this.state.rows,
				totalRecords: this.state.totalRecords,
				first: this.state.first,
				last: this.state.last,
				loading: this.state.loading,
				sortField: this.state.sortField,
				sortOrder: this.state.sortOrder,
				data: this.state.data
			};
			localStorage.setItem("negotiationDataTable", JSON.stringify(negotiationDataTable));

			this.fetchData();
		});
	};

	getValue = (obj) => {
		return (
			<div style={{ textAlign: "center" }}>
				<p className="p-0 m-0">{formatBRL(obj.details.values?.total || 0)}</p>
			</div>
		);
	};

	getUserName = (obj, type) => {
		if (obj.landlord && obj.tenant) {
			return (
				<div style={{ textAlign: 'left' }}>
					<Link to={`/users/show/${obj.landlord?.id}`}>{obj.landlord?.name},</Link>
					<br />
					<Link to={`/users/show/${obj.tenant?.id}`} >{obj.tenant?.name}</Link>
				</div>
			)
		}
	};

	getStatusProposition = (obj) => {
		let status = '';
		let backgroundColor = '#4AA3FF'

		switch (obj?.status) {
			case 'PLACEHOLDER':
				status = 'Chat iniciado';
				backgroundColor = '#4AA3FF';
				break;
			case 'DISCARDED_AS_PLACEHOLDER':
				status = 'Descartado'
			case 'DRAFT':
				status = 'Criada integrações';
				backgroundColor = '#5664D2';
				break;
			case 'DISCARDED_BY_TENANT':
				status = 'Descartado pelo inquilino';
				backgroundColor = '#5664D2';
				break;
			case 'DISCARDED_BY_LANDLORD':
				status = 'Descartado pelo responsável';
				backgroundColor = '#5664D2';
				break;
			case 'AWAITING_TENANT_LOCK':
				status = 'Aguardando trava inquilino';
				backgroundColor = '#5664D2';
				break;
			case 'AWAITING_CREDIT_REVIEW':
				status = 'Aguardando análise financeira';
				backgroundColor = '#EFA200';
				break;
			case 'APPROVED':
				status = 'Análise financeira aprovada';
				backgroundColor = '#1CBB8C';
				break;
			case 'FIRST_PROPOSAL':
				status = 'Criada Admin';
				backgroundColor = '#5664D2';
				break;
			case 'CREDIT_REFUSED':
				status = 'Análise financeira reprovada';
				backgroundColor = '#FF3D60';
				break;
			case 'AWAITING_SIGN':
				status = 'Aguardando assinaturas';
				backgroundColor = '#EFA200';
				break;
			case 'FINALIZED':
				status = 'Contrato assinado';
				backgroundColor = '#1CBB8C';
				break;
			case 'FINISHED':
				status = 'Contrato rescindido';
				backgroundColor = '#4AA3FF';
				break;
			default:
				status = obj?.status;
				break;
		}
		return (
			<div className="tag" style={{ textAlign: "center", backgroundColor }}>
				{status}
			</div>
		);
	}

	getAddress(property) {
		if (property?.address) {
			return (
				<Link to={`/announcements/show/${property?.id}`}>
					<div className='negotiation-active' style={{ textAlign: 'left' }}>
						<FontAwesomeIcon icon={faBuilding} style={{ marginRight: '8.5px' }} />

						{property.address.street} {property.address.number}, {property.address?.complement ?? ''} {property.address.neighbourhood ? `, ${property.address.neighbourhood}` : ''},  - {property.address.city}/{property.address.state}
					</div>
				</Link>
			)
		}
	}

	columnOpcoesCrud = (rowData) => {
		let idRow = this.props.idRow ? this.props.idRow : 'id';

		if (this.props.activeTab != '1') {
			idRow = 'proposition_id';
		}

		let type = '';

		switch (this.props.activeTab) {
			case '1':
				type = 'negociando'
				break;
			case '2':
				type = 'contratando'
				break;
			case '3':
				type = 'vigentes'
				break;
			case '4':
				type = 'encerrando'
				break;
			case '5':
				type = 'encerrados'
				break;
			case '6':
				type = 'cancelados'
				break;
			default:
				break;
		}

		const typeNegotiation = `${this.props.activeTab !== '7' ? `?typeNegociacao=${type}` : ''}`

		if (this.props.crudUrl) {
			return (
				<Link to={`${this.props.crudUrl}/show/${rowData[idRow]}${typeNegotiation}`} className='showButton'>
					<i className='fa fa-chevron-right' />
				</Link>
			);
		} else {
			return null;
		}
	};

	getWarranty = (key, plan = {}) => {
		let garantia = '';

		if (key == 'FREE') {
			garantia = 'Grátis';
		} else if (key == 'WARRANTY') {
			garantia = 'Pagamento';
		} else if (key == 'WARRANTY_GUARANTEE_DAMAGE') {
			garantia = 'Completa';
		} else {
			garantia = 'Não identificado';
		}

		return (
			<div>
				{garantia}

				{plan?.percentage ?
					<div className="tag" style={{ backgroundColor: '#5664D2', marginTop: 4 }}>
						{plan.percentage}%
					</div>
					: null}
			</div>
		)
	};

	tempoRestante = (obj) => {
		const { start_date, end_date, duration } = obj.details;

		const hoje = moment(new Date);
		const inicio = moment(start_date, 'MM/DD/YYYY');
		const fim = moment(end_date, 'MM/DD/YYYY');

		let mesesFaltando = moment(fim).diff(hoje, 'M');
		let porcentagem = Math.round((mesesFaltando / duration) * 100)

		if (Math.sign(mesesFaltando) < 0) {
			mesesFaltando = 0;
		}

		if (porcentagem < 0) {
			porcentagem = 100;
		}

		return (
			<div>
				{mesesFaltando}m

				<div className="tag" style={{ backgroundColor: '#5664D2', marginTop: 4 }}>
					{porcentagem}%
				</div>
			</div>
		)
	}

	getStatusTerminationContract = (obj) => {
		let status = obj?.terminations?.[0]?.type ?? obj?.termination_status ?? 'SEM_MOTIVO';
		let backgroundColor = '#4AA3FF'

		if (obj?.terminations?.[0]?.status == 'AWAITING_COURT_APPROVAL' || obj?.termination_status == 'AWAITING_COURT_APPROVAL') {
			return (
				<div className="tag" style={{ textAlign: "center", backgroundColor: '#EFA200' }}>
					Esperando aprovação judicial
				</div>
			)
		}

		switch (status) {
			case 'Quebra de contrato':
				backgroundColor = '#FF3D60';
				break;
			case 'EXTRAJUDICIAL':
				backgroundColor = '#FF3D60';
				status = 'Quebra de contrato'
				break;
			case 'Comum acordo':
				backgroundColor = '#1CBB8C';
				break;
			case 'TERMINATION_TERM':
				backgroundColor = '#1CBB8C';
				status = 'Comum acordo'
				break;
			case 'CLOSURE_TERM':
				backgroundColor = '#4AA3FF';
				status = 'Tempo previsto por contrato';
				break;
			case 'Tempo previsto por contrato':
				backgroundColor = '#4AA3FF';
				break;
			case 'TERMINATION_SCHEDULED':
				status = 'Rescisão agendada'
				backgroundColor = '#4AA3FF';
				break;
			case 'AWAITING_COURT_APPROVAL':
				status = 'Esperando aprovação judicial'
			case 'SEM_MOTIVO':
				status = 'Rescindido pelo Admin'
				break;
			default:
				status = 'Rescindido pelo Admin'
				break;
		}

		console.log(status)
		return (
			<div className="tag" style={{ textAlign: "center", backgroundColor }}>
				{status}
			</div>
		);
	}

	getContractLink = (obj, type) => {
		return (
			<Link to={`/negotiation/show/${obj?.proposition_id}?typeNegociacao=${type}`}>
				{obj.friendly_code}
			</Link>
		)
	}

	getOcorrenciasTotal = (obj) => {
		return (
			<Link to={`/ocorrencias/show/${obj?.ultimaOcorrencia?.id}/${obj?.proposition_id}`} className="d-flex" style={{ color: '#4AA3FF', gap: 8.5 }}>
				{obj?.ocorrenciasTotal}

				<FontAwesomeIcon icon={faBellRing} />
			</Link>
		)
	}

	render() {
		const negociando = [
			() => <Column style={{ width: 116 }} sortable field="created_at" body={(obj, i) => moment(obj.created_at).format('DD/MM/YYYY HH:mm:ss')} header="Cadastrado" />,
			() => <Column style={{ width: 132 }} sortable field="status" header="Status" body={(obj) => this.getStatusProposition(obj)} />,
			() => <Column style={{ width: 110 }} sortable field="updated_at" body={(obj, i) => moment(obj.updated_at).format('DD/MM/YYYY HH:mm:ss')} header="Atualizado" />,
			() => <Column style={{ width: 283 }} sortable field="details.place" header="Imóvel" body={({ property }) => this.getAddress(property)} />,
			() => <Column style={{ width: 283 }} field="user.name" body={(obj, i) => this.getUserName(obj)} header="Envolvidos" />,
			() => <Column style={{ width: 108 }} sortable field="plan.name" body={(obj) => this.getWarranty(obj?.plan?.name)} header="Garantia" />,
			() => <Column style={{ width: 102 }} sortable body={(obj) => this.getValue(obj)} field="details.values.rent" header="Valor total" />
		]

		const contratando = [
			() => <Column style={{ width: 116 }} sortable field="created_at" body={(obj, i) => moment(obj.created_at).format('DD/MM/YYYY HH:mm:ss')} header="Cadastrado" />,
			() => <Column style={{ width: 110 }} sortable field="updated_at" body={(obj, i) => moment(obj.updated_at).format('DD/MM/YYYY HH:mm:ss')} header="Atualizado" />,
			() => <Column style={{ width: 240 }} sortable field="property.address.street" header="Imóvel" body={({ property }) => this.getAddress(property)} />,
			() => <Column style={{ width: 240 }} field="user.name" body={(obj, i) => this.getUserName(obj)} header="Envolvidos" />,
			() => <Column style={{ width: 100 }} sortable field="friendly_code" body={(obj) => this.getContractLink(obj, 'contratando')} header="Contrato" />,
			() => <Column style={{ width: 118 }} field="Assinaturas" header="Assinaturas" body={(obj) => (<div style={obj?.signatures?.signed == 0 ? { color: '#FF3D60' } : { color: '#EFA200' }}>{obj?.signatures?.signed} de {obj?.signatures?.total}</div>)} />,
			() => <Column style={{ width: 102 }} sortable field="plan.name" body={(obj) => this.getWarranty(obj?.plan?.name)} header="Garantia" />,
			() => <Column style={{ width: 108 }} sortable body={(obj) => this.getValue(obj)} field="details.values.rent" header="Valor total" />
		]

		const vigentes = [
			() => <Column style={{ width: 116 }} sortable field="created_at" body={(obj, i) => moment(obj.created_at).format('DD/MM/YYYY HH:mm:ss')} header="Cadastrado" />,
			() => <Column style={{ width: 104 }} sortable field="signed_at" body={(obj, i) => moment(obj.updated_at).format('DD/MM/YYYY HH:mm:ss')} header="Assinado" />,
			() => <Column style={{ width: 232 }} sortable field="property.address.street" header="Imóvel" body={({ property }) => this.getAddress(property)} />,
			() => <Column style={{ width: 232 }} field="user.name" body={(obj, i) => this.getUserName(obj)} header="Envolvidos" />,
			() => <Column style={{ width: 100 }} sortable field="friendly_code" body={(obj) => this.getContractLink(obj, 'vigentes')} header="Contrato" />,
			() => <Column style={{ width: 140 }} body={(obj, i) => this.tempoRestante(obj)} header="Tempo restante" />,
			() => <Column style={{ width: 102 }} field="plan.name" body={(obj) => this.getWarranty(obj?.plan?.name, obj?.plan)} header="Garantia" />,
			() => <Column style={{ width: 108 }} sortable body={(obj) => this.getValue(obj)} field="details.values.rent" header="Valor total" />
		]

		const encerrando = [
			() => <Column style={{ width: 116 }} sortable field="created_at" body={(obj, i) => moment(obj.created_at).format('DD/MM/YYYY HH:mm:ss')} header="Cadastrado" />,
			() => <Column style={{ width: 151 }} sortable field="termination_status" header="Status" body={(obj) => this.getStatusTerminationContract(obj)} />,
			() => <Column style={{ width: 110 }} sortable field="updated_at" body={(obj, i) => moment(obj.updated_at).format('DD/MM/YYYY HH:mm:ss')} header="Atualizado" />,
			() => <Column style={{ width: 207 }} field="property.address.street" header="Imóvel" body={({ property }) => this.getAddress(property)} />,
			() => <Column style={{ width: 207 }} field="user.name" body={(obj, i) => this.getUserName(obj)} header="Envolvidos" />,
			() => <Column style={{ width: 100 }} sortable field="friendly_code" body={(obj) => this.getContractLink(obj, 'encerrando')} header="Contrato" />,
			() => <Column style={{ width: 102 }} sortable field="plan.name" body={(obj) => this.getWarranty(obj?.plan?.name, obj?.plan)} header="Garantia" />,
			() => <Column style={{ width: 141 }} sortable body={(obj) => this.getValue(obj)} field="details.values.rent" header="Valor da recisão" />
		]

		const encerrados = [
			() => <Column style={{ width: 116 }} sortable field="created_at" body={(obj, i) => moment(obj.created_at).format('DD/MM/YYYY HH:mm:ss')} header="Cadastrado" />,
			() => <Column style={{ width: 151 }} sortable field="termination_status" header="Status" body={(obj) => this.getStatusTerminationContract(obj)} />,
			() => <Column style={{ width: 108 }} sortable field="updated_at" body={(obj, i) => moment(obj.updated_at).format('DD/MM/YYYY HH:mm:ss')} header="Encerrado" />,
			() => <Column style={{ width: 208 }} field="property.address.street" header="Imóvel" body={({ property }) => this.getAddress(property)} />,
			() => <Column style={{ width: 208 }} field="user.name" body={(obj, i) => this.getUserName(obj)} header="Envolvidos" />,
			() => <Column style={{ width: 100 }} sortable field="friendly_code" body={(obj) => this.getContractLink(obj, 'encerrados')} header="Contrato" />,
			() => <Column style={{ width: 102 }} sortable field="plan.name" body={(obj) => this.getWarranty(obj?.plan?.name, obj?.plan)} header="Garantia" />,
			() => <Column style={{ width: 141 }} sortable body={(obj) => this.getValue(obj)} field="details.values.rent" header="Valor da recisão" />
		]

		const cancelados = [
			() => <Column style={{ width: 116 }} sortable field="created_at" body={(obj, i) => moment(obj.created_at).format('DD/MM/YYYY HH:mm:ss')} header="Cadastrado" />,
			() => <Column style={{ width: 111 }} sortable field="terminated_at" body={(obj, i) => moment(obj.terminated_at).format('DD/MM/YYYY HH:mm:ss')} header="Cancelado" />,
			() => <Column style={{ width: 348 }} sortable field="property.address.street" header="Imóvel" body={({ property }) => this.getAddress(property)} />,
			() => <Column style={{ width: 348 }} field="user.name" body={(obj, i) => this.getUserName(obj)} header="Envolvidos" />,
			() => <Column style={{ width: 102 }} sortable field="plan.name" body={(obj) => this.getWarranty(obj?.plan?.name, obj?.plan)} header="Garantia" />,
			() => <Column style={{ width: 108 }} sortable body={(obj) => this.getValue(obj)} field="details.values.rent" header="Valor total" />
		]

		const ocorrencias = [
			() => <Column style={{ width: 116 }} sortable field="created_at" body={(obj, i) => moment(obj.created_at).format('DD/MM/YYYY HH:mm:ss')} header="Cadastrado" />,
			() => <Column style={{ width: 132 }} sortable field="status" header="Status" body={(obj) => this.getStatusProposition(obj?.proposition)} />,
			() => <Column style={{ width: 108 }} sortable field="updated_at" body={(obj, i) => moment(obj.updated_at).format('DD/MM/YYYY HH:mm:ss')} header="Atualizado" />,
			() => <Column style={{ width: 208 }} field="property.address.street" header="Imóvel" body={({ property }) => this.getAddress(property)} />,
			() => <Column style={{ width: 208 }} field="user.name" body={(obj, i) => this.getUserName(obj)} header="Envolvidos" />,
			() => <Column style={{ width: 102 }} sortable field="plan.name" body={(obj) => this.getWarranty(obj?.plan?.name, obj?.plan)} header="Garantia" />,
			() => <Column style={{ width: 108 }} sortable body={(obj) => this.getValue(obj)} field="details.values.rent" header="Valor total" />,
			() => <Column style={{ width: 108 }} sortable body={(obj) => this.getOcorrenciasTotal(obj)} field="ocorrenciasTotal" header="Ocorrências" />,
		]

		return (
			<DataTable ref={(el) => this.dt = el} value={this.state.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={[10, 25, 50]}
				first={this.state.first} last={this.state.last} responsive={true} totalRecords={this.state.totalRecords} lazy={true} sortField={this.state.sortField}
				loading={this.state.loading} loadingIcon={`fas fa-sync fa-spin`} emptyMessage="Nenhum registro encontrado!" onSort={(e) => this.sortTable(e)}
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" onPage={this.onPage}
				currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" sortOrder={this.state.sortOrder} className="custom-datatableConfigs" >
				{this.props.activeTab == '1' && (
					negociando.map((coluna) => coluna())
				)}

				{this.props.activeTab == '2' && (
					contratando.map((coluna) => coluna())
				)}

				{this.props.activeTab == '3' && (
					vigentes.map((coluna) => coluna())
				)}

				{this.props.activeTab == '4' && (
					encerrando.map((coluna) => coluna())
				)}

				{this.props.activeTab == '5' && (
					encerrados.map((coluna) => coluna())
				)}

				{this.props.activeTab == '6' && (
					cancelados.map((coluna) => coluna())
				)}

				{this.props.activeTab == '7' && (
					ocorrencias.map((coluna) => coluna())
				)}

				<Column header="" body={this.columnOpcoesCrud} style={{ width: '34px', padding: 0 }} />
			</DataTable>
		);
	}
}
