import React, { Component } from 'react';
import { Container, Card, CardBody, FormGroup, Label, Input, Col } from "reactstrap";
import BetterCircularProgress from '../../components/BetterCircularProgress';
import { Link, Redirect } from "react-router-dom";
import moment from 'moment';
import { createImageRepayment, createRepayment, getById, responseRepayment } from '../../crud/billings.crud';
import { formatBRL, limparMoeda } from '../../helpers/Utils';
import Breadcrumbs from '../../components/Breadcrumb';
import 'moment/locale/pt-br';
import { ErrorMessage, Formik } from 'formik';
import { validateFormReembolso } from '../../helpers/FormValidators';
import { TextField } from '@material-ui/core';
import BetterAlerts from '../../components/BetterAlerts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImport } from '@fortawesome/pro-regular-svg-icons';

moment.locale('pt-br');

class BillingReembolso extends Component {

	state = {
		loading: false,
		data: null,
		agreeModal: false,
		errors: []
	}

	componentDidMount = () => {
		this.setState({ loading: true });

		getById(this.props.match.params.id).then(res => {
			this.setState({ data: res.data.data });
		}).catch((err) => console.log(err));

		this.setState({ loading: false });
	};

	formatDate(date) {
		return moment(date).utcOffset(+3).format('DD/MMMM/YYYY');
	}

	submit = (values) => {
		values.billing_id = this.props.match.params.id;
		values.amount = limparMoeda(values?.amount);
		values.contract_id = this.state.data.contract_id;

		if (values.description == '') {
			delete values.description
		}

		this.setState({ loading: true, errors: [] });

		let anexos = values.anexos;
		delete values.anexos

		createRepayment(values).then(res => {
			if (res?.status == 200) {
				// responseRepayment(res.data.data.id, { status: 'accepted' }).then((res) => {
				// }).catch(({ response }) => {
				// 	console.log(response)
				// });

				if (anexos.length > 0) {
					anexos.forEach(async (anexo, index) => {
						let formData = new FormData();
						formData.append('file', anexo);

						await createImageRepayment(res.data.data.id, formData).then((res) => {
							if (anexos.length == index + 1) {
								this.setState({ success: true });
							}
						}).catch(({ response }) => {
							if (response?.data?.message) {
								this.setState({ errors: [...this.state.errors, response?.data?.message] });
							} else {
								this.setState({ errors: [...this.state.errors, 'houve um erro, entre em contato com o suporte'] });
							}
						});
					});
				} else {
					// router.push(`/perfil/gerenciamento/pagamentos/reembolso/${router?.query?.id}`)
				}
			}
		}).catch(({ response }) => {
			if (response?.data?.message) {
				this.setState({ errors: [...this.state.errors, response?.data?.message] });
			} else {
				this.setState({ errors: [...this.state.errors, 'houve um erro, entre em contato com o suporte'] });
			}
		}).finally(() => {
			this.setState({ loading: false });
		});
	}

	render() {
		return (
			<React.Fragment>
				{this.state.success ? (<Redirect to={{ pathname: `/billing/show/${this.props.match.params.id}`, state: { success: ["Reembolso criado com sucesso!"] } }} />) : null}

				<BetterCircularProgress loading={this.state.loading}>
					<div className="page-content container-page userNew reembolso">
						<Container fluid className='form'>
							<Formik initialValues={{ amount: 0, type: '', title: '', description: '', anexos: [] }} validate={(values) => validateFormReembolso(values)} onSubmit={this.submit}>
								{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
									<form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>
										<div style={{ width: '736px' }}>
											<div className='header' style={{ marginBottom: 0 }}>
												<h1>Novo Reembolso</h1>

												<Breadcrumbs breadcrumbItems={[{ link: '/pagamento/cobrancas/index', title: 'Financeiro' }, { link: '/pagamento/cobrancas/index', title: 'Cobranças' }, { link: `#`, title: `ID ${this.props.match.params.id}` }]} />
											</div>

											<div className='box'>
												<h5>DETALHES</h5>

												<div className='formulario'>
													<Col md={12} className="p-0 m-0">
														<Label className='label-bold'>Tipo de reembolso:</Label>

														<FormGroup className="w-100 pt-2" style={{ display: 'flex', gap: '42px', alignItems: 'baseline' }}>
															<Label className="pl-4 input-radio-group">
																<Input type="radio" name={'type'} id={`tipo-1`} value={'addition'} onChange={handleChange} onBlur={handleBlur} color="primary" checked={['addition'].includes(values.type)} />
																<Label className='label-choice-acrescimo' style={{ padding: "3px", color: '#FF3D60' }} for={`tipo-1`}>
																	Acréscimo
																</Label>
															</Label>

															<Label className="pl-2 w-100 input-radio-group">
																<Input type="radio" name={'type'} id={`tipo-2`} value={'repayment'} onChange={handleChange} onBlur={handleBlur} color="primary" checked={['repayment'].includes(values.type)} />
																<Label className='label-choice-reembolso' style={{ padding: "3px" }} for={`tipo-2`}>
																	Desconto
																</Label>
															</Label>
														</FormGroup>

														<ErrorMessage name="type">
															{error => <p style={{ color: '#f44336' }} className='MuiFormHelperText-root MuiFormHelperText-contained Mui-error'>{error}</p>}
														</ErrorMessage>
													</Col>

													<Col md={12} className="p-0 m-0">
														<Label>O que é a requisição?</Label>
														<TextField name="title" type="text" variant="outlined" placeholder='Digite...' value={values.title} onBlur={handleBlur} onChange={handleChange}
															helperText={touched.title && errors.title} error={Boolean(touched.title && errors.title)} />
													</Col>

													<Col md={12} className="p-0 m-0">
														<Label>Descrição</Label>
														<TextField name="description" type="text" variant="outlined" placeholder='Digite...' value={values.description} onBlur={handleBlur} onChange={handleChange} />
													</Col>

													<Col md={12} className="p-0 m-0">
														<Label>Valor</Label>
														<TextField style={{ background: "#FFF", height: "40px" }} name={'amount'} variant="outlined" value={formatBRL(values.amount)} onBlur={handleBlur} placeholder="Digite o valor..."
															onChange={(e) => { e.target.value = Number(e.target.value.toString().replace(/[^0-9-]/g, '')) / 10 ** 2; handleChange(e) }}
															helperText={touched.amount && errors.amount} error={Boolean(touched.amount && errors.amount)} />
													</Col>
												</div>
											</div>

											<div className='box'>
												<h5>COMPROVANTE E FOTOS</h5>

												<label type="button" htmlFor="anexos" className="buttonPrimary w-100" style={{ width: '100%', maxWidth: '121px', marginBottom: 24 }}>
													<Input type="file" accept=".png,.jpeg,.jpg,.pdf" id="anexos" name='anexos' multiple className='d-none' onChange={(event) => {
														setFieldValue('anexos', [...values.anexos, ...event.currentTarget.files]); event.target.value = "";
													}} />
													IMPORTAR
													<FontAwesomeIcon icon={faFileImport} />
												</label>

												<div className='container-fotos'>
													{values?.anexos?.map((anexo, index) =>
														<div key={index} className="anexo" onClick={() => window.open(anexo.url)}>
															anexo-{index + 1}
														</div>
													)}
												</div>
											</div>

											<div className='box' style={{ paddingTop: 32 }}>
												<Label className="pl-4 pb-0 mb-0 input-radio-group">
													<Input type="radio" name={'agreeModal'} id={`agreeModal`} value={!this.state.agreeModal} onChange={() => this.setState({ agreeModal: !this.state.agreeModal })} color="primary" checked={this.state.agreeModal} />
													<Label style={{ padding: "3px 0 0 3px", marginBottom: 0 }} for={`agreeModal`}>
														Confirmo em criar esse reembolso e sei que esta ação é <b style={{ color: '#FF3D60' }}>irreversível.</b>
													</Label>
												</Label>
											</div>

											<div className='box-footer'>
												<button onClick={() => this.props.history.goBack()} className='button-secundary'>
													<i className="fa fa-arrow-left" />
													VOLTAR
												</button>

												<button type="submit" className='button-primary' disabled={this.state.loading || !this.state.agreeModal} style={{ width: 95 }}>
													{this.state.loading ?
														<i className="fas fa-sync fa-spin" />
														:
														<>Salvar <i className="fa fa-check" /></>
													}
												</button>
											</div>
										</div>
									</form>
								)}
							</Formik>
						</Container>
					</div>
				</BetterCircularProgress>
			</React.Fragment>
		)
	}
}

export default BillingReembolso;