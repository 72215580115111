import React, { Component, createRef } from 'react';
import { Container, Card, CardBody, Col, FormGroup, Label, Button } from "reactstrap";
import BetterCircularProgress from '../../components/BetterCircularProgress';
import { get as getContract, createContractAdminLogs, deleteContractualEdit, getContractualEdit, getContractualEditFile, resendContractualEditNotifications } from '../../crud/contracts.crud';
import { Link, Redirect } from "react-router-dom";
import { formatBRL, formatCPF, formatTelefone } from '../../helpers/Utils';
import Breadcrumbs from '../../components/Breadcrumb';
import moment from 'moment';
import BetterAlerts from '../../components/BetterAlerts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faEye } from '@fortawesome/pro-solid-svg-icons';
import cashIcon from '../../assets/images/icons-custom/cash.svg';
import listIcon from '../../assets/images/icons-custom/icon.svg';
import groupIcon from '../../assets/images/icons-custom/user-group.svg';
import Swal from 'sweetalert2';

class ContractualEditShow extends Component {
	dataTableLogsContratoRef = createRef();

	state = {
		loading: false,
		success: [],
		message: null,
		data: null,
		landlord: null,
		tenant: null,
		activeTab: '1',
		tenant_parties: [],
		planName: '',
		proposition_id: null,
		contract_id: null,
		propertyInspectionList: [],
		canRestore: false,
		commissionParticipants: [],
		affiliate_user_list: [],
		terminations: [],
		contractual_edits: [],
		terminations_users: [],
		comentario: '',
		witnesses: [],
		filters: {
			depoisDe: '',
			antesDe: '',
			informacao: ''
		},
		flags: {
			tenant_sign: false,
			landlord_sign: false,
			show_depoisDe: false,
			show_antesDe: false,
			show_informacao: false,
		},
		friendlyAgreement: [],
		showCreateFriendlyAgreement: false,
		disableVistoria: false,
		contract: null
	}

	componentDidMount = () => {
		this.setState({ loading: true });

		if (this.props.location.state) {
			if (this.props.location.state.success) {
				this.setState({ success: this.props.location.state.success });
			}
		}

		this.fetchData();

		this.setState({ loading: false })
	};

	fetchData = () => {
		getContractualEdit(this.props.match.params.id).catch(err => {
			alert('An error occurred, please contact the support.');
		}).then(async res => {
			if (res?.status === 200) {
				if (res.data) {
					res?.data?.contractual_edit_users.sort(function (a, b) {
						// Coloque os objetos com user_type igual a "witness" no final
						if (a.user_type === "witness" && b.user_type !== "witness") {
							return 1;
						} else if (a.user_type !== "witness" && b.user_type === "witness") {
							return -1;
						} else {
							// Para outros casos, mantenha a ordem atual
							return 0;
						}
					});

					this.setState({ data: res.data });

					console.log(res.data);

					const w = res.data.contractual_edit_users.filter(obj => obj.user_type === 'witness');

					this.setState({ witnesses: w })

					getContract(res.data?.contract_id).then(res => {
						if (res.status === 200) {
							this.setState({ contract: res.data?.data?.entity })
						}
					})
				}
			}
			this.setState({ loading: false });
		});


	}

	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	};

	formatDate(date) {
		const newDate = date?.split('/')[1] + '/' + date?.split('/')[0] + '/' + date?.split('/')[2];
		return newDate;
	}

	renderPlan = () => {
		return (
			this.state.data?.entity?.property?.warranty?.security_deposit_months > 0 ?
				<p className="col-form-label" style={{ fontSize: '13px', lineHeight: '16px' }}>{"- " + this.state.data?.entity?.property?.warranty?.security_deposit_months + " caução de entrada"}</p>
				:
				<p className="col-form-label" style={{ fontSize: '13px', lineHeight: '16px' }}>Sem garantia</p>
		)
	}



	handleDeleteContractualEdit = async () => {
		try {
			Swal.fire({
				customClass: { title: 'custom-title', confirmButton: 'custom-confirm', container: 'custom-container' },
				title: "Tem certeza que deseja cancelar a edição contratual?",
				text: `Ao cancelar, não será possível assinar o aditivo de contrato e a edição dos dados não vai acontecer.`,
				showCancelButton: true,
				confirmButtonColor: '#F1F5F7',
				cancelButtonColor: '#5664D2',
				cancelButtonText: "Voltar",
				confirmButtonText: "Cancelar edição",
			}).then((result) => {
				if (result.value) {
					deleteContractualEdit(this.state.data?.id).then((res) => {
						if (res?.status === 200) {
							this.setState({ success: ['Edição excluído com sucesso!'] });
						}
					}).catch((err) => {
						this.setState({ errors: ['Houve um erro ao tentar cancelar a edição!', ...this.state.errors] });
						console.log(err);
					})
				}
			});
		} catch (err) {
			this.setState({ errors: ['Houve um erro e não foi possível a exclusão da edição contratual.'] });
		}
	}

	getContractOperation = (operation) => {
		switch (operation) {
			case 'RESTORE_CONTRACT':
				return 'Contrato restaurado';
			default:
				return 'N/A';
		}
	};

	formatIndice = (indice) => {
		if (indice === 'igp_m') {
			return 'IGP-M'
		}
		if (indice === 'ipca') {
			return 'IPCA'
		}
		if (indice === 'common_agreement') {
			return 'Comum acordo'
		}
	}

	getTerminationType = (contract) => {
		if (contract?.terminated_by === contract.landlord_id) {
			return 'Responsável'
		} else if (contract?.terminated_by === contract.tenant_id) {
			return 'Inquilino'
		} else {
			return ''
		}
	}

	getContractAdminLogText = (operation, log) => {
		switch (operation) {
			case 'RESTORE_CONTRACT':
				return (
					<>
						<p>{`Data de rescisão anterior: ${moment(log?.terminated_at_before).format('DD/MM/YYYY HH:mm:ss')}`}</p>

						{log?.terminated_by_before &&
							<Link to={`/users/show/${log?.terminated_by_before}`}>
								{`Rescindido por: ${log?.terminated_by_before ?? 'N/A'}`}
							</Link>
						}
					</>
				)
			default:
				return 'N/A';
		}
	};

	getUserType = (user) => {
		if (user.user_type === 'admin') {
			return 'Administrador'
		} else if (user.user_type === 'commissioned') {
			return 'Comissionado'
		} else if (user.user_type === 'landlord') {
			return 'Dono'
		}
	}

	saveFilters = () => {
		localStorage.setItem('dataTableLogsContratoFilters', JSON.stringify(this.state.filters));
		localStorage.setItem('dataTableLogsContratoFlags', JSON.stringify(this.state.flags));
	};

	getUserTypeName = (user_type) => {
		if (user_type === 'landlord') {
			return 'Dono'
		}

		if (user_type === 'tenant') {
			return 'Inquilino'
		}

		if (user_type === 'admin') {
			return 'Administrador'
		}
		if (user_type === 'commissioned') {
			return 'Comissionado'
		}
		if (user_type === 'witness') {
			return 'Testemunha'
		}
	}

	getEditStatus = (status) => {
		if (status === 'AWAITING_SIGN') {
			return 'Em assinatura'
		}

		if (status === 'SIGNED') {
			return 'Assinado'
		}

		if (status === 'CANCELED') {
			return 'Cancelado'
		}

		if (status === 'EXPIRED') {
			return 'Expirado'
		}
	}

	getLandlordChages = () => {

		let string = '';

		if (this.state.data.landlord_old_info?.name && this.state.data.landlord_new_info?.name) string = `${this.state.data.landlord_new_info?.name};`;
		if (this.state.data.landlord_old_info?.address?.zip_code && this.state.data.landlord_new_info?.address?.zip_code) string = `${string} ${this.state.data.landlord_new_info?.address?.zip_code};`
		if (this.state.data.landlord_old_info?.address?.city && this.state.data.landlord_new_info?.address?.city) string = `${string} ${this.state.data.landlord_new_info?.address?.city};`
		if (this.state.data.landlord_old_info?.address?.state && this.state.data.landlord_new_info?.address?.state) string = `${string} ${this.state.data.landlord_new_info?.address?.state};`
		if (this.state.data.landlord_old_info?.address?.street && this.state.data.landlord_new_info?.address?.street) string = `${string} ${this.state.data.landlord_new_info?.address?.street};`
		if (this.state.data.landlord_old_info?.address?.neighbourhood && this.state.data.landlord_new_info?.address?.neighbourhood) string = `${string} ${this.state.data.landlord_new_info?.address?.neighbourhood};`
		if (this.state.data.landlord_old_info?.address?.number && this.state.data.landlord_new_info?.address?.number) string = `${string} ${this.state.data.landlord_new_info?.address?.number};`
		if (this.state.data.landlord_old_info?.address?.complement && this.state.data.landlord_new_info?.address?.complement) string = `${string} ${this.state.data.landlord_new_info?.address?.complement};`

		return string;
	}

	getTenantChanges = (tenant) => {

		const newTenant = this.state?.data?.tenant_new_info?.find(obj => obj.user_id === tenant.user_id);
		const oldTenant = this.state?.data?.tenant_old_info?.find(obj => obj.user_id === tenant.user_id);

		let string = '';

		if (oldTenant?.name && newTenant?.name) string = `${newTenant?.name};`;
		if (oldTenant?.address?.zip_code && newTenant?.address?.zip_code) string = `${string} ${newTenant?.address?.zip_code};`
		if (oldTenant?.address?.city && newTenant?.address?.city) string = `${string} ${newTenant?.address?.city};`
		if (oldTenant?.address?.state && newTenant?.address?.state) string = `${string} ${newTenant?.address?.state};`
		if (oldTenant?.address?.street && newTenant?.address?.street) string = `${string} ${newTenant?.address?.street};`
		if (oldTenant?.address?.neighbourhood && newTenant?.address?.neighbourhood) string = `${string} ${newTenant?.address?.neighbourhood};`
		if (oldTenant?.address?.number && newTenant?.address?.number) string = `${string} ${newTenant?.address?.number};`
		if ((oldTenant?.address?.complement && newTenant?.address?.complement) || (oldTenant?.address?.complement === '' && newTenant?.address?.complement !== '')) string = `${string} ${newTenant?.address?.complement};`

		return string;
	}

	getAnnouncementChanges = () => {

		let string = '';
		if (this.state.data.announcement_old_info?.zip_code && this.state.data.announcement_new_info?.zip_code) string = `${string} ${this.state.data.announcement_new_info?.zip_code};`
		if (this.state.data.announcement_old_info?.city && this.state.data.announcement_new_info?.city) string = `${string} ${this.state.data.announcement_new_info?.city};`
		if (this.state.data.announcement_old_info?.state && this.state.data.announcement_new_info?.state) string = `${string} ${this.state.data.announcement_new_info?.state};`
		if (this.state.data.announcement_old_info?.street && this.state.data.announcement_new_info?.street) string = `${string} ${this.state.data.announcement_new_info?.street};`
		if (this.state.data.announcement_old_info?.neighbourhood && this.state.data.announcement_new_info?.neighbourhood) string = `${string} ${this.state.data.announcement_new_info?.neighbourhood};`
		if (this.state.data.announcement_old_info?.number && this.state.data.announcement_new_info?.number) string = `${string} ${this.state.data.announcement_new_info?.number};`
		if ((this.state.data.announcement_old_info?.complement && this.state.data.announcement_new_info?.complement) || (this.state.data.announcement_old_info?.complement === '' && this.state.data.announcement_new_info?.complement !== '')) string = `${string} ${this.state.data.announcement_new_info?.complement};`

		return string;
	}

	render() {
		// const Asset = ({ titleAsset, textAsset, icon, colorBackground, colorIcon, message, children }) => {
		// 	return (
		// 		<div className={'assetPersonComissao'}>
		// 			<div style={{ backgroundColor: colorBackground ?? '#E1E3F7' }} className={'containerFigure'}>
		// 				<i className={icon} style={{ color: colorIcon ?? '#5664D2' }} />
		// 			</div>

		// 			<div>
		// 				{titleAsset ? <h6>{titleAsset}</h6> : null}

		// 				<h5>{textAsset ? textAsset : children}</h5>

		// 				{message ? <p className={'info'} style={{ marginTop: 8 }}>
		// 					{message}
		// 				</p> : null}
		// 			</div>
		// 		</div>
		// 	)
		// }

		const handleSeeFile = async () => {
			const id = this.state?.data?.id;
			const sign = this.state?.data?.status === 'SIGNED' ? true : false;

			try {
				await getContractualEditFile(id, sign).then((data) => { console.log(data); window.open(data?.request?.responseURL, '_blank') });
			} catch (err) {
				console.log(err);
				this.setState({ errors: ['Houve um erro ao buscar o termo aditivo, entre em contato com o suporte.'] });
			}
		}

		const handleSendNotifications = () => {
			resendContractualEditNotifications(this.props.match.params.id).then(res => {
				if (res?.status === 200) {
					this.setState({ success: [...this.state.success, 'Notificações enviadas com sucesso!'] })
				}
			}).catch(err => {
				this.setState({ errors: [...this.state.errors, 'Houve um erro ao reenviar as notificações...'] })
			})
		}

		const SignButton = ({ user: participant }) => {
			var component = <></>;
			console.log(participant);

			if (participant?.signed) {
				component = (
					<Button color="primary" style={{ backgroundColor: '#1CBB8C', border: 'none' }} className="col-form-label" onClick={() => { navigator.clipboard.writeText(participant?.link); this.setState({ success: [...this.state?.success, 'Link copiado!'] }) }}>
						Aditivo assinado
					</Button>
				)

			} else if (!participant?.signed && participant?.infos?.required_sign) {
				component = (
					<Button color="secondary" className="col-form-label" onClick={() => { navigator.clipboard.writeText(participant?.link); this.setState({ success: [...this.state?.success, 'Link copiado!'] }) }}>
						Link da assinatura
					</Button>
				)
			} else {
				return <></>
			}

			return component;
		}

		return (
			<React.Fragment>
				<BetterCircularProgress loading={this.state.loading}>
					<div className="page-content container-page ContractualEditShow rescisao">
						<Container fluid>
							<Breadcrumbs breadcrumbItems={[{ link: '/contracts/index', title: 'Contrato' }, { link: `/contracts/show.${this.state.data?.entity?.id}`, title: 'Visualizar' }]} />

							<BetterAlerts success={this.state.success} errors={this.state.errors} />

							{this.state.success && this.state.success[0] && this.state.success[0].includes('Edição excluído com sucesso!') ? <Redirect to={{ pathname: `/contracts/show/${this.state?.data?.contract_id}`, state: { success: ['Edição contratual excluída com sucesso!'] } }} /> : null}

							<Card>
								<CardBody>

									<div className="d-flex">
										<div className="p-3" style={{ background: '#F1F5F7', width: '50%', minHeight: '672px', height: '100%', borderRadius: '6px' }}>
											<Col md={12}>
												<Label className="col-form-label mb-2" style={{ color: '#8590A5', fontSize: '16px', fontWeight: '600', fontFamily: 'Inter' }}>Assinatura edição contratual</Label>

												<FormGroup className="w-100 d-flex justify-content-between">
													<div>
														<Label className="col-form-label" style={{ color: '#4AA3FF', fontSize: '13px' }}>Status</Label>
														<p className='mr-auto' style={{ fontSize: '13px', lineHeight: '16px', flexDirection: 'row', alignItems: 'center' }}>{this.getEditStatus(this.state?.data?.status)}</p>
													</div>

												</FormGroup>

												{this.state?.data?.contractual_edit_users.filter((obj) => obj.user_type !== 'witness').map((participant, index) =>
													<FormGroup className="w-100 d-flex justify-content-between">
														<div>
															<Label className="col-form-label" style={{ color: '#4AA3FF', fontSize: '13px' }}>{this.getUserTypeName(participant.user_type)}</Label>
															<p className='mr-auto' style={{ fontSize: '13px', lineHeight: '16px', flexDirection: 'row', alignItems: 'center' }}>{participant?.user?.name ?? participant?.name}</p>
														</div>

														<p className="col-form-label d-flex" style={{ fontSize: '13px', lineHeight: '16px', flexDirection: 'row', alignItems: 'center' }}>
															<SignButton user={participant} user_id={null} />
														</p>

													</FormGroup>
												)}
											</Col>
										</div>

										<div className="p-3 rounded ml-4 mr-4" style={{ background: '#F9F9F9', width: '50%', height: '672px', borderRadius: "10px" }}>
											{this.state?.data ? (
												<Col md={12} className="column contractual-edit" style={{ width: '100%' }}>
													<Label className="col-form-label mb-2" style={{ color: '#8590A5', fontSize: '16px', fontWeight: '600', fontFamily: 'Inter' }}>Edição contratual</Label>

													<FormGroup className="w-100">
														<Label className="col-form-label" style={{ color: '#4AA3FF', fontSize: '13px', paddingBottom: 0 }} >Data máxima para assinatura</Label>

														<p className="col-form-label d-flex" style={{ fontSize: '13px', lineHeight: '16px', flexDirection: 'row', alignItems: 'center' }}>
															<p className='mr-auto'>{moment(this.state.data.sign_expiration_date).format('DD/MM/YYYY')}</p>
														</p>
													</FormGroup>

													{this.state.data.type === 'PAYMENT' ? (
														<div style={{ display: 'flex', marginTop: '22px', width: '100%' }}>
															<div className='box-blue'>
																<img src={cashIcon} alt='icon' />
															</div>
															<div>
																<p className='input-label' style={{ marginBottom: '12px' }}>Pagamentos</p>

																{this.state.data?.contract_old_info?.base_value ? (
																	<div style={{ display: 'flex' }}>
																		<h6 style={{ marginBottom: '0px', display: 'contents' }}>Valor base:</h6>
																		<p className='input-label' style={{ marginLeft: '8px', marginTop: '0px' }}>{formatBRL(this.state.data?.contract_new_info?.new_base_value)}</p>
																	</div>
																) : null}

																{this.state.data?.contract_old_info?.rent_value ? (
																	<div style={{ display: 'flex' }}>
																		<h6 style={{ marginBottom: '0px', display: 'contents' }}>Valor dos próximos aluguéis:</h6>
																		<p className='input-label' style={{ marginLeft: '8px', marginTop: '0px' }}>{formatBRL(this.state.data?.contract_new_info?.next_rent_value)}</p>
																	</div>
																) : null}

																{this.state.data?.contract_old_info?.due_date ? (
																	<div style={{ display: 'flex' }}>
																		<h6 style={{ marginBottom: '0px', display: 'contents' }}>Data de vencimento:</h6>
																		<p className='input-label' style={{ marginLeft: '8px', marginTop: '0px' }}>{moment(this.state.data?.contract_new_info?.new_due_date).format('DD/MM/YYYY')}</p>
																	</div>
																) : null}
															</div>
														</div>
													) : null}

													{this.state.data?.type === 'CONTRACT_DATA' ? (
														<div style={{ display: 'flex', marginTop: '22px', width: '100%' }}>
															<div className='box-blue'>
																<img src={listIcon} alt='icon' />
															</div>
															<div style={{ width: '80%' }}>
																<div style={{ marginBottom: '12px' }}>
																	<p className='input-label'>Dados contratuais</p>
																</div>

																{
																	this.state?.data?.landlord_new_info ?
																		<div style={{ display: 'flex' }}>
																			<h6 style={{ marginBottom: '0px' }}>Dados do responsável:</h6>
																			<p className='input-label' style={{ marginLeft: '8px', marginTop: '0px' }}>{this.getLandlordChages()}</p>
																		</div>
																		: null
																}

																{this.state.data?.tenant_new_info && this.state.data?.tenant_new_info.map((tenant, i) => (
																	<div style={{ display: 'flex' }}>
																		<h6 style={{ marginBottom: '0px', width: '28%', display: 'contents' }}>Dados do inquilino:</h6>
																		<p className='input-label' style={{ marginLeft: '8px', marginTop: '0px' }}>{this.getTenantChanges(tenant)}</p>
																	</div>
																))}

																{this.state?.data?.announcement_new_info ?
																	<div style={{ display: 'flex' }}>
																		<h6 style={{ marginBottom: '0px', width: '25%', display: 'contents' }}>Dados do imóvel:</h6>
																		<p className='input-label' style={{ marginLeft: '8px', marginTop: '0px' }}>{this.getAnnouncementChanges()}</p>
																	</div>
																	: null}
															</div>
														</div>
													) : null}

													{/* {this.state?.witnesses.map((w, i) => (
														<div style={{ display: 'flex', marginTop: '22px', width: '100%' }}>
															<div className='box-blue'>
																<img src={groupIcon} alt='icon' />
															</div>
															<div>
																<p className='input-label' style={{ marginBottom: '12px' }}>Testemunha {i + 1}</p>

																<div style={{ display: 'flex' }}>
																	<h6 style={{ marginBottom: '0px' }}>{w.name}</h6>
																</div>


																<div style={{ display: 'flex' }}>
																	<h6 style={{ marginBottom: '0px' }}>{w.email}</h6>

																</div>


																<div style={{ display: 'flex' }}>
																	<h6 style={{ marginBottom: '0px' }}>{formatTelefone(w.phone)}</h6>
																</div>

																<div style={{ display: 'flex' }}>
																	<h6 style={{ marginBottom: '0px' }}>{formatCPF(w.cpf)}</h6>
																</div>
															</div>
														</div>
													))} */}



												</Col>
											) : null}
										</div>
									</div>

								</CardBody>

								<CardBody className="row">
									<Link to={`/negotiation/show/${this.state?.contract?.proposition_id}`} className="btn btn-secondary btn-bold ml-2 mr-2"><i className="fa fa-arrow-left mr-2"></i>Voltar</Link>

									<>
										<Button color="primary" className="btn btn-primary ml-3" onClick={async (e) => {
											e.preventDefault();
											await handleSeeFile();
										}} >
											Ver aditivo <FontAwesomeIcon icon={faEye} style={{ marginLeft: '8.5px' }} ></FontAwesomeIcon>
										</Button>


										<Button color="primary" className="btn btn-primary ml-3" onClick={async (e) => {
											e.preventDefault();
											await handleSendNotifications();
										}} >
											Reenviar notificações <FontAwesomeIcon icon={faEnvelope} style={{ marginLeft: '8.5px' }} ></FontAwesomeIcon>
										</Button>
									</>


									{this.state?.data?.status === 'EXPIRED' || this.state?.data?.status === 'CANCELED' ? null : (
										<Button color="danger" type="submit" className="waves-effect waves-light ml-3"
											onClick={() => this.handleDeleteContractualEdit()}>
											Cancelar Edição <i className="ri-close-circle-line align-middle ml-2"></i>
										</Button>
									)}


								</CardBody>
							</Card>
						</Container>
					</div>
				</BetterCircularProgress>
			</React.Fragment>
		)
	}
}

export default ContractualEditShow;