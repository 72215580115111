
import axios from "axios";
import { Constants } from '../helpers/Constants';

export function sendSingleWhatsappMessage(phone, data) {
	return axios.post(`${Constants.baseUrl}/whatsapp/template/${phone}`, data);
}

export function getWhatsappTemplates() {
	return axios.get(`${Constants.baseUrl}/whatsapp/workspaces/templates`);
}

export function updateChatAttendant(user_id, roomId) {
	return axios.get(`${Constants.baseUrl}/chat/update-room-attendant/${user_id}?roomId=${roomId}`);
}

export function getChatByPropositionId(propositionId) {
	return axios.get(`${Constants.baseUrl}/chat/rooms/${propositionId}`);
}

export function updateChatStatus(propositionId, data) {
	return axios.patch(`${Constants.baseUrl}/chat/rooms/${propositionId}`, data);
}
