import React, { Component } from 'react';
import { Container, Card, CardBody, Col, FormGroup, Label, Button } from "reactstrap";
import BetterCircularProgress from '../../components/BetterCircularProgress';
import { getRawById } from '../../crud/announcements.crud';
import { get as getUser, update as updateUser } from '../../crud/users.crud';
import { Redirect } from "react-router-dom";
import { Formik } from "formik";
import BetterAlerts from '../../components/BetterAlerts';
import { validateFormCrawler } from '../../helpers/FormValidators';
import { formatTelefoneInput, formatTelefone, limparTelefone } from '../../helpers/Utils';
import { TextField } from "@material-ui/core";
import Breadcrumbs from '../../components/Breadcrumb';
import { updateRaw } from '../../crud/announcements.crud';

class CrawlerEdit extends Component {

	constructor(props) {
		super(props);

		this.goBack = this.goBack.bind(this);
	}

	state = {
		submitted: false,
		success: false,
		errors: [],
		loading: false,
		data: null,
		user: null
	}

	componentDidMount = () => {
		this.setState({ loading: true });

		getRawById(this.props.match.params.id).catch(err => {
			alert('An error occurred, please contact the support.');
		}).then(async res => {
			if (res?.status == 200) {
				if (res.data) {
					this.setState({ data: res.data.data });

					await getUser(res.data.data.user_id).catch(error => {
						alert('An error occurred, please contact the support.');
					}).then(result => {
						if (result?.status == 200) {
							if (result.data) {
								this.setState({ user: result.data.data });
							}
						}
					});
				}
			}

			this.setState({ loading: false });
		});
	};

	goBack() {
		this.props.history.goBack();
	}

	onSubmit = (values) => {
		this.setState(({ submitted: true }));

		updateRaw(this.props.match.params.id, { editable: { landlord_name: values.name, landlord_phone: limparTelefone(values.phone) } });

		updateUser(this.state.user.id, { name: values.name, phone: limparTelefone(values.phone) }).catch((err) => {
			this.setState({ submitted: false, success: false, errors: this.processErrorMessage(err.response.data.message) ? [this.processErrorMessage(err.response.data.message)] : [] });
		}).then(res => {
			if (res?.status == 200) {
				this.setState({ submitted: false, success: !Boolean(res.data.message), errors: this.processErrorMessage(res.data.message) ? [this.processErrorMessage(res.data.message)] : [] })
			}
		});
	};

	processErrorMessage = (message) => {
		switch (message) {
			case 'Duplicated phone':
				return "Número de telefone duplicado.";
			default:
				return message;
		}
	}

	getInitialValues = () => {
		return {
			name: this.state.user?.name ? this.state.user?.name : '',
			phone: this.state.user?.phone ? formatTelefone(this.state.user?.phone) : '',
		};
	};

	render() {
		return (
			<React.Fragment>
				<BetterCircularProgress loading={this.state.loading}>
					<div className="page-content container-page">
						<Container fluid>
							<Breadcrumbs breadcrumbItems={[{ link: '/crawler/index', title: 'Crawler' }, { link: `/crawler/edit/${this.props.match.params.id}`, title: 'Editar' }]} />
							<BetterAlerts errors={this.state.errors} />

							{this.state.success ?
								<Redirect to={{ pathname: `/crawler/show/${this.props.match.params.id}`, state: { success: ['Crawler atualizado com sucesso!'] } }} />
								: null
							}

							<Formik initialValues={this.getInitialValues()} validate={values => validateFormCrawler(values)} onSubmit={(values) => this.onSubmit(values)}>
								{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
									<form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>
										<Card>
											<CardBody>
												<div className="row">
													<Col md={6}>
														<FormGroup row>
															<Label onClick={() => console.log(this.state)} className="col-md-2 col-form-label">Nome do locador</Label>
															<Col md={9} className="container-center-input">
																<TextField name="name" type="text" variant="outlined" value={values.name} onBlur={handleBlur} onChange={handleChange}
																	helperText={touched.name && errors.name} error={Boolean(touched.name && errors.name)} />
															</Col>
														</FormGroup>
													</Col>

													<Col md={6}>
														<FormGroup row>
															<Label className="col-md-2 col-form-label">Telefone do locador</Label>
															<Col md={9} className="container-center-input">
																<TextField name="phone" type="text" variant="outlined" value={values.phone} onBlur={handleBlur} onChange={(e) => handleChange(formatTelefoneInput(e))}
																	helperText={touched.phone && errors.phone} error={Boolean(touched.phone && errors.phone)} />
															</Col>
														</FormGroup>
													</Col>
												</div>
											</CardBody>
										</Card>

										<Card>
											<CardBody className="container-space-between">
												{/* <Link to='/crawler/index' className="btn btn-danger btn-bold"><i className="fa fa-arrow-left mr-2"></i>Voltar</Link> */}
												<Button onClick={this.goBack} color="danger" type="button" className="waves-effect waves-light mr-10">
													<i className={`mr-2 align-middle fa fa-arrow-left `}></i>Voltar
												</Button>

												<Button color="success" type="submit" className="waves-effect waves-light mr-10">
													<i className={`mr-2 align-middle ${this.state.submitted ? "fas fa-sync fa-spin" : "ri-save-3-line"}`}></i>Salvar
												</Button>
											</CardBody>
										</Card>
									</form>
								)}
							</Formik>
						</Container>
					</div>
				</BetterCircularProgress>
			</React.Fragment>
		)
	}
}

export default CrawlerEdit;