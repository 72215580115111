import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Alert } from "reactstrap";

export default class BetterAlerts extends Component {

    render() {
        return (
            <div style={{ width: this.props?.fullWidth ? '100%' : '90%', margin: '0 auto' }}>
                {this.props.errors ?
                    this.props.errors.map((obj, i) => {
                        return <Alert key={i} color="danger">{obj}</Alert>
                    })
                    : null
                }

                {this.props.success ?
                    this.props.success.map((obj, i) => {
                        return <Alert key={i} color="success" style={{ ...this.props.style }}>{this.props.icon ? (<FontAwesomeIcon style={{ marginRight: '10px' }} icon={this.props.icon} />) : null}{obj}</Alert>
                    })
                    : null
                }
            </div>
        );
    }
}