import React, { Component } from 'react';
import { Container, Card, CardBody, Col, FormGroup, Label, Button } from "reactstrap";
import BetterCircularProgress from '../../components/BetterCircularProgress';
import { Link, Redirect } from "react-router-dom";
import { Formik } from "formik";
import BetterAlerts from '../../components/BetterAlerts';
import { validateHsmConfig } from '../../helpers/FormValidators';
import { TextField } from "@material-ui/core";
import Breadcrumbs from '../../components/Breadcrumb';
import { get, store } from '../../crud/hsm.crud';

class HSMConfig extends Component {

	state = {
		submitted: false,
		success: false,
		errors: [],
		loading: false,
		hsm_crawler_proprietarios: null,
		tallos_integration: null,
		template_nao_convertidos: null,
	};

	componentDidMount = async () => {
		this.setState({ loading: true });

		let hsm_crawler_proprietarios = null;
		let tallos_integration = null;
		let template_nao_convertidos = null;

		await get({ type: 'tallos_integration' }).catch(err => {
			console.log(err);
		}).then(res => {
			if (res?.status == 200) {
				tallos_integration = res.data
			} else {
				tallos_integration = {
					type: 'tallos_integration',
					hsm_name: '',
				}
			}
		});

		await get({ type: 'crawler_proprietarios' }).catch(err => {
			console.log(err);
		}).then(res => {
			if (res?.status == 200) {
				hsm_crawler_proprietarios = res.data;
			} else {
				hsm_crawler_proprietarios = {
					type: 'crawler_proprietarios',
					hsm_name: '',
				}
			}
		});

		await get({ type: 'template_nao_convertidos' }).catch(err => {
			console.log(err);
		}).then(res => {
			if (res?.status == 200) {
				template_nao_convertidos = res.data;
			} else {
				template_nao_convertidos = {
					type: 'template_nao_convertidos',
					hsm_name: '',
				}
			}
		});

		this.setState({ loading: false, hsm_crawler_proprietarios, tallos_integration, template_nao_convertidos });
	};

	getInitialValues = () => {
		return {
			hsm_name: this.state?.hsm_crawler_proprietarios?.hsm_name ?? '',
			tallos_integration: this.state?.tallos_integration?.hsm_name ?? '',
			template_nao_convertidos: this.state?.template_nao_convertidos?.hsm_name ?? '',
		};
	};

	onSubmit = async (values) => {
		this.setState({ submitted: true });

		let error = null;

		if (values.hsm_name != this.state.hsm_crawler_proprietarios.hsm_name) {
			await store({ type: 'crawler_proprietarios', hsm_name: values.hsm_name }).catch(err => {
				console.log(err);
			}).then(res => {
				if (res?.status == 200) {
					error = res.data.error;
				}
			});
		}

		if (values.tallos_integration != this.state.tallos_integration.hsm_name) {
			await store({ type: 'tallos_integration', hsm_name: values.tallos_integration }).catch(err => {
				console.log(err);
			}).then(res => {
				if (res?.status == 200) {
					if (res.data.error) error = res.data.error;
				}
			});
		}

		if (values.template_nao_convertidos != this.state.template_nao_convertidos.hsm_name) {
			await store({ type: 'template_nao_convertidos', hsm_name: values.template_nao_convertidos }).catch(err => {
				console.log(err);
			}).then(res => {
				if (res?.status == 200) {
					if (res.data.error) error = res.data.error;
				}
			});
		}

		this.setState({ loading: false, submitted: false, success: !Boolean(error), errors: error ? [error] : [] });
	};

	render() {
		return (
			<React.Fragment>
				<BetterCircularProgress loading={this.state.loading}>
					<div className="page-content container-page">
						<Container fluid >
							<Breadcrumbs title="Configurar HSM" breadcrumbItems={[]} />
							<BetterAlerts errors={this.state.errors} />

							{this.state.success ?
								<Redirect to={{ pathname: '/crawlerConfig/index', state: { success: ['HSM alterado com sucesso!'] } }} />
								: null
							}

							<Formik initialValues={this.getInitialValues()} validate={values => validateHsmConfig(values)} onSubmit={(values) => this.onSubmit(values)}>
								{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
									<form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>
										<Card>
											<CardBody>
												<div className="row">
													<Col md={12}>
														<FormGroup row>
															<Label className="col-md-12 col-form-label">HSM Crawler proprietários *</Label>
															<Col md={12} className="container-center-input">
																<TextField name="hsm_name" type="text" variant="outlined" value={values.hsm_name} onBlur={handleBlur} onChange={handleChange}
																	helperText={touched.hsm_name && errors.hsm_name} error={Boolean(touched.hsm_name && errors.hsm_name)} />
															</Col>
														</FormGroup>
													</Col>

													<Col md={12}>
														<FormGroup row>
															<Label className="col-md-12 col-form-label">Tallos integration *</Label>
															<Col md={12} className="container-center-input">
																<TextField name="tallos_integration" type="text" variant="outlined" value={values.tallos_integration} onBlur={handleBlur} onChange={handleChange}
																	helperText={touched.tallos_integration && errors.tallos_integration} error={Boolean(touched.tallos_integration && errors.tallos_integration)} />
															</Col>
														</FormGroup>
													</Col>

													<Col md={12}>
														<FormGroup row>
															<Label className="col-md-12 col-form-label">Template não convertidos *</Label>
															<Col md={12} className="container-center-input">
																<TextField name="template_nao_convertidos" type="text" variant="outlined" value={values.template_nao_convertidos} onBlur={handleBlur} onChange={handleChange}
																	helperText={touched.template_nao_convertidos && errors.template_nao_convertidos} error={Boolean(touched.template_nao_convertidos && errors.template_nao_convertidos)} />
															</Col>
														</FormGroup>
													</Col>
												</div>
											</CardBody>
										</Card>

										<Card>
											<CardBody className="container-space-between">
												<Link to='/crawlerConfig/index' className="btn btn-danger btn-bold"><i className="fa fa-arrow-left mr-2"></i>Voltar</Link>

												<Button color="success" type="submit" className="waves-effect waves-light mr-10">
													<i className={`mr-2 align-middle ${this.state.submitted ? "fas fa-sync fa-spin" : "ri-save-3-line"}`}></i>Salvar
												</Button>
											</CardBody>
										</Card>
									</form>
								)}
							</Formik>
						</Container>
					</div>
				</BetterCircularProgress>
			</React.Fragment>
		);
	}
}

export default HSMConfig;