import React, { Component } from 'react';
import { Col, FormGroup, Label } from "reactstrap";
import { FormHelperText, TextField } from "@material-ui/core";
import { getByFilter } from "../../../crud/users.crud";
import ListBoxComponent from './list-box.component';
import { userFilter } from '../helpers';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Button } from 'reactstrap';

class Basis extends Component {

	state = {
		timeout: null,
		list_users: [],
		list_users_total: 0,
		term_user: ""
	};

	findUser = async (term, skip = 0) => {
		const { data } = await getByFilter({
			$skip: skip,
			$sort: { name: 1, email: 1 },
			name_email_or: term,
		});
		return data;
	};

	findUserLoadMore = () => {
		const skip = this.state.list_users.length;
		if (skip >= this.state.list_users_total) return;
		this.findUser(this.state.term_user, skip).then(({ data, total }) => {
			this.setState({ list_users: [...this.state.list_users, ...data], list_users_total: total });
		});
	};

	onFindUSerInputChange = (term) => {
		if (this.timeout) clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			if (term?.trim() === "") {
				return this.setState({ list_users: [], list_users_skip: 0 });
			} else {
				return this.findUser(term).then(({ data, total }) => {
					return this.setState({ list_users: data, term_user: term, list_users_total: total });
				});
			}
		}, 500);
	};
}

export class UserComponent extends Basis {

	render() {
		const {
			onBlur,
			onChange,
			value,
			name,
			label,
			error,
			onError,
			disabled,
			layout
		} = this.props;

		return (

			<React.Fragment>
				{layout === 'row' ? (
					<Col md={12}>
						<FormGroup className="w-100">
							{label ?
								<Label className="col-form-label" style={{ color: "#343A40" }}>
									{label}
								</Label>
								: null
							}
							<Autocomplete disabled={disabled} name={name} className="mb-2" style={{ background: "#FFF", height: "40px" }}
								disableClearable noOptionsText="No results." variant="outlined" clearOnBlur={false} onInputChange={(event, term) => this.onFindUSerInputChange(term)}
								onChange={onChange} getOptionSelected={(option, value) => option.id === value.id} value={value}
								onBlur={onBlur} options={this.state.list_users} defaultValue={value ?? undefined}
								getOptionLabel={(option) => option?.name ?? ""} ListboxComponent={ListBoxComponent(this.findUserLoadMore)}
								filterOptions={userFilter} renderInput={(params) => {
									return (<TextField {...params} type="text" placeholder="Nome ou email..." variant="outlined" />);
								}} />

							{error ?
								<FormHelperText className="ml-3" error>{onError}</FormHelperText> : null
							}
						</FormGroup>
					</Col>
				) : null}

				{!layout ? (
					<Col md={12}>
						<FormGroup className="w-100">
							{label ?
								<Label className="col-form-label" style={{ color: "#343A40" }}>
									{label}
								</Label>
								: null
							}
							<Autocomplete
								disabled={disabled}
								name={name}
								className="mb-2"
								style={{ background: "#FFF", height: "40px" }}
								disableClearable
								noOptionsText="No results."
								variant="outlined"
								clearOnBlur={false}
								onInputChange={(event, term) => this.onFindUSerInputChange(term)}
								onChange={onChange}
								getOptionSelected={(option, value) => option.id === value.id}
								onBlur={onBlur}
								options={this.state.list_users}
								defaultValue={value ?? undefined}
								value={value}
								getOptionLabel={(option) => option?.name ?? ""}
								ListboxComponent={ListBoxComponent(this.findUserLoadMore)}
								filterOptions={userFilter}
								renderInput={(params) => {
									return (
										<TextField
											{...params}
											type="text"
											placeholder="Nome ou email..."
											variant="outlined"
										/>
									);
								}}
							/>
							{error ?
								<FormHelperText className="ml-3" error>{onError}</FormHelperText> : null
							}
						</FormGroup>
					</Col>
				) : null}

			</React.Fragment>
		);
	}
}

export class UserFilterComponent extends Basis {

	render() {
		const { onChange, value } = this.props;

		return (
			<React.Fragment>
				<Autocomplete style={{ width: '326px', ...this?.props?.style }} disableClearable noOptionsText="No results."
					variant="outlined" clearOnBlur={false} onInputChange={(event, term) => this.onFindUSerInputChange(term)}
					onChange={onChange} getOptionSelected={(option, value) => option.id === value.id} options={this.state.list_users}
					defaultValue={value ?? undefined} value={value} getOptionLabel={(option) => option?.name ?? ""}
					ListboxComponent={ListBoxComponent(this.findUserLoadMore)} filterOptions={userFilter} renderInput={(params) => {
						return (
							<TextField {...params} type="text" placeholder={this?.props?.placeholder ?? "Nome ou email..."} variant="outlined" />
						);
					}}
				/>
			</React.Fragment>
		);
	}
}

export class UserComponentDefault extends Basis {

	render() {
		const {
			onBlur,
			onChange,
			value,
			name,
			label,
			error,
			onError,
			disabled,
			layout
		} = this.props;

		return (
			<React.Fragment>
				<FormGroup className="w-100">
					{label ?
						<Label className="col-form-label" style={{ color: "#343A40" }}>
							{label}
						</Label>
						: null
					}

					<Autocomplete
						disabled={disabled}
						name={name}
						className="mb-2"
						style={{ background: "#FFF", height: "40px" }}
						disableClearable
						noOptionsText="No results."
						variant="outlined"
						clearOnBlur={false}
						onInputChange={(event, term) => this.onFindUSerInputChange(term)}
						onChange={onChange}
						getOptionSelected={(option, value) => option.id === value.id}
						onBlur={onBlur}
						options={this.state.list_users}
						defaultValue={value ?? undefined}
						value={value}
						getOptionLabel={(option) => option?.name ?? ""}
						ListboxComponent={ListBoxComponent(this.findUserLoadMore)}
						filterOptions={userFilter}
						renderInput={(params) => {
							return (
								<TextField
									{...params}
									type="text"
									placeholder="Nome ou email..."
									variant="outlined"
								/>
							);
						}}
					/>
					{error ?
						<FormHelperText className="ml-3" error>{onError}</FormHelperText> : null
					}
				</FormGroup>
			</React.Fragment>
		);
	}
}

export class UserFilterComponentObsolete extends Basis {
	render() {
		const { onChange, value, onClick, label, layout } = this.props;

		return (
			<React.Fragment>
				{layout === 'row' ? (
					<Autocomplete freeSolo disableClearable noOptionsText="No results." variant="outlined" style={{ width: 400, height: 36.23, marginLeft: 12 }}
						clearOnBlur={false} onInputChange={(event, term) => this.onFindUSerInputChange(term)}
						onChange={onChange} getOptionSelected={(option, value) => option.id === value.id}
						options={this.state.list_users} defaultValue={value ?? undefined}
						value={value} getOptionLabel={(option) => option?.name ?? ""}
						ListboxComponent={ListBoxComponent(this.findUserLoadMore)}
						filterOptions={userFilter} renderInput={(params) =>
							<TextField style={{ width: 400, height: 36.23, marginLeft: 12 }}  {...params} type="text" placeholder="Pesquisar por usuário" variant="outlined" />
						} />
				) : null}

				{!layout ? (
					<Col md={4}>
						<Label className="col-form-label" style={{ color: "#343A40" }}>
							{label}
						</Label>
						<FormGroup row>
							<Col>
								<Autocomplete freeSolo disableClearable noOptionsText="No results." variant="outlined" clearOnBlur={false}
									onInputChange={(event, term) => this.onFindUSerInputChange(term)} onChange={onChange}
									getOptionSelected={(option, value) => option.id === value.id} options={this.state.list_users}
									defaultValue={value ?? undefined} value={value} getOptionLabel={(option) => option?.name ?? ""}
									ListboxComponent={ListBoxComponent(this.findUserLoadMore)} filterOptions={userFilter}
									renderInput={(params) => {
										return (<TextField {...params} type="text" placeholder="Nome ou email..." variant="outlined" />);
									}}
								/>
							</Col>

							<Button onClick={onClick} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
								<i className="ri-close-circle-line align-middle"></i>
							</Button>
						</FormGroup>
					</Col>
				) : null}

			</React.Fragment>
		);
	}
}