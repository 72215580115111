import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatBRL } from "../../helpers/Utils";
import moment from "moment";
import smile from '../../assets/images/icons-custom/face-smile.png';

export default class DataTableListMonthlyBonus extends Component {
	constructor() {
		super();

		this.state = {
			property: {},
			data: null,
			rows: 10,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: 'id',
			sortOrder: 1,
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters !== this.props.filters) {
			this.fetchData();
		}
	}

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = async (event) => {
		this.setState({
			loading: true,

		});
		if (event.data) {
			event.data = null;
		}

		this.setState({
			...this.state,
			rows: event.rows,
		});
		this.fetchData(event.rows, event.first);
	};

	fetchData = async (limit, skip) => {
		this.setState({ loading: true });

		let options = {
			$limit: limit ? limit : this.state.rows,
			$skip: skip != null ? skip : this.state.first,
			$sort: { [this.state.sortField]: this.state.sortOrder },
			gratification_id: this.props.gratification_id,
		};

		return this.props.fetchEvent(options).then(async (res) => {
			// this.props.updateCounter(res?.data?.total);

			if (res.data.data.length == 0) {
				this.setState({
					...this.state,
					data: [],
					totalRecords: res.data.total,
					first: skip,
					last: skip + res.data.limit,
					loading: false
				});
			}
			this.setState({
				...this.state,
				data: res.data.data,
				totalRecords: res.data.total,
				first: skip,
				last: skip + res.data.limit,
				loading: false
			});
		}).finally(() => {
			this.setState({ loading: false });
		});
	};

	sortTable = async (e) => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder, loading: true }, () => {
			let monthlyBonusDataTable = {
				rows: this.state.rows,
				totalRecords: this.state.totalRecords,
				first: this.state.first,
				last: this.state.last,
				loading: this.state.loading,
				sortField: this.state.sortField,
				sortOrder: this.state.sortOrder,
				data: this.state.data
			};

			this.fetchData();
		});
	};


	getDateFormatted = (date) => {
		return (
			<p>
				{date ? moment(date).add(3, 'hours').format("DD/MM/YYYY") : '--'}
			</p>
		);
	};

	export() {
		this.dt.exportCSV();
	}

	emptyMessageReturn() {
		return (
			<div className="boxImage">
				<img src={smile} alt="smile" width={48} height={48} />

				<p className="subtitleNoItems">Nenhum registro encontrado</p>
			</div>
		)
	}

	render() {
		return (
			<div style={{ position: 'relative' }}>
				<DataTable ref={(el) => this.dt = el} value={this.state.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={[10, 15, 30]} first={this.state.first} last={this.state.last}
					totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} responsive={true} loadingIcon={`fas fa-sync fa-spin`} onSort={(e) => this.sortTable(e)}
					paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" emptyMessage={this.emptyMessageReturn}
					currentPageReportTemplate="" sortField={this.state.sortField} sortOrder={this.state.sortOrder} pageLinkSize={3} id="custom-table-2">

					<Column field="payment_month" sortable body={(obj) => moment(obj.payment_month).format('MM/YYYY')} header="Mês" style={{ width: '95px', padding: '22px 16px 22px 16px' }} />

					<Column field="new_contracts" sortable body={(obj) => obj.new_contracts} header="Novos contratos" style={{ width: '144px', padding: '22px 16px 22px 16px' }} />

					<Column field="commission" sortable body={(obj) => formatBRL(obj.commission)} header="Gratificações" style={{ width: '633px', padding: '22px 16px 22px 16px' }} />

					<Column field="transfered_at" sortable body={(obj) => this.getDateFormatted(obj?.transfered_at)} header="Transferência" style={{ width: '128px', padding: '22px 16px 22px 16px' }} />

				</DataTable>

				<div className="paginator-new-items">
					<p>Registros por página</p>
					{/* <Link className="custom-see-all-table" to={`/pagamento/cobrancas/index`} >
						Ver todos
					</Link> */}
				</div>
			</div>
		);
	}
}
