import React, { Component } from 'react';
import { Container, Card, CardBody, Col, FormGroup, Label, Button, TabPane, Input, Row } from "reactstrap";
import BetterCircularProgress from '../../components/BetterCircularProgress';
import { Link, Redirect, useSearchParams } from "react-router-dom";
import moment from 'moment';
import { getById, listRepayments, resendEmail, update, getBoleto, getSplitData, getSecBillings } from '../../crud/billings.crud';
import { formatBRL, formatBRLInput, formatBRLNoSign, limparMoeda } from '../../helpers/Utils';
import Breadcrumbs from '../../components/Breadcrumb';
import 'moment/locale/pt-br';
import BetterAlerts from '../../components/BetterAlerts';
import Swal from 'sweetalert2';
import { getFalhaPagamento, updateToInsured } from "../../crud/billings.crud";
import { create as createNegativacao, get as getNegativacao } from "../../crud/negativacao.crud";
import { get as getContract, getContractUsers } from '../../crud/contracts.crud';
import { get as getUser } from '../../crud/users.crud';
import { get as getAllBillingsParams, getTotalAdmin } from '../../crud/billings.crud';
import DataTableContractsPayment from '../../components/datatables/DataTableContractsPayment';
import { getFriendlyAgreement } from '../../crud/billings.crud';
import { query as queryTermination } from '../../crud/termination.crud';
import DataTableComissionados from '../../components/datatables/cobranca/DataTableComissionados';
import { faArrowLeft, faCheck, faCheckToSlot, faCircleCheck, faFileArrowDown, faFileCirclePlus, faMoneyCheckDollarPen, faPen, faSpinner, faUser, faXmarkToSlot } from '@fortawesome/pro-regular-svg-icons';
import { faUser as faUserSolid } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getUsersWithLaudoStatus } from '../../crud/propositions.crud';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import { validateFormBilling } from '../../helpers/FormValidators';
import { TextField } from '@material-ui/core';
import { includes } from 'lodash';

moment.locale('pt-br');

class BillingShow extends Component {

	state = {
		loading: false,
		data: null,
		landlord: null,
		tenant: null,
		repayments: [],
		success: [],
		errors: [],
		transfer: null,
		paid_by: null,
		authUser: null,
		plan_percentage: null,
		contract: {},
		diasUsufruidos: 0,
		mesesMultaRescisoria: 0,
		participantUsers: [],
		statusTipoParcelamento: '',
		friendlyAgreement: [],
		recebedores: [],
		statusTipoParcelamento: '',
		boleto: [],
		termination: null,
		agreeModal: false,
		showModalReenviar: false,
		showModalPago: false,
		showModalGarantido: false,
		showModalEditar: false,
		loadingBaixarBoleto: false,
		secBilling: null,
	}

	componentDidMount = async () => {
		await this.handleGetBill();
		const searchParams = new URLSearchParams(document.location.search);
		let transferId = searchParams.get('transfer');
		if (transferId) {
			this.handleFindTransfer(transferId);
		}
		const authUser = JSON.parse(localStorage.getItem("authUser"));
		this.setState({ authUser: authUser });
		this.handleFindBoleto();
	};

	componentDidUpdate = (prevProps) => {
		if (this.props.match.params.id !== prevProps.match.params.id) {
			this.handleGetBill();
			window.scrollTo(0, 0);
		}
	}

	handleFindBoleto = () => {
		let params = {
			billing_id: this.props.match.params.id
		}

		getBoleto(params).then(({ data: { data: boleto } }) => {
			if (boleto) {
				this.setState({ boleto: boleto?.data });
			}
		}).catch((err) => {
			console.log(err)
		})
	}

	handleFindTransfer = async (transferId) => {
		this.setState({ loading: true });

		await getFalhaPagamento({ id: transferId }).then(({ data: { data: falhaPagamento } }) => {
			if (falhaPagamento?.length > 0) {
				this.setState({ transfer: falhaPagamento[0] });
			}

		});

		this.setState({ loading: false });
	}

	handleGetBill = async () => {
		this.setState({ loading: true, showModalPago: false });

		try {
			const { data: { data: billing } } = await getById(this.props.match.params.id);

			let contractVar;
			const { data: { data: contract } } = await getContract(billing?.contract_id);
			this.setState({ contract });
			contractVar = contract?.entity;

			await getUser(billing?.user_id).catch((err) => console.log(err)).then(({ data: { data: userBilling } }) => {
				if (userBilling) this.setState({ userBilling });
			});

			await getUser(billing?.landlord_id).catch((err) => console.log(err)).then(({ data: { data: landlordBilling } }) => {
				if (landlordBilling) this.setState({ landlordBilling });
			});

			if (billing.type == 'termination') {
				const terminations = await getAllBillingsParams({ contract_id: billing?.contract_id, type: 'termination' });

				let terminationsBillings = [];

				if (terminations?.status === 200) {
					await terminations.data.data.map((billingTermination, index) => {
						terminationsBillings.push({
							...billingTermination,
							numberTermination: index + 1,
							totalTerminationBillings: terminations.data.total
						})

						if (billingTermination.id == billing?.id) {
							billing.terminationData = {
								number: index + 1,
								total: terminations.data.total,
							}
						}
					})
				}

				this.setState({ terminationsBillings })
			}

			if (billing.receipts?.rent?.paid_for_admin_user) {
				await getUser(billing.receipts?.rent?.paid_for_admin_user).catch((err) => console.log(err)).then(({ data: { data: admin } }) => {
					if (admin) this.setState({ adminPayment: admin });
				});
			}

			if (contractVar) {
				this.setState({ plan_percentage: contractVar?.plan?.percentage });

				const usersWithLaudoStatusRes = await getUsersWithLaudoStatus(contractVar?.proposition_id);
				this.setState({ participantUsers: usersWithLaudoStatusRes.data });

				const tenantPromise = getUser(contractVar?.tenant_id).then(({ data: { data: tenant } }) => {
					if (tenant) this.setState({ tenant });
				});

				const landlordPromise = getUser(contractVar?.landlord_id).then(({ data: { data: landlord } }) => {
					if (landlord) this.setState({ landlord });
				});

				const friendlyAgreementPromise = getFriendlyAgreement({ contract_id: contractVar.id }).then(({ data: { data: friendlyAgreement } }) => {
					if (friendlyAgreement) {
						const sortedFriendlyAgreements = friendlyAgreement?.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
						this.setState({ friendlyAgreement: sortedFriendlyAgreements });
					}
				});

				await Promise.all([tenantPromise, landlordPromise, friendlyAgreementPromise]);
			}

			if (billing.values.start_value !== billing.values.current_value) {
				billing.juros = billing.values.current_value - billing.values.start_value;
			}

			if (billing?.credit_negative?.status_tipo_parcelamento) {
				this.setState({ statusTipoParcelamento: billing?.credit_negative?.status_tipo_parcelamento });
			}

			if (billing?.receipts?.rent?.payment_warranted_by_admin) {
				const { data: { data: pagador } } = await getUser(billing?.receipts?.rent?.insurance_paid_by);
				if (pagador) this.setState({ paid_by: pagador });
			}

			if (billing?.receipts?.rent?.paid_for_admin) {
				const { data: { data: pagador } } = await getUser(billing?.receipts?.rent?.paid_for_admin_user);
				if (pagador) this.setState({ paid_by: pagador });
			}

			if (billing?.type === "termination" || billing?.type === "warranty_refund") {
				const mesesMultaRescisoria = moment(contractVar?.details?.start_date, 'MM/DD/YYYY').add(contractVar?.details?.duration, 'months').diff(moment(contractVar?.terminated_at, 'YYYY-MM-DD'), 'months');
				this.setState({ mesesMultaRescisoria });

				const res = await queryTermination({ contract_id: billing?.contract_id, $sort: { created_at: -1 } });
				if (res?.status === 200) {
					this.setState({ termination: res.data.data[0] });
				}
			}

			const { data: { data: repayments } } = await listRepayments({ billing_id: this.props.match.params.id });
			this.setState({ repayments });

			let acrescimo = 0;
			let desconto = 0;

			const amountTotal = repayments.reduce((total, { status, type, amount }) => {
				if (type == "addition") {
					acrescimo += Number(amount);
				}

				if (type == 'repayment') {
					desconto += Number(amount);
				}

				if (status === "accepted") {
					return type === "addition" ? total + Number(amount) : total - Number(amount);
				}

				return total;
			}, 0);

			if (billing?.receipts?.rent?.value) {
				billing.receipts.rent.value += amountTotal;
				billing.receipts.totalReembolso = acrescimo - desconto;
				billing.receipts.acrescimo = acrescimo;
				billing.receipts.desconto = desconto;
			}

			if (billing?.securitized) {
				await getSecBillings({ billing_id: billing.id, $sort: { ['updated_at']: '-1' } }).then(({ data, status }) => {
					if (status === 200) {
						this.setState({ secBilling: data?.data[0] });
					}
				});
			}

			this.setState({ data: billing });
		} catch (error) {
			console.error('Error fetching billing information:', error);
		} finally {
			this.setState({ loading: false });
		}

		if (this.props.location.state?.success) {
			this.setState({ success: this.props.location.state.success });
		}
	};

	formatDate(date) {
		return moment(date).utcOffset(+3).format('DD/MMMM/YYYY');
	}

	getStatus = (obj) => {
		const momentDate = moment(obj?.due_date).add(3, 'hours');
		const daysToAdd = momentDate.day() === 6 ? 2 : momentDate.day() === 0 ? 1 : 0;

		const agreementStatus = () => {
			const isBillingOverdue = this.state?.friendlyAgreement?.some((agreement =>
				agreement?.overdue_billings?.includes(obj?.id)
			));

			return isBillingOverdue;
		}

		if (agreementStatus()) {
			return (
				<div className="tag" style={{ backgroundColor: obj?.receipts?.rent?.paid ? '#1CBB8C' : '#4AA3FF' }}>
					{obj?.receipts?.rent?.paid && obj?.receipts?.rent?.agreement_settled ? 'Acordo quitado' : 'Em acordo'}
				</div>
			)
		}

		if (obj?.type === 'monthly' && obj?.termination_id) {
			return (
				<div className="tag" style={{ backgroundColor: '#5664D2' }}>
					Acerto de rescisão
				</div>
			)
		}

		if (obj?.receipts?.rent?.paid === true && (obj?.receipts?.rent?.paid_for_insurer === false || !obj?.receipts?.rent?.paid_for_insurer)) {
			return (
				<div className="tag" style={{ backgroundColor: '#1CBB8C' }}>
					Pago
				</div>
			);
		} else if (obj?.receipts?.rent?.paid_for_insurer == true && obj?.receipts?.rent?.paid == true) {
			return (
				<div className='d-flex' style={{ gap: 4 }}>
					<div className="tag" style={{ backgroundColor: '#1CBB8C' }}>
						Pago
					</div>

					<div className="tag" style={{ backgroundColor: '#4AA3FF' }}>
						Garantido
					</div>
				</div>
			);
		} else if (obj?.receipts?.rent?.paid_for_insurer == true && obj?.receipts?.rent?.paid == false) {
			return (
				<div className='d-flex' style={{ gap: 4 }}>
					<div className="tag" style={{ backgroundColor: '#FF3D60' }}>
						Atrasado
					</div>

					<div className="tag" style={{ backgroundColor: '#4AA3FF' }}>
						Garantido
					</div>
				</div>
			);
		} else if (moment().isSameOrBefore(obj?.due_date, 'day') && obj?.boleto) {
			return (
				<div className="tag" style={{ backgroundColor: '#FCB92C' }}>
					Em aberto
				</div>
			);
		} else if (moment().isAfter(moment(obj?.due_date).add(1, 'd'), 'day')) {
			return (
				<div className="tag" style={{ backgroundColor: '#FF3D60' }}>
					Atrasado
				</div>
			);
		} else {
			return (
				<div className="tag" style={{ backgroundColor: '#8590A5' }}>
					Aguardando
				</div>
			);
		}
	};

	isWaiting = (obj) => {
		const dueDate = moment(obj?.due_date).format('YYYYMMDD');
		const currentDate = moment().add(1, 'day').format('YYYYMMDD');

		return obj?.receipts?.rent?.paid === true ? 'paga' : (currentDate > dueDate) ? 'atrasada' : !!obj?.boleto?.pdf_url ? 'aberto' : 'Aguardando'
	}

	handlePayBill = () => {
		update(this.props.match.params.id, { paid: true, paid_for_admin: true }).then(() => {
			this.handleGetBill();
		}).catch((err) => {
			this.setState({ errors: ['Houve um erro ao tentar marcar como pago!', ...this.state.errors] });
			console.log(err);
		})
	}

	handlePayWarranty = () => {
		updateToInsured(this.props.match.params.id).then(() => {
			this.handleGetBill();
			this.handleClose();
		}).catch((err) => {
			this.setState({ errors: ['Houve um erro ao tentar marcar como garantido!', ...this.state.errors] });
			console.log(err);
		})
	}

	handleResendEmail = () => {
		this.setState({ showModalReenviar: false });

		let obj = this.state?.data;
		const dueDate = moment(obj?.due_date).format('YYYYMMDD');
		const currentDate = moment().add(1, 'day').format('YYYYMMDD');

		let body = {
			id: this.props.match.params.id,
			contract_id: this.state.data.contract_id,
		}

		if ((obj?.receipts?.rent?.paid_for_insurer === true && obj?.receipts?.rent?.paid === false) || (currentDate > dueDate)) {
			//garantido e atrasado
			body.type_email = 'overdue'
		} else {
			// if (!!obj?.boleto?.pdf_url)
			//aberto
			body.type_email = 'duedate'
		}

		resendEmail(body).then(() => {
			this.setState({ success: ['Email da cobrança enviado com sucesso!', ...this.state.success] })
		}).catch(() => {
			this.setState({ errors: ['Houve um erro ao tentar enviar o email de cobrança!', ...this.state.errors] })
		})
	}

	handleSeeNegativation = () => {
		if (this.state?.data?.credit_negative?.negative) {
			getNegativacao(this.props.match.params.id).catch(err => {
				alert('Ocorreu um erro, entre em contato com o suporte.');
			}).then(({ data, status }) => {
				if (status === 200) {
					if (data) {
						window.location.href = `/pagamento/negativacao/show/${this.props.match.params.id}/${data?.data?.creditNegative?.id}`;
					}
				}
			}).finally(() => {
				this.setState({ loading: false });
			});
		} else {
			Swal.fire({
				customClass: { title: 'custom-title', confirmButton: 'custom-confirm', container: 'custom-container' },
				title: "Tem certeza que deseja negativar a cobrança?",
				text: `Depois que o pagamento é negativado não é possível voltar para o status anterior.`,
				showCancelButton: true,
				confirmButtonColor: '#F1F5F7',
				cancelButtonColor: '#5664D2',
				cancelButtonText: "Voltar",
				confirmButtonText: "Negativar",
			}).then((result) => {
				if (result.value) {
					createNegativacao({ billing_id: this.props.match.params.id }).then(() => {
						this.setState({ success: ['Negativação iniciada com sucesso!', ...this.state.success] })
						this.handleGetBill();
					}).catch((err) => {
						this.setState({ errors: [...this.state.errors, err.response.data.message] })
					})
				}
			});
		}
	}

	formatIndice = (indice) => {
		if (indice) {
			indice = indice[indice.length - 1].readjustment_indice

			if (indice === 'igp_m') {
				return 'IGP-M'
			}
			if (indice === 'ipca') {
				return 'IPCA'
			}
			if (indice === 'common_agreement') {
				return 'Comum acordo'
			}
		}
	}

	submitTipoParcelamento = (e) => {
		this.setState({ statusTipoParcelamento: e.target.value });

		let body = {
			credit_negative: {
				status_tipo_parcelamento: e.target.value
			}
		}

		update(this.props.match.params.id, body).then(() => {
			this.handleGetBill();
		}).catch((err) => {
			this.setState({ errors: ['Houve um erro ao tentar atualizar o tipo de pagamento!', ...this.state.errors] });
		})
	};

	getBillingType = (billing) => {
		const type = billing.type;
		if (billing?.receipts?.rent?.payment_for_tenant) {
			return 'Devolução da caução'
		} else if (type === 'termination') {
			return 'Multa rescisória'
		} else if (type === 'agreement') {
			return `Acordo 01 Parcela ${billing?.number}`
		} else {
			return this.formatDate(this.state?.data?.due_date)
		}
	}

	getStatusString = () => {
		const obj = this.state?.data;
		const dueDate = moment(obj?.due_date).add(3, "hours").format('YYYYMMDD');
		const currentDate = moment().format('YYYYMMDD');
		const rent = obj?.receipts?.rent;
		let status = '';

		if (rent?.paid === true && (rent?.paid_for_insurer === false || !rent?.paid_for_insurer)) {
			status = 'pago'
		} else if (rent?.paid_for_insurer === true && rent?.paid === true) {
			status = 'garantido e pago'
		} else if (rent?.paid_for_insurer === true && rent?.paid === false) {
			status = 'garantido e atrasado'
		} else if (currentDate > dueDate) {
			status = 'atrasada'
		} else if (!!obj?.boleto?.pdf_url) {
			status = 'aberto'
		} else {
			status = 'aguardando'
		}

		if ((this.state?.friendlyAgreement[0] && this.state?.friendlyAgreement[0].overdue_billings.includes(obj?.id)) || rent?.agreement_settled) {
			status = rent?.paid && obj?.receipts?.rent?.agreement_settled ? 'Acordo quitado' : 'Acordo vigente'
		}

		if (obj?.type == 'monthly' && obj?.termination_id) {
			status = 'Acerto de rescisão'
		}

		return status;
	};

	calcContractPercentage = (contract) => {
		const startDate = moment(contract?.details?.end_date, 'MM/DD/YYYY')
		const today = moment()

		var months = startDate.diff(today, 'months');
		today.add(months, 'months');

		var days = startDate.diff(today, 'days');

		const start_date = contract?.details?.start_date;
		const end_date = contract?.details?.end_date;
		const duration = contract?.details?.duration;

		const hoje = moment(new Date());
		const inicio = moment(start_date, 'MM/DD/YYYY');
		const fim = moment(end_date, 'MM/DD/YYYY');

		let mesesFaltando = moment(fim).diff(hoje, 'M');
		let porcentagem = Math.round((mesesFaltando / duration) * 100)

		if (Math.sign(mesesFaltando) < 0) {
			mesesFaltando = 0;
		}

		if (porcentagem < 0) {
			porcentagem = 100;
		}

		if (months < 0 && days < 0) {
			return (
				<div className="box-purple">
					{porcentagem}%
				</div>
			)
		}


		return (
			<div className="box-purple">
				{months}m {days}d {porcentagem}%
			</div>
		)
	}

	getWarranty = (key, plan = {}) => {
		let garantia = '';

		if (key == 'FREE') {
			garantia = 'Grátis';
		} else if (key == 'WARRANTY') {
			garantia = 'Pagamento';
		} else if (key == 'WARRANTY_GUARANTEE_DAMAGE') {
			garantia = 'Completa';
		} else {
			garantia = 'Não identificado';
		}

		return (
			<div className='d-flex'>
				{garantia}

				{plan?.percentage ?
					<div className="tag" style={{ backgroundColor: '#5664D2', marginLeft: 8 }}>
						{plan.percentage}%
					</div>
					: null}
			</div>
		)
	};

	handleSetRecebedores = (recebedores) => {
		this.setState({ recebedores })
	}

	downloadPDF = async (url, filename) => {
		this.setState({ loadingBaixarBoleto: true });

		try {
			const response = await axios({ url: url, method: 'GET', responseType: 'blob' });

			const blob = new Blob([response.data], { type: 'application/pdf' });
			const blobURL = URL.createObjectURL(blob);

			const link = document.createElement('a');
			link.href = blobURL;
			link.download = filename;
			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
			URL.revokeObjectURL(blobURL);
		} catch (error) {
			console.error('Falha ao baixar o boleto', error);
		} finally {
			this.setState({ loadingBaixarBoleto: false });
		}
	};

	handleClose = () => {
		this.setState({ showModalReenviar: false, agreeModal: false, showModalPago: false, showModalGarantido: false, showModalEditar: false });
	}

	getInitialValues = () => {
		return {
			value: this.state.data?.values?.value ? formatBRL(this.state.data?.values.value) : '',
			due_date: moment(this.state?.data?.due_date).add(1, 'd').format('YYYY-MM-DD')
		};
	};

	handleSubmitEdit = (values) => {
		let billingData = moment.utc(values.due_date);

		if (moment.utc().isSameOrAfter(billingData)) {
			this.setState({ success: false, errors: ['Não é possível selecionar o dia atual ou uma data passada.'] });
			return;
		}

		this.setState({ loading: true });

		const data = { value: limparMoeda(values.value), due_date: values.due_date }

		update(this.state.data.id, data).then((res) => {
			if (res.status === 200) {
				this.setState({ loading: false, success: ['O pagamento foi editado.'], errors: [], showModalEditar: false });
			}
		}).catch((err) => {
			this.setState({ loading: false, success: false, errors: err?.response?.data?.message ? [err?.response?.data?.message] : [] });
		}).finally(async () => {
			await this.handleGetBill();

			const searchParams = new URLSearchParams(document.location.search);

			let transferId = searchParams.get('transfer');
			if (transferId) {
				this.handleFindTransfer(transferId);
			}

			const authUser = JSON.parse(localStorage.getItem("authUser"));

			this.setState({ authUser: authUser });
			this.handleFindBoleto();
		});
	};

	render() {
		const billing = this.state?.data;
		const dueDate = moment(this.state?.data?.due_date).format('YYYYMMDD');
		const currentDate = moment().add(1, 'day').format('YYYYMMDD');
		const receipts = this.state?.data?.receipts;
		const status = this.getStatusString();

		const property = this.state?.contract?.entity?.property;
		const address = property?.address;
		const contract = this.state?.contract?.entity;

		const withNoButton = ['pago', 'garantido e pago', 'Acerto de rescisão', 'Acordo quitado', 'Acordo vigente'].includes(this.getStatusString()) || ['warranty_refund'].includes(this.state?.data?.type);
		const transfMellro = moment(this.state?.recebedores?.filter((recebedor) => recebedor?.user_type == 'mellro')?.[0]?.deposit_date, 'DD/MM/YYYY HH:mm:ss').subtract(3, 'hours').format('DD/MM/YYYY HH:mm:ss');
		const transfLandlord = moment(this.state?.recebedores?.filter((recebedor) => recebedor?.user_type == 'landlord')?.[0]?.deposit_date, 'DD/MM/YYYY HH:mm:ss').subtract(3, 'hours').format('DD/MM/YYYY HH:mm:ss');

		const Botoes = () => {
			let botoesDom = null;

			const Editar = () => (
				<button onClick={() => this.setState({ showModalEditar: true })} className="btnSecundary">
					EDITAR
					<FontAwesomeIcon icon={faPen} />
				</button>
			)

			const MarcarPago = () => (
				<button disabled={(receipts?.rent?.paid_for_insurer === true && receipts?.rent?.paid === true) ||
					receipts?.rent?.paid || (!this.state?.data?.boleto?.pdf_url && currentDate < dueDate)}
					onClick={() => this.setState({ showModalPago: true })} style={{ width: 'auto' }} className="btnSecundary">
					MARCAR COMO PAGO
					<FontAwesomeIcon icon={faCheckToSlot} />
				</button>
			)

			const MarcarGarantido = () => (
				<button onClick={() => this.setState({ showModalGarantido: true })} style={{ width: 'auto' }} className="btnSecundary">
					MARCAR COMO GARANTIDO
					<i className="fa fa-exclamation" />
				</button>
			)

			const BaixarBoleto = () => (
				<button disabled={this.state.loadingBaixarBoleto} onClick={() => this.downloadPDF(this.state?.data?.boleto?.pdf_url, 'boleto.pdf')} style={{ width: 'auto' }} className="btnSecundary">
					{this.state.loadingBaixarBoleto ?
						<FontAwesomeIcon spin icon={faSpinner} />
						: (
							<>
								BAIXAR BOLETO
								<FontAwesomeIcon icon={faFileArrowDown} />
							</>
						)
					}
				</button>
			)

			const Negativacao = () => (
				<button onClick={this.handleSeeNegativation} style={{ width: 'auto' }} className="btnSecundary">
					{this.state?.data?.credit_negative?.negative ? 'DETALHES DA NEGATIVAÇÃO' : 'NEGATIVAR COBRANÇA'}
					<FontAwesomeIcon icon={faXmarkToSlot} />
				</button>
			)

			const ReenviarCobranca = () => (
				<button onClick={() => this.setState({ showModalReenviar: true })} style={{ width: 'auto' }} className="btnSecundary">
					REENVIAR
					<FontAwesomeIcon icon={faFileCirclePlus} />
				</button>
			)

			const AddReembolco = () => (
				<button disabled={this.isWaiting(this.state?.data) != 'Aguardando'} onClick={() => this.props.history.push('/billing/reembolso/' + this.props.match.params.id)} style={{ width: 'auto' }} className="btnSecundary">
					ADICIONAR REEMBOLSO
					<FontAwesomeIcon icon={faMoneyCheckDollarPen} />
				</button>
			)

			const ParcelamentoAmigavel = () => (
				<Link className='btnSecundary' to={`/contracts/friendly-agreement/${contract?.id}`} style={(this.state?.data?.type === 'agreement' || this.state?.data?.type === 'termination') ? { display: 'none' } : {}}>
					CRIAR ACORDO
					<FontAwesomeIcon icon={faFileCirclePlus} />
				</Link>
			)

			if (this.state?.data?.type === 'monthly' && this.state?.data?.termination_id) {
				botoesDom = <></>

				return botoesDom;
			}

			if (status == 'pago') {
				botoesDom = <></>
			}

			if (status == 'garantido e pago') {
				botoesDom = <></>
			}

			if (status == 'garantido e atrasado') {
				botoesDom = <div className='d-flex align-itens-center justify-content-between w-100'>
					<div className='d-flex align-itens-center' style={{ gap: 8 }}>
						<ReenviarCobranca />
						<BaixarBoleto />
					</div>

					<div className='d-flex align-itens-center' style={{ gap: 8 }}>
						<MarcarPago />
						<Negativacao />
						<ParcelamentoAmigavel />
					</div>
				</div>
			}

			if (status == 'atrasada') {
				botoesDom = <div className='d-flex align-itens-center justify-content-between w-100'>
					<div className='d-flex align-itens-center' style={{ gap: 8 }}>
						<ReenviarCobranca />
						<BaixarBoleto />
					</div>

					<div className='d-flex align-itens-center' style={{ gap: 8 }}>
						<MarcarPago />
						<Negativacao />
						<ParcelamentoAmigavel />
					</div>
				</div>
			}

			if (status == 'aberto') {
				botoesDom = <div className='d-flex align-itens-center justify-content-between w-100'>
					<div className='d-flex align-itens-center' style={{ gap: 8 }}>
						<ReenviarCobranca />
						<BaixarBoleto />
						<MarcarGarantido />
					</div>

					<div className='d-flex align-itens-center' style={{ gap: 8 }}>
						<MarcarPago />
						<Negativacao />
					</div>
				</div>
			}

			if (status == 'aguardando') {
				botoesDom = <div className='d-flex align-itens-center justify-content-between w-100'>
					<div className='d-flex align-itens-center' style={{ gap: 8 }}>
						<Editar />
						<AddReembolco />
					</div>
				</div>
			}

			if (status == 'Acordo vigente') {
				botoesDom = <></>
			}

			return botoesDom;
		}

		const FaturaRow = ({ title, text, color, subtitle, subTitleColor, diffColor }) => {
			return (
				<div className='faturaContainer'>
					<div>
						<p style={color ? { color } : {}}>{title}</p>
						{subtitle && <p className='subtitle' style={color && !diffColor ? { color } : {}}>{subtitle}</p>}
					</div>

					<b style={color ? { color } : subTitleColor ? { color: subTitleColor } : {}}>{text}</b>
				</div>
			)
		}

		const getNomeNegociacao = () => {
			let text = '';

			this.state.participantUsers.map((participante, index) => {
				if (participante.user_type == 'commissioned') return;

				let firstName = participante.name.split(' ');
				firstName = firstName[0];

				let lastName = index + 1 == this.state.participantUsers.length;

				if (text == '') {
					text = firstName;
				} else {
					if (lastName) {
						text = text + ' e ' + firstName;
					} else {
						text = text + ', ' + firstName;
					}
				}
			})

			text = text + ` em ${address?.neighbourhood ? `${address?.neighbourhood},` : ''} ${address?.city} - ${address?.state}`

			return text;
		};

		const getAgreementIndex = (billing) => {

			const { friendly_agreement_id } = billing;
			const { friendlyAgreement } = this.state;

			if (!friendlyAgreement) return '';

			const index = friendlyAgreement.findIndex(
				(agreement) => agreement.id === friendly_agreement_id
			);

			return index >= 0 ? `Acordo ${index + 1}` : '';
		};


		const getBillingTitle = (bill) => {
			if (bill?.type === 'termination') {
				return `Parcela ${bill?.number + 1} da rescisão de contrato`
			}

			if (bill?.type === 'agreement') {
				return `${getAgreementIndex(this.state.data)} Parcela ${bill?.number}`
			}

			if (bill?.type === 'warranty_refund') {
				return `Parcela ${bill?.number + 1} da devolução de garantia`
			}

			return this.formatDate(this.state?.data?.due_date)
		}


		return (
			<React.Fragment>
				<BetterCircularProgress loading={this.state.loading}>
					<div className="page-content container-page billing">
						<Container fluid>
							<div className='header' style={{ marginBottom: this.state?.success ? '1rem' : '' }}>
								<h1>Pagamento</h1>

								<Breadcrumbs breadcrumbItems={[
									{ link: '/pagamento/cobrancas/index', title: 'Financeiro' },
									{ link: '/pagamento/cobrancas/index', title: 'Cobranças' },
									{ link: `#`, title: `ID ${this.props.match.params.id}` }]}
								/>
							</div>

							<BetterAlerts success={this.state.success} errors={this.state.errors}
								icon={faCircleCheck} style={{ marginBottom: '34px', color: '#1CBB8C', backgroundColor: '#D6F3EA', border: '1px solid #1CBB8C ' }}
							/>

							<div className='boxUser'>
								<div className='headerUser'>
									{property?.images?.[0]?.medium?.url ?
										<div className='userPhoto' style={{ backgroundImage: `url(${property?.images?.[0]?.medium?.url})` }} />
										:
										<div className='userPhoto noPhotoAnnouncement' />
									}

									<div className='fotoParticipantes'>
										{this.state?.participantUsers?.map((participante, index) => (
											<div className="boxUserTableUsers" style={{ marginLeft: index > 0 ? `-15%` : '0%' }}>
												{participante?.profile_image?.medium?.url ?
													<div className='userPhoto' style={{ backgroundImage: `url(${participante?.profile_image?.medium?.url})` }} />
													:
													<div className='userPhotoNone'>
														<FontAwesomeIcon icon={faUser} />
													</div>
												}
											</div>
										))}
									</div>

									<div className='tagsAssets groupAlign' style={{ marginLeft: ((this?.state?.participantUsers?.length || 2) * 30) + 'px' }}>
										<h3>
											{getBillingTitle(this.state?.data)}
										</h3>

										<p className='mb-0'>{getNomeNegociacao()}</p>

										<div className='tagGroup'>
											{this.getStatus(this.state?.data)}

											{billing?.anticipated && (
												<div className='tag' style={{ backgroundColor: '#5664d2' }}>Antecipado</div>
											)}

											{contract?.id && contract?.is_terminated != true && contract?.scheduled_termination_date && (
												<div className='tag' style={{ backgroundColor: '#EFA200' }}>Encerramento agendado</div>
											)}

											{receipts?.rent?.paid_for_admin && (
												<div className='tag' style={{ backgroundColor: '#5664D2' }}>Marcado manualmente</div>
											)}
										</div>
									</div>
								</div>

								<div className='bodyAssets' style={withNoButton ? { borderBottom: 'none', justifyContent: 'space-between' } : {}}>
									<div className='bodyAssets' style={withNoButton ? { borderBottom: 'none' } : {}}>
										{this.state?.data?.type == 'termination' || this.state?.data?.type == 'warranty_refund' && (
											<div className='boxInformation'>
												<p>Parcela</p>
												<b>{this.state?.data?.number + 1} / {this.state?.data?.type === 'termination' ? this.state?.termination?.number_of_installments : this.state?.termination?.retroactive_billings_guaranteed?.installments ? this.state?.termination?.retroactive_billings_guaranteed?.installments : 'N/A'}</b>
											</div>
										)}

										<div className='boxInformation'>
											<p>Vence em</p>
											<b>{moment(this?.state?.data?.due_date).add(1, 'day').format('DD/MM/YY')}</b>
										</div>

										<div className='boxInformation'>
											<p>Pago em</p>
											<b>{transfMellro != 'Data inválida' ? transfMellro : '--'}</b>
										</div>

										<div className='boxInformation'>
											<p>Trans. p/ Mellro</p>
											<b>{transfMellro != 'Data inválida' ? transfMellro : '--'}</b>
										</div>

										{['Acordo quitado', 'Acordo vigente'].includes(status) && (
											<div className='boxInformation'>
												<p>Data de acordo</p>
												<b>{moment(this.state?.data?.created_at).format('DD/MM/YY HH:mm:ss')}</b>
											</div>
										)}

										{receipts?.rent?.paid_for_admin_user && (
											<div className='boxInformation'>
												<p>Marcado por</p>

												<Link to={`/users/show/${receipts?.rent?.paid_for_admin_user}`}>
													<b className='inBlue'>
														{this.state.adminPayment?.name}
														<FontAwesomeIcon icon={faUserSolid} style={{ marginLeft: 8.5 }} />
													</b>
												</Link>
											</div>
										)}

										{(this.state?.userBilling?.id && (this.state.data?.type == 'termination' || this.state.data?.type == 'warranty_refund')) && (
											<div className='boxInformation'>
												<p>Cobrança para</p>

												<Link to={`/users/show/${this.state?.userBilling?.id}`}>
													<b className='inBlue'>
														{this.state?.userBilling?.name}
													</b>
												</Link>
											</div>
										)}

										{(this.state?.transfer) && (
											<div className='boxInformation'>
												<p>Falha de repasse</p>
												<b style={{ color: '#FF3D60', fontWeight: '400' }}>
													{moment(this.state?.transfer?.transfer?.created_at).format('DD/MM/YYYY')}	{this.state?.transfer?.transfer?.fail_reason}
												</b>
											</div>
										)}
									</div>
									{this.state.secBilling ? (
										<div className='bodyAssets' style={withNoButton ? { borderBottom: 'none' } : {}}>

											<div className='boxInformation'>
												<p>Sequencial da Remessa</p>
												<b className='inBlue'>{this.state?.secBilling?.shipment_batch}</b>
											</div>

											<div className='boxInformation'>
												<p>Status da remessa</p>

												{this.state?.secBilling?.status === 'WAITING_CNAB' ?
													<div className="d-flex flex-row" style={{ width: '140px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center', backgroundColor: '#8590A5' }}>
														<p className="col-form-label text-white" style={{ marginBottom: 0 }}>Esperando CNAB</p>
													</div>
													: null}

												{this.state?.secBilling?.status === 'SECURITIZED' ?
													<div className="d-flex flex-row" style={{ width: '140px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center', backgroundColor: '#4AA3FF' }}>
														<p className="col-form-label text-white" style={{ marginBottom: 0 }}>Securtizado</p>
													</div>
													: null}

												{this.state?.secBilling?.status === 'REJECTED' ?
													<div className="d-flex flex-row" style={{ width: '140px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center', backgroundColor: '#FF3D60' }}>
														<p className="col-form-label text-white" style={{ marginBottom: 0 }}>Rejeitada</p>
													</div>
													: null}
											</div>

											<div className='boxInformation'>
												<p>Dono da cobrança</p>

												{moment().isAfter(moment(this.state.secBilling?.due_date).subtract(1, 'days')) && this.state?.secBilling?.status != 'SECURITIZED' ?
													<div className="d-flex flex-row" style={{ width: '140px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center', backgroundColor: '#4AA3FF' }}>
														<p className="col-form-label text-white" style={{ marginBottom: 0 }}>Mell.ro TEC</p>
													</div>
													: null}

												{moment().isBefore(moment(this.state.secBilling?.due_date)) && this.state?.secBilling?.status != 'SECURITIZED' ?
													<div className="d-flex flex-row" style={{ width: '140px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center', backgroundColor: '#5664D2' }}>
														<p className="col-form-label text-white" style={{ marginBottom: 0 }}>Responsável</p>
													</div>
													: null}

												{this.state?.secBilling?.status === 'SECURITIZED' ?
													<div className="d-flex flex-row" style={{ width: '140px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center', backgroundColor: '#8590A5' }}>
														<p className="col-form-label text-white" style={{ marginBottom: 0 }}>Mell.ro SEC</p>
													</div>
													: null}

											</div>

										</div>
									) : null}
								</div>

								{withNoButton ? null :
									<div className='footerAssets'>
										<Botoes />
									</div>
								}
							</div>

							<div className='containerGeralLayout'>
								<div className='principalLayout'>
									<div className='box'>
										<BetterCircularProgress loading={this.state.loading}>
											<div className='boxInfo-imovel-negociacao'>
												<h4 style={{ marginBottom: 32 }}>CONSTITUINTES</h4>

												<div className='boxInfo-imovel-header'>
													<h2 className='title-section'>Contrato</h2>

													<span>ID {contract?.id}</span>
												</div>

												<div style={{ marginTop: 11.5 }} className='boxInfos-container boxInfos-container-unico boxInfoUser'>
													<div className='boxUser'>
														<div className='headerUser'>
															<div style={{ width: 163 }} className='boxInformation'>
																<a className='link-contrato-box' href={`/negotiation/show/${contract?.proposition_id}`}>
																	Contrato de locação de imóvel residencial
																</a>

																<div className='tag' style={{ marginTop: 4 }}>
																	{contract?.is_terminated ? 'Rescindido' : contract?.signed ? 'Assinado' : 'Não assinado'}
																</div>
															</div>

															<div className='boxInformation'>
																<p style={{ marginBottom: 4 }}>Data de início em</p>
																<b>{moment(contract?.details.start_date).format('DD/MM/YY')}</b>
															</div>

															<div className='boxInformation'>
																<p style={{ marginBottom: 4 }}>Prazo de contrato</p>

																<div style={{ display: 'flex', alignItems: 'center' }}>
																	<b>{contract?.details?.duration} meses</b>

																	{this.calcContractPercentage(contract)}
																</div>
															</div>

															<div className='boxInformation'>
																<p style={{ marginBottom: 4 }}>Garantia</p>
																<b>{this.getWarranty(contract?.plan?.name, contract?.plan)}</b>
															</div>
														</div>
													</div>
												</div>

												{this.state?.data?.type == 'termination' ?
													<>
														<div className='recebedores-termination'>
															<h3>Recebedores <small>Aluguéis atrasados</small></h3>

															<div className='recebedores-termination-box'>
																<div className='recebedores-line'>
																	<div className='boxInformation'>
																		<b><div className='tag'>Mell.ro</div></b>
																	</div>

																	<div className='boxInformation'>
																		<p style={{ marginBottom: 4 }}>Depositado em</p>
																		<b>{transfMellro != 'Data inválida' ? transfMellro : '--'}</b>
																	</div>

																	<div className='boxInformation'>
																		<p style={{ marginBottom: 4 }}>Recebe:</p>
																		<b>{formatBRL(this.state?.data?.values?.mellro_termination_value ?? 0)}</b>
																	</div>
																</div>
															</div>
														</div>

														<div className='recebedores-termination'>
															<h3>Recebedores <small>Mês vigente</small></h3>

															<div className='recebedores-termination-box'>
																<div className='recebedores-line'>
																	<div className='boxInformation'>
																		<p style={{ marginBottom: 4 }}>{this.state?.landlordBilling?.name}</p>
																		<b><div className='tag'>Inquilino</div></b>
																	</div>

																	<div className='boxInformation'>
																		<p style={{ marginBottom: 4 }}>Depositado em</p>
																		<b>{transfLandlord != 'Data inválida' ? transfLandlord : '--'}</b>
																	</div>

																	<div className='boxInformation'>
																		<p style={{ marginBottom: 4 }}>Recebe:</p>
																		<b>{formatBRL(this.state?.data?.values?.landlord_termination_value ?? 0)}</b>
																	</div>
																</div>
															</div>
														</div>

														<div className='recebedores-termination'>
															<h3>Recebedores <small>Fatura em aberto</small></h3>

															<div className='recebedores-termination-box'>
																<div className='recebedores-line'>
																	<div className='boxInformation'>
																		<p style={{ marginBottom: 4 }}>{this.state?.landlordBilling?.name}</p>
																		<b><div className='tag'>Responsável</div></b>
																	</div>

																	<div className='boxInformation'>
																		<p style={{ marginBottom: 4 }}>Depositado em</p>
																		<b>{transfLandlord != 'Data inválida' ? transfLandlord : '--'}</b>
																	</div>

																	<div className='boxInformation'>
																		<p style={{ marginBottom: 4 }}>Recebe:</p>
																		<b>{formatBRL(this.state?.data?.values?.open_billing_value || 0)}</b>
																	</div>
																</div>
															</div>
														</div>

														<div className='recebedores-termination'>
															<h3>Recebedores <small>Devolução de aluguel</small></h3>

															<div className='recebedores-termination-box'>
																<div className='recebedores-line'>
																	<div className='boxInformation'>
																		<p style={{ marginBottom: 4 }}>{this.state?.userBilling?.name}</p>
																		<b><div className='tag'>Inquilino</div></b>
																	</div>

																	<div className='boxInformation'>
																		<p style={{ marginBottom: 4 }}>Depositado em</p>
																		<b>{transfLandlord != 'Data inválida' ? transfLandlord : '--'}</b>
																	</div>

																	<div className='boxInformation'>
																		<p style={{ marginBottom: 4 }}>Recebe:</p>
																		<b>{formatBRL(this.state?.termination?.retroactive_billings_rent?.value || 0)}</b>
																	</div>
																</div>
															</div>
														</div>

													</>
													:
													this.state?.data?.contract_id && (
														<>
															<h2 className='title-section' style={{ marginTop: 32, marginBottom: 16 }}>Recebedores</h2>

															<div style={{ marginTop: '20px', marginBottom: '20px' }}>
																<DataTableComissionados type={this.state?.data?.type} noDelete handleSetRecebedores={this.handleSetRecebedores}
																	fetchEvent={getSplitData} billingId={this.props.match.params.id} billingData={this.state.data} idRow={`id`}
																/>
															</div>
														</>
													)}
											</div>
										</BetterCircularProgress>
									</div>

									{this.state.repayments.length > 0 && (
										<div className='box'>
											<BetterCircularProgress loading={this.state.loading}>
												<div className='boxInfo-imovel-negociacao'>
													<h4 style={{ marginBottom: 16 }}>REEMBOLSO</h4>

													<div className='datatable-box table-responsive'>
														<DataTable ref={(el) => this.dt = el} value={this.state.repayments} lazy={true}
															loading={this.state.loading} responsive={true} loadingIcon={`fas fa-sync fa-spin`}
															sortField={this.state.sortField} sortOrder={this.state.sortOrder}>
															<Column style={{ width: 120 }} field="type" body={(obj) => <div className='tag' style={obj.type == "repayment" ?
																{ backgroundColor: '#1CBB8C' } : { backgroundColor: '#FF3D60' }}>{obj.type == "repayment" ? 'Desconto' : 'Acréscimo'}</div>} header="Tipo" />
															<Column style={{ width: 417 }} field="text" body={(obj) => obj?.title} header="Descrição" />
															<Column style={{ width: 117 }} field="amount" body={(obj) => obj.type == "repayment" ?
																<span style={{ color: '#1CBB8C' }}>- {formatBRL(obj?.amount)}</span>
																:
																<span style={{ color: '#FF3D60' }}>+ {formatBRL(obj?.amount)}</span>} header="Valor"
															/>
															<Column style={{ width: 34 }} header="" body={(obj) => <Link to={`/billing/reembolso/show/${obj.id}`} className='showButton'><i className='fa fa-chevron-right' /></Link>} />
														</DataTable>
													</div>

													<div className='footerTotaisReembolso'>
														<div>
															<h4>Total de acréscimo</h4>
															<p><b>+ R$</b>{formatBRLNoSign(this.state?.repayments?.reduce((sum, item) => { return sum + (item.type != "repayment" ? parseFloat(item.amount) : 0) }, 0))}</p>
														</div>
														<div>
															<h4>Total de desconto</h4>
															<p><b>- R$</b>{formatBRLNoSign(this.state?.repayments?.reduce((sum, item) => { return sum + (item.type == "repayment" ? parseFloat(item.amount) : 0) }, 0))}</p>
														</div>
														<div>
															<h4>Ajuste total</h4>
															<p style={Number(receipts?.totalReembolso) > 0 ? { color: '#FF3D60' } : { color: '#1CBB8C' }}>
																<b>{Number(receipts?.totalReembolso) > 0 ? '+' : '-'} R$</b>{formatBRLNoSign(Math.abs(receipts?.totalReembolso))}
															</p>
														</div>
													</div>
												</div>
											</BetterCircularProgress>
										</div>
									)}

									{billing?.contract_id && (receipts?.rent?.agreement_settled || this.state?.friendlyAgreement[0] || ['termination', 'agreement', 'warranty_refund'].includes(billing?.type)) && (
										<div className='box'>
											<BetterCircularProgress loading={this.state.loading}>
												<div className='boxInfo-imovel-negociacao'>
													<h4 style={{ marginBottom: 16 }}>{(receipts?.rent?.agreement_settled) ? 'PARCELAS DO ACORDO' : 'OUTRAS PARCELAS'}</h4>
													<div className='datatable-box table-responsive'>
														<DataTableContractsPayment noDelete crudButtons={true} idRow={`id`} noShow paymentType={billing?.type} contractId={this.state.data?.contract_id} billing={billing} />
													</div>
												</div>
											</BetterCircularProgress>
										</div>
									)}
								</div>

								<div className='faturasLayout'>
									<div className='box'>
										<h4>DETALHES DO VALOR</h4>
										{this.state?.data?.type == 'warranty_refund' ?
											<>
												<FaturaRow color={`#FF3D60`} subTitleColor={""} title={`Devolução de garantia`}
													subtitle={`${this.state?.termination?.retroactive_billings_guaranteed.billings?.length || 0} ${this.state?.termination?.retroactive_billings_guaranteed.billings?.length > 1 ? 'meses' : 'mês'}`}
													text={`+ ${formatBRL(this.state?.termination?.retroactive_billings_guaranteed?.value || 0)}`}
													diffColor={true}
												/>

												<FaturaRow title={'Parcelas'} text={`${this.state?.termination?.retroactive_billings_guaranteed?.installments}x`} />
											</>
											: this.state?.data?.type == 'termination' ?
												<>
													{this.state?.termination?.overdue_payments?.value > 0 && (
														<FaturaRow color={`#FF3D60`} title={`Aluguéis atrasados`}
															subtitle={`${this.state?.termination?.overdue_payments.months || 0} ${this.state?.termination?.overdue_payments.months > 1 ? 'meses' : 'mês'}`}
															text={`+ ${formatBRL(this.state?.termination?.overdue_payments?.value || 0)}`}
														/>
													)}

													{this.state?.termination?.current_rent?.value > 0 && (
														<FaturaRow title={`Mês vigente`}
															subtitle={`${this.state.data?.values?.return_days ?? 0} dias devolução`}
															text={`- ${formatBRL(this.state?.termination?.current_rent?.value)}`}
														/>
													)}


													{this.state?.data?.values?.days_spent > 0 &&
														<FaturaRow title={`Fatura em aberto`}
															subtitle={`${this.state?.data?.values?.days_spent} ${this.state?.data?.values?.days_spent > 1 ? 'dias usufruídos' : 'dia usufruído'}`}
															text={`${formatBRL(this.state?.data?.values?.open_billing_value || 0)}`}
														/>
													}

													{this.state?.termination?.termination_fine?.value > 0 && (
														<FaturaRow color={`#EFA200`} title={`Multa rescisória`}
															subtitle={`10% de ${this.state?.mesesMultaRescisoria || 0} ${this.state?.mesesMultaRescisoria > 1 ? 'meses' : 'mês'}`}
															text={`+ ${formatBRL(this.state?.termination?.termination_fine?.value || 0)}`}
														/>
													)}

													{this.state?.data?.values?.repayment_value > 0 && (
														<FaturaRow color={`#1CBB8C`} title={'Reembolsos'} text={`- ${formatBRL(this.state?.data?.values?.repayment_value || 0)}`} />
													)}

													{this.state?.termination?.retroactive_billings_rent?.value > 0 && (
														<FaturaRow subTitleColor={`#1CBB8C`} title={`Devolução de aluguel`}
															subtitle={`${this.state?.termination?.retroactive_billings_rent?.billings?.length} meses`}
															text={`- ${formatBRL(this.state?.termination?.retroactive_billings_rent?.value ?? 0)}`}
														/>
													)}

													{this.state?.termination?.number_of_installments > 0 && (
														<FaturaRow title={'Parcelas'} text={`${this.state?.termination?.number_of_installments}x`} />
													)}
												</>
												:
												<>
													{receipts?.rent?.value > 0 && (
														<FaturaRow title={'Aluguel'} text={`+ ${formatBRL(receipts?.rent?.value)}`} />
													)}

													{this.state?.recebedores?.find(({ user_type }) => user_type == 'mellro')?.value > 0 && (
														<FaturaRow title={'Valor Mellro'} text={`+ ${formatBRL(this.state?.recebedores?.find(({ user_type }) => user_type == 'mellro')?.value || 0)}`} />
													)}

													{receipts?.acrescimo > 0 && (
														<FaturaRow color={`#EFA200`} title={'Acréscimo'} text={`+ ${formatBRL(receipts?.acrescimo || 0)}`} />
													)}

													{receipts?.desconto > 0 && (
														<FaturaRow color={`#1CBB8C`} title={'Desconto'} text={`- ${formatBRL(receipts?.desconto || 0)}`} />
													)}

													{this.state?.data?.juros > 0 && (
														<FaturaRow color={`#FF3D60`} title={'Multa'} text={`+ ${formatBRL(this.state?.data?.juros || 0)}`} />
													)}

													{this.state?.data?.values?.total_future_billings > 0 && (
														<FaturaRow color={`#FF3D60`} title={'Juros'} text={`+ ${formatBRL(this.state?.data?.values?.total_future_billings || 0)}`} />
													)}
												</>}

										<footer>
											<hr />
											<p>Valor total</p>
											{this.state?.data?.type == 'termination' ?
												<b><small>R$</small> {formatBRLNoSign(((this.state?.termination?.termination_total ?? 0)) || 0)}</b>
												:
												<b><small>R$</small>
													{formatBRLNoSign((
														Number(this.state?.data?.values?.current_value ?? 0)
														+ Number(receipts?.acrescimo ?? 0)
														- Number(receipts?.desconto ?? 0)
													))}
												</b>
											}
										</footer>
									</div>
								</div>
							</div>
						</Container>
					</div>
				</BetterCircularProgress>

				<Modal dialogClassName="modalCancelarNegociacao" centered show={this.state?.showModalReenviar} onHide={this.handleClose}>
					<Modal.Header closeButton={false}>
						<Modal.Title>Você tem certeza que deseja reenviar?</Modal.Title>

						<button onClick={this.handleClose} style={{ backgroundColor: 'transparent', color: '#8590A5', display: 'flex', alignItems: 'center', border: 'none', fontWeight: '900', fontSize: '18px' }}>
							X
						</button>
					</Modal.Header>

					<Modal.Body>
						<p>Ao confirmar as comunicações sobre essa cobrança serão reenviadas</p>

						<Label className="pl-2 input-radio-group">
							<Input type="radio" name={'agreeModal'} id={`agreeModal`} value={!this.state.agreeModal}
								onChange={() => this.setState({ agreeModal: !this.state.agreeModal })} color="primary" checked={this.state.agreeModal}
							/>

							<Label style={{ padding: "3px" }} for={`agreeModal`}>
								Confirmo em fazer o reenvio e sei que esta ação é <b>irreversível.</b>
							</Label>
						</Label>
					</Modal.Body>

					<Modal.Footer>
						<button className='btnSecundary' onClick={this.handleClose}>
							<FontAwesomeIcon icon={faArrowLeft} />
							VOLTAR
						</button>

						<button className='buttonPrimary' disabled={!this.state.agreeModal} onClick={this.handleResendEmail}>
							CONFIRMAR
							<FontAwesomeIcon icon={faCheck} />
						</button>
					</Modal.Footer>
				</Modal>

				<Modal dialogClassName="modalCancelarNegociacao" centered show={this.state?.showModalGarantido} onHide={this.handleClose}>
					<Modal.Header closeButton={false}>
						<Modal.Title style={{ fontSize: 16, fontWeight: 600, color: '#505D69' }}>Tem certeza que deseja marcar como garantido?</Modal.Title>

						<button onClick={this.handleClose} style={{ backgroundColor: 'transparent', color: '#8590A5', display: 'flex', alignItems: 'center', border: 'none', fontWeight: '900', fontSize: '18px' }}>
							X
						</button>
					</Modal.Header>

					<Modal.Body>
						<p>Depois que o pagamento é marcado como garantido não é possível voltar.</p>

						<p>Lembre-se que ao fazer isto a <b style={{ color: '#FF3D60' }}>garantia de pagamento não ocorrerá de forma automática.</b></p>

						<Label className="pl-2 input-radio-group">
							<Input type="radio" name={'agreeModal'} id={`agreeModal`} value={!this.state.agreeModal} onChange={() => this.setState({ agreeModal: !this.state.agreeModal })}
								color="primary" checked={this.state.agreeModal}
							/>

							<Label style={{ padding: "3px" }} for={`agreeModal`}>
								Confirmo em fazer o reenvio e sei que esta ação é <b>irreversível.</b>
							</Label>
						</Label>
					</Modal.Body>

					<Modal.Footer>
						<button className='btnSecundary' onClick={this.handleClose}>
							<FontAwesomeIcon icon={faArrowLeft} />
							VOLTAR
						</button>

						<button className='buttonPrimary' disabled={!this.state.agreeModal} onClick={this.handlePayWarranty}>
							CONFIRMAR
							<FontAwesomeIcon icon={faCheck} />
						</button>
					</Modal.Footer>
				</Modal>

				<Modal dialogClassName="modalCancelarNegociacao" centered show={this.state?.showModalPago} onHide={this.handleClose}>
					<Modal.Header closeButton={false}>
						<Modal.Title>Tem certeza que deseja marcar como pago?</Modal.Title>

						<button onClick={this.handleClose} style={{ backgroundColor: 'transparent', color: '#8590A5', display: 'flex', alignItems: 'center', border: 'none', fontWeight: '900', fontSize: '18px' }}>
							X
						</button>
					</Modal.Header>

					<Modal.Body>
						<p>Depois que o pagamento é marcado como pago não é possível voltar para o status anterior.</p>

						<Label className="pl-2 input-radio-group">
							<Input type="radio" name={'agreeModal'} id={`agreeModal`} value={!this.state.agreeModal} onChange={() => this.setState({ agreeModal: !this.state.agreeModal })} color="primary" checked={this.state.agreeModal} />
							<Label style={{ padding: "3px" }} for={`agreeModal`}>
								Confirmo em fazer o reenvio e sei que esta ação é <b>irreversível.</b>
							</Label>
						</Label>
					</Modal.Body>

					<Modal.Footer>
						<button className='btnSecundary' onClick={this.handleClose}>
							<FontAwesomeIcon icon={faArrowLeft} />
							VOLTAR
						</button>

						<button className='buttonPrimary' disabled={!this.state.agreeModal} onClick={this.handlePayBill}>
							CONFIRMAR
							<FontAwesomeIcon icon={faCheck} />
						</button>
					</Modal.Footer>
				</Modal>

				<Modal dialogClassName="modalCancelarNegociacao" centered show={this.state?.showModalEditar} onHide={this.handleClose}>
					<Formik initialValues={this.getInitialValues()} validate={values => validateFormBilling(values)} onSubmit={(values) => this.handleSubmitEdit(values)}>
						{({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
							<form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>
								<Modal.Header closeButton={false}>
									<Modal.Title>Editar valor e vencimento</Modal.Title>

									<button type="button" onClick={this.handleClose} style={{ backgroundColor: 'transparent', color: '#8590A5', display: 'flex', alignItems: 'center', border: 'none', fontWeight: '900', fontSize: '18px' }}>
										X
									</button>
								</Modal.Header>

								<Modal.Body>

									<p>Não é possível selecionar o dia atual ou anterior.</p>

									<Row>
										<Col md={12}>
											<FormGroup row>
												<Label className="col-md-9 col-form-label">Vencimento *</Label>
												<Col md={6} className="container-center-input">
													<TextField name="due_date" type="date" variant="outlined" value={values.due_date} onBlur={handleBlur} onChange={handleChange}
														helperText={touched.due_date && errors.due_date} error={Boolean(touched.due_date && errors.due_date)} />
												</Col>
											</FormGroup>

											<FormGroup row>
												<Label className="col-md-9 col-form-label">Valor * </Label>
												<Col md={6} className="container-center-input">
													<TextField name="value" type="text" variant="outlined" value={values.value} onBlur={handleBlur} onChange={(e) => handleChange(formatBRLInput(e))}
														helperText={touched.value && errors.value} error={Boolean(touched.value && errors.value)} placeholder="R$ 1500,00" />
												</Col>
											</FormGroup>
										</Col>
									</Row>


									<Label className="pl-2 input-radio-group">
										<Input type="radio" name={'agreeModal'} id={`agreeModal`} value={!this.state.agreeModal}
											onChange={() => this.setState({ agreeModal: !this.state.agreeModal })} color="primary" checked={this.state.agreeModal}
										/>

										<Label style={{ padding: "3px" }} for={`agreeModal`}>
											Confirmo em fazer o reenvio e sei que esta ação é <b>irreversível.</b>
										</Label>
									</Label>
								</Modal.Body>

								<Modal.Footer>
									<button className='btnSecundary' type="button" onClick={this.handleClose}>
										<FontAwesomeIcon icon={faArrowLeft} />
										VOLTAR
									</button>

									<button className='buttonPrimary' disabled={!this.state.agreeModal} type="submit">
										CONFIRMAR
										<FontAwesomeIcon icon={faCheck} />
									</button>
								</Modal.Footer>
							</form>
						)}
					</Formik>
				</Modal>
			</React.Fragment>
		)
	}
}

export default BillingShow;