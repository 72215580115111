import axios from "axios";
import { Constants } from '../helpers/Constants';
import { parseQuery } from '../helpers/Utils';

export function getAllByFilter(params) {
	return axios.get(`${Constants.baseUrl}/coupons`, { params: parseQuery(params) });
}

export function get(id) {
	return axios.get(`${Constants.baseUrl}/coupons/${id}`);
}

export function getAppliedByAnnouncement(id) {
	return axios.get(`${Constants.baseUrl}/coupons/announcement/${id}`);
}

export function update(id, data) {
	return axios.put(`${Constants.baseUrl}/coupons/${id}`, data);
}

export function store(data) {
	return axios.post(`${Constants.baseUrl}/coupons`, data);
}

export function deleteCoupon(id) {
	return axios.delete(`${Constants.baseUrl}/coupons/${id}`);
}

export function getApplications(params) {
	return axios.get(`${Constants.baseUrl}/coupons/users`, { params: parseQuery(params) });
}

export function storeCouponUser(data) {
	return axios.post(`${Constants.baseUrl}/coupons/users`, data);
}

export function deleteCouponUser(id) {
	return axios.delete(`${Constants.baseUrl}/coupons/users/${id}`);
}