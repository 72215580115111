

import { faChevronRight } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Col } from "reactstrap"
import { formatBRL } from "../../../helpers/Utils"
import { getPropertiesPerLandlord } from "../../../crud/dashboard.crud"
import moment from "moment"
import { useCallback } from "react"
import { debounce } from "lodash"
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons"

const PIE_CHART_ENUM = {
	"CONVERSION_INDICATORS_BY_ADMIN": "Convertidos por admins",
	"CONVERSION_INDICATORS_BY_AFFILIATE": "Convertidos de afiliado",
	"CONVERSION_INDICATORS_BY_COMMISSIONED": "Convertido de comissionados",
	"CONVERSION_INDICATORS_BY_ORGANIC": "Convertidos orgânicos",
	"CONVERSION_INDICATORS_NOT_CONVERTED": "Indicados não convertidos",
	"USER_INDICATORS_NOT_VERIFIED": "Não verificados",
	"USER_INDICATORS_VERIFIED_WITHOUT_CONTRACT": "Verificados",
	"USER_INDICATORS_WITH_CONTRACTS": "Em contratos",
	"USER_INDICATORS_INACTIVES": "Inativos",
}

export const FiltersModuleTradingIndicators = ({ setState, state }) => {
	const getFilterChoised = (filter) => {
		let component = <></>

		if (filter == 'time_frame') {
			component = (
				<div className='d-flex position-relative '>
					<select onChange={(e) => setState({ tradingIndicatorsFilters: { ...state, time_frame: e.target.value } })} style={{ width: 175, paddingRight: 30 }} className="custom-select app-none"  >
						<option value="ultima_semana">Última semana</option>
						<option value={'ultimos_30_dias'}>Últimos 30 dias</option>
						<option value={'ultimos_12_meses'} selected>Último ano</option>
					</select>
					<FontAwesomeIcon icon={faChevronDown} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', pointerEvents: 'none' }} />
				</div>
			)
		} else if (filter == 'user_type') {
			component = (
				<div className='d-flex position-relative'>
					<select onChange={(e) => setState({ tradingIndicatorsFilters: { ...state, user_type: e.target.value } })} style={{ width: 175 }} className="custom-select">
						<option value="apenas_usuarios" selected>Apenas usuários</option>
						<option value={'apenas_admins'}>Apenas Admins</option>

					</select>
					<FontAwesomeIcon icon={faChevronDown} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', pointerEvents: 'none' }} />
				</div>
			)
		} else {
			component = <></>
		}

		return component;
	}

	return (
		<Col style={{ display: 'flex', alignItems: 'center', padding: 0 }}>
			<div className='d-flex position-relative'>
				{getFilterChoised('user_type')}
				<div className='divisionArrow' style={{ display: 'flex', alignItems: 'center', margin: '0px 14px' }}>
					<FontAwesomeIcon icon={faChevronRight} />
				</div>
				{getFilterChoised('time_frame')}
			</div>
		</Col>
	)
}

export const FiltersModuleConversionIndicators = ({ setState, state }) => {
	const getFilterChoised = (filter) => {
		let component = <></>

		if (filter == 'conversion_and_revenue') {
			component = (
				<div className='d-flex position-relative'>
					<select onChange={(e) => setState({ conversionIndicatorsFilters: { ...state, conversion_and_revenue: e.target.value } })} style={{ width: 175 }} className="custom-select">
						<option value="conversion" selected>Por conversão</option>
						<option value={'revenue'}>Por receita</option>

					</select>
					<FontAwesomeIcon icon={faChevronDown} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', pointerEvents: 'none' }} />
				</div>
			)
		} else if (filter == 'time_frame') {
			component = (
				<div className='d-flex position-relative'>
					<select onChange={(e) => setState({ conversionIndicatorsFilters: { ...state, time_frame: e.target.value } })} style={{ width: 175 }} className="custom-select">
						<option value="ultima_semana">Última semana</option>
						<option value={'ultimos_30_dias'}>Últimos 30 dias</option>
						<option value={'ultimos_12_meses'} selected>Último ano</option>
					</select>
					<FontAwesomeIcon icon={faChevronDown} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', pointerEvents: 'none' }} />
				</div>
			)
		} else {
			component = <></>
		}

		return component;
	}

	return (
		<Col style={{ display: 'flex', alignItems: 'center', padding: 0 }}>
			<div className='d-flex position-relative'>
				{getFilterChoised('conversion_and_revenue')}
				<div className='divisionArrow' style={{ display: 'flex', alignItems: 'center', margin: '0px 14px' }}>
					<FontAwesomeIcon icon={faChevronRight} />
				</div>
				{getFilterChoised('time_frame')}
			</div>
		</Col>
	)
}

export const FiltersModuleCommissionIndicators = ({ setState, state }) => {
	const getFilterChoised = (filter) => {
		let component = <></>

		if (filter == 'user_type') {
			component = (
				<div className='d-flex position-relative'>
					<select onChange={(e) => setState({ commissionIndicatorsFilters: { ...state, user_type: e.target.value } })} style={{ width: 175 }} className="custom-select">
						<option value="all" selected>Todos</option>
						<option value="only_commissioned" >Apenas comissionados</option>
						<option value="apenas_admins" >Apenas admins</option>
						<option value="only_affiliate" >Apenas afiliados</option>

					</select>
					<FontAwesomeIcon icon={faChevronDown} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', pointerEvents: 'none' }} />
				</div>
			)
		} else if (filter == 'time_frame') {
			component = (
				<div className='d-flex position-relative'>
					<select onChange={(e) => setState({ commissionIndicatorsFilters: { ...state, time_frame: e.target.value } })} style={{ width: 175 }} className="custom-select">
						<option value="always" selected>Sempre</option>
						<option value="this_year">Este ano</option>
						<option value="this_month">Este mês</option>
						<option value="last_month">Mês passado</option>
						<option value="before_last_month">Mês retrasado</option>
					</select>
					<FontAwesomeIcon icon={faChevronDown} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', pointerEvents: 'none' }} />
				</div>
			);

		} else {
			component = <></>
		}

		return component;
	}

	return (
		<Col style={{ display: 'flex', alignItems: 'center', padding: 0 }}>
			<div className='d-flex position-relative'>
				{getFilterChoised('user_type')}
				<div className='divisionArrow' style={{ display: 'flex', alignItems: 'center', margin: '0px 14px' }}>
					<FontAwesomeIcon icon={faChevronRight} />
				</div>
				{getFilterChoised('time_frame')}
			</div>
		</Col>
	)
}

export const FiltersModuleContractIndicators = ({ setState, state }) => {
	const getFilterChoised = (filter) => {
		let component = <></>

		if (filter == 'time_frame') {
			component = (
				<div className='d-flex position-relative'>
					<select onChange={(e) => setState({ contractIndicatorsFilters: { ...state, time_frame: e.target.value } })} style={{ width: 175 }} className="custom-select">
						<option value="always" selected>Sempre</option>
						<option value="this_year">Este ano</option>
						<option value="this_month">Este mês</option>
						<option value="last_month">Mês passado</option>
						<option value="before_last_month">Mês retrasado</option>
					</select>
					<FontAwesomeIcon icon={faChevronDown} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', pointerEvents: 'none' }} />
				</div>
			)
		} else if (filter == 'affiliate_type') {
			component = (
				<div className='d-flex position-relative'>
					<select onChange={(e) => setState({ contractIndicatorsFilters: { ...state, affiliate_type: e.target.value } })} style={{ width: 175 }} className="custom-select">
						<option value="all" selected>Todas</option>
						<option value={'clients'}>Clientes afiliados</option>
						<option value={'comercial'} >Comercial</option>
						<option value={'organic'}>Orgânico</option>
					</select>
					<FontAwesomeIcon icon={faChevronDown} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', pointerEvents: 'none' }} />
				</div>
			)
		} else {
			component = <></>
		}

		return component;
	}

	return (
		<Col style={{ display: 'flex', alignItems: 'center', padding: 0 }}>
			<div className='d-flex position-relative'>
				{getFilterChoised('time_frame')}
				<div className='divisionArrow' style={{ display: 'flex', alignItems: 'center', margin: '0px 14px' }}>
					<FontAwesomeIcon icon={faChevronRight} />
				</div>
				{getFilterChoised('affiliate_type')}
			</div>
		</Col>
	)
}

export const FiltersModuleAnnouncementIndicators = ({ setState, state }) => {
	const getFilterChoised = (filter) => {
		let component = <></>

		if (filter == 'time_frame') {
			component = (
				<div className='d-flex position-relative'>
					<select onChange={(e) => setState({ announcementIndicatorsFilters: { ...state, time_frame: e.target.value } })} style={{ width: 175 }} className="custom-select">
						<option value="always" selected>Sempre</option>
						<option value="this_year">Este ano</option>
						<option value="this_month">Este mês</option>
						<option value="last_month">Mês passado</option>
						<option value="before_last_month">Mês retrasado</option>
					</select>
					<FontAwesomeIcon icon={faChevronDown} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', pointerEvents: 'none' }} />
				</div>
			)
		} else {
			component = <></>
		}

		return component;
	}

	return (
		<Col style={{ display: 'flex', alignItems: 'center', padding: 0 }}>
			<div className='d-flex position-relative'>
				{getFilterChoised('time_frame')}
			</div>
		</Col>
	)
}

export const FiltersModuleFinancialTransactionIndicators = ({ setState, state }) => {
	const getFilterChoised = (filter) => {
		let component = <></>

		if (filter == 'billing_type') {
			component = (
				<div className='d-flex position-relative'>
					<select onChange={(e) => setState({ financialTransactionsIndicatorsFilters: { ...state, billing_type: e.target.value } })} style={{ width: '125px' }} className="custom-select">
						<option value="all" selected>Todos</option>
						<option value={'rents'}>Aluguéis</option>
						<option value={'agreements'}>Acordos</option>
						<option value={'terminations'}>Rescisões</option>
					</select>
					<FontAwesomeIcon icon={faChevronDown} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', pointerEvents: 'none' }} />
				</div>
			)
		} else if (filter == 'time_period') {
			component = (
				<div className='d-flex position-relative'>
					<select onChange={(e) => setState({ financialTransactionsIndicatorsFilters: { ...state, time_period: e.target.value } })} style={{ width: '125px' }} className="custom-select">
						<option value="12_months" selected>Últimos 12 meses</option>
						<option value={'last_years'}>Últimos anos</option>
					</select>
					<FontAwesomeIcon icon={faChevronDown} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', pointerEvents: 'none' }} />
				</div>
			)
		} else if (filter == 'graphic_data') {
			component = (
				<div className='d-flex position-relative'>
					<select onChange={(e) => setState({ financialTransactionsIndicatorsFilters: { ...state, graphic_data: e.target.value } })} style={{ width: '125px' }} className="custom-select">
						<option value="per_contract" selected>Em contratos</option>
						<option value={'per_billing'}>Em faturas</option>
						<option value={'per_values'} >Em valores</option>
					</select>
					<FontAwesomeIcon icon={faChevronDown} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', pointerEvents: 'none' }} />
				</div>
			)
		} else if (filter == 'contract_status') {
			component = (
				<div className='d-flex position-relative'>
					<select onChange={(e) => setState({ financialTransactionsIndicatorsFilters: { ...state, contract_status: e.target.value } })} style={{ width: '125px' }} className="custom-select">
						<option value="all" selected>Todas as vigências</option>
						<option value={'healthy'}>Contratos vigentes</option>
						<option value={'closed'} >Contratos encerrados</option>
					</select>
					<FontAwesomeIcon icon={faChevronDown} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', pointerEvents: 'none' }} />
				</div>
			)
		} else {
			component = <></>
		}

		return component;
	}

	return (
		<Col style={{ display: 'flex', alignItems: 'center', padding: 0 }}>
			<div className='d-flex position-relative' style={{ width: '100%', gap: 12, flexWrap: 'wrap' }}>
				<div className="d-flex" style={{ gap: 12 }}>
					{getFilterChoised('graphic_data')}
					<div className='divisionArrow' style={{ display: 'flex', alignItems: 'center', margin: '0px 0px' }}>
						<FontAwesomeIcon icon={faChevronRight} />
					</div>
				</div>

				<div className="d-flex" style={{ gap: 12 }}></div>
				{getFilterChoised('billing_type')}
				<div className='divisionArrow' style={{ display: 'flex', alignItems: 'center', margin: '0px 0px' }}>
					<FontAwesomeIcon icon={faChevronRight} />
				</div>

				<div className="d-flex" style={{ gap: 12 }}>
					{getFilterChoised('contract_status')}
					<div className='divisionArrow' style={{ display: 'flex', alignItems: 'center', margin: '0px 0px' }}>
						<FontAwesomeIcon icon={faChevronRight} />
					</div>
				</div>

				<div className="d-flex" style={{ gap: 12 }}>
					{getFilterChoised('time_period')}
				</div>
			</div>
		</Col>
	)
}

export const FiltersModulePaymentsIndicators = ({ setState, state }) => {
	const getFilterChoised = (filter) => {
		let component = <></>

		if (filter == 'billing_type') {
			component = (
				<div className='d-flex position-relative'>
					<select onChange={(e) => setState({ paymentsIndicatorsFilters: { ...state, billing_type: e.target.value } })} style={{ width: '125px' }} className="custom-select">
						<option value="all" selected>Todos</option>
						<option value={'rents'}>Aluguéis</option>
						<option value={'agreements'}>Acordos</option>
					</select>
					<FontAwesomeIcon icon={faChevronDown} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', pointerEvents: 'none' }} />
				</div>
			)
		} else if (filter == 'time_period') {
			component = (
				<div className='d-flex position-relative'>
					<select onChange={(e) => setState({ paymentsIndicatorsFilters: { ...state, time_period: e.target.value } })} style={{ width: '125px' }} className="custom-select">
						<option value="12_months" selected>Últimos 12 meses</option>
						<option value={'last_years'}>Últimos anos</option>
					</select>
					<FontAwesomeIcon icon={faChevronDown} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', pointerEvents: 'none' }} />
				</div>
			)
		} else if (filter == 'graphic_data') {
			component = (
				<div className='d-flex position-relative'>
					<select onChange={(e) => setState({ paymentsIndicatorsFilters: { ...state, graphic_data: e.target.value } })} style={{ width: '125px' }} className="custom-select">
						<option value="per_contract" selected>Em contratos</option>
						<option value={'per_billing'}>Em faturas</option>
						<option value={'per_values'} >Em valores</option>
					</select>
					<FontAwesomeIcon icon={faChevronDown} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', pointerEvents: 'none' }} />
				</div>
			)
		} else {
			component = <></>
		}

		return component;
	}

	return (
		<Col style={{ display: 'flex', alignItems: 'center', padding: 0 }}>
			<div className='d-flex position-relative' style={{ width: '100%' }}>
				{getFilterChoised('graphic_data')}
				<div className='divisionArrow' style={{ display: 'flex', alignItems: 'center', margin: '0px 14px' }}>
					<FontAwesomeIcon icon={faChevronRight} />
				</div>
				{getFilterChoised('billing_type')}
				<div className='divisionArrow' style={{ display: 'flex', alignItems: 'center', margin: '0px 14px' }}>
					<FontAwesomeIcon icon={faChevronRight} />
				</div>
				{getFilterChoised('time_period')}
			</div>
		</Col>
	)
}

export const FiltersModuleBadDebtsIndicators = ({ setState, state }) => {
	const getFilterChoised = (filter) => {
		let component = <></>

		if (filter == 'billing_type') {
			component = (
				<div className='d-flex position-relative'>
					<select onChange={(e) => setState({ badDebtIndicatorsFilters: { ...state, billing_type: e.target.value } })} style={{ width: 175 }} className="custom-select">
						<option value="all" selected>Todos</option>
						<option value={'rents'}>Aluguéis</option>
						<option value={'agreements'}>Acordos</option>
					</select>
					<FontAwesomeIcon icon={faChevronDown} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', pointerEvents: 'none' }} />
				</div>
			)
		} else if (filter == 'time_frame') {
			component = (
				<div className='d-flex position-relative'>
					<select onChange={(e) => setState({ badDebtIndicatorsFilters: { ...state, time_frame: e.target.value } })} style={{ width: 175 }} className="custom-select">
						<option value="ultima_semana">Última semana</option>
						<option value={'ultimos_30_dias'}>Últimos 30 dias</option>
						<option value={'ultimos_12_meses'} >Último ano</option>
						<option value={'always'} selected>Sempre</option>
					</select>
					<FontAwesomeIcon icon={faChevronDown} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', pointerEvents: 'none' }} />
				</div>
			)
		} else {
			component = <></>
		}

		return component;
	}

	return (
		<Col style={{ display: 'flex', alignItems: 'center', padding: 0 }}>
			<div className='d-flex position-relative'>
				{getFilterChoised('billing_type')}
				<div className='divisionArrow' style={{ display: 'flex', alignItems: 'center', margin: '0px 14px' }}>
					<FontAwesomeIcon icon={faChevronRight} />
				</div>
				{getFilterChoised('time_frame')}
			</div>
		</Col>
	)
}

export const FiltersModuleFailuresIndicators = ({ setState, state }) => {
	const getFilterChoised = (filter) => {
		let component = <></>

		if (filter == 'billing_type') {
			component = (
				<div className='d-flex position-relative'>
					<select onChange={(e) => setState({ failuresIndicatorsFilters: { ...state, billing_type: e.target.value } })} style={{ width: 175 }} className="custom-select">
						<option value="all" selected>Todos os pagamentos</option>
						<option value={'rents'}>Aluguéis</option>
						<option value={'agreements'}>Acordos</option>
					</select>
					<FontAwesomeIcon icon={faChevronDown} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', pointerEvents: 'none' }} />
				</div>
			)
		} else {
			component = <></>
		}

		return component;
	}

	return (
		<Col style={{ display: 'flex', alignItems: 'center', padding: 0 }}>
			<div className='d-flex position-relative'>
				{getFilterChoised('billing_type')}
			</div>
		</Col>
	)
}

export const FiltersModuleDueDateListIndicators = ({ setState, state }) => {
	const getFilterChoised = (filter) => {
		let component = <></>

		if (filter == 'billing_type') {
			component = (
				<div className='d-flex position-relative'>
					<select onChange={(e) => setState({ dueDateDistIndicatorsFilters: { ...state, billing_type: e.target.value } })} style={{ width: 175 }} className="custom-select">
						<option value="all" selected>Todos os pagamentos</option>
						<option value={'rents'}>Aluguéis</option>
						<option value={'agreements'}>Acordos</option>
						<option value={'terminations'}>Rescisão</option>
					</select>
					<FontAwesomeIcon icon={faChevronDown} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', pointerEvents: 'none' }} />
				</div>
			)
		} else {
			component = <></>
		}

		return component;
	}

	return (
		<Col style={{ display: 'flex', alignItems: 'center', padding: 0 }}>
			<div className='d-flex position-relative'>
				{getFilterChoised('billing_type')}
			</div>
		</Col>
	)
}

export const FiltersModuleExtrajudicialIndicators = ({ setState, state, stateSelected }) => {
	const getFilterChoised = (filter) => {
		let component = <></>

		if (filter == 'time_frame') {
			component = (
				<div className='d-flex position-relative'>
					<select onChange={(e) => {
						if (stateSelected === 'legalChargesIndicatorsFilters') {
							setState({ legalChargesIndicatorsFilters: { ...state, time_frame: e.target.value } })
						} else if (stateSelected === 'agreementIndicatorsFilters') {
							setState({ agreementIndicatorsFilters: { ...state, time_frame: e.target.value } })
						} else {
							setState({ extrajudicialIndicatorsFilters: { ...state, time_frame: e.target.value } })
						}
					}} style={{ width: 175 }} className="custom-select">
						<option value="always" selected>Sempre</option>
						<option value="this_year">Este ano</option>
						<option value="this_month">Este mês</option>
						<option value="last_month">Mês passado</option>
						<option value="before_last_month">Mês retrasado</option>
					</select>
					<FontAwesomeIcon icon={faChevronDown} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', pointerEvents: 'none' }} />
				</div>
			)
		} else {
			component = <></>
		}

		return component;
	}

	return (
		<Col style={{ display: 'flex', alignItems: 'center', padding: 0 }}>
			<div className='d-flex position-relative'>
				{getFilterChoised('time_frame')}
			</div>
		</Col>
	)
}

export const FiltersModuleBadDebtsTableIndicators = ({ setState, state }) => {
	const getFilterChoised = (filter) => {
		let component = <></>

		if (filter == 'billing_type') {
			component = (
				<div className='d-flex position-relative'>
					<select onChange={(e) => setState({ badDebtTableIndicatorsFilters: { ...state, billing_type: e.target.value } })} style={{ width: 175 }} className="custom-select">
						<option value="all" selected>Todos os pagamentos</option>
						<option value={'monthly'}>Aluguéis</option>
						<option value={'agreement'}>Acordos</option>
						<option value={'termination'}>Rescisão</option>
					</select>
					<FontAwesomeIcon icon={faChevronDown} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', pointerEvents: 'none' }} />
				</div>
			)
		} else if (filter == 'status') {
			component = (
				<div className='d-flex position-relative'>
					<select onChange={(e) => setState({ badDebtTableIndicatorsFilters: { ...state, billing_status: e.target.value } })} style={{ width: 175 }} className="custom-select">
						<option value="overdue">Apenas atrasados</option>
						<option value={'bad_debt'}>Apenas inadimplentes</option>
						<option value={'all'} selected >Todos os status</option>
					</select>
					<FontAwesomeIcon icon={faChevronDown} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', pointerEvents: 'none' }} />
				</div>
			)
		} else {
			component = <></>
		}

		return component;
	}

	return (
		<Col style={{ display: 'flex', alignItems: 'center', padding: 0 }}>
			<div className='d-flex position-relative'>
				{getFilterChoised('billing_type')}
				<div className='divisionArrow' style={{ display: 'flex', alignItems: 'center', margin: '0px 14px' }}>
					<FontAwesomeIcon icon={faChevronRight} />
				</div>
				{getFilterChoised('status')}
			</div>
		</Col>
	)
}

export const transformDataForChart = (data, value, labels) => {

	const chartData = [['Indicator', 'Valor']];

	if (value === 'conversion') {
		data.forEach(item => {
			if (labels.find(obj => obj.key === item.name && obj.isActive)) {
				chartData.push([PIE_CHART_ENUM?.[item.name] ? PIE_CHART_ENUM?.[item.name] : item.name, item.count]);
			}
		});
	} else if (value === 'revenue') {
		data.forEach(item => {
			if (labels.find(obj => obj.key === item.name && obj.isActive)) {
				chartData.push([PIE_CHART_ENUM?.[item.name] ? PIE_CHART_ENUM?.[item.name] : item.name, parseFloat(item.sum)]);
			}
		});
	}

	return chartData;
}

export const transformDataToBarChart = (arr) => {
	const data = [
		["", ""]
	];

	arr.forEach(item => {
		if (item.due_day) {
			data.push([`${item.due_day}`, parseFloat(item.total)]);
		}
	});

	return data;
}

export const getChartColors = (labels) => {
	const colors = [];

	labels.forEach(item => {
		if (item.isActive && item.color) {
			colors.push({ color: item.color })
		}
	})

	return colors;
}

export const findMetricInfo = (arr, filters, name, type) => {
	let item = arr.find(obj =>
		filters?.length > 0 && obj?.filters?.length === filters?.length &&
		obj.filters.sort().every((value, index) => value === filters.sort()[index])
	);

	if (!item && filters === null) {
		item = arr[0]
	}

	item = item?.value?.find(obj => obj.name === name)

	if (!item) {
		const itens = arr.filter(obj =>
			filters?.length > 0 &&
			obj?.filters?.length === filters?.length &&
			obj.filters.sort().every((value, index) => value === filters.sort()[index])
		).flatMap(obj => obj.value.filter(innerObj => innerObj.name === name));

		item = itens[0]
	}

	if (type === 'sum') {
		return formatBRL(item?.sum ?? 0)
	} else if (type === 'installments') {
		return item?.installments ?? 0;
	} else {
		return item?.count ?? 0
	}
}

export const sumMetricInfo = (arr, filters, name, type) => {
	let itens = arr?.filter(obj =>
		filters?.length > 0 && obj?.filters?.length === filters?.length &&
		obj.filters.sort().every((value, index) => value === filters.sort()[index])
	);

	if (name === 'PENDING') {
		let item = itens[0]?.value?.find(obj => obj.name === 'LEGAL_CHARGES_PENDING')
		let item2 = itens[1]?.value?.find(obj => obj.name === 'AGREEMENT_INDICATOR_OPEN')

		if (!item && !item2) {
			item = itens[0]?.value?.find(obj => obj.name === 'AGREEMENT_INDICATOR_OPEN')
			item2 = itens[1]?.value?.find(obj => obj.name === 'LEGAL_CHARGES_PENDING')
		}

		if (type === 'count') {
			return item?.count + item2?.count;
		} else {
			return formatBRL(parseFloat(item?.sum ?? 0) + parseFloat(item2?.sum ?? 0))
		}
	}

	if (name === 'PAID') {
		let item = itens[0]?.value?.find(obj => obj.name === 'LEGAL_CHARGES_WON')
		let item2 = itens[1]?.value?.find(obj => obj.name === 'AGREEMENT_INDICATOR_SETTLED')

		if (type === 'count') {
			return item?.count ?? 0 + item2?.count ?? 0;
		} else {
			return formatBRL(parseFloat(item?.sum ?? 0) + parseFloat(item2?.sum ?? 0))
		}
	}
}

export const FiltersModulePropertyPerLandlord = ({ setState, state }) => {
	const getFilterChoised = (filter) => {
		let component = <></>

		if (filter == 'data_cadastro') {
			component = (
				<div className='d-flex position-relative'>
					<select onChange={(e) => setState({ filtersPropertyPerLandlord: { data_cadastro: e.target.value } }, () => fetchByFilter())} className="custom-select">
						<option value="last_week">Última semana</option>
						<option value={'last_third'}>Últimos 30 dias</option>
						<option value={'last_ninety'}>Últimos 90 dias</option>
						<option value={'last_year'}>Último ano</option>
						<option value={'always'} selected>Sempre</option>
					</select>
					<FontAwesomeIcon icon={faChevronDown} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', pointerEvents: 'none' }} />
				</div>
			)
		} else if (filter == 'property_type') {
			component = (
				<div className='d-flex position-relative'>
					<select onChange={(e) => setState({ filtersPropertyPerLandlord: { property_type: e.target.value } }, fetchByFilter({ property_type: e.target.value }))} className="custom-select">
						<option value="all">Todos</option>
						<option value={'just_rented'}>Alugados</option>
						<option value={'just_available'}>Anunciados</option>
						<option value={'without_announcement'}>Cadastrados (sem anúncio)</option>
					</select>
					<FontAwesomeIcon icon={faChevronDown} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', pointerEvents: 'none' }} />
				</div>
			)
		} else {
			component = <></>
		}

		return component;
	}

	// const onSetFilter = (filtro) => {
	// 	let stateFilter = this.state.newFiltersPropertyPerLandlord.slice();
	// 	stateFilter[index] = filtro;

	// 	this.setState({ newFiltersPropertyPerLandlord: stateFilter })
	// }

	// const getSelectOptions = (nameFiltro) => {
	// 	const stateFilter = this.state.newFiltersPropertyPerLandlord;

	// 	if (stateFilter.includes(nameFiltro)) {
	// 		return 'd-none'
	// 	} else {
	// 		return ''
	// 	}
	// }

	const fetchByFilter = useCallback(debounce(async (filter) => {
		let params = {};
		const filters = filter ?? state?.filtersPropertyPerLandlord

		if (filters?.property_type) {
			if (filters?.property_type === 'just_rented') {
				params.isRented = true;
			} else if (filters?.property_type === 'just_available') {
				params.isRented = false;
			} else if (filters?.property_type === 'all' || filters?.property_type === '') {
				delete params.isRented;
			} else if (filters?.property_type === 'without_announcement') {
				params.isRented = false;
				params.isPublished = false;
			}
		}

		if (filters?.data_cadastro) {
			params.endDate = moment().add(3, 'hours').format('YYYY-MM-DD');

			switch (filters?.data_cadastro) {
				case 'last_week':
					params.startDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
					break;
				case 'last_third':
					params.startDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
					break;
				case 'last_ninety':
					params.startDate = moment().subtract(90, 'days').format('YYYY-MM-DD');
					break;
				case 'last_year':
					params.startDate = moment().subtract(365, 'days').format('YYYY-MM-DD');
					break;
				case 'always':
					delete params.endDate;
					break;
			}
		}

		await getPropertiesPerLandlord(params).then(res => {
			if (res.status === 200) {
				setState({ propertiesPerLandlordList: res?.data?.data });
			}
		});
	}, 300), [state?.filtersPropertyPerLandlord]);

	return (
		<Col style={{ display: 'flex', alignItems: 'center', padding: 0 }}>
			<div className='d-flex position-relative'>
				{/* <select onChange={({ target }) => {
					onSetFilter(target.value);
					if (target.value === 'reset') {
						setState({ filtersPropertyPerLandlord: [] }, () => fetchByFilter())
					}
				}} value={this.state.newFiltersPropertyPerLandlord[index]} className="custom-select">
					<option value="reset">Selecione um filtro</option>
					<option className={getSelectOptions('property_type')} value="property_type">Tipo de imóvel</option>
					<option className={getSelectOptions('data_cadastro')} value="data_cadastro">Data de cadastro</option>
				</select>
				<FontAwesomeIcon icon={faChevronDown} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', pointerEvents: 'none' }} />

				{this.state.newFiltersPropertyPerLandlord[index] != '' && (
					<div className='divisionArrow' style={{ display: 'flex', alignItems: 'center', margin: '0px 14px' }}>
						<FontAwesomeIcon icon={faChevronRight} />
					</div>
				)} */}

				{getFilterChoised('property_type')}
			</div>
		</Col>
	)
}

export const findMetricInfoGraphic = (arr, filters, type, labels) => {
	let item = arr.find(obj =>
		filters?.length > 0 && obj?.filters?.length === filters?.length &&
		obj.filters.sort().every((value, index) => value === filters.sort()[index])
	);

	if (item?.value && item?.value?.length > 0) {


		for (let i = 0; i < item?.value?.length; i++) {
			for (let j = 1; j < item?.value[i].length; j++) {
				item.value[i][j] = parseFloat(item?.value[i][j]);
			}
		}

		if (type === 1 && filters.includes('per_billing')) {
			let data = [
				[
					"Month",
					"Faturas pagas",
					"Primeiro pagamento de fatura",
					"Faturas inadimplentes",
					"Faturas judicializadas",
				],
				...item?.value
			]

			data = filterMatrixByLabels(data, labels)

			return data;
		}

		if (type === 1) {
			let data = [
				[
					"Month",
					"Contratos saudáveis",
					"Novos contratos assinados",
					"Contratos inadimplentes",
					"Contratos em jurídico",
				],
				...item?.value
			]

			data = filterMatrixByLabels(data, labels)

			return data;
		}

		let data = [
			[
				"Month",
				"Pago",
				"Atrasado",
				"Inadimplente"
			],
			...item?.value
		] ?? [];

		data = filterMatrixByLabels(data, labels)

		return data;
	} else {
		return []
	}
}

export const sumColumnValues = (mx, col, format, options) => {
	let sum = 0;

	if (options?.graphic_data === 'per_contract' && mx?.length > 0) {
		return mx?.[mx?.length - 1][col] ? parseFloat(mx[mx.length - 1][col]) : 0;
	}

	for (let i = 1; i < mx.length; i++) {
		sum += mx[i][col] ? parseFloat(mx[i][col]) : 0;
	}

	if (format) {
		sum = formatBRL(sum);
	}

	return sum;
}

export const sumColumnValuesWithoutBRL = (mx, col, format) => {
	let sum = 0;

	for (let i = 1; i < mx.length; i++) {
		sum += mx[i][col] ? parseFloat(mx[i][col]) : 0;
	}

	if (format) {
		sum = formatBRL(sum);
	}

	return sum;
}

export const filterMatrixByLabels = (matrix, labels_) => {
	const labels = labels_;
	if (!labels) {
		return matrix;
	}

	const columnsToRemove = labels
		.map((labelObj, index) => (labelObj.isActive || index === 0 ? null : index + 1)) // index + 1 para ignorar a primeira coluna
		.filter(index => index !== null);

	if (columnsToRemove.length === 0) {
		return matrix;
	}

	return matrix.map(row =>
		row.filter((_, index) => !columnsToRemove.includes(index))
	);
};
