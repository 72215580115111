import React, { Component } from 'react';
import { Col, FormGroup, Label, Input } from "reactstrap";
import { get as getUser } from "../../../crud/users.crud";
import { formatBRL } from '../../../helpers/Utils';

export class TenantComponent extends Component {
	constructor() {
		super();

		this.state = {
			user: null
		};
	}

	componentDidMount() {
		console.log(this.props.value);

		getUser(this.props.value.user_id).catch(err => { }).then(res => {
			if (res?.status == 200) {
				this.setState({ user: res.data.data });
			}
		})
	};

	render() {
		return (
			<React.Fragment>
				<Col md={12}>
					<FormGroup className="w-100">
						<Label className="col-form-label">{`Nome do ${this.props.label}`}</Label>
						<FormGroup>
							<Input className="form-control" type="text" value={this.state.user?.name} disabled />
						</FormGroup>

						<FormGroup>
							<Label className="col-form-label">{`Tipo de renda do ${this.props.label}`}</Label>
							<Input className="form-control" type="text" value={this.props.value.income_type ?? 'Não informado'} disabled />
						</FormGroup>

						<FormGroup>
							<Label className="col-form-label">{`Valor da renda do ${this.props.label}`}</Label>
							<Input className="form-control" type="text" value={this.props.value.income_value ? formatBRL(this.props.value.income_value) : 'Não informado'} disabled />
						</FormGroup>

						{this.props.value.income_type === 'personal_income' ?
							<FormGroup>
								<Label className="col-form-label">{`Fonte de renda do ${this.props.label}`}</Label>
								<Input className="form-control" type="text" value={this.props.value.income_source ?? 'Não informado'} disabled />
							</FormGroup>
							: null
						}
					</FormGroup>
				</Col>
			</React.Fragment>
		);
	}
}
