import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CHECK_LOGIN, LOGOUT_USER } from './actionTypes';
import { apiError, loginUserSuccessful, logoutUserSuccess } from './actions';

// AUTH related methods  
import { login } from './actions';

//If user is login then dispatch redux action's are directly from here.
function* loginUser({ payload: { user, history } }) {
	try {
		// call(checkLogin, { user: null, history: null }); 
		const response = yield call(login, { email: user.username, password: user.password });

		if (response?.user?.roles?.includes('admin')) {
			localStorage.setItem("authUser", JSON.stringify(response));
			localStorage.setItem("authToken", response.access_token);

			yield put(loginUserSuccessful(response));

			history.push('/dashboard');
		} else {
			yield put(apiError('You need to be an admin to access.'));
		}


	} catch (error) {
		yield put(apiError(error));
	}
}

function* logoutUser({ payload: { history } }) {
	try {
		localStorage.clear();

		yield put(logoutUserSuccess());

		history.push('/login');
	} catch (error) {
		yield put(apiError(error));
	}
}

export function* watchUserLogin() {
	yield takeEvery(CHECK_LOGIN, loginUser)
}

export function* watchUserLogout() {
	yield takeEvery(LOGOUT_USER, logoutUser)
}

function* loginSaga() {
	yield all([
		fork(watchUserLogin),
		fork(watchUserLogout),
	]);
}

export default loginSaga;