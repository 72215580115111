import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";
import { FiltersModuleExtrajudicialIndicators, findMetricInfo, sumMetricInfo } from "../filters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical, faEyeSlash, faGripVertical, faMaximize, faMinimize } from '@fortawesome/pro-solid-svg-icons';
import { Checkbox, ListItemText, Menu, MenuItem } from "@material-ui/core";

export default class ExtrajudicialIndicatorSection extends Component {
	constructor() {
		super();

		this.state = {
			metrics: [],
			extrajudicialIndicatorsFilters: {
				"time_frame": "always"
			},
			extrajudicialIndicatorsAvailableLabels: [
				{ label: "VALOR PENDENTE TOTAL", isActive: true },
				{ label: "PAGAMENTOS DE ACORDO MENSAIS", isActive: true },
				{ label: "VALOR PENDENTE DE ACORDO", isActive: true },
				{ label: "COBRANÇAS JUDICIAIS EM ABERTO", isActive: true },
				{ label: "ACORDO AMIGÁVEL EXTRAJUDICIAL", isActive: true },
			],
			dropdownKey: null,
			anchorEl: null,
		};
	}

	componentDidMount() {
		if (this?.props?.metrics) {
			this.setState({ metrics: this.props.metrics })
		}
	}

	render() {
		return (
			<Card>
				<CardBody className="dashboard-item">
					<div className="box-header">
						<div className="" style={{ alignItems: 'start' }}>
							<h3 className="title">Indicadores de valores <br />extrajudiciais</h3>
							<div className="box-info">
								Jurídico
							</div>
						</div>

						<div>
							<FontAwesomeIcon icon={this.props.layout?.w === 6 ? faMaximize : faMinimize} style={{ color: '#5664D2', padding: '12px', cursor: 'pointer' }} onClick={() => this.props.toggleMaximize(this.props?.itemKey)} />
							<FontAwesomeIcon icon={faEllipsisVertical} style={{ color: '#5664D2', padding: '12px', cursor: 'pointer' }} onClick={(e) => {
								this.setState({ dropdownKey: this.props.itemKey });
								this.setState({ anchorEl: e.currentTarget });
							}} />
							<FontAwesomeIcon icon={faEyeSlash} style={{ color: '#5664D2', padding: '12px', cursor: 'pointer' }} onClick={() => this.props.changeWidgetValue(this.props.itemKey)} />
							<FontAwesomeIcon icon={faGripVertical} style={{ color: '#DADBDD', padding: '12px', cursor: 'pointer' }} />
						</div>
					</div>

					<Menu
						anchorEl={this.state.anchorEl}
						open={this.state.dropdownKey === this.props.itemKey ? true : false}
						onClose={() => { this.setState({ dropdownKey: null }) }}
						className="dashboard-item"
						id="dashboard-drop"
					>
						{this.state.extrajudicialIndicatorsAvailableLabels.map((item) => (
							<MenuItem key={item.label} value={item.label}>
								<Checkbox checked={item.isActive} onChange={() => {
									this.setState(prevState => ({
										extrajudicialIndicatorsAvailableLabels: prevState.extrajudicialIndicatorsAvailableLabels.map(i =>
											i.label === item.label
												? { ...i, isActive: !i.isActive }
												: i
										)
									}));
								}} />
								<ListItemText primary={item.label} />
							</MenuItem>
						))}
					</Menu>


					<div className="box-filter">
						<FiltersModuleExtrajudicialIndicators setState={this.setState.bind(this)} state={this.state.extrajudicialIndicatorsFilters} />
					</div>

					<div style={{ position: 'relative' }}>
						<hr className="divisor" />
					</div>

					<div className="card-wrapper" style={{}}>
						<div className="card-box" style={{ display: this.state.extrajudicialIndicatorsAvailableLabels.find(obj => obj?.label === 'VALOR PENDENTE TOTAL')?.isActive ? '' : 'none' }}>
							<h3>VALOR PENDENTE <br />TOTAL</h3>

							<h2>
								<span style={{ fontSize: 13, marginTop: 8 }}>R$</span>
								{sumMetricInfo(this.state.metrics, [this.state.extrajudicialIndicatorsFilters.time_frame], 'PENDING', 'sum').replace('R$', '')}
							</h2>

							<div className="card-value card-value-red">
								{sumMetricInfo(this.state.metrics, [this.state.extrajudicialIndicatorsFilters.time_frame], 'PENDING', 'count')} contratos
							</div>
						</div>

						<div className="card-box" style={{ display: this.state.extrajudicialIndicatorsAvailableLabels.find(obj => obj?.label === 'PAGAMENTOS DE ACORDO MENSAIS')?.isActive ? '' : 'none' }}>
							<h3>PAGAMENTOS DE <br />ACORDO MENSAIS</h3>

							<h2>
								<span style={{ fontSize: 13, marginTop: 8 }}>R$</span>
								{sumMetricInfo(this.state.metrics, [this.state.extrajudicialIndicatorsFilters.time_frame], 'PAID', 'sum').replace('R$', '')}
							</h2>

							<div className="card-value card-value-green">
								{sumMetricInfo(this.state.metrics, [this.state.extrajudicialIndicatorsFilters.time_frame], 'PAID', 'count')}  acordos
							</div>
						</div>

						<div className="card-box" style={{ display: this.state.extrajudicialIndicatorsAvailableLabels.find(obj => obj?.label === 'VALOR PENDENTE DE ACORDO')?.isActive ? '' : 'none' }}>
							<h3>VALOR PENDENTE DE <br />ACORDO</h3>

							<h2>
								<span style={{ fontSize: 13, marginTop: 8 }}>R$</span>
								{findMetricInfo(this.state.metrics, [this.state.extrajudicialIndicatorsFilters.time_frame], 'AGREEMENT_INDICATOR_OPEN', 'sum').replace('R$', '')}
							</h2>

							<div className="card-value card-value-yellow">
								{findMetricInfo(this.state.metrics, [this.state.extrajudicialIndicatorsFilters.time_frame], 'AGREEMENT_INDICATOR_OPEN', 'count')} faturas
							</div>
						</div>

						<div className="card-box" style={{ display: this.state.extrajudicialIndicatorsAvailableLabels.find(obj => obj?.label === 'COBRANÇAS JUDICIAIS EM ABERTO')?.isActive ? '' : 'none' }}>
							<h3>COBRANÇAS <br />JUDICIAIS EM ABERTO</h3>

							<h2>
								<span style={{ fontSize: 13, marginTop: 8 }}>R$</span>
								{findMetricInfo(this.state.metrics, [this.state.extrajudicialIndicatorsFilters.time_frame], 'LEGAL_CHARGES_PENDING', 'sum').replace('R$', '')}
							</h2>

							<div className="card-value card-value-brown">
								{findMetricInfo(this.state.metrics, [this.state.extrajudicialIndicatorsFilters.time_frame], 'LEGAL_CHARGES_PENDING', 'count')} faturas
							</div>
						</div>

						<div className="card-box" style={{ display: this.state.extrajudicialIndicatorsAvailableLabels.find(obj => obj?.label === 'ACORDO AMIGÁVEL EXTRAJUDICIAL')?.isActive ? '' : 'none' }}>
							<h3>ACORDO AMIGÁVEL <br />EXTRAJUDICIAL</h3>

							<h2>
								<span style={{ fontSize: 13, marginTop: 8 }}>R$</span>
								{findMetricInfo(this.state.metrics, [this.state.extrajudicialIndicatorsFilters.time_frame], 'AGREEMENT_OVERDUE_INSTALLMENTS', 'sum').replace('R$', '')}
							</h2>

							<div className="card-value card-value-brown">
								{findMetricInfo(this.state.metrics, [this.state.extrajudicialIndicatorsFilters.time_frame], 'AGREEMENT_OVERDUE_INSTALLMENTS', 'count')} faturas
							</div>
						</div>
					</div>
				</CardBody>
			</Card>
		)
	}
}


