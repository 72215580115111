import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";
import { FiltersModuleFinancialTransactionIndicators, findMetricInfoGraphic, sumColumnValues } from "../filters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical, faEyeSlash, faGripVertical, faMaximize, faMinimize } from '@fortawesome/pro-solid-svg-icons';
import { Checkbox, ListItemText, Menu, MenuItem } from "@material-ui/core";
import { Chart } from "react-google-charts";

export default class FinancialTransactionsIndicatorSection extends Component {
	constructor() {
		super();

		this.state = {
			metrics: [
				[
					"Month",
					"Contratos saudáveis",
					"Novos contratos assinados",
					"Contratos inadimplentes",
					"Contratos em jurídico",
					// "Contratos finalizados"
				],
				["2024/01", 1000, 400, 200, 150, 100],
				["2024/02", 1170, 460, 250, 180, 120],
				["2024/03", 660, 1120, 300, 200, 130],
				["2024/04", 1030, 540, 350, 220, 140],
				["2024/05", 800, 600, 400, 240, 160],
				["2024/06", 950, 650, 450, 260, 180],
				["2024/07", 1200, 700, 500, 280, 200],
				["2024/08", 1100, 750, 550, 300, 220],
				["2024/09", 1300, 800, 600, 320, 240],
				["2024/10", 1400, 850, 650, 340, 260],
				["2024/11", 1500, 900, 700, 360, 280],
				["2024/12", 1600, 950, 750, 380, 300],
			],
			financialTransactionsIndicatorsFilters: {
				"billing_type": "all",
				"time_period": "12_months",
				"graphic_data": "per_contract",
				"contract_status": "all"
			},
			financialTransactionsIndicatorsAvailableLabels: [
				{ label: "Contratos saudáveis", isActive: true, key: '', color: '#1CBB8C' },
				{ label: "Novos contratos assinados", isActive: true, key: '', color: '#4AA3FF' },
				{ label: "Contratos inadimplentes", isActive: true, key: '', color: '#FCB92C' },
				{ label: "Contratos em jurídico", isActive: true, key: '', color: '#FF3D60' },
				// { label: "Contratos finalizados", isActive: true, key: '', color: '#D9D9D9' }
			],
			dropdownKey: null,
			anchorEl: null,
			maximeGraphic: false
		};
	}

	componentDidMount() {
		if (this?.props?.metrics) {
			this.setState({ metrics: this.props.metrics })
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.financialTransactionsIndicatorsFilters !== this.state.financialTransactionsIndicatorsFilters) {

			if (this.state.financialTransactionsIndicatorsFilters.graphic_data === 'per_billing') {
				this.setState({
					financialTransactionsIndicatorsAvailableLabels: [
						{ label: "Faturas pagas", isActive: true, key: '', color: '#1CBB8C' },
						{ label: "Primeiro pagamento de fatura", isActive: true, key: '', color: '#4AA3FF' },
						{ label: "Faturas inadimplentes", isActive: true, key: '', color: '#FCB92C' },
						{ label: "Faturas judicializadas", isActive: true, key: '', color: '#FF3D60' },
					]
				})
			}

			if (this.state.financialTransactionsIndicatorsFilters.graphic_data === 'per_contract') {
				this.setState({
					financialTransactionsIndicatorsAvailableLabels: [
						{ label: "Contratos vigentes saudáveis", isActive: true, key: '', color: '#1CBB8C' },
						{ label: "Novos contratos assinados", isActive: true, key: '', color: '#4AA3FF' },
						{ label: "Contratos vigentes inadimplentes", isActive: true, key: '', color: '#FCB92C' },
						{ label: "Contratos vigentes em jurídico", isActive: true, key: '', color: '#FF3D60' },
						{ label: "Contratos finalizados", isActive: true, key: '', color: '#D9D9D9' }
					]
				})
			}

			if (this.state.financialTransactionsIndicatorsFilters.graphic_data === 'per_values') {
				this.setState({
					financialTransactionsIndicatorsAvailableLabels: [
						{ label: "Valores pagos", isActive: true, key: '', color: '#1CBB8C' },
						{ label: "Primeiros pagamentos", isActive: true, key: '', color: '#4AA3FF' },
						{ label: "Valores inadimplentes", isActive: true, key: '', color: '#FCB92C' },
						{ label: "Valores judicializados", isActive: true, key: '', color: '#FF3D60' },
						{ label: "Valores de contratos finalizados", isActive: true, key: '', color: '#D9D9D9' }
					]
				})
			}
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.layout?.w !== this.props.layout?.w) {
			setTimeout(() => window.dispatchEvent(new Event('resize')), 200);
		}
	}

	render() {
		const options = {
			chartArea: { width: "85%" },
			isStacked: true,
			colors: this.state.financialTransactionsIndicatorsAvailableLabels.filter(labelObj => labelObj.isActive).length > 0 ? this.state.financialTransactionsIndicatorsAvailableLabels.filter(labelObj => labelObj.isActive).map(labelObj => labelObj.color) : ['transparent'],
			legend: 'none',
			hAxis: {
				textStyle: {
					color: '#8590A5',
					fontSize: 12,
				},
			},
			vAxis: {
				textStyle: {
					color: '#8590A5',
					fontSize: 12,
				},
			},
		};

		return (
			<Card>
				<CardBody className="dashboard-item">
					<div className="box-header">
						<div className="">
							<h3 className="title">Gráfico de movimentações</h3>
							<div className="box-info">
								Negociações
							</div>

							{this.props.layout?.w === 12 ? (
								<div className="box-filter" style={{ marginLeft: 16 }}>
									<FiltersModuleFinancialTransactionIndicators setState={this.setState.bind(this)} state={this.state.financialTransactionsIndicatorsFilters} />
								</div>
							) : null}
						</div>

						<div>
							<FontAwesomeIcon icon={this.props.layout?.w === 6 ? faMaximize : faMinimize} style={{ color: '#5664D2', padding: '12px', cursor: 'pointer' }} onClick={() => this.props.toggleMaximize(this.props?.itemKey)} />
							<FontAwesomeIcon icon={faEllipsisVertical} style={{ color: '#5664D2', padding: '12px', cursor: 'pointer' }} onClick={(e) => {
								this.setState({ dropdownKey: this.props.itemKey });
								this.setState({ anchorEl: e.currentTarget });
							}} />
							<FontAwesomeIcon icon={faEyeSlash} style={{ color: '#5664D2', padding: '12px', cursor: 'pointer' }} onClick={() => this.props.changeWidgetValue(this.props.itemKey)} />
							<FontAwesomeIcon icon={faGripVertical} style={{ color: '#DADBDD', padding: '12px', cursor: 'pointer' }} />
						</div>
					</div>

					<Menu
						anchorEl={this.state.anchorEl}
						open={this.state.dropdownKey === this.props.itemKey ? true : false}
						onClose={() => { this.setState({ dropdownKey: null }) }}
						className="dashboard-item"
						id="dashboard-drop"
					>
						{this.state.financialTransactionsIndicatorsAvailableLabels.map((item) => (
							<MenuItem key={item.label} value={item.label}>
								<Checkbox checked={item.isActive} onChange={() => {
									this.setState(prevState => ({
										financialTransactionsIndicatorsAvailableLabels: prevState.financialTransactionsIndicatorsAvailableLabels.map(i =>
											i.label === item.label
												? { ...i, isActive: !i.isActive }
												: i
										)
									}));
								}} />
								<ListItemText primary={item.label} />
							</MenuItem>
						))}
					</Menu>

					{this.props.layout?.w === 6 ? (
						<div className="box-filter" >
							<FiltersModuleFinancialTransactionIndicators setState={this.setState.bind(this)} state={this.state.financialTransactionsIndicatorsFilters} />
						</div>
					) : null}

					<div style={{ position: 'relative' }}>
						<hr className="divisor" />
					</div>

					<div className="chart-wrapper graphic">

						<div className="chart-graphic-wrapper">
							<Chart
								chartType="ColumnChart"
								height="230px"
								data={findMetricInfoGraphic(this.state.metrics, [this.state.financialTransactionsIndicatorsFilters.graphic_data, this.state.financialTransactionsIndicatorsFilters.billing_type, this.state.financialTransactionsIndicatorsFilters.time_period, this.state.financialTransactionsIndicatorsFilters.contract_status], 1, this.state.financialTransactionsIndicatorsAvailableLabels)}
								options={options}
								key={this.state.maximeGraphic ? 'expanded' : 'collapsed'}
								width={this.props.layout?.w === 12 ? '100%' : '100%'} />
						</div>

						<div className="chart-graphic-legend">
							<div className="box-pie-legend" style={{ display: this.state.financialTransactionsIndicatorsAvailableLabels.find(obj => obj.color === '#1CBB8C').isActive ? '' : 'none' }}>
								<div className="bg-green">
									<p>
										{sumColumnValues(findMetricInfoGraphic(this.state.metrics, [this.state.financialTransactionsIndicatorsFilters.graphic_data, this.state.financialTransactionsIndicatorsFilters.billing_type, this.state.financialTransactionsIndicatorsFilters.time_period, this.state.financialTransactionsIndicatorsFilters.contract_status]), 1, this.state.financialTransactionsIndicatorsFilters.graphic_data === 'per_values' ? true : false, this.state.financialTransactionsIndicatorsFilters)}
									</p>
								</div>
								<div className="box-pie-legend-text" style={{ minWidth: 100, maxWidth: 130 }}>
									<p>{this.state.financialTransactionsIndicatorsAvailableLabels.find(obj => obj.color === '#1CBB8C')?.label}</p>
								</div>
							</div>

							<div className="box-pie-legend" style={{ display: this.state.financialTransactionsIndicatorsAvailableLabels.find(obj => obj.color === '#4AA3FF').isActive ? '' : 'none' }}>
								<div className="bg-blue">
									<p>
										{sumColumnValues(findMetricInfoGraphic(this.state.metrics, [this.state.financialTransactionsIndicatorsFilters.graphic_data, this.state.financialTransactionsIndicatorsFilters.billing_type, this.state.financialTransactionsIndicatorsFilters.time_period, this.state.financialTransactionsIndicatorsFilters.contract_status]), 2, this.state.financialTransactionsIndicatorsFilters.graphic_data === 'per_values' ? true : false, this.state.financialTransactionsIndicatorsFilters)}
									</p>
								</div>
								<div className="box-pie-legend-text" style={{ minWidth: 100, maxWidth: 130 }}>
									<p>{this.state.financialTransactionsIndicatorsAvailableLabels.find(obj => obj.color === '#4AA3FF')?.label}</p>
								</div>
							</div>

							<div className="box-pie-legend" style={{ display: this.state.financialTransactionsIndicatorsAvailableLabels.find(obj => obj.color === '#FCB92C').isActive ? '' : 'none' }}>
								<div className="bg-yellow">
									<p>
										{sumColumnValues(findMetricInfoGraphic(this.state.metrics, [this.state.financialTransactionsIndicatorsFilters.graphic_data, this.state.financialTransactionsIndicatorsFilters.billing_type, this.state.financialTransactionsIndicatorsFilters.time_period, this.state.financialTransactionsIndicatorsFilters.contract_status]), 3, this.state.financialTransactionsIndicatorsFilters.graphic_data === 'per_values' ? true : false, this.state.financialTransactionsIndicatorsFilters)}
									</p>
								</div>
								<div className="box-pie-legend-text" style={{ minWidth: 100, maxWidth: 130 }}>
									<p>{this.state.financialTransactionsIndicatorsAvailableLabels.find(obj => obj.color === '#FCB92C')?.label}</p>
								</div>
							</div>

							<div className="box-pie-legend" style={{ display: this.state.financialTransactionsIndicatorsAvailableLabels.find(obj => obj.color === '#FF3D60').isActive ? '' : 'none' }}>
								<div className="bg-red">
									<p>
										{sumColumnValues(findMetricInfoGraphic(this.state.metrics, [this.state.financialTransactionsIndicatorsFilters.graphic_data, this.state.financialTransactionsIndicatorsFilters.billing_type, this.state.financialTransactionsIndicatorsFilters.time_period, this.state.financialTransactionsIndicatorsFilters.contract_status]), 4, this.state.financialTransactionsIndicatorsFilters.graphic_data === 'per_values' ? true : false, this.state.financialTransactionsIndicatorsFilters)}
									</p>
								</div>
								<div className="box-pie-legend-text" style={{ minWidth: 100, maxWidth: 130 }}>
									<p>{this.state.financialTransactionsIndicatorsAvailableLabels.find(obj => obj.color === '#FF3D60')?.label}</p>
								</div>
							</div>

							{this.state.financialTransactionsIndicatorsFilters.graphic_data === 'per_billing' ? null : (
								<div className="box-pie-legend" style={{ display: this.state.financialTransactionsIndicatorsAvailableLabels.find(obj => obj.color === '#D9D9D9')?.isActive ? '' : 'none' }}>
									<div className="bg-gray">
										<p>
											{sumColumnValues(findMetricInfoGraphic(this.state.metrics, [this.state.financialTransactionsIndicatorsFilters.graphic_data, this.state.financialTransactionsIndicatorsFilters.billing_type, this.state.financialTransactionsIndicatorsFilters.time_period, this.state.financialTransactionsIndicatorsFilters.contract_status]), 5, this.state.financialTransactionsIndicatorsFilters.graphic_data === 'per_values' ? true : false, this.state.financialTransactionsIndicatorsFilters)}
										</p>
									</div>
									<div className="box-pie-legend-text" style={{ minWidth: 100, maxWidth: 130 }}>
										<p>{this.state.financialTransactionsIndicatorsAvailableLabels.find(obj => obj.color === '#D9D9D9')?.label}</p>
									</div>
								</div>
							)}

						</div>
					</div>
				</CardBody>
			</Card>
		)
	}
}


