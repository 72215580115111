import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Button } from 'reactstrap';
import { Column } from 'primereact/column';
import _ from 'lodash';
import moment from 'moment';
import { get as getBillings, listRepayments, uploadBoleto } from '../../crud/billings.crud';
import { getApplications } from '../../crud/coupons.crud';
import { Alert } from "reactstrap";
import { formatBRL } from '../../helpers/Utils';
import { Link } from 'react-router-dom';
import 'moment/locale/pt-br';

moment.locale('pt-br');

export default class DataTableCouponApplications extends Component {
	constructor() {
		super();

		this.state = {
			data: [],
			rows: 10,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: 'created_at',
			sortOrder: 1,
			boletoExists: false,
			boletoError: false,
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		let couponApplicationsDataTable = JSON.parse(localStorage.getItem('couponApplicationsDataTable'));

		if (couponApplicationsDataTable) {
			this.setState({
				rows: couponApplicationsDataTable.rows,
				totalRecords: couponApplicationsDataTable.totalRecords,
				first: couponApplicationsDataTable.first,
				last: couponApplicationsDataTable.last,
				loading: couponApplicationsDataTable.loading,
				sortField: couponApplicationsDataTable.sortField,
				sortOrder: couponApplicationsDataTable.sortOrder,
			}, () => {
				this.fetchData();
			});
		} else {
			this.fetchData();
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters != this.props.filters) {
			this.onFilterChanged();
		}
	}

	onFilterChanged = _.debounce(() => {
		if (!this.state.isLoading) {
			this.fetchData();
		}
	}, 700);

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = async (event) => {
		if (event.data) {
			event.data = null;
		}
		let couponApplicationsDataTable = {
			rows: event.rows,
			totalRecords: this.state.totalRecords,
			first: event.first,
			last: event.rows + event.first,
			loading: this.state.loading,
			sortField: this.state.sortField,
			sortOrder: this.state.sortOrder,
		}

		localStorage.setItem('couponApplicationsDataTable', JSON.stringify(couponApplicationsDataTable));

		this.setState({
			...this.state,
			rows: event.rows,
			loading: true
		});

		this.fetchData(event.rows, event.first);
	};

	fetchData = (limit, skip) => {
		const params = {
			coupon_id: this.props.couponId,
			$limit: limit,
			$skip: skip != null ? skip : this.state.first,
			$sort: { [this.state.sortField]: this.state.sortOrder }
		};

		getApplications(params).then(res => {
			this.setState({
				...this.state,
				data: res.data.data,
				totalRecords: res.data.total,
				first: res.data.skip,
				last: res.data.skip + res.data.limit,
				loading: false
			});
		});
	};


	sortTable = e => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder }, () => {
			let couponApplicationsDataTable = {
				rows: this.state.rows,
				totalRecords: this.state.totalRecords,
				first: this.state.first,
				last: this.state.last,
				loading: this.state.loading,
				sortField: this.state.sortField,
				sortOrder: this.state.sortOrder,
			};

			localStorage.setItem('couponApplicationsDataTable', JSON.stringify(couponApplicationsDataTable));

			this.fetchData();
		});
	};

	getUserName = (obj) => {
		return (
			<div>{console.log(obj)}
				<Link to={`/users/show/${obj.user_id}`}>{obj.user?.name ?? '-'}</Link>
			</div>
		);
	};

	getAnnouncement = (obj) => {
		return (
			<div>
				<Link to={`/announcements/show/${obj.property_announcement_id}`}>{obj.property_announcement_id}</Link>
			</div>
		);
	};

	render() {
		const header = (
			<div>
				{this.state.boletoExists ?
					<Alert color="info">Ja existe um boleto para esse contrato</Alert>
					: false}
				{this.state.boletoError ?
					<Alert color="danger">Erro ao criar boleto</Alert>
					: false}
			</div>
		);

		return (
			<>
				<DataTable ref={(el) => this.dt = el} value={this.state.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={[10, 25, 50]}
					first={this.state.first} last={this.state.last} responsive={true} className="ml-auto"
					totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} loadingIcon={`fas fa-sync fa-spin`}
					paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
					currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" emptyMessage="Nenhum registro encontrado!"
					onSort={(e) => this.sortTable(e)} sortField={this.state.sortField} sortOrder={this.state.sortOrder}
					header={header}>
					<Column body={(obj) => this.getUserName(obj)} field={(obj) => this.getUserName(obj)} header="Usuário"></Column>
					<Column body={(obj, i) => this.getAnnouncement(obj)} field={(obj, i) => this.getAnnouncement(obj)} header="Imóvel" />
				</DataTable>
			</>
		);
	}
}