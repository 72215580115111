import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { get as getProposition } from "../../../crud/propositions.crud";
import { formatBRL } from "../../../helpers/Utils";
import { Link } from "react-router-dom";
import { faBuilding } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { get as getUser } from '../../../crud/users.crud';
import { get as getContract } from '../../../crud/contracts.crud';
import { getLaudoTag } from "../../../pages/negotiation/helpers";
import { getPropertiesById } from "../../../crud/announcements.crud";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";

export default class DataTableAnalises extends Component {
	constructor() {
		super();

		this.state = {
			property: {},
			data: [],
			rows: 10,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: "created_at",
			sortOrder: -1,
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters !== this.props.filters) this.fetchData();
	}

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = async (event) => {
		this.setState({ loading: true });

		if (event.data) event.data = null;

		let analiseDataTable = {
			rows: event.rows,
			totalRecords: this.state.totalRecords,
			data: this.data,
			first: event.first,
			last: event.rows + event.first,
			loading: this.state.loading,
			sortField: this.state.sortField,
			sortOrder: this.state.sortOrder,
		};

		localStorage.setItem("analiseDataTable", JSON.stringify(analiseDataTable));

		this.setState({ ...this.state, rows: event.rows });
		this.fetchData(event.rows, event.first);
	};

	fetchData = async (limit, skip) => {
		this.setState({ loading: true });

		let options = {
			$limit: limit ? limit : this.state.rows,
			$skip: skip != null ? skip : this.state.first,
			$sort: { [this.state.sortField]: this.state.sortOrder },
		};

		return this.props.fetchEvent(options).then(async (res) => {
			this.props.handleSetIndicadorAtual(res.data.total);

			await res?.data?.data?.map(async (obj, i) => {
				if (obj.property_announcement_id) {
					obj.loadingProperty = true;
					await getPropertiesById(obj.property_announcement_id).catch(err => {
						obj.property = null;
					}).then(res => {
						if (res?.data?.data) {
							obj.property = res.data.data;
							obj.loadingProperty = false;
						}
					});
				}

				if (obj.tenant_id) {
					obj.loading_tenant = true;
					await getUser(obj.tenant_id).catch(err => {
						obj.tenant = null;
						obj.loading_tenant = false;
					}).then(res => {
						if (res?.data?.data) {
							obj.tenant = res.data.data;
							obj.loading_tenant = false;
						}
					});
				}

				if (obj.proposition_id) {
					obj.proposition = true;

					await getProposition(obj.proposition_id).catch(err => {
						obj.proposition = null;
					}).then(async res => {
						if (res?.data?.data) {
							let proposition = res?.data?.data

							obj.proposition = proposition;

							if (proposition.landlord_id) {
								obj.loading_landlord = true;
								await getUser(proposition.landlord_id).catch(err => {
									obj.landlord = null;
									obj.loading_landlord = false;
								}).then(res => {
									if (res?.data?.data) {
										obj.landlord = res.data.data;
									}
								});
							}
						}
					}).catch(() => {

					}).finally(() => {
						this.setState({ loading: false });
					});
				}
			});

			if (res?.data?.data?.length <= 0) {
				this.setState({ loading: false });
			}

			this.setState({ data: res.data.data, totalRecords: res.data.total, first: skip, last: skip + res.data.limit });
		});
	};

	sortTable = async (e) => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder, loading: true }, () => {
			let analiseDataTable = {
				rows: this.state.rows,
				totalRecords: this.state.totalRecords,
				first: this.state.first,
				last: this.state.last,
				loading: this.state.loading,
				sortField: this.state.sortField,
				sortOrder: this.state.sortOrder,
				data: this.state.data
			};
			localStorage.setItem("analiseDataTable", JSON.stringify(analiseDataTable));

			this.fetchData();
		});
	};

	getValue = (laudo) => {
		let aluguel = parseFloat(laudo?.fc_laudo?.locacao.aluguel);
		let condo = laudo?.fc_laudo?.locacao?.condominio ? parseFloat(laudo?.fc_laudo?.locacao?.condominio) : 0;
		let iptu = laudo?.fc_laudo?.locacao?.iptu ? parseFloat(laudo?.fc_laudo?.locacao?.iptu) : 0

		let total = aluguel + condo + iptu;

		return (
			<div style={{ textAlign: "center" }}>
				<p>{formatBRL(total || 0)}</p>
			</div>
		);
	};

	getUserName = (obj, type) => {
		if (obj.landlord && obj.tenant) {
			return (
				<div style={{ textAlign: 'center' }}>
					<Link to={`/users/show/${obj.landlord?.id}`}>{obj.landlord?.name}, </Link> <Link to={`/users/show/${obj.tenant?.id}`} >{obj.tenant?.name}</Link>
				</div>
			)
		} else if (obj.tenant && !obj?.landlord) {
			return (
				<div style={{ textAlign: 'center' }}>
					<Link to={`/users/show/${obj.tenant?.id}`} >{obj.tenant?.name}</Link>
				</div>
			)
		} else if (obj.loading_tenant || obj.loading_landlord) {
			return <FontAwesomeIcon icon={faSpinner} spin />
		} else {
			return null;
		}
	};

	getAddress(property) {
		if (property?.address) {
			return (
				<Link to={`/announcements/show/${property?.id}`}>
					<div className='negotiation-active' style={{ textAlign: 'left' }}>
						<FontAwesomeIcon icon={faBuilding} style={{ marginRight: '8.5px' }} />

						{property.address.street} {property.address.number}, {property.address?.complement ?? ''} {property.address.neighbourhood ? `, ${property.address.neighbourhood}` : ''},  - {property.address.city}/{property.address.state}
					</div>
				</Link>
			)
		} else {
			return <FontAwesomeIcon icon={faSpinner} spin />
		}
	}

	getWarranty = (key, plan = {}) => {
		let garantia = '';

		if (key == 'FREE') {
			garantia = 'Grátis';
		} else if (key == 'WARRANTY') {
			garantia = 'Pagamento';
		} else if (key == 'WARRANTY_GUARANTEE_DAMAGE') {
			garantia = 'Completa';
		} else {
			garantia = 'Não identificado';
		}

		return (
			<div>
				{garantia}

				{plan?.percentage ?
					<div className="tag" style={{ backgroundColor: '#5664D2', marginTop: 4 }}>
						{plan.percentage}%
					</div>
					: null}
			</div>
		)
	};

	getIncomeXRent = (laudo) => {
		let incomeXrent = true;
		let aluguel = parseFloat(laudo?.fc_laudo?.locacao.aluguel) + parseFloat(laudo?.fc_laudo?.locacao?.condominio ?? 0) + parseFloat(laudo?.fc_laudo?.locacao?.iptu ?? 0);
		let quantoFalta = 0;

		let totalIncome = 0;

		if (laudo?.fc_laudo) {
			for (const inquilino of laudo?.fc_laudo?.parecer.sintese) {
				let tenantIncome = parseFloat(inquilino.renda.principal.valor);
				totalIncome = totalIncome + tenantIncome;
			}

			const minAccepted = (aluguel - aluguel * 0.2) * 3;

			if (totalIncome < minAccepted) {
				// ---------> RENDA X ALUGUEL RECUSADO
				incomeXrent = false;
				quantoFalta = (3 * aluguel) - totalIncome

				return (
					<div>
						{formatBRL(totalIncome)}

						<div className="tag" style={{ backgroundColor: '#FF3D60', marginTop: 2 }}>
							+ {formatBRL(quantoFalta)}
						</div>
					</div>
				)
			} else {
				let diff = (3 * aluguel) - totalIncome
				diff = Math.abs(diff)

				return (
					<div>
						{formatBRL(totalIncome)}

						<div className="tag" style={{ backgroundColor: '#1CBB8C', marginTop: 2 }}>
							+ {formatBRL(diff ?? 0)}
						</div>
					</div>
				)
			}
		}

	}

	columnOpcoesCrud = (rowData) => {
		let idRow = this.props.idRow ? this.props.idRow : 'id';

		if (this.props.crudUrl) {
			return (
				<Link to={`${this.props.crudUrl}/${rowData[idRow]}`} className='showButton'>
					<i className='fa fa-chevron-right' />
				</Link>
			);
		} else {
			return null;
		}
	};

	render() {
		return (
			<DataTable ref={(el) => this.dt = el} value={this.state.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={[10, 25, 50]}
				first={this.state.first} last={this.state.last} responsive={true} totalRecords={this.state.totalRecords} lazy={true} sortField={this.state.sortField}
				loading={this.state.loading} loadingIcon={`fas fa-sync fa-spin`} emptyMessage="Nenhum registro encontrado!" onSort={(e) => this.sortTable(e)}
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" onPage={this.onPage}
				currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" sortOrder={this.state.sortOrder}>
				<Column style={{ width: 138 }} sortable field="created_at" body={(obj, i) => moment(obj.created_at).format('DD/MM/YYYY HH:mm:ss')} header="Requisitado em" />
				<Column style={{ width: 194 }} sortable field="analysis_status" header="Status" body={(laudo) => getLaudoTag(laudo)} />
				<Column style={{ width: 217.5 }} sortable field="fc_laudo.locacao.proprietario" body={(obj, i) => this.getUserName(obj)} header="Constituintes" />
				<Column style={{ width: 217.5 }} field="property.details.place" header="Imóvel" body={({ property }) => this.getAddress(property)} />
				<Column style={{ width: 141 }} body={(laudo) => this.getIncomeXRent(laudo)} header="Renda somada" />
				<Column style={{ width: 102 }} body={(obj) => this.getWarranty(obj?.proposition?.plan?.name)} header="Garantia" />
				<Column style={{ width: 124 }} sortable body={(laudo) => this.getValue(laudo)} field="fc_laudo.locacao.aluguel" header="Valor total" />

				<Column header="" body={this.columnOpcoesCrud} style={{ width: '34px', padding: 0 }} />
			</DataTable>
		);
	}
}
