import React, { Component } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical, faEyeSlash, faGripVertical } from '@fortawesome/pro-solid-svg-icons';
import { Checkbox, ListItemText, Menu, MenuItem } from "@material-ui/core";
import { getContractsToBeJudialized } from "../../../crud/contracts.crud";
import DataTableContractsToBeJudicialized from "../../datatables/DataTableContractsToBeJudicialized";

export default class ContractToBeJudializedSection extends Component {
	constructor() {
		super();

		this.state = {
			metrics: [],
			contractToBeJudializedAvailableLabels: [
				{ label: "Contrato", isActive: true },
				{ label: "Garantias", isActive: true },
				{ label: "Vencimento", isActive: true },
				{ label: "Valor aluguel", isActive: true },
				{ label: "Responsável", isActive: true },
				{ label: "Inquilino", isActive: true },
			],
			dropdownKey: null,
			anchorEl: null,
			counter: 0
		};
	}

	render() {
		return (
			<Row className="container-center-input">
				<Col lg={12}>
					<Card >
						<CardBody>
							<div className="box-header">
								<div className="">
									<h3 className="title">Contratos a judicializar</h3>
									<span className="count-indicator">{this.state.counter}</span>
									<div className="box-info">
										Financeiro
									</div>
								</div>

								<div>
									<FontAwesomeIcon icon={faEllipsisVertical} style={{ color: '#5664D2', padding: '12px', cursor: 'pointer' }} onClick={(e) => {
										this.setState({ dropdownKey: this.props.itemKey });
										this.setState({ anchorEl: e.currentTarget });
									}} />
									<FontAwesomeIcon icon={faEyeSlash} style={{ color: '#5664D2', padding: '12px', cursor: 'pointer' }} onClick={() => this.props.changeWidgetValue(this.props.itemKey)} />
									<FontAwesomeIcon icon={faGripVertical} style={{ color: '#DADBDD', padding: '12px', cursor: 'pointer' }} />
								</div>
							</div>

							<Menu
								anchorEl={this.state.anchorEl}
								open={this.state.dropdownKey === this.props.itemKey ? true : false}
								onClose={() => { this.setState({ dropdownKey: null }) }}
								className="dashboard-item"
								id="dashboard-drop"
							>
								{this.state.contractToBeJudializedAvailableLabels.map((item) => (
									<MenuItem key={item.label} value={item.label}>
										<Checkbox checked={item.isActive} onChange={() => {
											this.setState(prevState => ({
												contractToBeJudializedAvailableLabels: prevState.contractToBeJudializedAvailableLabels.map(i =>
													i.label === item.label
														? { ...i, isActive: !i.isActive }
														: i
												)
											}));
										}} />
										<ListItemText primary={item.label} />
									</MenuItem>
								))}
							</Menu>

							<div style={{ position: 'relative', marginTop: '10px', marginBottom: '34px' }}>
								<hr className="divisor" />
							</div>

							<div style={{ height: '100%', overflow: 'auto', maxHeight: 690 }}>
								<DataTableContractsToBeJudicialized noDelete fetchEvent={getContractsToBeJudialized} crudButtons={true}
									crudUrl="/dashboard" idRow={`id`} type='contracts_to_be_judicialized' labels={this.state.contractToBeJudializedAvailableLabels} setState={this.setState.bind(this)} />
							</div>
						</CardBody>
					</Card>
				</Col>
			</Row>
		)
	}
}

