import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import _ from 'lodash';
import { formatBRL } from '../../../helpers/Utils';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';


export default class DataTableLogsUsuario extends Component {
	constructor() {
		super();

		this.state = {
			data: [],
			rows: 10,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: 'created_at',
			sortOrder: '1',
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		let DataTableLogsUsuario = JSON.parse(localStorage.getItem('DataTableLogsUsuario'));

		if (DataTableLogsUsuario) {
			this.setState({
				rows: DataTableLogsUsuario.rows,
				totalRecords: DataTableLogsUsuario.totalRecords,
				first: DataTableLogsUsuario.first,
				last: DataTableLogsUsuario.last,
				loading: DataTableLogsUsuario.loading,
				sortField: DataTableLogsUsuario.sortField,
				sortOrder: DataTableLogsUsuario.sortOrder,
			}, () => {
				this.fetchData();
			});
		} else {
			this.fetchData();
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters != this.props.filters) {
			this.onFilterChanged();
		}
	}

	onFilterChanged = _.debounce(() => {
		if (!this.state.isLoading) {
			this.fetchData();
		}
	}, 700);

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = async (event) => {
		if (event.data) {
			event.data = null;
		}

		let DataTableLogsUsuario = {
			rows: event.rows,
			totalRecords: this.state.totalRecords,
			first: event.first,
			last: event.rows + event.first,
			loading: this.state.loading,
			sortField: this.state.sortField,
			sortOrder: this.state.sortOrder,
		};

		localStorage.setItem('DataTableLogsUsuario', JSON.stringify(DataTableLogsUsuario));

		this.setState({
			...this.state,
			rows: event.rows,
			loading: true
		});

		this.fetchData(event.rows, event.first);
	};

	fetchData = (limit, skip) => {
		let data = {
			$limit: limit ? limit : this.state.rows,
			$skip: skip != null ? skip : this.state.first,
			$sort: { [this.state.sortField]: this.state.sortOrder }
		};

		this.props.fetchEvent(data).then(async ({ data }) => {
			console.log(data)
			this.setState({
				...this.state,
				data: data.data,
				totalRecords: data.total,
				first: data.skip,
				last: data.skip + data.limit,
				loading: false
			});
		});
	};

	sortTable = e => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder }, () => {
			let DataTableLogsUsuario = {
				rows: this.state.rows,
				totalRecords: this.state.totalRecords,
				first: this.state.first,
				last: this.state.last,
				loading: this.state.loading,
				sortField: this.state.sortField,
				sortOrder: this.state.sortOrder,
			};

			localStorage.setItem('DataTableLogsUsuario', JSON.stringify(DataTableLogsUsuario));

			this.fetchData();
		});
	};

	getContractAdminLogText = (operation, log) => {
		switch (operation) {
			case 'RESTORE_CONTRACT':
				return (
					<>
						<p>{`Data de rescisão anterior: ${moment(log?.terminated_at_before).format('DD/MM/YYYY HH:mm:ss')}`}</p>

						{log?.terminated_by_before &&
							<Link to={`/users/show/${log?.terminated_by_before}`}>
								{`Rescindido por: ${log?.terminated_by_before ?? 'N/A'}`}
							</Link>
						}
					</>
				)
			case 'comment':
				return (
					<p>{log?.comment ?? log}</p>
				)
			default:
				return 'N/A';
		}
	};

	getContractOperation = (operation) => {
		switch (operation) {
			case 'RESTORE_CONTRACT':
				return 'Contrato restaurado';
			case 'comment':
				return 'Comentário';
			default:
				return 'N/A';
		}
	};

	getUserLink = ({ id, name }) => {
		return (
			<div className='negotiation-active' style={{ textAlign: 'center' }}>
				<FontAwesomeIcon icon={faUserMagnifyingGlass} style={{ marginRight: '8.5px', color: '#4AA3FF' }} />

				<Link to={`/users/show/${id}`}>{name}</Link>
			</div>
		);
	}

	render() {
		return (
			<DataTable ref={(el) => this.dt = el} value={this.state.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={[10, 25, 50]}
				first={this.state.first} last={this.state.last} header={null} responsive={true} className="ml-auto"
				totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} loadingIcon={`fas fa-sync fa-spin`}
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
				currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" emptyMessage="Nenhum registro encontrado!"
				onSort={(e) => this.sortTable(e)} sortField={this.state.sortField} sortOrder={this.state.sortOrder}>
				<Column sortable field="created_at" body={({ created_at }) => moment(created_at).format('DD/MM/YYYY HH:mm:ss')} header="Criado em" />
				<Column field="type" header="Categoria" body={({ operation }) => this.getContractOperation(operation)} />
				<Column field="user.name" header="Usuário" body={({ user }) => this.getUserLink(user)} />
				<Column field="description" header="Informação" body={({ operation, log }) => this.getContractAdminLogText(operation, log)} style={{ maxWidth: '55%', width: '100%' }} />
			</DataTable>
		);
	}
}