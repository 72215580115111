import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import _ from 'lodash';
import moment from 'moment';
import { get as getBillings, getBillingSplitData, listRepayments, getFriendlyAgreement } from '../../../crud/billings.crud';
import { formatBRL } from '../../../helpers/Utils';
import { Link } from 'react-router-dom';
import { get as getUser } from '../../../crud/users.crud';
import { faUserMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';

import 'moment/locale/pt-br';

moment.locale('pt-br');
export default class DataTableBillingNegotiation extends Component {
	constructor() {
		super();

		this.state = {
			data: [],
			rows: 10,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: 'due_date',
			sortOrder: 1,
			boletoExists: false,
			boletoError: false,
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		this.setState({
			loading: true
		});

		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters != this.props.filters) {
			this.onFilterChanged();
		}
	}

	onFilterChanged = _.debounce(() => {
		if (!this.state.isLoading) {
			this.fetchData();
		}
	}, 700);

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = async (event) => {
		if (event.data) {
			event.data = null;
		}

		this.setState({
			...this.state,
			rows: event.rows,
			loading: true
		});

		this.fetchData(event.rows, event.first);
	};

	fetchData = (limit, skip) => {
		let options = {
			$limit: limit,
			$skip: skip != null ? skip : this.state.first,
			$sort: { [this.state.sortField]: this.state.sortOrder }
		};

		let friendlyAgreementsArr = [];

		options.type = this.props.paymentType;

		if (!this.props.contract_id) {
			this.setState({ ...this.state, data: [], totalRecords: 0, first: 0, last: 0, });
			return;
		}

		return this.props.fetchEvent(options).then(async res => {
			await getFriendlyAgreement({ contract_id: this.props.contract_id, $sort: { ['created_at']: 1 } }).then(({ data: { data: friendlyAgreement } }) => {
				if (friendlyAgreement) {
					friendlyAgreementsArr = friendlyAgreement;
					this.setState({ friendlyAgreement })
				}
			})

			res?.data?.data?.map(async (element, index) => {
				let paramsReembolso = {
					billing_id: element?.id,
					$limit: 50,
				};

				let number = 0;

				if (friendlyAgreementsArr.length > 0) {
					let numberAgr = 1;

					for (const agreement of friendlyAgreementsArr) {
						const usadoNoAcordo = agreement?.overdue_billings?.includes(element?.id);
						const parcelaDoAcordo = agreement.id == element.friendly_agreement_id;

						if (usadoNoAcordo) {
							element.acordo = true;

							if (element?.receipts?.rent?.paid && element?.receipts?.rent?.agreement_settled) {
								element.acordoQuitado = true;
							}
						}

						if (parcelaDoAcordo) {
							element.acordoNumero = numberAgr;
						}

						numberAgr += 1;
					}
				}

				if (element.type === 'termination') {
					number++;
					element.billingNumeroParcela = element?.number + 1;
				}

				await getBillingSplitData(element?.id).catch((err) => { }).then(({ data }) => {
					let adminValue = data.data.find((e) => e.user_type == "admin");
					let mellroValue = data.data.find((e) => e.user_type == "mellro");

					let landlordValue = data.data.find((e) => e.user_type == "landlord");
					let comissionedValue = data.data.find((e) => e.user_type == "commissioned");

					if (!adminValue) {
						adminValue = landlordValue;
					}

					element.split = {
						adminValue,
						mellroValue,
						comissionedValue: Number(landlordValue?.value) + Number(comissionedValue?.value ?? 0)
					}
				})

				await listRepayments(paramsReembolso).then(({ data }) => {
					let amount = 0;

					if (data.data.length > 0) {
						data.data.map((reembolso, index) => {
							if (reembolso?.status == "accepted") {
								if (reembolso?.type == "addition") {
									amount += Number(reembolso?.amount);
								} else if (reembolso?.type == "repayment") {
									amount -= Number(reembolso?.amount);
								}
							}
						});
					}

					element.receipts.rent.value += amount;
				}).catch((e) => { }).finally(() => {
					this.setState({
						...this.state,
						data: res.data.data,
						totalRecords: res.data.total,
						first: res.data.skip,
						last: res.data.skip + res.data.limit,
						loading: false
					});
				});
			});
		});
	};

	getValueIptu = (obj) => {
		return (
			<div>
				<p className="col-form-label">{obj?.receipts?.iptu?.value ? formatBRL(obj?.receipts?.iptu?.value) : 'R$ -'}</p>
			</div>
		);
	};

	getValueCondo = (obj) => {
		return (
			<div>
				<p className="col-form-label">{obj?.receipts?.condo?.value ? formatBRL(obj?.receipts?.condo?.value) : 'R$ -'}</p>
			</div>
		);
	};

	getValueRent = (obj) => {
		return (
			<div>
				<p className="col-form-label">{obj.type == 'termination' || obj.type === 'warranty_refund' ? formatBRL(obj.values.value) : obj?.receipts?.rent?.value ? formatBRL(obj?.receipts?.rent?.value) : 'R$ -'}</p>
			</div>
		);
	};

	getValueMoney = (value) => {
		return (
			<div>
				<p className="col-form-label">{value ? formatBRL(value) : 'R$ -'}</p>
			</div>
		);
	};

	getStatus = (obj) => {
		const momentDate = moment(obj?.due_date).add(3, 'hours');

		const daysToAdd = momentDate.day() === 6 ? 2 : momentDate.day() === 0 ? 1 : 0;

		const dueDate = moment(obj?.due_date).add(daysToAdd, 'days').add(3, "hours").format('YYYYMMDD');
		const currentDate = moment().format('YYYYMMDD');

		if (obj.acordo) {
			return (
				<div className=" d-flex flex-row" style={{ width: '128px', fontSize: '13px', lineHeight: '14px', height: 'auto', alignItems: 'center', backgroundColor: obj?.receipts?.rent?.paid ? '#1CBB8C' : '#4AA3FF' }}>
					<p className="col-form-label text-white" >{obj.acordoQuitado ? 'Acordo quitado' : 'Acordo vigente'}</p>
				</div>
			)
		}

		if (obj?.type === 'monthly' && obj?.termination_id) {
			return (
				<div className=" d-flex flex-row" style={{ width: '128px', fontSize: '13px', lineHeight: '14px', height: 'auto', alignItems: 'center', backgroundColor: '#5664D2' }}>
					<p className="col-form-label text-white" >Acerto de rescisão</p>
				</div>
			)
		}

		if (obj?.receipts?.rent?.paid === true && (obj?.receipts?.rent?.paid_for_insurer === false || !obj?.receipts?.rent?.paid_for_insurer)) {
			return (
				<>
					<div className="bg-success d-flex flex-row" style={{ width: '98px', fontSize: '13px', lineHeight: '16px', height: '24px', alignItems: 'center' }}>
						<p className="col-form-label text-white">{obj?.receipts?.rent?.paid ? 'Fatura paga' : 'N/A'}</p>
					</div>

					{obj.anticipated ?
						<div className="d-flex flex-row" style={{ width: '98px', marginTop: 5, fontSize: '13px', lineHeight: '16px', height: '24px', alignItems: 'center', backgroundColor: '#5664D2' }}>
							<p className="col-form-label text-white">Antecipado</p>
						</div>
						: null}
				</>
			);
		} else if (obj?.receipts?.rent?.paid_for_insurer == true && obj?.receipts?.rent?.paid == true) {
			return (
				<>
					<div className="d-flex flex-row" style={{ width: '140px', fontSize: '13px', lineHeight: '16px', height: '24px', alignItems: 'center', backgroundColor: '#5664D2' }}>
						<p className="col-form-label text-white">{obj?.receipts?.rent?.paid_for_insurer ? 'Garantido e pago' : 'N/A'}</p>
					</div>

					{obj.anticipated ?
						<div className="d-flex flex-row" style={{ width: '98px', marginTop: 5, fontSize: '13px', lineHeight: '16px', height: '24px', alignItems: 'center', backgroundColor: '#5664D2' }}>
							<p className="col-form-label text-white">Antecipado</p>
						</div>
						: null}
				</>
			);
		} else if (obj?.receipts?.rent?.paid_for_insurer == true && obj?.receipts?.rent?.paid == false) {
			return (
				<>
					<div className="d-flex flex-row" style={{ width: '140px', fontSize: '13px', lineHeight: '16px', height: '24px', alignItems: 'center', backgroundColor: '#F06595' }}>
						<p className="col-form-label text-white">{obj?.receipts?.rent?.paid_for_insurer ? 'Garantido e atrasado' : 'N/A'}</p>
					</div>

					{obj.anticipated ?
						<div className="d-flex flex-row" style={{ width: '98px', marginTop: 5, fontSize: '13px', lineHeight: '16px', height: '24px', alignItems: 'center', backgroundColor: '#5664D2' }}>
							<p className="col-form-label text-white">Antecipado</p>
						</div>
						: null}
				</>
			);
		} else if (moment().isSameOrBefore(obj.due_date, 'day') && obj.boleto) {
			return (
				<>
					<div className="bg-warning d-flex flex-row" style={{ width: '131px', fontSize: '13px', lineHeight: '16px', height: '24px', alignItems: 'center' }}>
						<p className="col-form-label text-white">Fatura em aberto</p>
					</div>

					{obj.anticipated ?
						<div className="d-flex flex-row" style={{ width: '98px', marginTop: 5, fontSize: '13px', lineHeight: '16px', height: '24px', alignItems: 'center', backgroundColor: '#5664D2' }}>
							<p className="col-form-label text-white">Antecipado</p>
						</div>
						: null}
				</>
			);
		} else if (moment().isAfter(moment(obj.due_date).add(1, 'd'), 'day')) {
			return (
				<>
					<div className="bg-danger d-flex flex-row" style={{ width: '120px', fontSize: '13px', lineHeight: '16px', height: '24px', alignItems: 'center' }}>
						<p className="col-form-label text-white">Fatura atrasada</p>
					</div>

					{obj.anticipated ?
						<div className="d-flex flex-row" style={{ width: '98px', marginTop: 5, fontSize: '13px', lineHeight: '16px', height: '24px', alignItems: 'center', backgroundColor: '#5664D2' }}>
							<p className="col-form-label text-white">Antecipado</p>
						</div>
						: null}
				</>
			);
		} else {
			return (
				<>
					<div className="bg-secondary d-flex flex-row" style={{ width: '98px', fontSize: '13px', lineHeight: '16px', height: '24px', alignItems: 'center' }}>
						<p className="col-form-label text-white">Aguardando</p>
					</div>

					{obj.anticipated ?
						<div className="d-flex flex-row" style={{ width: '98px', marginTop: 5, fontSize: '13px', lineHeight: '16px', height: '24px', alignItems: 'center', backgroundColor: '#5664D2' }}>
							<p className="col-form-label text-white">Antecipado</p>
						</div>
						: null}
				</>
			);
		}
	};

	getPaymentType = (billing) => {
		let terminationExists = false;

		if (billing.contract.entity.terminated_at || billing.contract.entity.termination_status) {
			terminationExists = true;
		}

		if (billing.type === 'monthly' && billing.number === 0) {
			return (
				<div>
					<p className="col-form-label">Primeiro Aluguel</p>
				</div>
			)
		} else if (billing.type === 'agreement') {
			return (
				<div>
					<p className="col-form-label">Acordo {billing?.acordoNumero} - Parcela {billing.number}</p>
				</div>
			)
		} else if (billing.type === 'warranty_refund') {
			return (
				<div>
					<p className="col-form-label">{`Devolução de garantia`}</p>
				</div>
			)
		} else if (billing.type === 'rent_refund') {
			return (
				<div>
					<p className="col-form-label">{`Devolução de aluguel`}</p>
				</div>
			)
		} else if (billing.type === 'termination' && billing?.values?.value === 0) {
			return (
				<div>
					<p className="col-form-label">{`Vistoria`}</p>
				</div>
			)
		} else if (billing.type === 'termination' && terminationExists) {
			return (
				<div>
					<p className="col-form-label">{`Parcela ${billing.billingNumeroParcela} Rescisão: ${billing.user_id == billing.landlord_id ? 'Responsável' : 'Inquilino'}`}</p>
				</div>
			)
		} else if (billing.type === 'termination' && !terminationExists) {
			return (
				<div>
					<p className="col-form-label">{`Vistoria`}</p>
				</div>
			)
		} else {
			return (
				<div>
					<p className="col-form-label">Aluguel mensal</p>
				</div>
			)
		}
	}

	columnOpcoesCrud = (rowData) => {
		let idRow = this.props.idRow ? this.props.idRow : 'id';

		if (this.props.crudUrl) {
			return (
				<Link to={`${this.props.crudUrl}/show/${rowData[idRow]}`} className='showButton'>
					<FontAwesomeIcon icon={faChevronRight} />
				</Link>
			);
		} else {
			return null;
		}
	};

	sortTable = e => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder }, () => {
			let contractsDataTable = {
				rows: this.state.rows,
				totalRecords: this.state.totalRecords,
				first: this.state.first,
				last: this.state.last,
				loading: this.state.loading,
				sortField: this.state.sortField,
				sortOrder: this.state.sortOrder,
			};

			localStorage.setItem('contractsDataTable', JSON.stringify(contractsDataTable));

			this.fetchData();
		});
	};

	getUserName = (obj, type) => {
		if (obj?.tenant?.name && obj?.landlord?.name) {
			return (
				<div className='negotiation-active' style={{ textAlign: 'center' }}>
					<FontAwesomeIcon icon={faUserMagnifyingGlass} style={{ marginRight: '8.5px' }} />

					<Link to={`/users/show/${obj?.tenant?.id}`} >{obj?.tenant?.name},</Link>

					<Link to={`/users/show/${obj?.landlord?.id}`}>{obj?.landlord?.name}</Link>
				</div>
			)
		}
	};

	render() {
		const header = (
			<div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row' }}>
				{this.props.titleTable &&
					<>
						<h3 style={{ margin: 0 }}>{this.props.titleTable}</h3>

						<div style={{ marginLeft: 8 }} className='indicadorUsuarios'>{this.state?.totalRecords ?? 0}</div>
					</>
				}
			</div>
		);

		const paginatorRight = (
			<button onClick={() => { this.fetchData(this.state?.totalRecords); this.setState({ rows: this.state?.totalRecords }) }} className='btnSecundary' style={{ marginLeft: 24 }}>
				Ver todos
			</button>
		);

		return (
			<>
				<DataTable ref={(el) => this.dt = el} value={this.state.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={[10, 25, 50, this.state?.totalRecords]}
					first={this.state.first} last={this.state.last} responsive={true} className="ml-auto" paginatorRight={paginatorRight}
					totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} loadingIcon={`fas fa-sync fa-spin`}
					paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
					currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" emptyMessage={this?.props?.messageNoData ?? "Nenhum registro encontrado!"}
					onSort={(e) => this.sortTable(e)} sortField={this.state.sortField} sortOrder={this.state.sortOrder} header={header}>
					<Column style={{ width: 120 }} body={(obj) => this.getStatus(obj)} field="status" header="Status" />
					<Column style={{ width: 136 }} sortable body={(obj) => this.getPaymentType(obj)} field="type" header="Transação" />
					<Column style={{ width: 104 }} sortable body={(obj) => obj?.created_at ? moment(obj?.boleto?.dueDate, 'YYYY-MM-DD').add(3, "hours").format('DD/MM/YYYY') : '--'} field="boleto.dueDate" header="Emitido" />
					<Column style={{ width: 118 }} sortable body={(obj) => obj?.due_date ? moment(obj?.due_date).add(3, "hours").format('DD/MM/YYYY') : '--'} field="due_date" header="Vencimento" />
					<Column style={{ width: 104 }} sortable body={(obj) => obj?.paid_at ? moment(obj?.paid_at).add(3, "hours").format('DD/MM/YYYY HH:mm:ss') : '--'} field="paid_at" header="Pago em" />
					<Column style={{ width: 108 }} sortable body={(obj) => this.getValueRent(obj)} field="receipts.receipts.values" header="Valor total" />

					<Column style={{ width: 108 }} body={(obj) => this.getValueMoney(obj?.split?.mellroValue?.value ?? 0)} field="split.mellroValue.value" header="$ Mellro" />
					<Column style={{ width: 108 }} body={(obj) => this.getValueMoney(obj?.split?.adminValue?.value ?? 0)} field="split.adminValue.value" header="$ proprietário" />
					<Column style={{ width: 108 }} body={(obj) => this.getValueMoney(obj?.split?.comissionedValue ?? 0)} field="split.comissionedValue" header="$ comissão" />

					<Column style={{ width: 34 }} header="" body={(obj) => this.columnOpcoesCrud(obj)} />
				</DataTable>
			</>
		);
	}
}