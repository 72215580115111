import React, { Component, createRef } from 'react';
import { Container, Card, Row, Col, Label, Input, Nav, NavItem, NavLink } from "reactstrap";
import Breadcrumbs from '../../../components/Breadcrumb';
import BetterAlerts from '../../../components/BetterAlerts';
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import BetterCircularProgress from '../../../components/BetterCircularProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { faBox, faChevronRight, faFilter, faMagnifyingGlass, faSpinner, faTimes, faUserPlus } from '@fortawesome/pro-regular-svg-icons';
import { faArrowRotateRight, faBoxOpenFull } from '@fortawesome/pro-solid-svg-icons';
import DataTableShipment from '../../../components/datatables/DataTableShipment';
import { getSecShipment as getAllByParams, uploadRetFile, getSecReturn } from '../../../crud/billings.crud';
import { getGratifications as getAllByParamsReturn, getTotalBonusRate } from '../../../crud/users.crud';
import DataTableReturn from '../../../components/datatables/DataTableSecReturn';
import DataTableGratifications from '../../../components/datatables/DataTableGratifications';

class GratificationIndex extends Component {
	dataTableRef = createRef();
	fileInputRef = createRef();

	state = {
		flags: {
			created_at_lte: null,
			created_at_gte: null,
			status: null
		},
		filters: {
			created_at_lte: null,
			created_at_gte: null,
			status: null
		},
		is_filter_open: false,
		errors: [],
		success: [],
		activeTab: '1',
		filtersCount: 0,
		newFilters: [],
		indicadores: {},
		totalBonusRate: 0,
		list_users: [],
		termo_user: '',
		secReturn: null,
		totalShipment: null,
		totalReturn: null,
	};

	componentDidMount = () => {
		getAllByParamsReturn().then(({ data, status }) => {
			if (status === 200) {
				this.setState({ totalReturn: data.total });
			}
		})

		getTotalBonusRate().then(({ data, status }) => {
			if (status === 200) {
				this.setState({ totalBonusRate: data.total_bonus_rate });
			}
		});
	};

	saveFilters = () => {
		localStorage.setItem('gratificationsFilter', JSON.stringify(this.state.filters));
		localStorage.setItem('gratificationsFlags', JSON.stringify(this.state.flags));
	};

	fetchByFilter = (data) => {
		var params = {
			...data,
		};

		return getAllByParamsReturn(params);
	};

	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({ activeTab: tab }, () => {
				this.setState({ totalAtual: 0 });
				this.dataTableRef?.current?.fetchData();
			});
		}
	};

	FiltersModule = ({ filtroModular, index }) => {
		const getFilterChoised = (filter) => {
			let component = <></>

			if (filter == 'created_at') {
				component = (
					<>
						<div className='d-flex'>
							<Label htmlFor="created_at_gte" className="col-form-label">De</Label>
							<Input className="form-control" type="date" id="created_at_gte" value={this.state.filters.created_at_gte} style={{ width: 200, margin: '0 16px' }}
								onChange={(e) => this.setState({ filters: { ...this.state.filters, created_at_gte: e.target.value } }, () => this.saveFilters())} />
						</div>

						<div className='d-flex'>
							<Label htmlFor="created_at_lte" className="col-form-label">Até</Label>
							<Input className="form-control" type="date" id="created_at_lte" value={this.state.filters.created_at_lte} style={{ width: 200, margin: '0 16px' }}
								onChange={(e) => this.setState({ filters: { ...this.state.filters, created_at_lte: e.target.value } }, () => this.saveFilters())} />
						</div>
					</>
				)
			} else if (filter == 'status' && this.state.activeTab === '1') {
				component = (
					<div className='d-flex'>
						<select onChange={(e) => this.setState({ filters: { ...this.state.filters, status: e.target.value } }, () => this.saveFilters())} style={{ width: 400, margin: '0 16px' }} className="custom-select">
							<option value="" hidden>Selecionar</option>
							<option value="GENERATED">Gerado</option>
							<option value="SENT">Enviado</option>
							<option value="PROCESSED">Processado</option>
						</select>
					</div>
				)
			} else {
				component = <></>
			}

			return component;
		}

		const onSetFilter = (filtro) => {
			let stateFilter = this.state.newFilters.slice();
			stateFilter[index] = filtro;

			this.setState({ newFilters: stateFilter })
		}

		const getSelectOptions = (nameFiltro) => {
			const stateFilter = this.state.newFilters;

			if (stateFilter.includes(nameFiltro)) {
				return 'd-none'
			} else {
				return ''
			}
		}

		const removeFilter = (filterUnit, filterName) => {
			let stateFilter = this.state.newFilters;

			stateFilter.splice(filterUnit, 1);

			switch (filterName) {
				case 'created_at':
					this.setState({
						flags: { ...this.state.flags, created_at_lte: false, created_at_gte: false },
						filters: { ...this.state.filters, created_at_lte: '', created_at_gte: '' }
					}, () => this.saveFilters())

					break;

				case 'status':
					this.setState({
						flags: { ...this.state.flags, status: false },
						filters: { ...this.state.filters, status: null }
					}, () => this.saveFilters())

					break;

				default:
					break;
			}

			this.setState({ newFilters: stateFilter })
		}

		return (
			<Col md={12} className="d-flex m-0 p-0 filtroUnit">
				<div className='d-flex'>
					<select onChange={({ target }) => onSetFilter(target.value)} value={this.state.newFilters[index]} className="custom-select" style={{ width: 300 }}>
						<option value="">Selecione um filtro</option>
						<option className={getSelectOptions('created_at')} value="created_at">Data</option>
						<option className={getSelectOptions('status')} value="status">Status</option>
					</select>

					{this.state.newFilters[index] != '' && (
						<div className='divisionArrow'>
							<FontAwesomeIcon icon={faChevronRight} />
						</div>
					)}

					{getFilterChoised(this.state.newFilters[index])}
				</div>

				<div onClick={() => removeFilter(index, this.state.newFilters[index])}>
					<FontAwesomeIcon icon={faTimes} />
				</div>
			</Col>
		)
	}

	uploadRetFile = async () => {
		this.fileInputRef.current.click();
	}

	handleFileSelect = async (event) => {
		const file = event.target.files[0];
		if (!file) return;


		const formData = new FormData();
		formData.append('file', file);

		await uploadRetFile(formData).then(res => {
			if (res.status === 200) {
				this.setState({ secReturn: res.data?.data });
				this.setState({ success: ['Arquivo .RET enviado com sucesso!'] });
			}
		})
	};


	render() {
		return (
			<React.Fragment>
				{this.state.success.length > 0 ? (
					<Redirect
						to={{
							pathname: `/pagamento/securitizacao/retorno/preview/${this.state.secReturn?.id}`,
							state: { success: ["Upload do arquivo .RET teve sucesso! Revise os dados antes de conciliar."] },
						}}
					/>
				) : null}
				<div className="page-content container-page userIndex">
					<Container fluid>
						<div className='header'>
							<h1>Gratificações</h1>

							<Breadcrumbs breadcrumbItems={[{ link: '/pagamento/cobrancas/index', title: 'Pagamentos' }, { link: '/announcements/index', title: 'Gratificações' }]} />
						</div>

						<BetterAlerts errors={this.state.errors} success={this.state.success} />

						<Col md={12} className="box-filtros">
							<Col md={12} className="d-flex m-0 p-0">
								<div className='generalSearch'>
									<Input className="inputCustomNew" type="text" value={this.state.filters.searchAll} placeholder="Busque por nome, e-mail ou telefone"
										onChange={(e) => this.setState({ filters: { ...this.state.filters, searchAll: e.target.value } }, () => this.saveFilters())} />

									<FontAwesomeIcon icon={faMagnifyingGlass} />
								</div>

								{/* <button onClick={() => this.setState({ newFilters: [...this.state.newFilters, ''] })} className="btnSecundary" style={{ width: 'max-content', margin: '0px 8px', padding: '8px 12px' }}>
									FILTRO
									<FontAwesomeIcon icon={faFilter} />
								</button> */}

								<button onClick={() => this.dataTableRef.current.refreshTable()} className="btnSecundary" style={{ width: 'max-content', margin: '0px 8px', padding: '8px 12px' }}>
									ATUALIZAR
									<FontAwesomeIcon icon={faArrowRotateRight} />
								</button>

								<Link to={`/pagamento/gratificacao/new`} className="buttonPrimary w-100" style={{ width: '100%', maxWidth: '126px', margin: '0px 8px' }}>
									ADICIONAR
									<FontAwesomeIcon icon={faUserPlus} />
								</Link>
							</Col>

							{this.state.newFilters.map((filtroModular, index) => {
								return <this.FiltersModule filtroModular={filtroModular} key={index} index={index} />
							})}
						</Col>

						<Row>
							<Col lg={12}>
								<Card className='card-general'>
									<h3 style={{ marginBottom: '32px' }}>Usuários com gratificações</h3>

									<div className=' gratification-counter'>
										<p>PRIMEIRO PAGAMENTO DISTRIBUÍDO</p>
										<div className='d-flex align-items-center'>
											<p className='p-green'>{this.state.totalBonusRate}</p>
											<p>/</p>
											<p className='p-grey'>50%</p>
										</div>
									</div>

									<div className='datatable-box'>
										<BetterCircularProgress loading={this.state.loading}>
											<DataTableGratifications filters={this.state.filters} noDelete fetchEvent={this.fetchByFilter} crudButtons={true}
												crudUrl="/pagamento/gratificacao/index" idRow={`id`} export={this.state.export} ref={this.dataTableRef} setState={this.setState.bind(this)} />
										</BetterCircularProgress>
									</div>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}

export default GratificationIndex;