import axios from "axios";
import { Constants } from '../helpers/Constants';
import { parseQuery } from '../helpers/Utils';

export function getAllTemplates(params) {
	return axios.get(`${Constants.baseUrl}/templates/sms`, { params: parseQuery(params) });
}

export function getTemplate(id) {
	return axios.get(`${Constants.baseUrl}/templates/sms/${id}`);
}

export function updateTemplate(id, data) {
	return axios.patch(`${Constants.baseUrl}/templates/sms/${id}`, data);
}


export function storeVariable(data) {
	return axios.post(`${Constants.baseUrl}/templates/variables`, data);
}