/* eslint-disable no-useless-computed-key */
/* eslint-disable eqeqeq */
import React, { Component, createRef } from 'react';
import { Container, Card, Row, Col, Label, Input, Nav, NavItem, NavLink, Button } from "reactstrap";
import Breadcrumbs from '../../components/Breadcrumb';
import DataTableUsers from '../../components/datatables/DataTableUsers';
import { getByFilter, getTotalUsers } from '../../crud/users.crud';
import { Link } from "react-router-dom";
import BetterAlerts from '../../components/BetterAlerts';
import classnames from 'classnames';
import BetterCircularProgress from '../../components/BetterCircularProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from "moment";
import { faChevronRight, faCopy, faFile, faFilter, faMagnifyingGlass, faRotateRight, faSpinner, faTimes, faUserPlus } from '@fortawesome/pro-regular-svg-icons';

class UsersIndex extends Component {
	dataTableRef = createRef();

	state = {
		errors: [],
		success: [],
		activeTab: '1',
		filtersCount: 0,
		newFilters: [],
		indicadores: {},
		loading: false,
		totalAtual: 0,
		blockInput: false,
		filters: {
			name: '',
			email: '',
			phone: '',
			context: '',
			signup_source: '',
			integration_source: '',
			portal_source: '',
			pending_signup: 'false',
			signup_after: '',
			signup_before: '',
			type_account: '',
			roles: 'admin',
			user_city: '',
			active_contracts_lte: '',
			active_contracts_gte: ''
		},
		export: false,
		flags: {
			show_name: false,
			show_email: false,
			show_phone: false,
			show_context: false,
			show_signup_source: false,
			show_integration_source: false,
			show_portal_source: false,
			show_pending_signup: true,
			show_signup_after: false,
			show_signup_before: false,
			show_user_city: false,
			show_type_account: false,
			show_roles: false,
		},
		is_filter_open: false,
	}

	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({ activeTab: tab }, () => { this.setState({ totalAtual: 0 }); this.dataTableRef.current.fetchData(); });
		}
	};

	componentDidMount = () => {
		let filters = this.state.filters;
		let flags = this.state.flags;
		let newFilters = this.state.newFilters;

		if (this.props?.location?.state?.propertyCont) {
			const splited = this.props?.location?.state?.propertyCont.split('-');

			if (this.props?.location?.state?.filtersPropertyPerLandlord?.data_cadastro) {
				const rangeDate = this.props?.location?.state?.filtersPropertyPerLandlord?.data_cadastro;
				let created_at_gte = '';

				switch (rangeDate) {
					case 'last_week':
						created_at_gte = moment().subtract(7, 'days').format('YYYY-MM-DD');
						break;
					case 'last_third':
						created_at_gte = moment().subtract(30, 'days').format('YYYY-MM-DD');
						break;
					case 'last_ninety':
						created_at_gte = moment().subtract(90, 'days').format('YYYY-MM-DD');
						break;
					case 'last_year':
						created_at_gte = moment().subtract(365, 'days').format('YYYY-MM-DD');
						break;
					case 'always':
						break;
				}

				if (created_at_gte !== '') {
					flags = {
						...flags,
						announcements_created_at: true
					}

					filters = {
						...filters,
						announcements_created_at_gte: created_at_gte
					}

					newFilters.push('announcements_created_at')
				}
			}

			if (this.props?.location?.state?.filtersPropertyPerLandlord?.property_type == "just_rented") {
				this.setState({
					flags: { ...flags, active_contracts: true },
					filters: { ...filters, active_contracts_gte: splited[0], active_contracts_lte: splited[1] }
				}, () => this.saveFilters());

				this.setState({ newFilters: [...newFilters, 'active_contracts'] })
			} else {
				this.setState({
					flags: { ...flags, active_announcements: true },
					filters: { ...filters, active_announcements_gte: splited[0], active_announcements_lte: splited[1] }
				}, () => this.saveFilters());

				this.setState({ newFilters: [...newFilters, 'active_announcements'] })
			}

			this.setState({ activeTab: '2' })
		}

		// if (localStorage.getItem('userFilters')) {
		// 	let filtersStorage = JSON.parse(localStorage.getItem('userFilters'));

		// this.setState({ filters, flags });

		if (this.props.location.state) {
			if (this.props.location.state.success) {
				this.setState({ success: this.props.location.state.success });
			}
		}

		getTotalUsers().catch(err => {
			alert('Ocorreu um erro ao carregar o número de usuarios, entre em contato com o suporte.');
		}).then(async res => {
			if (res?.status === 200) {
				if (res.data) {
					this.setState({ indicadores: res.data });
				}
			}
		});
	};

	saveFilters = () => {
		let filterToSave = this.state.filters;

		if (Array.isArray(filterToSave?.user_city)) {
			filterToSave.user_city = '';
		}

		localStorage.setItem('userFilters', JSON.stringify(filterToSave));
		localStorage.setItem('userFlags', JSON.stringify(this.state.flags));
	};

	fetchByFilter = (data) => {
		this.setState({ blockInput: true })
		let params = { ...data };

		if (this.state.filters.searchAll?.trim() != '') params.searchAll = this.state.filters.searchAll;
		if (this.state.filters.signup_after?.trim() != '') params.created_at_gte = this.state.filters.signup_after;
		if (this.state.filters.signup_before?.trim() != '') params.created_at_lte = this.state.filters.signup_before;
		if (this.state.filters.name?.trim() != '') params.name_ilike = this.state.filters.name.replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '%');
		if (this.state.filters.integration_source?.trim() != '') params.metadata = { ['lead_source']: this.state.filters.integration_source };
		if (this.state.filters.portal_source?.trim() != '') params.metadata = { ...params.metadata, ['lead_subsource']: this.state.filters.portal_source };
		if (this.state.filters.email?.trim() != '') params.email_ilike = this.state.filters.email;
		if (this.state.filters.phone?.trim() != '') params.phone_ilike = this.state.filters.phone;
		if (this.state.filters.signup_source?.trim() != '') params.signup_source = this.state.filters.signup_source;
		if (this.state.filters.type_account?.trim() != '') params.type_account = this.state.filters.type_account;

		if (this.state.filters.active_contracts_gte?.trim() != '') params.active_contracts_gte = this.state.filters.active_contracts_gte;
		if (this.state.filters.active_contracts_lte?.trim() != '') params.active_contracts_lte = this.state.filters.active_contracts_lte;
		if (this.state.filters.active_announcements_gte?.trim() != '') params.active_announcements_gte = this.state.filters.active_announcements_gte;
		if (this.state.filters.active_announcements_lte?.trim() != '') params.active_announcements_lte = this.state.filters.active_announcements_lte;
		if (this.state.filters.announcements_created_at_gte?.trim() != '') params.announcements_created_at_gte = this.state.filters.announcements_created_at_gte;
		if (this.state.filters.announcements_created_at_lte?.trim() != '') params.announcements_created_at_lte = this.state.filters.announcements_created_at_lte;


		if (this.state.filters.context?.trim() != '') {
			if (this.state.filters.context == "PJ") {
				params.type_account = "PJ";
			} else {
				params.context = this.state.filters.context;
			}
		}

		if (this.state.filters.user_city?.trim() != '') {
			params.address = { ['city_ilike']: [this.state.filters.user_city?.trim()] }
		}

		getTotalUsers(params).catch(err => {
			alert('Ocorreu um erro ao carregar o número de usuarios, entre em contato com o suporte.');
		}).then(async res => {
			if (res?.status === 200) {
				if (res.data) {
					this.setState({ indicadores: res.data });
				}
			}
		});

		if (this.state.activeTab === '1') {
			params.context = 'Tenant';
			// params.rd_roles = 'rd_tenant';
		}

		if (this.state.activeTab === '2') {
			// params.context = 'Landlord';
			params.rd_roles = 'rd_admin'
		}

		if (this.state.activeTab === '3') {
			// params.getAfiliados = true;
			params.rd_roles = 'rd_commissioned';
		}

		if (this.state.activeTab === '4') {
			// params.pending_signup = true;
			params.rd_roles = 'rd_landlord';
		}

		if (this.state.activeTab === '5') {
			// params.role_admin = true;
			params.rd_roles = null;
		}

		if (this.state.activeTab === '6') {
			params.getAfiliados = true;
		}

		if (this.state.activeTab === '7') {
			params.pending_signup = true;
		}

		if (this.state.activeTab === '8') {
			params.role_admin = true;
		}

		return getByFilter(params).then((res) => {
			this.setState({ blockInput: false })
			return res
		})
	};

	FiltersModule = ({ filtroModular, index }) => {
		const getFilterChoised = (filter) => {
			let component = <></>

			if (filter == 'data_cadastro') {
				component = (
					<>
						<div className='d-flex'>
							<Label htmlFor="signup_after" className="col-form-label">De</Label>
							<Input className="form-control" type="date" id="signup_after" value={this.state.filters.signup_after} style={{ width: 200, margin: '0 16px' }}
								onChange={(e) => this.setState({ filters: { ...this.state.filters, signup_after: e.target.value } }, () => this.saveFilters())} />
						</div>

						<div className='d-flex'>
							<Label htmlFor="signup_before" className="col-form-label">Até</Label>
							<Input className="form-control" type="date" id="signup_before" value={this.state.filters.signup_before} style={{ width: 200, margin: '0 16px' }}
								onChange={(e) => this.setState({ filters: { ...this.state.filters, signup_before: e.target.value } }, () => this.saveFilters())} />
						</div>
					</>
				)
			} else if (filter == 'name') {
				component = (
					<div className='d-flex'>
						<Input className="form-control" type="text" value={this.state.filters.name} style={{ width: 400, margin: '0 16px' }} placeholder="Pesquisar por nome"
							onChange={(e) => this.setState({ filters: { ...this.state.filters, name: e.target.value } }, () => this.saveFilters())} />
					</div>
				)
			} else if (filter == 'email') {
				component = (
					<div className='d-flex'>
						<Input className="form-control" type="email" value={this.state.filters.email} style={{ width: 400, margin: '0 16px' }} placeholder="Pesquisar por e-mail"
							onChange={(e) => this.setState({ filters: { ...this.state.filters, email: e.target.value } }, () => this.saveFilters())} />
					</div>
				)
			} else if (filter == 'telefone') {
				component = (
					<div className='d-flex'>
						<Input className="form-control" type="text" value={this.state.filters.phone} style={{ width: 400, margin: '0 16px' }} placeholder="Pesquisar por telefone"
							onChange={(e) => this.setState({ filters: { ...this.state.filters, phone: e.target.value } }, () => this.saveFilters())} />
					</div>
				)
			} else if (filter == 'cadastro') {
				component = (
					<div className='d-flex'>
						<select onChange={(e) => this.setState({ filters: { ...this.state.filters, signup_source: e.target.value } }, () => this.saveFilters())} style={{ width: 400, margin: '0 16px' }} className="custom-select">
							<option value="">Pesquisar por origem de cadastro</option>
							<option value="organic">Orgânico</option>
							<option value="crawler">Crawler</option>
							<option value="affiliate">Afiliado</option>
							<option value="listing_lead">Integrações</option>
						</select>
					</div>
				)
			} else if (filter == 'integração') {
				component = (
					<div className='d-flex'>
						<select onChange={(e) => this.setState({ filters: { ...this.state.filters, integration_source: e.target.value } }, () => this.saveFilters())} style={{ width: 400, margin: '0 16px' }} className="custom-select">
							<option value="">Pesquisar por integração</option>
							<option value="olx">OLX</option>
							<option value="zap">Zap</option>
						</select>
					</div>
				)
			} else if (filter == 'portal_origem') {
				component = (
					<div className='d-flex'>
						<select onChange={(e) => this.setState({ filters: { ...this.state.filters, portal_source: e.target.value } }, () => this.saveFilters())} style={{ width: 400, margin: '0 16px' }} className="custom-select">
							<option value="">Pesquisar por portal de origem</option>
							<option value="viva_real">Viva Real</option>
							<option value="grupo_zap">Grupo Zap</option>
						</select>
					</div>
				)
			} else if (filter == 'cidade') {
				component = (
					<div className='d-flex'>
						<Input className="form-control" type="text" value={this.state.filters.user_city} onChange={(e) => this.setState({ filters: { ...this.state.filters, user_city: e.target.value } }, () => this.saveFilters())} style={{ width: 400, margin: '0 16px' }} placeholder="Pesquisar por cidade" />
					</div>
				)
			} else if (filter == 'active_contracts') {
				component = (
					<div className='d-flex'>
						<div style={{ position: 'relative' }}>
							<Input className="form-control" disabled={this.state?.blockInput} type="text" value={this.state.filters.active_contracts_gte} onChange={(e) => this.setState({ filters: { ...this.state.filters, active_contracts_gte: e.target.value } }, () => this.saveFilters())} style={{ width: 200, margin: '0px 16px 0px 0px' }} placeholder="De" />
							<FontAwesomeIcon icon={faFile} style={{ fontSize: '12px', color: '#495057', position: 'absolute', right: '27px', top: '12px' }} />
						</div>
						<div style={{ position: 'relative' }}>
							<Input className="form-control" disabled={this.state?.blockInput} type="text" value={this.state.filters.active_contracts_lte} onChange={(e) => this.setState({ filters: { ...this.state.filters, active_contracts_lte: e.target.value } }, () => this.saveFilters())} style={{ width: 200, margin: '0px 16px 0px 0px' }} placeholder="a" />
							<FontAwesomeIcon icon={faCopy} style={{ fontSize: '12px', color: '#495057', position: 'absolute', right: '27px', top: '12px' }} />
						</div>
					</div>
				)
			} else if (filter == 'active_announcements') {

				component = (
					<div className='d-flex'>
						<div style={{ position: 'relative' }}>
							<Input className="form-control" type="text" disabled={this.state?.blockInput} value={this.state.filters.active_announcements_gte} onChange={(e) => this.setState({ filters: { ...this.state.filters, active_announcements_gte: e.target.value } }, () => this.saveFilters())} style={{ width: 200, margin: '0px 16px 0px 0px' }} placeholder="De" />
							<FontAwesomeIcon icon={faFile} style={{ fontSize: '12px', color: '#495057', position: 'absolute', right: '27px', top: '12px' }} />
						</div>
						<div style={{ position: 'relative' }}>
							<Input className="form-control" type="text" disabled={this.state?.blockInput} value={this.state.filters.active_announcements_lte} onChange={(e) => this.setState({ filters: { ...this.state.filters, active_announcements_lte: e.target.value } }, () => this.saveFilters())} style={{ width: 200, margin: '0px 16px 0px 0px' }} placeholder="a" />
							<FontAwesomeIcon icon={faCopy} style={{ fontSize: '12px', color: '#495057', position: 'absolute', right: '27px', top: '12px' }} />
						</div>
					</div>
				)
			} else if (filter == 'announcements_created_at') {
				component = (
					<div className='d-flex'>
						<div style={{ position: 'relative' }}>
							<Input className="form-control" type="date" disabled={this.state?.blockInput} value={this.state.filters.announcements_created_at_gte} onChange={(e) => this.setState({ filters: { ...this.state.filters, announcements_created_at_gte: e.target.value } }, () => this.saveFilters())} style={{ width: 200, margin: '0px 16px 0px 0px' }} placeholder="De" />
						</div>
						<div style={{ position: 'relative' }}>
							<Input className="form-control" type="date" disabled={this.state?.blockInput} value={this.state.filters.announcements_created_at_lte} onChange={(e) => this.setState({ filters: { ...this.state.filters, announcements_created_at_lte: e.target.value } }, () => this.saveFilters())} style={{ width: 200, margin: '0px 16px 0px 0px' }} placeholder="a" />
						</div>
					</div>
				)
			} else {
				component = <></>
			}

			return component;
		}

		const onSetFilter = (filtro) => {
			let stateFilter = this.state.newFilters.slice();
			stateFilter[index] = filtro;

			this.setState({ newFilters: stateFilter })
		}

		const getSelectOptions = (nameFiltro) => {
			const stateFilter = this.state.newFilters;

			if (stateFilter.includes(nameFiltro)) {
				return 'd-none'
			} else {
				return ''
			}
		}

		const removeFilter = (filterUnit, filterName) => {
			let stateFilter = this.state.newFilters;

			stateFilter.splice(filterUnit, 1);

			switch (filterName) {
				case 'name':
					this.setState({
						flags: { ...this.state.flags, show_name: false },
						filters: { ...this.state.filters, name: '' }
					}, () => this.saveFilters())

					break;

				case 'email':
					this.setState({
						flags: { ...this.state.flags, show_email: false },
						filters: { ...this.state.filters, email: '' }
					}, () => this.saveFilters())

					break;

				case 'telefone':
					this.setState({
						flags: { ...this.state.flags, show_phone: false },
						filters: { ...this.state.filters, phone: '' }
					}, () => this.saveFilters())

					break;

				case 'cadastro':
					this.setState({
						flags: { ...this.state.flags, show_signup_source: false },
						filters: { ...this.state.filters, signup_source: '' }
					}, () => this.saveFilters())

					break;

				case 'integração':
					this.setState({
						flags: { ...this.state.flags, show_integration_source: false },
						filters: { ...this.state.filters, integration_source: '' }
					}, () => this.saveFilters())

					break;

				case 'portal_origem':
					this.setState({
						flags: { ...this.state.flags, show_portal_source: false },
						filters: { ...this.state.filters, portal_source: '' }
					}, () => this.saveFilters())

					break;

				case 'data_cadastro':
					this.setState({
						flags: { ...this.state.flags, show_signup_after: false, show_signup_before: false },
						filters: { ...this.state.filters, signup_after: '', signup_before: '' }
					}, () => this.saveFilters())

					break;

				case 'cidade':
					this.setState({
						flags: { ...this.state.flags, show_user_city: false },
						filters: { ...this.state.filters, user_city: '' }
					}, () => this.saveFilters())

				case 'active_contracts':
					this.setState({
						flags: { ...this.state.flags, active_contracts: false },
						filters: { ...this.state.filters, active_contracts_gte: '', active_contracts_lte: '' }
					}, () => this.saveFilters())
					break;

				case 'active_announcements':
					this.setState({
						flags: { ...this.state.flags, active_announcements: false },
						filters: { ...this.state.filters, active_announcements_gte: '', active_announcements_lte: '' }
					}, () => this.saveFilters())
					break;

				case 'announcements_created_at':
					this.setState({
						flags: { ...this.state.flags, announcements_created_at: false },
						filters: { ...this.state.filters, announcements_created_at_gte: '', announcements_created_at_lte: '' }
					}, () => this.saveFilters())
					break;

					break;
				default:
					break;
			}

			this.setState({ newFilters: stateFilter })
		}

		return (
			<Col md={12} className="d-flex m-0 p-0 filtroUnit">
				<div className='d-flex'>
					<select onChange={({ target }) => onSetFilter(target.value)} value={this.state.newFilters[index]} className="custom-select" style={{ width: 300 }}>
						<option value="">Selecione um filtro</option>
						<option className={getSelectOptions('name')} value="name">Nome</option>
						<option className={getSelectOptions('email')} value="email">Email</option>
						<option className={getSelectOptions('telefone')} value="telefone">Telefone</option>
						<option className={getSelectOptions('cadastro')} value="cadastro">Origem do cadastro</option>
						<option className={getSelectOptions('integração')} value="integração">Origem da integração</option>
						<option className={getSelectOptions('portal_origem')} value="portal_origem">Portal de origem</option>
						<option className={getSelectOptions('data_cadastro')} value="data_cadastro">Data de cadastro</option>
						<option className={getSelectOptions('cidade')} value="cidade">Cidade</option>
						<option className={getSelectOptions('active_contracts')} value="active_contracts">Qntd. de contratos ativos</option>
						<option className={getSelectOptions('active_announcements')} value="active_announcements">Qntd. de imóveis registrados</option>
						<option className={getSelectOptions('announcements_created_at')} value="announcements_created_at">Com imóveis cadastrados em</option>
					</select>

					{this.state.newFilters[index] != '' && (
						<div className='divisionArrow'>
							<FontAwesomeIcon icon={faChevronRight} />
						</div>
					)}

					{getFilterChoised(this.state.newFilters[index])}
				</div>


				<div onClick={() => removeFilter(index, this.state.newFilters[index])}>
					<FontAwesomeIcon icon={faTimes} />
				</div>
			</Col>
		)
	}

	handleSetIndicadorAtual = (indicador) => {
		this.setState({ totalAtual: indicador })
	}

	render() {
		return (
			<React.Fragment>
				<div className="page-content container-page userIndex">
					<Container fluid>
						<div className='header'>
							<h1>Usuários</h1>

							<Breadcrumbs breadcrumbItems={[{ link: '/users/index', title: 'Cadastros' }, { link: '/users/index', title: 'Usuários' }]} />
						</div>

						<BetterAlerts errors={this.state.errors} success={this.state.success} />

						<Col md={12} className="box-filtros">
							<Col md={12} className="d-flex m-0 p-0">
								<div className='generalSearch'>
									<Input className="inputCustomNew" type="text" value={this.state.filters.searchAll} placeholder="Busque por nome, email, telefone, cidade, ID ou documento"
										onChange={(e) => this.setState({ filters: { ...this.state.filters, searchAll: e.target.value } }, () => this.saveFilters())} />

									<FontAwesomeIcon icon={faMagnifyingGlass} />
								</div>

								<button onClick={() => this.setState({ newFilters: [...this.state.newFilters, ''] })} className="btnSecundary" style={{ width: 'max-content', margin: '0px 8px', padding: '8px 12px' }}>
									FILTRO
									<FontAwesomeIcon icon={faFilter} />
								</button>

								<button onClick={() => this.dataTableRef.current.fetchData()} className="btnSecundary" style={{ width: 'max-content', margin: '0px 8px', padding: '8px 12px' }}>
									ATUALIZAR
									<FontAwesomeIcon icon={faRotateRight} />
								</button>

								<Link to={`/users/new`} className="buttonPrimary w-100" style={{ width: '100%', maxWidth: '97px' }}>
									CRIAR
									<FontAwesomeIcon icon={faUserPlus} />
								</Link>
							</Col>

							{this.state.newFilters.map((filtroModular, index) => {
								return <this.FiltersModule filtroModular={filtroModular} key={index} index={index} />
							})}
						</Col>

						<Row>
							<Col lg={12}>
								<Card className='card-general'>
									<Nav tabs className="nav-tabs-custom" role="tablist" style={{ marginBottom: '32px' }}>
										<NavItem>
											<NavLink id="info-tab" className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggleTab('1'); }}>
												<div className='tabName'>Inquilinos
													<div className='indicadorUsuarios'>
														{this.state?.indicadores?.inquilinos ?? <FontAwesomeIcon spin icon={faSpinner} />}
													</div>
												</div>
											</NavLink>
										</NavItem>

										<NavItem>
											<NavLink id="announcements-tab" className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggleTab('2'); }}>
												<div className='tabName'>Responsáveis
													<div className='indicadorUsuarios'>
														{this.state?.indicadores?.proprietarios ?? <FontAwesomeIcon spin icon={faSpinner} />}
													</div>
												</div>
											</NavLink>
										</NavItem>

										<NavItem>
											<NavLink id="announcements-tab" className={classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.toggleTab('3'); }}>
												<div className='tabName'>Comissionados
													<div className='indicadorUsuarios'>
														{this.state?.indicadores?.comissionados ?? <FontAwesomeIcon spin icon={faSpinner} />}
													</div>
												</div>
											</NavLink>
										</NavItem>

										<NavItem>
											<NavLink id="announcements-tab" className={classnames({ active: this.state.activeTab === '4' })} onClick={() => { this.toggleTab('4'); }}>
												<div className='tabName'>Donos
													<div className='indicadorUsuarios'>
														{this.state?.indicadores?.donos ?? <FontAwesomeIcon spin icon={faSpinner} />}
													</div>
												</div>
											</NavLink>
										</NavItem>

										<NavItem>
											<NavLink id="negotiations-tab" className={classnames({ active: this.state.activeTab === '5' })} onClick={() => { this.toggleTab('5'); }}>
												<div className='tabName'>Usuários
													<div className='indicadorUsuarios'>
														{this.state?.indicadores?.usersDefault ?? <FontAwesomeIcon spin icon={faSpinner} />}
													</div>
												</div>
											</NavLink>
										</NavItem>

										<NavItem>
											<NavLink id="negotiations-tab" className={classnames({ active: this.state.activeTab === '6' })} onClick={() => { this.toggleTab('6'); }}>
												<div className='tabName'>Afiliados
													<div className='indicadorUsuarios'>
														{this.state?.indicadores?.afiliados ?? <FontAwesomeIcon spin icon={faSpinner} />}
													</div>
												</div>
											</NavLink>
										</NavItem>

										<NavItem>
											<NavLink id="negotiations-tab" className={classnames({ active: this.state.activeTab === '7' })} onClick={() => { this.toggleTab('7'); }}>
												<div className='tabName'>Não confirmado
													<div className='indicadorUsuarios'>
														{this.state?.indicadores?.naoConfirmados ?? <FontAwesomeIcon spin icon={faSpinner} />}
													</div>
												</div>
											</NavLink>
										</NavItem>

										<NavItem>
											<NavLink id="negotiations-tab" className={classnames({ active: this.state.activeTab === '8' })} onClick={() => { this.toggleTab('8'); }}>
												<div className='tabName'>Admin
													<div className='indicadorUsuarios'>
														{this.state?.indicadores?.admin ?? <FontAwesomeIcon spin icon={faSpinner} />}
													</div>
												</div>
											</NavLink>
										</NavItem>
									</Nav>

									<div className='datatable-box'>
										<BetterCircularProgress loading={this.state.loading}>
											<DataTableUsers filters={this.state.filters} noDelete fetchEvent={this.fetchByFilter} activeTab={this.state.activeTab} crudButtons={true}
												crudUrl="/users" idRow={`id`} noShow ref={this.dataTableRef} handleSetIndicadorAtual={this.handleSetIndicadorAtual} />
										</BetterCircularProgress>
									</div>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		)
	}
}

export default UsersIndex;