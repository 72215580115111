import React, { Component } from "react";
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins, faFilePen, faGauge, faIdCard, faInboxIn } from "@fortawesome/pro-regular-svg-icons";

class SidebarContent extends Component {
	constructor(props) {
		super(props);
		this.metisMenu = null;
	}

	componentDidMount() {
		this.initMenu();
	}

	componentDidUpdate(prevProps) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			this.initMenu();
		}
	}

	componentWillUnmount() {
		if (this.metisMenu) {
			this.metisMenu.dispose();
		}
	}

	initMenu() {
		if (this.metisMenu) {
			this.metisMenu.dispose();
		}

		this.metisMenu = new MetisMenu("#side-menu");

		let matchingMenuItem = null;
		const ul = document.getElementById("side-menu");
		const items = ul.getElementsByTagName("a");

		for (let i = 0; i < items.length; ++i) {
			if (this.props.location.pathname === items[i].pathname) {
				matchingMenuItem = items[i];
				break;
			}
		}

		if (matchingMenuItem) {
			this.activateParentDropdown(matchingMenuItem);
		}
	}

	activateParentDropdown = (item) => {
		const keywordMappings = [
			{ keywords: ['crawler', 'crawlerConfig', 'coupons'], listId: 'list1' },
			{ keywords: ['users', 'announcements', 'notifications'], listId: 'list2' },
			{ keywords: ['analise', 'negotiation'], listId: 'list3' },
			{ keywords: ['pagamento'], listId: 'list4' },
		];

		let parent = null;

		keywordMappings.forEach(({ keywords, listId }) => {
			if (keywords.some((keyword) => item.pathname.includes(keyword))) {
				parent = document.getElementById(listId);
			}
		});

		while (parent) {
			parent.classList.add("mm-active");

			let childUl = parent.querySelector("ul");

			if (childUl) {
				childUl.classList.add("mm-show");
			}

			const parentUl = parent.parentElement;

			if (parentUl && parentUl.classList.contains("sub-menu")) {
				parentUl.classList.add("mm-show");
			}

			parent = parentUl ? parentUl.closest("li") : null;
		}
	};

	render() {
		const path = this.props.location.pathname;

		return (
			<div id="sidebar-menu">
				<ul className="metismenu list-unstyled" id="side-menu">
					<li id="list0" className={path.includes('/dashboard') && 'selected-dash'}>
						<a href="/dashboard" className="waves-effect">
							<FontAwesomeIcon icon={faGauge} />
							<span className="ml-3">Dashboard geral</span>
						</a>
					</li>

					<li id="list1">
						<a href="#" className="has-arrow waves-effect">
							<FontAwesomeIcon icon={faInboxIn} />
							<span className="ml-3">Aquisição</span>
						</a>

						<ul className="sub-menu">
							<li><a href="/crawler/index" className={path.includes('/crawler/index') && 'selected'}>Crawler data</a></li>
							<li><a href="/crawlerConfig/index" className={path.includes('/crawlerConfig/index') && 'selected'}>Configurações do crawler</a></li>
							<li><a href="/coupons/index" className={path.includes('/coupons/index') && 'selected'}>Cupons</a></li>
						</ul>
					</li>

					<li id="list2">
						<a href="#" className="has-arrow waves-effect">
							<FontAwesomeIcon icon={faIdCard} />
							<span className="ml-3">Cadastros</span>
						</a>

						<ul className="sub-menu">
							<li><a href="/users/index" className={path.includes('/users/index') && 'selected'}>Usuários</a></li>
							<li><a href="/announcements/index" className={path.includes('/announcements/index') && 'selected'}>Imóveis</a></li>
							<li><a href="/notifications/index" className={path.includes('/notifications/index') && 'selected'}>Notificações</a></li>
						</ul>
					</li>

					<li id="list3">
						<a href="#" className="has-arrow waves-effect">
							<FontAwesomeIcon icon={faFilePen} />
							<span className="ml-3">Negociações</span>
						</a>

						<ul className="sub-menu">
							<li><a href="/analise/index" className={path.includes('/analise/index') && 'selected'}>Análises financeiras</a></li>
							<li><a href="/negotiation/index" className={path.includes('/negotiation/index') && 'selected'}>Negociações e contratos</a></li>
						</ul>
					</li>

					<li id="list4">
						<a href="#" className="has-arrow waves-effect">
							<FontAwesomeIcon icon={faCoins} />
							<span className="ml-3">Financeiro</span>
						</a>

						<ul className="sub-menu">
							<li><a href="/pagamento/cobrancas/index" className={path.includes('/pagamento/cobrancas/index') && 'selected'}>Cobranças</a></li>
							<li><a href="/pagamento/negativacao/index" className={path.includes('/pagamento/negativacao/index') && 'selected'}>Negativações</a></li>
							<li><a href="/pagamento/indices/index" className={path.includes('/pagamento/indices/index') && 'selected'}>Índices renovação</a></li>
							<li><a href="/pagamento/securitizacao/index" className={path.includes('/pagamento/securitizacao/index') && 'selected'}>Remessas e retornos</a></li>
							<li><a href="/pagamento/gratificacao/index" className={path.includes('/pagamento/gratificacao/index') && 'selected'}>Gratificações</a></li>
						</ul>
					</li>
				</ul>
			</div>
		);
	}
}

export default withRouter(SidebarContent);
