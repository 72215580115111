import axios from "axios";
import { Constants } from '../helpers/Constants';
import { parseQuery } from '../helpers/Utils';

export function getAllByParams(params) {
	return axios.get(`${Constants.baseUrl}/credit-negatives/`, { params: parseQuery(params) });
}

export function get(billing_id) {
	return axios.get(`${Constants.baseUrl}/credit-negatives/${billing_id}`);
}

export function create(data) {
	return axios.post(`${Constants.baseUrl}/credit-negatives/`, data);
}

export function remove(id) {
	return axios.delete(`${Constants.baseUrl}/credit-negatives/${id}`);
}
