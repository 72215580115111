import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment';
import 'moment/locale/pt-br';
import { getVistoriaFile, getVistoriasItems } from '../../crud/property-inspection.crud';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownToLine, faArrowLeft, faCheck, faDownload } from '@fortawesome/pro-solid-svg-icons';
import { listRepayments } from '../../crud/billings.crud';
import { formatBRL } from '../../helpers/Utils';

moment.locale('pt-br');
export default class DataTableRepaymentsInspection extends Component {
	constructor() {
		super();

		this.state = {
			data: [],
			rows: 10,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: 'created_at',
			sortOrder: -1,
			boletoExists: false,
			boletoError: false,
			page: 1,
			disableVistoria: false,
			totalDiscount: 0,
			totalAdd: 0
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		this.fetchData();
	}

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = async (event) => {
		if (event.data) {
			event.data = null;
		}

		this.setState({
			...this.state,
			rows: event.rows,

		});

		this.fetchData((event.first / 10) + 1);
	};

	fetchData = (page) => {
		let params = {
			$sort: { [this.state.sortField]: this.state.sortOrder }
		}


		listRepayments({ contract_id: this.props.contractId, $limit: 99, ...params }).then(res => {
			if (page) this.setState({ page });

			this.setState({
				...this.state,
				data: res.data.data,
				totalRecords: res.data.total,
				first: res.data.skip,
				last: res.data.skip + res.data.limit,
			});

			let discount = 0;
			let add = 0;

			for (const repayment of res?.data?.data) {
				if (repayment.type === 'repayment') {
					discount = discount + parseFloat(repayment.amount)
				} else {
					add = add + parseFloat(repayment.amount)
				}
			}

			this.setState({ totalDiscount: discount })
			this.setState({ totalAdd: add })
		}).finally(() => {

		});

	};


	sortTable = e => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder }, () => {
			let propertyInspectionDataTable = {
				rows: this.state.rows,
				totalRecords: this.state.totalRecords,
				first: this.state.first,
				last: this.state.last,
				loading: this.state.loading,
				sortField: this.state.sortField,
				sortOrder: this.state.sortOrder,
			};

			localStorage.setItem('propertyInspectionDataTable', JSON.stringify(propertyInspectionDataTable));

			this.fetchData();
		});
	};


	render() {
		return (
			<div>
				<DataTable ref={(el) => this.dt = el} value={this.state.data} rows={this.state.rows} rowsPerPageOptions={[10]}
					first={this.state.first} last={this.state.last} responsive={true} className="ml-auto inspectionTable"
					totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} loadingIcon={`fas fa-sync fa-spin`}
					paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
					currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" emptyMessage="Nenhum registro encontrado!"
					onSort={(e) => this.sortTable(e)} sortField={this.state.sortField} sortOrder={this.state.sortOrder} >

					<Column sortable field='type' style={{ width: '130px', padding: '16px' }} body={(obj, i) => obj.type === 'repayment' ? (
						<p className='good-state-box' style={{ width: '85px' }}>Desconto</p>
					) : <p className='repair-box' style={{ width: '88px' }}>Acréscimo</p>} header="Tipo"></Column>
					<Column sortable field='description' style={{ width: '801px', padding: '16px' }} body={(obj) => obj.description} header="Descrição"></Column>
					<Column sortable field='value' style={{ width: '130px', padding: '16px' }} body={(obj) => formatBRL(obj.amount)} header="Valor"></Column>
				</DataTable>
				<div className='repayment-inspection-totals'>
					<div style={{ display: 'flex', gap: 24 }}>
						<div className='add'>
							<h2>Total de acréscimo</h2>
							<div>
								+ {formatBRL(this.state.totalAdd)}
							</div>
						</div>
						<div className='discount'>
							<h2>Total de desconto</h2>
							<div>
								- {formatBRL(this.state.totalDiscount)}
							</div>
						</div>
					</div>
					<div className={this.state.totalAdd - this.state.totalDiscount > 0 ? 'discount' : 'add'}>
						<h2>Ajuste total</h2>
						<div>
							{this.state.totalAdd - this.state.totalDiscount > 0 ? '+ ' : '- '}
							{formatBRL(Math.abs(this.state.totalAdd - this.state.totalDiscount))}
						</div>
					</div>
				</div>
			</div>
		);
	}
}