import React, { Component } from 'react';
import { Container, Card, CardBody, Col, FormGroup, Label, Button, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from 'classnames';
import BetterCircularProgress from '../../components/BetterCircularProgress';
import { Link, Redirect } from "react-router-dom";
import { createJob, getConfig, getJob, storeConfig } from "../../crud/jobs.crud";
import { listZones, listLocations } from "../../crud/crawler.crud";
import { Formik } from "formik";
import BetterAlerts from '../../components/BetterAlerts';
import { validateNewCrawler } from '../../helpers/FormValidators';
import { Checkbox, TextField } from "@material-ui/core";
import BetterSelect from '../../components/BetterSelect';
import Breadcrumbs from '../../components/Breadcrumb';
import { ReCron, Tab } from '@sbzen/re-cron';
import { formatBRL, formatBRLInput, limparMoeda } from '../../helpers/Utils';
import Swal from 'sweetalert2';

class CrawlerConfigNew extends Component {


	state = {
		submitted: false,
		success: false,
		errors: [],
		activeTab: '1',
		list_stateCode: [],
		list_cityCode: [],
		data: {
			name: '',
			stateCode: '',
			cityCode: '',
			description: '',
			cron: '',
			options: {
				state: ''
			},
			auto_approve: false,
			min_value_auto_approve: formatBRL(1999),
			max_value_auto_approve: formatBRL(39999),
			min_value: formatBRL(1999),
			max_value: formatBRL(39999),
		},
		configCrawler: null,
		load_data: false,
		loading_zones: false,
		loading: true,
	};

	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	};

	componentDidMount = () => {
		this.setState({ loading: true });

		if (!this.state.list_stateCode.length) {
			this.getLocations();
		}

		getJob(this.props.match.params).catch(err => {
			alert('An error occurred, please contact the support.');
		}).then(async res => {
			if (res?.status == 200) {
				listZones({ state_code: res.data[0].stateCode }).then(({ data: zones }) => {
					this.setState({
						loading_zones: false,
						list_cityCode: zones
					});
				});

				let configCrawler = null;
				let auto_approve = false;
				let min_value_auto_approve = formatBRL(1999);
				let max_value_auto_approve = formatBRL(39999);
				let min_value = formatBRL(1999);
				let max_value = formatBRL(39999);

				await getConfig({ state_code: res.data[0].stateCode, city_code: res.data[0].cityCode }).catch(error => {
					alert('An error occurred, please contact the support.');
				}).then(async result => {
					if (result?.status == 200) {
						console.log('Entrou');
						configCrawler = result.data;
						auto_approve = result.data.auto_approve;
						min_value_auto_approve = formatBRL(result.data.min_value_auto_approve);
						max_value_auto_approve = formatBRL(result.data.max_value_auto_approve);
						min_value = formatBRL(result.data.min_value);
						max_value = formatBRL(result.data.max_value);
					}
				});

				const data = {
					name: res.data[0].name,
					stateCode: res.data[0].stateCode,
					cityCode: res.data[0].cityCode,
					description: res.data[0].description,
					cron: "* " + res.data[0].scheduleExpression.replace(/cron\(|\)/g, ''),
					options: {
						state: res.data[0].state
					},
					auto_approve,
					min_value_auto_approve,
					max_value_auto_approve,
					min_value,
					max_value,
				};
				console.log(data)

				this.setState({ data, loading_zones: false, loading: false, configCrawler });
			}
		});
	};

	onSubmit = (values) => {
		let shouldCreateConfig = false;

		if (this.state.configCrawler) {
			if ((this.state.configCrawler.auto_approve != values.auto_approve) ||
				(this.state.configCrawler.min_value_auto_approve != limparMoeda(values.min_value_auto_approve)) ||
				(this.state.configCrawler.max_value_auto_approve != limparMoeda(values.max_value_auto_approve)) ||
				(this.state.configCrawler.min_value != limparMoeda(values.min_value)) ||
				(this.state.configCrawler.max_value != limparMoeda(values.max_value))) {

				if (values.auto_approve) {
					if (!(values.max_value_auto_approve && values.min_value_auto_approve)) {
						return Swal.fire('Ops', 'Você precisa preencher os valores de auto aprovação.', 'error');
					}

					if (limparMoeda(values.min_value_auto_approve) > limparMoeda(values.max_value_auto_approve)) {
						return Swal.fire('Ops', 'O valor mínimo de auto aprovação não pode ser maior que o valor máximo.', 'error');
					}
				}

				if (limparMoeda(values.min_value) > limparMoeda(values.max_value)) {
					return Swal.fire('Ops', 'O valor mínimo não pode ser maior que o valor máximo.', 'error');
				}

				shouldCreateConfig = true;
			}
		} else {
			shouldCreateConfig = true;
		}

		let data = this.state.data;

		data.name = values.name.toLowerCase();
		data.stateCode = values.stateCode;
		data.cityCode = values.cityCode;
		data.description = values.description;
		data.cron = values.cron.substring(2);

		this.setState(({ submitted: true, loading: true }));

		createJob(data).catch((err) => {
			this.setState({ loading: false, submitted: false, success: false, errors: err.response.data.error ? [err.response.data.error] : [] });
		}).then((res) => {
			if (res?.status == 200) {
				this.setState({ loading: false, submitted: false, success: !Boolean(res.data.error), errors: res.data.error ? [res.data.error] : [] });
			}
		});

		if (shouldCreateConfig) {
			storeConfig({
				state_code: values.stateCode,
				city_code: values.cityCode,
				auto_approve: values.auto_approve,
				min_value_auto_approve: values.min_value_auto_approve ? limparMoeda(values.min_value_auto_approve) : 1999,
				max_value_auto_approve: values.max_value_auto_approve ? limparMoeda(values.max_value_auto_approve) : 39999,
				min_value: limparMoeda(values.min_value),
				max_value: limparMoeda(values.max_value),
			}).catch((err) => {
				this.setState({ loading: false, submitted: false, success: false, errors: err.response.data.error ? [err.response.data.error] : [] });
			}).then((res) => {
				if (res?.status == 200) {
					this.setState({ loading: false, submitted: false, success: !Boolean(res.data.error), errors: res.data.error ? [res.data.error] : [] });
				}
			});
		}

	};

	handlerLocations = (state_code) => {
		this.getZones(state_code);
		return state_code;
	};

	getZones = (input) => {
		this.setState({
			loading_zones: true
		});
		if (input.target.value && input.target.value !== 'false') {
			listZones({ state_code: input.target.value }).then(({ data: zones }) => {
				this.setState({
					loading_zones: false,
					list_cityCode: zones
				});
			});
		}

	};

	getLocations = () => {
		listLocations().then(({ data: locations }) => {
			this.setState({
				// loading: false,
				list_stateCode: locations
			});
		});
	};

	getInitialValues = () => {
		return { ...this.state.data };
	};

	cronChange = (input, values) => {
		values.cron = input;
	};

	render() {
		return (
			<React.Fragment>
				<BetterCircularProgress loading={this.state.loading}>
					<div className="page-content container-page">
						<Container fluid >
							<Breadcrumbs title="Adicionar Crawler Job" breadcrumbItems={[]} />
							<BetterAlerts errors={this.state.errors} />

							{this.state.success ?
								<Redirect to={{ pathname: '/crawlerConfig/index', state: { success: ['Job successfuly added!'] } }} />
								: null
							}

							<Formik initialValues={this.getInitialValues()} validate={values => validateNewCrawler(values)} onSubmit={(values) => this.onSubmit(values)}>
								{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
									<form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>
										<Card>
											<CardBody>
												<Nav tabs className="nav-tabs-custom" role="tablist">
													<NavItem>
														<NavLink id="account-tab" className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggleTab('1'); }} >JOB INFO</NavLink>
													</NavItem>
													<NavItem>
														<NavLink id="info-tab" className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggleTab('2'); }}>SCHEDULE</NavLink>
													</NavItem>
												</Nav>
												<TabContent activeTab={this.state.activeTab} className="border border-top-0 p-4">
													<TabPane tabId="1" role="tabpanel">
														<div className="row">
															<Col md={6}>
																<FormGroup row>
																	<Label className="col-md-4 col-form-label">Nome *</Label>
																	<Col md={8} className="container-center-input">
																		<TextField name="name" type="text" disabled variant="outlined" value={values.name} onBlur={handleBlur} onChange={handleChange}
																			helperText={touched.name && errors.name} error={Boolean(touched.name && errors.name)} />
																	</Col>
																</FormGroup>
															</Col>
															<Col md={6}>
																<FormGroup row>
																	<Label className="col-md-4 col-form-label">Descrição *</Label>
																	<Col md={8} className="container-center-input">
																		<TextField name="description" type="text" variant="outlined" value={values.description} onBlur={handleBlur} onChange={handleChange}
																			helperText={touched.description && errors.description} error={Boolean(touched.description && errors.description)} />
																	</Col>
																</FormGroup>
															</Col>
															<Col md={6}>
																<FormGroup row>
																	<Label className="col-md-4 col-form-label">Estado *</Label>
																	<Col md={8} className="container-center-input">
																		<BetterSelect name="stateCode" blankOption value={values.stateCode} onBlur={handleBlur} onChange={e => handleChange(this.handlerLocations(e))}
																			helperText={touched.stateCode && errors.stateCode} error={Boolean(touched.stateCode && errors.stateCode)} >
																			{
																				(this.state.list_stateCode && this.state.list_stateCode.length > 0) ?
																					this.state.list_stateCode.map((location, i) => {
																						return (<option key={i} value={location.stateCode}>{location.state}</option>);
																					}) : (<option key={1} value={false} >Nenhum Estado encontrado</option>)
																			}
																		</BetterSelect>
																	</Col>
																</FormGroup>
															</Col>
															<Col md={6}>
																<FormGroup row>
																	<Label className="col-md-4 col-form-label">Código Cidade *</Label>

																	<Col md={8} className="container-center-input">
																		<BetterSelect name="cityCode" blankOption value={values.cityCode} onBlur={handleBlur} onChange={handleChange}
																			helperText={touched.cityCode && errors.cityCode} error={Boolean(touched.cityCode && errors.cityCode)}>
																			{
																				(this.state.loading_zones) ? (<option key={1} value={false}  >Loading...</option>) :

																					(this.state.list_cityCode && this.state.list_cityCode.length > 0) ?
																						this.state.list_cityCode.map((zone, i) => {
																							return (<option key={i} value={zone.code}>{zone.name}</option>);
																						})
																						: (<option key={1} value={false} >Nenhum Código Cidade encontrado</option>)


																			}
																		</BetterSelect>
																	</Col>
																</FormGroup>
															</Col>
														</div>

														<div className="row" style={{ marginBottom: '12px' }}>
															<Col md={12}>
																<hr />
															</Col>
														</div>

														<div className="row">
															<Col md={4}>
																<FormGroup row>
																	<Label className="col-md-4 col-form-label">Valor mínimo *</Label>
																	<Col md={8} className="container-center-input">
																		<TextField name="min_value" type="text" disabled variant="outlined" value={values.min_value} onBlur={handleBlur} onChange={(e) => handleChange(formatBRLInput(e))}
																			helperText={touched.min_value && errors.min_value} error={Boolean(touched.min_value && errors.min_value)} />
																	</Col>
																</FormGroup>
															</Col>

															<Col md={4}>
																<FormGroup row>
																	<Label className="col-md-4 col-form-label">Valor máximo *</Label>
																	<Col md={8} className="container-center-input">
																		<TextField name="max_value" type="text" disabled variant="outlined" value={values.max_value} onBlur={handleBlur} onChange={(e) => handleChange(formatBRLInput(e))}
																			helperText={touched.max_value && errors.max_value} error={Boolean(touched.max_value && errors.max_value)} />
																	</Col>
																</FormGroup>
															</Col>

															<Col md={4}>
																<FormGroup row>
																	<Checkbox name="auto_approve" color="primary" checked={values.auto_approve} onBlur={handleBlur} onChange={handleChange} />
																	<Label style={{ marginTop: '12px' }}>Auto aprovar?</Label>
																</FormGroup>
															</Col>

															{values.auto_approve ?
																<>
																	<Col md={4}>
																		<FormGroup row>
																			<Label className="col-md-4 col-form-label">Valor mínimo para auto aprovar *</Label>
																			<Col md={8} className="container-center-input">
																				<TextField name="min_value_auto_approve" type="text" disabled variant="outlined" value={values.min_value_auto_approve} onBlur={handleBlur} onChange={(e) => handleChange(formatBRLInput(e))}
																					helperText={touched.min_value_auto_approve && errors.min_value_auto_approve} error={Boolean(touched.min_value_auto_approve && errors.min_value_auto_approve)} />
																			</Col>
																		</FormGroup>
																	</Col>

																	<Col md={4}>
																		<FormGroup row>
																			<Label className="col-md-4 col-form-label">Valor máximo para auto aprovar *</Label>
																			<Col md={8} className="container-center-input">
																				<TextField name="max_value_auto_approve" type="text" disabled variant="outlined" value={values.max_value_auto_approve} onBlur={handleBlur} onChange={(e) => handleChange(formatBRLInput(e))}
																					helperText={touched.max_value_auto_approve && errors.max_value_auto_approve} error={Boolean(touched.max_value_auto_approve && errors.max_value_auto_approve)} />
																			</Col>
																		</FormGroup>
																	</Col>
																</>
																: null
															}
														</div>
													</TabPane>
													<TabPane tabId="2" role="tabpanel">
														<div className="row">
															<Col className="container-center-input">
																<FormGroup>
																	<Label className="col-md-4 col-form-label">Agendar Crawler *</Label>
																	<ReCron
																		tabs={[Tab.MINUTES, Tab.HOURS, Tab.DAY, Tab.MONTH, Tab.YEAR]}
																		value={values.cron}
																		onBlur={handleBlur}
																		onChange={(e) => this.cronChange(e, values)}
																		helperText={touched.cron && errors.cron}
																		error={Boolean(touched.cron && errors.cron)}
																	>
																	</ReCron>
																</FormGroup>
															</Col>
														</div>
													</TabPane>
												</TabContent>
											</CardBody>
										</Card>

										<Card>
											<CardBody className="container-space-between">
												<Link to='/crawlerConfig/index' className="btn btn-danger btn-bold"><i className="fa fa-arrow-left mr-2"></i>Voltar</Link>

												<Button color="success" type="submit" className="waves-effect waves-light mr-10">
													<i className={`mr-2 align-middle ${this.state.submitted ? "fas fa-sync fa-spin" : "ri-save-3-line"}`}></i>Salvar
												</Button>
											</CardBody>
										</Card>
									</form>
								)}
							</Formik>
						</Container>
					</div>
				</BetterCircularProgress>
			</React.Fragment>
		);
	}
}

export default CrawlerConfigNew;