import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";
import { FiltersModuleContractIndicators, findMetricInfo } from "../filters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical, faEyeSlash, faFileCircleCheck, faFileCirclePlus, faFileContract, faGripVertical, faMaximize, faMinimize, faUser } from '@fortawesome/pro-solid-svg-icons';
import { Checkbox, ListItemText, Menu, MenuItem } from "@material-ui/core";
import { formatBRL } from "../../../helpers/Utils";
import { getAllByFilter } from '../../../crud/contracts.crud'

export default class ContractIndicatorSection extends Component {
	constructor() {
		super();

		this.state = {
			metrics: [],
			contractsIndicatorsAvailableLabels: [
				{ label: "RESPONSÁVEIS COM CONTRATO ATIVO", isActive: true },
				{ label: "CONTRATOS VIGENTES", isActive: true },
				{ label: "VALOR MÉDIO DE CONTRATO", isActive: true },
				{ label: "DURAÇÃO MÉDIA DE CONTRATO", isActive: true },
				{ label: "VALOR BRUTO GERAL DE ALUGUÉIS", isActive: true },
				{ label: "CONTRATOS CRIADOS", isActive: true },
				{ label: "CONTRATOS ASSINADOS", isActive: true },
			],
			contractIndicatorsFilters: {
				"time_frame": "always",
				"affiliate_type": "all"
			},
			dropdownKey: null,
			anchorEl: null,
			counter: 0
		};
	}

	componentDidMount() {
		if (this?.props?.metrics) {
			this.setState({ metrics: this.props.metrics })

			getAllByFilter({ limit: 1 }).then(res => {
				this.setState({ counter: res?.data?.total })

			})
		}
	}

	render() {
		return (
			<Card>
				<CardBody className="dashboard-item">
					<div className="box-header">
						<div className="">
							<h3 className="title">Indicadores de contrato</h3>
							<span className="count-indicator">{this.state?.counter}</span>
							<div className="box-info">
								Negociações
							</div>
						</div>

						<div>
							<FontAwesomeIcon icon={this.props.layout?.w === 6 ? faMaximize : faMinimize} style={{ color: '#5664D2', padding: '12px', cursor: 'pointer' }} onClick={() => this.props.toggleMaximize(this.props?.itemKey)} />
							<FontAwesomeIcon icon={faEllipsisVertical} style={{ color: '#5664D2', padding: '12px', cursor: 'pointer' }} onClick={(e) => {
								this.setState({ dropdownKey: this.props.itemKey });
								this.setState({ anchorEl: e.currentTarget });
							}} />
							<FontAwesomeIcon icon={faEyeSlash} style={{ color: '#5664D2', padding: '12px', cursor: 'pointer' }} onClick={() => this.props.changeWidgetValue(this.props.itemKey)} />
							<FontAwesomeIcon icon={faGripVertical} style={{ color: '#DADBDD', padding: '12px', cursor: 'pointer' }} />
						</div>
					</div>

					<Menu
						anchorEl={this.state.anchorEl}
						open={this.state.dropdownKey === this.props.itemKey ? true : false}
						onClose={() => { this.setState({ dropdownKey: null }) }}
						className="dashboard-item"
						id="dashboard-drop"
					>
						{this.state.contractsIndicatorsAvailableLabels.map((item) => (
							<MenuItem key={item.label} value={item.label}>
								<Checkbox checked={item.isActive} onChange={() => {
									this.setState(prevState => ({
										contractsIndicatorsAvailableLabels: prevState.contractsIndicatorsAvailableLabels.map(i =>
											i.label === item.label
												? { ...i, isActive: !i.isActive }
												: i
										)
									}));
								}} />
								<ListItemText primary={item.label} />
							</MenuItem>
						))}
					</Menu>

					<div className="box-filter">
						<FiltersModuleContractIndicators setState={this.setState.bind(this)} state={this.state.contractIndicatorsFilters} />
					</div>

					<div style={{ position: 'relative', marginTop: '10px' }}>
						<hr className="divisor" />
					</div>

					<div className="card-wrapper" style={{ marginBottom: 60 }}>
						<div className="card-box" style={{ display: this.state.contractsIndicatorsAvailableLabels.find(obj => obj?.label === 'RESPONSÁVEIS COM CONTRATO ATIVO')?.isActive ? '' : 'none' }}>
							<h3>RESPONSÁVEIS COM<br /> CONTRATO ATIVO</h3>

							<h2>
								{findMetricInfo(this.state.metrics, [this.state.contractIndicatorsFilters.time_frame, this.state.contractIndicatorsFilters.affiliate_type], 'CONTRACT_INDICATORS_LANDLORDS_PER_CONTRACT', 'count')}
								<FontAwesomeIcon icon={faUser} />
							</h2>
						</div>

						<div className="card-box" style={{ display: this.state.contractsIndicatorsAvailableLabels.find(obj => obj?.label === 'CONTRATOS CRIADOS')?.isActive ? '' : 'none' }}>
							<h3>CONTRATOS<br /> CRIADOS</h3>

							<h2>
								{parseFloat(findMetricInfo(this.state.metrics, [this.state.contractIndicatorsFilters.time_frame, this.state.contractIndicatorsFilters.affiliate_type], 'CONTRACT_INDICATORS_CREATED', 'count')).toFixed(0)} <FontAwesomeIcon icon={faFileCirclePlus} />
							</h2>
						</div>

						<div className="card-box" style={{ display: this.state.contractsIndicatorsAvailableLabels.find(obj => obj?.label === 'CONTRATOS ASSINADOS')?.isActive ? '' : 'none' }}>
							<h3>CONTRATOS<br /> ASSINADOS</h3>

							<h2>
								{parseFloat(findMetricInfo(this.state.metrics, [this.state.contractIndicatorsFilters.time_frame, this.state.contractIndicatorsFilters.affiliate_type], 'CONTRACT_INDICATORS_SIGNED', 'count')).toFixed(0)}	<FontAwesomeIcon icon={faFileContract} />
							</h2>
						</div>

						<div className="card-box" style={{ display: this.state.contractsIndicatorsAvailableLabels.find(obj => obj?.label === 'CONTRATOS VIGENTES')?.isActive ? '' : 'none' }}>
							<h3>CONTRATOS<br /> VIGENTES</h3>

							<h2>
								{findMetricInfo(this.state.metrics, [this.state.contractIndicatorsFilters.time_frame, this.state.contractIndicatorsFilters.affiliate_type], 'CONTRACT_INDICATORS_ACTIVE_CONTRACTS', 'count')}
								<FontAwesomeIcon icon={faFileCircleCheck} />
							</h2>
						</div>


						<div className="card-box" style={{ display: this.state.contractsIndicatorsAvailableLabels.find(obj => obj?.label === 'VALOR MÉDIO DE CONTRATO')?.isActive ? '' : 'none' }}>
							<h3>VALOR MÉDIO<br /> DE CONTRATO</h3>

							<h2>
								<span style={{ fontSize: 13, marginTop: 8 }}>R$</span>
								{formatBRL(findMetricInfo(this.state.metrics, [this.state.contractIndicatorsFilters.time_frame, this.state.contractIndicatorsFilters.affiliate_type], 'CONTRACT_INDICATORS_AVERAGE_RENT', 'count')).replace("R$", '')}

							</h2>
						</div>

						<div className="card-box" style={{ display: this.state.contractsIndicatorsAvailableLabels.find(obj => obj?.label === 'DURAÇÃO MÉDIA DE CONTRATO')?.isActive ? '' : 'none' }}>
							<h3>DURAÇÃO MÉDIA<br /> DE CONTRATO</h3>

							<h2>
								{parseFloat(findMetricInfo(this.state.metrics, [this.state.contractIndicatorsFilters.time_frame, this.state.contractIndicatorsFilters.affiliate_type], 'CONTRACT_INDICATORS_AVERAGE_DURATION', 'count')).toFixed(0)} meses

							</h2>
						</div>

						<div className="card-box" style={{ display: this.state.contractsIndicatorsAvailableLabels.find(obj => obj?.label === 'VALOR BRUTO GERAL DE ALUGUÉIS')?.isActive ? '' : 'none' }}>
							<h3>VALOR BRUTO GERAL<br /> DE ALUGUÉIS</h3>

							<h2>
								<span style={{ fontSize: 13, marginTop: 8 }}>R$</span>
								{formatBRL(findMetricInfo(this.state.metrics, [this.state.contractIndicatorsFilters.time_frame, this.state.contractIndicatorsFilters.affiliate_type], 'CONTRACT_INDICATORS_GERAL_VALUE', 'count')).replace("R$", '')}

							</h2>
						</div>
					</div>
				</CardBody>
			</Card>
		)
	}
}


