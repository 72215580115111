import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import _ from 'lodash';
import moment from 'moment';
import { Link } from "react-router-dom";


export default class DataTableUsersNotificacoes extends Component {
	constructor() {
		super();

		this.state = {
			data: [],
			rows: 10,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: 'id',
			sortOrder: '1',
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		let DataTableUsersNotificacoes = JSON.parse(localStorage.getItem('DataTableUsersNotificacoes'));

		if (DataTableUsersNotificacoes) {
			this.setState({
				rows: DataTableUsersNotificacoes.rows,
				totalRecords: DataTableUsersNotificacoes.totalRecords,
				first: DataTableUsersNotificacoes.first,
				last: DataTableUsersNotificacoes.last,
				loading: DataTableUsersNotificacoes.loading,
				sortField: DataTableUsersNotificacoes.sortField,
				sortOrder: DataTableUsersNotificacoes.sortOrder,
			}, () => {
				this.fetchData();
			});
		} else {
			this.fetchData();
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters != this.props.filters) {
			this.onFilterChanged();
		}
	}

	onFilterChanged = _.debounce(() => {
		if (!this.state.isLoading) {
			this.fetchData();
		}
	}, 700);

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = async (event) => {
		if (event.data) {
			event.data = null;
		}

		let DataTableUsersNotificacoes = {
			rows: event.rows,
			totalRecords: this.state.totalRecords,
			first: event.first,
			last: event.rows + event.first,
			loading: this.state.loading,
			sortField: this.state.sortField,
			sortOrder: this.state.sortOrder,
		};

		localStorage.setItem('DataTableUsersNotificacoes', JSON.stringify(DataTableUsersNotificacoes));

		this.setState({
			...this.state,
			rows: event.rows,
			loading: true
		});

		this.fetchData(event.rows, event.first);
	};

	fetchData = (limit, skip) => {
		let data = {
			$limit: limit ? limit : this.state.rows,
			$skip: skip != null ? skip : this.state.first,
			$sort: { [this.state.sortField]: this.state.sortOrder }
		};

		this.props.fetchEvent(data).then(res => {
			this.setState({
				...this.state,
				data: res.data.data,
				totalRecords: res.data.total,
				first: res.data.skip,
				last: res.data.skip + res.data.limit,
				loading: false
			});
		});
	};

	sortTable = e => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder }, () => {
			let DataTableUsersNotificacoes = {
				rows: this.state.rows,
				totalRecords: this.state.totalRecords,
				first: this.state.first,
				last: this.state.last,
				loading: this.state.loading,
				sortField: this.state.sortField,
				sortOrder: this.state.sortOrder,
			};

			localStorage.setItem('DataTableUsersNotificacoes', JSON.stringify(DataTableUsersNotificacoes));

			this.fetchData();
		});
	};

	getDateFormatted = (date) => {
		return (
			<p>{moment(date).format('DD/MM/YYYY HH:mm:ss')}</p>
		)
	}

	getUserName = (obj) => {
		if (obj.user) {
			return (
				<div style={{ textAlign: 'center' }}>
					<Link to={`/users/show/${obj.user.id}`} >{obj.user.name}</Link>
				</div>
			)
		}
	};

	columnOpcoesCrud = (rowData) => {
		let idRow = this.props.idRow ? this.props.idRow : 'id';

		if (this.props.crudUrl) {
			return (
				<Link to={`${this.props.crudUrl}/show/${rowData[idRow]}`} className='showButton'>
					<i className='fa fa-chevron-right' />
				</Link>
			);
		} else {
			return null;
		}
	};

	render() {
		const paginatorRight = (
			<button onClick={() => { this.fetchData(this.state?.totalRecords); this.setState({ rows: this.state?.totalRecords }) }} className='btnSecundary' style={{ marginLeft: 24 }}>
				Ver todos
			</button>
		);

		return (
			<DataTable ref={(el) => this.dt = el} value={this.state.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={[10, 25, 50, this.state?.totalRecords]}
				first={this.state.first} last={this.state.last} header={null} responsive={true} className="ml-auto" paginatorRight={paginatorRight}
				totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} loadingIcon={`fas fa-sync fa-spin`}
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
				currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" emptyMessage="Nenhum registro encontrado!"
				onSort={(e) => this.sortTable(e)} sortField={this.state.sortField} sortOrder={this.state.sortOrder}>
				<Column style={{ width: 104 }} sortable field="dispatched_at" body={(obj, i) => this.getDateFormatted(obj.dispatched_at)} header="Enviado" />
				<Column style={{ width: 131 }} sortable field="updated_at" body={(obj, i) => this.getDateFormatted(obj.updated_at)} header="Atualizado em" />
				<Column style={{ width: 451 }} field="title" header="Título" />
				<Column style={{ width: 103 }} field="channel" header="Canal" />
				<Column style={{ width: 123, wordWrap: 'break-word' }} field="category" header="Categoria" />
				<Column header="" body={this.columnOpcoesCrud} style={{ width: '34px', padding: 0 }} />
			</DataTable>
		);
	}
}