import React, { Component } from 'react';
import { Col, FormGroup, Label, Input, Button } from "reactstrap";
import { FormHelperText } from "@material-ui/core";
import { get } from "../../../crud/plans.crud";
import { findPlanByName, safeJsonStringify } from '../helpers';

export class PlanDepositMonth extends Component {
	render() {
		const { onBlur, onChange, max, min, name, checked } = this.props;
		return (
			<React.Fragment>
				<Col md={12}>
					<FormGroup className="w-100">
						<Label
							className="col-form-label"
							style={{ color: '#343A40' }}>Garantia</Label>
						<Col md={12}>
							{
								Array.from({
									length: (max - min) + 1
								}, (_, i) => {
									const index = i + min;
									return (
										< Label
											className="pl-2 w-100"
											key={index}
										>
											<Input
												id={String(index)}
												type="radio"
												name={name}
												value={index}
												onChange={onChange}
												onBlur={onBlur}
												checked={Number(checked) === index}
												color="primary" />{' '}
											<Label
												style={{ padding: "3px" }}
												for={String(index)}>
												{(index) ? `${index} caução de entrada` : 'Sem garantia'}
											</Label>
										</Label>
									);
								})
							}
						</Col>
					</FormGroup>
				</Col>
			</React.Fragment>
		);
	}
}

export class PlanPercentage extends Component {
	render() {
		const { onBlur, onChange, value, details } = this.props;

		return (
			<React.Fragment>
				<Col md={12}>
					<FormGroup className="w-100">
						<Label className="col-form-label" style={{ color: '#343A40' }}>Adicional</Label>
						<Col md={12}>
							<Label className="pl-2 w-100">
								<Input
									type="radio"
									name="_"
									onChange={onChange}
									onBlur={onBlur}
									value={value}
									checked={true}
									color="primary" />{' '}
								<Label style={{ padding: "3px" }}>{details}</Label>
							</Label>
						</Col>
					</FormGroup>
				</Col>
			</React.Fragment>
		);
	}
}

export class Basis extends Component {
	state = {
		list: [],
	};

	componentDidUpdate = () => {
		if (!this.state.list.length) {
			get()
				.then(({ data }) => {
					this.setState({ list: data.data });
				});
		}
	};

}

export class ListPlans extends Basis {
	render() {
		const { onBlur, onChange, value, name, error, onError, list } = this.props;

		return (
			<React.Fragment>
				<Col md={12}>
					<FormGroup className="w-100">
						{/* {JSON.stringify(value)} */}
						<Label className="col-form-label" style={{ color: '#343A40' }}>Plano</Label>
						<select
							style={{ background: "#FFF", height: "40px" }}
							name={name}
							onChange={onChange}
							onBlur={onBlur}
							value={JSON.stringify(value)}
							className="custom-select">
							<option key={null} value={null} hidden>Selecione o plano</option>
							{[...this.state.list]
								.map((plan) => {
									// Remover plano caução
									if (plan.name !== 'SECURITY_DEPOSIT') {
										return (
											<option key={plan.id} value={JSON.stringify(plan)}>{plan.friendly_name}</option>
										);
									}
								})}
						</select>
						{error ?
							<FormHelperText className="ml-3" error>{onError}</FormHelperText> : null
						}
					</FormGroup>
				</Col>
			</React.Fragment>
		);
	}
}

export class PlansFilterComponent extends Basis {

	componentDidMount = () => {
		if (!this.state.list.length) {
			get()
				.then(({ data }) => {
					this.setState({ list: data.data });
				});
		}
	};

	componentDidUpdate = () => {
		console.log("VALUE: " + this.props.value);
	};

	render() {
		const {
			onChange,
			value,
			onClick,
			label,
		} = this.props;

		return (
			<React.Fragment>
				<Col md={4}>
					<Label className="col-form-label" style={{ color: "#343A40" }}>
						{label}
					</Label>
					<FormGroup row>
						<Col>
							<select
								onChange={onChange}
								value={safeJsonStringify(value || findPlanByName(value, this.state.list))}
								className="custom-select">
								<option key={null} value={null} hidden>Selecione o plano</option>
								{[...this.state.list]
									.map((plan) => {
										return (
											<option key={plan.id} value={plan.name}>{plan.friendly_name}</option>
										);
									})}
							</select>
						</Col>
						<Button
							onClick={onClick}
							color="light"
							type="button"
							className="waves-effect waves-light btn-rounded btn-close-input"
						>
							<i className="ri-close-circle-line align-middle"></i>
						</Button>
					</FormGroup>
				</Col>
			</React.Fragment>
		);
	}
}