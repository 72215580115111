import React, { Component } from 'react';
import { Container, Card, CardBody, Label, Col, FormGroup } from "reactstrap";
import { TextField } from "@material-ui/core";
import BetterCircularProgress from '../../components/BetterCircularProgress';
import { Link, Redirect } from "react-router-dom";
import moment from 'moment';
import { validateFormBilling } from '../../helpers/FormValidators';
import { getById, update } from '../../crud/billings.crud';
import { Formik } from "formik";
import Breadcrumbs from '../../components/Breadcrumb';
import { formatBRL, formatBRLInput, limparMoeda } from '../../helpers/Utils'
import BetterAlerts from '../../components/BetterAlerts';

class BillingEdit extends Component {

	state = {
		loading: false,
		data: null,
		success: false,
		errors: false,
	}

	componentDidMount = async () => {
		this.setState({ loading: true });

		await getById(this.props.match.params.id).then(res => {
			this.setState({ data: res.data.data, loading: false });
			return;
		});

		this.setState({ loading: false });
	};

	formatDate(date) {
		const newDate = moment(date).utcOffset(+2).format('DD/MM/YYYY');
		const formatedDate = newDate?.split('/')[0] + '/' + this.getMonth(newDate?.split('/')[1]) + '/' + newDate?.split('/')[2];
		return formatedDate;
	}

	getMonth(month) {
		switch (month) {
			case '01' || '1':
				return 'Janeiro';
			case '02' || '2':
				return 'Fevereiro';
			case '03' || '3':
				return 'Marco';
			case '04' || '4':
				return 'Abril';
			case '05' || '5':
				return 'Maio';
			case '06' || '6':
				return 'Junho';
			case '07' || '7':
				return 'Julho';
			case '08' || '8':
				return 'Agosto';
			case '09' || '9':
				return 'Setembro';
			case '10' || '10':
				return 'Outubro';
			case '11' || '11':
				return 'Novembro';
			case '12' || '12':
				return 'Dezembro';
		}
	}

	handleSubmit = (values) => {
		let billingData = moment.utc(values.due_date);

		if (moment.utc().isSameOrAfter(billingData)) {
			this.setState({ success: false, errors: ['Não é possível selecionar o dia atual ou uma data passada.'] });
			return;
		}

		this.setState({ loading: true });

		const data = {
			value: limparMoeda(values.value),
			due_date: values.due_date
		}

		update(this.state.data.id, data).then((res) => {
			if (res.status === 200) {
				this.setState({ loading: false, success: true, errors: [] });
			}
		}).catch((err) => {
			this.setState({ loading: false, success: false, errors: this.processErrorMessage(err.response.data.message) ? [this.processErrorMessage(err.response.data.message)] : [] });
		});
	};

	getInitialValues = () => {
		return {
			value: this.state.data?.values?.value ? formatBRL(this.state.data?.values.value) : '',
			due_date: ''
		};
	};

	render() {
		return (
			<React.Fragment>
				<BetterCircularProgress loading={this.state.loading}>
					<div className="page-content container-page">
						<Container fluid>
							<Breadcrumbs breadcrumbItems={[{ link: `/contracts/index`, title: 'Contrato' }, { link: `/contracts/show/${this.state.data?.contract_id}`, title: 'Visualizar' },
							{ link: `/billing/show/${this.state.data?.id}`, title: `${this.formatDate(this.state?.data?.due_date)}` },
							{ link: `/billing/edit/${this.state.data?.id}`, title: `Editar pagamento` }]} />

							<BetterAlerts errors={this.state.errors} />

							{this.state.success ?
								<Redirect to={{ pathname: `/billing/show/${this.state.data?.id}`, state: { success: ['Cobrança atualizada com sucesso!'] } }} />
								: null
							}
							<Formik initialValues={this.getInitialValues()} validate={values => validateFormBilling(values)} onSubmit={(values) => this.handleSubmit(values)}>
								{({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
									<form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>
										<Card>
											<CardBody>
												<Label className="col-form-label ml-2" style={{ color: '#343A40', fontSize: '23px' }}>Editar valor e vencimento</Label>
												<div className="row">
													<Col md={9}>
														<FormGroup row>
															<Label className="col-md-9 col-form-label">Vencimento *</Label>
															<Col md={6} className="container-center-input">
																<TextField name="due_date" type="date" variant="outlined" value={values.due_date} onBlur={handleBlur} onChange={handleChange}
																	helperText={touched.due_date && errors.due_date} error={Boolean(touched.due_date && errors.due_date)} />
															</Col>
														</FormGroup>
													</Col>
													<Col md={9}>
														<FormGroup row>
															<Label className="col-md-9 col-form-label">Valor * </Label>
															<Col md={6} className="container-center-input">
																<TextField name="value" type="text" variant="outlined" value={values.value} onBlur={handleBlur} onChange={(e) => handleChange(formatBRLInput(e))}
																	helperText={touched.value && errors.value} error={Boolean(touched.value && errors.value)} placeholder="R$ 1500,00" />
															</Col>
														</FormGroup>
													</Col>
												</div>
											</CardBody>
											<CardBody className="row">
												<Link to={'/billing/show/' + this.state?.data?.id} className="btn btn-secondary btn-bold ml-2"><i className="fa fa-arrow-left mr-2"></i>Voltar</Link>
												<button type="submit" className="btn btn-success ml-2" style={{ width: "158px", height: "36px" }}>Salvar</button>
											</CardBody>
										</Card>
									</form>
								)}
							</Formik>
						</Container>
					</div>
				</BetterCircularProgress>
			</React.Fragment>
		)
	}
}

export default BillingEdit;