import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Link } from "react-router-dom";
import { Column } from 'primereact/column';
import _ from 'lodash';
import { CircularProgress } from "@material-ui/core";
import moment from 'moment';
import { formatBRL, limparMoeda } from '../../helpers/Utils';
import { faUserMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAllByFilter as getContracts } from '../../crud/contracts.crud';
export default class DataTableUserAnnouncementsNegotiation extends Component {
	constructor() {
		super();

		this.state = {
			data: [],
			rows: 10,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: 'id',
			sortOrder: 1,
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		this.onPage(this.state);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters != this.props.filters) {
			this.onFilterChanged();
		}
	}

	onFilterChanged = _.debounce(() => {
		if (!this.state.isLoading) {
			this.setState({
				loading: true,
				rows: 10,
				totalRecords: 0,
				first: 0,
				last: 0,
			}, () => { this.fetchData() });
		}
	}, 700);

	fetchData = (limit, skip) => {
		let data = {
			$limit: limit ? limit : this.state.rows,
			$skip: skip != null ? skip : this.state.first,
			$sort: { [this.state.sortField]: this.state.sortOrder }
		};

		this.props.fetchEvent(data).then(async res => {
			await res.data.data.map(async (obj, i) => {
				await getContracts({ tenant_id: obj.tenant_id, landlord_id: obj.landlord_id, property_announcement_id: obj.property_announcement_id }).catch(err => {
					obj.contract = null;
					obj.loading_contract = false;
				}).then(res => {
					if (res?.data?.data) {
						obj.contract = res?.data?.data[0];
					}
				});

				this.setState({
					...this.state,
					data: res.data.data,
					totalRecords: res.data.total,
					first: res.data.skip,
					last: res.data.skip + res.data.limit,
					loading: false
				});
			});

			this.setState({
				...this.state,
				data: res.data.data,
				totalRecords: res.data.total,
				first: res.data.skip,
				last: res.data.skip + res.data.limit,
				loading: false
			});
		});
	};

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = async (event) => {
		if (event.data) {
			event.data = null;
		}

		this.setState({
			...this.state,
			rows: event.rows,
			loading: true
		});

		this.fetchData(event.rows, event.first);
	};

	columnOpcoesCrud = (rowData) => {
		let idRow = this.props.idRow ? this.props.idRow : 'id';

		if (this.props.crudUrl) {
			return (
				<Link to={`${this.props.crudUrl}/show/${rowData[idRow]}`} className='showButton'>
					<i className='fa fa-chevron-right' />
				</Link>
			);
		} else {
			return null;
		}
	};

	getDateFormatted = (date) => {
		return (
			<p>{moment(date).format('DD/MM/YYYY HH:mm:ss')}</p>
		)
	}

	getWarranty = (key) => {
		switch (key) {
			case 'FREE':
				return 'Grátis';

			case 'WARRANTY':
				return 'Pagamento';


			case 'WARRANTY_GUARANTEE_DAMAGE':
				return 'Completa';

			default:
				return 'Não identificado';
		}
	}

	getContext = (obj) => {
		if (obj?.landlord_id == this.props.user) {
			return 'Dono';
		} else if (obj?.tenant_id == this.props.user) {
			return 'Inquilino';
		} else {
			return 'Comissionado';
		}
	}

	getUserName = (obj, type) => {
		if (obj.contract?.landlord_id == this?.props?.user) {
			return (
				<div className='negotiation-active' style={{ textAlign: 'center' }}>
					<FontAwesomeIcon icon={faUserMagnifyingGlass} style={{ marginRight: '8.5px' }} />

					<Link to={`/users/show/${obj?.tenant?.id}`} >{obj?.tenant?.name}</Link>
				</div>
			)
		} else {
			return (
				<div className='negotiation-active' style={{ textAlign: 'center' }}>
					<FontAwesomeIcon icon={faUserMagnifyingGlass} style={{ marginRight: '8.5px' }} />

					<Link to={`/users/show/${obj?.landlord?.id}`} >{obj?.landlord?.name}</Link>
				</div>
			)
		}
	};

	getAnnouncement = (obj) => {
		const address = obj?.property?.address;

		if (obj?.property) {
			return (
				<div style={{ textAlign: 'center' }}>
					<Link to={`/announcements/show/${obj.property_announcement_id}`} >{`${address.street} ${address.number}, ${address?.complement ?? ''} ${address.neighbourhood ? `, ${address.neighbourhood}` : ''},  - ${address.city}/${address.state}`}</Link>
				</div>
			)
		} else {
			return (
				<div style={{ textAlign: 'center' }}>
					<CircularProgress color="inherit" style={{ color: "#4AA3FF" }} size={15} />
				</div>
			)
		}
	};

	getStatusTerminationContract = (obj) => {
		let status = obj;
		let backgroundColor = '#4AA3FF'

		if (obj == 'Quebra de contrato') {
			backgroundColor = '#FF3D60';
		} else if (obj == 'Comum acordo') {
			backgroundColor = '#1CBB8C';
		} else if (obj == 'Tempo previsto por contrato') {
			backgroundColor = '#4AA3FF';
		} else if (obj == 'TERMINATION_SCHEDULED') {
			status = 'Rescisão agendada'
			backgroundColor = '#4AA3FF';
		} else if (obj == 'AWAITING_COURT_APPROVAL') {
			status = 'Aguardando jurídico'
			backgroundColor = '#EFA200';
		} else if (obj == 'SEM_MOTIVO') {
			status = 'Rescindido pelo Admin'
		} else {
			status = 'Rescindido pelo Admin'
		}

		return { backgroundColor, status }
	}

	getStatusProposition = (obj) => {
		let status = '';
		let color = '';

		switch (obj?.status) {
			case 'PLACEHOLDER':
				status = 'Iniciado';
				color = '#4AA3FF';

				break;
			case 'DRAFT':
				status = 'Integrações';
				color = '#5664D2';

				break;
			case 'AWAITING_TENANT_LOCK':
				status = 'Trava inquilino';
				color = '#5664D2';

				break;
			case 'AWAITING_CREDIT_REVIEW':
				status = 'Análise financeira';
				color = '#5664D2';

				break;
			case 'APPROVED':
				status = 'Análise financeira aprovada';
				color = '#1CBB8C';

				break;
			case 'FIRST_PROPOSAL':
				status = 'Admin';
				color = '#5664D2';

				break;
			case 'CREDIT_REFUSED':
				status = 'Análise financeira reprovada';
				color = '#FF3D60';

				break;
			case 'AWAITING_SIGN':
				status = 'Assinaturas';
				color = '#5664D2';

				break;
			case 'FINALIZED':
				status = 'Em contrato';
				color = '#1CBB8C';

				break;
			case 'FINISHED':
				status = 'Contrato rescindido';
				color = '#FF3D60';

				break;
			default:
				status = obj?.status;
				color = '#FCB92C';

				break;
		}

		if (obj?.contract?.termination_status && obj?.status != 'FINISHED') {
			color = this.getStatusTerminationContract(obj?.contract.termination_status).backgroundColor;
			status = this.getStatusTerminationContract(obj?.contract.termination_status).status;
		}

		return (
			<div className='tag' style={{ margin: 'auto', backgroundColor: color }}>
				{status}
			</div>
		);
	}

	getInNumber = (value) => {
		value = formatBRL(value);
		value = limparMoeda(value);
		value = Number(value);

		return value;
	}

	getValuesFormated = (values) => {
		let rent = this.getInNumber(values?.rent ?? 0);
		let condo = this.getInNumber(values?.condo ?? 0);
		let iptu = this.getInNumber(values?.iptu ?? 0);

		return formatBRL(rent + condo + iptu);
	}

	sortTable = e => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder }, () => {
			this.fetchData();
		});
	};

	render() {
		const header = (
			<div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row' }}>
				{this.props.titleTable &&
					<>
						<h3 style={{ margin: 0 }}>{this.props.titleTable}</h3>

						<div style={{ marginLeft: 8 }} className='indicadorUsuarios'>{this.state?.totalRecords ?? 0}</div>
					</>
				}
			</div>
		);

		const paginatorRight = (
			<button onClick={() => { this.fetchData(this.state?.totalRecords); this.setState({ rows: this.state?.totalRecords }) }} className='btnSecundary' style={{ marginLeft: 24 }}>
				Ver todos
			</button>
		);

		return (
			<DataTable ref={(el) => this.dt = el} value={this.state.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={[10, 25, 50, this.state?.totalRecords]}
				first={this.state.first} last={this.state.last} header={this.props.noHeader ? null : header} responsive={true}
				totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} loadingIcon={`fas fa-sync fa-spin`}
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
				currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" emptyMessage="Nenhum registro encontrado!"
				sortField={this.state.sortField} sortOrder={this.state.sortOrder} paginatorRight={paginatorRight} onSort={(e) => this.sortTable(e)}>
				<Column style={{ width: 132 }} field="status" header="Status" body={(obj) => this.getStatusProposition(obj)} />
				<Column style={{ width: 131 }} sortable field="updated_at" body={(obj, i) => this.getDateFormatted(obj.updated_at)} header="Atualizado em" />
				<Column style={{ width: 110 }} field="context" body={(obj, i) => this.getContext(obj)} header="Papel" />
				<Column style={{ width: 227.5 }} field="announcement" body={(obj, i) => this.getAnnouncement(obj)} header="Imóvel" />
				<Column style={{ width: 227.5 }} field="envolvido" body={(obj, i) => this.getUserName(obj)} header="Envolvido" />
				<Column style={{ width: 102 }} field="plan.name" header="Garantia" body={(obj, i) => this.getWarranty(obj?.plan?.name)} />
				<Column style={{ width: 108 }} field="announcement.values" body={(obj, i) => this.getValuesFormated(obj?.details?.values)} header="Valor total" />
				<Column header="" body={this.columnOpcoesCrud} style={{ width: '34px', padding: 0 }} />
			</DataTable>
		);
	}
}