import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { Column } from "primereact/column";

export default class DataTableTemplates extends Component {
    constructor() {
        super();

        this.state = {
            data: [],
            rows: 10,
            totalRecords: 0,
            first: 0,
            last: 0,
            loading: false,
            sortField: "category",
            sortOrder: 1,
        };

        this.onPage = this.onPage.bind(this);
    }

    componentDidMount() {
        let templateConfigsDataTable = JSON.parse(localStorage.getItem("templateConfigsDataTable"));

        if (templateConfigsDataTable) {
            this.setState(
                {
                    ...templateConfigsDataTable,
                },
                () => {
                    this.fetchData();
                },
            );
        } else {
            this.fetchData();
        }
    }

    refreshTable() {
        this.onPage(this.state);
    }

    onPage = async (event) => {
        if (event.data) {
            event.data = null;
        }

        let templateConfigsDataTable = {
            rows: event.rows,
            totalRecords: this.state.totalRecords,
            first: event.first,
            last: event.rows + event.first,
            loading: this.state.loading,
            sortField: this.state.sortField,
            sortOrder: this.state.sortOrder,
        };

        localStorage.setItem("templateConfigsDataTable", JSON.stringify(templateConfigsDataTable));

        this.setState({
            rows: event.rows,
            loading: true,
        });

        this.fetchData();
    };

    sortData = (data, sortField, sortOrder) => {
        return data.sort((a, b) => {
            return a[sortField].toLowerCase() < b[sortField].toLowerCase()
                ? sortOrder
                : sortOrder - sortOrder * 2;
        });
    };

    fetchData = () => {
        this.setState({ loading: true });
        this.props.fetchEvent().then((res) => {
            console.log(res);
            this.setState({
                data: this.sortData(res.data, this.state.sortField, this.state.sortOrder),
                totalRecords: res.data.length,
                loading: false,
            });
        });
    };

    columnOptions = (rowData, column) => {
        let idRow = this.props.idRow ? this.props.idRow : "id";

        if (this.props.crudUrl) {
            return (
                <div style={{ textAlign: "center" }}>
                    <Link
                        to={`${this.props.crudUrl}/edit/${rowData[idRow]}`}
                        className="btn btn-success btn-table-action mr-2"
                    >
                        <i className="fa fa-edit"></i>
                    </Link>
                    <Link
                        to={`${this.props.crudUrl}/show/${rowData[idRow]}`}
                        className="btn btn-primary btn-table-action mr-2"
                    >
                        <i className="fas fa-eye"></i>
                    </Link>
                </div>
            );
        } else {
            return null;
        }
    };

    export() {
        this.dt.exportCSV();
    }

    getCategory = (obj) => {
        let idRow = this.props.idRow ? this.props.idRow : "id";
        if (obj[idRow]) {
            return (
                <div style={{ textAlign: "left" }}>
                    <Link to={`${this.props.crudUrl}/show/${obj[idRow]}`}>{obj.category}</Link>
                </div>
            );
        } else {
            return null;
        }
    };

    getVariables = ({ template_variables }) => {
        return template_variables?.variables?.join(' | ');
    };

    sortTable = (e) => {
        this.setState(
            {
                sortField: e.sortField,
                sortOrder: e.sortOrder,
            },
            () => {
                let templateConfigsDataTable = {
                    rows: this.state.rows,
                    totalRecords: this.state.totalRecords,
                    first: this.state.first,
                    last: this.state.last,
                    loading: this.state.loading,
                    sortField: this.state.sortField,
                    sortOrder: this.state.sortOrder,
                };

                localStorage.setItem("templateConfigsDataTable", JSON.stringify(templateConfigsDataTable));

                this.fetchData();
            },
        );
    };

    render() {
        const header = (
            <div style={{ float: "right" }}>
                <Button
                    className="tableHeaderItem"
                    type="button"
                    style={{ marginRight: "5px", marginLeft: "5px" }}
                    icon="fas fa-file-csv"
                    iconPos="left"
                    label="CSV"
                    onClick={() => this.export()}
                />
                <Button
                    className="tableHeaderItem"
                    icon={!this.state.loading ? "fas fa-sync" : "fas fa-sync fa-spin"}
                    disabled={this.state.loading ? "disabled" : ""}
                    onClick={() => this.refreshTable()}
                />
            </div>
        );

        return (
            <DataTable
                ref={(el) => (this.dt = el)}
                value={this.state.data}
                paginator={false}
                rows={this.state.rows}
                first={this.state.first}
                last={this.state.last}
                header={this.props.noHeader ? null : header}
                responsive={true}
                totalRecords={this.state.totalRecords}
                lazy={true}
                onPage={this.onPage}
                loading={this.state.loading}
                loadingIcon={`fas fa-sync fa-spin`}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros"
                emptyMessage="Nenhum registro encontrado!"
                onSort={(e) => this.sortTable(e)}
                sortField={this.state.sortField}
                sortOrder={this.state.sortOrder}
            >
                <Column sortable field="id" header="id" />
                <Column sortable field="category" body={(obj, i) => this.getCategory(obj)} header="category" />
                <Column sortable field="text" header="text" />
                <Column sortable field="variables" body={(obj, i) => this.getVariables(obj)} header="variables"></Column>
                <Column sortable field="created_at" header="created_at"></Column>
                <Column sortable field="updated_at" header="updated_at"></Column>
                <Column header="Opções" body={this.columnOptions} style={{ width: "150px" }} />
            </DataTable>
        );
    }
}
