import axios from "axios";
import { Constants } from '../helpers/Constants';
import { parseQuery } from "../helpers/Utils";

export function store(data) {
	return axios.post(`${Constants.baseUrl}/credit/consult`, data);
}

export function update(data) {
	return axios.put(`${Constants.baseUrl}/credit/consult`, data);
}

export function getByPropositionId(proposition_id) {
	return axios.get(`${Constants.baseUrl}/credit/consult/proposition/${proposition_id}`);
}

export function laudosQuery(params) {
	return axios.get(`${Constants.baseUrl}/credit/consult/laudos`, { params: parseQuery(params) });
}

export function aprovarHumanizado(data) {
	return axios.post(`${Constants.baseUrl}/credit/consult/approve-humanized`, data);
}

export function getTotalAnalises() {
	return axios.get(`${Constants.baseUrl}/credit/consult/laudos-total`);
}