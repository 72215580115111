import axios from "axios";
import { Constants } from '../helpers/Constants';
import { parseQuery } from '../helpers/Utils';

export function getAllByParams(params) {
    return axios.get(`${Constants.baseUrl}/notifications`, { params: parseQuery(params) });
}

export function getById(id) {
    return axios.get(`${Constants.baseUrl}/notifications/${id}`);
}