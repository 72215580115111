import React, { Component } from 'react';
import { Container, Card, CardBody, Col, Label, TabContent, TabPane, Nav, NavItem, NavLink, Alert, Row } from "reactstrap";
import classnames from 'classnames';
import BetterCircularProgress from '../../components/BetterCircularProgress';
import { formatBRL } from '../../helpers/Utils';
import Breadcrumbs from '../../components/Breadcrumb';
import moment from 'moment';
import { get } from '../../crud/coupons.crud';
import Swal from 'sweetalert2';
import { get as getPlans } from "../../crud/plans.crud";
import DataTableCouponApplications from '../../components/datatables/DataTableCouponApplications';
import { Link } from 'react-router-dom';

class CouponShow extends Component {

	state = {
		loading: false,
		success: [],
		message: null,
		data: null,
		landlord: null,
		tenant: null,
		activeTab: '1',
		tenant_parties: [],
		billings: [],
		planName: '',
		flags: {
			tenant_sign: false,
			landlord_sign: false
		},
		coupon_users: [],
		proposition_id: null,
		contract_id: null
	}

	componentDidMount = async () => {
		this.setState({ loading: true });
		let plan_list = [];

		await getPlans().then(({ data }) => {
			// this.setState({ plan_list: data.data });
			plan_list = data.data;
		});

		get(this.props.match.params.id).catch(err => {
			return Swal.fire('Ops', 'Ocorreu um erro ao buscar o cupom.', 'error');
		}).then(res => {
			if (res?.status === 200) {
				if (res.data) {
					let plan = null;

					if (plan_list.length > 0) {
						plan_list.forEach((obj, i) => {
							if (obj.id == res.data.plan_id) plan = obj;
						});
					}

					let coupon_users = [];
					let added_users = [];

					if (res.data.users) {
						res.data.users.forEach((obj, i) => {
							if (!added_users.includes(obj.user_id)) {
								coupon_users.push(obj);
								added_users.push(obj.user_id);
							}
						});
					}

					console.log({ coupon_users });

					this.setState({
						data: {
							code: res.data.code,
							plan: plan,
							discount_type: res.data.discount_type,
							discount_value: res.data.discount_type === 'FIXED_VALUE' ? formatBRL(res.data.discount_amount) : res.data.discount_amount,
							users: [],
							application_limit: res.data.application_limit,
							application_per_user_limit: res.data.application_per_user_limit,
							application_start_date: moment(res.data.application_start_date).format('YYYY-MM-DD HH:mm:ss'),
							application_end_date: moment(res.data.application_end_date).format('YYYY-MM-DD HH:mm:ss'),
							expiration_date: moment(res.data.expiration_date).format('YYYY-MM-DD HH:mm:ss'),
						},
						coupon_users,
						coupon: res.data,
						plan_list
					}, () => console.log(this.state));
				}
			}
		}).finally(() => this.setState({ loading: false }));
	};

	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	};

	formatDate(date) {
		const newDate = date?.split('/')[1] + '/' + date?.split('/')[0] + '/' + date?.split('/')[2];
		return newDate;
	}

	renderPlan = () => {
		return (
			this.state.data?.entity?.property?.warranty?.security_deposit_months > 0 ?
				<p className="col-form-label" style={{ fontSize: '13px', lineHeight: '16px' }}>{"- " + this.state.data?.entity?.property?.warranty?.security_deposit_months + " caução de entrada"}</p>
				:
				<p className="col-form-label" style={{ fontSize: '13px', lineHeight: '16px' }}>Sem garantia</p>
		)
	}

	render() {
		return (
			<React.Fragment>
				<BetterCircularProgress loading={this.state.loading}>
					<div className="page-content container-page">
						<Container fluid>
							<Breadcrumbs breadcrumbItems={[{ link: '/coupons/index', title: 'Cupons' }, { link: `/coupons/show/${this.state.data?.entity?.id}`, title: 'Visualizar' }]} />
							{/* <BetterAlerts success={this.state.success} /> */}

							{this.state.success && this.state.message ? <Alert color="success">{this.state.message}</Alert> : null}

							<Card>
								<CardBody>
									<Nav tabs className="nav-tabs-custom" role="tablist">
										<NavItem>
											<NavLink id="details-tab" className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggleTab('1'); }} >Detalhes</NavLink>
										</NavItem>
										<NavItem>
											<NavLink id="applications-tab" className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggleTab('2'); }}>Aplicações</NavLink>
										</NavItem>
									</Nav>
									<TabContent activeTab={this.state.activeTab} className="">
										<TabPane tabId="1" role="tabpanel">
											<Row style={{ marginTop: '24px' }}>
												<Col md={12} className="d-flex">
													<p style={{ color: "#505D69", fontSize: "23px", fontWeight: '600', lineHeight: '28px' }} >
														Geral <span style={{ color: "#8590A5", fontSize: "13px", fontWeight: '600', lineHeight: '16px' }}>(Obrigatório)</span>
													</p>
												</Col>
											</Row>

											<Row>
												<Col md={6}>
													<Label style={{ fontSize: '13px', lineHeight: '16px', color: '#DADBDD' }} className="col-form-label">Código do cupom *</Label>
													<p className="col-form-label" style={{ fontSize: '13px', lineHeight: '16px', color: '#505D69', paddingTop: 0 }}>{this.state?.data?.code}</p>
												</Col>

												<Col md={6}>
													<Label style={{ fontSize: '13px', lineHeight: '16px', color: '#DADBDD' }} className="col-form-label">Plano *</Label>
													<p className="col-form-label" style={{ fontSize: '13px', lineHeight: '16px', color: '#505D69', paddingTop: 0 }}>{this.state?.data?.plan.friendly_name}</p>
												</Col>

												<Col md={6}>
													<Label style={{ fontSize: '13px', lineHeight: '16px', color: '#DADBDD' }} className="col-form-label">Tipo do desconto *</Label>
													<p className="col-form-label" style={{ fontSize: '13px', lineHeight: '16px', color: '#505D69', paddingTop: 0 }}>{this.state?.data?.discount_type === 'PERCENTAGE' ? 'Porcentagem' : 'Valor fixo'}</p>
												</Col>

												<Col md={6}>
													<Label style={{ fontSize: '13px', lineHeight: '16px', color: '#DADBDD' }} className="col-form-label">Tipo do desconto *</Label>
													<p className="col-form-label" style={{ fontSize: '13px', lineHeight: '16px', color: '#505D69', paddingTop: 0 }}>{this.state?.data?.discount_type === 'PERCENTAGE' ? `${this.state.data?.discount_value}%` : this.state.data?.discount_value}</p>
												</Col>
											</Row>

											<Row style={{ marginTop: '24px' }}>
												<Col md={12} className="d-flex">
													<p style={{ color: "#505D69", fontSize: "23px", fontWeight: '600', lineHeight: '28px' }} >
														Restrições de uso <span style={{ color: "#8590A5", fontSize: "13px", fontWeight: '600', lineHeight: '16px' }}>(Opcional)</span>
													</p>
												</Col>
											</Row>

											<Row>
												<Col md={6}>
													<Label style={{ fontSize: '13px', lineHeight: '16px', color: '#DADBDD' }} className="col-form-label">Usuários *</Label>
													<p className="col-form-label" style={{ fontSize: '13px', lineHeight: '16px', color: '#505D69', paddingTop: 0 }}>
														{this.state?.coupon?.type === 'PERSONAL' ?
															this.state?.coupon_users.map((obj, i) => (
																<Link style={{ marginRight: 5 }} key={`user_${i}`} to={`/users/show/${obj.user_id}`}>{obj.user.name};</Link>
															))
															:
															'Não definido'
														}
													</p>
												</Col>

												<Col md={6}>
													<Label style={{ fontSize: '13px', lineHeight: '16px', color: '#DADBDD' }} className="col-form-label">Limites de uso por usuário</Label>
													<p className="col-form-label" style={{ fontSize: '13px', lineHeight: '16px', color: '#505D69', paddingTop: 0 }}>{this.state?.data?.application_per_user_limit ?? 'Não definido'}</p>
												</Col>

												<Col md={6}>
													<Label style={{ fontSize: '13px', lineHeight: '16px', color: '#DADBDD' }} className="col-form-label">Limites de aplicação</Label>
													<p className="col-form-label" style={{ fontSize: '13px', lineHeight: '16px', color: '#505D69', paddingTop: 0 }}>{this.state?.data?.application_limit ?? 'Não definido'}</p>
												</Col>
											</Row>

											<Row style={{ marginTop: '24px' }}>
												<Col md={12} className="d-flex">
													<p style={{ color: "#505D69", fontSize: "23px", fontWeight: '600', lineHeight: '28px' }} >
														Datas <span style={{ color: "#8590A5", fontSize: "13px", fontWeight: '600', lineHeight: '16px' }}>(Opcional)</span>
													</p>
												</Col>
											</Row>

											<Row>
												<Col md={6}>
													<Label style={{ fontSize: '13px', lineHeight: '16px', color: '#DADBDD' }} className="col-form-label">Data de inicial de aplicação</Label>
													<p className="col-form-label" style={{ fontSize: '13px', lineHeight: '16px', color: '#505D69', paddingTop: 0 }}>{this.state?.coupon?.application_start_date ? moment(this.state?.coupon?.application_start_date).format('YYYY-MM-DD HH:mm:ss') : 'Não definido'}</p>
												</Col>

												<Col md={6}>
													<Label style={{ fontSize: '13px', lineHeight: '16px', color: '#DADBDD' }} className="col-form-label">Validade de aplicação</Label>
													<p className="col-form-label" style={{ fontSize: '13px', lineHeight: '16px', color: '#505D69', paddingTop: 0 }}>{this.state?.coupon?.application_end_date ? moment(this.state?.coupon?.application_end_date).format('YYYY-MM-DD HH:mm:ss') : 'Não definido'}</p>
												</Col>

												<Col md={6}>
													<Label style={{ fontSize: '13px', lineHeight: '16px', color: '#DADBDD' }} className="col-form-label">Data de expiração</Label>
													<p className="col-form-label" style={{ fontSize: '13px', lineHeight: '16px', color: '#505D69', paddingTop: 0 }}>{this.state?.coupon?.expiration_date ? moment(this.state?.coupon?.expiration_date).format('YYYY-MM-DD HH:mm:ss') : 'Não definido'}</p>
												</Col>
											</Row>
										</TabPane>

										<TabPane tabId="2" role="tabpanel">
											<div className="d-flex">
												<DataTableCouponApplications noDelete crudButtons={false} idRow={`id`} noShow couponId={this.props.match.params.id} />
											</div>
										</TabPane>
									</TabContent>
								</CardBody>

								<CardBody className="row">
									<Link to={'/coupons/index'} className="btn btn-secondary btn-bold ml-2"><i className="fa fa-arrow-left mr-2"></i>Voltar</Link>
									<Link to={'/coupons/edit/' + this.props.match.params.id} className="btn btn-primary ml-2">Editar <i className="fa fa-pen ml-2"></i></Link>
								</CardBody>
							</Card>
						</Container>
					</div>
				</BetterCircularProgress>
			</React.Fragment>
		)
	}
}

export default CouponShow;