import axios from "axios";
import { Constants } from '../helpers/Constants';
import { parseQuery } from '../helpers/Utils';

export function getByFilter(params) {
	return axios.get(`${Constants.baseUrl}/users`, { params: parseQuery(params) });
}

export const getTotalUsers = (params) => {
	return axios.get(`${Constants.baseUrl}/users/v2/total-users`, { params: parseQuery(params) });
};

export function get(id) {
	return axios.get(`${Constants.baseUrl}/users/${id}`);
}

export function update(id, data) {
	return axios.patch(`${Constants.baseUrl}/users/${id}`, data);
}

export function store(data) {
	return axios.post(`${Constants.baseUrl}/users`, data);
}

export function getAccountBank(id) {
	return axios.get(`${Constants.baseUrl}/users/${id}/bank-account`);
}

export const storeBankAccount = (id, data) => {
	return axios.post(`${Constants.baseUrl}/users/${id}/bank-account`, data);
};

export const updateProfile = (data, token = null) => {
	return axios.patch(`${Constants.baseUrl}/profile`, data);
};

export const getExternalAuth = (data) => {
	return axios.post(`${Constants.baseUrl}/users/ext-auth`, data);
};

// Fiscal Documents
export function storeFiscalDocuments(data) {
	return axios.post(`${Constants.baseUrl}/users/fiscal-documents/`, data);
}

export function queryFiscalDocuments(params) {
	const parse = parseQuery(params);
	const queryString = new URLSearchParams(parse).toString();
	return axios.post(`${Constants.baseUrl}/users/fiscal-documents/query?${queryString}`);
}

export function updateFiscalDocuments(id, data) {
	return axios.patch(`${Constants.baseUrl}/users/fiscal-documents/${id}`, data);
}

export function generateDimob({ user_id, yearReference }) {
	return axios.post(`${Constants.baseUrl}/contracts/generate-dimob?user_id=${user_id}&yearReference=${yearReference}`);
}

export function generateIncome({ user_id, yearReference }) {
	return axios.post(`${Constants.baseUrl}/contracts/generate-income?user_id=${user_id}&yearReference=${yearReference}`);
}

export function getGratifications(params) {
	return axios.get(`${Constants.baseUrl}/gratifications`, { params: parseQuery(params) });
}

export function createGratification(data) {
	return axios.post(`${Constants.baseUrl}/gratifications`, data);
}

export function updateGratification(id, data) {
	return axios.patch(`${Constants.baseUrl}/gratifications/${id}`, data);
}

export function getGratificationById(id) {
	return axios.get(`${Constants.baseUrl}/gratifications/${id}`);
}

export function getMonthlyBonus(params) {
	return axios.get(`${Constants.baseUrl}/gratifications/monthly-bonus`, { params: parseQuery(params) });
}

export function getTotalBonusRate() {
	return axios.get(`${Constants.baseUrl}/gratifications/total-bonus-rate`);
}