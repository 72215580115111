/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { Container, Card, CardBody, Button } from "reactstrap";
import BetterCircularProgress from '../../components/BetterCircularProgress';
import { Redirect } from "react-router-dom";
import Breadcrumbs from '../../components/Breadcrumb';
import { Checkbox, TextField } from "@material-ui/core";
import BetterAlerts from '../../components/BetterAlerts';
import { createContractualEdit, get, getContractUsers } from '../../crud/contracts.crud';
import moment from 'moment';
import { formatBRL, formatCPFInput, formatCep, formatCepInput, formatTelefoneInput, getStates, limparCPF, limparTelefone } from '../../helpers/Utils';
import { formatBRLInput, formatDate, limparMoeda } from '../negotiation/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faEnvelope, faFileCheck } from '@fortawesome/pro-solid-svg-icons';
import { Formik } from 'formik';
import cashIcon from '../../assets/images/icons-custom/cash.svg';
import listIcon from '../../assets/images/icons-custom/icon.svg';
import groupIcon from '../../assets/images/icons-custom/user-group.svg';
import { validateContractEdit } from '../../helpers/FormValidators';
import { get as getBillings } from '../../crud/billings.crud';

class FriendlyAgreement extends Component {

	state = {
		loading: false,
		errors: [],
		success: false,
		contract: null,
		currentStep: 1,
		contract_users: [],
		proporcional_value: 0,
		edit_id: null,
		nextPayment: 'DD/MM/YYYY'
	}

	componentDidMount = () => {
		this.setState({ loading: true });
		console.log(`rerender`);

		get(this.props.match.params.id).then(async res => {
			if (res?.status == 200) {
				this.setState({ contract: res.data.data?.entity });

				getContractUsers(this.props.match.params.id).then(async res => {
					if (res?.status == 200) {
						this.setState({ contract_users: res.data.data });
					}
				})

				getBillings({ is_paid: false, contract_id: this.props.match.params.id, due_date_gte: moment().add(11, 'days').format('YYYY-MM-DD'), type: 'monthly', '$sort[due_date]': 1, $limit: 1 }).then(res => {
					if (res?.status) {
						this.setState({ nextPayment: moment(res.data.data[0].due_date).add(3, 'hours').format('DD/MM/YYYY') })
					}
				}).catch(err => {
					console.log(err);
				})
			}
		}).catch(({ response }) => {
			if (response?.data?.message) {
				this.setState({ errors: [...this.state.errors, response?.data?.message] });
			} else {
				this.setState({ errors: [...this.state.errors, 'houve um erro, entre em contato com o suporte'] });
			}
		}).finally(() => {
			this.setState({ loading: false })
		});
	};

	onSubmit = async (values) => {
		console.log(values);

		if (this.state.currentStep == 1) {
			this.setState({ currentStep: 3 })
			return;
		}

		// if (this.state.currentStep == 2) {
		// 	if (limparCPF(values.witness_1_cpf) === limparCPF(values.witness_2_cpf)) {
		// 		this.setState({ errors: [...this.state.errors, 'As duas testemunhas não podem ser a mesma pessoa.'] });
		// 		return
		// 	}

		// 	if (values.witness_2_email === values.witness_1_email) {
		// 		this.setState({ errors: [...this.state.errors, 'Os emails das duas testemunhas não podem ser iguais'] });
		// 		return;
		// 	}

		// 	for (const u of this.state?.contract_users) {
		// 		if (u.user.email === values.witness_1_email || u.user.email === values.witness_2_email) {
		// 			this.setState({ errors: [...this.state.errors, 'O email da testemunha não pode ser o mesmo do usuário que figura no contrato.'] });
		// 			return;
		// 		}

		// 		if (u.user.cpf === limparCPF(values.witness_1_cpf) || u.user.cpf === limparCPF(values.witness_2_cpf)) {
		// 			this.setState({ errors: [...this.state.errors, 'O cpf de uma testemunha não pode ser o mesmo de um usuário que figura no contrato.'] });
		// 			return;
		// 		}

		// 		if (u.user.phone === limparTelefone(values.witness_1_phone) || u.user.phone === limparTelefone(values.witness_2_phone)) {
		// 			this.setState({ errors: [...this.state.errors, 'O telefone da testemunha não pode ser o mesmo de um usuário que figura no contrato.'] });
		// 			return;
		// 		}
		// 	}

		// 	this.setState({ errors: [] });
		// 	this.setState({ currentStep: 3 })
		// 	return;
		// }

		if (!values.check_final) {
			this.setState({ errors: [...this.state.errors, 'Confirme a criação do termo aditivo para prosseguir.'] });
			return;
		}

		let form = {
			"contract_id": this.props.match.params.id,
			"type": values.type,
			"sign_expiration_date": moment(values.signExpirationDate).format('YYYY-MM-DD'),
		}

		if (values.type === 'CONTRACT_DATA') {
			let editTenants = []

			const tenants = this.state?.contract_users.filter(obj => obj.user_type === 'tenant');
			let admin;

			admin = this.state?.contract_users.find(obj => obj.user_type === 'admin');

			if (!admin) {
				admin = this.state?.contract_users.find(obj => obj.user_type === 'landlord');
			}

			for (const tenant of tenants) {
				if (values[`check_tenant_${tenant.user_id}`]) {
					editTenants.push({
						user_id: tenant.user_id,
						name: values[`tenant_${tenant.user_id}_name`],
						address: {
							zip_code: limparTelefone(values[`tenant_${tenant.user_id}_cep`]),
							state: values[`tenant_${tenant.user_id}_state`],
							city: values[`tenant_${tenant.user_id}_city`],
							neighbourhood: values[`tenant_${tenant.user_id}_neighbourhood`],
							street: values[`tenant_${tenant.user_id}_street`],
							number: values[`tenant_${tenant.user_id}_number`],
							complement: values[`tenant_${tenant.user_id}_complement`]
						}
					})
				}
			}

			if (values.check_landlord_data) {
				form.landlord_new_info = {
					user_id: admin.user_id,
					name: values.landlord_name,
					address: {
						zip_code: limparTelefone(values.landlord_cep),
						state: values.landlord_state,
						city: values.landlord_city,
						neighbourhood: values.landlord_neighbourhood,
						street: values.landlord_street,
						number: values.landlord_number,
						complement: values.landlord_complement
					}
				}
			}

			if (values.check_announcement_data) {
				form.announcement_new_info = {
					zip_code: limparTelefone(values.announcement_cep),
					state: values.announcement_state,
					city: values.announcement_city,
					neighbourhood: values.announcement_neighbourhood,
					street: values.announcement_street,
					number: values.announcement_number,
					complement: values.announcement_complement

				}
			}

			if (values.check_tenant_data) {
				form.tenant_new_info = editTenants
			}
		}

		if (values.type === 'PAYMENT') {
			form.contract_new_info = {
				"new_due_date": values?.check_due_date ? moment(values.new_due_date).format('YYYY-MM-DD') : null,
				"new_base_value": values?.check_base_value ? parseFloat(limparMoeda(values.new_base_value)) : null,
				"next_rent_value": values?.check_next_value ? parseFloat(limparMoeda(values.next_rent_value)) : null
			}
		}

		this.setState({ loading: true })
		await createContractualEdit(form).then(res => {
			if (res?.status === 200) {
				console.log(res);

				this.setState({ edit_id: res?.data?.id })
				this.setState({ loading: false })
				this.setState({ success: ['Edição contratual criada com sucesso!'] })
			}
		}).catch(err => {
			this.setState({ loading: false })
			this.setState({ errors: ['Houve um erro ao tentar criar o termo aditivo!', ...this.state.errors] })
		})

		return;
	};

	getInitialValues = () => {
		const tenants = this.state?.contract_users.filter(obj => obj.user_type === 'tenant')

		let admin;

		admin = this.state?.contract_users.find(obj => obj.user_type === 'admin');

		if (!admin) {
			admin = this.state?.contract_users.find(obj => obj.user_type === 'landlord');
		}

		const property = this.state?.contract?.property
		const initialValues = {
			new_base_value: formatBRL(this.state.contract?.details?.values?.rent) ?? 0,
			next_rent_value: formatBRL(this.state.contract?.details?.values?.rent) ?? 0,
			new_due_date: null,
			type: 'CONTRACT_DATA',
			check_base_value: false,
			check_next_value: false,
			check_due_date: false,
			check_announcement_data: false,
			check_landlord_data: false,
			check_tenant_data: false,
			signExpirationDate: null,

			//landlord
			landlord_name: admin?.user?.name ?? '',
			landlord_cep: formatCep(admin?.user?.address?.zip_code) ?? '',
			landlord_state: admin?.user?.address?.state ?? '',
			landlord_city: admin?.user?.address?.city ?? '',
			landlord_neighbourhood: admin?.user?.address?.neighbourhood ?? '',
			landlord_street: admin?.user?.address?.street ?? '',
			landlord_number: admin?.user?.address?.number ?? '',
			landlord_complement: admin?.user?.address?.complement ?? '',

			//announcement
			announcement_cep: formatCep(property?.address?.zip_code) ?? '',
			announcement_state: property?.address?.state ?? '',
			announcement_city: property?.address?.city ?? '',
			announcement_neighbourhood: property?.address?.neighbourhood ?? '',
			announcement_street: property?.address?.street ?? '',
			announcement_number: property?.address?.number ?? '',
			announcement_complement: property?.address?.complement ?? '',

			// witnesses
			witness_1_name: '',
			witness_1_phone: '',
			witness_1_email: '',
			witness_1_cpf: '',

			witness_2_name: '',
			witness_2_phone: '',
			witness_2_email: '',
			witness_2_cpf: '',

			check_final: false,

			currentStepForm: 1
		}


		// Iterar inquilinos
		for (const tenant of tenants) {
			initialValues[`tenant_${tenant.user_id}_name`] = tenant?.user?.name;
			initialValues[`tenant_${tenant.user_id}_cep`] = formatCep(tenant?.user?.address?.zip_code);
			initialValues[`tenant_${tenant.user_id}_state`] = tenant?.user?.address?.state;
			initialValues[`tenant_${tenant.user_id}_city`] = tenant?.user?.address?.city;
			initialValues[`tenant_${tenant.user_id}_neighbourhood`] = tenant?.user?.address?.neighbourhood;
			initialValues[`tenant_${tenant.user_id}_street`] = tenant?.user?.address?.street;
			initialValues[`tenant_${tenant.user_id}_number`] = tenant?.user?.address?.number;
			initialValues[`tenant_${tenant.user_id}_complement`] = tenant?.user?.address?.complement;
		}

		console.log(initialValues);
		return initialValues
	};

	getLandlordChages = (values) => {
		let admin;

		admin = this.state?.contract_users.find(obj => obj.user_type === 'admin');

		if (!admin) {
			admin = this.state?.contract_users.find(obj => obj.user_type === 'landlord');
		}

		let string = '';

		if (admin?.user?.name !== values?.landlord_name) string = `${values.landlord_name};`;
		if (limparTelefone(admin?.user?.address?.zip_code) !== limparTelefone(values.landlord_cep)) string = `${string} ${values.landlord_cep};`
		if (admin?.user?.address?.city !== values.landlord_city) string = `${string} ${values.landlord_city};`
		if (admin?.user?.address?.state !== values.landlord_state) string = `${string} ${values.landlord_state};`
		if (admin?.user?.address?.street !== values.landlord_street) string = `${string} ${values.landlord_street};`
		if (admin?.user?.address?.neighbourhood !== values.landlord_neighbourhood) string = `${string} ${values.landlord_neighbourhood};`
		if (admin?.user?.address?.number !== values.landlord_number) string = `${string} ${values.landlord_number};`
		if (admin?.user?.address?.complement !== values.landlord_complement) string = `${string} ${values.landlord_complement};`

		return string;
	}

	getTenantChages = (tenant, values) => {
		const tenantOld = this.state?.contract_users.find(obj => obj.user_id === tenant.user_id)
		console.log(values);
		console.log(tenantOld);

		let string = '';

		if (tenantOld?.user?.name !== values[`tenant_${tenant.user_id}_name`]) string = `${values[`tenant_${tenant.user_id}_name`]};`;
		if (limparTelefone(tenantOld?.user?.address?.zip_code) !== limparTelefone(values[`tenant_${tenant.user_id}_cep`])) string = `${string} ${values[`tenant_${tenant.user_id}_cep`]};`
		if (tenantOld?.user?.address?.city !== values[`tenant_${tenant.user_id}_city`]) string = `${string} ${values[`tenant_${tenant.user_id}_city`]};`
		if (tenantOld?.user?.address?.state !== values[`tenant_${tenant.user_id}_state`]) string = `${string} ${values[`tenant_${tenant.user_id}_state`]};`
		if (tenantOld?.user?.address?.street !== values[`tenant_${tenant.user_id}_street`]) string = `${string} ${values[`tenant_${tenant.user_id}_street`]};`
		if (tenantOld?.user?.address?.neighbourhood !== values[`tenant_${tenant.user_id}_neighbourhood`]) string = `${string} ${values[`tenant_${tenant.user_id}_neighbourhood`]};`
		if (tenantOld?.user?.address?.number !== values[`tenant_${tenant.user_id}_number`]) string = `${string} ${values[`tenant_${tenant.user_id}_number`]};`
		if (tenantOld?.user?.address?.complement !== values[`tenant_${tenant.user_id}_complement`]) string = `${string} ${values[`tenant_${tenant.user_id}_complement`]};`

		return string;
	}

	getAnnouncementChages = (values) => {
		const property = this.state?.contract?.property

		let string = '';

		if (limparTelefone(property?.address?.zip_code) !== limparTelefone(values.announcement_cep)) string = `${string} ${values.announcement_cep};`
		if (property?.address?.city !== values.announcement_city) string = `${string} ${values.announcement_city};`
		if (property?.address?.state !== values.announcement_state) string = `${string} ${values.announcement_state};`
		if (property?.address?.street !== values.announcement_street) string = `${string} ${values.announcement_street};`
		if (property?.address?.neighbourhood !== values.announcement_neighbourhood) string = `${string} ${values.announcement_neighbourhood};`
		if (property?.address?.number !== values.announcement_number) string = `${string} ${values.announcement_number};`
		if (property?.address?.complement !== values.announcement_complement) string = `${string} ${values.announcement_complement};`

		return string;
	}


	disabledConditions = values => {
		if (!values?.signExpirationDate) {
			return true;
		}

		if (values.type === 'CONTRACT_DATA' && !values.check_landlord_data && !values.check_announcement_data && !values.check_tenant_data) {
			return true;
		}

		if (values.type === 'CONTRACT_DATA' && !values.check_landlord_data && !values.check_announcement_data && !values.check_tenant_data) {
			return true;
		}

		if (values.type === 'PAYMENT' && (!values.check_base_value && !values.check_due_date && !values.check_next_value)) {

			return true;
		}

		if (values.type === 'PAYMENT' && values.check_due_date && !values.new_due_date) {

			return true;
		}

		if (values.type === 'CONTRACT_DATA' && values.check_tenant_data) {
			let atLeastOne = false;
			for (const tenant of this.state?.contract_users.filter(obj => obj.user_type === 'tenant')) {
				if (values[`check_tenant_${tenant.user_id}`]) {
					atLeastOne = true;
				}
			}

			if (!atLeastOne) {
				return true;
			}
		}

		// if (values.type === 'PAYMENT' && values.check_due_date && values.new_due_date === null) {
		// 	return true;
		// }

		if (this.state?.currentStep === 2 && (!values.witness_1_cpf || !values.witness_1_name || !values.witness_1_phone || !values.witness_1_email)) {
			return true;
		}

		if (this.state?.currentStep === 2 && (!values.witness_2_cpf || !values.witness_2_name || !values.witness_2_phone || !values.witness_2_email)) {
			return true;
		}

		return false
	};

	calcProportionalValue = (e) => {
		let oldDate = moment(this.state?.nextPayment, 'DD/MM/YYYY').format('YYYY-MM-DD');
		let newDate = e.target.value;

		let diff = moment(oldDate).diff(newDate, 'days');
		diff = Math.abs(diff)

		let daysInMonth = 30;

		let finalValue = (parseFloat(this.state?.contract?.details?.values?.rent) * diff) / daysInMonth;

		const oldDay = moment(this.state?.nextPayment, 'DD/MM/YYYY').format('YYYY-MM-DD');
		const newDay = moment(e.target.value)

		if (moment(oldDay).isAfter(moment(newDay))) {
			finalValue = finalValue * -1;
		}

		this.setState({ proporcional_value: finalValue })

		return;
	}

	render() {
		// const RadioButton = ({ handleChange, handleBlur, values, field, name, text }) => {
		// 	const isHTML = (str) => /<\/?[a-z][\s\S]*>/i.test(str);

		// 	return (
		// 		<Col md={12} style={{ marginLeft: "10px" }}>
		// 			<Input id={field} type="radio" name={name} value={field}
		// 				onChange={handleChange} onBlur={handleBlur} color="primary"
		// 				checked={values[name] === field}
		// 			/>
		// 			<Label style={{ padding: "3px", marginLeft: 2 }} for={field}>
		// 				{isHTML(text) ? (
		// 					<span dangerouslySetInnerHTML={{ __html: text }} />
		// 				) : (
		// 					text
		// 				)}
		// 			</Label>
		// 		</Col>
		// 	)
		// }

		const WarningContainer = ({ styleContainer, title, titleColor, children }) => {
			return (
				<div className={'warning'} style={styleContainer}>
					<h5 style={{ color: titleColor }}>{title}</h5>

					<div>{children}</div>
				</div>
			);
		}

		return (
			<React.Fragment>
				{this.state.success ? (
					<Redirect
						to={{
							pathname: `/contracts/show/${this.props.match.params.id}`,
							state: { success: ["Edição contratual criada com sucesso!"] },
						}}
					/>
				) : null}
				<BetterCircularProgress loading={this.state.loading}>
					<div className="page-content container-page friendly-agreement">
						<Container fluid>
							<Breadcrumbs breadcrumbItems={[{ link: '/negotiation/index', title: 'Contrato' }, { link: `/negotiation/show/${this.state?.contract?.proposition_id}`, title: 'Visualizar' }, { link: `/contracts/contractual-edit/${this.props.match.params.id}`, title: 'Edição contratual' }]} />

							<BetterAlerts errors={this.state.errors} />

							{this.state.success ?
								<Redirect to={{ pathname: `/show/contractual-edit/${this?.state?.edit_id}`, state: { success: ['Edição contratual criada com sucesso!'] } }} />
								: null
							}

							{this.state?.contract_users.length > 0 ? (
								<Formik initialValues={this.getInitialValues()} validate={values => validateContractEdit(values, this.state.currentStep, this.state?.contract_users)} onSubmit={(values) => this.onSubmit(values)} >
									{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
										<form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>
											<Card>
												{this.state?.currentStep === 1 ? (
													<CardBody className="column contractual-edit">
														<div className=''>
															<h1>Edição contratual</h1>
														</div>
														<div>
															<h6>
																Preencha os campos abaixo para criar um aditivo de contrato. O documento de aditivo precisa ser assinado por todas as partes para que as alterações preenchidas abaixo passem a ter validade.
															</h6>
														</div>

														<div style={{ marginTop: '22px' }}>
															<p className='input-label' >Data máxima para assinatura</p>

															<TextField className="mb-2" style={{ background: "#FFF", height: "40px" }} name={'signExpirationDate'} placeholder="00/00/0000"
																type="date" variant="outlined" value={values.signExpirationDate} InputLabelProps={{ shrink: true }} inputProps={{
																	min: formatDate({ date: moment().add(1, 'days'), format: 'YYYY-MM-DD' }),
																	max: values.check_due_date && values.new_due_date ? formatDate({ date: moment(values.new_due_date).subtract(5, 'days'), format: 'YYYY-MM-DD' }) : ''
																}}
																onChange={async e => { handleChange(e); }} onBlur={handleBlur} />

															<p>A data definida será o último dia para assinar o termo aditivo. Se for uma edição de pagamento, defina a data máxima como no mínimo 2 dias úteis antes do próximo pagamento ser gerado.</p>
														</div>

														<div style={{ marginTop: '22px' }}>
															<p className='input-label'>Tipo de edição contratual</p>

															<select style={{ background: "#FFF", height: "38px" }} name='type'
																onChange={handleChange} handleBlur={handleBlur} value={values.type} className="custom-select" >

																<option key='contract_data' value='CONTRACT_DATA'>Dados contratuais</option>
																<option key='payment' value='PAYMENT'>Pagamento</option>
															</select>
														</div>

														<hr />

														{values.type === 'CONTRACT_DATA' ? (
															<>
																<div className=''>
																	<h1>Edição contratual de dados</h1>
																</div>
																<div>
																	<h6 style={{ fontWeight: '400' }}>
																		<small style={{ marginRight: '4px', fontFamily: 'Inter', fontSize: '13px', fontWeight: '600', color: '#8590a5' }}>Lembre-se:</small> Para evitar golpes, peça um documento de comprovação do erro ao preencher para justificar a edição.
																	</h6>
																</div>

																<div style={{ marginTop: '22px' }}>
																	<p className='input-label'>Selecione as edições necessárias</p>

																	<div className='d-flex align-items-center '>
																		<Checkbox name="check_landlord_data" color="primary" checked={values.check_landlord_data} onBlur={handleBlur} onChange={handleChange} />
																		Dados do responsável
																	</div>

																	<div className='d-flex align-items-center '>
																		<Checkbox name="check_tenant_data" color="primary" checked={values.check_tenant_data} onBlur={handleBlur} onChange={handleChange} />
																		Dados do inquilino
																	</div>

																	<div className='d-flex align-items-center '>
																		<Checkbox name="check_announcement_data" color="primary" checked={values.check_announcement_data} onBlur={handleBlur} onChange={handleChange} />
																		Dados do imóvel
																	</div>
																</div>

																{this.state?.contract_users?.length > 0 && values.check_landlord_data ? (
																	<div style={{ marginTop: '18px' }}>
																		<div>
																			<h5>Responsável pelo imóvel</h5>
																		</div>

																		<div style={{ marginTop: '18px' }} className='d-flex justify-content-between'>
																			<div style={{ width: '49%' }}>
																				<p className='input-label'>Nome completo</p>
																				<TextField name="landlord_name" placeholder={'Nome completo'} className={'input mb-2'} type="text" variant="outlined" value={values.landlord_name} style={{ background: '#FFF' }}
																					onBlur={handleBlur} onChange={handleChange} helperText={touched.landlord_name && errors.landlord_name} error={Boolean(touched.landlord_name && errors.landlord_name)}
																				/>
																			</div>
																			{console.log(Boolean(touched.landlord_cep && errors.landlord_cep))}
																			<div style={{ width: '49%' }}>
																				<p className='input-label'>CEP</p>
																				<TextField name="landlord_cep" placeholder={'CEP'} className={'input mb-2'} type="text" variant="outlined" value={values.landlord_cep} style={{ background: '#FFF' }}
																					onBlur={handleBlur} onChange={(e) => handleChange(formatCepInput(e))} helperText={touched.landlord_cep && errors.landlord_cep} error={Boolean(touched.landlord_cep && errors.landlord_cep)} inputProps={{ maxLength: 9 }}
																				/>
																			</div>
																		</div>

																		<div style={{ marginTop: '18px' }} className='d-flex justify-content-between'>
																			<div style={{ width: '49%' }}>
																				<p className='input-label'>Estado</p>
																				<select style={{ background: "#FFF", height: "38px" }} name='landlord_state'
																					onChange={handleChange} handleBlur={handleBlur} value={values.landlord_state} className="custom-select" >
																					{getStates().map(state => (
																						<option key={state} value={state}>{state}</option>
																					))}
																				</select>
																			</div>
																			<div style={{ width: '49%' }}>
																				<p className='input-label'>Cidade</p>
																				<TextField name="landlord_city" placeholder={'Digite a cidade...'} className={'input mb-2'} type="text" variant="outlined" value={values.landlord_city} style={{ background: '#FFF' }}
																					onBlur={handleBlur} onChange={handleChange} helperText={touched.landlord_city && errors.landlord_city} error={Boolean(touched.landlord_city && errors.landlord_city)}
																				/>
																			</div>
																		</div>

																		<div style={{ marginTop: '18px' }} className='d-flex justify-content-between'>
																			<div style={{ width: '49%' }}>
																				<p className='input-label'>Bairro</p>
																				<TextField name="landlord_neighbourhood" placeholder={'Digite o bairro...'} className={'input mb-2'} type="text" variant="outlined" value={values.landlord_neighbourhood} style={{ background: '#FFF' }}
																					onBlur={handleBlur} onChange={handleChange} helperText={touched.landlord_neighbourhood && errors.landlord_neighbourhood} error={Boolean(touched.landlord_neighbourhood && errors.landlord_neighbourhood)}
																				/>
																			</div>
																			<div style={{ width: '49%' }}>
																				<p className='input-label'>Endereço</p>
																				<TextField name="landlord_street" placeholder={'Digite a rua da residência'} className={'input mb-2'} type="text" variant="outlined" value={values.landlord_street} style={{ background: '#FFF' }}
																					onBlur={handleBlur} onChange={handleChange} helperText={touched.landlord_street && errors.landlord_street} error={Boolean(touched.landlord_street && errors.landlord_street)}
																				/>
																			</div>
																		</div>

																		<div style={{ marginTop: '18px' }} className='d-flex justify-content-between'>
																			<div style={{ width: '49%' }}>
																				<p className='input-label'>Número</p>
																				<TextField name="landlord_number" placeholder={'Digite o número da residência...'} className={'input mb-2'} type="text" variant="outlined" value={values.landlord_number} style={{ background: '#FFF' }}
																					onBlur={handleBlur} onChange={handleChange} helperText={touched.landlord_number && errors.landlord_number} error={Boolean(touched.landlord_number && errors.landlord_number)}
																				/>
																			</div>
																			<div style={{ width: '49%' }}>
																				<p className='input-label'>Complemento</p>
																				<TextField name="landlord_complement" placeholder={'Digite o complemento...'} className={'input mb-2'} type="text" variant="outlined" value={values.landlord_complement} style={{ background: '#FFF' }}
																					onBlur={handleBlur} onChange={handleChange} helperText={touched.landlord_complement && errors.landlord_complement} error={Boolean(touched.landlord_complement && errors.landlord_complement)}
																				/>
																			</div>
																		</div>
																	</div>
																) : null}

																{this.state?.contract_users?.length > 0 && values.check_tenant_data ? (
																	<div style={{ marginTop: '18px' }}>
																		<div>
																			<h5>Inquilinos</h5>
																		</div>

																		<div>
																			<p className='input-label'>Selecione os inquilinos que você deseja alterar os dados:</p>
																		</div>

																		<div>
																			{this.state?.contract_users.filter(obj => obj.user_type === 'tenant').map(tenant => (
																				<>
																					<div className='d-flex align-items-center '>
																						<Checkbox name={`check_tenant_${tenant.user_id}`} color="primary" checked={values[`check_tenant_${tenant.user_id}`]} onBlur={handleBlur} onChange={handleChange} />
																						{tenant?.user?.name}
																					</div>
																				</>
																			))}
																		</div>

																		{this.state?.contract_users.filter(obj => obj.user_type === 'tenant').map(tenant => {
																			if (values[`check_tenant_${tenant.user_id}`]) {
																				return (
																					<>
																						<div style={{ marginTop: '18px' }} >
																							<h5>{tenant?.user?.name}</h5>
																						</div>

																						<div style={{ marginTop: '18px' }} className='d-flex justify-content-between'>
																							<div style={{ width: '49%' }}>
																								<p className='input-label'>Nome completo</p>
																								<TextField name={`tenant_${tenant.user_id}_name`} placeholder={'Nome completo'} className={'input mb-2'} type="text" variant="outlined" value={values[`tenant_${tenant.user_id}_name`]} style={{ background: '#FFF' }}
																									onBlur={handleBlur} onChange={handleChange} helperText={touched[`tenant_${tenant.user_id}_name`] && errors[`tenant_${tenant.user_id}_name`]} error={Boolean(touched[`tenant_${tenant.user_id}_name`] && errors[`tenant_${tenant.user_id}_name`])}
																								/>
																							</div>
																							<div style={{ width: '49%' }}>
																								<p className='input-label'>CEP</p>
																								<TextField name={`tenant_${tenant.user_id}_cep`} placeholder={'Código postal'} className={'input mb-2'} type="text" variant="outlined" value={values[`tenant_${tenant.user_id}_cep`]} style={{ background: '#FFF' }}
																									onBlur={handleBlur} onChange={(e) => handleChange(formatCepInput(e))} helperText={touched[`tenant_${tenant.user_id}_cep`] && errors[`tenant_${tenant.user_id}_cep`]} error={Boolean(touched[`tenant_${tenant.user_id}_cep`] && errors[`tenant_${tenant.user_id}_cep`])} inputProps={{ maxLength: 9 }}
																								/>
																							</div>
																						</div>

																						<div style={{ marginTop: '18px' }} className='d-flex justify-content-between'>
																							<div style={{ width: '49%' }}>
																								<p className='input-label'>Estado</p>
																								<select style={{ background: "#FFF", height: "38px" }} name={`tenant_${tenant.user_id}_state`}
																									onChange={handleChange} handleBlur={handleBlur} value={values[`tenant_${tenant.user_id}_state`]} className="custom-select" >
																									{getStates().map(state => (
																										<option key={state} value={state}>{state}</option>
																									))}
																								</select>
																							</div>
																							<div style={{ width: '49%' }}>
																								<p className='input-label'>Cidade</p>
																								<TextField name={`tenant_${tenant.user_id}_city`} placeholder={'Digite sua cidade'} className={'input mb-2'} type="text" variant="outlined" value={values[`tenant_${tenant.user_id}_city`]} style={{ background: '#FFF' }}
																									onBlur={handleBlur} onChange={handleChange} helperText={touched[`tenant_${tenant.user_id}_city`] && errors[`tenant_${tenant.user_id}_city`]} error={Boolean(touched[`tenant_${tenant.user_id}_city`] && errors[`tenant_${tenant.user_id}_city`])}
																								/>
																							</div>
																						</div>

																						<div style={{ marginTop: '18px' }} className='d-flex justify-content-between'>
																							<div style={{ width: '49%' }}>
																								<p className='input-label'>Bairro</p>
																								<TextField name={`tenant_${tenant.user_id}_neighbourhood`} placeholder={'Digite seu bairro'} className={'input mb-2'} type="text" variant="outlined" value={values[`tenant_${tenant.user_id}_neighbourhood`]} style={{ background: '#FFF' }}
																									onBlur={handleBlur} onChange={handleChange} helperText={touched[`tenant_${tenant.user_id}_neighbourhood`] && errors[`tenant_${tenant.user_id}_neighbourhood`]} error={Boolean(touched[`tenant_${tenant.user_id}_neighbourhood`] && errors[`tenant_${tenant.user_id}_neighbourhood`])}
																								/>
																							</div>
																							<div style={{ width: '49%' }}>
																								<p className='input-label'>Endereço</p>
																								<TextField name={`tenant_${tenant.user_id}_street`} placeholder={'Digite seu endereço'} className={'input mb-2'} type="text" variant="outlined" value={values[`tenant_${tenant.user_id}_street`]} style={{ background: '#FFF' }}
																									onBlur={handleBlur} onChange={handleChange} helperText={touched[`tenant_${tenant.user_id}_street`] && errors[`tenant_${tenant.user_id}_street`]} error={Boolean(touched[`tenant_${tenant.user_id}_street`] && errors[`tenant_${tenant.user_id}_street`])}
																								/>
																							</div>
																						</div>

																						<div style={{ marginTop: '18px' }} className='d-flex justify-content-between'>
																							<div style={{ width: '49%' }}>
																								<p className='input-label'>Número</p>
																								<TextField name={`tenant_${tenant.user_id}_number`} placeholder={'Digite o número da residência'} className={'input mb-2'} type="text" variant="outlined" value={values[`tenant_${tenant.user_id}_number`]} style={{ background: '#FFF' }}
																									onBlur={handleBlur} onChange={handleChange} helperText={touched[`tenant_${tenant.user_id}_number`] && errors[`tenant_${tenant.user_id}_number`]} error={Boolean(touched[`tenant_${tenant.user_id}_number`] && errors[`tenant_${tenant.user_id}_number`])}
																								/>
																							</div>
																							<div style={{ width: '49%' }}>
																								<p className='input-label'>Complemento</p>
																								<TextField name={`tenant_${tenant.user_id}_complement`} placeholder={'Complemento'} className={'input mb-2'} type="text" variant="outlined" value={values[`tenant_${tenant.user_id}_complement`]} style={{ background: '#FFF' }}
																									onBlur={handleBlur} onChange={handleChange} helperText={touched[`tenant_${tenant.user_id}_complement`] && errors[`tenant_${tenant.user_id}_complement`]} error={Boolean(touched[`tenant_${tenant.user_id}_complement`] && errors[`tenant_${tenant.user_id}_complement`])}
																								/>
																							</div>
																						</div>
																					</>
																				)
																			}
																		})}

																	</div>
																) : null}

																{this.state?.contract_users?.length > 0 && values.check_announcement_data ? (
																	<div style={{ marginTop: '22px' }}>
																		<div>
																			<h5>Dados do imóvel</h5>
																		</div>

																		<div style={{ marginTop: '18px' }} className='d-flex justify-content-between'>
																			<div style={{ width: '49%' }}>
																				<p className='input-label'>CEP</p>
																				<TextField name="announcement_cep" placeholder={'Código postal'} className={'input mb-2'} type="text" variant="outlined" value={values.announcement_cep} style={{ background: '#FFF' }}
																					onBlur={handleBlur} onChange={(e) => handleChange(formatCepInput(e))} helperText={touched.announcement_cep && errors.announcement_cep} error={Boolean(touched.announcement_cep && errors.announcement_cep)} inputProps={{ maxLength: 9 }}
																				/>
																			</div>
																			<div style={{ width: '49%' }}>
																				<p className='input-label'>Estado</p>
																				<select style={{ background: "#FFF", height: "38px" }} name='announcement_state'
																					onChange={handleChange} handleBlur={handleBlur} value={values.announcement_state} className="custom-select" >
																					{getStates().map(state => (
																						<option key={state} value={state}>{state}</option>
																					))}
																				</select>
																			</div>
																		</div>

																		<div style={{ marginTop: '18px' }} className='d-flex justify-content-between'>

																			<div style={{ width: '49%' }}>
																				<p className='input-label'>Cidade</p>
																				<TextField name="announcement_city" placeholder={'Cidade'} className={'input mb-2'} type="text" variant="outlined" value={values.announcement_city} style={{ background: '#FFF' }}
																					onBlur={handleBlur} onChange={handleChange} helperText={touched.announcement_city && errors.announcement_city} error={Boolean(touched.announcement_city && errors.announcement_city)}
																				/>
																			</div>
																			<div style={{ width: '49%' }}>
																				<p className='input-label'>Bairro</p>
																				<TextField name="announcement_neighbourhood" placeholder={'Bairro'} className={'input mb-2'} type="text" variant="outlined" value={values.announcement_neighbourhood} style={{ background: '#FFF' }}
																					onBlur={handleBlur} onChange={handleChange} helperText={touched.announcement_neighbourhood && errors.announcement_neighbourhood} error={Boolean(touched.announcement_neighbourhood && errors.announcement_neighbourhood)}
																				/>
																			</div>
																		</div>

																		<div style={{ marginTop: '18px' }} className='d-flex justify-content-between'>
																			<div style={{ width: '49%' }}>
																				<p className='input-label'>Endereço</p>
																				<TextField name="announcement_street" placeholder={'Rua'} className={'input mb-2'} type="text" variant="outlined" value={values.announcement_street} style={{ background: '#FFF' }}
																					onBlur={handleBlur} onChange={handleChange} helperText={touched.announcement_street && errors.announcement_street} error={Boolean(touched.announcement_street && errors.announcement_street)}
																				/>
																			</div>
																			<div style={{ width: '49%' }}>
																				<p className='input-label'>Número</p>
																				<TextField name="announcement_number" placeholder={'Número'} className={'input mb-2'} type="text" variant="outlined" value={values.announcement_number} style={{ background: '#FFF' }}
																					onBlur={handleBlur} onChange={handleChange} helperText={touched.announcement_number && errors.announcement_number} error={Boolean(touched.announcement_number && errors.announcement_number)}
																				/>
																			</div>
																		</div>

																		<div style={{ marginTop: '18px' }} className='d-flex justify-content-between'>
																			<div style={{ width: '49%' }}>
																				<p className='input-label'>Complemento</p>
																				<TextField name="announcement_complement" placeholder={'Complemento'} className={'input mb-2'} type="text" variant="outlined" value={values.announcement_complement} style={{ background: '#FFF' }}
																					onBlur={handleBlur} onChange={handleChange} helperText={touched.announcement_complement && errors.announcement_complement} error={Boolean(touched.announcement_complement && errors.announcement_complement)}
																				/>
																			</div>
																		</div>
																	</div>
																) : null}
															</>
														) : null}

														{values.type === 'PAYMENT' ? (
															<>
																<div className=''>
																	<h1>Edição contratual de pagamento</h1>
																</div>
																<div>
																	<h6 style={{ fontWeight: '400' }}>
																		Se é necessário editar apenas um pagamento mensal, não siga esse fluxo. Edite apenas o pagamento que precisa de edição.
																	</h6>
																</div>

																<div style={{ marginTop: '22px' }}>
																	<p className='input-label'>Selecione as edições necessárias</p>

																	<div className='d-flex align-items-center '>
																		<Checkbox name="check_base_value" color="primary" checked={values.check_base_value} onBlur={handleBlur} onChange={(e) => {
																			handleChange(e);
																			console.log(e);
																			if (e.target.checked === false) {
																				setFieldValue('new_base_value', formatBRL(this.state.contract?.details?.values?.rent))
																			}
																		}} />
																		Valor base
																	</div>

																	<div className='d-flex align-items-center '>
																		<Checkbox name="check_next_value" color="primary" checked={values.check_next_value} onBlur={handleBlur} onChange={(e) => {
																			handleChange(e);
																			if (e.target.checked === false) {
																				setFieldValue('next_rent_value', formatBRL(this.state.contract?.details?.values?.rent))
																			}
																		}} />
																		Valor dos próximos aluguéis
																	</div>

																	<div className='d-flex align-items-center '>
																		<Checkbox name="check_due_date" color="primary" checked={values.check_due_date} onBlur={handleBlur} onChange={handleChange} />
																		Data de vencimento
																	</div>
																</div>

																<div style={{ marginTop: '22px' }}>
																	{values.check_base_value ? (
																		<>
																			<p className='input-label'>
																				Novo valor base
																			</p>
																			<TextField name="new_base_value" placeholder={'Adicionar todos os detalhes...'} className={'input mb-2'} type="text" variant="outlined" value={values.new_base_value} style={{ background: '#FFF' }}
																				onBlur={handleBlur} onChange={(e) => {
																					handleChange(formatBRLInput(e))
																				}} helperText={touched.new_base_value && errors.new_base_value} error={Boolean(touched.new_base_value && errors.new_base_value)}
																			/>

																			<p>
																				Valor base do contrato, ao alterar vai influenciar o valor de renovação do contrato.
																			</p>
																		</>
																	) : null}

																	{values.check_next_value ? (
																		<div style={{ marginTop: '22px' }}>
																			<p className='input-label'>
																				Valor dos próximos aluguéis
																			</p>
																			<TextField name="next_rent_value" placeholder={'Adicionar todos os detalhes...'} className={'input mb-2'} type="text" variant="outlined" value={values.next_rent_value} style={{ background: '#FFF' }}
																				onBlur={handleBlur} onChange={(e) => {
																					handleChange(formatBRLInput(e))
																				}} helperText={touched.next_rent_value && errors.next_rent_value} error={Boolean(touched.next_rent_value && errors.next_rent_value)}
																			/>

																			<p>
																				Altera todos os próximos pagamentos do contrato com o status aguardando. Pagamentos com outros status não serão alterados.
																			</p>
																		</div>
																	) : null}

																	{values.check_due_date ? (
																		<div style={{ marginTop: '22px' }}>
																			<p className='input-label'>
																				Nova data de vencimento
																			</p>
																			<TextField name="new_due_date" placeholder={'00/00/0000'} className={'input mb-2'} type="date" variant="outlined" value={values.new_due_date} style={{ background: '#FFF' }}
																				onBlur={handleBlur} onChange={(e) => {
																					console.log(e);
																					handleChange(e);
																					this.calcProportionalValue(e);
																					if (moment(values.signExpirationDate).add(5, 'days').isAfter(moment(e.target.value))) {
																						setFieldValue('signExpirationDate', '')
																					}
																				}} inputProps={{
																					min: formatDate({ date: moment().add(30, 'days'), format: 'YYYY-MM-DD' })
																				}} helperText={touched.new_due_date && errors.new_due_date} error={Boolean(touched.new_due_date && errors.new_due_date)}
																			/>

																			<div className='d-flex align-items-center'>
																				<p className='input-label'> Próximo pagamento: </p><p>
																					{this.state.nextPayment}
																				</p>
																			</div>

																			<div className='d-flex align-items-center'>
																				<p className='input-label'> Valor do próximo pagamento: </p><p>
																					{formatBRL(this.state?.contract?.details?.values?.rent + this.state?.proporcional_value)}
																				</p>
																			</div>

																			<p>Altera a data de vencimento a partir do pagamento exibido acima. O próximo pagamento terá um edição proporcional a data escolhida.</p>
																		</div>
																	) : null}
																</div>
															</>
														) : null}
													</CardBody>
												) : null}

												{/* {this.state?.currentStep === 2 ? (
													<CardBody className="column contractual-edit">
														<div className=''>
															<h1>Edição contratual</h1>
														</div>
														<div>
															<h5 style={{ color: '#8590A5', marginTop: '30px' }}>
																Testemunhas
															</h5>
														</div>
														<div>
															<h6 style={{ fontWeight: '400', fontFamily: 'Lato' }}>
																O aditivo de contrato precisa ser assinada por duas testemunhas que não fazem parte do contrato.
															</h6>
														</div>

														<hr />

														<div>
															<h5 style={{ color: '#8590A5' }}>
																Primeira testemunha
															</h5>
														</div>

														<div style={{ marginTop: '22px' }}>
															<p className='input-label'>Nome completo</p>

															<TextField className="mb-2" style={{ background: "#FFF", height: "40px" }} name={'witness_1_name'} placeholder='Nome completo'
																type="text" variant="outlined" value={values.witness_1_name} InputLabelProps={{ shrink: true }}
																onChange={async e => { handleChange(e); }} onBlur={handleBlur} />
														</div>

														<div style={{ marginTop: '22px' }}>
															<p className='input-label'>Email</p>

															<TextField className="mb-2" style={{ background: "#FFF", height: "40px" }} name={'witness_1_email'} placeholder='email@email.com'
																type="text" variant="outlined" value={values.witness_1_email} InputLabelProps={{ shrink: true }}
																onChange={async e => { handleChange(e); }} onBlur={handleBlur} />
														</div>

														<div style={{ marginTop: '22px' }}>
															<p className='input-label'>Telefone</p>

															<TextField className="mb-2" style={{ background: "#FFF", height: "40px" }} name={'witness_1_phone'} placeholder='(42) 99999-9999'
																type="text" variant="outlined" value={values.witness_1_phone} InputLabelProps={{ shrink: true }}
																onChange={async e => { handleChange(formatTelefoneInput(e)); }} onBlur={handleBlur} inputProps={{ maxLength: 15 }} />
														</div>

														<div style={{ marginTop: '22px' }}>
															<p className='input-label'>CPF</p>

															<TextField className="mb-2" style={{ background: "#FFF", height: "40px" }} name={'witness_1_cpf'} placeholder='000.000.000-00'
																type="text" variant="outlined" value={values.witness_1_cpf} InputLabelProps={{ shrink: true }}
																onChange={async e => { handleChange(formatCPFInput(e)); }} onBlur={handleBlur} inputProps={{ maxLength: 14 }} />
														</div>

														<hr />

														<div>
															<h5 style={{ color: '#8590A5' }}>
																Segunda testemunha
															</h5>
														</div>

														<div style={{ marginTop: '22px' }}>
															<p className='input-label'>Nome completo</p>

															<TextField className="mb-2" style={{ background: "#FFF", height: "40px" }} name={'witness_2_name'} placeholder='Nome completo'
																type="text" variant="outlined" value={values.witness_2_name} InputLabelProps={{ shrink: true }}
																onChange={async e => { handleChange(e); }} onBlur={handleBlur} />
														</div>

														<div style={{ marginTop: '22px' }}>
															<p className='input-label'>Email</p>

															<TextField className="mb-2" style={{ background: "#FFF", height: "40px" }} name={'witness_2_email'} placeholder='email@email.com'
																type="text" variant="outlined" value={values.witness_2_email} InputLabelProps={{ shrink: true }}
																onChange={async e => { handleChange(e); }} onBlur={handleBlur} />
														</div>

														<div style={{ marginTop: '22px' }}>
															<p className='input-label'>Telefone</p>

															<TextField className="mb-2" style={{ background: "#FFF", height: "40px" }} name={'witness_2_phone'} placeholder='(42) 99999-9999'
																type="text" variant="outlined" value={values.witness_2_phone} InputLabelProps={{ shrink: true }}
																onChange={async e => { handleChange(formatTelefoneInput(e)); }} onBlur={handleBlur} inputProps={{ maxLength: 15 }} />
														</div>

														<div style={{ marginTop: '22px' }}>
															<p className='input-label'>CPF</p>

															<TextField className="mb-2" style={{ background: "#FFF", height: "40px" }} name={'witness_2_cpf'} placeholder='000.000.000-00'
																type="text" variant="outlined" value={values.witness_2_cpf} InputLabelProps={{ shrink: true }}
																onChange={async e => { handleChange(formatCPFInput(e)); }} onBlur={handleBlur} inputProps={{ maxLength: 14 }} />
														</div>

													</CardBody>
												) : null} */}

												{this.state?.currentStep === 3 ? (
													<CardBody className="column contractual-edit">
														<div style={{ marginBottom: '14px' }}>
															<h1 style={{ color: '#505D69' }}>Edição contratual</h1>
														</div>

														<WarningContainer title={'Leia com atenção'} titleColor={'#FCB92C'} styleContainer={{ backgroundColor: '#FEF2D9', border: '1px solid #FCB92C', marginBottom: 24 }}>
															Ao continuar, será gerado um documento de termo aditivo de contrato. Todas as partes e testemunhas serão notificados e precisam assinar o documento.
															<br /><br />
															Será possível acompanhar o andamento das assinaturas.
															<br /><br />
															Se uma das partes ou testemunha não assinar até a data máxima, o termo aditivo é cancelado automaticamente.
														</WarningContainer>

														<div>
															<h5 style={{ color: '#8590A5' }}>
																Revise as informações
															</h5>
														</div>

														<div>
															<p>Caso seja necessário, volte para editar uma das informações.</p>
														</div>

														{values.type === 'PAYMENT' ? (
															<div style={{ display: 'flex', marginTop: '22px', width: '100%' }}>
																<div className='box-blue'>
																	<img src={cashIcon} alt='icon' />
																</div>
																<div>
																	<p className='input-label' style={{ marginBottom: '12px' }}>Pagamentos</p>

																	{values.check_base_value ? (
																		<div style={{ display: 'flex' }}>
																			<h6 style={{ marginBottom: '0px', color: '#505D69' }}>Valor base:</h6>
																			<p className='input-label' style={{ marginLeft: '8px', marginTop: '0px' }}>{values.new_base_value}</p>
																		</div>
																	) : null}

																	{values.check_next_value ? (
																		<div style={{ display: 'flex', color: '#505D69' }}>
																			<h6 style={{ marginBottom: '0px', color: '#505D69' }}>Valor dos próximos aluguéis:</h6>
																			<p className='input-label' style={{ marginLeft: '8px', marginTop: '0px' }}>{values.next_rent_value}</p>
																		</div>
																	) : null}

																	{values.check_due_date ? (
																		<div style={{ display: 'flex' }}>
																			<h6 style={{ marginBottom: '0px', color: '#505D69' }}>Data de vencimento:</h6>
																			<p className='input-label' style={{ marginLeft: '8px', marginTop: '0px' }}>{moment(values.new_due_date).format('DD/MM/YYYY')}</p>
																		</div>
																	) : null}
																</div>
															</div>
														) : null}

														{values.type === 'CONTRACT_DATA' ? (
															<div style={{ display: 'flex', marginTop: '22px', width: '100%' }}>
																<div className='box-blue'>
																	<img src={listIcon} alt='icon' />
																</div>
																<div style={{ width: '90%' }}>
																	<p className='input-label' style={{ marginBottom: '12px', color: '#505D69' }}>Dados contratuais</p>

																	{values.check_landlord_data ? (
																		<div style={{ display: 'flex' }}>
																			<h6 style={{ marginBottom: '0px', color: '#505D69' }}>Dados do responsável:	<small>{this.getLandlordChages(values)}</small></h6>

																		</div>
																	) : null}

																	{values.check_tenant_data && this.state.contract_users.filter(obj => obj.user_type === 'tenant').map((tenant, i) => (
																		<div style={{ display: 'flex' }} key={i}>
																			<h6 style={{ marginBottom: '0px', color: '#505D69' }}>Dados do inquilino: <small>{this.getTenantChages(tenant, values)}</small></h6>

																		</div>
																	))}

																	{values.check_announcement_data ? (
																		<div style={{ display: 'flex' }}>
																			<h6 style={{ marginBottom: '0px', color: '#505D69' }}>Dados do imóvel: <small>{this.getAnnouncementChages(values)}</small></h6>

																		</div>
																	) : null}
																</div>
															</div>
														) : null}

														{/* <div style={{ display: 'flex', marginTop: '22px', width: '100%' }}>
															<div className='box-blue'>
																<img src={groupIcon} alt='icon' />
															</div>
															<div>
																<p className='input-label' style={{ marginBottom: '12px' }}>Testemunha 1</p>

																<div style={{ display: 'flex' }}>
																	<h6 style={{ marginBottom: '0px', color: '#505D69' }}>{values.witness_1_name}</h6>
																</div>


																<div style={{ display: 'flex' }}>
																	<h6 style={{ marginBottom: '0px', color: '#505D69' }}>{values.witness_1_email}</h6>

																</div>


																<div style={{ display: 'flex' }}>
																	<h6 style={{ marginBottom: '0px', color: '#505D69' }}>{values.witness_1_phone}</h6>
																</div>

																<div style={{ display: 'flex' }}>
																	<h6 style={{ marginBottom: '0px', color: '#505D69' }}>{values.witness_1_cpf}</h6>
																</div>
															</div>
														</div>

														<div style={{ display: 'flex', marginTop: '22px', width: '100%' }}>
															<div className='box-blue'>
																<img src={groupIcon} alt='icon' />
															</div>
															<div>
																<p className='input-label' style={{ marginBottom: '12px' }}>Testemunha 2</p>

																<div style={{ display: 'flex' }}>
																	<h6 style={{ marginBottom: '0px', color: '#505D69' }}>{values.witness_2_name}</h6>
																</div>


																<div style={{ display: 'flex' }}>
																	<h6 style={{ marginBottom: '0px', color: '#505D69' }}>{values.witness_2_email}</h6>

																</div>


																<div style={{ display: 'flex' }}>
																	<h6 style={{ marginBottom: '0px', color: '#505D69' }}>{values.witness_2_phone}</h6>
																</div>

																<div style={{ display: 'flex' }}>
																	<h6 style={{ marginBottom: '0px', color: '#505D69' }}>{values.witness_2_cpf}</h6>
																</div>
															</div>
														</div> */}

														<hr />

														<div>
															<p className='input-label'>Tem certeza que deseja editar este contrato?</p>

															<div className='d-flex align-items-center '>
																<Checkbox name="check_final" color="primary" checked={values.check_final} onBlur={handleBlur} onChange={handleChange} />
																Confirmo a criação do termo aditivo. Entendo que precisa ser assinado por todas as partes e duas testemunhas.
															</div>
														</div>
														<div>

														</div>
													</CardBody>
												) : null}

												<CardBody className="row">
													<>
														<Button className="btn ml-3" style={{ backgroundColor: '#8590A5', border: 'none' }} type='button' onClick={() => {
															this.state.currentStep === 1 ? window.location.href = `/negotiation/show/${this.state?.contract.proposition_id}` : this.setState({
																currentStep: this.state.currentStep - 1
															})
														}}>
															<i className="fa fa-arrow-left mr-2"></i>Voltar
														</Button>

														<Button disabled={this.disabledConditions(values || this.state.loading)} title="Preencha os campos para continuar" alt="Preencha os campos para continuar" className="btn  ml-3" style={{ backgroundColor: this.disabledConditions(values) ? '#DADBDD' : '#1CBB8C', border: this.disabledConditions(values) ? '#DADBDD' : '#1CBB8C', display: 'flex', alignItems: 'center' }} type='submit'>
															{
																this.state.currentStep === 1 ? (
																	<>Próximo passo <FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: '8.5px' }} /></>
																) : null
															}

															{
																this.state.currentStep === 2 ? (
																	<>Revisar <FontAwesomeIcon icon={faFileCheck} style={{ marginLeft: '8.5px' }} /></>
																) : null
															}

															{
																this.state.currentStep === 3 ? (
																	<>Enviar aditivo <FontAwesomeIcon icon={faEnvelope} style={{ marginLeft: '8.5px', fontSize: '16px' }} /></>
																) : null
															}
														</Button>

													</>
												</CardBody>
											</Card>
										</form>
									)}
								</Formik>
							) : null}
						</Container>
					</div>
				</BetterCircularProgress>
			</React.Fragment >
		)
	}
}

export default FriendlyAgreement;