import React, { Component } from 'react';
import heic2any from 'heic2any';

class ImageComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			convertedImages: []
		};
	}

	componentDidMount() {
		this.convertImages();
	}

	async convertImages() {
		const { obj } = this.props;

		const converted = await Promise.all(
			obj.medias.map(async (img) => {
				try {
					if (img?.Location.endsWith('.heif') || img?.Location.endsWith('.heic')) {
						const response = await fetch(img?.Location);
						const blob = await response.blob();
						const convertedBlob = await heic2any({ blob });
						const url = URL.createObjectURL(convertedBlob);
						return url;
					} else {
						return img?.Location; // Se não for .heic, use a URL original
					}
				} catch (error) {
					console.error('Erro ao converter imagem HEIC:', error);
					return img?.Location; // Fallback para a URL original em caso de erro
				}
			})
		);

		this.setState({ convertedImages: converted });
	}

	render() {
		const { obj } = this.props;
		const { convertedImages } = this.state;

		return (
			<div style={{ padding: '16px' }}>
				<p>{obj?.name}, {obj?.room}</p>
				<div style={{ display: 'flex', flexWrap: 'wrap', width: '264px', gap: '8px' }}>
					{convertedImages.map((imgUrl, index) => (
						<img key={index} src={imgUrl} alt='inspectionImage' style={{ width: '72px', height: '72px', objectFit: 'cover' }}
						/>
					))}
				</div>
			</div>
		);
	}
}

export default ImageComponent;