import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
import { Button, Nav, NavItem, NavLink, TabContent, Col, FormGroup, TabPane } from "reactstrap";
import { TextField, InputAdornment } from '@material-ui/core';
import BetterAlerts from '../BetterAlerts';
import { updateIndice } from '../../crud/billings.crud';
import classnames from 'classnames';
import debounce from 'lodash.debounce';

export default class DataTableCobranca extends Component {
	constructor() {
		super();

		this.state = {
			property: {},
			indicesByYears: {},
			orderedIndices: [],
			data: null,
			rows: 12,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: 'created_at',
			sortOrder: 1,
			igp_m_sum: 0,
			ipca_sum: 0,
			editing: false,
			newIndices: [],
			success: [],
			errors: [],
			old_igpm_sum: null,
			old_ipca_sum: null,
			activeTab: '0'
		};

		this.debouncedSaveIndice = debounce(this.saveIndice, 2000);
	}

	componentDidMount() {
		this.fetchData();
	}

	formatPercentage = (number, editable = true, indiceId, indice) => {
		const index = this.state?.data?.findIndex(objeto => objeto?.id === indiceId);

		const updateField = (field, value) => {
			const newValue = value === '' ? '' : value;
			const updatedIndices = {
				...this.state.newIndices,
				[indiceId]: {
					...this.state.newIndices[indiceId],
					[field]: newValue
				}
			};
			this.setState({ newIndices: updatedIndices });
		};

		return (
			<FormGroup row>
				<Col md={8} className="container-center-input">
					<TextField name={indice} type="text" variant="outlined" onChange={(e) => updateField(indice, e.target.value)}
						value={this.state.newIndices[indiceId]?.[indice] !== undefined ? this.state.newIndices[indiceId][indice] : this.state.data[index]?.[indice] || ''}
						InputProps={{ endAdornment: <InputAdornment position='end'>%</InputAdornment> }}
					/>
				</Col>
			</FormGroup>
		);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters !== this.props.filters) {
			this.fetchData();
		}
	}

	refreshTable = () => {
		this.onPage(this.state);
	}

	onPage = async (event) => {
		this.setState({ loading: true });

		const { rows, first } = event;

		this.setState({ rows, first });

		this.fetchData(rows, first);
	};

	fetchData = async (limit, skip) => {
		this.setState({ loading: true });

		const options = { $limit: 48, $skip: 0, $sort: { [this.state.sortField]: this.state.sortOrder } };

		try {
			const res = await this.props.fetchEvent(options);
			const { data } = res.data;

			if (!data.length) {
				this.setState({ data: [], totalRecords: res.data.total, loading: false });
				return;
			}

			const igp_m_sum = data.reduce((sum, obj) => sum + obj.igp_m, 0);
			const ipca_sum = data.reduce((sum, obj) => sum + obj.ipca, 0);

			const responses = data.reduce((acc, indice) => {
				const year = moment(indice.created_at).format('YYYY');
				acc[year] = acc[year] || [];
				acc[year].push(indice);
				return acc;
			}, {});

			const indicesByYears = Object.keys(responses).reduce((acc, year) => {
				acc[year] = responses[year];
				return acc;
			}, {});

			const orderedIndices = Object.keys(indicesByYears).sort((a, b) => b - a);

			this.setState({
				data,
				totalRecords: res.data.total,
				first: skip,
				last: skip + res.data.limit,
				indicesByYears,
				orderedIndices,
				igp_m_sum,
				ipca_sum
			});

		} finally {
			this.setState({ loading: false });
		}
	};

	saveIndice = async () => {
		const { newIndices, data } = this.state;

		const updatePromises = Object.keys(newIndices).map(async (id) => {
			const updatedIndice = newIndices[id];
			const lastIndice = data.find((lastIndiceValues) => lastIndiceValues.id === id)

			const updatedData = {
				igp_m: parseFloat(updatedIndice.igp_m?.toString()?.replace(',', '.')) || lastIndice.igp_m,
				ipca: parseFloat(updatedIndice.ipca?.toString()?.replace(',', '.')) || lastIndice.ipca
			};

			return updateIndice(id, updatedData).then((res) => {
				if (res?.status === 200) {
					this.setState((prevState) => {
						const updatedDataArray = prevState.data.map((val) => (
							val.id === parseInt(id, 10) ? { ...val, ...updatedData } : val
						));

						return {
							data: updatedDataArray,
							success: [...prevState.success, `Índice do mês de ${moment(lastIndice.created_at).format('MMMM [de] YYYY')} atualizado com sucesso!`],
							newIndices: {}
						};
					});
				} else {
					throw new Error();
				}
			}).catch((err) => {
				this.setState({ errors: ['Ocorreu um erro, entre em contato com o suporte.'] });
			}).finally(() => {
				this.refreshTable();
			});
		});

		await Promise.all(updatePromises);
	}

	cancelOperation = (obj) => {
		const newIndices = this.state.newIndices.filter(o => o.id !== obj.id);
		this.setState({ newIndices, old_igpm_sum: null, old_ipca_sum: null });
	}

	toggleTab = (tab) => {
		if (this.state.activeTab !== tab) this.setState({ activeTab: tab }, this.refreshTable);
	};

	sortTable = (e, keys) => {
		const { sortField, sortOrder } = e;

		const sortedData = [...this.state.indicesByYears[keys]].sort((a, b) => {
			if (a[sortField] < b[sortField]) return -1;
			if (a[sortField] > b[sortField]) return -1;
			return 0;
		});

		this.setState({ sortField, sortOrder, indicesByYears: { ...this.state.indicesByYears, [keys]: sortedData } });
	};

	render() {
		return (
			<>
				<Nav tabs className="nav-tabs-custom" role="tablist" style={{ marginBottom: '32px' }}>
					{this.state.orderedIndices
						.map((keys, indice) =>
							<NavItem>
								<NavLink id="info-tab" className={classnames({ active: this.state.activeTab === indice.toString() })} onClick={() => this.toggleTab(indice.toString())}>
									{keys}
								</NavLink>
							</NavItem>
						)}
				</Nav>

				<BetterAlerts success={this.state.success} errors={this.state.errors} />

				<TabContent activeTab={this.state.activeTab}>
					{this.state.orderedIndices
						.map((keys, indice) =>
							<TabPane tabId={indice.toString()} role="tabpanel">
								<DataTable
									ref={(el) => this.dt = el}
									value={this?.state?.indicesByYears?.[keys]}
									rows={12}
									first={this.state.first}
									last={this.state.last}
									lazy={true}
									totalRecords={this.state.totalRecords}
									onPage={this.onPage}
									loading={this.state.loading}
									responsive={true}
									sortOrder={this.state.sortOrder}
									loadingIcon={`fas fa-sync fa-spin`}
									onSort={(e) => this.sortTable(e, keys)}
									emptyMessage="Nenhum registro encontrado!"
									sortField={this.state.sortField}
								>
									<Column field="created_at" sortable body={(obj) => moment(obj.created_at).format('MMMM')} header="Mês" />
									<Column field="igp_m" sortable body={(obj) => this.formatPercentage(obj.igp_m, true, obj.id, 'igp_m')} header="IGP-M acumulado dos 12 meses" />
									<Column field="ipca" sortable body={(obj) => this.formatPercentage(obj.ipca, true, obj.id, 'ipca')} header="IPCA acumulado dos 12 meses" />
								</DataTable>
							</TabPane>
						)}
				</TabContent>
			</>
		);
	}
}
