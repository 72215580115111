import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
import { formatTelefone } from "../../../helpers/Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/pro-solid-svg-icons";
import { faLink } from "@fortawesome/pro-regular-svg-icons";
import Swal from "sweetalert2";
import { get as getUser, getByFilter } from '../../../crud/users.crud';
import { Link } from "react-router-dom";

export default class DataTableUsersProposition extends Component {
	constructor() {
		super();

		this.state = {
			data: [],
			loading: false,
			sortField: "",
			sortOrder: 1,
			usuarios: [],
		};
	}

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters !== this.props.filters) this.fetchData();
	}

	fetchData = async () => {
		this.setState({ loading: true });

		let usuarios = []

		if (this?.props?.state) {
			const contract_users = this?.props?.state?.checkedContract?.friendly_agreement_users || this?.props?.state?.contract_users;

			for (const usuario of contract_users) {
				await getUser(usuario.user_id).catch(err => { }).then(res => {
					if (res?.data?.data) {
						if (this.props.contratoSelecionado?.type == 'Procuração') {
							if (usuario.user_id == this?.props?.state.data.contract.landlord_id) usuarios.push({ ...usuario, ...res.data.data })
						} else {
							usuarios.push({ ...usuario, ...res.data.data })
						}
					}
				}).finally(() => {
					this.setState({ loading: false });
				});
			}

			this.setState({ usuarios: usuarios })
		} else {
			return this.props.fetchEvent().catch((e) => { }).then(async (res) => {
				if (res?.data) {
					for (const usuario of res.data) {
						await getByFilter({ email: usuario.email }).catch(err => { }).then(res => {
							if (res?.data?.data?.[0]) {
								usuarios.push({ ...usuario, ...res.data.data[0] })
							}
						});
					}

					this.setState({ usuarios: usuarios });
				}
			}).finally(() => {
				this.setState({ loading: false });
			});
		}
	};

	sortTable = async ({ sortField, sortOrder }) => {
		let usuarios = this.state.usuarios;

		usuarios = usuarios.sort((a, b) => {
			if (a[sortField] < b[sortField]) return -1
			if (a[sortField] > b[sortField]) return -1

			return 0
		});

		this.setState({ sortField: sortField, sortOrder: sortOrder, usuarios });
	};

	getTipoUsuarioTag = (obj) => {
		//atualizar
		let tipoUser = '';
		let backgroundColor = '#5664D2'

		switch (obj?.user_type) {
			case 'landlord':
				backgroundColor = '#1CBB8C';
				tipoUser = 'Proprietário'
				break;
			case 'responsavel':
				backgroundColor = '#5664D2';
				tipoUser = 'Responsável'
				break;
			case 'admin':
				backgroundColor = '#5664D2';
				tipoUser = 'Administrador'
				break;
			case 'commissioned':
				backgroundColor = '#5664D2';
				tipoUser = 'Comissionado'
				break;
			case 'tenant':
				backgroundColor = '#4AA3FF';
				tipoUser = 'Inquilino'
				break;
			default:
				break;
		}
		return (
			<div className="tag" style={{ textAlign: "center", backgroundColor, overflowWrap: 'break-word', display: 'inline' }}>
				{tipoUser}
			</div>
		);
	}

	getProfilePic = (obj) => {
		return (
			<div className="boxUserTableUsers">
				{obj?.profile_image?.medium?.url ?
					<div className='userPhoto' style={{ backgroundImage: `url(${obj?.profile_image?.medium?.url})` }} />
					:
					<div className='userPhotoNone'>
						<FontAwesomeIcon icon={faUser} />
					</div>
				}
			</div>
		)
	}

	getAnaliseTag = (obj) => {
		if (obj?.user_type != 'tenant') {
			return null
		}

		if (obj?.user_type == 'tenant' && (!obj?.parecer_key || obj?.parecer_key == 'UNKNOW')) {
			return (
				<div className="tag" style={{ textAlign: "center", backgroundColor: '#FF3D60', textTransform: 'lowercase', overflowWrap: 'break-word' }}>
					Pendente
				</div>
			);
		}

		if (obj?.parecer_key == 'ALERTA_FRAUDE_CRIMINAL' || obj?.parecer_key == 'ALERTA_FRAUDE') {
			return (
				<div className="tag" style={{ textAlign: "center", backgroundColor: '#FF3D60', textTransform: 'lowercase' }}>
					Alerta de fraude criminal
				</div>
			);
		}

		if (!obj?.parecer_key) return;

		let color = '';
		let text = '';

		if (obj?.parecer_key) {
			switch (obj?.parecer_key) {
				case 'APPROVED':
					text = "Aprovado";
					color = '#1CBB8C';
					break;
				case 'REFUSED':
					text = "Recusada";
					color = '#FF3D60';
					break;
				case 'AWAITING':
					text = "Aguardando";
					color = '#FCB92C';
					break;
				case 'NOT_CONSULTED':
					text = "Não consultado";
					color = '#8590A5';
					break;
				case 'APPROVED_SYSTEM':
					text = "Aprovado sistêmico";
					color = '#4AA3FF';
					break;
				case 'APPROVED_HUMANIZED':
					text = "Aprovado humanizado";
					color = '#5664D2';
					break;
				case 'EXAUSTED_ANALYSIS':
					text = "Consultas esgotadas";
					color = '#EFA200';
					break;
				case 'FRAUD':
					text = "Fraude";
					color = '#852032';
					break;
				case 'PARECER_SUSPEITA_OBITO':
					text = "Suspeita de óbito";
					color = '#FF3D60';
					break;
				case 'PARECER_NAO_APURADO':
					text = "Não apurado";
					color = '#FF3D60';
					break;
				case 'PARECER_NIVEL_1':
					text = "Risco muito baixo";
					color = '#1CBB8C';
					break;
				case 'PARECER_NIVEL_2':
					text = "Risco baixo";
					color = '#1CBB8C';
					break;
				case 'PARECER_NIVEL_3':
					text = "Risco médio";
					color = '#4AA3FF';
					break;
				case 'PARECER_NIVEL_4':
					text = "Risco acima da média";
					color = '#FF3D60';
					break;
				case 'PARECER_NIVEL_5':
					text = "Risco muito alto";
					color = '#FF3D60';
					break;
				case 'ALERTA_FRAUDE':
					text = "Alerta de fraude";
					color = '#FF3D60';
					break;
				case 'ALERTA_FRAUDE_CRIMINAL':
					text = "Alerta de fraude criminal";
					color = '#FF3D60';
					break;
				case 'ALERTA_ACAO_CRIMINAL':
					text = "Alerta de ação criminal";
					color = '#FF3D60';
					break;
				case 'ALERTA_ACAO_CIVIL':
					text = "Alerta de ação civil";
					color = '#FF3D60';
					break;
				default:
					color = '#343A40';
					text = 'Não informado';
					break;
			}
		} else {
			color = '#FCB92C';
			text = 'Aguardando';
		}

		return (
			<div className="tag" style={{ textAlign: "center", backgroundColor: color, textTransform: 'lowercase' }}>
				{text}
			</div>
		);
	}

	getLinkAssinatura = (obj) => {
		const onClick = () => {
			if (['Contrato de locação', 'Procuração'].includes(this.props.contratoSelecionado?.type)) {
				if (process.env.NODE_ENV === 'development' || window.location.host == 'dev-admin.mellro.com') {
					navigator.clipboard.writeText(`https://dev-app.mellro.com/contratos/contrato-previa?id=${this.props.contract_id}`)
				} else {
					navigator.clipboard.writeText(`https://mellro.com/contratos/contrato-previa?id=${this.props.contract_id}`)
				}
			}

			if (this.props.contratoSelecionado?.type == 'Termo de recisão') {
				if (Array.isArray(this.props.contratoSelecionado?.terminationUsers) && this.props.contratoSelecionado?.terminationUsers?.length > 0) {
					let terminationUser = this.props.contratoSelecionado.terminationUsers.find((usuario) => usuario?.user_id == obj.id);
					let witnessUser = this.props.contratoSelecionado.terminationUsers.find((usuario) => usuario.user_type == 'witness');

					if (terminationUser) {
						navigator.clipboard.writeText(terminationUser.link);
					} else {
						navigator.clipboard.writeText(witnessUser.link);
					}
				}
			}

			if (this.props.contratoSelecionado?.type == 'Edição contratual') {
				let edicaoContratualUser = this.props.contratoSelecionado.contractual_edit_users.find((usuario) => usuario?.user_id == obj.id);

				navigator.clipboard.writeText(edicaoContratualUser.link);
			}

			if (this.props.contratoSelecionado?.type == 'Confissão de dívida') {
				let friendlyAgreementUser = this.props.contratoSelecionado.friendly_agreement_users.find((usuario) => usuario?.user_id == obj.id);

				navigator.clipboard.writeText(friendlyAgreementUser.link);
			}

			Swal.fire('Ok!', 'Link copiado para área de transferência.', 'success');
		}

		return (
			<b className='inBlue' onClick={onClick}>
				Copiar
				<FontAwesomeIcon icon={faLink} style={{ marginLeft: 8.5 }} />
			</b>
		)
	}

	getSignedDate = (obj) => {
		let date = null;

		if (['Contrato de locação', 'Procuração', 'Confissão de dívida'].includes(this.props.contratoSelecionado?.type)) {
			date = obj?.signed_date;
		}

		if (this.props.contratoSelecionado?.type == 'Termo de recisão') {
			if (this.props?.contratoSelecionado?.terminationUsers?.length > 0) {
				let terminationUser = this.props.contratoSelecionado.terminationUsers.find((usuario) => usuario?.user_id == obj.id);

				if (terminationUser) date = terminationUser?.signed_date
			}
		}

		if (this.props.contratoSelecionado?.type == 'Edição contratual') {
			let edicaoContratualUser = this.props.contratoSelecionado.contractual_edit_users.find((usuario) => usuario?.user_id == obj.id);

			if (edicaoContratualUser) date = edicaoContratualUser?.signed_date
		}

		if (this.props.contratoSelecionado?.type == 'Confissão de dívida') {
			let friendlyAgreementUser = this.props.contratoSelecionado.friendly_agreement_users.find((usuario) => usuario?.user_id == obj.id);

			if (friendlyAgreementUser) date = friendlyAgreementUser?.signed_date
		}

		return date == null ? '--' : moment(date).format('DD/MM/YYYY HH:mm:ss')
	}

	getUserName = (obj) => {
		return (
			<div>
				<Link to={`/users/show/${obj?.id}`}>{obj?.name}</Link>
			</div>
		)

	};

	getVerified = (verified) => {
		if (verified) {
			return (
				<p style={{ marginBottom: 0 }}>Sim</p>
			)
		} else {
			return (
				<p style={{ marginBottom: 0, color: '#FF3D60', fontWeight: 600 }}>Não</p>
			)
		}
	}

	render() {
		const usuariosProposition = [
			() => <Column style={{ overflowWrap: 'break-word' }} sortable field="user_type" body={(obj, i) => this.getTipoUsuarioTag(obj)} header="Tipo" />,
			() => <Column style={{ overflowWrap: 'break-word' }} field="photo" header="" body={(obj) => this.getProfilePic(obj)} />,
			() => <Column style={{ overflowWrap: 'break-word' }} sortable field="name" body={(obj) => this.getUserName(obj)} header="Nome" />,
			() => <Column style={{ overflowWrap: 'break-word' }} sortable field="email" header="Email" />,
			() => <Column style={{ overflowWrap: 'break-word' }} sortable field="verified" body={({ verified }) => this.getVerified(verified)} header="Perfil verificado" />,
			() => <Column style={{ width: 'auto' }} sortable field="phone" body={({ phone }) => formatTelefone(phone ?? '0')} header="Telefone" />,
			() => <Column style={{ overflowWrap: 'break-word' }} sortable field="analise" body={(obj) => this.getAnaliseTag(obj)} header="Análise" />,
		]

		const usuariosSignature = [
			() => <Column style={{ width: 143 }} sortable field="user_type" body={(obj, i) => this.getTipoUsuarioTag(obj)} header="Tipo" />,
			() => <Column style={{ width: 80 }} field="photo" header="" body={(obj) => this.getProfilePic(obj)} />,
			() => <Column style={{ width: 327 }} sortable field="name" body={(obj) => this.getUserName(obj)} header="Nome" />,
			() => <Column style={{ width: 169 }} sortable field="created_at" body={(obj, i) => moment(this.props?.contratoSelecionado?.created_at).format('DD/MM/YYYY HH:mm:ss')} header="Enviado em" />,
			() => <Column style={{ width: 169, color: '#1CBB8C' }} sortable field="signed_date" body={(obj, i) => this.getSignedDate(obj)} header="Assinado em" />,
			() => <Column style={{ width: 136 }} sortable field="link" body={(obj) => this.getLinkAssinatura(obj)} header="Link assinatura" />,
		]

		const usuariosModal = [
			() => <Column style={{ width: 143 }} sortable field="user_type" body={(obj, i) => this.getTipoUsuarioTag(obj)} header="Tipo" />,
			() => <Column style={{ width: 327 }} sortable field="name" body={(obj) => this.getUserName(obj)} header="Nome" />,
			() => <Column style={{ width: 169 }} sortable field="created_at" body={(obj, i) => moment(this.props?.state?.data?.contract?.created_at).format('DD/MM/YYYY HH:mm:ss')} header="Enviado em" />,
		]

		return (
			<DataTable ref={(el) => this.dt = el} value={this.state.usuarios} responsive={true} lazy={true} sortField={this.state.sortField} sortOrder={this.state.sortOrder}
				loading={this.state.loading} loadingIcon={`fas fa-sync fa-spin`} id="datatable-users-proposition" emptyMessage="Nenhum registro encontrado!" onSort={(e) => this.sortTable(e)}>
				{this.props.usuariosProposition && (
					usuariosProposition.map((coluna) => coluna())
				)}

				{this.props.usuariosSignature && (
					usuariosSignature.map((coluna) => coluna())
				)}

				{this.props.modal && (
					usuariosModal.map((coluna) => coluna())
				)}
			</DataTable>
		);
	}
}
