import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Link } from "react-router-dom";
import { Column } from 'primereact/column';
import _ from 'lodash';
import { get as getUser } from '../../crud/users.crud';
import { CircularProgress } from "@material-ui/core";
import { formatBRL } from '../../helpers/Utils';
import { get } from "../../crud/plans.crud";
import moment from 'moment';
import { Button } from 'primereact/button';
import { listContractsWithUsersData } from '../../crud/contracts.crud'
import { query as queryTermination } from '../../crud/termination.crud';

export default class DataTableContracts extends Component {
	constructor() {
		super();

		this.state = {
			data: [],
			rows: 10,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: 'id',
			sortOrder: '1',
			fetchingCsvData: false,
			csvData: [],
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		let contractsDataTable = JSON.parse(localStorage.getItem('contractsDataTable'));

		if (contractsDataTable) {
			this.setState({
				rows: contractsDataTable.rows,
				totalRecords: contractsDataTable.totalRecords,
				first: contractsDataTable.first,
				last: contractsDataTable.last,
				loading: contractsDataTable.loading,
				sortField: contractsDataTable.sortField,
				sortOrder: contractsDataTable.sortOrder,
			}, () => {
				this.fetchData();
			});
		} else {
			this.fetchData();
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters != this.props.filters) {
			this.onFilterChanged();
		}
	}

	onFilterChanged = _.debounce(() => {
		if (!this.state.isLoading) {
			this.fetchData();
		}
	}, 700);

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = async (event) => {
		if (event.data) {
			event.data = null;
		}

		let contractsDataTable = {
			rows: event.rows,
			totalRecords: this.state.totalRecords,
			first: event.first,
			last: event.rows + event.first,
			loading: this.state.loading,
			sortField: this.state.sortField,
			sortOrder: this.state.sortOrder,
		};

		localStorage.setItem('contractsDataTable', JSON.stringify(contractsDataTable));

		this.setState({
			...this.state,
			rows: event.rows,
			loading: true
		});

		this.fetchData(event.rows, event.first);
	};

	fetchData = (limit, skip) => {
		let data = {
			$limit: limit ? limit : this.state.rows,
			$skip: skip != null ? skip : this.state.first,
			$sort: { [this.state.sortField]: this.state.sortOrder }
		};

		this.props.fetchEvent(data).then(async res => {
			await res.data.data.map(async (obj, i) => {
				if (obj.landlord_id) {
					obj.loading_landlord = true;
					await getUser(obj.landlord_id).catch(err => {
						obj.landlord = null;
						obj.loading_landlord = false;
					}).then(res => {
						if (res?.data?.data) {
							obj.landlord = res.data.data;
						}
					});
				}

				if (obj.tenant_id) {
					obj.loading_tenant = true;
					await getUser(obj.tenant_id).catch(err => {
						obj.tenant = null;
						obj.loading_tenant = false;
					}).then(res => {
						if (res?.data?.data) {
							obj.tenant = res.data.data;
						}
					});
				}

				this.setState({
					...this.state,
					data: res.data.data,
					totalRecords: res.data.total,
					first: skip,
					last: skip + res.data.limit,
					loading: false
				});
			});

			this.setState({
				...this.state,
				data: res.data.data,
				totalRecords: res.data.total,
				first: res.data.skip,
				last: res.data.skip + res.data.limit,
				loading: false
			});
		});
	};

	columnOptionsCrud = (rowData, column) => {
		let idRow = this.props.idRow ? this.props.idRow : 'id';

		if (this.props.crudUrl) {
			return (
				<div className="align-middle">
					<Link to={`${this.props.crudUrl}/show/${rowData[idRow]}`} className="btn btn-table-action mr-2"><i className="fas fa-eye" style={{ color: '#5664D2' }}></i></Link>
					{/*!rowData.is_terminated ? (
						<Link to={`${this.props.crudUrl}/edit/${rowData[idRow]}`} className="btn btn-table-action mr-2"><i className="fas fa-pencil-alt" style={{ color: '#5664D2' }}></i></Link>
					) : null*/}
				</div>
			);
		} else {
			return null;
		}
	};

	// export() {
	// 	this.dt.exportCSV();
	// }

	export() {
		this.setState({ fetchingCsvData: true });

		listContractsWithUsersData().then(async res => {
			let csvData = [['Proprietário', 'Inquilino', 'Cidade', 'Bairro', 'Valor total', 'Plano', 'Status', 'Data de criação']];

			for (const obj of res.data.data) {
				let status = '';
				switch (obj?.details?.warranty_type?.trim()) {
					case 'SECURITY_DEPOSIT':
					case 'SECURITY_DEPOSITY':
						status = 'Caução';
						break;
					case 'FREE':
						status = 'Gratuito';
						break;
					case 'WARRANTY':
						status = 'Garantia';
						break;
					case 'WARRANTY_GUARANTEE_DAMAGE':
						status = 'Garantia de danos';
						break;

					default:
						status = obj.status;
						break;
				}
				if (!status) {
					switch (obj?.property?.warranty?.type.trim()) {
						case 'SECURITY_DEPOSIT':
						case 'SECURITY_DEPOSITY':
							status = 'Caução';
							break;
						case 'FREE':
							status = 'Gratuito';
							break;
						case 'WARRANTY':
							status = 'Garantia';
							break;
						case 'WARRANTY_GUARANTEE_DAMAGE':
							status = 'Garantia de danos';
							break;

						default:
							status = obj.status;
							break;
					}
				}
				csvData.push([
					obj?.landlord?.name ?? '',
					obj?.tenant?.name ?? '',
					obj.property.address.city ?? '',
					obj.property.address.neighbourhood ?? '',
					obj.details.values.rent ?? '',
					status ?? '',
					obj.is_terminated ? 'Rescindido' : obj.signed ? 'Assinado' : 'Não assinado',
					obj.updated_at ? moment(obj.updated_at).format('DD/MM/YYYY HH:mm:ss') : ''
				])
			}

			console.log(csvData);

			this.setState({ csvData }, () => document.getElementById('csv_button').click());

		}).finally(() => {
			this.setState({ fetchingCsvData: false })
		});
	};

	getUserName = (obj, type) => {
		if (type === 'landlord') {
			if (obj.landlord) {
				return (
					<div style={{ textAlign: 'center' }}>
						<Link to={`/users/show/${obj.landlord.id}`} >{obj.landlord.name}</Link>
					</div>
				);
			} else {
				if (obj.loading_landlord) {
					return (
						<div style={{ textAlign: 'center' }}>
							<CircularProgress color="inherit" style={{ color: "#4AA3FF" }} size={15} />
						</div>
					);
				}
			}
		}

		if (type === 'tenant') {
			if (obj.tenant) {
				return (
					<div style={{ textAlign: 'center' }}>
						<Link to={`/users/show/${obj.tenant.id}`} >{obj.tenant.name}</Link>
					</div>
				);
			} else {
				if (obj.loading_tenant) {
					return (
						<div style={{ textAlign: 'center' }}>
							<CircularProgress color="inherit" style={{ color: "#4AA3FF" }} size={15} />
						</div>
					);
				}
			}
		}
	};

	getInsurance = (obj) => {
		let text_insurance = '';
		if (obj.insurance) {
			if (obj.insurance.portoseguro_status) {
				text_insurance += `Porto Seguro: ${obj.insurance.portoseguro_status}`;
			}

			if (obj.insurance.liberty_status) {
				text_insurance = text_insurance + (text_insurance == '' ? '' : ' / ');

				text_insurance += `Liberty: ${obj.insurance.liberty_status}`;
			}

			if (obj.insurance.velo_status) {
				text_insurance = text_insurance + (text_insurance == '' ? '' : ' / ');

				text_insurance += `Velo: ${obj.insurance.velo_status}`;
			}

			return (
				<div style={{ textAlign: 'center' }}>
					<p>{text_insurance}</p>
				</div>
			);
		} else {
			if (obj.loading_insurance) {
				return (
					<div style={{ textAlign: 'center' }}>
						<CircularProgress color="inherit" style={{ color: "#4AA3FF" }} size={15} />
					</div>
				);
			}
		}
	};

	getValor = (obj) => {
		return (
			<div style={{ textAlign: 'center' }}>
				<p>{obj.details?.values?.total ? formatBRL(obj.details?.values?.total) : ''}</p>
			</div>
		);
	};

	sortTable = e => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder }, () => {
			let contractsDataTable = {
				rows: this.state.rows,
				totalRecords: this.state.totalRecords,
				first: this.state.first,
				last: this.state.last,
				loading: this.state.loading,
				sortField: this.state.sortField,
				sortOrder: this.state.sortOrder,
			};

			localStorage.setItem('contractsDataTable', JSON.stringify(contractsDataTable));

			this.fetchData();
		});
	};

	getAnnouncementCity = (obj) => {
		if (obj.property?.address?.city) {
			return (
				<div style={{ textAlign: 'center' }}>
					<Link to={`/announcements/show/${obj.property.id}`} >{obj.property?.address?.city}</Link>
				</div>
			);
		}
	};

	getAnnouncement = (obj) => {
		if (obj.property?.address?.neighbourhood) {
			return (
				<div style={{ textAlign: 'center' }}>
					<Link to={`/announcements/show/${obj.property.id}`} >{obj.property?.address?.neighbourhood}</Link>
				</div>
			);
		}
	};

	getDateFormatted = (date) => {
		return (
			<p>{moment(date).format('DD/MM/YYYY HH:mm:ss')}</p>
		);
	};

	getPlan = (obj) => {
		if (obj?.plan_id) {
			get().then((res) => {
				return (
					<p>{res?.data?.data.name}</p>
				);
			});
		} else {
			return (
				<p>{obj?.details?.warranty_type}</p>
			);
		}
	};

	getStatusProposition = (obj) => {
		let status = '';
		switch (obj?.details?.warranty_type?.trim()) {
			case 'SECURITY_DEPOSIT':
			case 'SECURITY_DEPOSITY':
				status = 'Caução';
				break;
			case 'FREE':
				status = 'Gratuito';
				break;
			case 'WARRANTY':
				status = 'Garantia';
				break;
			case 'WARRANTY_GUARANTEE_DAMAGE':
				status = 'Garantia de danos';
				break;

			default:
				status = obj.status;
				break;
		}
		if (!status) {
			switch (obj?.property?.warranty?.type.trim()) {
				case 'SECURITY_DEPOSIT':
				case 'SECURITY_DEPOSITY':
					status = 'Caução';
					break;
				case 'FREE':
					status = 'Gratuito';
					break;
				case 'WARRANTY':
					status = 'Garantia';
					break;
				case 'WARRANTY_GUARANTEE_DAMAGE':
					status = 'Garantia de danos';
					break;

				default:
					status = obj.status;
					break;
			}
		}

		return (
			<div style={{ textAlign: 'center' }}>
				<p>{status}</p>
			</div>
		);
	};

	getStatus = (obj) => {
		let status = '';
		if (obj.termination_status == "AWAITING_COURT_APPROVAL") {
			status = 'Aguardando jurídico'
		} else if (obj.is_terminated) {
			status = 'Rescindido'
		} else if (obj.signed && (!obj.scheduled_termination_date || obj.scheduled_termination_date === null)) {
			status = 'Assinado'
		} else if (obj.scheduled_termination_date) {
			status = 'Rescisão agendada'
		} else {
			status = 'Não assinado'
		}

		return (
			<div style={{ textAlign: 'center' }}>
				<p>{status}</p>
			</div>
		);
	}

	render() {
		return (
			<DataTable ref={(el) => this.dt = el} value={this.state.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={[10, 25, 50]}
				first={this.state.first} last={this.state.last} header={null} responsive={true} className="ml-auto"
				totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} loadingIcon={`fas fa-sync fa-spin`}
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
				currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" emptyMessage="Nenhum registro encontrado!"
				onSort={(e) => this.sortTable(e)} sortField={this.state.sortField} sortOrder={this.state.sortOrder} >
				{this.props.export ? this.export() : false}
				<Column body={(obj, i) => this.getUserName(obj, 'landlord')} field="landlord.name" header="Proprietário"></Column>
				<Column body={(obj, i) => this.getUserName(obj, 'tenant')} field="tenant.name" header="Inquilino" />
				<Column sortable field="property.address.city" body={(obj, i) => this.getAnnouncementCity(obj)} header="Cidade" />
				<Column sortable field="property.address.neighbourhood" body={(obj, i) => this.getAnnouncement(obj)} header="Bairro" />
				<Column body={(obj, i) => this.getValor(obj)} field="details.values.total" header="Valor total" />
				<Column field="details.warranty_type" header="Plano" body={(obj) => this.getStatusProposition(obj)} style={{ width: '200px' }} />
				<Column sortable field="signed" header="Status" body={(obj) => this.getStatus(obj)} />
				<Column sortable field="created_at" body={(obj, i) => this.getDateFormatted(obj.created_at)} header="Data de criação" />
				<Column header="Ações" body={this.columnOptionsCrud} style={{ width: '130px' }} />
			</DataTable>
		);
	}
}