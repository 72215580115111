import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";
import { findMetricInfo, getChartColors, transformDataForChart } from "../filters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical, faEyeSlash, faGripVertical, faMaximize, faMinimize } from '@fortawesome/pro-solid-svg-icons';
import { Checkbox, ListItemText, Menu, MenuItem } from "@material-ui/core";

import { Chart } from "react-google-charts";

export default class UserIndicatorSection extends Component {
	constructor() {
		super();

		this.state = {
			metrics: [],
			dropdownKey: null,
			anchorEl: null,
			usersIndicatorsAvailableLabels: [
				{ label: "Não verificados", isActive: true, key: 'USER_INDICATORS_NOT_VERIFIED', color: '#5664D2' },
				{ label: "Verificados", isActive: true, key: 'USER_INDICATORS_VERIFIED_WITHOUT_CONTRACT', color: '#1CBB8C' },
				{ label: "Em contrato", isActive: true, key: 'USER_INDICATORS_WITH_CONTRACTS', color: '#4AA3FF' },
				{ label: "Total", isActive: true, key: 'TOTAL_USERS', color: '#DADBDD' },
				{ label: "Inativos", isActive: true, key: 'USER_INDICATORS_INACTIVES', color: null }
			],
			maximize: false
		};
	}

	componentDidMount() {
		if (this?.props?.metrics) {
			this.setState({ metrics: this.props.metrics })
		}
	}

	render() {
		return (
			<Card>
				<CardBody className="dashboard-item">
					<div className="box-header">
						<div className="">
							<h3 className="title">Indicadores de usuários</h3>
							<div className="box-info">
								Registro
							</div>
						</div>

						<div>
							<FontAwesomeIcon icon={this.props.layout?.w === 6 ? faMaximize : faMinimize} style={{ color: '#5664D2', padding: '12px', cursor: 'pointer' }} onClick={() => this.props.toggleMaximize(this.props?.itemKey)} />
							<FontAwesomeIcon icon={faEllipsisVertical} style={{ color: '#5664D2', padding: '12px', cursor: 'pointer' }} onClick={(e) => {
								this.setState({ dropdownKey: this.props.itemKey });
								this.setState({ anchorEl: e.currentTarget });
							}} />
							<FontAwesomeIcon icon={faEyeSlash} style={{ color: '#5664D2', padding: '12px', cursor: 'pointer' }} onClick={() => this.props.changeWidgetValue(this.props.itemKey)} />
							<FontAwesomeIcon icon={faGripVertical} style={{ color: '#DADBDD', padding: '12px', cursor: 'pointer' }} />
						</div>
					</div>

					<Menu
						anchorEl={this.state.anchorEl}
						open={this.state.dropdownKey === this.props.itemKey ? true : false}
						onClose={() => { this.setState({ dropdownKey: null }) }}
						className="dashboard-item"
						id="dashboard-drop"
					>
						{this.state.usersIndicatorsAvailableLabels.map((item) => (
							<MenuItem key={item.label} value={item.label}>
								<Checkbox checked={item.isActive} onChange={() => {
									this.setState(prevState => ({
										usersIndicatorsAvailableLabels: prevState.usersIndicatorsAvailableLabels.map(i =>
											i.label === item.label
												? { ...i, isActive: !i.isActive }
												: i
										)
									}));
								}} />
								<ListItemText primary={item.label} />
							</MenuItem>
						))}
					</Menu>

					<div style={{ position: 'relative', marginTop: '10px' }}>
						<hr className="divisor" />
					</div>

					<div className="chart-wrapper" style={{ marginTop: '80px', marginBottom: '55px' }}>

						<div className="chart-pie-wrapper" style={{ marginRight: 20 }}>
							{this.state.metrics[0] ? (
								<Chart
									key={this.props.layout?.w}
									chartType="PieChart"
									width={this.props.layout?.w === 6 ? '250px' : '580px'}
									height="250px"
									data={transformDataForChart(this.state.metrics[0]?.value, 'conversion', this.state.usersIndicatorsAvailableLabels)}
									options={{
										title: '',
										pieHole: 0.4,
										slices: getChartColors(this.state.usersIndicatorsAvailableLabels),
										legend: 'none',
									}}
								/>
							) : null}
						</div>

						<div className="chart-pie-legend">
							<div className="box-pie-legend" style={{ display: this.state.usersIndicatorsAvailableLabels.find(obj => obj?.label === 'Não verificados')?.isActive ? '' : 'none' }}>
								<div className="bg-purple">
									<p>
										{findMetricInfo(this.state.metrics, null, 'USER_INDICATORS_NOT_VERIFIED', 'count')}
									</p>
								</div>
								<div className="box-pie-legend-text">
									<p>Não verificados</p>
								</div>
							</div>

							<div className="box-pie-legend" style={{ display: this.state.usersIndicatorsAvailableLabels.find(obj => obj?.label === 'Verificados')?.isActive ? '' : 'none' }}>
								<div className="bg-green">
									<p>
										{findMetricInfo(this.state.metrics, null, 'USER_INDICATORS_VERIFIED_WITHOUT_CONTRACT', 'count')}
									</p>
								</div>
								<div className="box-pie-legend-text">
									<p>Verificados</p>
								</div>
							</div>

							<div className="box-pie-legend" style={{ display: this.state.usersIndicatorsAvailableLabels.find(obj => obj?.label === 'Em contrato')?.isActive ? '' : 'none' }}>
								<div className="bg-blue">
									<p>
										{findMetricInfo(this.state.metrics, null, 'USER_INDICATORS_WITH_CONTRACTS', 'count')}
									</p>
								</div>
								<div className="box-pie-legend-text">
									<p>Em contrato</p>
								</div>
							</div>

							<div className="box-pie-legend" style={{ display: this.state.usersIndicatorsAvailableLabels.find(obj => obj?.label === 'Inativos')?.isActive ? '' : 'none' }}>
								<div className="bg-gray">
									<p>
										{findMetricInfo(this.state.metrics, null, 'USER_INDICATORS_INACTIVES', 'count')}
									</p>
								</div>
								<div className="box-pie-legend-text">
									<p>Inativos</p>
								</div>
							</div>

							<div className="box-pie-legend" style={{ display: this.state.usersIndicatorsAvailableLabels.find(obj => obj?.label === 'Total')?.isActive ? '' : 'none' }}>
								<div className="bg-white">
									<p>
										{findMetricInfo(this.state.metrics, null, 'USER_TOTAL', 'count')}
									</p>
								</div>
								<div className="box-pie-legend-text">
									<p>Total</p>
								</div>
							</div>
						</div>
					</div>
				</CardBody>
			</Card>
		)
	}
}


