import React, { Component } from 'react';
import {
    Container, Card, CardBody, Row, Col, FormGroup, Label,
    Input, Button, Dropdown, DropdownMenu, DropdownItem, DropdownToggle
} from "reactstrap";
import Breadcrumbs from '../../components/Breadcrumb';
import { getAllByParams as getAllNotifications } from '../../crud/notifications.crud'
import DataTableNotifications from '../../components/datatables/DataTableNotifications';
import { UserFilterComponentObsolete } from '../negotiation/components/user.component';

class NotificationsIndex extends Component {

    state = {
        filters: {
            category: '',
            user: {},
        },
        flags: {
            show_category: false,
            show_users: false,
        },
        is_filter_open: false,
    }

    componentDidMount = () => {
        let filters = this.state.filters;
        let flags = this.state.flags;

        if (localStorage.getItem('notificationFilters')) {
            filters = JSON.parse(localStorage.getItem('notificationFilters'));
        };

        if (localStorage.getItem('notificationFlags')) {
            flags = JSON.parse(localStorage.getItem('notificationFlags'));
        };

        this.setState({ filters, flags });
    };

    saveFilters = () => {
        localStorage.setItem('notificationFilters', JSON.stringify(this.state.filters));
        localStorage.setItem('notificationFlags', JSON.stringify(this.state.flags));
    };

    fetchByFilter = (data) => {
        let params = {
            ...data,
            category_ilike: this.state.flags.show_category ? this.state.filters.category : undefined,
            user_id: this.state.flags.show_users ? this.state.filters.user?.id : undefined,
        };

        return getAllNotifications(params);
    };

    render() {
        return (
            <React.Fragment>
                <div className="page-content container-page">
                    <Container fluid>
                        <Breadcrumbs breadcrumbItems={[{ link: '/notifications/index', title: 'Notificações' }]} />

                        <Row className="align-right mb-30">
                            <Dropdown className="mr-10 wm-100" isOpen={this.state.is_filter_open} toggle={() => this.setState({ is_filter_open: !this.state.is_filter_open })} >
                                <DropdownToggle className="btn-index-action" color="primary" caret>
                                    <i className="ri-filter-3-line align-middle mr-2"></i> {"ADD FILTRO"}
                                </DropdownToggle>
                                <DropdownMenu>
                                    {!this.state.flags.show_category ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_category: true } })}>Categoria</DropdownItem> : null}
                                    {!this.state.flags.show_users ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_users: true } })}>Usuários</DropdownItem> : null}
                                </DropdownMenu>
                            </Dropdown>
                        </Row>

                        <Row className="container-center-input">
                            {this.state.flags.show_category ?
                                <Col md={4}>
                                    <Label className="col-form-label" style={{ color: "#343A40" }}>
                                        Categoria
                                    </Label>
                                    <FormGroup row>
                                        <Col>
                                            <Input className="form-control" type="text" value={this.state.filters.category} placeholder="Categoria"
                                                onChange={(e) => this.setState({ filters: { ...this.state.filters, category: e.target.value } }, () => this.saveFilters())} />
                                        </Col>
                                        <Button onClick={() => this.setState({ flags: { ...this.state.flags, show_category: false }, filters: { ...this.state.filters, category: '' } }, () => this.saveFilters())} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
                                            <i className="ri-close-circle-line align-middle"></i>
                                        </Button>
                                    </FormGroup>
                                </Col>
                                : false
                            }

                            {this.state.flags.show_users ?
                                <UserFilterComponentObsolete
                                    label="Usuários"
                                    onChange={(e, value) => {
                                        this.setState({ filters: { ...this.state.filters, user: value } });
                                        this.saveFilters();
                                    }}
                                    value={this.state.filters.user}
                                    onClick={
                                        () => this.setState(
                                            {
                                                flags: { ...this.state.flags, show_users: false },
                                                filters: { ...this.state.filters, user: {} }
                                            },
                                            () => this.saveFilters()
                                        )
                                    }
                                />
                                : false
                            }

                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <DataTableNotifications filters={this.state.filters} noDelete fetchEvent={this.fetchByFilter} crudButtons={true}
                                            crudUrl="/notifications" idRow={`id`} noShow />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default NotificationsIndex;