import React, { Component } from 'react';
import { Container, Card, CardBody, Alert, Row, Col, DropdownMenu, Input, DropdownItem, Button, FormGroup, DropdownToggle, ButtonDropdown, Label } from "reactstrap";
import BetterCircularProgress from '../../components/BetterCircularProgress';
import { formatBRL } from '../../helpers/Utils';
import Breadcrumbs from '../../components/Breadcrumb';
import moment from 'moment';
import { getAgreementBillsInfos, getAcordos as getAllByFilter } from '../../crud/billings.crud';
import Swal from 'sweetalert2';
import DataTableConbracasJuridicas from '../../components/datatables/DataTableConbracasJuridicas';
import { UserFilterComponent } from '../users/components/user.component';
import BetterAlerts from '../../components/BetterAlerts';
import DataTableExtrajudiciais from '../../components/datatables/DataTableExtrajudiciais';

class friendlyAgreementIndex extends Component {

	state = {
		filters: {
			landlord_id: undefined,
			tenant_id: undefined,
			status: undefined,
			due_date: undefined,
			value: undefined,
		},
		flags: {
			show_status: false,
			show_landlord_id: false,
			show_tenant_id: false,
			show_due_date: false,
			show_value: false,
		},
		is_filter_open: false,
		export: false,
		agreementInfos: {
			"totalPendingValue": 0,
			"openAgreements": 0,
			"settledAgreements": 0,
			"overdueAgreements": 0,
			"overdueInstallments": 0
		}
	};

	componentDidMount = async () => {
		this.setState({ loading: true });

		getAllByFilter().catch(err => {
			return Swal.fire('Ops', 'Ocorreu um erro ao buscar a cobrança jurídica.', 'error');
		}).then(res => {
			if (res?.status === 200) {
				console.log(res.data.data);
				if (res.data) {
					this.setState({ data: res.data.data })
				}
			}
		}).finally(() => this.setState({ loading: false }));

		getAgreementBillsInfos().then(res => {
			if (res.status === 200) {
				this.setState({ agreementInfos: res.data })
			}
		})
	};

	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	};

	formatDate(date) {
		const newDate = date?.split('/')[1] + '/' + date?.split('/')[0] + '/' + date?.split('/')[2];
		return newDate;
	}

	fetchByFilter = (data) => {
		let params = {
			...data,
			friendly_contract_code: this.state.flags.show_contract ? this.state.filters.friendly_contract_code : undefined,
			'values[value]': this.state.flags.show_value ? this.state.filters.value !== '' ? this.state.filters.value : undefined : undefined,
			landlord_id: this.state.flags.show_landlord_id ? this.state.filters.landlord_id : undefined,
			user_id: this.state.flags.show_tenant_id ? this.state.filters.tenant_id : undefined,
			due_date: this.state.flags.show_due_date ? this.state.filters.due_date : undefined
		};

		if (this.state.flags.show_status) {
			if (this.state.filters.status === 'OVERDUE') {
				params = {
					...params,
					due_date_lt: moment().format('YYYY-MM-DD'),
					is_paid: false
				}
			}

			if (this.state.filters.status === "OPEN") {
				params = {
					...params,
					is_paid: false,
					due_date_gte: moment().format('YYYY-MM-DD'),
					due_date_lte: moment().add(10, 'days').format('YYYY-MM-DD'),
				}
			}

			if (this.state.filters.status === "PAID") {
				params = {
					...params,
					is_paid: true
				}
			}
		}

		let infosParams = {
			friendly_contract_code: this.state.flags.show_contract ? this.state.filters.friendly_contract_code : undefined,
			'values[value]': this.state.flags.show_value ? this.state.filters.value !== '' ? this.state.filters.value : undefined : undefined,
			landlord_id: this.state.flags.show_landlord_id ? this.state.filters.landlord_id : undefined,
			user_id: this.state.flags.show_tenant_id ? this.state.filters.tenant_id : undefined,
			due_date: this.state.flags.show_due_date ? this.state.filters.due_date : undefined
		};

		if (this.state.flags.show_status) {
			if (this.state.filters.status === 'OVERDUE') {
				infosParams = {
					...infosParams,
					due_date_lt: moment().format('YYYY-MM-DD'),
					is_paid: false
				}
			}

			if (this.state.filters.status === "OPEN") {
				infosParams = {
					...infosParams,
					is_paid: false,
					due_date_gte: moment().format('YYYY-MM-DD'),
					due_date_lte: moment().add(10, 'days').format('YYYY-MM-DD'),
				}
			}

			if (this.state.filters.status === "PAID") {
				infosParams = {
					...infosParams,
					is_paid: true
				}
			}
		}

		getAgreementBillsInfos({ ...infosParams }).then(res => {
			if (res.status === 200) {
				this.setState({ agreementInfos: res.data })
			}
		})

		return getAllByFilter(params);
	};

	render() {
		return (
			<React.Fragment>
				<div className="page-content container-page">
					<Container fluid>
						<Breadcrumbs breadcrumbItems={[{ link: '/doc-user/index', title: 'Dashboard' }]} />
						<Row className="align-right mb-30">

							<ButtonDropdown className="mr-10 wm-100" isOpen={this.state.is_filter_open} toggle={() => this.setState({ is_filter_open: !this.state.is_filter_open })} >
								<DropdownToggle color="primary" caret>
									<i className="ri-filter-2-fill align-middle mr-2"></i> {"ADD FILTRO"}
								</DropdownToggle>
								<DropdownMenu className="col-sm btn-index-action dropdown-menu-right">
									{!this.state.flags.show_landlord_id ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_landlord_id: true } })}>Responsável</DropdownItem> : null}
									{!this.state.flags.show_tenant_id ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_tenant_id: true } })}>Inquilino</DropdownItem> : null}
									{!this.state.flags.show_status ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_status: true } })}>Status</DropdownItem> : null}
									{!this.state.flags.show_due_date ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_due_date: true } })}>Vencimento</DropdownItem> : null}
									{!this.state.flags.show_value ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_value: true } })}>Valor</DropdownItem> : null}
								</DropdownMenu>

							</ButtonDropdown>
						</Row>
						<BetterAlerts errors={this.state.errors} success={this.state.success} />


						<Row className="container-center-input">
							<Col lg={12}>
								<Card>
									<h3 className="title" style={{ color: '#505D69', fontSize: '22px', fontWeight: '600', padding: '1.25rem', marginTop: '10px' }}>Acordo amigável</h3>
									<div style={{ padding: '0.6rem', display: 'flex', flexWrap: 'wraṕ' }}>
										{this.state.flags.show_landlord_id ?
											<Col style={{ flex: '0 0 20%', maxWidth: '20%' }}>
												<Label className="col-form-label" style={{ color: "#343A40" }}>Responsável</Label>

												<FormGroup row>
													<Col style={{ flexGrow: 0 }}>
														<UserFilterComponent
															label="Responsável"
															onChange={(e, value) => {
																this.setState({ filters: { ...this.state.filters, landlord_id: value.id } });
															}}
															value={this.state.filters.landlord_id}
															onClick={
																() => this.setState(
																	{
																		flags: { ...this.state.flags, show_landlord_id: false },
																		filters: { ...this.state.filters, landlord_id: {} }
																	},
																)
															}
															style={{ width: '180px' }}
														/>
													</Col>
													<Button onClick={
														() => this.setState(
															{
																flags: { ...this.state.flags, show_landlord_id: false },
																filters: { ...this.state.filters, landlord_id: undefined }
															},
														)
													} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
														<i className="ri-close-circle-line align-middle"></i>
													</Button>
												</FormGroup>
											</Col>
											: false
										}

										{this.state.flags.show_tenant_id ?
											<Col style={{ flex: '0 0 20%', maxWidth: '20%' }}>
												<Label className="col-form-label" style={{ color: "#343A40" }}>Inquilino</Label>

												<FormGroup row>
													<Col style={{ flexGrow: 0 }}>
														<UserFilterComponent
															label="Responsável"
															onChange={(e, value) => {
																this.setState({ filters: { ...this.state.filters, tenant_id: value.id } });
															}}
															value={this.state.filters.tenant_id}
															onClick={
																() => this.setState(
																	{
																		flags: { ...this.state.flags, show_tenant_id: false },
																		filters: { ...this.state.filters, tenant_id: {} }
																	},
																)
															}
															style={{ width: '180px' }}
														/>
													</Col>
													<Button onClick={
														() => this.setState(
															{
																flags: { ...this.state.flags, show_tenant_id: false },
																filters: { ...this.state.filters, tenant_id: undefined }
															},
														)
													} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
														<i className="ri-close-circle-line align-middle"></i>
													</Button>
												</FormGroup>
											</Col>
											: false
										}

										{this.state.flags.show_status ?
											<Col style={{ flex: '0 0 20%', maxWidth: '20%' }}>
												<Label className="col-form-label" style={{ color: "#343A40" }}>Status</Label>

												<FormGroup row>
													<Col style={{ flexGrow: 0 }}>
														<select style={{ width: '180px', height: "40px", }} onChange={(e, value) => {
															this.setState({ filters: { ...this.state.filters, status: e.target.value } });
														}} value={this.state.filters.status} className="custom-select">
															<option key={null} value={null} hidden>Selecione o status</option>
															<option key='OVERDUE' value='OVERDUE'>Atrasado</option>
															<option key='OPEN' value='OPEN'>Em aberto</option>
															<option key='PAID' value='PAID'>Pago</option>
														</select>
													</Col>

													<Button onClick={
														() => this.setState(
															{
																flags: { ...this.state.flags, show_status: false },
																filters: { ...this.state.filters, status: undefined }
															},
														)
													} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
														<i className="ri-close-circle-line align-middle"></i>
													</Button>
												</FormGroup>
											</Col>
											: false
										}

										{this.state.flags.show_due_date ?
											<Col style={{ flex: '0 0 20%', maxWidth: '20%' }}>
												<Label className="col-form-label" style={{ color: "#343A40" }}>
													Vencimento
												</Label>
												<FormGroup row>
													<Col style={{ flexGrow: 0 }}>
														<Input className="mb-2" type="date" variant="outlined" style={{ background: "#FFF", height: "40px", width: '180px' }} value={this.state.filters.due_date}
															onChange={(e) => this.setState({ filters: { ...this.state.filters, due_date: e.target.value } })} />
													</Col>
													<Button onClick={() => this.setState({ flags: { ...this.state.flags, show_due_date: false }, filters: { ...this.state.filters, due_date: '' } })} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
														<i className="ri-close-circle-line align-middle"></i>
													</Button>
												</FormGroup>
											</Col>
											: false
										}

										{this.state.flags.show_value ?
											<Col style={{ flex: '0 0 20%', maxWidth: '20%' }}>
												<Label className="col-form-label">Valor</Label>
												<FormGroup row style={{ width: '300px' }}>
													<div className="col-10 col-sm-8 container-center-input">
														<Input className="form-control" style={{ width: '180px', height: "40px", }} type="text" placeholder='R$' value={this.state.filters.value ? formatBRL(this.state.filters.value) : ''}
															onChange={(e) => this.setState({ filters: { ...this.state.filters, value: Number(e.target.value.toString().replace(/[^0-9-]/g, '')) / 10 ** 2 } })} />
													</div>
													<Button onClick={() => this.setState({ flags: { ...this.state.flags, show_value: false }, filters: { ...this.state.filters, value: undefined } })} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
														<i className="ri-close-circle-line align-middle"></i>
													</Button>
												</FormGroup>
											</Col>
											: false
										}

									</div>
									<CardBody style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center', flex: 'inherit' }} id="dashboard">
										<div className="dashboardCard" style={{ width: '19%' }}>
											<h3 className="" style={{ color: '#505D69' }}><small>R$</small> {formatBRL(this.state?.agreementInfos?.totalPendingValue).replace('R$', '')}</h3>
											<p className="">Valor pendente total</p>
										</div>
										<div className="dashboardCard" style={{ width: '19%' }}>
											<h3 className="" style={{ color: '#FCB92C' }}>{this.state?.agreementInfos?.openAgreements}</h3>
											<p className="">Acordos em aberto</p>
										</div>
										<div className="dashboardCard" style={{ width: '19%' }}>
											<h3 className="" style={{ color: '#1CBB8C' }}>{this.state?.agreementInfos?.settledAgreements}</h3>
											<p className="">Acordos quitados</p>
										</div>
										<div className="dashboardCard" style={{ width: '19%' }}>
											<h3 className="" style={{ color: '#FF3D60' }}>{this.state?.agreementInfos?.overdueAgreements}</h3>
											<p className="">Acordos atrasados</p>
										</div>
										<div className="dashboardCard" style={{ width: '19%' }}>
											<h3 className="" style={{ color: '#FF3D60' }}>{this.state?.agreementInfos?.overdueInstallments}</h3>
											<p className="">Parcelas atrasadas</p>
										</div>
									</CardBody>
									<CardBody>
										<DataTableExtrajudiciais filters={this.state.filters} noDelete fetchEvent={this.fetchByFilter} crudButtons={true}
											crudUrl="/friendly-agreement" idRow={`id`} export={this.state.export} rows={10} />
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		)
	}
}

export default friendlyAgreementIndex;