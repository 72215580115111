import React, { Component } from 'react';
import { Container, Card, CardBody, Col, Label, TabContent, TabPane, Nav, NavItem, NavLink, Alert, Row } from "reactstrap";
import classnames from 'classnames';
import BetterCircularProgress from '../../components/BetterCircularProgress';
import { formatBRL } from '../../helpers/Utils';
import Breadcrumbs from '../../components/Breadcrumb';
import moment from 'moment';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { getWebhookError } from '../../crud/billings.crud';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';

class WebhookErrorShow extends Component {

	state = {
		loading: false,
		success: [],
		message: null,
		data: null,
		landlord: null,
		tenant: null,
		activeTab: '1',
		tenant_parties: [],
		billings: [],
		planName: '',
		flags: {
			tenant_sign: false,
			landlord_sign: false
		},
		proposition_id: null,
		contract_id: null
	}

	componentDidMount = async () => {
		this.setState({ loading: true });

		getWebhookError(this.props.match.params.id).catch(err => {
			return Swal.fire('Ops', 'Ocorreu um erro ao buscar o erro.', 'error');
		}).then(res => {
			if (res?.status === 200) {
				if (res.data) {
					this.setState({ data: res.data })
				}
			}
		}).finally(() => this.setState({ loading: false }));
	};

	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	};

	formatDate(date) {
		const newDate = date?.split('/')[1] + '/' + date?.split('/')[0] + '/' + date?.split('/')[2];
		return newDate;
	}

	render() {
		return (
			<React.Fragment>
				<BetterCircularProgress loading={this.state.loading}>
					<div className="page-content container-page">
						<Container fluid>


							<div className='wrapper-100'>
								<div className='friendly-agreement-breadcrumb webhook-error-breadcrumb'>
									<Label className="col-form-label" style={{ color: '#505D69', fontSize: '24px', fontFamily: 'Inter' }}>Erro do webhook</Label>

									<Breadcrumbs breadcrumbItems={[{ link: '/dashboard', title: 'Dashboard' }, { link: '/dashboard', title: 'Lista de erros no webhook de pagamentos' }, { link: `/webhook-errors/show/${this.state.data?.entity?.id}`, title: this.state?.data?.request?.payment?.id ?? 'Visualizar' }]} />
								</div>
							</div>


							{this.state.success && this.state.message ? <Alert color="success">{this.state.message}</Alert> : null}

							<div style={{ display: 'flex', justifyContent: 'center', width: '100%', flexDirection: 'column', alignItems: 'center' }}>
								<Card style={{ width: '70%' }}>
									<CardBody>

										<TabContent activeTab={this.state.activeTab} className="">
											<TabPane tabId="1" role="tabpanel">
												<Row style={{ marginTop: '24px', marginBottom: 24 }}>
													<Col md={12} className="d-flex">
														<p style={{ color: "var(--Cinza-500, #8590A5)", fontFamily: "Inter", fontSize: "12px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal", margin: '0px' }} >
															COBRANÇA {this.state?.data?.request?.payment?.id}
														</p>
													</Col>
												</Row>

												<Row>
													<Col md={6}>
														<Label style={{ fontSize: '20px', lineHeight: '16px' }} className="col-form-label">Evento recebido:</Label>
														<p className="col-form-label" style={{ color: "var(--Cinza-800, #343A40)", fontFamily: "Inter", fontSize: "13.008px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal", paddingTop: 0 }}>{this.state?.data?.request?.event}</p>
													</Col>

													<Col md={6}>
														<Label style={{ fontSize: '20px', lineHeight: '16px' }} className="col-form-label">Descrição da cobrança:</Label>
														<p className="col-form-label" style={{ color: "var(--Cinza-800, #343A40)", fontFamily: "Inter", fontSize: "13.008px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal", paddingTop: 0 }}>{this.state?.data?.request?.payment?.description}</p>
													</Col>
												</Row>

												<Row style={{ marginTop: '16px' }}>
													<Col md={6} style={{ display: 'flex', flexDirection: 'column' }}>
														<Label style={{ fontSize: '20px', lineHeight: '16px' }} className="col-form-label">Url da fatura na asaas:</Label>
														<a target='_blank' href={this.state?.data?.request?.payment?.invoiceUrl} className="col-form-label" style={{ fontSize: '13px', lineHeight: '16px', color: '#4AA3FF', paddingTop: 0 }}>{this.state?.data?.request?.payment?.invoiceUrl}</a>
													</Col>


													<Col md={6}>
														<Label style={{ fontSize: '20px', lineHeight: '16px' }} className="col-form-label">Erro retornado:</Label>
														<p className="col-form-label" style={{ color: "var(--Cinza-800, #343A40)", fontFamily: "Inter", fontSize: "13.008px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal", paddingTop: 0 }}>{this.state?.data?.response?.output?.payload?.message}</p>
													</Col>
												</Row>

												<Row style={{ marginTop: '16px' }}>
													<Col md={6} style={{ display: 'flex', flexDirection: 'column' }}>
														<Label style={{ fontSize: '20px', lineHeight: '16px' }} className="col-form-label">Comprovante:</Label>
														<a target='_blank' href={this.state?.data?.request?.payment?.transactionReceiptUrl} style={{ fontSize: '13px', lineHeight: '16px', color: '#4AA3FF', paddingTop: 0 }}>{this.state?.data?.request?.payment?.transactionReceiptUrl}</a>
													</Col>
													<Col md={6}>

														<Label style={{ fontSize: '20px', lineHeight: '16px' }} className="col-form-label">Data de pagamento:</Label>
														<p className="col-form-label" style={{ color: "var(--Cinza-800, #343A40)", fontFamily: "Inter", fontSize: "13.008px", fontStyle: "normal", fontWeight: 600, lineHeight: "normal", paddingTop: 0 }}>{moment(this.state?.data?.request?.payment?.paymentDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}</p>
													</Col>

												</Row>
											</TabPane>
										</TabContent>
									</CardBody>
								</Card>

								<Card style={{ width: '70%' }}>
									<CardBody className="row" style={{ flex: 'none', display: 'flex', justifyContent: 'end' }}>
										<>
											<button className="go-back-1" type='button' onClick={() => window.location.href = `/dashboard`}>
												<FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: '15px' }} />VOLTAR
											</button>


										</>
									</CardBody>
								</Card>
							</div>
						</Container>
					</div>
				</BetterCircularProgress>
			</React.Fragment>
		)
	}
}

export default WebhookErrorShow;