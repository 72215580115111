import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Link } from "react-router-dom";
import { Column } from 'primereact/column';
import Swal from 'sweetalert2';
import _ from 'lodash';
import { updateProperties, getPropertiesById, updateRaw, admitAnnouncement } from '../../crud/announcements.crud';
import { welcomeLandlord } from '../../crud/publicLinks.crud';
import { get as getUser } from '../../crud/users.crud';
import { CircularProgress } from "@material-ui/core";
import { formatTelefone } from '../../helpers/Utils';
import moment from 'moment';

export default class DataTableCrawler extends Component {
	constructor() {
		super();

		this.state = {
			data: [],
			rows: 10,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: 'id',
			sortOrder: 1,
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		let crawlerDataTable = JSON.parse(localStorage.getItem('crawlerDataTable'));

		if (crawlerDataTable) {
			this.setState({
				rows: crawlerDataTable.rows,
				totalRecords: crawlerDataTable.totalRecords,
				first: crawlerDataTable.first,
				last: crawlerDataTable.last,
				loading: crawlerDataTable.loading,
				sortField: crawlerDataTable.sortField,
				sortOrder: crawlerDataTable.sortOrder,
			}, () => {
				this.fetchData(true);
			});
		} else {
			this.fetchData(true);
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters != this.props.filters) {
			this.onFilterChanged(true);
		}
	}

	onFilterChanged = _.debounce((showLoading) => {
		if (!this.state.isLoading) {
			this.setState({
				loading: showLoading,
			}, () => {
				this.fetchData(showLoading);
			});
		}
	}, 700);

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = async (event) => {
		if (event.data) {
			event.data = null;
		}

		let crawlerDataTable = {
			rows: event.rows,
			totalRecords: this.state.totalRecords,
			first: event.first,
			last: event.rows + event.first,
			loading: this.state.loading,
			sortField: this.state.sortField,
			sortOrder: this.state.sortOrder,
		}

		localStorage.setItem('crawlerDataTable', JSON.stringify(crawlerDataTable));

		this.setState({
			...this.state,
			rows: event.rows,
			loading: true
		});

		this.fetchData(true, event.rows, event.first);
	};

	fetchData = (showLoading, limit, skip) => {
		let data = {
			$limit: limit ? limit : this.state.rows,
			$skip: skip != null ? skip : this.state.first,
			$sort: { [this.state.sortField]: this.state.sortOrder }
		};

		this.props.fetchEvent(data).then(async res => {
			await res.data.data.map(async (obj, i) => {
				if (obj.user_id) {
					obj.loading_user = showLoading;
					await getUser(obj.user_id).catch(err => {
						obj.user_data = null
						obj.loading_user = false;
					}).then(res => {
						if (res?.data?.data) {
							obj.user_data = res.data.data;
						}
					});
				}

				this.setState({
					...this.state,
					data: res.data.data,
					totalRecords: res.data.total,
					first: res.data.skip,
					last: res.data.skip + res.data.limit,
					loading: false
				});
			});

			this.setState({
				...this.state,
				data: res.data.data,
				totalRecords: res.data.total,
				first: res.data.skip,
				last: res.data.skip + res.data.limit,
				loading: false
			});
		});
	};

	columnOpcoesCrud = (rowData, column) => {
		let idRow = this.props.idRow ? this.props.idRow : 'id';

		if (this.props.crudUrl) {
			return (
				<div style={{ textAlign: 'center' }}>
					<Link to={`${this.props.crudUrl}/edit/${rowData[idRow]}`} className="btn btn-success btn-table-action mr-2"><i className="fa fa-edit"></i></Link>
					<Link to={`${this.props.crudUrl}/show/${rowData[idRow]}`} className="btn btn-primary btn-table-action mr-2"><i className="fas fa-eye"></i></Link>
				</div>
			);
		} else {
			return null;
		}
	};

	export() {
		this.dt.exportCSV();
	};

	getUserName = (obj) => {
		if (obj.user_data) {
			return (
				<div style={{ textAlign: 'center' }}>
					<Link to={`/users/show/${obj.user_data.id}`} >{obj.user_data.name}</Link>
				</div>
			)
		} else {
			if (obj.loading_user) {
				return (
					<div style={{ textAlign: 'center' }}>
						<CircularProgress color="inherit" style={{ color: "#4AA3FF" }} size={15} />
					</div>
				)
			}
		}
	};

	getUserPhone = (obj) => {
		if (obj.user_data) {
			return (
				<div style={{ textAlign: 'center' }}>
					<p>{obj.user_data.phone ? formatTelefone(obj.user_data.phone) : ''}</p>
				</div>
			)
		} else {
			if (obj.loading_user) {
				return (
					<div style={{ textAlign: 'center' }}>
						<CircularProgress color="inherit" style={{ color: "#4AA3FF" }} size={15} />
					</div>
				)
			}
		}
	};

	getAnnouncement = (obj) => {
		if (obj.location) {
			return (
				<div style={{ textAlign: 'center' }}>
					<p>{obj.location?.municipality}</p>
				</div>
			)
		} else {
			if (this.loading) {
				return (
					<div style={{ textAlign: 'center' }}>
						<CircularProgress color="inherit" style={{ color: "#4AA3FF" }} size={15} />
					</div>
				)
			}
		}
	};

	getValor = (obj) => {
		if (obj.data) {
			return (
				<div style={{ textAlign: 'center' }}>
					<p>{obj.data?.price ? obj.data?.price : 'Não identificado'}</p>
				</div>
			)
		} else {
			if (this.loading) {
				return (
					<div style={{ textAlign: 'center' }}>
						<CircularProgress color="inherit" style={{ color: "#4AA3FF" }} size={15} />
					</div>
				)
			}
		}
	};

	getConverted = (obj) => {
		if (obj.converted) {
			return (
				<div style={{ textAlign: 'center' }}>
					<p>Sim</p>
				</div>
			)
		} else {
			return (
				<div style={{ textAlign: 'center' }}>
					<p>Não</p>
				</div>
			)
		}
	};

	setApproval = async (obj, i, approval) => {
		if (approval) {
			if (!obj?.user_data?.phone) {
				Swal.fire('Ops', 'Para aprovar é necessário adicionar um telefone ao usuário.', 'error');
				return;
			}
		}

		if (obj.approved != approval) {
			let data = this.state.data;

			data[i].block = true;

			this.setState({ data });

			updateRaw(obj.id, { approved: approval, complete: false }).catch(err => {
				Swal.fire('Ops!', 'An error ocurred with our servers, please try again.', 'error');
			});
		}

		if (approval) {
			// Adicionado para imóvel aprovado pelo admin aparecer na busca, mesmo que não seja convertido https://trello.com/c/LBzY5RTj/177-crawler-aprovar-e-aparecer-na-busca
			await updateProperties(obj.announcement_id, { is_published: true }).catch(err => { }).then(res => { });

			let data = {
				authenticate_to: obj.user_id,
				data: {
					raw_announcement: obj,
				},
				path: `?skipLandingPage=true&id=${obj.id}`
			};

			welcomeLandlord(data).catch(err => {
				Swal.fire('Ops!', 'An error ocurred with our servers, please try again.', 'error');
			}).then(res => {
			});

			let dataNoLP = {
				authenticate_to: obj.user_id,
				data: {
					raw_announcement: obj,
				},
				path: `?id=${obj.id}`
			};

			welcomeLandlord(dataNoLP).catch(err => {
				Swal.fire('Ops!', 'An error ocurred with our servers, please try again.', 'error');
			}).then(res => {
			});
		}
	}

	showClickableApproval = (obj, approval) => {
		if (obj.block) {
			return false;
		} else {
			if (obj.approved == null) {
				return true;
			}

			if (obj.approved != approval) {
				return true;
			}

			return false;
		}
	}

	getSetApproval = (obj, i) => {
		return (
			<div style={{ textAlign: 'center' }}>
				<div className={this.showClickableApproval(obj, true) ? 'pointer-events' : ''} onClick={() => this.setApproval(obj, i, true)}><i className={this.showClickableApproval(obj, true) ? 'ri-thumb-up-fill align-middle approval-icon-focus ri-2x' : 'ri-thumb-up-fill align-middle ri-2x'}></i><p className={this.showClickableApproval(obj, true) ? 'approval-text-focus' : ''} >APROVAR</p></div>
				<div className={this.showClickableApproval(obj, false) ? 'pointer-events' : ''} onClick={() => this.setApproval(obj, i, false)}><i className={this.showClickableApproval(obj, false) ? 'ri-thumb-down-fill align-middle approval-icon-focus ri-2x' : 'ri-thumb-down-fill align-middle ri-2x'}></i><p className={this.showClickableApproval(obj, false) ? 'approval-text-focus' : ''} >DESAPROVAR</p></div>
			</div >
		)
	};

	sortTable = e => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder }, () => {
			let crawlerDataTable = {
				rows: this.state.rows,
				totalRecords: this.state.totalRecords,
				first: this.state.first,
				last: this.state.last,
				loading: this.state.loading,
				sortField: this.state.sortField,
				sortOrder: this.state.sortOrder,
			};

			localStorage.setItem('crawlerDataTable', JSON.stringify(crawlerDataTable));

			this.fetchData(true);
		});
	};

	getNameAnnouncement = (obj) => {
		return (
			<a className="link" rel="noopener noreferrer" target="_blank" href={obj.link}>{obj.name}</a>
		)
	};

	getDateFormatted = (date) => {
		return (
			<p>{moment(date).format('DD/MM/YYYY HH:mm:ss')}</p>
		)
	}

	render() {
		const header = (
			<div style={{ float: 'right' }}>
				<Button className="tableHeaderItem" icon={!this.state.loading ? "fas fa-sync" : "fas fa-sync fa-spin"} disabled={this.state.loading ? "disabled" : ""} onClick={() => this.refreshTable()} />
			</div>
		);

		return (
			<DataTable ref={(el) => this.dt = el} value={this.state.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={[10, 25, 50]}
				first={this.state.first} last={this.state.last} header={this.props.noHeader ? null : header} responsive={true}
				totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} loadingIcon={`fas fa-sync fa-spin`}
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
				currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" emptyMessage="Nenhum registro encontrado!"
				onSort={(e) => this.sortTable(e)} sortField={this.state.sortField} sortOrder={this.state.sortOrder} >
				<Column sortable field="name" body={(obj, i) => this.getNameAnnouncement(obj)} header="Nome" />
				<Column body={(obj, i) => this.getUserName(obj)} header="Usuário" />
				<Column body={(obj, i) => this.getAnnouncement(obj)} header="Imóvel"></Column>
				<Column sortable field="data.price" body={(obj, i) => this.getValor(obj)} header="Valor" />
				<Column body={(obj, i) => this.getUserPhone(obj)} header="Telefone" />
				<Column sortable field="converted" body={(obj, i) => this.getConverted(obj)} header="Convertido" />
				<Column sortable field="created_at" body={(obj, i) => this.getDateFormatted(obj.created_at)} header="Entrou em" />
				<Column body={(obj, i) => this.getSetApproval(obj, i.rowIndex)} header="Aprovação" />
				<Column header="Ações" body={this.columnOpcoesCrud} style={{ width: '130px' }} />
			</DataTable>
		);
	}
}