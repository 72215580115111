import React, { Component, createRef } from 'react';
import { Container, Card, Row, Col, Label, Input, } from "reactstrap";
import Breadcrumbs from '../../../components/Breadcrumb';
import BetterAlerts from '../../../components/BetterAlerts';
import BetterCircularProgress from '../../../components/BetterCircularProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faChevronDown, faFileImport } from '@fortawesome/pro-regular-svg-icons';
import { faArrowLeft, faFileExport } from '@fortawesome/pro-solid-svg-icons';
import { formatBRL } from '../../../helpers/Utils';
import { Redirect } from "react-router-dom";
import { Formik } from "formik";
import { validateFormGratification } from "../../../helpers/FormValidators";
import { TextField, MenuItem, Select, FormGroup } from '@material-ui/core';
import { calcAnticipationValues, createAnticipation, uploadAnticipation } from '../../../crud/billings.crud';
import { getContractsOfUser } from '../../../crud/contracts.crud';
import moment from 'moment';
import { formatDate } from '../../negotiation/helpers';
import { getAllByFilter as getDocUsers } from '../../../crud/doc-users.crud';

class NewAnticipation extends Component {
	dataTableRef = createRef();

	state = {
		errors: [],
		success: [],
		anticipation_id: null,
		contracts: [],
		availablePeriod: [],
		totalValueRetained: 0,
		anticipatedValue: 0,
		file: null,
		judicializedContracts: []
	};

	componentDidMount() {
		getContractsOfUser({ user_id: this.props.match.params.id, firstPayment: true, is_terminated_ne: true }).then(async (response) => {
			this.setState({ contracts: response?.data?.data });

			for (const contract of response.data.data) {
				await getDocUsers({ category: ['Cobranças jurídicas', 'Sentença', 'Processo conhecimento', 'Processo fase de execução'], status: null, contract_id: contract.contract_id }).catch(err => { }).then(res => {
					if (res.data.total > 0) {
						const previousJudicializedContracts = this.state.judicializedContracts;
						previousJudicializedContracts.push(contract.contract_id);
						this.setState({ judicializedContracts: previousJudicializedContracts });
					}
				});
			}
		})
	}

	saveAnticipation = async (values, errors) => {
		this.setState({ loading: true });

		if (values?.contracts?.length == 0) {
			this.setState({ loading: false });
			this.setState({ errors: ['Adicione contratos!'] });
			return;
		}

		if (moment().add(1, 'days').isAfter(moment(values.anticipation_date))) {
			this.setState({ loading: false });
			this.setState({ errors: ['Data de antecipação inválida. A data de pagamento deve ser maior que a data atual!'] });
			return;
		}

		if (moment().add(14, 'days').isAfter(moment(values.start_date))) {
			this.setState({ loading: false });
			this.setState({ errors: ['Data de início de retenção inválida! Somente datas superiores a 15 dias a partir da data atual são permitidas!'] });
			return;
		}

		if (!this.state.file) {
			this.setState({ loading: false });
			this.setState({ errors: ['Carregue o Instrumento particular de cessão de créditos de locação!'] });
			return;
		}

		const body = {
			start_date: values.start_date,
			anticipation_period: values.anticipation_period,
			contracts_ids: values.contracts.map(obj => obj.contract.id),
			anticipation_date: values.anticipation_date,
			user_id: this.props.match.params.id,
		}

		await createAnticipation(body).catch((error) => {
			this.setState({ loading: false });
			this.setState({ errors: [error?.response?.data?.message] });
		}).then((response) => {
			if (response?.status == 200) {
				this.setState({ anticipation_id: response?.data?.data?.id });
				let formData = new FormData();

				formData.append('file', this.state.file);

				uploadAnticipation(response.data.data.id, formData).then((res) => {

				}).catch((err) => {
					console.log(`Error uploading file: ${err}`);
				}).finally(() => {

				})

				this.setState({ loading: false });
				this.setState({ success: ['Antecipação criada com sucesso!'] })
			} else {
				this.setState({ errors: ['Erro ao criar antecipação!'] })
				this.setState({ loading: false });
			}
		});
	}

	getInitialValues = () => {
		return {
			contracts: [],
			role: '',
			contractSelect: '',
			anticipation_period: 1,
			bonus_rate: 0.1,
			start_date: moment().add(15, 'days').format('YYYY-MM-DD'),
			anticipation_date: moment().add(1, 'days').format('YYYY-MM-DD')
		};
	}

	deleteUser = (setFieldValue, values, index) => {
		this.setState({ errors: [] });

		let contractToRemove = values.contracts[index];

		const subValue = values.contracts[index]?.contract?.calc?.totalValueRetained ?? 0;
		const subAnticipatedValue = values.contracts[index]?.contract?.calc?.anticipatedValue ?? 0;

		this.setState({ totalValueRetained: Math.abs(this.state.totalValueRetained - subValue) });
		this.setState({ anticipatedValue: Math.abs(this.state.anticipatedValue - subAnticipatedValue) });

		const contracts = values?.contracts;
		contracts.splice(index, 1);

		setFieldValue('contracts', contracts);

		let availablePeriod = this.state.availablePeriod;

		if (moment(contractToRemove?.contract?.details?.end_date, 'MM/DD/YYYY').diff(moment(values.start_date), 'months') === availablePeriod.length) {
			availablePeriod = []
		}

		for (const key of contracts) {
			const finalPeriod = moment(key?.contract?.details?.end_date, 'MM/DD/YYYY').diff(moment(values.start_date), 'months')

			if (availablePeriod.length === 0 || availablePeriod.length > finalPeriod) {
				availablePeriod = Array.from({ length: finalPeriod }, (_, i) => i + 1)
			}
		}

		this.setState({ availablePeriod: availablePeriod });
	}

	getContractStatus = (contract) => {
		return (
			<div style={{ display: 'flex', gap: 8 }}>
				{contract?.is_terminated !== true && contract?.signed && (
					<div style={{ backgroundColor: '#1CBB8C', borderRadius: '2px', fontFamily: 'Inter', color: '#fff', padding: '4px 12px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						Contrato vigente
					</div>
				)}

				{contract?.scheduled_termination_date !== null && (
					<div style={{ backgroundColor: '#EFA200', borderRadius: '2px', fontFamily: 'Inter', color: '#fff', padding: '4px 12px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						Encerramento agendado
					</div>
				)}

				{this.state.judicializedContracts.includes(contract.id) && (
					<div style={{ backgroundColor: '#5664D2', borderRadius: '2px', fontFamily: 'Inter', color: '#fff', padding: '4px 12px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						Judicializado
					</div>
				)}
			</div>
		)
	}

	handleCalculate = async (contracts, anticipation_period, start_date) => {
		let calc;
		let contractsToAdd = []

		const data = {
			start_date: start_date,
			anticipation_period: anticipation_period,
			user_id: this.props.match.params.id,
			contracts_ids: contracts.map(obj => obj.contract.id)
		}

		await calcAnticipationValues(data).then((response) => {
			if (response?.data?.data) {
				calc = response.data.data

				contracts.map((key, index) => {
					const contractBillings = calc.billingsToAnticipate.filter(obj => obj.contract_id === key.contract.id);

					const contractValueToRetain = contractBillings.reduce((acc, obj) => acc + obj.value, 0);
					const contractValueToAnticipate = contractBillings.reduce((acc, obj) => acc + obj.valueToAnticipate, 0);

					const finalKey = { ...key, contract: { ...key.contract, calc: { totalValueRetained: contractValueToRetain, anticipatedValue: contractValueToAnticipate } } }

					// setFieldValue(`contracts[${index}]`, finalKey)
					contractsToAdd.push(finalKey);
				})

				this.setState({ totalValueRetained: calc.totalValueRetained });
				this.setState({ anticipatedValue: calc.anticipatedValue });
			}
		})

		return contractsToAdd;
	}

	addNewContract = async (setFieldValue, values, key) => {
		const contracts = values.contracts;

		if (values.contracts.find(obj => obj.id === key.id)) {
			return;
		}

		contracts.push(key);

		const contractsToAdd = await this.handleCalculate(contracts, values.anticipation_period, values.start_date, setFieldValue);

		const finalPeriod = moment(key?.contract?.details?.end_date, 'MM/DD/YYYY').diff(moment(values.start_date), 'months')

		if (finalPeriod < 0 || finalPeriod === 0) {
			this.setState({ errors: ['Contrato não pode ser antecipado, não existem faturas a partir da data de início sugerida.'] });
			return;
		}

		if (this.state.availablePeriod.length === 0 || this.state.availablePeriod.length > finalPeriod) {
			this.setState({ availablePeriod: Array.from({ length: finalPeriod }, (_, i) => i + 1) })
		}

		setFieldValue(`contracts`, contractsToAdd)
	}

	handleStartDate = async (setFieldValue, values, e, handleChange) => {
		this.setState({ errors: [] });
		handleChange(e);

		const start_date = e.target.value;
		let contracts = values.contracts;
		const contractsToRemove = [];

		for (const key of contracts) {
			const finalPeriod = moment(key?.contract?.details?.end_date, 'MM/DD/YYYY').diff(moment(start_date), 'months')

			if (finalPeriod < 0 || finalPeriod === 0) {
				contractsToRemove.push(key.id);
				this.setState({ errors: [`O contracto ${key?.contract?.friendly_code} foi removido, por não possuir faturas a partir da data de início da retenção!`] });
			}

			if (this.state.availablePeriod.length === 0 || this.state.availablePeriod.length > finalPeriod) {
				this.setState({ availablePeriod: Array.from({ length: finalPeriod }, (_, i) => i + 1) })
			}
		}

		contracts = values.contracts.filter(obj => !contractsToRemove.includes(obj.id));

		const contractsToAdd = await this.handleCalculate(contracts, values.anticipation_period, e.target.value, setFieldValue);

		setFieldValue(`contracts`, contractsToAdd)
	}

	handleAntecipationPeriod = async (setFieldValue, values, key, e) => {
		const contracts = values.contracts;
		const anticipation_period = key;

		const contractsToAdd = await this.handleCalculate(contracts, anticipation_period, values.start_date, setFieldValue);

		setFieldValue('anticipation_period', anticipation_period);
		setFieldValue(`contracts`, contractsToAdd)
	}

	render() {
		const ContractBox = ({ contract, index, setFieldValue, values }) => {
			return (
				<div className='boxContractAnticipation'>
					<div className='d-flex align-items justify-content-between w-100'>
						<div className="" style={{ alignSelf: 'end' }}>
							<a href={`/negotiation/show/${contract.proposition_id}`}>
								{contract?.friendly_code}
							</a>

							<div className='d-flex gap-2'>
								{this.getContractStatus(contract)}
							</div>
						</div>

						<div className='d-flex' style={{ alignSelf: 'end' }}>
							<div style={{ marginRight: 24 }}>
								<label>Prazo restante</label>
								<p>{moment(contract.details.end_date, 'MM/DD/YYYY').diff(moment(values.start_date), 'months')} {moment(contract.details.end_date, 'MM/DD/YYYY').diff(moment(values.start_date), 'months') > 1 ? 'meses' : 'mês'}</p>
							</div>

							<div>
								<label>Valor retido</label>
								<p>{formatBRL(contract.calc?.totalValueRetained)}</p>
							</div>
						</div>
					</div>

					<div className="d-flex" style={{ gap: 24 }}>
						<div className="deleteContract" onClick={() => this.deleteUser(setFieldValue, values, index)}>
							<FontAwesomeIcon icon={faTimes} />
						</div>
					</div>
				</div>
			)
		}

		const RadioButton = ({ handleChange, handleBlur, values, field, name, text, style }) => {
			const isHTML = (str) => /<\/?[a-z][\s\S]*>/i.test(str);

			return (
				<Col md={12} style={{ marginLeft: "10px" }}>
					<Input id={field} type="radio" name={name} value={field}
						onChange={handleChange} onBlur={handleBlur} color="primary"
						checked={values[name] === field}
					/>
					<Label style={{ padding: "3px", marginLeft: 2, ...style }} for={field} >
						{isHTML(text) ? (
							<span dangerouslySetInnerHTML={{ __html: text }} />
						) : (
							text
						)}
					</Label>
				</Col>
			)
		}

		return (
			<React.Fragment>
				{this.state.success.length > 0 ? (
					<Redirect
						to={{
							pathname: `/anticipations/show/${this.state.anticipation_id}`,
							state: { success: ["Antecipação criada com sucesso!"] },
						}}
					/>
				) : null}

				<div className="page-content container-page userShow negociacao newAnticipation">
					<Container fluid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', alignItems: 'center' }}>
						<div className='header' style={{ width: '75%' }}>
							<h1>Nova antecipação</h1>

							<Breadcrumbs breadcrumbItems={[{ link: '/users/index', title: 'Usuários' }, { link: `/users/show/${this.props.match.params.id}`, title: `${this.props.match.params.id}` }, { link: '', title: 'Nova antecipação' }]} />
						</div>

						<BetterAlerts errors={this.state.errors} success={this.state.success} />

						<Formik initialValues={this.getInitialValues()} validate={(values) => validateFormGratification(values, "store")} onSubmit={this.onSubmit}>
							{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) =>
								<form noValidate={true} autoComplete="off" onSubmit={handleSubmit} className="form" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', alignItems: 'center' }}>
									<div className='box' style={{ width: '75%' }}>
										<h5>DETALHES</h5>

										<div>
											<div>

											</div>

											<div className='formulario'>

												<div className='d-flex justify-content-between' style={{ gap: 24 }}>
													<div className='w-50'>
														<Label>Início da retenção de valores</Label>
														<TextField name="start_date" type="date" placeholder="00/00/0000" variant="outlined" value={values.start_date} onBlur={handleBlur} onChange={(e) => { this.handleStartDate(setFieldValue, values, e, handleChange) }} inputProps={{
															min: formatDate({ date: moment().add(15, 'days'), format: 'YYYY-MM-DD' })
														}}
															helperText={touched.start_date && errors.start_date} error={Boolean(touched.start_date && errors.start_date)} />
													</div>

													<div className='w-50'>
														<Label>Busque e selecione contratos para antecipar</Label>

														<Select labelId="select-widget-label" value={this.state.contractSelect} displayEmpty
															onChange={this.handleSelectChange} IconComponent={() => <FontAwesomeIcon icon={faChevronDown} />}
															variant="outlined" className="custom-select" MenuProps={{ PaperProps: { className: 'custom-menu-item' } }}>
															<MenuItem value="">
																<em style={{ color: '#8590A5', fontFamily: 'Inter', fontStyle: 'normal' }}>Selecione um ou mais contratos</em>
															</MenuItem>


															{this.state.contracts.map((key, index) => (
																<MenuItem key={index} value={key} className="custom-menu-item" onClick={async () => { await this.addNewContract(setFieldValue, values, key, handleChange) }}>
																	{key?.contract?.friendly_code}
																	{values.contracts.find(obj => obj.id === key.id) ? <FontAwesomeIcon icon={faCheck} style={{ color: '#1CBB8C', marginLeft: 8 }} /> : null}
																</MenuItem>
															))}
														</Select>
													</div>
												</div>

												{values.contracts.map((contract, index) => {
													return (
														<ContractBox contract={contract?.contract} index={index} setFieldValue={setFieldValue} values={values} />
													)
												})}

												{values.contracts.length > 0 ?
													<div className='warningBox_1' style={{ padding: '32px 24px', height: '127px' }}>
														<label>Lembre-se:</label>
														<p>O valor retido é referente apenas a quantia que este usuário receberá do pagamento e não a todo o valor do aluguel.</p>
													</div>
													: null}
											</div>
										</div>
									</div>

									<div className='box' style={{ width: '75%' }}>
										<h5>FINANCEIRO</h5>

										<div className='formulario'>

											<div className='d-flex justify-content-between' style={{ gap: 24 }}>
												<div className='w-50'>
													<Label>Qual período será antecipado?</Label>

													<Select labelId="select-widget-label" value={values.anticipation_period} displayEmpty
														onChange={this.handleSelectChange} IconComponent={() => <FontAwesomeIcon icon={faChevronDown} />}
														variant="outlined" className="custom-select" MenuProps={{ PaperProps: { className: 'custom-menu-item' } }}>
														<MenuItem value="">
															<em style={{ color: '#8590A5', fontFamily: 'Inter', fontStyle: 'normal' }}>Selecione o período</em>
														</MenuItem>


														{this.state.availablePeriod.map((key, index) => (
															<MenuItem key={index} value={key} className="custom-menu-item" onClick={(e) => { this.handleAntecipationPeriod(setFieldValue, values, key, e) }}>
																{`${key} mês(es)`}
																{values.contracts.find(obj => obj.id === key.id) ? <FontAwesomeIcon icon={faCheck} style={{ color: '#1CBB8C', marginLeft: 8 }} /> : null}
															</MenuItem>
														))}
													</Select>
												</div>

												<div className='w-50'>
													<Label>Pagamento da antecipação</Label>
													<TextField name="anticipation_date" type="date" placeholder="00/00/0000" variant="outlined" value={values.anticipation_date} onBlur={handleBlur} onChange={handleChange} inputProps={{
														min: formatDate({ date: moment().add(1, 'days'), format: 'YYYY-MM-DD' })
													}}
														helperText={touched.anticipation_date && errors.anticipation_date} error={Boolean(touched.anticipation_date && errors.anticipation_date)} />
												</div>
											</div>

											<div className='d-flex mt-5 values-footer'>
												<div>
													<h3>Mensais de</h3>
													<h1 style={{ color: '#1CBB8C', fontSize: 19 }}><small style={{ fontSize: 13, fontWeight: 600 }}>R$</small>{formatBRL((this.state.totalValueRetained / values.anticipation_period).toFixed(2)).replace("R$", "")}</h1>
												</div>

												<div style={{ marginLeft: 34 }}>
													<h3>Periodo retido</h3>
													<h1 style={{ color: '#8590A5', fontSize: 19, marginBottom: 0 }}>{values.anticipation_period} <small>meses</small></h1>
												</div>

												<div style={{ marginLeft: 34 }}>
													<h3>Valor total retido</h3>
													<h1 style={{ color: '#1CBB8C', fontSize: 19 }}><small style={{ fontSize: 13, fontWeight: 600 }}>R$</small>{formatBRL((this.state.totalValueRetained).toFixed(2)).replace("R$", "")}</h1>
												</div>

												<div style={{ marginLeft: 34 }}>
													<h3>Valor antecipado</h3>
													<h1 style={{ color: '#5664D2', fontSize: 19 }}><small style={{ fontSize: 13, fontWeight: 600 }}>R$</small>{formatBRL((this.state.anticipatedValue).toFixed(2)).replace("R$", "")}</h1>
												</div>
											</div>
										</div>
									</div>

									<div className='box' style={{ width: '75%' }}>
										<h5>JURÍDICO</h5>

										<div className='formulario'>
											<FormGroup className="mb-0 checkBox-admin">
												<Label className="" style={{ fontSize: '13px', marginBottom: 0 }}>Carregue o Instrumento particular de cessão de créditos de locação</Label>

												<br />

												<Row className="p-0 m-0" style={{ display: 'flex', alignItems: 'center' }}>
													<Col lg={9} md={12} className="p-0 m-0" style={{ maxWidth: '85%', flex: '2' }}>
														<div className='file-label'>
															{this.state.file ? (
																<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
																	<p className='file-text'>{this.state.file.name}</p>
																	<FontAwesomeIcon icon={faFileExport} style={{ color: '#4AA3FF' }} />
																</div>
															) : (
																<p className='input-text'>Arquivo.for</p>
															)}
														</div>
													</Col>

													<Col lg={3} md={12} className="marginInputMobile" style={{ maxWidth: '121px' }}>
														<label htmlFor={'file'} className="btn btn-secondary btn-bold mb-0 d-flex justify-content-center align-items-center" style={{ width: '121px', height: '38px', backgroundColor: '#5664D2', color: '#FFF', fontWeight: 600, fontFamily: 'Inter' }}>
															<input className="doc-juridico-select" type="file" id={'file'} style={{ display: 'none' }} name={'file'} accept="*/*" onChange={(event) => { this.setState({ file: event.currentTarget.files[0] }) }} />

															IMPORTAR
															<FontAwesomeIcon icon={faFileImport} style={{ marginLeft: '10px', fontSize: '14px' }} />
														</label>
													</Col>
												</Row>
											</FormGroup>
										</div>
									</div>

									<div className='box' style={{ width: '75%', padding: '16px 24px' }}>
										<div className=''>
											<RadioButton handleChange={handleChange} handleBlur={handleBlur} values={values} field='true' name='confirm_anticipation' text='Confirmo em criar esse acordo e sei que esta ação é <span style="color: #FF3D60">irreversível.</span>' style={{ marginBottom: '0px' }} />
										</div>
									</div>

									<Card className='card-general' style={{ width: '75%' }}>
										<div className='datatable-box'>
											<BetterCircularProgress loading={this.state.loading}>
												<div className='d-flex justify-content-end align-items-center p-4'>
													<div className='d-flex'>
														<button onClick={() => this.props.history.goBack()} className="btnSecundary" style={{ width: 'max-content', margin: '0px 8px', padding: '8px 12px' }}>
															<FontAwesomeIcon icon={faArrowLeft} />
															CANCELAR
														</button>

														<button onClick={async () => await this.saveAnticipation(values, errors)} disabled={values.contracts.length === 0 || !values.confirm_anticipation} className="buttonPrimary w-100" style={{ width: 'max-content', margin: '0px 8px', padding: '8px 12px', backgroundColor: "#5664D2" }}>
															CRIAR ANTECIPAÇÃO
															<FontAwesomeIcon icon={faCheck} />
														</button>
													</div>
												</div>
											</BetterCircularProgress>
										</div>
									</Card>
								</form>
							}
						</Formik>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}

export default NewAnticipation;