import axios from "axios";
import { Constants } from '../helpers/Constants';
import { parseQuery } from '../helpers/Utils';

export function listLocations() {
    return axios.get(`${Constants.baseUrl}/crawler/olx/list-locations`);
}

export function listZones(params) {
    return axios.get(`${Constants.baseUrl}/crawler/olx/list-zones-location`, { params: parseQuery(params) });
}
