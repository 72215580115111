import axios from "axios";
import { Constants } from '../helpers/Constants';

const baseUrl = `${Constants.baseUrl}/whatsapp`
// const baseUrl = 'http://localhost:3030/api'


export function getTemplates() {
    return axios.get(`${baseUrl}/workspaces/templates`);
}

export function getNumber() {
    return axios.get(`${baseUrl}/workspaces/numbers`);
}


export function getWorkspace() {
    return axios.get(`${baseUrl}/workspaces`);
}