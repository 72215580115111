import React, { Component } from 'react';
import { Container, Card, CardBody, Row, Col, FormGroup, Label, Button, ButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, Input } from "reactstrap";
import Breadcrumbs from '../../components/Breadcrumb';
import DataTableContracts from '../../components/datatables/DataTableContracts';
import { getAllByFilter } from '../../crud/contracts.crud';
import _ from 'lodash';
import { getByFilter as getUsers } from '../../crud/users.crud';
import { PlansFilterComponent } from '../negotiation/components/plans.component';
import { UserFilterComponentObsolete } from '../negotiation/components/user.component';
import { AnnouncementFilterComponent } from '../negotiation/components/announcement.component';
import { OriginFilterComponent } from '../negotiation/components/origin.component';

class ContractsIndex extends Component {

	state = {
		filters: {
			landlord: '',
			tenant: '',
			announcement: '',
			plan: '',
			status: '',
			origin: '',
			friendly_code: '',
		},
		flags: {
			show_landlord: false,
			show_tenant: false,
			show_announcement: false,
			show_plan: false,
			show_status: false,
			show_origin: false,
			friendly_code: false
		},
		is_filter_open: false,
		list_users: [],
		termo_landlord: '',
		termo_tenant: '',
		export: false,
	}

	componentDidMount = () => {
		let filters = this.state.filters;
		let flags = this.state.flags;

		if (localStorage.getItem('contractsFilters')) {
			filters = JSON.parse(localStorage.getItem('contractsFilters'));
		};

		if (localStorage.getItem('contractsFlags')) {
			flags = JSON.parse(localStorage.getItem('contractsFlags'));
		};

		this.setState({ filters, flags });
	};

	saveFilters = () => {
		localStorage.setItem('contractsFilters', JSON.stringify(this.state.filters));
		localStorage.setItem('contractsFlags', JSON.stringify(this.state.flags));
	};

	fetchByFilter = (data) => {
		let params = {
			...data,
			details: {
				...(data.details ? data.details : {}),
				place: this.state.flags.show_announcement ? this.state.filters.announcement : undefined,
				warranty_type: this.state.flags.show_plan ? this.state.filters.warranty_type : undefined,
			},
			landlord_id: this.state.flags.show_landlord ? this.state.filters.landlord.id : undefined,
			tenant_id: this.state.flags.show_tenant ? this.state.filters.tenant.id : undefined,
		};

		if (this.state.flags.show_origin) params.origin = this.state.filters.origin;

		if (this.state.flags.friendly_code && this.state.filters.friendly_code) params.friendly_code = this.state.filters.friendly_code;

		if (this.state.flags.show_status && this.state.filters.status) {
			if (this.state.filters.status === 'signed') {
				params.signed_eq = true;
				params.is_terminated_not = true;
			} else if (this.state.filters.status === 'not_signed') {
				params.signed_ne = true;
				params.is_terminated_not = true;
			} else if (this.state.filters.status === 'terminated') {
				params.is_terminated_eq = true;
			} else if (this.state.filters.status === 'schedule-termination') {
				params.scheduled_termination_date_not = 'null';
				params.is_terminated_not = true;
			} else if (this.state.filters.status === 'waiting-extrajudicial') {
				params.termination_status = 'AWAITING_COURT_APPROVAL';
			}
		}

		return getAllByFilter(params);
	};

	onTenantInputChange = (termo) => {
		let filters = { ...this.state.filters };

		if (termo == '') {
			filters.tenant = '';
		}

		this.setState({ termo_tenant: termo, filters });
		this.onChangeTenant();
	}

	onChangeTenant = _.debounce(() => {
		this.buscarUsuarios(this.state.termo_tenant);
	}, 500);

	onLandlordInputChange = (termo) => {
		let filters = { ...this.state.filters };

		if (termo == '') {
			filters.landlord = '';
		}

		this.setState({ termo_landlord: termo, filters });
		this.onChangeLandlord();
	}

	onChangeLandlord = _.debounce(() => {
		this.buscarUsuarios(this.state.termo_landlord);
	}, 500);

	buscarUsuarios = (termo) => {
		if (termo.trim() == '') {
			this.setState({ list_users: [] });
		} else {
			getUsers({ $limit: 20, $skip: 0, name_ilike: termo }).then(res => {
				this.setState({ list_users: res.data.data });
			});
		}
	}

	getUserName = (option) => {
		if (option) {
			return option.name;
		}

		return '';
	}

	render() {
		return (
			<React.Fragment>
				<div className="page-content container-page">
					<Container fluid>
						<Breadcrumbs breadcrumbItems={[{ link: '/contracts/index', title: 'Contrato' }]} />

						<Row className="align-right mb-30">
							<ButtonDropdown className="mr-10 wm-100" isOpen={this.state.is_filter_open} toggle={() => this.setState({ is_filter_open: !this.state.is_filter_open })} >
								<DropdownToggle color="primary" caret>
									<i className="ri-filter-3-line align-middle mr-2"></i> {"ADD FILTRO"}
								</DropdownToggle>
								<DropdownMenu className="btn-index-action dropdown-menu-right">
									{!this.state.flags.friendly_code ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, friendly_code: true } })}>Código Sequencial</DropdownItem> : null}
									{!this.state.flags.show_landlord ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_landlord: true } })}>Proprietário</DropdownItem> : null}
									{!this.state.flags.show_tenant ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_tenant: true } })}>Inquilino</DropdownItem> : null}
									{!this.state.flags.show_announcement ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_announcement: true } })}>Cidade</DropdownItem> : null}
									{!this.state.flags.show_plan ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_plan: true } })}>Plano</DropdownItem> : null}
									{!this.state.flags.show_status ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_status: true } })}>Status</DropdownItem> : null}
									{!this.state.flags.show_origin ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_origin: true } })}>Origem</DropdownItem> : null}
								</DropdownMenu>
							</ButtonDropdown>
							{/* <Button color="primary mr-3" onClick={() => { this.setState({ export: true }) }}>
                                EXPORTAR
                            </Button> */}
						</Row>

						<Row className="container-center-input">
							{this.state.flags.show_landlord ?
								<UserFilterComponentObsolete
									label="Proprietário"
									onChange={(e, value) => {
										this.setState({ filters: { ...this.state.filters, landlord: value } });
										this.saveFilters();
									}}
									value={this.state.filters.landlord}
									onClick={
										() => this.setState(
											{
												flags: { ...this.state.flags, show_landlord: false },
												filters: { ...this.state.filters, landlord: {} }
											},
											() => this.saveFilters()
										)
									}
								/>
								: false
							}

							{this.state.flags.show_tenant ?
								<UserFilterComponentObsolete
									label="Inquilino"
									onChange={(e, value) => {
										this.setState({ filters: { ...this.state.filters, tenant: value } });
										this.saveFilters();
									}}
									value={this.state.filters.tenant}
									onClick={
										() => this.setState(
											{
												flags: { ...this.state.flags, show_tenant: false },
												filters: { ...this.state.filters, tenant: {} }
											},
											() => this.saveFilters()
										)
									}
								/>
								: false
							}

							{this.state.flags.friendly_code ?
								<Col md={6}>
									<Label className="col-form-label">Código Sequencial</Label>
									<FormGroup row>
										<div className="col-10 col-sm-8 container-center-input">
											<Input className="form-control" type="text" value={this.state.filters.friendly_code}
												onChange={(e) => this.setState({ filters: { ...this.state.filters, friendly_code: e.target.value } }, () => this.saveFilters())} />
										</div>
										<Button onClick={() => this.setState({ flags: { ...this.state.flags, friendly_code: false }, filters: { ...this.state.filters, friendly_code: '' } }, () => this.saveFilters())} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
											<i className="ri-close-circle-line align-middle"></i>
										</Button>
									</FormGroup>
								</Col>
								: false
							}

							{this.state.flags.show_origin ?
								<OriginFilterComponent
									label="Origem"
									onChange={(e, value) => {
										this.setState({ filters: { ...this.state.filters, origin: e.target.value } });
										this.saveFilters();
									}}
									value={this.state.filters.origin}
									onClick={
										() => this.setState(
											{
												flags: { ...this.state.flags, show_origin: false },
												filters: { ...this.state.filters, origin: undefined }
											},
											() => this.saveFilters()
										)
									}
								/>
								: false
							}

							{this.state.flags.show_announcement ?
								<AnnouncementFilterComponent
									label="Cidade"
									onChange={(e, value) => {
										this.setState({ filters: { ...this.state.filters, announcement: value } });
										this.saveFilters();
									}}
									value={this.state.filters.announcement}
									onClick={
										() => this.setState(
											{
												flags: { ...this.state.flags, show_announcement: false },
												filters: { ...this.state.filters, announcement: undefined }
											},
											() => this.saveFilters()
										)
									}
								/>
								: false
							}

							{this.state.flags.show_plan ?
								< PlansFilterComponent
									label="Plano"
									onChange={(e) => {
										this.setState({ filters: { ...this.state.filters, warranty_type: e.target.value } });
										this.saveFilters();
									}}
									value={this.state.filters.warranty_type}
									onClick={
										() => this.setState(
											{
												flags: { ...this.state.flags, show_plan: false },
												filters: { ...this.state.filters, warranty_type: undefined }
											},
											() => this.saveFilters()
										)
									}
								/>
								: false
							}

							{this.state.flags.show_status ?
								<Col md={4}>
									<Label className="col-form-label" style={{ color: '#343A40' }}>
										Status
									</Label>
									<FormGroup row>
										<Col>
											<select value={this.state.filters.status} onChange={(e) => this.setState({ filters: { ...this.state.filters, status: e.target.value } }, () => this.saveFilters())}
												className="custom-select">
												<option value=""></option>
												<option value="signed">Assinado</option>
												<option value="not_signed">Não assinado</option>
												<option value="terminated">Rescindido</option>
												<option value="schedule-termination">Rescisão agendada</option>
												<option value="waiting-extrajudicial">Aguardando jurídico</option>
											</select>
										</Col>
										<Button onClick={() => this.setState({ flags: { ...this.state.flags, show_status: false }, filters: { ...this.state.filters, plan: '' } }, () => this.saveFilters())} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
											<i className="ri-close-circle-line align-middle"></i>
										</Button>
									</FormGroup>
								</Col>
								: false
							}

							<Col lg={12}>
								<Card>
									<CardBody>
										<DataTableContracts filters={this.state.filters} noDelete noEdit fetchEvent={this.fetchByFilter} crudButtons={true}
											crudUrl="/contracts" idRow={`id`} noShow export={this.state.export} />
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		)
	}
}

export default ContractsIndex;