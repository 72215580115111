import React, { Component } from 'react';
import { Col, FormGroup, Label, TabPane, Row } from "reactstrap";
import BetterCircularProgress from '../../components/BetterCircularProgress';
import { Link, Redirect } from "react-router-dom";
import { Formik } from "formik";
import BetterAlerts from '../../components/BetterAlerts';
import Breadcrumbs from '../../components/Breadcrumb';
import moment from 'moment';
import { get as getUser } from '../../crud/users.crud';
import { formatBRL, limparMoeda, } from '../../helpers/Utils';
import { InputAdornment, TextField } from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { UserComponentDefault as UserComponent } from '../negotiation/components/user.component';
import { get, updateCommissionOnContract } from '../../crud/contracts.crud';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { get as getBillings } from '../../crud/billings.crud';

class UpdateCommissions extends Component {

	state = {
		loading: false,
		submitted: false,
		success: false,
		errors: [],
		data: null,
		coupon: null,
		commissioningParticipants: null,
		oldParticipants: null,
		addParticipantArray: [],
		comissionamento: false,
		nextBill: null,
		responsableRole: 'landlord'
	}

	componentDidMount = async () => {
		this.setState({ loading: true });

		await get(this.props.match.params.id).catch(err => { }).then(async (res) => {
			if (res?.status == 200) {
				if (res.data.data) {
					let contractUsers = res.data.data?.entity?.contract_users.filter(u => u.user_type !== 'tenant');
					let users = [];

					for (let user of contractUsers) {
						const { data: uData } = await getUser(user.user_id).catch(err => { });

						if (user?.user_type === 'admin') {
							this.setState({ responsableRole: 'admin' })
						}

						users.push({
							...user,
							additional_values: {
								first_month_payment: user.additional_values?.first_month_payment,
								first_month_value: formatBRL(user.additional_values?.first_month_value),
							},
							commission_percentage: user?.commission_percentage ?? 100,
							verified: true,
							user: {
								...uData?.data,
							}
						})
					}

					users.sort((a, b) =>
						['admin', 'landlord', 'commissioned'].indexOf(a.user_type) -
						['admin', 'landlord', 'commissioned'].indexOf(b.user_type)
					)

					this.setState({ commissioningParticipants: users, oldParticipants: users, data: res.data.data, coupon: res.data.data?.entity?.coupon });
				}
			}
		})

		await getBillings({ contract_id: this.props.match.params.id, due_date_gte: moment().format('YYYY-MM-DD'), is_paid: false, $sort: { 'due_date': '1' }, $limit: 1 }).catch(err => { }).then(async (res) => {
			if (res?.status === 200) {
				this.setState({ nextBill: res.data.data[0] })
			}
		})

		this.setState({ loading: false });
	};

	onSubmit = async (values) => {
		this.setState(({ submitted: true }));

		await this.onSubmitComissionado(values)

		this.setState(({ submitted: false }));
	};

	onSubmitComissionado = async (values) => {
		this.setState({ loading: true });

		const submitDto = []
		let percentageChecked = 0;
		let firstValueChecked = 0;

		for (const contractUser of [...this.state.commissioningParticipants, ...this.state.addParticipantArray]) {
			const user_id = contractUser.user_id;
			const user_type = contractUser.user_type;
			const commission_percentage = parseFloat(contractUser.commission_percentage);
			const additional_values = contractUser.additional_values;
			const first_month_value = parseFloat(limparMoeda(additional_values?.first_month_value))
			const first_month_payment = first_month_value > 0 ? true : false;

			submitDto.push({ user_id, user_type, commission_percentage, additional_values: { first_month_payment, first_month_value: first_month_value }, contract_id: this.props.match.params.id });

			percentageChecked += parseFloat(commission_percentage);
			firstValueChecked += first_month_payment ? first_month_value : 0;
		}

		if (percentageChecked < 100) {
			const responsable = submitDto.find(obj => obj.user_type === 'admin') ? 'admin' : 'landlord';
			submitDto.find(obj => obj.user_type === responsable).commission_percentage = parseFloat(submitDto.find(obj => obj.user_type === responsable).commission_percentage) + (100 - percentageChecked);
		}

		if (percentageChecked > 100) {
			this.setState({ errors: ['A soma das comissões não pode ser maior que 100%'] })
			this.setState({ loading: false });
			return;
		}

		await updateCommissionOnContract(this.props.match.params.id, submitDto).catch(err => {
			if (err.response?.data?.message === 'First payment sum is greater then rent') {
				this.setState({ errors: ['A soma dos valores do primeiro aluguel não pode ser maior que o valor do aluguel!', 'Por favor, lembre-se de que o valor máximo permitido corresponde ao valor do aluguel subtraído da porcentagem destinada à Mellro.'] })
			} else {
				this.setState({ errors: ['Erro ao salvar dados de comissões!', err.response?.data?.message] })
			}
		}).then(async (res) => {
			if (res?.status === 200) {
				this.setState({ success: true })
			}
		})

		this.setState({ loading: false });
	};

	getInitialValues = () => {
		return {
		};
	};

	valorGarantia = (tipoGarantia) => {
		switch (tipoGarantia) {
			case 'WARRANTY':
				return 0.07;
			case 'WARRANTY_GUARANTEE_DAMAGE':
				return 0.1;
			default:
				return 0.1;
		}
	};

	handleChangeValorAdicional = (e, participant, addNew) => {
		if (addNew) {
			this.state?.addParticipantArray.map((val, i) => {
				if (val.id === participant.id) {
					const updatedObject = { ...val, additional_values: { ...val.additional_values, first_month_payment: !e.target.value } };
					const updatedArray = [...this.state?.addParticipantArray];
					updatedArray[i] = updatedObject;

					this.setState({ addParticipantArray: updatedArray })
					return true;
				}
			})
		} else {
			this.state?.commissioningParticipants.map((val, i) => {
				if (val.id === participant.id) {
					const updatedObject = { ...val, additional_values: { ...val.additional_values, first_month_payment: !e.target.value } };
					const updatedArray = [...this.state?.commissioningParticipants];
					updatedArray[i] = updatedObject;

					this.setState({ commissioningParticipants: updatedArray })
					return true;
				}
			})
		}

	};

	handleChangePrimeiroAluguelValue = (e, participant, addNew) => {
		e.target.value = Number(e.target.value.toString().replace(/[^0-9-]/g, '')) / 10 ** 2;

		e.target.value = formatBRL(e.target.value);

		if (addNew) {
			this.state?.addParticipantArray.map((val, i) => {
				if (val.id === participant.id) {
					const updatedObject = { ...val, additional_values: { ...val.additional_values, first_month_value: e.target.value } };
					const updatedArray = [...this.state?.addParticipantArray];
					updatedArray[i] = updatedObject;
					this.setState({ addParticipantArray: updatedArray })
					return true;
				}
			})
		} else {
			this.state?.commissioningParticipants.map((val, i) => {
				if (val.id === participant.id) {
					const updatedObject = { ...val, additional_values: { ...val.additional_values, first_month_value: e.target.value } };
					const updatedArray = [...this.state?.commissioningParticipants];
					updatedArray[i] = updatedObject;
					this.setState({ commissioningParticipants: updatedArray })
					return true;
				}
			})
		}
	}

	handleSetFunction = (e, participant, addNew) => {
		if (addNew) {
			this.state?.addParticipantArray.map((val, i) => {
				if (val.id === participant.id) {
					const updatedObject = { ...val, user_role: e.target.value };
					const updatedArray = [...this.state?.addParticipantArray];
					updatedArray[i] = updatedObject;
					this.setState({ addParticipantArray: updatedArray })
					return true;
				}
			})
		} else {
			this.state?.commissioningParticipants.map((val, i) => {
				if (val.id === participant.id) {
					const updatedObject = { ...val, user_role: e.target.value };
					const updatedArray = [...this.state?.commissioningParticipants];
					updatedArray[i] = updatedObject;
					this.setState({ commissioningParticipants: updatedArray })
					return true;
				}
			})
		}
	}

	getMonthValue = (participant) => {
		const rent = this.state.data?.entity?.details?.values?.rent;
		let finalPercentage = this.valorGarantia(this.state.data?.entity?.plan?.name);
		const comissao = parseFloat(participant.commission_percentage) / 100;

		if (this.state.coupon) {
			finalPercentage = (finalPercentage - (Number(this.state.coupon?.discount_amount) / 100)).toFixed(2);
		}

		return formatBRL((rent - (rent * finalPercentage)) * comissao);
	}

	removeParticipant = (index) => {
		let prevArr = this.state.commissioningParticipants.slice();
		const indice = this.state.commissioningParticipants[index];
		if (indice !== -1) {
			prevArr.splice(indice, 1);
		}
		this.setState({ commissioningParticipants: prevArr })
	}

	removeNewParticipant = (index) => {
		let prevArr = this.state.addParticipantArray.slice();
		const indice = this.state.addParticipantArray[index];
		if (indice !== -1) {
			prevArr.splice(indice, 1);
		}
		this.setState({ addParticipantArray: prevArr })
	}

	addParticipant = () => {
		const defaultObj = {
			"user_id": null,
			"contract_id": this.props.match.params.id,
			"user_type": "commissioned",
			"additional_values": {
				"first_month_payment": false,
				"first_month_value": 'R$ 0,00'
			},
			"commission_percentage": 0,
			"verified": true
		}
		const prevArr = [...this.state.addParticipantArray];
		prevArr.push(defaultObj);
		this.setState({ addParticipantArray: prevArr })
	}

	render() {
		return (
			<BetterCircularProgress loading={this.state.loading}>
				{this.state.data ?
					<div className="page-content container-page userNew container-fluid anuncio">
						<BetterAlerts errors={this.state.errors} />

						{this.state.success ?
							<Redirect to={{ pathname: `/negotiation/show/${this.state.data?.entity?.proposition_id}`, state: { success: ['Comissionamento atualizado com sucesso!'] } }} />
							: null
						}

						<Formik initialValues={this.getInitialValues()} validate={values => { }} onSubmit={(values) => this.onSubmit(values)}>
							{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
								<form noValidate={true} autoComplete="off" onSubmit={handleSubmit} className="form" style={{ flexDirection: 'column', alignItems: 'center' }}>
									<div style={{ width: '60%' }}>
										<div className='header'>
											<h1>Editar comissões</h1>

											<Breadcrumbs breadcrumbItems={[{ link: '/negotiation', title: 'Negociações' }, { link: '/negotiation', title: 'Negociações e contratos' }, { link: `/negotiation/show/${this.state.data?.entity?.proposition_id}`, title: `ID ${this.props.match.params.id.slice(0, 5)}...` }, { link: `#`, title: `Editar comissões` }]} />
										</div>

										<div className='box'>
											<TabPane tabId="6" role="tabpanel">
												<div className='formulario'>
													<div>

														<p className='p-type-header m-0 p-0'>COMISSÕES</p>

														<p style={{ fontSize: 13, color: '#343A40', margin: '24px 0 0 0' }}>
															<FontAwesomeIcon icon={faCircleExclamation} style={{ color: '#EFA200', marginRight: 4 }} />
															Este contrato está vigente. A edição de comissões será aplicada neste contrato em pagamentos futuros.
														</p>


														<p style={{ fontSize: 13, color: '#343A40', margin: ' 0' }}>
															<span style={{ fontWeight: 900 }}>Pŕoximo pagamento:</span>
															{this.state.nextBill ? moment(this.state.nextBill?.due_date).add(3, 'hours').format('DD/MM/YYYY') : 'Não encontrado'}
														</p>

													</div>

													<hr style={{ height: '1px', color: '#DADBDD', width: 'calc(100% + 49px)', marginLeft: '-24px', marginTop: 0, marginBottom: 4 }} />

													<button onClick={() => this.addParticipant()} type="button" className='button-primary' style={{ width: 197, height: 38 }}>
														NOVO COMISSIONADO
														<i className="fa fa-check" />
													</button>

													{this.state?.commissioningParticipants ? this.state?.commissioningParticipants?.map((participant, i) => (
														<div className="p-3" style={{ background: '#F1F5F7', height: 'auto', borderRadius: '5px' }}>
															<Row>
																<Col md={6} className="d-flex" style={{ alignItems: 'center' }}>
																	<FormGroup className="w-100">
																		<Label className="col-form-label" >Usuário</Label>
																		<TextField disabled style={{ background: "#FFF", height: "40px", marginRight: 16 }} variant="outlined" value={participant.user.name} onChange={(e) => { }} />
																	</FormGroup>
																</Col>

																<Col md={5}>
																	<FormGroup className="w-100">
																		<Label className="col-form-label" >Função</Label>
																		<select disabled={participant.user_type === this.state.responsableRole} style={{ background: "#FFF", height: "40px" }} name='user_type' onBlur={handleBlur} value={participant.user_type}
																			className="custom-select" onChange={(e) => this.handleSetFunction(e, participant)}>
																			{this.state.responsableRole === 'admin' && participant.user_type === 'admin' ? (
																				<option key="admin" value='admin'>Administrador</option>
																			) : null}
																			<option key="commissioned" value='commissioned' >Comissionado</option>

																			{this.state.responsableRole === 'landlord' && participant.user_type !== 'landlord' ? null : (
																				<option key="landlord" value='landlord' >Dono</option>
																			)}
																		</select>
																	</FormGroup>
																</Col>

																{participant === 'admin' || (!this.state.commissioningParticipants.find(obj => obj.user_type === 'admin') && participant.user_type === 'landlord') ? null : (
																	<Col md={1}>
																		<FontAwesomeIcon onClick={() => this.removeParticipant(i)} icon={faTimes} className="close-icon" />
																	</Col>
																)}

																{/* <Col lg={12} className="p-0 m-0">
																	<div className='d-flex w-100' style={{ alignItems: 'center' }} >
																		<Checkbox id={'valorAdicional'} color="primary" onChange={(e) => this.handleChangeValorAdicional(e, participant)} checked={participant.additional_values.first_month_payment} />
																		<Label className='mb-0' style={{ padding: "3px 3px 0 3px" }} htmlFor={'valorAdicional'}>Receberá valor do primeiro aluguel</Label>
																	</div>
																</Col> */}

																<Col lg={6}>
																	<FormGroup className="w-100">
																		<Label className="col-form-label" style={{ color: "#343A40" }}>
																			Comissão do primeiro aluguel
																		</Label>
																		<TextField style={{ background: "#FFF", height: "40px" }} name="first_month_value" variant="outlined"
																			value={!participant.additional_values.first_month_value ? 0 : participant.additional_values.first_month_value}
																			// InputProps={{ startAdornment: <InputAdornment position='start'>R$</InputAdornment> }}
																			onBlur={handleBlur} placeholder="Digite o valor..." onChange={(e) => this.handleChangePrimeiroAluguelValue(e, participant)}
																		/>
																	</FormGroup>
																</Col>

																<Col lg={3} className="">
																	<FormGroup>
																		<Label className="col-form-label" style={{ color: "#343A40" }}>
																			% do aluguel mensal
																		</Label>
																		<TextField style={{ background: "#FFF", height: "40px" }} name="commission_percentage" variant="outlined" value={participant.commission_percentage}
																			onBlur={handleBlur} placeholder="Digite o valor..." InputProps={{ endAdornment: <InputAdornment position='end'>%</InputAdornment> }} onChange={(e) => {
																				this.state?.commissioningParticipants?.map((val, i) => {
																					if (val.id === participant.id) {
																						const updatedObject = { ...val, commission_percentage: e.target.value };
																						const updatedArray = [...this.state?.commissioningParticipants];
																						updatedArray[i] = updatedObject;
																						this.setState({ commissioningParticipants: updatedArray })
																						return true;
																					}
																				})
																			}}
																		/>
																	</FormGroup>
																</Col>

																<Col lg={3} className="p-0 m-0 d-flex align-items-center">
																	<Label className="col-form-label" style={{ color: "#343A40", paddingTop: '35px' }}>
																		Equivale a  <br />
																		<label style={{ color: "#20C997" }}>
																			{participant?.commission_percentage ? this.getMonthValue(participant) : 'R$ 0,00'}
																		</label> mensais
																	</Label>
																</Col>
															</Row>
														</div>
													)) : null}

													{this.state.addParticipantArray.length > 0 ? (<>
														{this.state.addParticipantArray.map((participant, i) => (
															<>
																<div className="p-4" style={{ background: '#F1F5F7', height: 'auto', borderRadius: '5px' }}>
																	<Row>
																		<Col md={6} className="d-flex" style={{ alignItems: 'center' }}>
																			<FormGroup className="w-100">
																				<Label className="col-form-label" >Usuário</Label>
																				<UserComponent name="user" onBlur={handleBlur} onChange={(e, value) => {
																					this.state?.addParticipantArray.map((val) => {
																						const updatedObject = { ...val, user_id: value.id, verified: value?.verified ? true : false };
																						const updatedArray = [...this.state?.addParticipantArray];
																						updatedArray[i] = updatedObject;
																						this.setState({ addParticipantArray: updatedArray })
																					})
																				}}
																				/>
																				{participant.verified ? null : <p style={{ color: '#FF0000', fontSize: 12, margin: 0 }}>O usuário selecionado precisa de perfil verificado.</p>}
																			</FormGroup>
																		</Col>

																		<Col md={5}>
																			<FormGroup className="w-100">
																				<Label className="col-form-label" >Função</Label>
																				<select style={{ background: "#FFF", height: "40px" }} name='user_type' onBlur={handleBlur} value={participant.user_type}
																					className="custom-select" onChange={(e) => this.handleSetFunction(e, participant, true)}>
																					{/* <option key="admin" value='admin'>Administrador</option> */}
																					<option key="commissioned" value='commissioned' >Comissionado</option>

																					{this.state.commissioningParticipants.find(obj => obj.user_type === 'landlord') || this.state.addParticipantArray.find(obj => obj.user_type === 'landlord') ? null : (
																						<option key="landlord" value='landlord' >Dono</option>
																					)}

																				</select>
																			</FormGroup>
																		</Col>

																		<Col md={1}>
																			<FontAwesomeIcon onClick={() => this.removeNewParticipant(participant.id)} icon={faTimes} className="close-icon" />
																		</Col>

																		{/* <Col lg={12} className="p-0 m-0">
																			<div className='d-flex w-100' style={{ alignItems: 'center' }} >
																				<Checkbox id={'valorAdicional'} color="primary" onChange={(e) => this.handleChangeValorAdicional(e, participant, true)} checked={participant.additional_values.first_month_payment} />
																				<Label className='mb-0' style={{ padding: "3px 3px 0 3px" }} htmlFor={'valorAdicional'}>Receberá valor do primeiro aluguel</Label>
																			</div>
																		</Col> */}

																		<Col lg={6}>
																			<FormGroup className="w-100">
																				<Label className="col-form-label" style={{ color: "#343A40" }}>
																					Comissão do primeiro aluguel
																				</Label>
																				<TextField style={{ background: "#FFF", height: "40px" }} name="first_month_value" variant="outlined"
																					value={!participant.additional_values.first_month_value ? 0 : participant.additional_values.first_month_value}
																					// InputProps={{ startAdornment: <InputAdornment position='start'>R$</InputAdornment> }}
																					onBlur={handleBlur} placeholder="Digite o valor..." onChange={(e) => this.handleChangePrimeiroAluguelValue(e, participant, true)}
																				/>
																			</FormGroup>
																		</Col>

																		<Col lg={3} className="">
																			<FormGroup>
																				<Label className="col-form-label" style={{ color: "#343A40" }}>
																					% do aluguel mensal
																				</Label>
																				<TextField style={{ background: "#FFF", height: "40px" }} name="commission_percentage" variant="outlined" value={participant.commission_percentage}
																					onBlur={handleBlur} placeholder="Digite o valor..." onChange={(e) => {
																						this.state?.addParticipantArray?.map((val, i) => {
																							if (val.id === participant.id) {
																								const updatedObject = { ...val, commission_percentage: e.target.value };
																								const updatedArray = [...this.state?.addParticipantArray];
																								updatedArray[i] = updatedObject;
																								this.setState({ addParticipantArray: updatedArray })
																								return true;
																							}
																						})
																					}}
																				/>
																			</FormGroup>
																		</Col>

																		<Col lg={3} className="p-0 m-0 d-flex align-items-center">
																			<Label className="col-form-label" style={{ color: "#343A40", paddingTop: '35px' }}>
																				Equivale a  <br />
																				<label style={{ color: "#20C997" }}>
																					{participant?.commission_percentage ? this.getMonthValue(participant) : 'R$ 0,00'}
																				</label> mensais
																			</Label>
																		</Col>
																	</Row>
																</div>
															</>
														))}
													</>) : null}
												</div>
											</TabPane>

										</div>
									</div>

									<div className='box-footer' style={{ width: '60%' }}>
										<Link to={`/negotiation/show/${this.state?.data?.entity?.proposition_id}`} className='button-secundary'>
											<i className="fa fa-arrow-left" />
											VOLTAR
										</Link>

										<button type="submit" className='button-primary' disabled={this.state.submitted} style={{ width: 95 }}>
											{this.state.submitted ?
												<>
													<i className="fas fa-sync fa-spin" />
												</> :
												<>
													Salvar
													<i className="fa fa-check" />
												</>
											}
										</button>
									</div>
								</form>
							)}
						</Formik>
					</div> : null}
			</BetterCircularProgress>
		)
	}
}

export default UpdateCommissions;