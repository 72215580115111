import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import _ from 'lodash';
import moment from 'moment';
import { get as getBillings, listRepayments } from '../../../crud/billings.crud';
import { formatBRL } from '../../../helpers/Utils';
import { Link } from 'react-router-dom';
import { get as getUser } from '../../../crud/users.crud';
import { faUserMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import 'moment/locale/pt-br';

moment.locale('pt-br');
export default class DataTableBillingsAnnouncements extends Component {
	constructor() {
		super();

		this.state = {
			data: [],
			rows: 10,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: 'due_date',
			sortOrder: 1,
			boletoExists: false,
			boletoError: false,
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		this.setState({
			loading: true
		});

		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters != this.props.filters) {
			this.onFilterChanged();
		}
	}

	onFilterChanged = _.debounce(() => {
		if (!this.state.isLoading) {
			this.fetchData();
		}
	}, 700);

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = async (event) => {
		if (event.data) {
			event.data = null;
		}

		this.setState({
			...this.state,
			rows: event.rows,
			loading: true
		});

		this.fetchData(event.rows, event.first);
	};

	fetchData = (limit, skip) => {
		let params = {
			property_announcement_id: this.props.property_announcement_id,
			$limit: limit,
			$skip: skip != null ? skip : this.state.first,
			$sort: { [this.state.sortField]: this.state.sortOrder }
		};

		params.type = this.props.paymentType;

		getBillings(params).then(res => {
			res.data.data.map(async (element, index) => {
				let paramsReembolso = {
					billing_id: element?.id,
					$limit: 50,
				};

				let number = 0;

				if (element.landlord_id) {
					element.loading_landlord = true;
					await getUser(element.landlord_id).catch(err => {
						element.landlord = null;
						element.loading_landlord = false;
					}).then(res => {
						if (res?.data?.data) {
							element.landlord = res.data.data;
						}
					});
				}

				if (element.tenant_id) {
					element.loading_tenant = true;
					await getUser(element.tenant_id).catch(err => {
						element.tenant = null;
						element.loading_tenant = false;
					}).then(res => {
						if (res?.data?.data) {
							element.tenant = res.data.data;
						}
					});
				}


				res.data.data.map((billingMap) => {
					if (billingMap.type === 'termination') {
						number++;
						billingMap.billingNumeroParcela = number;
					}
				});

				listRepayments(paramsReembolso).then(({ data }) => {
					let amount = 0;

					if (data.data.length > 0) {
						data.data.map((reembolso, index) => {
							if (reembolso?.status == "accepted") {
								if (reembolso?.type == "addition") {
									amount += Number(reembolso?.amount);
								} else if (reembolso?.type == "repayment") {
									amount -= Number(reembolso?.amount);
								}
							}
						});
					}

					element.receipts.rent.value += amount;
				}).catch(({ response }) => {
				}).finally(() => {
					this.setState({
						...this.state,
						data: res.data.data,
						totalRecords: res.data.total,
						first: res.data.skip,
						last: res.data.skip + res.data.limit,
					});
				});
			});
		}).finally(() => {
			this.setState({
				loading: false
			})
		});
	};

	getValueIptu = (obj) => {
		return (
			<div>
				<p className="col-form-label">{obj?.receipts?.iptu?.value ? formatBRL(obj?.receipts?.iptu?.value) : 'R$ -'}</p>
			</div>
		);
	};

	getValueCondo = (obj) => {
		return (
			<div>
				<p className="col-form-label">{obj?.receipts?.condo?.value ? formatBRL(obj?.receipts?.condo?.value) : 'R$ -'}</p>
			</div>
		);
	};

	getValueRent = (obj) => {
		return (
			<div>
				<p className="col-form-label">{obj.type == 'termination' ? formatBRL(obj.values.value) : obj?.receipts?.rent?.value ? formatBRL(obj?.receipts?.rent?.value) : 'R$ -'}</p>
			</div>
		);
	};

	getStatus = (obj) => {
		const momentDate = moment(obj?.due_date).add(3, 'hours');

		const daysToAdd = momentDate.day() === 6 ? 2 : momentDate.day() === 0 ? 1 : 0;

		const dueDate = moment(obj?.due_date).add(daysToAdd, 'days').add(3, "hours").format('YYYYMMDD');
		const currentDate = moment().format('YYYYMMDD');

		const friendlyAgreement = this.props?.agreement;

		if ((friendlyAgreement && friendlyAgreement?.overdue_billings.includes(obj?.id)) || obj?.receipts?.rent?.agreement_settled) {
			return (
				<div className=" d-flex flex-row" style={{ width: '128px', fontSize: '13px', lineHeight: '14px', height: 'auto', alignItems: 'center', backgroundColor: obj?.receipts?.rent?.paid ? '#1CBB8C' : '#4AA3FF' }}>
					<p className="col-form-label text-white" >{obj?.receipts?.rent?.paid && obj?.receipts?.rent?.agreement_settled ? 'Acordo quitado' : 'Acordo vigente'}</p>
				</div>
			)
		}

		return (
			obj?.receipts?.rent?.paid === true && (obj?.receipts?.rent?.paid_for_insurer === false || !obj?.receipts?.rent?.paid_for_insurer) ?
				<div className="bg-success d-flex flex-row" style={{ width: '98px', fontSize: '13px', lineHeight: '14px', height: 'auto', alignItems: 'center' }}>
					<p className="col-form-label text-white" >{obj?.receipts?.rent?.paid ? 'Fatura paga' : 'N/A'}</p>
				</div> :
				obj?.receipts?.rent?.paid_for_insurer === true && obj?.receipts?.rent?.paid === true ?
					<div className="bg-primary d-flex flex-row" style={{ width: '140px', fontSize: '13px', lineHeight: '14px', height: 'auto', alignItems: 'center' }}>
						<p className="col-form-label text-white" >Garantido e pago</p>
					</div> : obj?.receipts?.rent?.paid_for_insurer === true && obj?.receipts?.rent?.paid === false ?
						<div className="d-flex flex-row" style={{ width: '140px', fontSize: '13px', lineHeight: '14px', height: 'auto', alignItems: 'center', backgroundColor: "#F06595" }}>
							<p className="col-form-label text-white" >Garantido e atrasado</p>
						</div> :
						(currentDate > dueDate) ?
							<div className="bg-danger d-flex flex-row" style={{ width: '120px', fontSize: '13px', lineHeight: '14px', height: 'auto', alignItems: 'center' }}>
								<p className="col-form-label text-white" >Fatura atrasada</p>
							</div> :
							!!obj?.boleto?.pdf_url ?
								<div className="bg-warning d-flex flex-row" style={{ width: '131px', fontSize: '13px', lineHeight: '14px', height: 'auto', alignItems: 'center' }}>
									<p className="col-form-label text-white" >Fatura em aberto</p>
								</div> :
								<div className="bg-secondary d-flex flex-row" style={{ width: '98px', fontSize: '13px', lineHeight: '14px', height: 'auto', alignItems: 'center' }}>
									<p className="col-form-label text-white" >Aguardando</p>
								</div>
		);
	};

	getPaymentType = (billing) => {
		let terminationExists = false;

		if (this.props.terminations && this.props.terminations.length > 0) {
			terminationExists = true;
		}

		if (billing.type === 'monthly' && billing.number === 0) {
			return (
				<div>
					<p className="col-form-label">Primeiro Aluguel</p>
				</div>
			)
		} else if (billing.type === 'agreement') {
			return (
				<div>
					<p className="col-form-label">Acordo - Parcela {billing.number}</p>
				</div>
			)
		} else if (billing.type === 'termination' && terminationExists) {
			return (
				<div>
					<p className="col-form-label">{`Parcela ${billing.billingNumeroParcela} Rescisão: ${billing.user_id == billing.landlord_id ? 'Responsável' : 'Inquilino'}`}</p>
				</div>
			)
		} else if (billing.type === 'termination' && !terminationExists) {
			return (
				<div>
					<p className="col-form-label">{`Vistoria`}</p>
				</div>
			)
		} else {
			return (
				<div>
					<p className="col-form-label">Aluguel mensal</p>
				</div>
			)
		}

	}

	columnOpcoesCrud = (rowData) => {
		let idRow = this.props.idRow ? this.props.idRow : 'id';

		if (this.props.crudUrl) {
			return (
				<Link to={`${this.props.crudUrl}/show/${rowData[idRow]}`} className='showButton'>
					<i className='fa fa-chevron-right' />
				</Link>
			);
		} else {
			return null;
		}
	};

	sortTable = e => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder }, () => {
			let contractsDataTable = {
				rows: this.state.rows,
				totalRecords: this.state.totalRecords,
				first: this.state.first,
				last: this.state.last,
				loading: this.state.loading,
				sortField: this.state.sortField,
				sortOrder: this.state.sortOrder,
			};

			localStorage.setItem('contractsDataTable', JSON.stringify(contractsDataTable));

			this.fetchData();
		});
	};

	getUserName = (obj, type) => {
		if (obj?.tenant?.name && obj?.landlord?.name) {
			return (
				<div className='negotiation-active' style={{ textAlign: 'center' }}>
					<FontAwesomeIcon icon={faUserMagnifyingGlass} style={{ marginRight: '8.5px' }} />

					<Link to={`/users/show/${obj?.tenant?.id}`} >{obj?.tenant?.name},</Link>

					<Link to={`/users/show/${obj?.landlord?.id}`}>{obj?.landlord?.name}</Link>
				</div>
			)
		}
	};

	render() {
		const header = (
			<div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row' }}>
				{this.props.titleTable &&
					<>
						<h3 style={{ margin: 0 }}>{this.props.titleTable}</h3>

						<div style={{ marginLeft: 8 }} className='indicadorUsuarios'>{this.state?.totalRecords ?? 0}</div>
					</>
				}
			</div>
		);

		const paginatorRight = (
			<button onClick={() => { this.fetchData(this.state?.totalRecords); this.setState({ rows: this.state?.totalRecords }) }} className='btnSecundary' style={{ marginLeft: 24 }}>
				Ver todos
			</button>
		);

		return (
			<>
				<DataTable ref={(el) => this.dt = el} value={this.state.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={[10, 25, 50, this.state?.totalRecords]}
					first={this.state.first} last={this.state.last} responsive={true} className="ml-auto" paginatorRight={paginatorRight}
					totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} loadingIcon={`fas fa-sync fa-spin`}
					paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
					currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" emptyMessage="Nenhum registro encontrado!"
					onSort={(e) => this.sortTable(e)} sortField={this.state.sortField} sortOrder={this.state.sortOrder} header={header}>
					<Column style={{ width: 136 }} body={(obj, i) => this.getStatus(obj)} field="status" header="Status" />
					<Column style={{ width: 104 }} body={(obj, i) => obj?.paid_at ? moment.utc(obj?.paid_at).format('DD/MM/YYYY hh:mm:ss') : '--'} field="type" header="Pago em"></Column>
					<Column style={{ width: 118 }} body={(obj, i) => obj?.due_date ? moment.utc(obj?.due_date).format('DD/MM/YYYY') : '--'} field="due_date" header="Vencimento"></Column>
					<Column style={{ width: 109 }} body={(obj, i) => this.getPaymentType(obj)} field="type" header="Transação"></Column>
					<Column style={{ width: 364 }} field="envolvido" body={(obj, i) => this.getUserName(obj)} header="Envolvido" />

					<Column style={{ width: 132 }} body={(obj, i) => this.getValueRent(obj)} field="receipts.receipts.values" header="Valor total" />
					<Column style={{ width: 34 }} header="" body={(obj, i) => this.columnOpcoesCrud(obj)} />
				</DataTable>
			</>
		);
	}
}