import axios from "axios";
import { Constants } from '../helpers/Constants';
import { parseQuery } from '../helpers/Utils';

export function getDashboard(params) {
	return axios.get(`${Constants.baseUrl}/affiliate-conversions/dashboard`, { params: parseQuery(params) });
}

export function getBalance(params) {
	return axios.get(`${Constants.baseUrl}/affiliate-conversions/balance`, { params: parseQuery(params) });
}

export function withdraw(data) {
	return axios.post(`${Constants.baseUrl}/affiliate-conversions/withdraw`, data);
}


//novo afiliado

export function transactionsAfiliado(params) {
	// user_id=user_id
	// destination=affiliate
	return axios.get(`${Constants.baseUrl}/payment/asaas/transfers/queryAll`, { params: parseQuery(params) });
}

export function getContractAffiliate(params) {
	return axios.get(`${Constants.baseUrl}/contracts/contract-users`, { params: parseQuery(params) });
}

export function getByFilterUsersAffiliate(params) {
	return axios.get(`${Constants.baseUrl}/users/get-by-affiliate-id`, { params: parseQuery(params) });
}

export function updateConfigRepasse(id, body) {
	return axios.patch(`${Constants.baseUrl}/users/${id}`, body);
}

export function addUserAffiliate(body) {
	return axios.post(`${Constants.baseUrl}/users/add-affiliate`, body);
}

export function removeUserAffiliate(body) {
	return axios.post(`${Constants.baseUrl}/users/remove-affiliate`, body);
}

export function removeContractAffiliate(id, body) {
	return axios.post(`${Constants.baseUrl}/contracts/remove-affiliate/${id}`, body);
}

export const getAffiliateBalance = (user_id) => {
	return axios.get(`${Constants.baseUrl}/account/affiliate-balance/${user_id}`);
};