import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";
import { FiltersModuleExtrajudicialIndicators, findMetricInfo, sumMetricInfo } from "../filters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical, faEyeSlash, faGripVertical, faMaximize, faMinimize } from '@fortawesome/pro-solid-svg-icons';
import { Checkbox, ListItemText, Menu, MenuItem, } from "@material-ui/core";

export default class AgreementIndicatorSection extends Component {
	constructor() {
		super();

		this.state = {
			metrics: [],
			agreementIndicatorsFilters: {
				"time_frame": "always"
			},
			agreementIndicatorsAvailableLabels: [
				{ label: "ACORDO EM ABERTO", isActive: true },
				{ label: "ACORDOS QUITADOS", isActive: true },
				{ label: "ACORDOS ATRASADOS", isActive: true },
				{ label: "PARCELAS ATRASADAS", isActive: true },
			],
			dropdownKey: null,
			anchorEl: null,
		};
	}

	componentDidMount() {
		if (this?.props?.metrics) {
			this.setState({ metrics: this.props.metrics })
		}
	}

	render() {
		return (
			<Card>
				<CardBody className="dashboard-item">
					<div className="box-header">
						<div className="">
							<h3 className="title">Indicadores de acordo amigável</h3>
							<div className="box-info">
								Jurídico
							</div>
						</div>

						<div>
							<FontAwesomeIcon icon={this.props.layout?.w === 6 ? faMaximize : faMinimize} style={{ color: '#5664D2', padding: '12px', cursor: 'pointer' }} onClick={() => this.props.toggleMaximize(this.props?.itemKey)} />
							<FontAwesomeIcon icon={faEllipsisVertical} style={{ color: '#5664D2', padding: '12px', cursor: 'pointer' }} onClick={(e) => {
								this.setState({ dropdownKey: this.props.itemKey });
								this.setState({ anchorEl: e.currentTarget });
							}} />
							<FontAwesomeIcon icon={faEyeSlash} style={{ color: '#5664D2', padding: '12px', cursor: 'pointer' }} onClick={() => this.props.changeWidgetValue(this.props.itemKey)} />
							<FontAwesomeIcon icon={faGripVertical} style={{ color: '#DADBDD', padding: '12px', cursor: 'pointer' }} />
						</div>
					</div>

					<Menu
						anchorEl={this.state.anchorEl}
						open={this.state.dropdownKey === this.props.itemKey ? true : false}
						onClose={() => { this.setState({ dropdownKey: null }) }}
						className="dashboard-item"
						id="dashboard-drop"
					>
						{this.state.agreementIndicatorsAvailableLabels.map((item) => (
							<MenuItem key={item.label} value={item.label}>
								<Checkbox checked={item.isActive} onChange={() => {
									this.setState(prevState => ({
										agreementIndicatorsAvailableLabels: prevState.agreementIndicatorsAvailableLabels.map(i =>
											i.label === item.label
												? { ...i, isActive: !i.isActive }
												: i
										)
									}));
								}} />
								<ListItemText primary={item.label} />
							</MenuItem>
						))}
					</Menu>


					<div className="box-filter">
						<FiltersModuleExtrajudicialIndicators setState={this.setState.bind(this)} state={this.state.agreementIndicatorsFilters} stateSelected={'agreementIndicatorsFilters'} />
					</div>

					<div style={{ position: 'relative' }}>
						<hr className="divisor" />
					</div>

					<div className="card-wrapper" style={{ marginBottom: 22 }}>
						<div className="card-box" style={{ display: this.state.agreementIndicatorsAvailableLabels.find(obj => obj?.label === 'PARCELAS ATRASADAS')?.isActive ? '' : 'none' }}>
							<h3>ACORDOS <br />CRIADOS</h3>

							<h2>
								<span style={{ fontSize: 13, marginTop: 8 }}>R$</span>
								{findMetricInfo(this.state.metrics, [this.state.agreementIndicatorsFilters.time_frame], 'AGREEMENT_CREATED', 'sum').replace('R$', '')}
							</h2>

							<div style={{ background: '#8590A5' }} className="card-value card-value-red">
								{findMetricInfo(this.state.metrics, [this.state.agreementIndicatorsFilters.time_frame], 'AGREEMENT_CREATED', 'count')} acordos
							</div>

							<div style={{ background: '#8590A5' }} className="card-value card-value-red">
								{findMetricInfo(this.state.metrics, [this.state.agreementIndicatorsFilters.time_frame], 'AGREEMENT_CREATED', 'installments')} parcelas
							</div>
						</div>

						<div className="card-box" style={{ display: this.state.agreementIndicatorsAvailableLabels.find(obj => obj?.label === 'ACORDOS QUITADOS')?.isActive ? '' : 'none' }}>
							<h3>ACORDOS <br />QUITADOS</h3>

							<h2>
								<span style={{ fontSize: 13, marginTop: 8 }}>R$</span>
								{findMetricInfo(this.state.metrics, [this.state.agreementIndicatorsFilters.time_frame], 'AGREEMENT_INDICATOR_SETTLED', 'sum').replace('R$', '')}
							</h2>

							<div className="card-value card-value-green">
								{findMetricInfo(this.state.metrics, [this.state.agreementIndicatorsFilters.time_frame], 'AGREEMENT_INDICATOR_SETTLED', 'count')} acordos
							</div>

							<div className="card-value card-value-green">
								{findMetricInfo(this.state.metrics, [this.state.agreementIndicatorsFilters.time_frame], 'AGREEMENT_INDICATOR_SETTLED', 'installments')} parcelas
							</div>
						</div>

						<div className="card-box" style={{ display: this.state.agreementIndicatorsAvailableLabels.find(obj => obj?.label === 'ACORDO EM ABERTO')?.isActive ? '' : 'none' }}>
							<h3>ACORDO EM <br />ABERTO</h3>

							<h2>
								<span style={{ fontSize: 13, marginTop: 8 }}>R$</span>
								{findMetricInfo(this.state.metrics, [this.state.agreementIndicatorsFilters.time_frame], 'AGREEMENT_INDICATOR_OPEN', 'sum').replace('R$', '')}
							</h2>

							<div className="card-value card-value-yellow">
								{findMetricInfo(this.state.metrics, [this.state.agreementIndicatorsFilters.time_frame], 'AGREEMENT_INDICATOR_OPEN', 'count')} acordos
							</div>

							<div className="card-value card-value-yellow">
								{findMetricInfo(this.state.metrics, [this.state.agreementIndicatorsFilters.time_frame], 'AGREEMENT_INDICATOR_OPEN', 'installments')} parcelas
							</div>
						</div>

						<div className="card-box" style={{ display: this.state.agreementIndicatorsAvailableLabels.find(obj => obj?.label === 'ACORDOS ATRASADOS')?.isActive ? '' : 'none' }}>
							<h3>ACORDOS <br />ATRASADOS</h3>

							<h2>
								<span style={{ fontSize: 13, marginTop: 8 }}>R$</span>
								{findMetricInfo(this.state.metrics, [this.state.agreementIndicatorsFilters.time_frame], 'AGREEMENT_INDICATOR_OVERDUE', 'sum').replace('R$', '')}
							</h2>

							<div style={{ background: '#FF3D60' }} className="card-value card-value-brown">
								{findMetricInfo(this.state.metrics, [this.state.agreementIndicatorsFilters.time_frame], 'AGREEMENT_INDICATOR_OVERDUE', 'count')} acordos
							</div>

							<div style={{ background: '#FF3D60' }} className="card-value card-value-brown">
								{findMetricInfo(this.state.metrics, [this.state.agreementIndicatorsFilters.time_frame], 'AGREEMENT_INDICATOR_OVERDUE', 'installments')} parcelas
							</div>
						</div>
					</div>
				</CardBody>
			</Card>
		)
	}
}


