
import React, { Component } from "react";
import { getVistoria, updateVistoria } from "../../crud/property-inspection.crud";
import BetterCircularProgress from "../../components/BetterCircularProgress";
import { Card, Container, Label } from "reactstrap";
import Breadcrumbs from "../../components/Breadcrumb";
import BetterAlerts from "../../components/BetterAlerts";
import { Formik } from "formik";
import { TextField } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCheck } from "@fortawesome/pro-regular-svg-icons";
import moment from "moment";
import { validateInspectionEdit } from "../../helpers/FormValidators";

class PropertyInspectionEdit extends Component {
    state = {
        propertyInspection: null,
        loading: false,
        errors: [],
        start_date: '',
        end_date: '',
    }

    componentDidMount = () => {
        this.setState({ loading: true });

        getVistoria(this.props.match.params.id)
            .then(res => {
                const { start_date, end_date } = res.data;
                this.setState({
                    propertyInspection: res.data,
                    start_date,
                    end_date,
                    newStartDate: start_date,
                    newEndDate: end_date
                });
            })
            .catch(({ response }) => {
                if (response?.data?.message) {
                    this.setState({ errors: [...this.state.errors, response?.data?.message] });
                } else {
                    this.setState({ errors: [...this.state.errors, 'houve um erro, entre em contato com o suporte'] });
                }
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    };

    getInicialValues = () => {
        return {
            start_date: moment(this.state.start_date).add(3, 'hours').format('YYYY-MM-DD'),
            end_date: moment(this.state.end_date).add(3, 'hours').format('YYYY-MM-DD')
        }
    }

    onSubmit = (values) => {
        this.setState({ loading: true });

        const dataToUpdate = {
            ...values,
            start_date: moment(values.start_date).format('YYYY-MM-DD 00:00:00'),
            end_date: moment(values.end_date).format('YYYY-MM-DD 00:00:00')
        };

        updateVistoria(this.props.match.params.id, dataToUpdate)
            .then(() => {
                this.props.history.push({
                    pathname: '/contracts/property-inspection/show/' + this.state.propertyInspection.contract_id,
                    state: { success: 'Vistoria atualizada com sucesso' }
                });
            })
            .catch(({ response }) => {
                if (response?.data?.message) {
                    this.setState({ errors: [...this.state.errors, response?.data?.message] });
                } else {
                    this.setState({ errors: [...this.state.errors, 'houve um erro, entre em contato com o suporte'] });
                }
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    };

    render() {
        return (
            <React.Fragment>
                <BetterCircularProgress loading={this.state.loading}>
                    <div className="page-content container-page">
                        {this.state?.propertyInspection && (
                            <Container fluid>

                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '24px' }}>
                                    <div className="inspection-breadcrumb" style={{ width: '90%', margin: '0 auto', justifyContent: 'space-between', alignItems: 'center', display: 'flex' }}>
                                        <Label className="col-form-label" style={{ color: '#495057', fontSize: '24px', fontFamily: 'Inter' }}>Editar vistoria</Label>

                                        <Breadcrumbs
                                            breadcrumbItems={[
                                                { link: '/negotiation/index', title: 'Negociações' },
                                                { link: `/negotiation/index`, title: 'Negociações e contratos' },
                                                { link: `/contracts/property-inspection/show/${this.state?.propertyInspection?.contract_id}`, title: `ID ${this.props.match.params.id.substring(0, 6)}...` },
                                                { link: `/property-inspection/edit/${this.props.match.params.id}`, title: 'Editar Vistoria' }
                                            ]}
                                        />
                                    </div>
                                </div>

                                <BetterAlerts errors={this.state.errors} />

                                <Formik validate={validateInspectionEdit} initialValues={this.getInicialValues()} onSubmit={(values) => this.onSubmit(values)} enableReinitialize={true}>
                                    {({ values, errors, handleChange, handleSubmit, isValid }) => (
                                        <form noValidate={true} autoComplete="off" onSubmit={handleSubmit} style={{ display: 'flex', justifyContent: 'center', gap: '32px' }}>
                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                <Card style={{ width: '90%', margin: '0 auto', padding: '24px', borderRadius: '8px' }}>
                                                    <p style={{ fontFamily: 'Inter', fontSize: '12px', fontWeight: '600', color: '#8590A5' }}>DETALHES</p>

                                                    <div style={{ display: 'flex', gap: '32px' }}>
                                                        <div>
                                                            <p style={{ fontFamily: 'Inter', fontSize: '13px', color: '#343A40', margin: '8px 0' }}>Defina a data de início da vistoria</p>
                                                            <TextField
                                                                style={{ background: '#FFF', width: '244px' }}
                                                                name={'start_date'}
                                                                type="date"
                                                                variant="outlined"
                                                                value={values.start_date}
                                                                InputLabelProps={{ shrink: true }}
                                                                onChange={async (e) => { await handleChange(e) }}
                                                                error={Boolean(errors.start_date)}
                                                                helperText={errors.start_date}
                                                            />
                                                        </div>
                                                        <div>
                                                            <p style={{ fontFamily: 'Inter', fontSize: '13px', color: '#343A40', margin: '8px 0' }}>Defina a data final da vistoria</p>
                                                            <TextField
                                                                style={{ background: '#FFF', width: '244px' }}
                                                                name={'end_date'}
                                                                type="date"
                                                                variant="outlined"
                                                                value={values.end_date}
                                                                InputLabelProps={{ shrink: true }}
                                                                onChange={async (e) => { await handleChange(e) }}
                                                                error={Boolean(errors.end_date)}
                                                                helperText={errors.end_date}
                                                            />
                                                        </div>
                                                    </div>
                                                </Card>

                                                <Card style={{ width: '90%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '16px', borderRadius: '8px', marginTop: '24px', alignItems: 'center' }}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div style={{ marginRight: '32px' }}>
                                                            <p className="p-type-bold" style={{ marginBottom: '0' }}>Período atual de vistoria</p>
                                                            <p style={{ margin: 'auto 0', fontFamily: 'Inter', fontSize: '16px', fontWeight: '600', color: '#8590A5' }}>
                                                                {moment(this.state?.propertyInspection?.start_date).add(3, 'hours').format('DD/MM/YYYY')} a {moment(this.state?.propertyInspection?.end_date).add(3, 'hours').format('DD/MM/YYYY')}
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <p className="p-type-bold" style={{ marginBottom: '0' }}>Novo período de vistoria</p>
                                                            <p style={{ margin: '0', fontFamily: 'Inter', fontSize: '16px', fontWeight: '600', color: '#1CBB8C' }}>
                                                                {moment(values.start_date).format('DD/MM/YYYY')} a {moment(values.end_date).format('DD/MM/YYYY')}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div style={{ display: 'flex' }}>
                                                        <button className="go-back-1" type='button' onClick={() => window.location.href = `/contracts/property-inspection/show/${this.state.propertyInspection?.contract_id}`}>
                                                            <FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: '15px' }} />CANCELAR
                                                        </button>

                                                        <button className="buttonGreen" style={{ marginLeft: '12px' }} type='submit' disabled={!isValid}>
                                                            SALVAR<FontAwesomeIcon icon={faCheck} style={{ fontSize: '15px' }} />
                                                        </button>
                                                    </div>

                                                </Card>
                                            </div>
                                        </form>
                                    )}
                                </Formik>

                            </Container>
                        )}
                    </div>
                </BetterCircularProgress>
            </React.Fragment>
        );
    }
}


export default PropertyInspectionEdit;