import axios from "axios";
import { Constants } from '../helpers/Constants';
import { parseQuery } from "../helpers/Utils";

export function getAccountVerification(id) {
	return axios.get(`${Constants.baseUrl}/account/${id}`);
}

export function getDocumentStatus(id) {
	return axios.get(`${Constants.baseUrl}/account/document/${id}`);
}

export function createAccountVerification(data) {
	return axios.post(`${Constants.baseUrl}/account`, data);
}

export function updateAccountVerification(id, data) {
	return axios.put(`${Constants.baseUrl}/account/${id}`, data);
}

export const resendDocument = (user_id, data) => {
	return axios.put(`${Constants.baseUrl}/account/document/${user_id}`, data, {
		"Content-Type": "multipart/form-data"
	});
};

export const sendDocument = (user_id, data) => {
	return axios.post(`${Constants.baseUrl}/account/document/${user_id}`, data, {
		"Content-Type": "multipart/form-data"
	});
};

export const resendEmail = (data) => {
	return axios.post(`${Constants.baseUrl}/account/resend-link`, data);
};

export const getFinancialTransactions = (user_id, params) => {
	return axios.get(`${Constants.baseUrl}/account/financial-transactions/${user_id}`, { params: parseQuery(params) });
};

export const sendFileAsaasDirectly = (user_id, data) => {
	return axios.post(`${Constants.baseUrl}/account/document/${user_id}`, data)
}
