import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatBRL } from "../../helpers/Utils";
import { Link } from "react-router-dom";
import moment from "moment";
import { removeWebhookError } from "../../crud/billings.crud";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileContract } from "@fortawesome/pro-solid-svg-icons";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";

export default class DataTableWebhookErrors extends Component {
	constructor() {
		super();

		this.state = {
			property: {},
			data: null,
			rows: 6,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: 'id',
			sortOrder: 1,
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		let webhookErrorsDataTable = JSON.parse(localStorage.getItem("webhookErrorsDataTable"));

		if (webhookErrorsDataTable) {
			this.setState(
				{
					data: webhookErrorsDataTable.data,
					rows: webhookErrorsDataTable.rows,
					totalRecords: webhookErrorsDataTable.totalRecords,
					first: webhookErrorsDataTable.first,
					last: webhookErrorsDataTable.last,
					sortField: webhookErrorsDataTable.sortField,
					sortOrder: webhookErrorsDataTable.sortOrder,
					loading: false
				},
			);
		} else {
			this.fetchData();
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters !== this.props.filters) {
			this.fetchData();
		}
	}

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = async (event) => {
		this.setState({ loading: true });

		if (event.data) event.data = null;

		let webhookErrorsDataTable = {
			rows: event.rows,
			totalRecords: this.state.totalRecords,
			data: this.data,
			first: event.first,
			last: event.rows + event.first,
			loading: this.state.loading,
			sortField: this.state.sortField,
			sortOrder: this.state.sortOrder,
		};

		localStorage.setItem("webhookErrorsDataTable", JSON.stringify(webhookErrorsDataTable));

		this.setState({ ...this.state, rows: event.rows });

		this.fetchData(event.rows, event.first);
	};

	fetchData = async (limit, skip) => {
		this.setState({ loading: true });

		let options = {
			$limit: limit ? limit : this.state.rows,
			$skip: skip ? skip : this.state.first,
			$sort: { [this.state.sortField]: this.state.sortOrder },
		};

		return this.props.fetchEvent(options).then(async (res) => {

			if (res.data.data.length == 0) {
				this.setState({
					...this.state,
					data: [],
					totalRecords: res.data.total,
					first: skip ? skip : this.state.first,
					last: skip + res.data.limit,
				});
			}

			this.setState({
				...this.state,
				data: res.data.data,
				totalRecords: res.data.total,
				first: skip ? skip : this.state.first,
				last: skip + res.data.limit,
			});
		}).finally(() => {
			this.setState({ loading: false });
		});
	};

	sortTable = async (e) => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder, loading: true }, () => {
			let webhookErrorsDataTable = {
				rows: this.state.rows,
				totalRecords: this.state.totalRecords,
				first: this.state.first,
				last: this.state.last,
				loading: this.state.loading,
				sortField: this.state.sortField,
				sortOrder: this.state.sortOrder,
				data: this.state.data
			};
			localStorage.setItem("webhookErrorsDataTable", JSON.stringify(webhookErrorsDataTable));

			this.fetchData();
		});
	};

	getValue = (obj) => {
		return (
			<div style={{ textAlign: "center" }}>
				<p>{formatBRL(obj || 0)}</p>
			</div>
		);
	};

	getUserName = (obj) => {
		if (obj?.user?.name) {
			return (
				<div style={{ textAlign: "center" }}>
					<Link to={`/users/show/${obj.user?.id}`}>{obj.user?.name}</Link>
				</div>
			);
		}

		return (
			<div style={{ textAlign: "center" }}>
				Não encontrado
			</div>
		);
	};

	getBillingUrl = (obj) => {
		if (obj?.billing?.id) {
			return (
				<div style={{ textAlign: "center" }}>
					<Link to={`/billing/show/${obj.billing?.id}`}>{this.getDateFormatted(obj?.billing?.due_date)}</Link>
				</div>
			);
		}

		return (
			<div style={{ textAlign: "center" }}>
				N/A
			</div>
		);
	}

	getAsaasUrl = (obj) => {
		if (obj?.request?.payment?.invoiceUrl) {
			return (
				<div style={{ textAlign: "center" }}>
					<a href={obj?.request?.payment?.invoiceUrl} target="_blank">{obj?.request?.payment?.invoiceUrl}</a>
				</div>
			);
		}

		return (
			<div style={{ textAlign: "center" }}>
				Não encontrado
			</div>
		);
	}

	getContractFriendlyName = (obj) => {
		if (obj?.contract?.id) {
			return (
				<div style={{ textAlign: "start", width: '127px' }}>
					<FontAwesomeIcon icon={faFileContract} style={{ color: "#4AA3FF", marginRight: 10 }} />
					<Link style={{ color: "var(--Azul-500, #4AA3FF)", fontFamily: "Lato", fontSize: "14px", fontStyle: "normal", fontWeight: 400, lineHeight: "normal" }} to={`/contracts/show/${obj?.contract?.id}`}>{obj?.contract?.friendly_code}</Link>
				</div>
			);
		}

		return (
			<div style={{ textAlign: "center" }}>
				Não encontrado
			</div>
		);
	}

	getCity = (obj) => {
		if (obj?.property) {
			return (
				<div style={{ textAlign: "center" }}>
					<Link to={`/announcements/show/${obj.property?.id}`}>{obj.property?.address?.city}</Link>
				</div>
			);
		} else {
			return (
				<div style={{ textAlign: "center" }}>
					Não encontrado
				</div>
			)
		}
	};

	getNeighbourhood = (obj) => {
		return (
			<div style={{ textAlign: "center" }}>
				<Link to={`/announcements/show/${obj.property?.id}`}>
					{obj.property?.address?.neighbourhood}
				</Link>
			</div>
		);
	};

	getDateFormatted = (date, billing_id) => {
		if (billing_id) {
			return (
				<a href={`/billing/show/${billing_id}`}>
					{date ? moment(date).add(1, 'day').format("DD/MM/YYYY") : '-'}
				</a>
			)
		}
		return (
			<p style={{ marginBottom: '0px' }}>
				{date ? moment(date).add(1, 'day').format("DD/MM/YYYY") : '-'}
			</p>
		);
	};

	handleRemoveWebhookError = (obj) => {
		removeWebhookError(obj?.id).then(res => {
			if (res.status === 200) {
				alert('Erro do webhook removido da tabela!');
				this.fetchData();
			}
		});
	}


	columnOptionsCrud = (obj) => {
		if (this.props.crudUrl) {
			return (
				<div style={{}}>
					<i className="fa fa-times" style={{ color: "#ff3d60", cursor: 'pointer' }} onClick={(e) => { e.preventDefault(); this.handleRemoveWebhookError(obj) }}></i>
				</div>
			);
		}
	};

	seeError = (obj) => {
		return (
			<div style={{ textAlign: "center" }}>
				<Link to={`webhook-error/show/${obj?.id}`}>
					<FontAwesomeIcon icon={faChevronRight} style={{ color: "#5664D2" }} />
				</Link>
			</div>
		)
	}

	getValue = (obj) => {
		return (
			<div>
				<p>{formatBRL(obj?.request?.payment?.value ?? 0)}</p>
			</div>
		)
	}

	export() {
		this.dt.exportCSV();
	}

	render() {
		return (
			<div style={{ position: 'relative' }}>
				<DataTable ref={(el) => this.dt = el} value={this.state?.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={[6, 15, 30]} first={this.state.first} last={this.state.last}
					totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} responsive={true} loadingIcon={`fas fa-sync fa-spin`} onSort={(e) => this.sortTable(e)}
					paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" emptyMessage="Nenhum registro encontrado!"
					currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" sortField={this.state.sortField} sortOrder={this.state.sortOrder} id="custom-table-1" pageLinkSize={3}>

					{this.props.labels.find(obj => obj.label === 'Criado em').isActive ? <Column field="created_at" sortable body={(obj) => moment(obj.created_at).format('DD/MM/YYYY')} header="Criado em" style={{ width: 108, padding: '20px 16px 20px 16px' }} /> : null}

					{this.props.labels.find(obj => obj.label === 'Cobrança').isActive ? <Column field="billing" body={(obj) => this.getBillingUrl(obj)} header="Cobrança" style={{ width: 105, padding: '20px 16px 20px 16px' }} /> : null}

					{this.props.labels.find(obj => obj.label === 'Fatura ASAAS').isActive ? <Column field="asaas_bill" body={(obj) => this.getAsaasUrl(obj)} header="Fatura Asaas" style={{ width: '520px', padding: '20px 16px 20px 16px' }} /> : null}

					{this.props.labels.find(obj => obj.label === 'Contrato').isActive ? <Column field="contract" body={(obj) => this.getContractFriendlyName(obj)} header="Contrato" style={{ width: '160px', padding: '20px 16px 20px 16px' }} /> : null}

					{this.props.labels.find(obj => obj.label === 'Valor aluguel').isActive ? <Column field="value" body={(obj) => this.getValue(obj)} header="Valor aluguel" style={{ width: '124px', padding: '20px 16px 20px 16px' }} /> : null}

					<Column header="" body={(obj) => this.columnOptionsCrud(obj)} style={{ width: '34px', padding: 0 }} />
					<Column header="" body={(obj) => this.seeError(obj)} style={{ width: '34px', padding: 0 }} />
				</DataTable>

				<div className="paginator-new-items">
					<p>Registros por página</p>
					<button className="custom-see-all-table">Ver todos</button>
				</div>
			</div>
		);
	}
}
