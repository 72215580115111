import axios from "axios";
import { Constants } from '../helpers/Constants';
import { parseQuery } from "../helpers/Utils";

export function storeOccurrences(data) {
	return axios.post(`${Constants.baseUrl}/occurrences`, data);
}

export function getOccurrences(params) {
	return axios.get(`${Constants.baseUrl}/occurrences`, { params: parseQuery(params) });
}

export function updateOccurrence(data, id) {
	return axios.patch(`${Constants.baseUrl}/occurrences/${id}`, data);
}