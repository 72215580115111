import React, { Component, createRef } from 'react';
import { Container, Card, Row, Col, Label, Input, Nav, NavItem, NavLink } from "reactstrap";
import Breadcrumbs from '../../components/Breadcrumb';
import { getAllByParams as GetPropositions, getTotalNegociacoes } from '../../crud/propositions.crud';
import DataTableNegotiation from '../../components/datatables/DataTableNegotiation';
import BetterAlerts from '../../components/BetterAlerts';
import { UserFilterComponent } from './components/user.component';
import { AnnouncementFilterComponent } from './components/announcement.component';
import { Link } from "react-router-dom";
import { getAllByFilter as GetContracts } from '../../crud/contracts.crud';
import { getByFilter as getUserByFilter } from "../../crud/users.crud";
import { getProperties } from '../../crud/announcements.crud';
import BetterCircularProgress from '../../components/BetterCircularProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { faChevronRight, faFilter, faMagnifyingGlass, faMessagePlus, faSpinner, faTimes } from '@fortawesome/pro-regular-svg-icons';

class NegotiationIndex extends Component {
	dataTableRef = createRef();

	state = {
		filters: {
			inquilino: {},
			proprietario: {},
			warranty_type: '',
			negotiation_status: undefined,
			announcement_id: undefined,
			announcement: undefined,
			created_after: undefined,
			created_before: undefined,
			origin: undefined,
			searchAll: '',
			searchAllObject: {},
			announcement_friendly_code: undefined
		},
		is_filter_open: false,
		errors: [],
		success: [],
		activeTab: '1',
		filtersCount: 0,
		newFilters: [],
		indicadores: {},
		totalAtual: 0,
		list_users: [],
		termo_user: '',
	};

	componentDidMount = () => {
		let filters = this.state.filters;
		let flags = this.state.flags;

		this.setState({ filters, flags });

		if (this.props.location.state) {
			if (this.props.location.state.success) {
				this.setState({ success: this.props.location.state.success });
			}
		}


		if (this.props?.location?.state?.tab) {
			this.setState({ activeTab: this.props?.location?.state?.tab })
		}

		if (this.props?.location?.state?.friendlyCodeAnnouncement) {
			this.setState({
				flags: { ...this.state.flags, announcement_friendly_code: true },
				filters: { ...this.state.filters, announcement_friendly_code: this.props?.location?.state?.friendlyCodeAnnouncement }
			}, () => this.saveFilters());

			this.setState({ newFilters: [...this.state.newFilters, 'announcement_friendly_code'] })
		}

		getTotalNegociacoes().catch(err => {
			alert('Ocorreu um erro ao carregar o número de imóveis, entre em contato com o suporte.');
		}).then(async res => {
			if (res?.status === 200) {
				if (res.data) {
					this.dataTableRef?.current?.fetchData();

					this.setState({ indicadores: res.data });
				}
			}
		});
	};

	saveFilters = () => {
		localStorage.setItem('negotiationFilters', JSON.stringify(this.state.filters));
		localStorage.setItem('negotiationFlags', JSON.stringify(this.state.flags));
	};

	fetchByFilter = async (data) => {
		let params = { ...data };
		let details = {};
		let ids = [];

		if (this.state?.filters?.announcement_friendly_code) {
			let propertyParams = {
				friendly_code: this.state?.filters?.announcement_friendly_code,
				'$sort[created_at]': '1'
			};

			const properties = await getProperties(propertyParams);
			const property = properties?.data?.data[0];
			params.property_announcement_id = property?.id;
		}

		if (this.state.filters.signup_after?.trim() != '') params.created_at_gte = this.state.filters.signup_after;
		if (this.state.filters.signup_before?.trim() != '') params.created_at_lte = this.state.filters.signup_before;
		if (this.state.filters.id?.trim() != '') params.id = this.state.filters.id;
		if (this.state.filters.friendly_code?.trim() != '') params.friendly_code = this.state.filters.friendly_code;
		if (this.state.filters.proprietario?.id) params.landlord_id = this.state.filters.proprietario.id;
		if (this.state.filters.inquilino?.id) params.tenant_id = this.state.filters.inquilino.id;
		if (this.state.filters.negotiation_status != '') {
			if (this.state.activeTab == '5' || this.state.activeTab == '4') {
				params.termination_status = this.state.filters.negotiation_status?.replace(/[\\"]/g, "");
			} else {
				params.status = this.state.filters.negotiation_status?.replace(/[\\"]/g, "");
			}
		}

		if (this.state.filters.origin != '') params.origin = this.state.filters.origin;

		if (this.state.filters.announcement?.trim() != '') details.place = this.state.filters.announcement;
		if (this.state.filters.warranty_type != '') details.warranty_type = this.state.filters.warranty_type;
		if (this.state.filters.contractWithJudicial) params.contractWithJudicial = this.state.filters.contractWithJudicial;

		if (this.state.filters.bad_debt_months_gte) params.bad_debt_months_gte = this.state.filters.bad_debt_months_gte;
		if (this.state.filters.bad_debt_months_lte) params.bad_debt_months_lte = this.state.filters.bad_debt_months_lte;
		if (this.state.filters.stop_guarantee) params.stop_guarantee = this.state.filters.stop_guarantee;

		if (this.state.filters.searchAll?.trim() != '') {
			const { data } = await getUserByFilter({
				$skip: 0,
				$sort: { name: 1, email: 1 },
				$select: 'id',
				name_email_or: this.state.filters.searchAll,
			});

			if (data.total > 0) {
				for (const usuario of data.data) {
					ids.push(usuario.id);
				}

				params.searchAll = {
					search: this.state.filters.searchAll,
					ids: ids
				}

			} else {
				params.searchAll = {
					search: this.state.filters.searchAll,
				}
			}
		}

		params.details = details;

		getTotalNegociacoes(params).catch(err => {
			alert('Ocorreu um erro ao carregar o número de imóveis, entre em contato com o suporte.');
		}).then(async res => {
			if (res?.status === 200) {
				if (res.data) {
					this.setState({ indicadores: res.data });
				}
			}
		});

		if (this.state.activeTab === '1') {
			// negociando
			if (!params.status) {
				params.status_ne = ['FINALIZED', 'FINISHED', 'AWAITING_SIGN'];
			}

			if (this.state.filters.contractWithJudicial) {
				params.$limit = 0;
			}
		}

		if (this.state.activeTab === '2') {
			// contratando
			params.signed_ne = true;
			params.is_terminated_not = true;
		}

		if (this.state.activeTab === '3') {
			// vigente
			params.signed_eq = true;
			params.is_terminated_not = true;
			params.scheduled_termination_date = 'null';
			params.termination_status = 'null'
		}

		if (this.state.activeTab === '4') {
			// encerrando
			params.is_terminated_not = true;
			params.isAdmin = true;
		}

		if (this.state.activeTab === '5') {
			// encerrado
			params.signed_eq = true;
			params.is_terminated_eq = true;
			// params.isAdmin = true;
		}

		if (this.state.activeTab === '6') {
			// cancelados
			params.is_terminated_eq = true;
			params.signed_ne = true;
		}

		if (this.state.activeTab === '7') {
			// ocorrencias
			params.contractWithOccurrence = true;

			if (this.state.filters.contractWithJudicial) {
				params.$limit = 0;
			}
		}

		if (this.state.activeTab === '1') {
			return GetPropositions(params);
		} else {
			return GetContracts(params);
		}
	};

	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({ activeTab: tab }, () => {
				this.setState({ totalAtual: 0 });
				this.dataTableRef?.current?.fetchData();
			});
		}
	};

	FiltersModule = ({ filtroModular, index }) => {
		const getFilterChoised = (filter) => {
			let component = <></>

			if (filter == 'data_cadastro') {
				component = (
					<>
						<div className='d-flex'>
							<Label htmlFor="signup_after" className="col-form-label">De</Label>
							<Input className="form-control" type="date" id="signup_after" value={this.state.filters.signup_after} style={{ width: 200, margin: '0 16px' }}
								onChange={(e) => this.setState({ filters: { ...this.state.filters, signup_after: e.target.value } }, () => this.saveFilters())} />
						</div>

						<div className='d-flex'>
							<Label htmlFor="signup_before" className="col-form-label">Até</Label>
							<Input className="form-control" type="date" id="signup_before" value={this.state.filters.signup_before} style={{ width: 200, margin: '0 16px' }}
								onChange={(e) => this.setState({ filters: { ...this.state.filters, signup_before: e.target.value } }, () => this.saveFilters())} />
						</div>
					</>
				)
			} else if (filter == 'announcement') {
				component = (
					<div className='d-flex'>
						<AnnouncementFilterComponent label="Cidade" value={this.state.filters.announcement}
							onChange={(e, value) => { this.setState({ filters: { ...this.state.filters, announcement: value } }); this.saveFilters(); }}
							onClick={() => this.setState({ flags: { ...this.state.flags, show_city: false }, filters: { ...this.state.filters, announcement: undefined } }, () => this.saveFilters())} layout={'row'}
						/>
					</div>
				)
			} else if (filter == 'tenant') {
				component = (
					<div className='d-flex'>
						<UserFilterComponent label="Inquilino" value={this.state.filters.inquilino} onChange={(e, value) => { this.setState({ filters: { ...this.state.filters, inquilino: value } }); this.saveFilters(); }} layout={'row'} />
					</div>
				)
			} else if (filter == 'landlord') {
				component = (
					<div className='d-flex'>
						<UserFilterComponent label="Proprietário" value={this.state.filters.proprietario} onChange={(e, value) => { this.setState({ filters: { ...this.state.filters, proprietario: value } }); this.saveFilters(); }} layout={'row'} />
					</div>
				)
			} else if (filter == 'id') {
				component = (
					<div className='d-flex'>
						<Input className="form-control" type="text" value={this.state.filters.id} style={{ width: 400, margin: '0 16px' }} placeholder="Pesquisar por ID"
							onChange={(e) => this.setState({ filters: { ...this.state.filters, id: e.target.value } }, () => this.saveFilters())} />
					</div>
				)
			} else if (filter == 'friendly_code') {
				component = (
					<div className='d-flex'>
						<Input className="form-control" type="text" value={this.state.filters.friendly_code} style={{ width: 400, margin: '0 16px' }} placeholder="Pesquisar por Código do contrato"
							onChange={(e) => this.setState({ filters: { ...this.state.filters, friendly_code: e.target.value } }, () => this.saveFilters())} />
					</div>
				)
			} else if (filter == 'announcement_friendly_code') {
				component = (
					<div className='d-flex'>
						<Input className="form-control" type="text" value={this.state.filters.announcement_friendly_code} style={{ width: 400, margin: '0 16px' }} placeholder="Pesquisar por Código do contrato"
							onChange={(e) => this.setState({ filters: { ...this.state.filters, announcement_friendly_code: e.target.value } }, () => this.saveFilters())} />
					</div>
				)
			} else if (filter == 'warranty_type') {
				component = (
					<div className='d-flex'>
						<select onChange={(e) => this.setState({ filters: { ...this.state.filters, warranty_type: e.target.value } }, () => this.saveFilters())} style={{ width: 400, margin: '0 16px' }} className="custom-select">
							<option value="" hidden>Selecionar</option>
							<option value="WARRANTY">Garantia de pagamento</option>
							<option value="WARRANTY_GUARANTEE_DAMAGE">Garantia de pagamento com danos</option>
						</select>
					</div>
				)
			} else if (filter == 'negotiation_status') {
				component = (
					<div className='d-flex'>
						<select onChange={(e) => this.setState({ filters: { ...this.state.filters, negotiation_status: e.target.value } }, () => this.saveFilters())} style={{ width: 400, margin: '0 16px' }} className="custom-select">
							<option value="" hidden>Selecionar</option>

							{this.state.activeTab == '5' || this.state.activeTab == '4' ?
								<>
									<option value="EXTRAJUDICIAL">Quebra de contrato</option>
									<option value="AWAITING_COURT_APPROVAL">Aguardando aprovação judicial</option>
									<option value="TERMINATION_TERM">Comum acordo</option>
									<option value="CLOSURE_TERM">Tempo previsto por contrato</option>
								</>
								:
								<>
									<option value="PLACEHOLDER">Chat iniciado</option>
									<option value="DRAFT">Criada Integracoes</option>
									<option value="AWAITING_TENANT_LOCK">Aguardando trava inquilino</option>
									<option value="FIRST_PROPOSAL">Criada admin</option>
									<option value="AWAITING_CREDIT_REVIEW">Aguardando análise financeira</option>
									<option value="APPROVED">Análise financeira aprovada</option>
									<option value="CREDIT_REFUSED">Análise financeira recusada</option>
									<option value="AWAITING_SIGN">Aguardando Assinaturas</option>
								</>
							}
						</select>
					</div>
				)
			} else if (filter == 'origin') {
				component = (
					<div className='d-flex'>
						<select onChange={(e) => this.setState({ filters: { ...this.state.filters, origin: e.target.value } }, () => this.saveFilters())} style={{ width: 400, margin: '0 16px' }} className="custom-select">
							<option value="" hidden>Selecionar</option>
							<option value="PLACEHOLDER">Criada integrações</option>
							<option value="ADMIN">Admin</option>
							<option value="CONTRACT_GENERATOR">Gerador de contrato</option>
							<option value="ORGANIC">Orgânico</option>
							<option value="Convidar_inquilino">Convidar inquilino</option>
						</select>
					</div>
				)
			} else if (filter == 'judicializado') {
				component = (
					<div className='d-flex'>
						<select onChange={(e) => this.setState({ filters: { ...this.state.filters, contractWithJudicial: e.target.value } }, () => this.saveFilters())} style={{ width: 400, margin: '0 16px' }} className="custom-select">
							<option value="" hidden>Selecionar</option>
							<option value="true">Sim</option>
							<option value="false">Não</option>
						</select>
					</div>
				)
			} else if (filter == 'bad_debt_months') {
				component = (
					<>
						<div className='d-flex'>
							{/* <Label htmlFor="bad_debt_months_gte" className="col-form-label">De</Label> */}
							<Input className="form-control" type="text" id="bad_debt_months_gte" placeholder='De' value={this.state.filters.bad_debt_months_gte} style={{ width: 200, margin: '0px 0px 0px 0px' }}
								onChange={(e) => this.setState({ filters: { ...this.state.filters, bad_debt_months_gte: e.target.value } }, () => this.saveFilters())} />
						</div>

						<div className='d-flex'>
							{/* <Label htmlFor="bad_debt_months_lte" className="col-form-label">Até</Label> */}
							<Input className="form-control" type="text" placeholder='Até' id="bad_debt_months_lte" value={this.state.filters.bad_debt_months_lte} style={{ width: 200, margin: '0 16px' }}
								onChange={(e) => this.setState({ filters: { ...this.state.filters, bad_debt_months_lte: e.target.value } }, () => this.saveFilters())} />
						</div>
					</>
				)
			} else if (filter == 'stop_guarantee') {
				component = (
					<div className='d-flex'>
						<select onChange={(e) => this.setState({ filters: { ...this.state.filters, stop_guarantee: e.target.value } }, () => this.saveFilters())} style={{ width: 400, margin: '0 16px' }} className="custom-select">
							<option value="" hidden>Selecionar</option>
							<option value="true">Sim</option>
							<option value="false">Não</option>
						</select>
					</div>
				)
			} else {
				component = <></>
			}

			return component;
		}

		const onSetFilter = (filtro) => {
			let stateFilter = this.state.newFilters.slice();
			stateFilter[index] = filtro;

			this.setState({ newFilters: stateFilter })
		}

		const getSelectOptions = (nameFiltro) => {
			const stateFilter = this.state.newFilters;

			if (stateFilter.includes(nameFiltro)) {
				return 'd-none'
			} else {
				return ''
			}
		}

		const removeFilter = (filterUnit, filterName) => {
			let stateFilter = this.state.newFilters;

			stateFilter.splice(filterUnit, 1);

			switch (filterName) {
				case 'data_cadastro':
					this.setState({
						flags: { ...this.state.flags, show_signup_after: false, show_signup_before: false },
						filters: { ...this.state.filters, signup_after: '', signup_before: '' }
					}, () => this.saveFilters())

					break;

				case 'cidade':
					this.setState({
						flags: { ...this.state.flags, show_announcement: false },
						filters: { ...this.state.filters, announcement: '' }
					}, () => this.saveFilters())

					break;

				case 'judicializado':
					this.setState({
						flags: { ...this.state.flags, show_judicializado: false },
						filters: { ...this.state.filters, contractWithJudicial: false }
					}, () => this.saveFilters())

					break;

				case 'origin':
					this.setState({
						flags: { ...this.state.flags, show_origin: false },
						filters: { ...this.state.filters, origin: '' }
					}, () => this.saveFilters())

					break;

				case 'tenant':
					this.setState({
						flags: { ...this.state.flags, show_user: false },
						filters: { ...this.state.filters, inquilino: {} }
					}, () => this.saveFilters())

					break;

				case 'landlord':
					this.setState({
						flags: { ...this.state.flags, show_user: false },
						filters: { ...this.state.filters, proprietario: {} }
					}, () => this.saveFilters())

					break;

				case 'id':
					this.setState({
						flags: { ...this.state.flags, show_id: false },
						filters: { ...this.state.filters, id: '' }
					}, () => this.saveFilters())

					break;

				case 'warranty_type':
					this.setState({
						flags: { ...this.state.flags, show_warranty_type: false },
						filters: { ...this.state.filters, warranty_type: '' }
					}, () => this.saveFilters())

					break;

				case 'negotiation_status':
					this.setState({
						flags: { ...this.state.flags, show_negotiation_status: false },
						filters: { ...this.state.filters, negotiation_status: '' }
					}, () => this.saveFilters())

					break;

				case 'friendly_code':
					this.setState({
						flags: { ...this.state.flags, friendly_code: false },
						filters: { ...this.state.filters, friendly_code: '' }
					}, () => this.saveFilters())

					break;

				case 'announcement_friendly_code':
					this.setState({
						flags: { ...this.state.flags, announcement_friendly_code: false },
						filters: { ...this.state.filters, announcement_friendly_code: '' }
					}, () => this.saveFilters())

					break;

				case 'bad_debt_months':
					this.setState({
						flags: { ...this.state.flags, bad_debt_months: false },
						filters: { ...this.state.filters, bad_debt_months: '', bad_debt_months_gte: '', bad_debt_months_lte: '' }
					}, () => this.saveFilters())

					break;

				case 'stop_guarantee':
					this.setState({
						flags: { ...this.state.flags, stop_guarantee: false },
						filters: { ...this.state.filters, stop_guarantee: '' }
					}, () => this.saveFilters())

					break;

				default:
					break;
			}

			this.setState({ newFilters: stateFilter })
		}

		return (
			<Col md={12} className="d-flex m-0 p-0 filtroUnit">
				<div className='d-flex'>
					<select onChange={({ target }) => onSetFilter(target.value)} value={this.state.newFilters[index]} className="custom-select" style={{ width: 300 }}>
						<option value="">Selecione um filtro</option>
						<option className={getSelectOptions('id')} value="id">Id</option>
						<option className={getSelectOptions('negotiation_status')} value="negotiation_status">Status</option>
						<option className={getSelectOptions('data_cadastro')} value="data_cadastro">Data Cadastro</option>
						<option className={getSelectOptions('announcement')} value="announcement">Cidade</option>
						<option className={getSelectOptions('tenant')} value="tenant">Inquilino</option>
						<option className={getSelectOptions('landlord')} value="landlord">Proprietário</option>
						<option className={getSelectOptions('warranty_type')} value="warranty_type">Plano</option>
						<option className={getSelectOptions('origin')} value="origin">Origem</option>
						<option className={getSelectOptions('announcement_friendly_code')} value="announcement_friendly_code">Código do imóvel</option>

						{['3', '4', '5', '6'].includes(this.state?.activeTab) && (
							<>
								<option className={getSelectOptions('judicializado')} value="judicializado">Judicializado</option>
							</>
						)}

						{['2', '3', '4', '5'].includes(this.state?.activeTab) && (
							<>
								<option className={getSelectOptions('friendly_code')} value="friendly_code">Código do contrato</option>
							</>
						)}

						{['3', '4', '5'].includes(this.state?.activeTab) && (
							<>
								<>
									<option className={getSelectOptions('bad_debt_months')} value="bad_debt_months">Meses de inadimplência</option>
								</>

								<>
									<option className={getSelectOptions('stop_guarantee')} value="stop_guarantee">Garantia parada</option>
								</>
							</>
						)}
					</select>

					{this.state.newFilters[index] != '' && (
						<div className='divisionArrow'>
							<FontAwesomeIcon icon={faChevronRight} />
						</div>
					)}

					{getFilterChoised(this.state.newFilters[index])}
				</div>

				<div onClick={() => removeFilter(index, this.state.newFilters[index])}>
					<FontAwesomeIcon icon={faTimes} />
				</div>
			</Col>
		)
	}

	handleSetIndicadorAtual = (indicador) => {
		this.setState({ totalAtual: indicador })
	}

	render() {
		return (
			<>
				<React.Fragment>
					<div className="page-content container-page userIndex">
						<Container fluid>
							<div className='header'>
								<h1>Negociações e contratos</h1>

								<Breadcrumbs breadcrumbItems={[{ link: '/announcements/index', title: 'Negociações' }, { link: '/announcements/index', title: 'Negociações e contratos' }]} />
							</div>

							<BetterAlerts errors={this.state.errors} success={this.state.success} />

							<Col md={12} className="box-filtros">
								<Col md={12} className="d-flex m-0 p-0">
									<div className='generalSearch'>
										<Input className="inputCustomNew" type="text" value={this.state.filters.searchAll} placeholder=" Busque por Endereço, Nome, Email, Telefone ID ou documento"
											onChange={(e) => this.setState({ filters: { ...this.state.filters, searchAll: e.target.value } }, () => this.saveFilters())} />

										<FontAwesomeIcon icon={faMagnifyingGlass} />
									</div>

									<button onClick={() => this.setState({ newFilters: [...this.state.newFilters, ''] })} className="btnSecundary" style={{ width: 'max-content', margin: '0px 8px', padding: '8px 12px' }}>
										FILTRO
										<FontAwesomeIcon icon={faFilter} />
									</button>

									<Link to={`/negotiation/new`} className="buttonPrimary w-100" style={{ width: '100%', maxWidth: '97px' }}>
										CRIAR
										<FontAwesomeIcon icon={faMessagePlus} />
									</Link>
								</Col>

								{this.state.newFilters.map((filtroModular, index) => {
									return <this.FiltersModule filtroModular={filtroModular} key={index} index={index} />
								})}
							</Col>

							<Row>
								<Col lg={12}>
									<Card className='card-general'>
										<Nav tabs className="nav-tabs-custom" role="tablist" style={{ marginBottom: '32px' }}>
											<NavItem>
												<NavLink id="announcements-tab" className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggleTab('1'); }}>
													<div className='tabName'>Negociando
														<div className='indicadorUsuarios'>
															{this.state?.indicadores?.negociando ?? <FontAwesomeIcon spin icon={faSpinner} />}
														</div>
													</div>
												</NavLink>
											</NavItem>

											<NavItem>
												<NavLink id="announcements-tab" className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggleTab('2'); }}>
													<div className='tabName'>Contratando
														<div className='indicadorUsuarios'>
															{this.state?.indicadores?.contratando ?? <FontAwesomeIcon spin icon={faSpinner} />}
														</div>
													</div>
												</NavLink>
											</NavItem>

											<NavItem>
												<NavLink id="announcements-tab" className={classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.toggleTab('3'); }}>
													<div className='tabName'>Vigentes
														<div className='indicadorUsuarios'>
															{this.state?.indicadores?.vigentes ?? <FontAwesomeIcon spin icon={faSpinner} />}
														</div>
													</div>
												</NavLink>
											</NavItem>

											<NavItem>
												<NavLink id="announcements-tab" className={classnames({ active: this.state.activeTab === '4' })} onClick={() => { this.toggleTab('4'); }}>
													<div className='tabName'>Encerrando
														<div className='indicadorUsuarios'>
															{this.state?.indicadores?.encerrando ?? <FontAwesomeIcon spin icon={faSpinner} />}
														</div>
													</div>
												</NavLink>
											</NavItem>

											<NavItem>
												<NavLink id="announcements-tab" className={classnames({ active: this.state.activeTab === '5' })} onClick={() => { this.toggleTab('5'); }}>
													<div className='tabName'>Encerrados
														<div className='indicadorUsuarios'>
															{this.state?.indicadores?.encerrados ?? <FontAwesomeIcon spin icon={faSpinner} />}
														</div>
													</div>
												</NavLink>
											</NavItem>

											<NavItem>
												<NavLink id="announcements-tab" className={classnames({ active: this.state.activeTab === '6' })} onClick={() => { this.toggleTab('6'); }}>
													<div className='tabName'>Cancelados
														<div className='indicadorUsuarios'>
															{this.state?.indicadores?.cancelados ?? <FontAwesomeIcon spin icon={faSpinner} />}
														</div>
													</div>
												</NavLink>
											</NavItem>

											<NavItem>
												<NavLink id="announcements-tab" className={classnames({ active: this.state.activeTab === '7' })} onClick={() => { this.toggleTab('7'); }}>
													<div className='tabName'>Ocorrências
														<div className='indicadorUsuarios'>
															{this.state?.indicadores?.ocorrencias ?? <FontAwesomeIcon spin icon={faSpinner} />}
														</div>
													</div>
												</NavLink>
											</NavItem>
										</Nav>

										<div className='datatable-box'>
											<BetterCircularProgress loading={this.state.loading}>
												<DataTableNegotiation ref={this.dataTableRef} filters={this.state.filters} noDelete fetchEvent={this.fetchByFilter} crudButtons={true}
													crudUrl={"/negotiation"} idRow={`id`} activeTab={this.state.activeTab} handleSetIndicadorAtual={this.handleSetIndicadorAtual} />
											</BetterCircularProgress>
										</div>
									</Card>
								</Col>
							</Row>
						</Container>
					</div>
				</React.Fragment>
			</>
		);
	}
}

export default NegotiationIndex;