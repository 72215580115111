import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import _ from 'lodash';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownToLine, faTimer } from '@fortawesome/pro-regular-svg-icons';
import { generateDimob, generateIncome, updateProfile } from '../../../crud/users.crud';
import { limparCPF } from '../../../helpers/Utils';

export default class DataTableFiscalDocs extends Component {
	constructor() {
		super();

		this.state = {
			data: [],
			rows: 10,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: 'created_at',
			sortOrder: '1',
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		let DataTableFiscalDocs = JSON.parse(localStorage.getItem('DataTableFiscalDocs'));

		if (DataTableFiscalDocs) {
			this.setState({
				rows: DataTableFiscalDocs.rows,
				totalRecords: DataTableFiscalDocs.totalRecords,
				first: DataTableFiscalDocs.first,
				last: DataTableFiscalDocs.last,
				loading: DataTableFiscalDocs.loading,
				sortField: DataTableFiscalDocs.sortField,
				sortOrder: DataTableFiscalDocs.sortOrder,
			}, () => {
				this.fetchData();
			});
		} else {
			this.fetchData();
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters != this.props.filters) {
			this.onFilterChanged();
		}
	}

	onFilterChanged = _.debounce(() => {
		if (!this.state.isLoading) {
			this.fetchData();
		}
	}, 700);

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = async (event) => {
		if (event.data) {
			event.data = null;
		}

		let DataTableFiscalDocs = {
			rows: event.rows,
			totalRecords: this.state.totalRecords,
			first: event.first,
			last: event.rows + event.first,
			loading: this.state.loading,
			sortField: this.state.sortField,
			sortOrder: this.state.sortOrder,
		};

		localStorage.setItem('DataTableFiscalDocs', JSON.stringify(DataTableFiscalDocs));

		this.setState({ ...this.state, rows: event.rows, loading: true });

		this.fetchData(event.rows, event.first);
	};

	fetchData = (limit, skip) => {
		let data = {
			user_id: this.props.userId,
			$limit: limit ? limit : this.state.rows,
			$skip: skip != null ? skip : this.state.first,
			$sort: { [this.state.sortField]: this.state.sortOrder }
		};

		this.props.fetchEvent(data).then(async ({ data }) => {
			if (data.total > 0) {
				this.setState({ data: data.data, totalRecords: data.total, first: data.skip, last: data.skip + data.limit, loading: false });
			} else {
				this.setState({
					data: [
						{
							document_type: this.props.user?.type_account == 'PF' ? 'Informe de rendimento' : 'DIMOB',
							file: null,
							status: 'Aguardando',
							reference_year: moment().subtract(1, 'years').year(),
							created_at: moment().toISOString(),
							updated_at: moment().toISOString(),
						}
					],
					totalRecords: 1,
					first: 0,
					last: 1,
					loading: false
				});
			}
		});
	};

	sortTable = e => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder }, () => {
			let DataTableFiscalDocs = {
				rows: this.state.rows,
				totalRecords: this.state.totalRecords,
				first: this.state.first,
				last: this.state.last,
				loading: this.state.loading,
				sortField: this.state.sortField,
				sortOrder: this.state.sortOrder,
			};

			localStorage.setItem('DataTableFiscalDocs', JSON.stringify(DataTableFiscalDocs));

			this.fetchData();
		});
	};

	getDocumentTypeTag = (document_type) => {
		let color = '';

		if (document_type == 'DIMOB') {
			color = '#5664D2';
		} else if (document_type == 'Informe de rendimento') {
			color = '#4AA3FF';
		}

		return (
			<div className='tag' style={{ background: color }}>
				{document_type}
			</div>
		);
	}

	getStatus = (status) => {
		let color = '';

		if (status == 'Gerando') {
			color = '#FCB92C';
		} else if (status == 'Gerado') {
			color = '#1CBB8C';
		} else {
			color = '#8590A5';
		}

		return (
			<div className='tag' style={{ background: color }}>
				{status}
			</div>
		);
	}

	onSubmitCPF = async () => {
		let user = { cpf: limparCPF(this.props.cpfEmpresa) };

		return updateProfile(user).then((res) => {
			if (res?.status == 200) {
			}
		}).catch((err) => {
			this.props.handleSetErrors(["Ocorreu um erro ao atualizar seu perfil."]);
			throw err;
		});
	};

	columnOpcoesCrud = (registro) => {
		const link = registro?.file?.secure_url.replace("http://", "https://");

		const switchGenerateDocument = () => {
			if (this.props.user?.type_account == 'PF') {
				generateIncomeDocument();
			} else {
				generateDimobDocument();
			}
		}

		const generateDimobDocument = async () => {
			try {
				if (this.props.user?.cpf == null) {
					if (Boolean(this.props.cpfEmpresa)) {
						await this.onSubmitCPF();
					} else {
						this.props.handleSetErrors(["É preciso preencher o CPF do responsável."]);
						return;
					}
				}

				const params = { user_id: this.props.user.id, yearReference: registro?.reference_year };
				const response = await generateDimob(params);

				if (response?.data.includes("gerado com sucesso")) {
					this.fetchData();
				} else {
					this.props.handleSetErrors(["Erro ao gerar o documento DIMOB."]);
				}
			} catch (error) {
				console.log(error);
				this.props.handleSetErrors([error.response?.data?.message || "Erro ao gerar o documento DIMOB."]);
			}
		}

		const generateIncomeDocument = async () => {
			try {
				const params = { user_id: this.props.user.id, yearReference: registro?.reference_year };
				const response = await generateIncome(params);

				if (response?.data.includes("gerado com sucesso")) {
					this.fetchData();
				} else {
					this.props.handleSetErrors(["Erro ao gerar o documento Informe de renda."]);
				}
			} catch (error) {
				console.log(error);
				this.props.handleSetErrors([error.response?.data?.message || "Erro ao gerar o documento Informe de renda."]);
			}
		}

		const handleDownload = async (event) => {
			event.preventDefault();

			try {
				const response = await fetch(link);

				if (!response.ok) {
					this.props.handleSetErrors(["Erro ao baixar o arquivo."]);
					throw new Error('Erro ao baixar o arquivo.');
				}

				const blob = await response.blob();
				const url = window.URL.createObjectURL(blob);

				const anchor = document.createElement('a');
				anchor.href = url;

				if (registro?.document_type == 'Informe de rendimento') {
					anchor.download = `rendimento_${registro?.user_id}_${registro?.reference_year}.pdf`;
				}

				if (registro?.document_type == 'DIMOB') {
					anchor.download = `dimob_${registro?.user_id}_${registro?.reference_year}.txt`;
				}

				document.body.appendChild(anchor);
				anchor.click();

				document.body.removeChild(anchor);
				window.URL.revokeObjectURL(url);
			} catch (error) {
				this.props.handleSetErrors([`Erro ao fazer o download do arquivo:', ${error}`]);

				console.error('Erro ao fazer o download do arquivo:', error);
			}
		};

		return (
			<a onClick={registro.status == 'Aguardando' ? switchGenerateDocument : handleDownload} className='showButton'>
				<FontAwesomeIcon icon={registro.status == 'Aguardando' ? faTimer : faArrowDownToLine} />
			</a>
		);
	};

	render() {
		const paginatorRight = (
			<button onClick={() => { this.fetchData(this.state?.totalRecords); this.setState({ rows: this.state?.totalRecords }) }} className='btnSecundary' style={{ marginLeft: 24 }}>
				Ver todos
			</button>
		);

		return (
			<DataTable ref={(el) => this.dt = el} value={this.state.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={[10, 25, 50, this.state?.totalRecords]}
				first={this.state.first} last={this.state.last} header={null} responsive={true} className="ml-auto" paginatorRight={paginatorRight}
				totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} loadingIcon={`fas fa-sync fa-spin`}
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
				currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" emptyMessage="Nenhum registro encontrado!"
				onSort={(e) => this.sortTable(e)} sortField={this.state.sortField} sortOrder={this.state.sortOrder}>
				<Column sortable field="document_type" header="Documento" body={({ document_type }) => this.getDocumentTypeTag(document_type)} />
				<Column sortable field="status" header="Status" body={({ status }) => this.getStatus(status)} />
				<Column sortable field="created_at" body={({ created_at }) => created_at ? moment(created_at).format('DD/MM/YYYY HH:mm:ss') : '--'} header="Criado em" />
				<Column sortable field="last_download_at" body={({ last_download_at }) => last_download_at ? moment(last_download_at).format('DD/MM/YYYY HH:mm:ss') : 'Não foi feito download.'} header="Último download" />
				<Column sortable field="reference_year" body={({ reference_year }) => reference_year ? reference_year : '--'} header="Vigência" />
				<Column header="" body={(registro) => this.columnOpcoesCrud(registro)} style={{ width: '34px', padding: 0 }} />
			</DataTable>
		);
	}
}