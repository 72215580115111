import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { get } from "../../crud/propositions.crud";
import { formatBRL } from "../../helpers/Utils";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

export default class DataTableGeradorContrato extends Component {
	constructor() {
		super();

		this.state = {
			property: {},
			data: [],
			rows: 10,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: "status",
			sortOrder: 1,
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		let geradorContratoDataTable = JSON.parse(localStorage.getItem("geradorContratoDataTable"));

		if (geradorContratoDataTable) {
			this.setState(
				{
					data: geradorContratoDataTable.data,
					rows: geradorContratoDataTable.rows,
					totalRecords: geradorContratoDataTable.totalRecords,
					first: geradorContratoDataTable.first,
					last: geradorContratoDataTable.last,
					sortField: geradorContratoDataTable.sortField,
					sortOrder: geradorContratoDataTable.sortOrder,
					loading: false
				},
			);
		} else {
			this.fetchData();
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters !== this.props.filters) {
			this.fetchData();
		}
	}

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = async (event) => {
		this.setState({
			loading: true,

		});
		if (event.data) {
			event.data = null;
		}

		let geradorContratoDataTable = {
			rows: event.rows,
			totalRecords: this.state.totalRecords,
			data: this.data,
			first: event.first,
			last: event.rows + event.first,
			loading: this.state.loading,
			sortField: this.state.sortField,
			sortOrder: this.state.sortOrder,
		};

		localStorage.setItem("geradorContratoDataTable", JSON.stringify(geradorContratoDataTable));

		this.setState({
			...this.state,
			rows: event.rows,
		});
		this.fetchData(event.rows, event.first);
	};

	fetchData = async (limit, skip) => {
		this.setState({ loading: true });

		let options = {
			$limit: limit ? limit : this.state.rows,
			$skip: skip != null ? skip : this.state.first,
			$sort: { [this.state.sortField]: this.state.sortOrder },
		};

		return this.props
			.fetchEvent(options)
			.then(async (res) => {
				const data = await Promise.all(
					res.data.data.map(async (obj, i) => {
						return get(obj.id)
							.then(({ data }) => data.data)
							.catch(() => null);
					})
				);

				this.setState({
					data: data.filter((o) => (o)),
					totalRecords: res.data.total,
					first: skip,
					last: skip + res.data.limit,
				});
			})
			.finally(() => {
				this.setState({ loading: false });
			});
	};

	sortTable = async (e) => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder, loading: true }, () => {
			let geradorContratoDataTable = {
				rows: this.state.rows,
				totalRecords: this.state.totalRecords,
				first: this.state.first,
				last: this.state.last,
				loading: this.state.loading,
				sortField: this.state.sortField,
				sortOrder: this.state.sortOrder,
				data: this.state.data
			};
			localStorage.setItem("geradorContratoDataTable", JSON.stringify(geradorContratoDataTable));

			this.fetchData();
		});
	};

	getValue = (obj) => {
		return (
			<div style={{ textAlign: "center" }}>
				<p>{formatBRL(obj.details.values?.total || 0)}</p>
			</div>
		);
	};

	getUserName = (obj, type) => {
		if (type === "landlord") {
			return (
				<div style={{ textAlign: "center" }}>
					<Link to={`/users/show/${obj.landlord?.id}`}>{obj.landlord?.name}</Link>
				</div>
			);
		}

		if (type === "tenant") {
			return (
				<div style={{ textAlign: "center" }}>
					<Link to={`/users/show/${obj.tenant?.id}`}>{obj.tenant?.name}</Link>
				</div>
			);
		}
	};

	getCity = (obj) => {
		return (
			<div style={{ textAlign: "center" }}>
				<Link to={`/announcements/show/${obj.property?.id}`}>{obj.property?.address?.city}</Link>
			</div>
		);
	};

	getNeighbourhood = (obj) => {
		return (
			<div style={{ textAlign: "center" }}>
				<Link to={`/announcements/show/${obj.property?.id}`}>
					{obj.property?.address?.neighbourhood}
				</Link>
			</div>
		);
	};

	getStatus = (obj) => {
		return (
			<div style={{ textAlign: "center" }}>
				<p>{obj.insurance_status}</p>
			</div>
		);
	};

	getDateFormatted = (date) => {
		return (
			<p>
				{dayjs(date).format("DD/MM/YYYY HH:mm:ss")}
			</p>
		);
	};

	getStatusProposition = (obj) => {
		let status = '';
		switch (obj.status) {
			case 'FIRST_PROPOSAL':
				status = 'Criada Admin';
				break;
			case 'AWAITING_SIGN':
				status = 'Aguardando sinal';
				break;
			case 'FINALIZED':
				status = 'Finalizado';
				break;
			default:
				status = obj.status;
				break;
		}
		return (
			<div style={{ textAlign: "center" }}>
				<p>{status}</p>
			</div>
		);
	}

	columnOptionsCrud = (rowData) => {
		let idRow = this.props.idRow ?? "id";
		if (this.props.crudUrl) {
			return (
				<div style={{ textAlign: "center" }}>
					<Link to={`${this.props.crudUrl}/edit/${rowData[idRow]}`}
						className="btn btn-table-action mr-2" >
						<i className="fa fa-pencil-alt" style={{ color: "#5664D2" }}></i>
					</Link>
				</div>
			);
		}
	};

	export() {
		this.dt.exportCSV();
	}

	render() {
		return (
			<DataTable ref={(el) => this.dt = el} value={this.state.data} paginator={true} rows={this.state.rows} responsive={true}
				rowsPerPageOptions={[10, 25, 50]} first={this.state.first} last={this.state.last} sortOrder={this.state.sortOrder}
				totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading}
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
				currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" loadingIcon={`fas fa-sync fa-spin`}
				emptyMessage="Nenhum registro encontrado!" onSort={(e) => this.sortTable(e)} sortField={this.state.sortField} >
				{this.props.export ? this.export() : false}
				<Column field="landlord" body={(obj) => this.getUserName(obj, 'landlord')} header="Proprietário" />
				<Column field="tenant" body={(obj) => this.getUserName(obj, 'tenant')} header="Inquilino" />
				<Column field="city" body={(obj) => this.getCity(obj)} header="Imóvel" />
				<Column sortable body={(obj) => this.getValue(obj)} field="details.values.total" header="Valor total" />
				<Column sortable body={(obj) => obj.plan.friendly_name} field="details.warranty_type" header="Plano" />
				<Column sortable field="status" header="Status negociação" body={(obj) => this.getStatusProposition(obj)} />
				<Column field="neighbourhood" body={(obj) => this.getNeighbourhood(obj)} header="Bairro" />
				<Column sortable field="created_at" body={(obj) => this.getDateFormatted(obj.created_at)} header="Data de criação" />
				<Column header="Ações" body={this.columnOptionsCrud} style={{ width: '130px' }} />
			</DataTable>
		);
	}
}
