import React, { Component, createRef } from 'react';
import { Container, Card, Row, Col, Label, Input, } from "reactstrap";
import Breadcrumbs from '../../../components/Breadcrumb';
import BetterAlerts from '../../../components/BetterAlerts';

import BetterCircularProgress from '../../../components/BetterCircularProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronRight, faFilter, faMagnifyingGlass, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faArrowLeft, faArrowRotateRight } from '@fortawesome/pro-solid-svg-icons';

import { get as getAllByParams, createNewShipment, getSecBillings } from '../../../crud/billings.crud';
import DataTableSelectBillings from '../../../components/datatables/DataTableSecBillings';
import { formatBRL } from '../../../helpers/Utils';
import { Redirect } from "react-router-dom";

class SecuritizationCreate extends Component {
	dataTableRef = createRef();

	state = {
		flags: {
			created_at_lte: null,
			created_at_gte: null,
			status: null,
			landlord: null
		},
		filters: {
			created_at_lte: null,
			created_at_gte: null,
			status: null,
			landlord: null
		},
		is_filter_open: false,
		errors: [],
		success: [],
		activeTab: '1',
		filtersCount: 0,
		newFilters: [],
		indicadores: {},
		totalAtual: 0,
		list_users: [],
		termo_user: '',
		shipment_billings: [],
		shipment_id: null,
		sequentialNumber: '0000000',
		lastSequentialNumber: '0000000',
		sequentialError: '',
	};

	componentDidMount = async () => {
		// Buscar o último número sequencial para sugerir o próximo
		try {
			const response = await getSecBillings({ $limit: 1, $sort: { created_at: '-1' } });

			if (response.status === 200 && response.data.data.length > 0) {
				const lastBatch = response.data.data[0].shipment_batch || 0;

				const nextBatch = (parseInt(lastBatch) + 1).toString().padStart(7, '0');
				this.setState({
					sequentialNumber: nextBatch,
					lastSequentialNumber: nextBatch.toString().padStart(7, '0')
				});
			} else {
				this.setState({
					sequentialNumber: '0000001',
					lastSequentialNumber: '0000000'
				});
			}
		} catch (error) {
			console.error('Erro ao buscar último número sequencial', error);
			this.setState({
				sequentialNumber: '0000001',
				lastSequentialNumber: '0000000'
			});
		}
	};

	saveFilters = () => {
		localStorage.setItem('shipmentFilter', JSON.stringify(this.state.filters));
		localStorage.setItem('shipmentFlags', JSON.stringify(this.state.flags));
	};

	fetchByFilter = (data) => {
		var params = {
			...data,
			is_paid: false,
			type: 'monthly',
			securitized: false,
			to_be_securitized: true
		};

		return getAllByParams(params);
	};

	validateSequentialNumber = (value) => {
		// Verificar se é um número válido de 7 dígitos
		if (!/^\d{1,7}$/.test(value)) {
			return 'O número sequencial deve conter apenas dígitos (máximo 7)';
		}

		// Verificar se é maior que o último número sequencial
		const currentValue = parseInt(value);
		const lastValue = parseInt(this.state.lastSequentialNumber) - 1;

		if (currentValue <= lastValue) {
			return `O número sequencial deve ser maior ou igual a ${this.state.lastSequentialNumber}`;
		}

		return '';
	};

	handleSequentialChange = (e) => {
		const value = e.target.value.replace(/\D/g, ''); // Remove caracteres não numéricos
		const paddedValue = value.padStart(7, '0');
		const error = this.validateSequentialNumber(value);

		this.setState({ sequentialNumber: paddedValue, sequentialError: error });
	};

	incrementSequential = () => {
		const currentValue = parseInt(this.state.sequentialNumber);
		const newValue = (currentValue + 1).toString().padStart(7, '0');
		const error = this.validateSequentialNumber(newValue.replace(/^0+/, '')); // Remove zeros à esquerda para validação

		this.setState({ sequentialNumber: newValue, sequentialError: error });
	};

	decrementSequential = () => {
		const currentValue = parseInt(this.state.sequentialNumber);
		if (currentValue > 1) {
			const newValue = (currentValue - 1).toString().padStart(7, '0');
			const error = this.validateSequentialNumber(newValue.replace(/^0+/, '')); // Remove zeros à esquerda para validação

			this.setState({ sequentialNumber: newValue, sequentialError: error });
		}
	};

	createShipment = async () => {
		this.setState({ loading: true });
		if (this.state.shipment_billings.length == 0) {
			alert('Não é possível criar uma remessa sem cobranças');
			this.setState({ loading: false });
			return;
		}

		// Verificar se o número sequencial é válido
		const error = this.validateSequentialNumber(this.state.sequentialNumber.replace(/^0+/, ''));
		if (error) {
			this.setState({ errors: [error], loading: false });
			return;
		}

		const body = {
			shipment_billings: this.state.shipment_billings.map((billing) => billing.id),
			batch: parseInt(this.state.sequentialNumber) // Enviar o número sequencial para o backend
		};

		await createNewShipment(body).catch((error) => {
			this.setState({ errors: ['Erro ao criar remessa'], loading: false });
		}).then((response) => {
			if (response.status == 200) {
				this.setState({ loading: false, shipment_id: response.data?.data?.id, success: ['Remessa criada com sucesso'] });
			}
		});
	}

	calcFaceVolume = () => {
		let volume = 0;


		this.state.shipment_billings.map((billing) => {
			if (billing.anticipated_values?.length > 0) {
				volume += billing.anticipated_values.reduce((acc, curr) => acc + curr.retained_value, 0);
			} else {
				volume += billing.value;
			}
		})

		return formatBRL(volume);
	}

	FiltersModule = ({ filtroModular, index }) => {
		const getFilterChoised = (filter) => {
			let component = <></>

			if (filter == 'due_date') {
				component = (
					<>
						<div className='d-flex'>
							<Label htmlFor="due_date_gte" className="col-form-label">De</Label>
							<Input className="form-control" type="date" id="due_date_gte" value={this.state.filters.due_date_gte} style={{ width: 200, margin: '0 16px' }}
								onChange={(e) => this.setState({ filters: { ...this.state.filters, due_date_gte: e.target.value } }, () => this.saveFilters())} />
						</div>

						<div className='d-flex'>
							<Label htmlFor="due_date_lte" className="col-form-label">Até</Label>
							<Input className="form-control" type="date" id="due_date_lte" value={this.state.filters.due_date_lte} style={{ width: 200, margin: '0 16px' }}
								onChange={(e) => this.setState({ filters: { ...this.state.filters, due_date_lte: e.target.value } }, () => this.saveFilters())} />
						</div>
					</>
				)
			} else if (filter == 'status') {
				component = (
					<div className='d-flex'>
						<select onChange={(e) => this.setState({ filters: { ...this.state.filters, status: e.target.value } }, () => this.saveFilters())} style={{ width: 400, margin: '0 16px' }} className="custom-select">
							<option value="" hidden>Selecionar</option>
							<option value="waiting">Aguardando</option>
							<option value="open">Em aberto</option>
							<option value="warranty_and_late">Garantido e atrasado</option>
							<option value="anticipated">Antecipado</option>
						</select>
					</div>
				)
			} else if (filter == 'landlord') {
				component = (
					<div className='d-flex'>
						<select onChange={(e) => this.setState({ filters: { ...this.state.filters, landlord: e.target.value } }, () => this.saveFilters())} style={{ width: 400, margin: '0 16px' }} className="custom-select">
							<option value="" hidden>Selecionar</option>
							<option value="mellro_tec">Mell.ro TEC</option>
							<option value="landlord">Responsável</option>
						</select>
					</div>
				)
			} else {
				component = <></>
			}

			return component;
		}

		const onSetFilter = (filtro) => {
			let stateFilter = this.state.newFilters.slice();
			stateFilter[index] = filtro;

			this.setState({ newFilters: stateFilter })
		}

		const getSelectOptions = (nameFiltro) => {
			const stateFilter = this.state.newFilters;

			if (stateFilter.includes(nameFiltro)) {
				return 'd-none'
			} else {
				return ''
			}
		}

		const removeFilter = (filterUnit, filterName) => {
			let stateFilter = this.state.newFilters;

			stateFilter.splice(filterUnit, 1);

			switch (filterName) {
				case 'due_date':
					this.setState({
						flags: { ...this.state.flags, due_date_gte: false, due_date_lte: false },
						filters: { ...this.state.filters, due_date_gte: null, due_date_lte: null }
					}, () => this.saveFilters())

					break;

				case 'status':
					this.setState({
						flags: { ...this.state.flags, status: false },
						filters: { ...this.state.filters, status: null }
					}, () => this.saveFilters())

					break;

				case 'landlord':
					this.setState({
						flags: { ...this.state.flags, landlord: false },
						filters: { ...this.state.filters, landlord: null }
					}, () => this.saveFilters())

					break;

				default:
			}
			this.setState({ newFilters: stateFilter })
		}

		return (
			<Col md={12} className="d-flex m-0 p-0 filtroUnit">
				<div className='d-flex'>
					<select onChange={({ target }) => onSetFilter(target.value)} value={this.state.newFilters[index]} className="custom-select" style={{ width: 300 }}>
						<option value="">Selecione um filtro</option>
						<option className={getSelectOptions('due_date')} value="due_date">Vencimento</option>
						<option className={getSelectOptions('status')} value="status">Status</option>
						<option className={getSelectOptions('landlord')} value="landlord">Dono da cobrança</option>
					</select>

					{this.state.newFilters[index] != '' && (
						<div className='divisionArrow'>
							<FontAwesomeIcon icon={faChevronRight} />
						</div>
					)}

					{getFilterChoised(this.state.newFilters[index])}
				</div>

				<div onClick={() => removeFilter(index, this.state.newFilters[index])}>
					<FontAwesomeIcon icon={faTimes} />
				</div>
			</Col>
		)
	}


	render() {

		return (
			<React.Fragment>
				{this.state.success.length > 0 ? (
					<Redirect
						to={{
							pathname: `/pagamento/securitizacao/remessa/show/${this.state.shipment_id}`,
							state: { success: ["Remessa criada com sucesso!"] },
						}}
					/>
				) : null}

				<div className="page-content container-page userIndex sec-shipment">
					<Container fluid>
						<div className='header'>
							<h1>Remessas e Retornos</h1>

							<Breadcrumbs breadcrumbItems={[{ link: '/pagamento/cobrancas/index', title: 'Pagamentos' }, { link: '/announcements/index', title: 'Remessas e Retornos' }]} />
						</div>

						<BetterAlerts errors={this.state.errors} success={this.state.success} />

						<Col md={12} className="box-filtros">
							<Col md={12} className="d-flex m-0 p-0">
								<div className='generalSearch'>
									<Input className="inputCustomNew" type="text" value={this.state.filters.searchAll} placeholder="Busque por contrato ou uma das partes envolvidas"
										onChange={(e) => this.setState({ filters: { ...this.state.filters, searchAll: e.target.value } }, () => this.saveFilters())} />

									<FontAwesomeIcon icon={faMagnifyingGlass} />
								</div>

								<button onClick={() => this.setState({ newFilters: [...this.state.newFilters, ''] })} className="btnSecundary" style={{ width: 'max-content', margin: '0px 8px', padding: '8px 12px' }}>
									FILTRO
									<FontAwesomeIcon icon={faFilter} />
								</button>

								<button onClick={() => this.dataTableRef.current.refreshTable()} className="btnSecundary" style={{ width: 'max-content', margin: '0px 8px', padding: '8px 12px' }}>
									ATUALIZAR
									<FontAwesomeIcon icon={faArrowRotateRight} />
								</button>
							</Col>

							{this.state.newFilters.map((filtroModular, index) => {
								return <this.FiltersModule filtroModular={filtroModular} key={index} index={index} />
							})}
						</Col>

						<Row>
							<Col lg={12}>
								<Card className='card-general'>
									<div className='datatable-box'>
										<BetterCircularProgress loading={this.state.loading}>
											<DataTableSelectBillings filters={this.state.filters} noDelete fetchEvent={this.fetchByFilter} crudButtons={true}
												crudUrl="/pagamento/negativacao/index" idRow={`id`} export={this.state.export} ref={this.dataTableRef} state={this.state.shipment_billings} setState={this.setState.bind(this)} />
										</BetterCircularProgress>
									</div>
								</Card>
							</Col>
						</Row>

						<Row>
							<Col lg={12}>
								<Card className='card-general'>
									<div className='datatable-box'>
										<BetterCircularProgress loading={this.state.loading}>
											<div className='d-flex flex-column'>
												<h3 className='sec-shipment-subtitle-label mb-1' style={{ fontSize: '16px', color: '#252B3B' }}>Nº Sequencial da Remessa (7 dígitos)</h3>
												<p className='mb-1' style={{ fontSize: '14px', color: '#505D69' }}>Informe o número sequencial para a remessa. O valor sugerido é:</p>

												<div className='d-flex align-items-center'>
													<div className='d-flex align-items-center' style={{ width: '200px' }}>
														<Input type="number" value={this.state.sequentialNumber} onChange={this.handleSequentialChange} className="form-control" maxLength={7} style={{ textAlign: 'center' }} />
													</div>

													{this.state.sequentialError && (
														<div className='ml-3 text-danger'>{this.state.sequentialError}</div>
													)}

													{!this.state.sequentialError && (
														<div className='ml-3 text-success'>O número sequencial deve ser maior ou igual a {this.state.lastSequentialNumber}</div>
													)}
												</div>
											</div>
										</BetterCircularProgress>
									</div>
								</Card>
							</Col>
						</Row>

						<Row>
							<Col lg={12}>
								<Card className='card-general'>
									<div className='datatable-box'>
										<BetterCircularProgress loading={this.state.loading}>
											<div className='d-flex justify-content-between align-items-center'>
												<div className='d-flex'>
													<div>
														<h3 className='sec-shipment-subtitle-label'>Cobranças</h3>
														<h1 className='sec-shipment-subtitle-value'>{this.state.shipment_billings.length}</h1>
													</div>

													<div style={{ marginLeft: 34 }}>
														<h3 className='sec-shipment-subtitle-label'>Volume face</h3>
														<h1 className='sec-shipment-subtitle-value' style={{ color: '#1CBB8C' }}>{this.calcFaceVolume()}</h1>
													</div>
												</div>

												<div className='d-flex'>
													<button onClick={() => this.props.history.goBack()} className="btnSecundary" style={{ width: 'max-content', margin: '0px 8px', padding: '8px 12px' }}>
														<FontAwesomeIcon icon={faArrowLeft} />
														VOLTAR
													</button>

													<button onClick={async () => await this.createShipment()} className="buttonPrimary w-100" style={{ width: 'max-content', margin: '0px 8px', padding: '8px 12px' }}>
														CRIAR REMESSA
														<FontAwesomeIcon icon={faCheck} />
													</button>
												</div>
											</div>
										</BetterCircularProgress>
									</div>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}

export default SecuritizationCreate;