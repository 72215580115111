import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Link } from "react-router-dom";
import { Column } from 'primereact/column';
import _ from 'lodash';
import moment from 'moment';

export default class DataTableNotifications extends Component {
	constructor() {
		super();

		this.state = {
			data: [],
			rows: 10,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: 'id',
			sortOrder: '1',
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		let notificationsDataTable = JSON.parse(localStorage.getItem('notificationsDataTable'));

		if (notificationsDataTable) {
			this.setState({
				rows: notificationsDataTable.rows,
				totalRecords: notificationsDataTable.totalRecords,
				first: notificationsDataTable.first,
				last: notificationsDataTable.last,
				loading: notificationsDataTable.loading,
				sortField: notificationsDataTable.sortField,
				sortOrder: notificationsDataTable.sortOrder,
			}, () => {
				this.fetchData();
			});
		} else {
			this.fetchData();
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters != this.props.filters) {
			this.onFilterChanged();
		}
	}

	onFilterChanged = _.debounce(() => {
		if (!this.state.isLoading) {
			this.fetchData();
		}
	}, 700);

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = async (event) => {
		if (event.data) {
			event.data = null;
		}

		let notificationsDataTable = {
			rows: event.rows,
			totalRecords: this.state.totalRecords,
			first: event.first,
			last: event.rows + event.first,
			loading: this.state.loading,
			sortField: this.state.sortField,
			sortOrder: this.state.sortOrder,
		}

		localStorage.setItem('notificationsDataTable', JSON.stringify(notificationsDataTable));

		this.setState({
			...this.state,
			rows: event.rows,
			loading: true
		});

		this.fetchData(event.rows, event.first);
	};

	fetchData = (limit, skip) => {
		let data = {
			$limit: limit ? limit : this.state.rows,
			$skip: skip != null ? skip : this.state.first,
			$sort: { [this.state.sortField]: this.state.sortOrder }
		};

		this.props.fetchEvent(data).then(res => {
			this.setState({
				...this.state,
				data: res.data.data,
				totalRecords: res.data.total,
				first: res.data.skip,
				last: res.data.skip + res.data.limit,
				loading: false
			});
		});
	};

	columnOpcoesCrud = (rowData, column) => {
		let idRow = this.props.idRow ? this.props.idRow : 'id';

		if (this.props.crudUrl) {
			return (
				<div style={{ textAlign: 'center' }}>
					<Link to={`${this.props.crudUrl}/show/${rowData[idRow]}`} className="btn btn-primary btn-table-action mr-2"><i className="fas fa-eye"></i></Link>
				</div>
			);
		} else {
			return null;
		}
	};

	getUserName = (obj) => {
		if (obj.user) {
			return (
				<div style={{ textAlign: 'center' }}>
					<Link to={`/users/show/${obj.user.id}`} >{obj.user.name}</Link>
				</div>
			)
		}
	};

	// export() {
	//     this.dt.exportCSV();
	// };

	sortTable = e => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder }, () => {
			let notificationsDataTable = {
				rows: this.state.rows,
				totalRecords: this.state.totalRecords,
				first: this.state.first,
				last: this.state.last,
				loading: this.state.loading,
				sortField: this.state.sortField,
				sortOrder: this.state.sortOrder,
			};

			localStorage.setItem('notificationsDataTable', JSON.stringify(notificationsDataTable));

			this.fetchData();
		});
	};

	getDateFormatted = (date) => {
		return (
			<p>{moment(date).format('DD/MM/YYYY HH:mm:ss')}</p>
		)
	}

	render() {
		const header = (
			<div style={{ float: 'right' }}>
				{/* <Button className="tableHeaderItem" type="button" style={{ marginRight: '5px', marginLeft: '5px' }} icon="fas fa-file-csv" iconPos="left" label="CSV" onClick={() => this.export()} /> */}
				<Button className="tableHeaderItem" icon={!this.state.loading ? "fas fa-sync" : "fas fa-sync fa-spin"} disabled={this.state.loading ? "disabled" : ""} onClick={() => this.refreshTable()} />
			</div>
		);

		return (
			<DataTable ref={(el) => this.dt = el} value={this.state.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={[10, 25, 50]}
				first={this.state.first} last={this.state.last} header={this.props.noHeader ? null : header} responsive={true}
				totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} loadingIcon={`fas fa-sync fa-spin`}
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
				currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" emptyMessage="Nenhum registro encontrado!"
				onSort={(e) => this.sortTable(e)} sortField={this.state.sortField} sortOrder={this.state.sortOrder} >
				<Column field="category" header="Categoria" />
				<Column field="channel" header="Canal" />
				<Column field="title" header="Título" />
				<Column field="user.name" body={(obj, i) => this.getUserName(obj)} header="Usuário" />
				<Column sortable field="created_at" body={(obj, i) => this.getDateFormatted(obj.created_at)} header="Criado em" />
				<Column sortable field="dispatched_at" body={(obj, i) => this.getDateFormatted(obj.dispatched_at)} header="Enviado em" />
				<Column sortable field="delay_delivery_until" body={(obj, i) => this.getDateFormatted(obj.delay_delivery_until)} header="Entrega adiada até" />
				<Column header="Ações" body={this.columnOpcoesCrud} style={{ width: '130px' }} />
			</DataTable>
		);
	}
}