import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Link } from "react-router-dom";
import { Column } from 'primereact/column';
import { deleteJob, enableJob, disableJob } from '../../crud/jobs.crud';
import _ from 'lodash';

export default class DataTableCrawlerConfig extends Component {
    constructor() {
        super();

        this.state = {
            data: [],
            totalRecords: 0,
            first: 0,
            last: 0,
            loading: false,
            sortField: 'jobName',
            sortOrder: 1,
        };

        this.onPage = this.onPage.bind(this);
    }


    componentDidMount() {
        let crawlerConfigsDataTable = JSON.parse(localStorage.getItem('crawlerConfigsDataTable'));

        if (crawlerConfigsDataTable) {
            this.setState({
                totalRecords: crawlerConfigsDataTable.totalRecords,
                first: crawlerConfigsDataTable.first,
                last: crawlerConfigsDataTable.last,
                loading: crawlerConfigsDataTable.loading,
                sortField: crawlerConfigsDataTable.sortField,
                sortOrder: crawlerConfigsDataTable.sortOrder,
            }, () => {
                this.fetchData();
            });
        } else {
            this.fetchData();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.filters != this.props.filters) {
            this.onFilterChanged(true);
        }
    }

    onFilterChanged = _.debounce((showLoading) => {
        if (!this.state.isLoading) {
            this.setState({
                loading: showLoading,
            }, () => {
                this.fetchData();
            });
        }
    }, 700);

    refreshTable() {
        this.onPage(this.state);
    }

    onPage = async (event) => {
        if (event.data) {
            event.data = null;
        }

        let crawlerConfigsDataTable = {
            totalRecords: this.state.totalRecords,
            first: event.first,
            last: event.rows + event.first,
            loading: this.state.loading,
            sortField: this.state.sortField,
            sortOrder: this.state.sortOrder,
        };

        localStorage.setItem('crawlerConfigsDataTable', JSON.stringify(crawlerConfigsDataTable));

        this.setState({
            loading: true
        });

        this.fetchData();
    };

    sortData = (data, sortField, sortOrder) => {
        return data.sort((a, b) => {
            return (a[sortField].toLowerCase() < b[sortField].toLowerCase()) ? sortOrder : sortOrder - (sortOrder * 2);
        });
    };

    fetchData = () => {
        this.setState({ loading: true });
        this.props.fetchEvent().then(async res => {
            this.setState({
                data: this.sortData(res.data, this.state.sortField, this.state.sortOrder),
                totalRecords: res.data.length,
                loading: false
            });
        });
    };

    deleteOneJob = (rowData) => {
        this.setState({ loading: true });
        deleteJob({ name: rowData.name }).then(() => {
            this.fetchData();
        });
    };

    jobSwitch = async (rowData) => {
        this.setState({ loading: true });
        if (rowData.state === "DISABLED") {
            await enableJob({ name: rowData.name });
        } else {
            await disableJob({ name: rowData.name });
        }
        this.refreshTable();
    };

    columnOpcoesCrud = (rowData, column) => {
        let idRow = this.props.idRow ? this.props.idRow : 'id';

        if (this.props.crudUrl) {
            return (
                <div style={{ textAlign: 'center' }}>
                    <Link to={`${this.props.crudUrl}/edit/${rowData[idRow]}`} className="btn btn-success btn-table-action mr-2"><i className="fa fa-edit"></i></Link>
                    <Link to={`${this.props.crudUrl}/show/${rowData[idRow]}`} className="btn btn-primary btn-table-action mr-2"><i className="fas fa-eye"></i></Link>
                    <div onClick={() => this.jobSwitch(rowData)} className={`btn ${(rowData.state === "ENABLED") ? 'btn-primary' : 'btn-danger'} btn-table-action mr-2`}><i className="fas fa-check-double"></i></div>
                    <div onClick={() => this.deleteOneJob(rowData)} className="btn btn-primary btn-table-action mr-2"><i className="fas fa-trash-alt"></i></div>
                </div>
            );
        } else {
            return null;
        }
    };

    export() {
        this.dt.exportCSV();
    };

    getJob = (obj) => {
        let idRow = this.props.idRow ? this.props.idRow : 'id';
        if (obj[idRow]) {
            return (
                <div style={{ textAlign: 'left' }}>
                    <Link to={`${this.props.crudUrl}/show/${obj[idRow]}`} >{obj.name}</Link>
                </div>
            );
        } else {
            return null;
        }
    };

    sortTable = e => {
        this.setState({
            sortField: e.sortField,
            sortOrder: e.sortOrder,
        }, () => {
            let crawlerConfigsDataTable = {
                totalRecords: this.state.totalRecords,
                first: this.state.first,
                last: this.state.last,
                loading: this.state.loading,
                sortField: this.state.sortField,
                sortOrder: this.state.sortOrder,
            };

            localStorage.setItem('crawlerConfigsDataTable', JSON.stringify(crawlerConfigsDataTable));

            this.fetchData();
        });
    };


    render() {
        const header = (
            <div style={{ float: 'right' }}>
                <Button className="tableHeaderItem" type="button" style={{ marginRight: '5px', marginLeft: '5px' }} icon="fas fa-file-csv" iconPos="left" label="CSV" onClick={() => this.export()} />
                <Button className="tableHeaderItem" icon={!this.state.loading ? "fas fa-sync" : "fas fa-sync fa-spin"} disabled={this.state.loading ? "disabled" : ""} onClick={() => this.refreshTable()} />
            </div>
        );

        return (
            <DataTable ref={(el) => this.dt = el} value={this.state.data} paginator={false}
                first={this.state.first} last={this.state.last} header={this.props.noHeader ? null : header} responsive={true}
                totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} loadingIcon={`fas fa-sync fa-spin`}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" emptyMessage="Nenhum registro encontrado!"
                onSort={(e) => this.sortTable(e)} sortField={this.state.sortField} sortOrder={this.state.sortOrder} >
                <Column sortable field="jobName" header="jobName" />
                <Column sortable field="name" body={(obj, i) => this.getJob(obj)} header="Cidade" />
                <Column sortable field="scheduleText" header="Evento Programado" />
                <Column sortable field="state" header="Estado"></Column>
                <Column sortable field="cityCode" header="Região"></Column>
                <Column sortable field="description" header="Descrição"></Column>
                <Column header="Opções" body={this.columnOpcoesCrud} style={{ width: '220px' }} />

            </DataTable>
        );
    }
}