import React, { Component, createRef } from 'react';
import { Container, Card, Row, Col, Label, Input, } from "reactstrap";
import Breadcrumbs from '../../../components/Breadcrumb';
import BetterAlerts from '../../../components/BetterAlerts';
import BetterCircularProgress from '../../../components/BetterCircularProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faXmarkCircle, faCheck, faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { faUser, faUserMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { getGratificationById, getMonthlyBonus, updateGratification } from '../../../crud/users.crud';
import { Link } from "react-router-dom";
import moment from 'moment';
import DataTableListMonthlyBonus from '../../../components/datatables/DataTableListMonthlyBonus';
import Modal from 'react-bootstrap/Modal';

class GratificationShow extends Component {
	dataTableRef = createRef();

	state = {
		flags: {
			created_at_lte: null,
			created_at_gte: null,
			status: null,
			landlord: null
		},
		filters: {
			created_at_lte: null,
			created_at_gte: null,
			status: null,
			landlord: null
		},
		is_filter_open: false,
		errors: [],
		success: [],
		activeTab: '1',
		filtersCount: 0,
		newFilters: [],
		indicadores: {},
		totalAtual: 0,
		list_users: [],
		termo_user: '',
		monthly_bonus: [],
		gratification: null,
		showRemoveGratification: false,
		agreeRemove: false
	};

	componentDidMount = () => {
		getGratificationById(this.props.match.params.id).then((response) => {
			if (response.status == 200) {
				this.setState({ gratification: response.data })
			}
		})

		if (this.props?.location?.state?.success) {
			this.setState({ success: [this.props.location.state.success] })
		}
	};

	fetchByFilter = (data) => {
		var params = {
			...data,
			gratification_id: this.props.match.params.id
		};

		return getMonthlyBonus(params);
	};

	handleClose = () => {
		this.setState({ showRemoveGratification: false, agreeRemove: false });
	}

	handleUpdateToRemoved = async () => {
		await updateGratification(this.props.match.params.id, { status: 'REMOVED' }).then((response) => {
			this.setState({
				success: ['Gratificação removida com sucesso!'],
			})

			this.setState({ gratification: response.data })
		}).catch(err => {
			this.setState({
				errors: ['Não foi possivel remover a gratificação. Contate o suporte.'],
			})
		}).finally(() => {
			this.setState({ showRemoveGratification: false, agreeRemove: false })
		})
	}

	render() {

		return (
			<React.Fragment>
				<div className="page-content container-page userIndex sec-shipment">
					<Container fluid>
						<div className='header'>
							<h1>Detalhes da gratificação</h1>

							<Breadcrumbs breadcrumbItems={[{ link: '/pagamento/cobrancas/index', title: 'Pagamentos' }, { link: '/pagamento/gratificacao/index', title: 'Gratificações' }, { link: '/pagamento/gratificacao/show', title: `Detalhes da gratificação` }]} />
						</div>

						<BetterAlerts errors={this.state.errors} success={this.state.success} fullWidth={true} />
						<div className="userShow userNew negociacao">
							<div className='boxUser'>
								<div className='headerUser' style={{ alignItems: 'center', justifyContent: 'normal', gap: '32px' }}>
									<div className=' groupAlign d-flex' style={{ alignItems: 'center' }}>
										<div className='tagGroup'>
											{this.state.gratification?.user?.profile_image?.medium?.url ?
												<div className='userPhoto' style={{ backgroundImage: `url(${this.state.gratification?.user?.profile_image?.medium?.url})` }} />
												:
												<div className='userPhotoNone'>
													<FontAwesomeIcon icon={faUser} />
												</div>
											}
										</div>

										<div className='boxInformation'>
											<p style={{ marginBottom: 0 }}>Usuário</p>
											<div>
												<FontAwesomeIcon icon={faUserMagnifyingGlass} style={{ color: '#4AA3FF', marginRight: 8 }} />
												<Link to={`/users/show/${this.state?.gratification?.user_id}`} style={{ color: "#4AA3FF", fontWeight: 'bold' }}>{this.state?.gratification?.user?.name}</Link>
											</div>
										</div>
									</div>

									<div className=' groupAlign'>
										<p style={{ marginBottom: 0 }}>Status</p>

										<div className='tagGroup'>
											{this.state.gratification?.status == 'REMOVED' && (
												<div className='tag' style={{ backgroundColor: '#8590A5' }}>Removido</div>
											)}

											{this.state.gratification?.status == 'ACTIVE' && (
												<div className='tag' style={{ backgroundColor: '#1CBB8C' }}>Ativo</div>
											)}
										</div>
									</div>

									<div className=' groupAlign'>
										<div className='boxInformation'>
											<p style={{ marginBottom: 0 }}>Cargo</p>
											<p style={{ fontWeight: 600, fontFamily: 'Inter', color: '#343A40', marginBottom: 0 }}>{this.state?.gratification?.role}</p>
										</div>
									</div>

									<div className=' groupAlign'>
										<div className='boxInformation'>
											<p style={{ marginBottom: 0 }}>Taxa de gratificação</p>
											<p style={{ fontWeight: 600, fontFamily: 'Inter', color: '#343A40', marginBottom: 0 }}>{this.state?.gratification?.bonus_rate}%</p>
										</div>
									</div>

									<div className=' groupAlign'>
										<div className='boxInformation'>
											<p style={{ marginBottom: 0 }}>Data de adição</p>
											<p style={{ fontWeight: 600, fontFamily: 'Inter', color: '#343A40', marginBottom: 0 }}>{moment(this.state?.gratification?.created_at).format('DD/MM/YYYY HH:mm:ss')}</p>
										</div>
									</div>

									<div className=' groupAlign'>
										<div className='boxInformation'>
											<p style={{ marginBottom: 0 }}>Atualizado em</p>

											<p style={{ fontWeight: 600, fontFamily: 'Inter', color: '#343A40', marginBottom: 0 }}>{moment(this.state?.gratification?.updated_at).format('DD/MM/YYYY HH:mm:ss')}</p>

										</div>
									</div>
								</div>

								<div className='footerAssets'>

									<>
										<Link to={`/pagamento/gratificacao/edit/${this.state?.gratification?.id}`} className="buttonPrimary w-100" style={{ width: '100%', maxWidth: '126px', margin: '0px 8px' }}>
											EDITAR
											<FontAwesomeIcon icon={faPenToSquare} />
										</Link>
									</>

									<>
										<button className='buttonRemove' onClick={() => this.setState({ showRemoveGratification: true })}>
											REMOVER
											<FontAwesomeIcon icon={faXmarkCircle} />
										</button>
									</>
								</div>
							</div>
						</div>

						<Row>
							<Col lg={12}>
								<Card className='card-general'>
									<p style={{ color: '#8590A5', fontSize: '16px', fontWeight: 'bold', fontFamily: 'Inter', marginBottom: 24 }}>GRATIFICAÇÕES</p>
									<div className='datatable-box'>
										<BetterCircularProgress loading={this.state.loading}>
											<DataTableListMonthlyBonus filters={this.state.filters} noDelete fetchEvent={this.fetchByFilter} crudButtons={true}
												crudUrl="/pagamento/gratificacao/index" gratification_id={this.props.match.params.id} idRow={`id`} export={this.state.export} ref={this.dataTableRef} />
										</BetterCircularProgress>
									</div>
								</Card>
							</Col>
						</Row>

						<Modal dialogClassName="modalCancelarNegociacao" centered show={this.state?.showRemoveGratification} onHide={this.handleClose}>
							<Modal.Header closeButton={false}>
								<Modal.Title>Tem certeza que deseja remover as gratificações deste usuário?</Modal.Title>

								<button onClick={this.handleClose} style={{ backgroundColor: 'transparent', color: '#8590A5', display: 'flex', alignItems: 'center', border: 'none', fontWeight: '900', fontSize: '18px' }}>
									X
								</button>
							</Modal.Header>
							<Modal.Body>
								<div style={{ marginTop: 16 }}>
									<p>Lembre-se que ao fazer isto as gratificações já levantadas <span style={{ color: '#FF3D60', fontWeight: 600 }}>nesse mês ainda serão pagas.</span></p>

									<Label className="pl-2 input-radio-group">
										<Input type="radio" name={'agreeRemove'} id={`agreeRemove`} value={!this.state.agreeRemove} onChange={() => this.setState({ agreeRemove: !this.state.agreeRemove })} color="primary" checked={this.state.agreeRemove} />
										<Label style={{ padding: "3px" }} for={`agreeRemove`}>
											Confirmo remover as gratificações do usuário.
										</Label>
									</Label>
								</div>
							</Modal.Body>
							<Modal.Footer>
								<button className='btnSecundary' onClick={this.handleClose}>
									<FontAwesomeIcon icon={faArrowLeft} />
									VOLTAR
								</button>

								<button className='buttonPrimary' disabled={!this.state.agreeRemove} onClick={async () => await this.handleUpdateToRemoved()}>
									CONFIRMAR
									<FontAwesomeIcon icon={faCheck} />
								</button>
							</Modal.Footer>
						</Modal>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}

export default GratificationShow;