import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Link } from "react-router-dom";
import { Column } from 'primereact/column';
import _ from 'lodash';
import { CircularProgress } from "@material-ui/core";
import { getRawById } from '../../crud/announcements.crud';
import moment from 'moment';

export default class DataTableAnnouncementsNotifications extends Component {
    constructor() {
        super();

        this.state = {
            data: [],
            rows: 10,
            totalRecords: 0,
            first: 0,
            last: 0,
            loading: false,
            sortField: 'id',
            sortOrder: '-1',
        };

        this.onPage = this.onPage.bind(this);
    }

    componentDidMount() {
        this.onPage(this.state);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.filters != this.props.filters) {
            this.onFilterChanged();
        }
    }

    onFilterChanged = _.debounce(() => {
        if (!this.state.isLoading) {
            this.setState({
                loading: true,
                rows: 10,
                totalRecords: 0,
                first: 0,
                last: 0,
            }, () => { this.fetchData() });
        }
    }, 700);

    refreshTable() {
        this.onPage(this.state);
    }

    onPage = async (event) => {
        if (event.data) {
            event.data = null;
        }

        this.setState({
            ...this.state,
            rows: event.rows,
            loading: true
        });

        this.fetchData(event.rows, event.first);
    };

    fetchData = (limit, skip) => {
        let data = {
            $limit: limit ? limit : this.state.rows,
            $skip: skip != null ? skip : this.state.first,
            $sort: { [this.state.sortField]: this.state.sortOrder }
        };

        this.props.fetchEvent(data).then(async res => {
            await res.data.data.map(async (obj, i) => {
                if (obj.metadata?.raw_announcement_id) {
                    obj.loading_crawler_data = true;
                    await getRawById(obj.metadata?.raw_announcement_id).then(res => {
                        if (res?.data?.data) {
                            obj.crawler_data = res.data.data;
                        }
                    });
                }

                this.setState({
                    ...this.state,
                    data: res.data.data,
                    totalRecords: res.data.total,
                    first: res.data.skip,
                    last: res.data.skip + res.data.limit,
                    loading: false
                });
            });

            this.setState({
                ...this.state,
                data: res.data.data,
                totalRecords: res.data.total,
                first: res.data.skip,
                last: res.data.skip + res.data.limit,
                loading: false
            });
        });
    };

    columnOpcoesCrud = (rowData, column) => {
        let idRow = this.props.idRow ? this.props.idRow : 'id';

        if (this.props.crudUrl) {
            return (
                <div style={{ textAlign: 'center' }}>
                    <Link to={`${this.props.crudUrl}/show/${rowData[idRow]}`} className="btn btn-primary btn-table-action mr-2"><i className="fas fa-eye"></i></Link>
                </div>
            );
        } else {
            return null;
        }
    };

    export() {
        this.dt.exportCSV();
    };

    getIsPublished = (obj) => {
        if (obj.is_published) {
            return (
                <div style={{ textAlign: 'center' }}>
                    <i className="ri-checkbox-circle-line align-middle ri-2x"></i>
                </div>
            )
        } else {
            return (
                <div style={{ textAlign: 'center' }}>
                    <i className="ri-close-circle-line align-middle ri-2x"></i>
                </div>
            )
        }
    };

    getCrawlerData = (obj) => {
        if (obj.crawler_data) {
            return (
                <div style={{ textAlign: 'center' }}>
                    <p>{`${obj.crawler_data.source} ${obj.crawler_data.source_id}`}</p>
                </div>
            )
        } else {
            if (obj.loading_crawler_data) {
                return (
                    <div style={{ textAlign: 'center' }}>
                        <CircularProgress color="inherit" style={{ color: "#4AA3FF" }} size={15} />
                    </div>
                )
            }
        }
    };

    getUserName = (obj) => {
        if (obj.user) {
            return (
                <div style={{ textAlign: 'center' }}>
                    <Link to={`/users/show/${obj.user.id}`} >{obj.user.name}</Link>
                </div>
            )
        }
    };

    sortTable = e => {
        this.setState({ sortField: e.sortField, sortOrder: e.sortOrder }, () => this.fetchData());
    };

    getDateFormatted = (date) => {
        return (
            <p>{moment(date).format('DD/MM/YYYY HH:mm:ss')}</p>
        )
    }

    render() {
        const header = (
            <div style={{ float: 'right' }}>
                <Button className="tableHeaderItem" type="button" style={{ marginRight: '5px', marginLeft: '5px' }} icon="fas fa-file-csv" iconPos="left" label="CSV" onClick={() => this.export()} />
                <Button className="tableHeaderItem" icon={!this.state.loading ? "fas fa-sync" : "fas fa-sync fa-spin"} disabled={this.state.loading ? "disabled" : ""} onClick={() => this.refreshTable()} />
            </div>
        );

        return (
            <DataTable ref={(el) => this.dt = el} value={this.state.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={[10, 25, 50]}
                first={this.state.first} last={this.state.last} header={this.props.noHeader ? null : header} responsive={true}
                totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} loadingIcon={`fas fa-sync fa-spin`}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" emptyMessage="Nenhum registro encontrado!"
                onSort={(e) => this.sortTable(e)} sortField={this.state.sortField} sortOrder={this.state.sortOrder} >
                <Column sortable field="category" header="Categoria" />
                <Column sortable field="channel" header="Canal" />
                <Column sortable field="title" header="Título" />
                <Column field="user.name" body={(obj, i) => this.getUserName(obj)} header="Usuário" />
                <Column sortable field="created_at" body={(obj, i) => this.getDateFormatted(obj.created_at)} header="Criado em" />
                <Column sortable field="dispatched_at" body={(obj, i) => this.getDateFormatted(obj.dispatched_at)} header="Enviado em" />
                <Column sortable field="delay_delivery_until" header="Entrega adiada até" />
                <Column header="Ações" body={this.columnOpcoesCrud} style={{ width: '130px' }} />
            </DataTable>
        );
    }
}