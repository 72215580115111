import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";
import { FiltersModuleAnnouncementIndicators, FiltersModuleContractIndicators, findMetricInfo } from "../filters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical, faEyeSlash, faGripVertical, faHouseBuilding, faHouseCircleXmark, faHouseFlag, faHouseHeart, faMapLocation, faMapLocationDot, faMaximize, faMinimize } from '@fortawesome/pro-solid-svg-icons';
import { Checkbox, ListItemText, Menu, MenuItem } from "@material-ui/core";
import { getProperties } from "../../../crud/announcements.crud";

export default class AnnouncementIndicatorSection extends Component {
	constructor() {
		super();

		this.state = {
			metrics: [],
			announcementsIndicatorsAvailableLabels: [
				{ label: "EM CONTRATO ATIVO", isActive: true },
				{ label: "PUBLICADOS E ATIVOS", isActive: true },
				{ label: "PUBLICADOS PELO RASTREADOR DE IMÓVEIS", isActive: true },
				{ label: "REGIÕES RASTREADAS", isActive: true },
				{ label: "IMÓVEIS INATIVOS", isActive: true }
			],
			dropdownKey: null,
			anchorEl: null,
			counter: 0,
			announcementIndicatorsFilters: {
				"time_frame": "always"
			},
		};
	}

	componentDidMount() {
		if (this?.props?.metrics) {
			this.setState({ metrics: this.props.metrics })

			getProperties().then(res => {
				this.setState({ counter: res?.data?.total })
			})
		}
	}

	render() {
		return (
			<Card>
				<CardBody className="dashboard-item">
					<div className="box-header">
						<div className="">
							<h3 className="title">Indicadores de imóveis</h3>
							<span className="count-indicator">{this.state?.counter}</span>
							<div className="box-info">
								Registro
							</div>
						</div>

						<div>
							<FontAwesomeIcon icon={this.props.layout?.w === 6 ? faMaximize : faMinimize} style={{ color: '#5664D2', padding: '12px', cursor: 'pointer' }} onClick={() => this.props.toggleMaximize(this.props?.itemKey)} />
							<FontAwesomeIcon icon={faEllipsisVertical} style={{ color: '#5664D2', padding: '12px', cursor: 'pointer' }} onClick={(e) => {
								this.setState({ dropdownKey: this.props.itemKey });
								this.setState({ anchorEl: e.currentTarget });
							}} />
							<FontAwesomeIcon icon={faEyeSlash} style={{ color: '#5664D2', padding: '12px', cursor: 'pointer' }} onClick={() => this.props.changeWidgetValue(this.props.itemKey)} />
							<FontAwesomeIcon icon={faGripVertical} style={{ color: '#DADBDD', padding: '12px', cursor: 'pointer' }} />
						</div>
					</div>

					<Menu
						anchorEl={this.state.anchorEl}
						open={this.state.dropdownKey === this.props.itemKey ? true : false}
						onClose={() => { this.setState({ dropdownKey: null }) }}
						className="dashboard-item"
						id="dashboard-drop"
					>
						{this.state.announcementsIndicatorsAvailableLabels.map((item) => (
							<MenuItem key={item.label} value={item.label}>
								<Checkbox checked={item.isActive} onChange={() => {
									this.setState(prevState => ({
										announcementsIndicatorsAvailableLabels: prevState.announcementsIndicatorsAvailableLabels.map(i =>
											i.label === item.label
												? { ...i, isActive: !i.isActive }
												: i
										)
									}));
								}} />
								<ListItemText primary={item.label} />
							</MenuItem>
						))}
					</Menu>

					<div className="box-filter">
						<FiltersModuleAnnouncementIndicators setState={this.setState.bind(this)} state={this.state.announcementIndicatorsFilters} />
					</div>

					<div style={{ position: 'relative', marginTop: '10px' }}>
						<hr className="divisor" />
					</div>

					<div className="card-wrapper">
						<div className="card-box" style={{ display: this.state.announcementsIndicatorsAvailableLabels.find(obj => obj?.label === 'EM CONTRATO ATIVO')?.isActive ? '' : 'none', minHeight: 80 }}>
							<h3>EM CONTRATO <br />ATIVO</h3>

							<h2>
								{findMetricInfo(this.state.metrics, [this.state.announcementIndicatorsFilters.time_frame], 'ANNOUNCEMENT_INDICATOR_ACTIVE_CONTRACTS', 'count')}
								<FontAwesomeIcon icon={faHouseHeart} />
							</h2>
						</div>

						<div className="card-box" style={{ display: this.state.announcementsIndicatorsAvailableLabels.find(obj => obj?.label === 'PUBLICADOS E ATIVOS')?.isActive ? '' : 'none', minHeight: 80 }}>
							<h3>PUBLICADOS E ATIVOS</h3>

							<h2>
								{findMetricInfo(this.state.metrics, [this.state.announcementIndicatorsFilters.time_frame], 'ANNOUNCEMENT_INDICATOR_PUBLISHEDS_AND_ACTIVES', 'count')}
								<FontAwesomeIcon icon={faHouseBuilding} />
							</h2>
						</div>


						<div className="card-box" style={{ display: this.state.announcementsIndicatorsAvailableLabels.find(obj => obj?.label === 'PUBLICADOS PELO RASTREADOR DE IMÓVEIS')?.isActive ? '' : 'none', minHeight: 80 }}>
							<h3>PUBLICADOS PELO <br />RASTREADOR DE IMÓVEIS</h3>

							<h2>
								{findMetricInfo(this.state.metrics, [this.state.announcementIndicatorsFilters.time_frame], 'ANNOUNCEMENT_INDICATOR_BY_CRAWLER', 'count')}
								<FontAwesomeIcon icon={faHouseFlag} />
							</h2>
						</div>

						<div className="card-box" style={{ display: this.state.announcementsIndicatorsAvailableLabels.find(obj => obj?.label === 'REGIÕES RASTREADAS')?.isActive ? '' : 'none', minHeight: 80 }}>
							<h3>REGIÕES<br /> RASTREADAS</h3>

							<h2>
								{findMetricInfo(this.state.metrics, [this.state.announcementIndicatorsFilters.time_frame], 'ANNOUNCEMENT_INDICATOR_REGIONS_CRAWLER', 'count')}
								<FontAwesomeIcon icon={faMapLocationDot} />
							</h2>
						</div>

						<div className="card-box" style={{ display: this.state.announcementsIndicatorsAvailableLabels.find(obj => obj?.label === 'IMÓVEIS INATIVOS')?.isActive ? '' : 'none', minHeight: 80 }}>
							<h3>IMÓVEIS INATIVOS</h3>

							<h2>
								{findMetricInfo(this.state.metrics, [this.state.announcementIndicatorsFilters.time_frame], 'ANNOUNCEMENT_INDICATOR_INACTIVES', 'count')}
								<FontAwesomeIcon icon={faHouseCircleXmark} />
							</h2>
						</div>
					</div>
				</CardBody>
			</Card>
		)
	}
}


