import axios from "axios";
import { Constants } from '../helpers/Constants';

export function get(userId) {
	return axios.get(`${Constants.baseUrl}/income-users/${userId}`);
}

export function update(userId, data) {
	return axios.patch(`${Constants.baseUrl}/income-users/${userId}`, data);
}

export function store(data) {
	return axios.post(`${Constants.baseUrl}/income-users`, data);
}

export function updateIncome(userId, data) {
	return axios.patch(`${Constants.baseUrl}/users/${userId}`, { employment: data });
}