import React, { Component } from "react";
import { Container, Card, CardBody, Col, FormGroup, Label, Button, Input } from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import BetterCircularProgress from '../../components/BetterCircularProgress';
import BetterAlerts from "../../components/BetterAlerts";
import Breadcrumbs from "../../components/Breadcrumb";
import { get as getProposition, approveRefuseCredit } from "../../crud/propositions.crud";
import { formatDate } from '../negotiation/helpers';
import onSubmit from "../negotiation/helpers/on-submit";
import { RadioComponent } from '../negotiation/components/radio.component';
import { TenantComponent } from "./components/tenant.component";
import { formatBRL } from "../../helpers/Utils";
import moment from "moment";

class GeradorContratoEdit extends Component {
	state = {
		timeout: null,
		submitted: false,
		loading: false,
		success: false,
		errors: [],
		data: {
			id: '',
			landlord: {},
			tenant: {},
			start_date: formatDate({ format: 'YYYY-MM-DD' }),
			duration: 0,
			security_deposit_months: 0,
			announcement: {
				values: {
					iptu: 0,
					condo: 0,
					base_rent: 0
				},
				tenant_pays: {
					iptu: true,
					condo: true
				}
			},
			tenant_parties: [],
			plan: {}
		},
	};

	componentDidMount = () => {
		this.setState({ loading: true });

		getProposition(this.props.match.params.id).catch(err => {

		}).then(res => {
			if (res?.status == 200) {
				console.log(res.data.data);
			}

			this.setState({ data: res.data.data, loading: false });
		});
	};

	onSubmit = (values) => {
		this.setState({ loading: true, data: { ...this.state.data, ...values }, errors: [] });
		onSubmit(values, true)
			.then((res) => {
				this.setState({ submitted: true, success: !(Boolean(res.data.error)), errors: res.data.error ? [res.data.error] : [] });
			}).catch((err) => {
				if (Array.isArray(err)) {
					this.setState({ errors: [...this.state.errors, ...err] });
				} else {
					this.setState({ errors: [...this.state.errors, err] });
				}
			}).finally(() => {
				this.setState({ loading: false });
			});
	};

	getData = async () => {
		const negotiation = this.props.location?.state?.negotiation;
		if (!negotiation) {
			const { data } = await getProposition(this.props.match.params.id);
			return data.data;
		}
		return negotiation;
	};

	handleCreditApproval = (e, approved) => {
		e.preventDefault();

		approveRefuseCredit(this.props.match.params.id, { approved }).catch(err => { }).then(res => {
			this.setState({ success: true });
		});
	}

	render() {
		return (
			<React.Fragment>
				<div className="page-content container-page">
					<Container fluid>
						<Breadcrumbs title="Editar negociação" breadcrumbItems={[]} />
						<BetterAlerts errors={this.state.errors} />

						{this.state.success ? (
							<Redirect to={{ pathname: "/gerador-contrato/index", state: { success: ["Registro editado com sucesso!"] }, }} />
						) : null}
						<BetterCircularProgress loading={this.state.loading}>
							<Card>
								<CardBody>
									<Col md={12} className="d-flex">
										<Col md={4} className="rounded" style={{ background: "#F1F5F7" }}>
											<Col>
												<Label className="col-form-label mb-1" style={{ color: "#8590A5", fontSize: "16px" }} >
													Partes
												</Label>
											</Col>
											<Col md={12}>
												<FormGroup>
													<Label className="col-form-label">Proprietário</Label>
													<Input className="form-control" type="text" value={this.state.data?.landlord?.name} disabled />
												</FormGroup>
											</Col>
											<Col md={12}>
												<FormGroup>
													<Label className="col-form-label">Imóvel</Label>
													<Input className="form-control" type="text" value={this.state.data?.property?.address ? this.state.data?.property?.address?.city + ' - ' + this.state.data?.property?.address?.street : ''} disabled />
												</FormGroup>
											</Col>

											{this.state.data?.parties?.length ?
												<>
													<Col md={12}>
														<FormGroup>
															<Label className="col-form-label">
																Inquilinos
															</Label>
														</FormGroup>
													</Col>
													{this.state.data?.parties.map((obj, i) => {
														return (
															<TenantComponent key={i} disabled label={`inquilino ${i + 1}`}
																onBlur={null} onChange={null} value={obj} />
														);
													})}
												</>
												: null
											}

										</Col>
										<Col className="rounded ml-2 mr-2" md={4} style={{ background: "#F9F9F9", height: "672px" }} >
											<Col>
												<Label className="col-form-label mb-2"
													style={{ color: '#8590A5', fontSize: '16px' }} >
													Pagamentos
												</Label>
											</Col>
											<Col md={12}>
												<FormGroup>
													<Label className="col-form-label">Valor do aluguel</Label>
													<Input className="form-control" type="text" value={formatBRL(this.state.data?.details?.values?.base_rent)} disabled />
												</FormGroup>
											</Col>
											<RadioComponent disabled name="iptu" onChange={null} onBlur={null}
												value={this.state.data?.details?.tenant_pays?.iptu} label="IPTU" />
											<Col md={12}>
												<FormGroup>
													<Label className="col-form-label">Valor do IPTU</Label>
													<Input className="form-control" type="text" value={formatBRL(this.state.data?.details?.values?.iptu)} disabled />
												</FormGroup>
											</Col>
											<RadioComponent disabled name="condo" onChange={null} onBlur={null}
												value={this.state.data?.details?.tenant_pays?.condo} label="Condomínio" />
											<Col md={12}>
												<FormGroup>
													<Label className="col-form-label">Valor do Condomínio</Label>
													<Input className="form-control" type="text" value={formatBRL(this.state.data?.details?.values?.condo)} disabled />
												</FormGroup>
											</Col>
										</Col>
										<Col md={4} style={{ background: '#F1F5F7', height: '672px' }}>
											<Col>
												<Label className="col-form-label mb-2"
													style={{ color: '#8590A5', fontSize: '16px' }} 	>
													Detalhes & Plano
												</Label>
											</Col>
											<Col md={12}>
												<FormGroup>
													<Label className="col-form-label">Data do início do contrato</Label>
													<Input className="form-control" type="text" value={moment(this.state.data?.details?.start_date).format('DD/MM/YYYY')} disabled />
												</FormGroup>
											</Col>
											<Col md={12}>
												<FormGroup>
													<Label className="col-form-label">Tempo de contrato</Label>
													<Input className="form-control" type="text" value={`${this.state.data?.details?.duration} meses`} disabled />
												</FormGroup>
											</Col>
											<Col md={12}>
												<FormGroup>
													<Label className="col-form-label">Plano</Label>
													<Input className="form-control" type="text" value={`${this.state.data?.details?.warranty_type}`} disabled />
												</FormGroup>
											</Col>
											{this.state.data?.details?.warranty_type === 'SECURITY_DEPOSIT' ?
												<Col md={12}>
													<FormGroup>
														<Label className="col-form-label">Quantidade de meses de caução</Label>
														<Input className="form-control" type="text" value={`${this.state.data?.property?.warranty?.security_deposit_months}`} disabled />
													</FormGroup>
												</Col>
												: null
											}
										</Col>
									</Col>
								</CardBody>
								<CardBody className="row ml-2">
									<Link to="/gerador-contrato/index" className="btn btn-secondary btn-bold">
										<i className="fa fa-arrow-left mr-2"></i>Voltar
									</Link>

									<Button disabled={this.state.data?.status != 'AWAITING_CREDIT_REVIEW'} color="success" type="button" onClick={e => this.handleCreditApproval(e, true)}
										className="waves-effect waves-light ml-3" >
										Aprovar contrato <i className="fas fa-check align-middle ml-2"></i>
									</Button>

									<Button disabled={this.state.data?.status != 'AWAITING_CREDIT_REVIEW'} color="danger" type="button" onClick={e => this.handleCreditApproval(e, false)}
										className="waves-effect waves-light ml-3" 	>
										Reprovar contrato <i className="fas fa-check align-middle ml-2"></i>
									</Button>
								</CardBody>
							</Card>
						</BetterCircularProgress>
					</Container>
				</div>
			</React.Fragment >
		);
	}
}

export default GeradorContratoEdit;
