import React, { Component } from 'react';
import { Container, Card, CardBody, Row, Col, FormGroup, Label, Button, Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from "reactstrap";
import Breadcrumbs from '../../components/Breadcrumb';
import { getAllByFilter as getAllVouchers } from '../../crud/vouchers.crud';
import DataTableVouchers from '../../components/datatables/DataTableVouchers';
import { Link } from "react-router-dom";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from "@material-ui/core";
import _ from 'lodash';
import { getByFilter as getUsers } from '../../crud/users.crud';
import BetterAlerts from '../../components/BetterAlerts';

class VouchersIndex extends Component {

    state = {
        errors: [],
        success: [],
        filters: {
            user: '',
        },
        flags: {
            show_user: false,
        },
        is_filter_open: false,
        list_users: [],
        termo_user: '',
    }

    componentDidMount = () => {
        let filters = this.state.filters;
        let flags = this.state.flags;

        if (localStorage.getItem('vouchersFilters')) {
            filters = JSON.parse(localStorage.getItem('vouchersFilters'));
        };

        if (localStorage.getItem('vouchersFlags')) {
            flags = JSON.parse(localStorage.getItem('vouchersFlags'));
        };

        this.setState({ filters, flags });

        if (this.props.location.state) {
            if (this.props.location.state.success) {
                this.setState({ success: this.props.location.state.success });
            }
        }
    };

    saveFilters = () => {
        localStorage.setItem('vouchersFilters', JSON.stringify(this.state.filters));
        localStorage.setItem('vouchersFlags', JSON.stringify(this.state.flags));
    };

    fetchByFilter = (data) => {
        let params = { ...data };

        if (this.state.flags.show_user && this.state.filters.user) params.owner_id = this.state.filters.user.id;

        return getAllVouchers(params);
    };


    onUserInputChange = (termo) => {
        let filters = { ...this.state.filters };

        if (termo === '') {
            filters.user = '';
        }

        this.setState({ termo_user: termo, filters });
        this.onChangeUser();
    }

    onChangeUser = _.debounce(() => {
        this.buscarUsuarios(this.state.termo_user);
    }, 500);

    buscarUsuarios = (termo) => {
        if (termo.trim() === '') {
            this.setState({ list_users: [] });
        } else {
            getUsers({ $limit: 20, $skip: 0, name_ilike: termo }).then(res => {
                this.setState({ list_users: res.data.data });
            });
        }
    }

    getUserName = (option) => {
        if (option) {
            return option.name;
        }

        return '';
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content container-page">
                    <Container fluid>
                        <Breadcrumbs title="Vouchers" breadcrumbItems={[]} />
                        <BetterAlerts errors={this.state.errors} success={this.state.success} />

                        <Row className="align-right mb-30">
                            <Dropdown className="mr-10 wm-100" isOpen={this.state.is_filter_open} toggle={() => this.setState({ is_filter_open: !this.state.is_filter_open })} >
                                <DropdownToggle className="btn-index-action" color="primary" caret>
                                    <i className="ri-filter-3-line align-middle mr-2"></i> {"ADD FILTRO"}
                                </DropdownToggle>
                                <DropdownMenu>
                                    {!this.state.flags.show_user ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_user: true } })}>Usuário</DropdownItem> : null}
                                </DropdownMenu>
                            </Dropdown>

                            <Link to={`/vouchers/new`} className="btn-index-action button btn btn-success waves-effect waves-light mr-10">
                                <i className="ri-add-line align-middle mr-2"></i> CRIAR
                            </Link>
                        </Row>

                        {this.state.flags.show_user ?
                            <Row className="container-center-input">
                                <Col md={6}>
                                    <FormGroup row>
                                        <Label className="col-md-2 col-form-label">Usuário</Label>
                                        <div className="col-10 col-sm-8">
                                            <div className="form-group fg-line mb-0">
                                                <Autocomplete disableClearable noOptionsText="No results." value={this.state.filters.user}
                                                    onChange={(event, value) => { this.setState({ filters: { ...this.state.filters, user: value }, list_users: [] }, () => this.saveFilters()) }}
                                                    onInputChange={(event, termo) => this.onUserInputChange(termo)} options={this.state.list_users}
                                                    getOptionLabel={(option) => this.getUserName(option)} style={{ height: 10 }}
                                                    renderInput={(params) => <TextField  {...params} variant="outlined" />} />
                                            </div>
                                        </div>
                                        <Button onClick={() => this.setState({ flags: { ...this.state.flags, show_user: false, filters: { ...this.state.filters, user: '' } } }, () => this.saveFilters())} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
                                            <i className="ri-close-circle-line align-middle"></i>
                                        </Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                            : false
                        }

                        <Row className="container-center-input">
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <DataTableVouchers filters={this.state.filters} noDelete fetchEvent={this.fetchByFilter} crudButtons={true}
                                            crudUrl="/vouchers" idRow={`id`} noShow />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default VouchersIndex;