import React, { Component } from 'react';
import { Container, Card, CardBody, Row, Col, FormGroup, Label, Input, Button, ButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from "reactstrap";
import Breadcrumbs from '../../components/Breadcrumb';
import { getRaw as getCrawlerData } from '../../crud/announcements.crud';
import DataTableCrawler from '../../components/datatables/DataTableCrawler';
import BetterAlerts from '../../components/BetterAlerts';

class CrawlerIndex extends Component {

    state = {
        errors: [],
        success: [],
        filters: {
            approved: '',
            converted: '',
            list_city: [],
            announcement_published: '',
            source: '',
            source_id: '',
            link: '',
            user_name: '',
            user_phone: '',
            user_id: '',
            sms_sent_after: '',
            sms_sent_before: '',
            sms_link_sent: false,
            sms_accessed_after: '',
            sms_accessed_before: '',
            sms_link_accessed: false,
            created_after: '',
            created_before: '',
            city: '',
        },
        flags: {
            show_approved: false,
            show_converted: false,
            show_list_city: false,
            show_announcement_published: false,
            show_source: false,
            show_source_id: false,
            show_link: false,
            show_user_name: false,
            show_user_phone: false,
            show_user_id: false,
            show_sms_sent_after: false,
            show_sms_sent_before: false,
            show_sms_link_sent: false,
            show_sms_accessed_after: false,
            show_sms_accessed_before: false,
            show_sms_link_accessed: false,
            show_created_after: false,
            show_created_before: false,
        },
        is_filter_open: false,
    }

    componentDidMount = () => {
        let filters = this.state.filters;
        let flags = this.state.flags;

        if (localStorage.getItem('crawlerFilters')) {
            filters = JSON.parse(localStorage.getItem('crawlerFilters'));
        };

        if (localStorage.getItem('crawlerFlags')) {
            flags = JSON.parse(localStorage.getItem('crawlerFlags'));
        };

        this.setState({ filters, flags });

        if (this.props.location.state) {
            if (this.props.location.state.success) {
                this.setState({ success: this.props.location.state.success });
            }
        }
    };

    saveFilters = () => {
        localStorage.setItem('crawlerFilters', JSON.stringify(this.state.filters));
        localStorage.setItem('crawlerFlags', JSON.stringify(this.state.flags));
    };

    fetchByFilter = (data) => {
        let params = { ...data, $limit: 10 };

        if (this.state.flags.show_user_name && this.state.filters.user_name.trim() != '') params.editable = { ...params.editable, ['landlord_name_ilike']: this.state.filters.user_name };
        if (this.state.flags.show_user_phone && this.state.filters.user_phone.trim() != '') params.editable = { ...params.editable, ['landlord_phone_ilike']: this.state.filters.user_phone };
        if (this.state.flags.show_user_id && this.state.filters.user_id.trim() != '') params.editable = { ...params.editable, ['landlord_id_ilike']: this.state.filters.user_id };
        if (this.state.flags.show_sms_sent_after && this.state.filters.sms_sent_after != '') params.metadata = { ...params.metadata, ['signup_link_sent_at_gte']: this.state.filters.sms_sent_after };
        if (this.state.flags.show_sms_sent_before && this.state.filters.sms_sent_before != '') params.metadata = { ...params.metadata, ['signup_link_sent_at_lte']: this.state.filters.sms_sent_before };
        if (this.state.flags.show_sms_link_sent) params.metadata = { ...params.metadata, ['signup_link_sent']: this.state.filters.sms_link_sent };
        if (this.state.flags.show_sms_accessed_after && this.state.filters.sms_accessed_after != '') params.metadata = { ...params.metadata, ['signup_link_access_at_gte']: this.state.filters.sms_accessed_after };
        if (this.state.flags.show_sms_accessed_before && this.state.filters.sms_accessed_before != '') params.metadata = { ...params.metadata, ['signup_link_access_at_lte']: this.state.filters.sms_accessed_before };
        if (this.state.flags.show_sms_link_accessed) params.metadata = { ...params.metadata, ['signup_link_accessed']: this.state.filters.sms_link_accessed };
        if (this.state.flags.show_approved && this.state.filters.approved.trim() != '') params.approved = this.state.filters.approved;
        if (this.state.flags.show_converted && this.state.filters.converted.trim() != '') params.converted = this.state.filters.converted;
        if (this.state.flags.show_announcement_published && this.state.filters.announcement_published.trim() != '') params.announcement_is_published = this.state.filters.announcement_published;
        if (this.state.flags.show_source && this.state.filters.source.trim() != '') params.source = this.state.filters.source;
        if (this.state.flags.show_source_id && this.state.filters.source_id.trim() != '') params.source_id = this.state.filters.source_id;
        if (this.state.flags.show_link && this.state.filters.link.trim() != '') params.link_ilike = this.state.filters.link;
        if (this.state.flags.show_created_after && this.state.filters.created_after != '') params.created_at_gte = `${this.state.filters.created_after}T03:00:00.000Z`;
        if (this.state.flags.show_created_before && this.state.filters.created_before != '') params.created_at_lte = this.state.filters.created_before;
        if (this.state.flags.show_list_city && this.state.filters.city != '') params.location = { ...params.location, ['municipality_ilike']: this.state.filters.city }

        params.$select = '*';

        return getCrawlerData(params);
    };

    onChangeCity = (value, i) => {
        let list_city = this.state.filters.list_city;
        list_city[i] = value;

        this.setState({ filters: { ...this.state.filters, list_city } }, () => this.saveFilters());
    };

    onDeleteCity = (i) => {
        let list_city = this.state.filters.list_city;
        list_city.splice(i, 1);

        this.setState({ filters: { ...this.state.filters, list_city } }, () => this.saveFilters());
    }

    onAddCity = () => {
        let list_city = this.state.filters.list_city;
        list_city.push('');

        this.setState({ filters: { ...this.state.filters, list_city } });
    };


    render() {
        return (
            <React.Fragment>
                <div className="page-content container-page">
                    <Container fluid>
                        <Breadcrumbs breadcrumbItems={[{ link: '/crawler/index', title: 'Crawler' }]} />
                        <BetterAlerts errors={this.state.errors} success={this.state.success} />

                        <Row className="align-right mb-30">
                            <ButtonDropdown direction="left" className="mr-10 wm-100" isOpen={this.state.is_filter_open} toggle={() => this.setState({ is_filter_open: !this.state.is_filter_open })} >
                                <DropdownToggle className="btn-index-action" color="primary" caret>
                                    <i className="ri-filter-3-line align-middle mr-2"></i> {"ADD FILTRO"}
                                </DropdownToggle>
                                <DropdownMenu>
                                    {!this.state.flags.show_approved ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_approved: true } })}>Aprovado</DropdownItem> : null}
                                    {!this.state.flags.show_converted ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_converted: true } })}>Convertido</DropdownItem> : null}
                                    {!this.state.flags.show_list_city ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_list_city: true } })}>Cidade</DropdownItem> : null}
                                    {!this.state.flags.show_announcement_published ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_announcement_published: true } })}>Imóvel publicado</DropdownItem> : null}
                                    {!this.state.flags.show_source ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_source: true } })}>Fonte</DropdownItem> : null}
                                    {!this.state.flags.show_source_id ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_source_id: true } })}>ID da fonte</DropdownItem> : null}
                                    {!this.state.flags.show_link ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_link: true } })}>Link</DropdownItem> : null}
                                    {!this.state.flags.show_user_name ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_user_name: true } })}>Nome do usuário</DropdownItem> : null}
                                    {!this.state.flags.show_user_phone ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_user_phone: true } })}>Telefone do usuário</DropdownItem> : null}
                                    {!this.state.flags.show_user_id ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_user_id: true } })}>ID do usuário</DropdownItem> : null}
                                    {!this.state.flags.show_sms_sent_after ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_sms_sent_after: true } })}>SMS link enviado depois de</DropdownItem> : null}
                                    {!this.state.flags.show_sms_sent_before ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_sms_sent_before: true } })}>SMS link enviado antes de</DropdownItem> : null}
                                    {!this.state.flags.show_sms_link_sent ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_sms_link_sent: true } })}>SMS link enviado</DropdownItem> : null}
                                    {!this.state.flags.show_sms_accessed_after ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_sms_accessed_after: true } })}>SMS link acessado depois de</DropdownItem> : null}
                                    {!this.state.flags.show_sms_accessed_before ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_sms_accessed_before: true } })}>SMS link acessado antes de</DropdownItem> : null}
                                    {!this.state.flags.show_sms_link_accessed ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_sms_link_accessed: true } })}>SMS link acessado</DropdownItem> : null}
                                    {!this.state.flags.show_created_after ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_created_after: true } })}>Criado depois</DropdownItem> : null}
                                    {!this.state.flags.show_created_before ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_created_before: true } })}>Criado antes</DropdownItem> : null}
                                </DropdownMenu>
                            </ButtonDropdown>
                        </Row>

                        <Row className="container-center-input">
                            {this.state.flags.show_approved ?
                                <Col md={6}>
                                    <FormGroup row>
                                        <Label className="col-md-2 col-form-label">Aprovado</Label>
                                        <div className="col-10 col-sm-8 container-center-input">
                                            <select value={this.state.filters.approved} onChange={(e) => this.setState({ filters: { ...this.state.filters, approved: e.target.value } }, () => this.saveFilters())} className="custom-select">
                                                <option value=""></option>
                                                <option value="false">Não</option>
                                                <option value="true">Sim</option>
                                                <option value="null">Aguardando Aprovação</option>
                                            </select>
                                        </div>
                                        <Button onClick={() => this.setState({ flags: { ...this.state.flags, show_approved: false }, filters: { ...this.state.filters, approved: '' } }, () => this.saveFilters())} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
                                            <i className="ri-close-circle-line align-middle"></i>
                                        </Button>
                                    </FormGroup>
                                </Col>
                                : false
                            }

                            {this.state.flags.show_converted ?
                                <Col md={6}>
                                    <FormGroup row>
                                        <Label className="col-md-2 col-form-label">Convertido</Label>
                                        <div className="col-10 col-sm-8 container-center-input">
                                            <select value={this.state.filters.converted} onChange={(e) => this.setState({ filters: { ...this.state.filters, converted: e.target.value } }, () => this.saveFilters())} className="custom-select">
                                                <option value=""></option>
                                                <option value="false">No</option>
                                                <option value="true">Yes</option>
                                            </select>
                                        </div>
                                        <Button onClick={() => this.setState({ flags: { ...this.state.flags, show_converted: false }, filters: { ...this.state.filters, converted: '' } }, () => this.saveFilters())} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
                                            <i className="ri-close-circle-line align-middle"></i>
                                        </Button>
                                    </FormGroup>
                                </Col>
                                : false
                            }

                            {this.state.flags.show_announcement_published ?
                                <Col md={6}>
                                    <FormGroup row>
                                        <Label className="col-md-2 col-form-label">Imóvel publicado</Label>
                                        <div className="col-10 col-sm-8 container-center-input">
                                            <select value={this.state.filters.announcement_published} onChange={(e) => this.setState({ filters: { ...this.state.filters, announcement_published: e.target.value } }, () => this.saveFilters())} className="custom-select">
                                                <option value=""></option>
                                                <option value="false">No</option>
                                                <option value="true">Yes</option>
                                            </select>
                                        </div>
                                        <Button onClick={() => this.setState({ flags: { ...this.state.flags, show_announcement_published: false }, filters: { ...this.state.filters, announcement_published: '' } }, () => this.saveFilters())} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
                                            <i className="ri-close-circle-line align-middle"></i>
                                        </Button>
                                    </FormGroup>
                                </Col>
                                : false
                            }

                            {this.state.flags.show_source ?
                                <Col md={6}>
                                    <FormGroup row>
                                        <Label className="col-md-2 col-form-label">Fonte</Label>
                                        <div className="col-10 col-sm-8 container-center-input">
                                            <Input className="form-control" type="text" value={this.state.filters.source}
                                                onChange={(e) => this.setState({ filters: { ...this.state.filters, source: e.target.value } }, () => this.saveFilters())} />
                                        </div>
                                        <Button onClick={() => this.setState({ flags: { ...this.state.flags, show_source: false }, filters: { ...this.state.filters, source: '' } }, () => this.saveFilters())} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
                                            <i className="ri-close-circle-line align-middle"></i>
                                        </Button>
                                    </FormGroup>
                                </Col>
                                : false
                            }

                            {this.state.flags.show_source_id ?
                                <Col md={6}>
                                    <FormGroup row>
                                        <Label className="col-md-2 col-form-label">ID da fonte</Label>
                                        <div className="col-10 col-sm-8 container-center-input">
                                            <Input className="form-control" type="text" value={this.state.filters.source_id}
                                                onChange={(e) => this.setState({ filters: { ...this.state.filters, source_id: e.target.value } }, () => this.saveFilters())} />
                                        </div>
                                        <Button onClick={() => this.setState({ flags: { ...this.state.flags, show_source_id: false }, filters: { ...this.state.filters, source_id: '' } }, () => this.saveFilters())} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
                                            <i className="ri-close-circle-line align-middle"></i>
                                        </Button>
                                    </FormGroup>
                                </Col>
                                : false
                            }

                            {this.state.flags.show_link ?
                                <Col md={6}>
                                    <FormGroup row>
                                        <Label className="col-md-2 col-form-label">Link</Label>
                                        <div className="col-10 col-sm-8 container-center-input">
                                            <Input className="form-control" type="text" value={this.state.filters.show_link}
                                                onChange={(e) => this.setState({ filters: { ...this.state.filters, link: e.target.value } }, () => this.saveFilters())} />
                                        </div>
                                        <Button onClick={() => this.setState({ flags: { ...this.state.flags, show_link: false }, filters: { ...this.state.filters, link: '' } }, () => this.saveFilters())} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
                                            <i className="ri-close-circle-line align-middle"></i>
                                        </Button>
                                    </FormGroup>
                                </Col>
                                : false
                            }

                            {this.state.flags.show_user_name ?
                                <Col md={6}>
                                    <FormGroup row>
                                        <Label className="col-md-2 col-form-label">Nome do usuário</Label>
                                        <div className="col-10 col-sm-8 container-center-input">
                                            <Input className="form-control" type="text" value={this.state.filters.user_name}
                                                onChange={(e) => this.setState({ filters: { ...this.state.filters, user_name: e.target.value } }, () => this.saveFilters())} />
                                        </div>
                                        <Button onClick={() => this.setState({ flags: { ...this.state.flags, show_user_name: false }, filters: { ...this.state.filters, user_name: '' } }, () => this.saveFilters())} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
                                            <i className="ri-close-circle-line align-middle"></i>
                                        </Button>
                                    </FormGroup>
                                </Col>
                                : false
                            }

                            {this.state.flags.show_user_phone ?
                                <Col md={6}>
                                    <FormGroup row>
                                        <Label className="col-md-2 col-form-label">Telefone do usuário</Label>
                                        <div className="col-10 col-sm-8 container-center-input">
                                            <Input className="form-control" type="text" value={this.state.filters.user_phone}
                                                onChange={(e) => this.setState({ filters: { ...this.state.filters, user_phone: e.target.value } }, () => this.saveFilters())} />
                                        </div>
                                        <Button onClick={() => this.setState({ flags: { ...this.state.flags, show_user_phone: false }, filters: { ...this.state.filters, user_phone: '' } }, () => this.saveFilters())} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
                                            <i className="ri-close-circle-line align-middle"></i>
                                        </Button>
                                    </FormGroup>
                                </Col>
                                : false
                            }

                            {this.state.flags.show_user_id ?
                                <Col md={6}>
                                    <FormGroup row>
                                        <Label className="col-md-2 col-form-label">ID do usuário</Label>
                                        <div className="col-10 col-sm-8 container-center-input">
                                            <Input className="form-control" type="text" value={this.state.filters.user_id}
                                                onChange={(e) => this.setState({ filters: { ...this.state.filters, user_id: e.target.value } }, () => this.saveFilters())} />
                                        </div>
                                        <Button onClick={() => this.setState({ flags: { ...this.state.flags, show_user_id: false }, filters: { ...this.state.filters, user_id: '' } }, () => this.saveFilters())} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
                                            <i className="ri-close-circle-line align-middle"></i>
                                        </Button>
                                    </FormGroup>
                                </Col>
                                : false
                            }

                            {this.state.flags.show_sms_sent_after ?
                                <Col md={6}>
                                    <FormGroup row>
                                        <Label className="col-md-2 col-form-label">SMS link enviado depois de</Label>
                                        <div className="col-10 col-sm-8 container-center-input">
                                            <Input className="form-control" type="date" value={this.state.filters.sms_sent_after}
                                                onChange={(e) => this.setState({ filters: { ...this.state.filters, sms_sent_after: e.target.value } }, () => this.saveFilters())} />
                                        </div>
                                        <Button onClick={() => this.setState({ flags: { ...this.state.flags, show_sms_sent_after: false }, filters: { ...this.state.filters, sms_sent_after: '' } }, () => this.saveFilters())} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
                                            <i className="ri-close-circle-line align-middle"></i>
                                        </Button>
                                    </FormGroup>
                                </Col>
                                : false
                            }

                            {this.state.flags.show_sms_sent_before ?
                                <Col md={6}>
                                    <FormGroup row>
                                        <Label className="col-md-2 col-form-label">SMS link enviado antes de</Label>
                                        <div className="col-10 col-sm-8 container-center-input">
                                            <Input className="form-control" type="date" value={this.state.filters.sms_sent_before}
                                                onChange={(e) => this.setState({ filters: { ...this.state.filters, sms_sent_before: e.target.value } }, () => this.saveFilters())} />
                                        </div>
                                        <Button onClick={() => this.setState({ flags: { ...this.state.flags, show_sms_sent_before: false }, filters: { ...this.state.filters, sms_sent_before: '' } }, () => this.saveFilters())} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
                                            <i className="ri-close-circle-line align-middle"></i>
                                        </Button>
                                    </FormGroup>
                                </Col>
                                : false
                            }

                            {this.state.flags.show_sms_accessed_after ?
                                <Col md={6}>
                                    <FormGroup row>
                                        <Label className="col-md-2 col-form-label">SMS acessado depois de</Label>
                                        <div className="col-10 col-sm-8 container-center-input">
                                            <Input className="form-control" type="date" value={this.state.filters.sms_accessed_after}
                                                onChange={(e) => this.setState({ filters: { ...this.state.filters, sms_accessed_after: e.target.value } }, () => this.saveFilters())} />
                                        </div>
                                        <Button onClick={() => this.setState({ flags: { ...this.state.flags, show_sms_accessed_after: false }, filters: { ...this.state.filters, sms_accessed_after: '' } }, () => this.saveFilters())} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
                                            <i className="ri-close-circle-line align-middle"></i>
                                        </Button>
                                    </FormGroup>
                                </Col>
                                : false
                            }

                            {this.state.flags.show_sms_accessed_before ?
                                <Col md={6}>
                                    <FormGroup row>
                                        <Label className="col-md-2 col-form-label">SMS acessado antes de</Label>
                                        <div className="col-10 col-sm-8 container-center-input">
                                            <Input className="form-control" type="date" value={this.state.filters.sms_accessed_before}
                                                onChange={(e) => this.setState({ filters: { ...this.state.filters, sms_accessed_before: e.target.value } }, () => this.saveFilters())} />
                                        </div>
                                        <Button onClick={() => this.setState({ flags: { ...this.state.flags, show_sms_accessed_before: false }, filters: { ...this.state.filters, sms_accessed_before: '' } }, () => this.saveFilters())} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
                                            <i className="ri-close-circle-line align-middle"></i>
                                        </Button>
                                    </FormGroup>
                                </Col>
                                : false
                            }

                            {this.state.flags.show_sms_link_sent ?
                                <Col md={6}>
                                    <FormGroup row>
                                        <div className="col-10 col-sm-8 container-center-input">
                                            <div className="mt-4 mt-lg-0">
                                                <div className="custom-control custom-checkbox container-center-input">
                                                    <Input type="checkbox" className="custom-control-input" checked={this.state.filters.sms_link_sent}
                                                        onClick={(e) => this.setState({ filters: { ...this.state.filters, sms_link_sent: !this.state.filters.sms_link_sent } }, () => this.saveFilters())} />
                                                    <Label className="custom-control-label"
                                                        onClick={(e) => this.setState({ filters: { ...this.state.filters, sms_link_sent: !this.state.filters.sms_link_sent } }, () => this.saveFilters())} >SMS link enviado</Label>
                                                </div>
                                            </div>
                                        </div>
                                        <Button onClick={() => this.setState({ flags: { ...this.state.flags, show_sms_link_sent: false }, filters: { ...this.state.filters, sms_link_sent: '' } }, () => this.saveFilters())} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
                                            <i className="ri-close-circle-line align-middle"></i>
                                        </Button>
                                    </FormGroup>
                                </Col>
                                : false
                            }

                            {this.state.flags.show_sms_link_accessed ?
                                <Col md={6}>
                                    <FormGroup row>
                                        <div className="col-10 col-sm-8 container-center-input">
                                            <div className="mt-4 mt-lg-0">
                                                <div className="custom-control custom-checkbox container-center-input">
                                                    <Input type="checkbox" className="custom-control-input" checked={this.state.filters.sms_link_accessed}
                                                        onClick={(e) => this.setState({ filters: { ...this.state.filters, sms_link_accessed: !this.state.filters.sms_link_accessed } }, () => this.saveFilters())} />
                                                    <Label className="custom-control-label"
                                                        onClick={(e) => this.setState({ filters: { ...this.state.filters, sms_link_accessed: !this.state.filters.sms_link_accessed } }, () => this.saveFilters())} >SMS link accessed</Label>
                                                </div>
                                            </div>
                                        </div>
                                        <Button onClick={() => this.setState({ flags: { ...this.state.flags, show_sms_link_accessed: false }, filters: { ...this.state.filters, sms_link_accessed: '' } }, () => this.saveFilters())} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
                                            <i className="ri-close-circle-line align-middle"></i>
                                        </Button>
                                    </FormGroup>
                                </Col>
                                : false
                            }

                            {this.state.flags.show_created_after ?
                                <Col md={6}>
                                    <FormGroup row>
                                        <Label className="col-md-2 col-form-label">Criado depois de</Label>
                                        <div className="col-10 col-sm-8 container-center-input">
                                            <Input className="form-control" type="date" value={this.state.filters.created_after}
                                                onChange={(e) => this.setState({ filters: { ...this.state.filters, created_after: e.target.value } }, () => this.saveFilters())} />
                                        </div>
                                        <Button onClick={() => this.setState({ flags: { ...this.state.flags, show_created_after: false }, filters: { ...this.state.filters, created_after: '' } }, () => this.saveFilters())} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
                                            <i className="ri-close-circle-line align-middle"></i>
                                        </Button>
                                    </FormGroup>
                                </Col>
                                : false
                            }

                            {this.state.flags.show_created_before ?
                                <Col md={6}>
                                    <FormGroup row>
                                        <Label className="col-md-2 col-form-label">Criado antes de</Label>
                                        <div className="col-10 col-sm-8 container-center-input">
                                            <Input className="form-control" type="date" value={this.state.filters.created_before}
                                                onChange={(e) => this.setState({ filters: { ...this.state.filters, created_before: e.target.value } }, () => this.saveFilters())} />
                                        </div>
                                        <Button onClick={() => this.setState({ flags: { ...this.state.flags, show_created_before: false }, filters: { ...this.state.filters, created_before: '' } }, () => this.saveFilters())} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
                                            <i className="ri-close-circle-line align-middle"></i>
                                        </Button>
                                    </FormGroup>
                                </Col>
                                : false
                            }

                            {this.state.flags.show_list_city ?
                                <Col md={6}>
                                    <FormGroup row>
                                        <Label className="col-md-2 col-form-label">Cidade</Label>
                                        <div className="col-10 col-sm-8 container-center-input">
                                            <Input className="form-control" type="text" value={this.state.filters.city}
                                                onChange={(e) => this.setState({ filters: { ...this.state.filters, city: e.target.value } }, () => this.saveFilters())} />
                                        </div>
                                        <Button onClick={() => this.setState({ flags: { ...this.state.flags, show_list_city: false }, filters: { ...this.state.filters, city: '' } }, () => this.saveFilters())} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
                                            <i className="ri-close-circle-line align-middle"></i>
                                        </Button>
                                    </FormGroup>
                                </Col>
                                : false
                            }

                        </Row>

                        <Row className="container-center-input">
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <DataTableCrawler filters={this.state.filters} noDelete fetchEvent={this.fetchByFilter} crudButtons={true}
                                            crudUrl="/crawler" idRow={`id`} noShow />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

export default CrawlerIndex;