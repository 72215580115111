import React, { Component } from 'react';
import { Container, Card, CardBody, FormGroup, Label, Input, Col } from "reactstrap";
import BetterCircularProgress from '../../components/BetterCircularProgress';
import { Link, Redirect } from "react-router-dom";
import moment from 'moment';
import { getById, getRepayment } from '../../crud/billings.crud';
import { formatBRL } from '../../helpers/Utils';
import Breadcrumbs from '../../components/Breadcrumb';
import 'moment/locale/pt-br';
import { get as getUserById } from '../../crud/users.crud';

moment.locale('pt-br');

class BillingReembolsoShow extends Component {
	state = {
		loading: false,
		data: null,
		reembolso: null,
		tenant: null
	}

	componentDidMount = () => {
		this.setState({ loading: true });

		getRepayment(this.props.match.params.id).then(res => {
			this.setState({ reembolso: res.data.data });

			getById(res.data.data.billing_id).then(res => {
				this.setState({ data: res.data.data });
			});

			getUserById(res.data.data.tenant_id).then(res => {
				this.setState({
					tenant: res?.data?.data
				})
			})
		});

		this.setState({ loading: false });
	};

	formatDate(date) {
		return moment(date).utcOffset(+3).format('DD/MMMM/YYYY');
	}

	getRepaymentStatus = () => {
		if (this.state.reembolso?.status === 'pending' && moment().isAfter(moment(this.state.data?.due_date).subtract(10, 'days'))) {
			return (
				<div className='automatic-card'>
					Automático
				</div>
			)
		}

		if (this.state.reembolso?.status === 'pending') {
			return (
				<div className='pending-card'>
					Aguardando
				</div>
			)
		}

		if (this.state.reembolso?.status === 'accepted') {
			return (
				<div className='approved-card'>
					Aprovado
				</div>
			)
		}

		if (this.state.reembolso?.status === 'rejected') {
			return (
				<div className='rejected-card'>
					Negado
				</div>
			)
		}

		return (
			<div className='pending-card'>
				Aguardando
			</div>
		)
	}

	render() {
		return (
			<div className="page-content container-page userNew reembolso">
				<Container fluid className='form'>
					<div style={{ width: '736px' }}>
						<div className='header' style={{ marginBottom: 0 }}>
							<h1>Reembolso</h1>

							<Breadcrumbs breadcrumbItems={[{ link: '/pagamento/cobrancas/index', title: 'Financeiro' }, { link: '/pagamento/cobrancas/index', title: 'Cobranças' }, { link: `#`, title: `ID ${this.props.match.params.id}` }]} />
						</div>

						<div className='box repaymentBoxInfos'>
							<div style={{ display: 'flex', alignItems: 'center', marginBottom: '24px' }}>
								<h5>INFORMAÇÕES</h5>
								{this.getRepaymentStatus()}
							</div>
							<div className='bodyAssets'>
								<div className='boxInformation'>
									<p>Criada em</p>
									<b>{moment(this.state?.reembolso?.created_at).format('DD/MM/YY HH:mm:ss')}</b>
								</div>

								<div className='boxInformation'>
									<p>Prazo da resposta</p>
									<b>{moment(this.state?.data?.due_date).subtract(10, 'days').add(3, 'hours').format('DD/MM/YY HH:mm:ss')}</b>
								</div>

								<div className='boxInformation'>
									<p>Data da resposta</p>
									{moment().isAfter(moment(this.state.data?.due_date).subtract(10, 'days')) && (this.state?.reembolso?.status === null || this.state?.reembolso?.status === 'pending') ? (
										<b>{moment(moment(this.state.data?.due_date).subtract(10, 'days').add(3, 'hours')).format('DD/MM/YY HH:mm:ss')}</b>
									) : (
										<>
											<b>{moment(this.state?.reembolso?.updated_at).format('DD/MM/YY HH:mm') === moment(this.state?.reembolso?.created_at).format('DD/MM/YY HH:mm') ? '--' : moment(this.state?.reembolso?.updated_at).format('DD/MM/YY HH:mm:ss')}</b>
										</>
									)}

								</div>

								<div className='boxInformation'>
									<p>Usuário</p>
									{(this.state?.reembolso?.status === null || this.state?.reembolso?.status === 'pending') && moment().isAfter(moment(this.state.data?.due_date).subtract(10, 'days')) ? (
										<b>Automático</b>
									) : (this.state?.reembolso?.status === 'rejected' || this.state?.reembolso?.status === 'accepted') ? (
										<Link to={`/users/show/${this.state.reembolso?.tenant_id}`} >{this.state?.tenant?.name}</Link>
									) : '--'}
								</div>

							</div>
						</div>

						<div className='box' style={{ padding: '24px' }}>
							<h5>DETALHES</h5>

							<div className='formulario'>
								<Col md={12} className="p-0 m-0">
									<Label>Tipo de reembolso</Label>
									<p className='response'>{this.state?.reembolso?.type == 'addition' ? 'Adição' : 'Desconto'}</p>
								</Col>

								<Col md={12} className="p-0 m-0">
									<Label>O que é a requisição?</Label>
									<p className='response'>{this.state?.reembolso?.title}</p>
								</Col>

								{this.state?.reembolso?.description && (
									<Col md={12} className="p-0 m-0">
										<Label>Descrição</Label>
										<p className='response'>{this.state?.reembolso?.description}</p>
									</Col>
								)}

								<Col md={12} className="p-0 m-0">
									<Label>Valor</Label>
									<p className='response'>{formatBRL(this.state?.reembolso?.amount)}</p>
								</Col>
							</div>
						</div>

						{this.state.reembolso?.images.length > 0 && (
							<div className='box' style={{ padding: '24px' }}>
								<h5>COMPROVANTE E FOTOS (OPCIONAL)</h5>

								<div className='container-fotos'>
									{this.state.reembolso?.images?.map((anexo, index) =>
										<div key={index} className="anexo" onClick={() => window.open(anexo.url)}>
											anexo-{index + 1}
										</div>
									)}
								</div>
							</div>
						)}

						<div className='box-footer'>
							<button onClick={() => this.props.history.goBack()} className='button-secundary'>
								<i className="fa fa-arrow-left" />
								VOLTAR
							</button>
						</div>
					</div>
				</Container>
			</div>
		)
	}
}

export default BillingReembolsoShow;