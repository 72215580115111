import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";
import { FiltersModuleCommissionIndicators, findMetricInfo } from "../filters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullseye, faBullseyeArrow, faEllipsisVertical, faEyeSlash, faGripVertical, faMaximize, faMinimize, faUserBountyHunter } from '@fortawesome/pro-solid-svg-icons';
import { Checkbox, ListItemText, Menu, MenuItem } from "@material-ui/core";
import { formatBRL } from "../../../helpers/Utils";

export default class CommissionIndicatorSection extends Component {
	constructor() {
		super();

		this.state = {
			metrics: [],
			commissionIndicatorsFilters: {
				"user_type": "all",
				"time_frame": "always"
			},
			commissionIndicatorsAvailableLabels: [
				{ label: "COMISSIONADOS  E AFILIADOS ATIVOS", isActive: true },
				{ label: "TOTAL DE INDICADOS", isActive: true },
				{ label: "TOTAL DE INDICADOS CONVERTIDOS ATIVOS", isActive: true },
				{ label: "TOTAL DE INDICADOS CONVERTIDOS INATIVOS", isActive: true },
				{ label: "TOTAL DE COMISSÕES PAGAS", isActive: true },
				{ label: "TOTAL DE COMISSÕES A RESGATAR", isActive: true }
			],
			dropdownKey: null,
			anchorEl: null,
		};
	}

	componentDidMount() {
		if (this?.props?.metrics) {
			this.setState({ metrics: this.props.metrics })
		}
	}

	render() {
		return (
			<Card>
				<CardBody className="dashboard-item">
					<div className="box-header">
						<div className="">
							<h3 className="title">Indicadores de <br />comissionados e afiliados</h3>
							<div className="box-info" style={{ marginLeft: 16 }}>
								Aquisição
							</div>

							{this.props.layout?.w === 12 ? (
								<div className="box-filter" style={{ marginLeft: 16 }}>
									<FiltersModuleCommissionIndicators setState={this.setState.bind(this)} state={this.state.commissionIndicatorsFilters} />
								</div>
							) : null}
						</div>

						<div>
							<FontAwesomeIcon icon={this.props.layout?.w === 6 ? faMaximize : faMinimize} style={{ color: '#5664D2', padding: '12px', cursor: 'pointer' }} onClick={() => this.props.toggleMaximize(this.props?.itemKey)} />
							<FontAwesomeIcon icon={faEllipsisVertical} style={{ color: '#5664D2', padding: '12px', cursor: 'pointer' }} onClick={(e) => {
								this.setState({ dropdownKey: this.props.itemKey });
								this.setState({ anchorEl: e.currentTarget });
							}} />
							<FontAwesomeIcon icon={faEyeSlash} style={{ color: '#5664D2', padding: '12px', cursor: 'pointer' }} onClick={() => this.props.changeWidgetValue(this.props.itemKey)} />
							<FontAwesomeIcon icon={faGripVertical} style={{ color: '#DADBDD', padding: '12px', cursor: 'pointer' }} />
						</div>
					</div>

					<Menu
						anchorEl={this.state.anchorEl}
						open={this.state.dropdownKey === this.props.itemKey ? true : false}
						onClose={() => { this.setState({ dropdownKey: null }) }}
						className="dashboard-item"
						id="dashboard-drop"
					>
						{this.state.commissionIndicatorsAvailableLabels.map((item) => (
							<MenuItem key={item.label} value={item.label}>
								<Checkbox checked={item.isActive} onChange={() => {
									this.setState(prevState => ({
										commissionIndicatorsAvailableLabels: prevState.commissionIndicatorsAvailableLabels.map(i =>
											i.label === item.label
												? { ...i, isActive: !i.isActive }
												: i
										)
									}));
								}} />
								<ListItemText primary={item.label} />
							</MenuItem>
						))}
					</Menu>

					{this.props.layout?.w === 6 ? (
						<div className="box-filter" style={{}}>
							<FiltersModuleCommissionIndicators setState={this.setState.bind(this)} state={this.state.commissionIndicatorsFilters} />
						</div>
					) : null}

					<div style={{ position: 'relative' }}>
						<hr className="divisor" />
					</div>

					<div className="card-wrapper min-h">
						<div className="card-box" style={{ display: this.state.commissionIndicatorsAvailableLabels.find(obj => obj?.label === 'COMISSIONADOS  E AFILIADOS ATIVOS')?.isActive ? '' : 'none' }}>
							<h3>COMISSIONADOS <br /> E AFILIADOS ATIVOS</h3>

							<h2>
								{findMetricInfo(this.state.metrics, [this.state.commissionIndicatorsFilters.user_type, this.state.commissionIndicatorsFilters.time_frame], 'AFFILIATE_INDICATORS_TOTAL', 'count')}
								<FontAwesomeIcon icon={faUserBountyHunter} />
							</h2>
						</div>

						<div className="card-box" style={{ display: this.state.commissionIndicatorsAvailableLabels.find(obj => obj?.label === 'TOTAL DE INDICADOS')?.isActive ? '' : 'none' }}>
							<h3>TOTAL DE <br />INDICADOS</h3>

							<h2>
								{findMetricInfo(this.state.metrics, [this.state.commissionIndicatorsFilters.user_type, this.state.commissionIndicatorsFilters.time_frame], 'AFFILIATE_INDICATORS_INDICATED_TOTAL', 'count')}
								<FontAwesomeIcon icon={faBullseye} />
							</h2>
						</div>

						<div className="card-box" style={{ display: this.state.commissionIndicatorsAvailableLabels.find(obj => obj?.label === 'TOTAL DE INDICADOS CONVERTIDOS ATIVOS')?.isActive ? '' : 'none' }}>
							<h3>TOTAL DE INDICADOS<br />CONVERTIDOS ATIVOS</h3>

							<h2 className="text-green">
								{findMetricInfo(this.state.metrics, [this.state.commissionIndicatorsFilters.user_type, this.state.commissionIndicatorsFilters.time_frame], 'AFFILIATE_INDICATORS_CONVERTED_ACTIVE_TOTAL', 'count')}
								<FontAwesomeIcon icon={faBullseyeArrow} />
							</h2>

							<div className="card-value card-value-green">
								{(findMetricInfo(this.state.metrics, [this.state.commissionIndicatorsFilters.user_type, this.state.commissionIndicatorsFilters.time_frame], 'AFFILIATE_INDICATORS_CONVERTED_ACTIVE_TOTAL', 'sum'))}
							</div>
						</div>

						<div className="card-box" style={{ display: this.state.commissionIndicatorsAvailableLabels.find(obj => obj?.label === 'TOTAL DE INDICADOS CONVERTIDOS INATIVOS')?.isActive ? '' : 'none' }}>
							<h3>TOTAL DE INDICADOS<br /> CONVERTIDOS INATIVOS</h3>

							<h2 className="text-red">
								{findMetricInfo(this.state.metrics, [this.state.commissionIndicatorsFilters.user_type, this.state.commissionIndicatorsFilters.time_frame], 'AFFILIATE_INDICATORS_CONVERTED_INACTIVE_TOTAL', 'count')}
								<FontAwesomeIcon icon={faBullseyeArrow} />
							</h2>

							<div className="card-value">
								{findMetricInfo(this.state.metrics, [this.state.commissionIndicatorsFilters.user_type, this.state.commissionIndicatorsFilters.time_frame], 'AFFILIATE_INDICATORS_CONVERTED_INACTIVE_TOTAL', 'sum')}

							</div>
						</div>

						<div className="card-box" style={{ display: this.state.commissionIndicatorsAvailableLabels.find(obj => obj?.label === 'TOTAL DE COMISSÕES PAGAS')?.isActive ? '' : 'none' }}>
							<h3>TOTAL DE <br />COMISSÕES PAGAS</h3>

							<h2 className="">
								<span style={{ fontSize: 13, marginTop: 8 }}>R$</span>
								{(findMetricInfo(this.state.metrics, [this.state.commissionIndicatorsFilters.user_type, this.state.commissionIndicatorsFilters.time_frame], 'AFFILIATE_INDICATORS_PAID_COMISSIONS', 'sum')).replace('R$', '')}
							</h2>

							<div className="card-value card-value-green">
								{findMetricInfo(this.state.metrics, [this.state.commissionIndicatorsFilters.user_type, this.state.commissionIndicatorsFilters.time_frame], 'AFFILIATE_INDICATORS_PAID_COMISSIONS', 'count') ?? 0} pagamentos
							</div>
						</div>

						<div className="card-box" style={{ display: this.state.commissionIndicatorsAvailableLabels.find(obj => obj?.label === 'TOTAL DE COMISSÕES A RESGATAR')?.isActive ? '' : 'none' }}>
							<h3>TOTAL DE COMISSÕES<br /> A RESGATAR</h3>

							<h2 className="">
								<span style={{ fontSize: 13, marginTop: 8 }}>R$</span>
								{(findMetricInfo(this.state.metrics, [this.state.commissionIndicatorsFilters.user_type, this.state.commissionIndicatorsFilters.time_frame], 'AFFILIATE_INDICATORS_AWAINTING_COMISSIONS', 'sum')).replace('R$', '')}
							</h2>

							<div className="card-value card-value-red">
								{findMetricInfo(this.state.metrics, [this.state.commissionIndicatorsFilters.user_type, this.state.commissionIndicatorsFilters.time_frame], 'AFFILIATE_INDICATORS_AWAINTING_COMISSIONS', 'count') ?? 0} comissões
							</div>
						</div>
					</div>
				</CardBody>
			</Card>
		)
	}
}


