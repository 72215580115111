import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment';
import 'moment/locale/pt-br';
import { getVistoriaFile, getVistoriasItems } from '../../crud/property-inspection.crud';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownToLine } from '@fortawesome/pro-solid-svg-icons';
import { Link } from "react-router-dom";
import heic2any from 'heic2any';
import ImageComponent from '../Assets/ImageComponent';
import { faPen } from '@fortawesome/pro-regular-svg-icons';

moment.locale('pt-br');
export default class DataTablePropertyInspectionItems extends Component {
	constructor() {
		super();

		this.state = {
			data: [],
			rows: 10,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: 'created_at',
			sortOrder: -1,
			boletoExists: false,
			boletoError: false,
			page: 1,
			disableVistoria: false
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		this.fetchData();
	}

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = async (event) => {
		if (event.data) {
			event.data = null;
		}

		this.setState({
			...this.state,
			rows: event.rows,

		});

		this.fetchData((event.first / 10) + 1);
	};

	fetchData = (page) => {
		let params = {
			$sort: { [this.state.sortField]: this.state.sortOrder }
		}


		getVistoriasItems(this.props.propertyInspectionId, page ? page : this.state.page, params).then(res => {
			if (page) this.setState({ page });

			this.setState({
				...this.state,
				data: res.data.data,
				totalRecords: res.data.total,
				first: res.data.skip,
				last: res.data.skip + res.data.limit,
			});
		}).finally(() => {

		});

	};

	getMediaLinks = (obj) => {
		if (obj.medias?.length > 0) {
			return (
				<div style={{ display: 'flex', flexWrap: 'wrap' }}>
					{obj.medias.map((obj, i) =>
						<>
							<a style={{ display: 'block', marginRight: 6 }} href={obj.secure_url}>{`Imagem ${i + 1};`}</a>
						</>
					)}
				</div>
			)
		} else {
			return <p>N/A</p>
		}
	}

	getComentarios = (obj) => {
		if (obj.comments?.length > 0 || obj.comment) {
			return (
				<div style={{ display: 'flex', flexWrap: 'wrap' }}>
					{obj.comment ?
						<div>
							<p style={{ margin: 0, padding: 0, marginRight: 6 }}><b>{obj.user.name}:</b> {obj.comment};</p>
						</div>
						: null}

					{obj.comments?.length > 0 ? obj.comments.map((comentario, index) =>
						<div key={index}>
							{comentario.medias?.length > 0 ?
								<p style={{ margin: 0, padding: 0, marginRight: 6 }}><b>{comentario.user.name}:</b><a target='_blank' href={comentario.medias[0].url} > Imagem</a></p>
								:
								<p style={{ margin: 0, padding: 0, marginRight: 6 }}><b>{comentario.user.name}:</b> {comentario.comment};</p>
							}
						</div>
					) : null}
				</div>
			)
		} else {
			return <p>N/A</p>
		}
	}

	sortTable = e => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder }, () => {
			let propertyInspectionDataTable = {
				rows: this.state.rows,
				totalRecords: this.state.totalRecords,
				first: this.state.first,
				last: this.state.last,
				loading: this.state.loading,
				sortField: this.state.sortField,
				sortOrder: this.state.sortOrder,
			};

			localStorage.setItem('propertyInspectionDataTable', JSON.stringify(propertyInspectionDataTable));

			this.fetchData();
		});
	};

	getCreatedAtAndByWho = (obj) => {
		return (<p style={{ marginBottom: '0px', padding: '16px' }}>
			<Link to={`/users/show/${obj?.user?.id}`} style={{ color: 'var(--Azul-500, #4AA3FF)', fontSize: '14px' }}>{obj.user.name}</Link>
			<br />
			{moment(obj.submitted_at).format('DD/MM/YYYY HH:mm:ss')}
		</p>)

	}

	handleDownloadVistoria = async (id) => {
		this.setState({ disableVistoria: true })
		try {
			await getVistoriaFile(this.props?.propertyInspectionId).then((data) => { window.open(data?.request?.responseURL, '_blank'); this.setState({ disableVistoria: false }) });
		} catch (err) {
			console.log(err);
			this.setState({ errors: ['Pdf da vistoria não encontrado, entre em contato com o suporte.'] });
		}
	}

	getRoomData = (obj) => {
		return (
			<ImageComponent obj={obj} />
		)
	}

	render() {
		return (
			<div>
				<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px', padding: '8px 0px' }}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<h1 className='inspection-table-title'>Postagens</h1>
						<p className='inspection-count'>{this.state.totalRecords}</p>
					</div>

					<div style={{ display: 'flex' }}>
						{moment().isAfter(moment(this.props.endDate)) ? (
							<button disabled={this.state?.disableVistoria} onClick={async () => { await this.handleDownloadVistoria(); }} className="go-back-1" type='button'>
								BAIXAR VISTORIA
								<FontAwesomeIcon icon={faArrowDownToLine} style={{ fontSize: '16px' }} />
							</button>
						) : null}

						<a href={`/contracts/property-inspection/edit/${this.props.propertyInspectionId}`} className='btnSecundary' style={{ marginLeft: '8px' }}>
							EDITAR VISTORIA
							<FontAwesomeIcon icon={faPen} style={{ fontSize: '16px' }} />
						</a>
					</div>
				</div>
				<DataTable ref={(el) => this.dt = el} value={this.state.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={[10]}
					first={this.state.first} last={this.state.last} responsive={true} className="ml-auto inspectionTable"
					totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} loadingIcon={`fas fa-sync fa-spin`}
					paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
					currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" emptyMessage="Nenhuma postagem criada"
					onSort={(e) => this.sortTable(e)} sortField={this.state.sortField} sortOrder={this.state.sortOrder} id="property-inpsection-items">

					<Column sortable field='state' style={{ width: '130px', padding: '16px' }} body={(obj, i) => obj.state === 'fine' ? (
						<p className='good-state-box'>Bom estado</p>
					) : <p className='repair-box'>Precisa de reparos</p>} header="Estado"></Column>
					<Column sortable field='created_at' body={(obj, i) => this.getCreatedAtAndByWho(obj)} style={{ width: '108px', padding: '0px' }} header="Criado em"></Column>
					<Column sortable field='room' body={(obj, i) => this.getRoomData(obj)} style={{ width: '264px' }} header="Ambiente"></Column>
					<Column field='location.latitude' body={(obj, i) => `${obj.location?.latitude ?? 'N/A'}, ${obj.location?.longitude ?? 'N/A'}`} header="Geolocalização" style={{ width: '137px' }}></Column>
					<Column body={(obj, i) => this.getComentarios(obj)} style={{ width: '433px' }} header="Comentários"></Column>
				</DataTable>
			</div>
		);
	}
}