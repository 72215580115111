import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";
import { FiltersModuleFailuresIndicators } from "../filters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical, faEyeSlash, faGripVertical } from '@fortawesome/pro-solid-svg-icons';
import { Checkbox, ListItemText, Menu, MenuItem } from "@material-ui/core";
import DataTableFalhaPagamento from "../../datatables/DataTableFalhaPagamento";
import { getFalhaPagamento } from "../../../crud/billings.crud";

export default class PaymentFailureIndicatorSection extends Component {
	constructor() {
		super();

		this.state = {
			metrics: [],
			failuresIndicatorsAvailableLabels: [
				{ label: "Vence em", isActive: true },
				{ label: "Pago em", isActive: true },
				{ label: "Status", isActive: true },
				{ label: "Recebedores", isActive: true },
				{ label: "Contrato", isActive: true },
				{ label: "Valor aluguel", isActive: true }
			],
			failuresIndicatorsFilters: {
				"billing_type": "all"
			},
			dropdownKey: null,
			anchorEl: null,
			counter: 0
		};
	}

	componentDidMount() {
		getFalhaPagamento({ $limit: 1 }).then(res => {
			this.setState({ counter: res?.data?.total })
		})
	}

	render() {
		return (
			<Card>
				<CardBody className="dashboard-item">
					<div className="box-header">
						<div className="">
							<h3 className="title">Lista de falhas de repasse</h3>
							<span className="count-indicator">{this.state.counter}</span>
							<div className="box-info">
								Negociações
							</div>

							<div className="box-filter">
								<FiltersModuleFailuresIndicators setState={this.setState.bind(this)} state={this.state.failuresIndicatorsFilters} />
							</div>
						</div>

						<div>
							<FontAwesomeIcon icon={faEllipsisVertical} style={{ color: '#5664D2', padding: '12px', cursor: 'pointer' }} onClick={(e) => {
								this.setState({ dropdownKey: this.props.itemKey });
								this.setState({ anchorEl: e.currentTarget });
							}} />
							<FontAwesomeIcon icon={faEyeSlash} style={{ color: '#5664D2', padding: '12px', cursor: 'pointer' }} onClick={() => this.props.changeWidgetValue(this.props.itemKey)} />
							<FontAwesomeIcon icon={faGripVertical} style={{ color: '#DADBDD', padding: '12px', cursor: 'pointer' }} />
						</div>
					</div>

					<Menu
						anchorEl={this.state.anchorEl}
						open={this.state.dropdownKey === this.props.itemKey ? true : false}
						onClose={() => { this.setState({ dropdownKey: null }) }}
						className="dashboard-item"
						id="dashboard-drop"
					>
						{this.state.failuresIndicatorsAvailableLabels.map((item) => (
							<MenuItem key={item.label} value={item.label}>
								<Checkbox checked={item.isActive} onChange={() => {
									this.setState(prevState => ({
										failuresIndicatorsAvailableLabels: prevState.failuresIndicatorsAvailableLabels.map(i =>
											i.label === item.label
												? { ...i, isActive: !i.isActive }
												: i
										)
									}));
								}} />
								<ListItemText primary={item.label} />
							</MenuItem>
						))}
					</Menu>

					<div style={{ position: 'relative', marginTop: '10px', marginBottom: '34px' }}>
						<hr className="divisor" />
					</div>

					<div style={{ height: '85%', overflow: 'auto', maxHeight: 604 }}>
						<DataTableFalhaPagamento filters={this.state.failuresIndicatorsFilters} noDelete fetchEvent={getFalhaPagamento} crudButtons={true}
							crudUrl="/dashboard" idRow={`id`} export={this.state.export} labels={this.state.failuresIndicatorsAvailableLabels} updateCounter={(total) => this.setState({ counter: total })}
						/>
					</div>
				</CardBody>
			</Card>
		)
	}
}


