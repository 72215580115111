import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatBRL } from "../../helpers/Utils";
import { Link } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileContract } from "@fortawesome/pro-solid-svg-icons";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { get } from "../../crud/users.crud";

export default class DataTableContractsToBeJudicialized extends Component {
	constructor() {
		super();

		this.state = {
			property: {},
			data: null,
			rows: 6,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: 'created_at',
			sortOrder: -1,
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		let contractsToBeJudicializedTable = JSON.parse(localStorage.getItem("contractsToBeJudicializedTable"));

		if (contractsToBeJudicializedTable) {
			this.setState(
				{
					data: contractsToBeJudicializedTable.data,
					rows: contractsToBeJudicializedTable.rows,
					totalRecords: contractsToBeJudicializedTable.totalRecords,
					first: contractsToBeJudicializedTable.first,
					last: contractsToBeJudicializedTable.last,
					sortField: contractsToBeJudicializedTable.sortField,
					sortOrder: contractsToBeJudicializedTable.sortOrder,
					loading: false
				},
			);
		} else {
			this.fetchData();
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters !== this.props.filters) {
			this.fetchData();
		}
	}

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = async (event) => {
		this.setState({ loading: true });

		if (event.data) event.data = null;

		let contractsToBeJudicializedTable = {
			rows: event.rows,
			totalRecords: this.state.totalRecords,
			data: this.data,
			first: event.first,
			last: event.rows + event.first,
			loading: this.state.loading,
			sortField: this.state.sortField,
			sortOrder: this.state.sortOrder,
		};

		// localStorage.setItem("contractsToBeJudicializedTable", JSON.stringify(contractsToBeJudicializedTable));

		this.setState({ ...this.state, rows: event.rows });

		this.fetchData(event.rows, event.first);
	};

	fetchData = async (limit, skip) => {
		this.setState({ loading: true });

		let options = {
			$limit: limit ? limit : this.state.rows,
			$skip: skip ? skip : this.state.first,
			$sort: { [this.state.sortField]: this.state.sortOrder },
		};

		return this.props.fetchEvent(options).then(async (res) => {

			if (res.data.data.length == 0) {
				this.setState({
					...this.state,
					data: [],
					totalRecords: res.data.total,
					first: skip ? skip : this.state.first,
					last: skip + res.data.limit,
				});
			}

			this.props.setState({ counter: res.data.total })

			await res.data.data.map(async (obj, i) => {
				if (obj.landlord_id) {
					obj.loading_contract = true;
					await get(obj.landlord_id).catch(err => {

					}).then(res => {
						if (res?.data?.data) {
							obj.landlord = res.data.data;
						}
					});
				}

				if (obj.tenant_id) {
					obj.loading_contract = true;
					await get(obj.tenant_id).catch(err => {

					}).then(res => {
						if (res?.data?.data) {
							obj.tenant = res.data.data;
						}
					});
				}

				this.setState({
					...this.state,
					data: res.data.data,
					totalRecords: res.data.total,
					first: skip ? skip : this.state.first,
					last: skip + res.data.limit,
				});
			});


		}).finally(() => {
			this.setState({ loading: false });
		});
	};

	sortTable = async (e) => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder, loading: true }, () => {
			let contractsToBeJudicializedTable = {
				rows: this.state.rows,
				totalRecords: this.state.totalRecords,
				first: this.state.first,
				last: this.state.last,
				loading: this.state.loading,
				sortField: this.state.sortField,
				sortOrder: this.state.sortOrder,
				data: this.state.data
			};
			//localStorage.setItem("contractsToBeJudicializedTable", JSON.stringify(contractsToBeJudicializedTable));

			this.fetchData();
		});
	};

	getValue = (obj) => {
		return (
			<div style={{ textAlign: "start" }}>
				<p>{formatBRL(obj || 0)}</p>
			</div>
		);
	};

	getUserName = (obj, type) => {
		if (type === 'landlord') {
			return (
				<div style={{ textAlign: "start" }}>
					<Link to={`/users/show/${obj.landlord?.id}`}>{obj.landlord?.name}</Link>
				</div>
			);
		}

		if (type === 'tenant') {
			return (
				<div style={{ textAlign: "start" }}>
					<Link to={`/users/show/${obj.tenant?.id}`}>{obj.tenant?.name}</Link>
				</div>
			);
		}

		return (
			<div style={{ textAlign: "center" }}>
				Não encontrado
			</div>
		);
	};

	getBillingUrl = (obj) => {
		if (obj?.billing?.id) {
			return (
				<div style={{ textAlign: "center" }}>
					<Link to={`/billing/show/${obj.billing?.id}`}>{this.getDateFormatted(obj?.billing?.due_date)}</Link>
				</div>
			);
		}

		return (
			<div style={{ textAlign: "center" }}>
				N/A
			</div>
		);
	}

	getAsaasUrl = (obj) => {
		if (obj?.request?.payment?.invoiceUrl) {
			return (
				<div style={{ textAlign: "center" }}>
					<a href={obj?.request?.payment?.invoiceUrl} target="_blank">{obj?.request?.payment?.invoiceUrl}</a>
				</div>
			);
		}

		return (
			<div style={{ textAlign: "center" }}>
				Não encontrado
			</div>
		);
	}

	getContractFriendlyName = (obj) => {
		if (obj?.id) {
			return (
				<div style={{ textAlign: "-webkit-center" }}>
					<FontAwesomeIcon icon={faFileContract} style={{ color: "#4AA3FF", marginRight: 10 }} />
					<Link style={{ color: "var(--Azul-500, #4AA3FF)", fontFamily: "Lato", fontSize: "14px", fontStyle: "normal", fontWeight: 400, lineHeight: "normal" }} to={`/negotiation/show/${obj?.proposition_id}`}>{obj?.friendly_code}</Link>
					<div className="d-flex flex-row" style={{ width: obj?.plan?.name === 'WARRANTY' ? '94px' : '84px', fontSize: '13px', borderRadius: 2, height: '24px', marginTop: 4, alignItems: 'center', backgroundColor: obj?.plan?.name === 'WARRANTY' ? '#5664D2' : '#4AA3FF' }}>
						<p className="col-form-label text-white" style={{ fontFamily: 'Inter', padding: 0 }}>{obj?.plan?.name === 'WARRANTY' ? 'Pagamento' : 'Danos'}</p>
					</div>
				</div>
			);
		}

		return (
			<div style={{ textAlign: "center" }}>
				Não encontrado
			</div>
		);
	}

	getCity = (obj) => {
		if (obj?.property) {
			return (
				<div style={{ textAlign: "center" }}>
					<Link to={`/announcements/show/${obj.property?.id}`}>{obj.property?.address?.city}</Link>
				</div>
			);
		} else {
			return (
				<div style={{ textAlign: "center" }}>
					Não encontrado
				</div>
			)
		}
	};

	getNeighbourhood = (obj) => {
		return (
			<div style={{ textAlign: "center" }}>
				<Link to={`/announcements/show/${obj.property?.id}`}>
					{obj.property?.address?.neighbourhood}
				</Link>
			</div>
		);
	};

	getDateFormatted = (date) => {
		// if (billing_id) {
		// 	return (
		// 		<a href={`/billing/show/${billing_id}`}>
		// 			{date ? moment(date).add(1, 'day').format("DD/MM/YYYY") : '-'}
		// 		</a>
		// 	)
		// }
		return (
			<p style={{ marginBottom: '0px' }}>
				{date ? moment(date, 'MM/DD/YYYY').format('DD') : '-'}
			</p>
		);
	};

	seeNegotiation = (obj) => {
		return (
			<div style={{ textAlign: "center" }}>
				<Link to={`/negotiation/show/${obj?.proposition_id}`}>
					<FontAwesomeIcon icon={faChevronRight} style={{ color: "#5664D2" }} />
				</Link>
			</div>
		)
	}

	export() {
		this.dt.exportCSV();
	}

	render() {
		return (
			<div style={{ position: 'relative' }}>
				<DataTable ref={(el) => this.dt = el} value={this.state?.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={[6, 15, 30]} first={this.state.first} last={this.state.last}
					totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} responsive={true} loadingIcon={`fas fa-sync fa-spin`} onSort={(e) => this.sortTable(e)}
					paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" emptyMessage="Nenhum registro encontrado!"
					currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" sortField={this.state.sortField} sortOrder={this.state.sortOrder} id="custom-table-2" pageLinkSize={3}>

					{this.props.labels.find(obj => obj.label === 'Contrato')?.isActive ? <Column field="friendly_code" sortable body={(obj) => this.getContractFriendlyName(obj)} header="Contrato" style={{ width: '126px', padding: '20px 16px 20px 16px' }} /> : null}

					{this.props.labels.find(obj => obj.label === 'Garantias')?.isActive ? <Column field="billings_guarenteed" body={(obj) => obj?.billings_guarenteed} header="Garantias" style={{ width: 104, padding: '20px 16px 20px 16px' }} /> : null}

					{this.props.labels.find(obj => obj.label === 'Vencimento')?.isActive ? <Column field="details.start_date" sortable body={(obj) => this.getDateFormatted(obj?.details?.start_date)} header="Vencimento" style={{ width: 118, padding: '20px 16px 20px 16px' }} /> : null}

					{this.props.labels.find(obj => obj.label === 'Valor aluguel')?.isActive ? <Column field="details.values.rent" sortable body={(obj) => this.getValue(obj?.details?.values?.total)} header="Valor aluguel" style={{ width: '124px', padding: '20px 16px 20px 16px' }} /> : null}

					{this.props.labels.find(obj => obj.label === 'Responsável').isActive ? <Column field="responsable" body={(obj) => this.getUserName(obj, 'landlord')} header="Responsável" style={{ width: '283px', padding: '22px 16px 22px 16px' }} /> : null}

					{this.props.labels.find(obj => obj.label === 'Inquilino').isActive ? <Column field="tenant" body={(obj) => this.getUserName(obj, 'tenant')} header="Inquilino" style={{ width: '283px', padding: '22px 16px 22px 16px' }} /> : null}

					<Column header="" body={(obj) => this.seeNegotiation(obj)} style={{ width: '34px', padding: 0 }} />
				</DataTable>

				<div className="paginator-new-items">
					<p>Registros por página</p>
					{/* <button className="custom-see-all-table">Ver todos</button> */}
				</div>
			</div>
		);
	}
}
