import React, { Component, createRef } from 'react';
import { Container, Card, Row, Col, Label, Input, Nav, NavItem, NavLink } from "reactstrap";
import Breadcrumbs from '../../components/Breadcrumb';
import { getProperties, getTotalImoveis } from '../../crud/announcements.crud';
import DataTableAnnouncements from '../../components/datatables/announcements/DataTableAnnouncements';
import BetterAlerts from '../../components/BetterAlerts';
import { AnnouncementFilterComponent } from '../negotiation/components/announcement.component';
import { UserFilterComponent } from '../negotiation/components/user.component';
import BetterCircularProgress from '../../components/BetterCircularProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { faChevronRight, faFilter, faMagnifyingGlass, faRotateRight, faTimes } from '@fortawesome/pro-regular-svg-icons';

class AnnouncementsIndex extends Component {
	dataTableRef = createRef();

	state = {
		errors: [],
		success: [],
		activeTab: '4',
		filtersCount: 0,
		newFilters: [],
		indicadores: {},
		totalAtual: 0,
		filters: {
			is_published: '',
			city: '',
			user: '',
			id: '',
			warranty_type: '',
			searchAll: '',
		},
		flags: {
			show_city: false,
			show_user: false,
			show_id: false,
			show_warranty_type: false,
		},
		is_filter_open: false,
		list_users: [],
		termo_user: '',
	}

	saveFilters = () => {
		localStorage.setItem('announcementsFilters', JSON.stringify(this.state.filters));
		localStorage.setItem('announcementsFlags', JSON.stringify(this.state.flags));
	};

	fetchByFilter = (data) => {
		let params = { ...data };

		if (this.state.filters.signup_after?.trim() != '') params.created_at_gte = this.state.filters.signup_after;
		if (this.state.filters.signup_before?.trim() != '') params.created_at_lte = this.state.filters.signup_before;
		if (this.state.filters.searchAll?.trim() != '') params.searchAll = this.state.filters.searchAll;
		if (this.state.filters.city?.trim() != '') params.address = { ['city_ilike']: this.state.filters.city };
		if (this.state.filters.user) params.user_id = this.state.filters.user.id;
		if (this.state.filters.id?.trim() != '') params.id = this.state.filters.id;
		if (this.state.filters.warranty_type?.trim() != '') params.warranty = { ['type']: this.state.filters.warranty_type };
		if (this.state.filters.cod_sequencial?.trim() != '') params.friendly_code = this.state.filters.cod_sequencial;

		getTotalImoveis(params).catch(err => {
			alert('Ocorreu um erro ao carregar o número de imóveis, entre em contato com o suporte.');
		}).then(async res => {
			if (res?.status === 200) {
				if (res.data) {
					this.setState({ indicadores: res.data });
				}
			}
		});

		params.isAdmin = 'true';

		if (this.state.activeTab === '1') {
			params.is_published = 'true';
		}

		if (this.state.activeTab === '2') {
			params.is_rented = 'true';
		}

		if (this.state.activeTab === '3') {
			params = {
				...params,
				$disable_soft_delete: false,
				deleted_at_ne: 'null',
			};
		}

		if (this.state.activeTab === '4') {
			params.is_published = 'false';
		}

		return getProperties(params);
	};

	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({ activeTab: tab }, () => {
				this.setState({ totalAtual: 0 });
				this.dataTableRef.current.fetchData();
			});
		}
	};

	componentDidMount = () => {
		// let filters = this.state.filters;
		// let flags = this.state.flags;

		// this.setState({ filters, flags });

		if (this.props.location.state) {
			if (this.props.location.state.success) {
				this.setState({ success: this.props.location.state.success });
			}
		}

		getTotalImoveis().catch(err => {
			alert('Ocorreu um erro ao carregar o número de imóveis, entre em contato com o suporte.');
		}).then(async res => {
			if (res?.status === 200) {
				if (res.data) {
					this.setState({ indicadores: res.data });
				}
			}
		});
	};

	FiltersModule = ({ filtroModular, index }) => {
		const getFilterChoised = (filter) => {
			let component = <></>

			if (filter == 'data_cadastro') {
				component = (
					<>
						<div className='d-flex'>
							<Label htmlFor="signup_after" className="col-form-label">De</Label>
							<Input className="form-control" type="date" id="signup_after" value={this.state.filters.signup_after} style={{ width: 200, margin: '0 16px' }}
								onChange={(e) => this.setState({ filters: { ...this.state.filters, signup_after: e.target.value } }, () => this.saveFilters())} />
						</div>

						<div className='d-flex'>
							<Label htmlFor="signup_before" className="col-form-label">Até</Label>
							<Input className="form-control" type="date" id="signup_before" value={this.state.filters.signup_before} style={{ width: 200, margin: '0 16px' }}
								onChange={(e) => this.setState({ filters: { ...this.state.filters, signup_before: e.target.value } }, () => this.saveFilters())} />
						</div>
					</>
				)
			} else if (filter == 'cidade') {
				component = (
					<div className='d-flex'>
						<AnnouncementFilterComponent label="Cidade" value={this.state.filters.announcement}
							onChange={(e, value) => { this.setState({ filters: { ...this.state.filters, city: value } }); this.saveFilters(); }}
							onClick={() => this.setState({ flags: { ...this.state.flags, show_city: false }, filters: { ...this.state.filters, city: undefined } }, () => this.saveFilters())} layout={'row'}
						/>
					</div>
				)
			} else if (filter == 'usuario') {
				component = (
					<div className='d-flex'>
						<UserFilterComponent label="Usuário" value={this.state.filters.user} onChange={(e, value) => { this.setState({ filters: { ...this.state.filters, user: value } }); this.saveFilters(); }} layout={'row'} />
					</div>
				)
			} else if (filter == 'id') {
				component = (
					<div className='d-flex'>
						<Input className="form-control" type="text" value={this.state.filters.id} style={{ width: 400, margin: '0 16px' }} placeholder="Pesquisar por ID"
							onChange={(e) => this.setState({ filters: { ...this.state.filters, id: e.target.value.trim() } }, () => this.saveFilters())} />
					</div>
				)
			} else if (filter == 'warranty_type') {
				component = (
					<div className='d-flex'>
						<select onChange={(e) => this.setState({ filters: { ...this.state.filters, warranty_type: e.target.value } }, () => this.saveFilters())} style={{ width: 400, margin: '0 16px' }} className="custom-select">
							<option value="" hidden>Selecionar</option>
							<option value="WARRANTY">Garantia de pagamento</option>
							<option value="WARRANTY_GUARANTEE_DAMAGE">Garantia de pagamento com danos</option>
						</select>
					</div>
				)
			} else if (filter == 'cod_sequencial') {
				component = (
					<div className='d-flex'>
						<Input
							className="form-control" type="text"
							value={this.state.filters.cod_sequencial}
							style={{ width: 400 }}
							placeholder="Pesquisar por Código Sequencial"
							onChange={(e) => this.setState({ filters: { ...this.state.filters, cod_sequencial: e.target.value } }, () => this.saveFilters())} />
					</div>
				)
			} else {
				component = <></>
			}

			return component;
		}

		const onSetFilter = (filtro) => {
			let stateFilter = this.state.newFilters.slice();
			stateFilter[index] = filtro;

			this.setState({ newFilters: stateFilter })
		}

		const getSelectOptions = (nameFiltro) => {
			const stateFilter = this.state.newFilters;

			if (stateFilter.includes(nameFiltro)) {
				return 'd-none'
			} else {
				return ''
			}
		}

		const removeFilter = (filterUnit, filterName) => {
			let stateFilter = this.state.newFilters;

			stateFilter.splice(filterUnit, 1);

			switch (filterName) {
				case 'data_cadastro':
					this.setState({
						flags: { ...this.state.flags, show_signup_after: false, show_signup_before: false },
						filters: { ...this.state.filters, signup_after: '', signup_before: '' }
					}, () => this.saveFilters())

					break;

				case 'cidade':
					this.setState({
						flags: { ...this.state.flags, show_city: false },
						filters: { ...this.state.filters, city: '' }
					}, () => this.saveFilters())

					break;

				case 'usuario':
					this.setState({
						flags: { ...this.state.flags, show_user: false },
						filters: { ...this.state.filters, user: {} }
					}, () => this.saveFilters())

					break;

				case 'id':
					this.setState({
						flags: { ...this.state.flags, show_id: false },
						filters: { ...this.state.filters, id: '' }
					}, () => this.saveFilters())

					break;

				case 'warranty_type':
					this.setState({
						flags: { ...this.state.flags, show_warranty_type: false },
						filters: { ...this.state.filters, warranty_type: '' }
					}, () => this.saveFilters())

					break;

				case 'cod_sequencial':
					this.setState({
						flags: { ...this.state.flags, show_cod_sequencial: false },
						filters: { ...this.state.filters, cod_sequencial: '' }
					}, () => this.saveFilters())

					break;

				default:
					break;
			}

			this.setState({ newFilters: stateFilter })
		}

		return (
			<Col md={12} className="d-flex m-0 p-0 filtroUnit">
				<div className='d-flex'>
					<select onChange={({ target }) => onSetFilter(target.value)} value={this.state.newFilters[index]} className="custom-select" style={{ width: 300 }}>
						<option value="">Selecione um filtro</option>
						<option className={getSelectOptions('data_cadastro')} value="data_cadastro">Data Cadastro</option>
						<option className={getSelectOptions('id')} value="id">Id</option>
						<option className={getSelectOptions('cidade')} value="cidade">Cidade</option>
						<option className={getSelectOptions('usuario')} value="usuario">Usuário</option>
						<option className={getSelectOptions('warranty_type')} value="warranty_type">Plano</option>
						<option className={getSelectOptions('cod_sequencial')} value="cod_sequencial">Código Sequencial</option>
					</select>

					{this.state.newFilters[index] != '' && (
						<div className='divisionArrow'>
							<FontAwesomeIcon icon={faChevronRight} />
						</div>
					)}

					{getFilterChoised(this.state.newFilters[index])}
				</div>

				<div onClick={() => removeFilter(index, this.state.newFilters[index])}>
					<FontAwesomeIcon icon={faTimes} />
				</div>
			</Col>
		)
	}

	handleSetIndicadorAtual = (indicador) => {
		this.setState({ totalAtual: indicador })
	}

	render() {
		return (
			<React.Fragment>
				<div className="page-content container-page userIndex">
					<Container fluid>
						<div className='header'>
							<h1>Imóveis</h1>

							<Breadcrumbs breadcrumbItems={[{ link: '/announcements/index', title: 'Cadastros' }, { link: '/announcements/index', title: 'Imóveis' }]} />
						</div>

						<BetterAlerts errors={this.state.errors} success={this.state.success} />

						<Col md={12} className="box-filtros" style={{ rowGap: 8 }}>
							<Col md={12} className="d-flex p-0" style={this.state.newFilters.length > 0 ? { marginBottom: 8 } : {}}>
								<div className='generalSearch'>
									<Input className="inputCustomNew" type="text" value={this.state.filters.searchAll} placeholder="Busque por Endereço, Nome ou ID"
										onChange={(e) => this.setState({ filters: { ...this.state.filters, searchAll: e.target.value } }, () => this.saveFilters())} />

									<FontAwesomeIcon icon={faMagnifyingGlass} />
								</div>

								<button onClick={() => this.setState({ newFilters: [...this.state.newFilters, ''] })} className="btnSecundary" style={{ width: 'max-content', margin: '0px 8px', padding: '8px 12px' }}>
									FILTRO
									<FontAwesomeIcon icon={faFilter} />
								</button>

								<button onClick={() => this.dataTableRef.current.refreshTable()} className="btnSecundary" style={{ width: 'max-content', margin: '0px 0px 0px 8px', padding: '8px 12px' }}>
									ATUALIZAR
									<FontAwesomeIcon icon={faRotateRight} />
								</button>

								{/* <Link to={`/users/new`} className="buttonPrimary w-100" style={{ width: '100%', maxWidth: '97px' }}>
									CRIAR
									<FontAwesomeIcon icon={faHouseCircleCheck} />
								</Link> */}
							</Col>

							{this.state.newFilters.map((filtroModular, index) => {
								return <this.FiltersModule filtroModular={filtroModular} key={index} index={index} />
							})}
						</Col>

						<Row>
							<Col lg={12}>
								<Card className='card-general'>
									<Nav tabs className="nav-tabs-custom" role="tablist" style={{ marginBottom: '32px' }}>
										<NavItem>
											<NavLink id="negotiations-tab" className={classnames({ active: this.state.activeTab === '4' })} onClick={() => { this.toggleTab('4'); }}>
												<div className='tabName'>Cadastrados <div className='indicadorUsuarios'>{this.state.activeTab === '4' ? this.state?.totalAtual : this.state?.indicadores?.registered ?? 0}</div></div>
											</NavLink>
										</NavItem>

										<NavItem>
											<NavLink id="info-tab" className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggleTab('1'); }}>
												<div className='tabName'>Anunciados <div className='indicadorUsuarios'>{this.state.activeTab === '1' ? this.state?.totalAtual : this.state?.indicadores?.published ?? 0}</div></div>
											</NavLink>
										</NavItem>

										<NavItem>
											<NavLink id="announcements-tab" className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggleTab('2'); }}>
												<div className='tabName'>Alugados <div className='indicadorUsuarios'>{this.state.activeTab === '2' ? this.state?.totalAtual : this.state?.indicadores?.rented ?? 0}</div></div>
											</NavLink>
										</NavItem>

										<NavItem>
											<NavLink id="negotiations-tab" className={classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.toggleTab('3'); }}>
												<div className='tabName'>Inativos <div className='indicadorUsuarios'>{this.state.activeTab === '3' ? this.state?.totalAtual : this.state?.indicadores?.inatived ?? 0}</div></div>
											</NavLink>
										</NavItem>
									</Nav>

									<div className='datatable-box'>
										<BetterCircularProgress loading={this.state.loading}>
											<DataTableAnnouncements filters={this.state.filters} noDelete fetchEvent={this.fetchByFilter} crudButtons={true} activeTab={this.state.activeTab}
												crudUrl="/announcements" idRow={`id`} noShow ref={this.dataTableRef} handleSetIndicadorAtual={this.handleSetIndicadorAtual} />
										</BetterCircularProgress>
									</div>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		)
	}
}

export default AnnouncementsIndex;