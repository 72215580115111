import React, { Component, createRef } from 'react';
import { Container, Card, Row, Col, Label, Input, } from "reactstrap";
import Breadcrumbs from '../../../components/Breadcrumb';
import BetterAlerts from '../../../components/BetterAlerts';
import BetterCircularProgress from '../../../components/BetterCircularProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faUser } from '@fortawesome/pro-regular-svg-icons';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { formatBRL, formatTelefone } from '../../../helpers/Utils';
import { Redirect } from "react-router-dom";
import { UserFilterComponent } from '../../users/components/user.component';
import { Formik } from "formik";
import { validateFormGratification, validateFormNegotiation } from "../../../helpers/FormValidators";
import { FormHelperText, InputAdornment, TextField } from '@material-ui/core';
import { createGratification } from '../../../crud/users.crud';


class GratificationCreate extends Component {
	dataTableRef = createRef();

	state = {
		errors: [],
		success: [],
		shipment_billings: [],
		gratification_id: null
	};

	saveGratification = async (values, errors) => {
		this.setState({ loading: true });

		if (values?.users?.length == 0) {
			this.setState({ loading: false });
			this.setState({ errors: ['Adicione usuários!'] });
			return;
		}

		if (!values?.role || !values?.bonus_rate) {
			this.setState({ loading: false });
			this.setState({ errors: ['Campos obrigatórios não foram preenchidos!'] });
			return;
		}

		let body = {
			"user_id": values.users[0].id,
			"role": values.role,
			"status": "ACTIVE",
			"bonus_rate": parseFloat(values.bonus_rate).toFixed(2)
		}

		await createGratification(body).catch((error) => {
			this.setState({ loading: false });
			this.setState({ errors: [error?.response?.data?.message] });
		}).then((response) => {
			if (response?.status == 200) {
				this.setState({ gratification_id: response?.data?.id });
				this.setState({ loading: false });
				this.setState({ success: ['Gratificação adicionada com sucesso!'] })
			} else {
				errors.bonus_rate = 'Limite de 50% na soma de todas gratificações.'
				this.setState({ loading: false });
			}
		});
	}

	getInitialValues = () => {
		return {
			users: [],
			role: '',
			bonus_rate: 0.1
		};
	}

	selectInquilino = (e, value, setFieldValue, handleChange, values, errors) => {
		const users = values?.users;

		if (!value?.verified && !value?.roles?.includes('admin')) {
			this.setState({
				errors: {
					userSearch: 'O usuário selecionado precisa de perfil verificado e permissão de admin.'
				}
			});
			return;
		} else if (value?.verified && !value?.roles?.includes('admin')) {
			this.setState({
				errors: {
					userSearch: 'O usuário selecionado precisa de permissão de admin.'
				}
			});
			return;
		} else if (value?.roles?.includes('admin') && !value?.verified) {
			this.setState({
				errors: {
					userSearch: 'O usuário selecionado precisa de perfil verificado.'
				}
			});
			return;
		} else {
			this.setState({ errors: [] });
		}

		setFieldValue(`users[${users?.length}]`, value);
		handleChange(e);
	};

	deleteUser = (setFieldValue, values, index) => {
		let users = values?.users;
		users.splice(index, 1);

		setFieldValue('users', users);
	}

	getBonusRate = (value) => {
		const bonus_rate = parseFloat(value).toFixed(2);

		if (isNaN(bonus_rate)) {
			return "--,-";
		} else {
			return bonus_rate;
		}
	}

	disabledButton = (values) => {
		if (values.users?.length === 0 || this.state.errors.userSearch) {
			return true;
		}

		return false;
	}

	render() {
		const UserBox = ({ usuario, index, setFieldValue, values }) => {
			return (
				<div className='boxInfos-container boxInfos-container-unico boxInfoUser boxUsuarioNegociacao mt-0'>
					<div className='boxUser boxAnuncioNew'>
						<div className='headerUser'>
							<div className='container-imovel-imagem'>
								<div className="boxUserTableUsers" style={{ alignSelf: 'end' }}>
									{usuario?.profile_image?.medium?.url ?
										<div className='userPhoto' style={{ backgroundImage: `url(${usuario?.profile_image?.medium?.url})`, marginRight: 0 }} />
										:
										<div className='userPhotoNone' style={{ marginRight: 0 }}>
											<FontAwesomeIcon icon={faUser} />
										</div>
									}
								</div>

								<div className='tagsAssets groupAlign' style={{ flex: 'auto' }}>
									<h3 onClick={() => window.open(`/users/show/${usuario?.id}`, '_blank')} style={{ cursor: 'pointer', color: '#4AA3FF', marginBottom: 0 }}>
										{usuario?.name}
									</h3>

									<div className="informationsDatas">
										<div className='boxInformation'>
											<p>{formatTelefone(usuario?.phone ?? '0')}</p>
										</div>

										<div className='boxInformation'>
											<p>{usuario?.email}</p>
										</div>
									</div>
								</div>
							</div>

							<div className="d-flex" style={{ gap: 24 }}>
								<div className="deleteImovel" onClick={() => this.deleteUser(setFieldValue, values, index)}>
									<FontAwesomeIcon icon={faTimes} />
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		}

		return (
			<React.Fragment>
				{this.state.success.length > 0 ? (
					<Redirect
						to={{
							pathname: `/pagamento/gratificacao/show/${this.state.gratification_id}`,
							state: { success: ["Gratificação criada com sucesso!"] },
						}}
					/>
				) : null}

				<div className="sec-shipment page-content container-page userShow userNew userIndex anuncio negociacao">
					<Container fluid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', alignItems: 'center' }}>
						<div className='header' style={{ width: '80%' }}>
							<h1>Adicionar gratificação</h1>

							<Breadcrumbs breadcrumbItems={[{ link: '/pagamento/cobrancas/index', title: 'Financeiro' }, { link: '/pagamento/gratificacao/index', title: 'Gratificações' }, { link: '', title: 'Adicionar usuário' }]} />
						</div>

						<Formik initialValues={this.getInitialValues()} validate={(values) => validateFormGratification(values, "store")} onSubmit={this.onSubmit}>
							{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isValid }) =>
								<form noValidate={true} autoComplete="off" onSubmit={handleSubmit} className="form" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', alignItems: 'center' }}>
									<div className='box' style={{ width: '80%' }}>
										<h5>USUÁRIO</h5>

										<div className='formulario' style={{ gap: '0' }}>
											{values.users?.length == 0 &&
												<Col md={12} className="p-0 m-0">
													<Label>Adicione usuário</Label>

													<UserFilterComponent placeholder="Busque por nome, e-mail ou telefone" style={{ width: 'auto' }} name="userSearch" onBlur={handleBlur} onChange={(e, value) => this.selectInquilino(e, value, setFieldValue, handleChange, values, errors)} value={values.userSearch}
													/>
												</Col>
											}

											{values.users?.length > 0 && values.users?.map((tenant, index) => (
												<UserBox usuario={tenant} index={index} setFieldValue={setFieldValue} values={values} key={index} />
											))}

											<FormHelperText error={Boolean(this.state.errors.userSearch)}>{this.state.errors.userSearch}</FormHelperText>
										</div>
									</div>

									<div className='box' style={{ width: '80%' }}>
										<h5>GRATIFICAÇÃO</h5>

										<div className='formulario'>

											<Row className="p-0 m-0">
												<Col lg={6} className="p-0 m-0">
													<Label>Cargo</Label>

													<TextField name="role" type="text" placeholder="Gestor" variant="outlined" value={values.role} onBlur={handleBlur} onChange={(e) => handleChange(e)}
														helperText={touched.role && errors.role} error={Boolean(touched.role && errors.role)} />
												</Col>

												<Col lg={3} className="marginInputMobile pl-4 pr-4">
													<Label>Taxa de gratificação</Label>

													<TextField name="bonus_rate" type="text" variant="outlined" value={values.bonus_rate} onBlur={handleBlur} onChange={handleChange}
														helperText={touched.bonus_rate && errors.bonus_rate} error={Boolean(touched.bonus_rate && errors.bonus_rate)}
														InputProps={{ endAdornment: <InputAdornment position='end'>%</InputAdornment> }} />
												</Col>

												<Col lg={3} className="pt-3 m-0 col-text-plan" style={{ padding: 0, alignItems: 'center' }}>
													<div style={{ padding: 0 }}>
														<p>Para a gratificação, digite</p>
														<b>entre 0,1% e 50%</b>
													</div>
												</Col>
											</Row>

											<div className='warningBox_1'>
												<p>O usuário passa a receber os valores configurados a partir de sua adição ou edição.</p>
											</div>

										</div>
									</div>

									<Card className='card-general' style={{ width: '80%' }}>
										<div className='datatable-box'>
											<BetterCircularProgress loading={this.state.loading}>
												<div className='d-flex justify-content-between align-items-center'>
													<div className='d-flex'>
														<div>
															<h3 className='sec-shipment-subtitle-label'>Gratificação</h3>
															<h1 lassName='sec-shipment-subtitle-value' style={{ color: '#1CBB8C' }}>
																{this.getBonusRate(values?.bonus_rate)}%
															</h1>
														</div>

														<div style={{ marginLeft: 34 }}>
															<h3 className='sec-shipment-subtitle-label'>Quando</h3>
															<h1 className='sec-shipment-subtitle-value' style={{ color: '#8590A5', fontSize: 12, maxWidth: '168px' }}>Primeiro pagamento de todos os novos contratos</h1>
														</div>
													</div>

													<div className='d-flex'>
														<button onClick={() => this.props.history.goBack()} className="btnSecundary" style={{ width: 'max-content', margin: '0px 8px', padding: '8px 12px' }}>
															<FontAwesomeIcon icon={faArrowLeft} />
															VOLTAR
														</button>

														<button onClick={async () => await this.saveGratification(values, errors)} disabled={this.disabledButton(values) || !isValid} className="buttonPrimary w-100" style={{ width: 'max-content', margin: '0px 8px', padding: '8px 12px' }}>
															SALVAR
															<FontAwesomeIcon icon={faCheck} />
														</button>
													</div>
												</div>
											</BetterCircularProgress>
										</div>
									</Card>
								</form>
							}
						</Formik>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}

export default GratificationCreate;