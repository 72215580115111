import React, { Component, createRef } from 'react';
import { Container, Card, Row, Col, Label, Input, } from "reactstrap";
import Breadcrumbs from '../../../components/Breadcrumb';
import BetterAlerts from '../../../components/BetterAlerts';
import BetterCircularProgress from '../../../components/BetterCircularProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faUser } from '@fortawesome/pro-regular-svg-icons';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { formatTelefone } from '../../../helpers/Utils';
import { Redirect } from "react-router-dom";
import { UserFilterComponent } from '../../users/components/user.component';
import { Formik } from "formik";
import { validateFormGratification } from "../../../helpers/FormValidators";
import { FormHelperText, InputAdornment, TextField } from '@material-ui/core';
import { getGratificationById, updateGratification } from '../../../crud/users.crud';


class GratificationEdit extends Component {
	dataTableRef = createRef();

	state = {
		errors: [],
		success: [],
		shipment_billings: [],
		gratification_id: null,
		gratification: null
	};

	componentDidMount = () => {
		getGratificationById(this.props.match.params.id).then((response) => {
			if (response.status == 200) {
				this.setState({ gratification: response.data })
			}
		})
	};

	saveGratification = async (values, errors) => {
		this.setState({ loading: true });

		let body = {
			"user_id": values.users[0].id,
			"role": values.role,
			"status": "ACTIVE",
			"bonus_rate": parseFloat(values.bonus_rate).toFixed(2)
		}

		await updateGratification(this.props.match.params.id, body).catch((error) => { }).then((response) => {
			if (response.status == 200) {
				this.setState({ gratification_id: response?.data?.id });
				this.setState({ loading: false });
				this.setState({ success: ['Gratificação editada com sucesso!'] })
			} else {
				errors.bonus_rate = 'Limite de 50% na soma de todas gratificações.'
				this.setState({ loading: false });
			}
		});
	}

	getInitialValues = () => {
		let users = [
			{ ...this.state?.gratification?.user }
		]
		return {
			users: users,
			role: this.state?.gratification?.role,
			bonus_rate: this.state?.gratification?.bonus_rate
		};
	}

	selectInquilino = (e, value, setFieldValue, handleChange, values, errors) => {
		const users = values?.users;

		setFieldValue(`users[${users?.length}]`, value);

		handleChange(e);
	};

	deleteUser = (setFieldValue, values, index) => {
		let users = values?.users;
		users.splice(index, 1);

		setFieldValue('users', users);
	}

	render() {
		const UserBox = ({ usuario, index, setFieldValue, values }) => {
			return (
				<div className='boxInfos-container boxInfos-container-unico boxInfoUser boxUsuarioNegociacao mt-0'>
					<div className='boxUser boxAnuncioNew'>
						<div className='headerUser'>
							<div className='container-imovel-imagem'>
								<div className="boxUserTableUsers" style={{ alignSelf: 'end' }}>
									{usuario?.profile_image?.medium?.url ?
										<div className='userPhoto' style={{ backgroundImage: `url(${usuario?.profile_image?.medium?.url})`, marginRight: 0 }} />
										:
										<div className='userPhotoNone' style={{ marginRight: 0 }}>
											<FontAwesomeIcon icon={faUser} />
										</div>
									}
								</div>

								<div className='tagsAssets groupAlign' style={{ flex: 'auto' }}>
									<h3 onClick={() => window.open(`/users/show/${usuario?.id}`, '_blank')} style={{ cursor: 'pointer', color: '#4AA3FF', marginBottom: 0 }}>
										{usuario?.name}
									</h3>

									<div className="informationsDatas">
										<div className='boxInformation'>
											<p>{formatTelefone(usuario?.phone ?? '0')}</p>
										</div>

										<div className='boxInformation'>
											<p>{usuario?.email}</p>
										</div>
									</div>
								</div>
							</div>

							{/* <div className="d-flex" style={{ gap: 24 }}>
								<div className="deleteImovel" onClick={() => this.deleteUser(setFieldValue, values, index)}>
									<FontAwesomeIcon icon={faTimes} />
								</div>
							</div> */}
						</div>
					</div>
				</div>
			)
		}

		return (
			<React.Fragment>
				{this.state.success.length > 0 ? (
					<Redirect
						to={{
							pathname: `/pagamento/gratificacao/show/${this.props.match.params.id}`,
							state: { success: ["Gratificação editada com sucesso!"] },
						}}
					/>
				) : null}

				{this.state?.gratification?.id ? (
					<div className="sec-shipment page-content container-page userShow userNew userIndex anuncio negociacao">
						<Container fluid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', alignItems: 'center' }}>
							<div className='header' style={{ width: '80%' }}>
								<h1>Editar gratificação</h1>

								<Breadcrumbs breadcrumbItems={[{ link: '/pagamento/cobrancas/index', title: 'Financeiro' }, { link: '/pagamento/gratificacao/index', title: 'Gratificações' }, { link: '', title: 'Editar usuário' }]} />
							</div>

							<BetterAlerts errors={this.state.errors} success={this.state.success} />

							<Formik initialValues={this.getInitialValues()} validate={(values) => validateFormGratification(values, "store")} onSubmit={this.onSubmit}>
								{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) =>
									<form noValidate={true} autoComplete="off" onSubmit={handleSubmit} className="form" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', alignItems: 'center' }}>
										<div className='box' style={{ width: '80%' }}>
											<h5>USUÁRIOS</h5>

											<div className='formulario'>
												{values.users?.length == 0 &&
													<Col md={12} className="p-0 m-0">
														<Label>Adicione usuários</Label>

														<UserFilterComponent placeholder="Busque por nome, email, telefone, ID ou documento" style={{ width: 'auto' }} name="userSearch" onBlur={handleBlur} onChange={(e, value) => this.selectInquilino(e, value, setFieldValue, handleChange, values, errors)} value={values.userSearch} helpertext={touched.userSearch && errors.userSearch} error={Boolean(touched.userSearch && errors.userSearch)} />
													</Col>
												}

												{touched.userSearch && errors.userSearch ?
													<FormHelperText style={{ color: '#FF3D60' }}>{errors.userSearch}</FormHelperText>
													: null}

												{values.users?.length > 0 && values.users?.map((tenant, index) => (
													<UserBox usuario={tenant} index={index} setFieldValue={setFieldValue} values={values} />
												))}
											</div>
										</div>

										<div className='box' style={{ width: '80%' }}>
											<h5>GRATIFICAÇÃO</h5>

											<div className='formulario'>

												<Row className="p-0 m-0">
													<Col lg={6} className="p-0 m-0">
														<Label>Cargo</Label>

														<TextField name="role" type="text" placeholder="Gestor" variant="outlined" value={values.role} onBlur={handleBlur} onChange={(e) => handleChange(e)}
															helperText={touched.role && errors.role} error={Boolean(touched.role && errors.role)} />
													</Col>

													<Col lg={3} className="marginInputMobile pl-4 pr-4">
														<Label>Taxa de gratificação</Label>

														<TextField name="bonus_rate" type="text" variant="outlined" value={values.bonus_rate} onBlur={handleBlur} onChange={handleChange}
															helperText={touched.bonus_rate && errors.bonus_rate} error={Boolean(touched.bonus_rate && errors.bonus_rate)}
															InputProps={{ endAdornment: <InputAdornment position='end'>%</InputAdornment> }} />
													</Col>

													<Col lg={3} className="pt-3 m-0 col-text-plan" style={{ padding: 0, alignItems: 'center' }}>
														<div style={{ padding: 0 }}>
															<p>Para a gratificação, digite</p>
															<b>entre 0,1% e 50%</b>
														</div>
													</Col>
												</Row>

												<div className='warningBox_1'>
													<p>O usuário passa a receber os valores configurados a partir de sua adição ou edição.</p>
												</div>

											</div>
										</div>

										<Card className='card-general' style={{ width: '80%' }}>
											<div className='datatable-box'>
												<BetterCircularProgress loading={this.state.loading}>
													<div className='d-flex justify-content-between align-items-center'>
														<div className='d-flex'>
															<div>
																<h3 className='sec-shipment-subtitle-label'>Gratificação</h3>
																<h1 lassName='sec-shipment-subtitle-value' style={{ color: '#1CBB8C' }}>{values.bonus_rate !== "" ? parseFloat(values?.bonus_rate ?? 0).toFixed(2) : 0}%</h1>
															</div>

															<div style={{ marginLeft: 34 }}>
																<h3 className='sec-shipment-subtitle-label'>Quando</h3>
																<h1 className='sec-shipment-subtitle-value' style={{ color: '#8590A5', fontSize: 12, maxWidth: '168px' }}>Primeiro pagamento de todos os novos contratos</h1>
															</div>
														</div>

														<div className='d-flex'>
															<button onClick={() => this.props.history.goBack()} className="btnSecundary" style={{ width: 'max-content', margin: '0px 8px', padding: '8px 12px' }}>
																<FontAwesomeIcon icon={faArrowLeft} />
																VOLTAR
															</button>

															<button onClick={async () => await this.saveGratification(values, errors)} className="buttonPrimary w-100" style={{ width: 'max-content', margin: '0px 8px', padding: '8px 12px' }}>
																SALVAR
																<FontAwesomeIcon icon={faCheck} />
															</button>
														</div>
													</div>
												</BetterCircularProgress>
											</div>
										</Card>
									</form>
								}
							</Formik>
						</Container>
					</div>
				) : null}
			</React.Fragment>
		);
	}
}

export default GratificationEdit;