import React, { Component } from 'react';
import { Container, Card, CardBody, Col, FormGroup, Label, TabContent, TabPane, Nav, NavItem, NavLink, Input } from "reactstrap";
import BetterCircularProgress from '../../components/BetterCircularProgress';
import classnames from 'classnames';
import { get as getUser } from '../../crud/users.crud';
import { updateProperties, getRawById, getPropertiesById, updateRaw, admitAnnouncement } from '../../crud/announcements.crud';
import DataTableAnnouncementsNotifications from '../../components/datatables/DataTableAnnouncementsNotifications';
import { getAllByParams as getAllNotifications } from '../../crud/notifications.crud';
import { getAllByFilter as getAllPublicLinks, welcomeLandlord } from '../../crud/publicLinks.crud';
import DataTableCrawlerLinks from '../../components/datatables/DataTableCrawlerLinks';
import { Link } from "react-router-dom";
import Breadcrumbs from '../../components/Breadcrumb';
import Swal from 'sweetalert2';

class CrawlerShow extends Component {

	state = {
		loading: false,
		activeTab: '1',
		data: null,
		properties: null,
		raw: null,
		user: null,
		announcement: null,
	}

	componentDidMount = () => {
		this.setState({ loading: true });

		this.fetchData();
	};

	fetchData = () => {
		getRawById(this.props.match.params.id).catch(err => {
			alert('An error occurred, please contact the support.');
		}).then(res => {
			if (res?.status == 200) {
				if (res.data) {
					this.setState({ data: res.data.data });

					if (res.data.data.user_id) {
						getUser(res.data.data.user_id).then(res => {
							if (res?.data?.data) {
								this.setState({ user: res.data.data });
							}
						});
					}

					if (res.data.data.announcement_id) {
						getPropertiesById(res.data.data.announcement_id).then(res => {
							if (res?.data?.data) {
								this.setState({ announcement: res.data.data });
							}
						});
					}
				}
			}

			this.setState({ loading: false });
		});
	};

	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	};

	fetchNotifications = (obj) => {
		let params = { ...obj }

		params.user_id = this.state.data?.user_id;

		return getAllNotifications(params);
	};

	fetchLinks = (obj) => {
		let params = { ...obj }

		params.data = { ['raw_announcement']: { ['id']: this.props.match.params.id } };

		return getAllPublicLinks(params);
	};

	setApproval = async (approval) => {
		if (approval) {
			if (!this.state.user?.phone) {
				Swal.fire('Ops', 'Para aprovar é necessário adicionar um telefone ao usuário.', 'error');
				return;
			}
		}

		if (this.state.data.approved != approval) {
			let data = this.state.data;

			data.block = true;

			this.setState({ data });

			updateRaw(this.props.match.params.id, { approved: approval, complete: false }).catch(err => {
				Swal.fire('Ops!', 'An error ocurred with our servers, please try again.', 'error');
			}).then(res => {
				this.fetchData();
			});
		}

		if (approval) {
			// Adicionado para imóvel aprovado pelo admin aparecer na busca, mesmo que não seja convertido https://trello.com/c/LBzY5RTj/177-crawler-aprovar-e-aparecer-na-busca
			await updateProperties(this.state.data.announcement_id, { is_published: true }).catch(err => { }).then(res => { });

			let data = {
				authenticate_to: this.state.data?.user_id,
				data: {
					raw_announcement: this.state.data,
				},
				path: `?skipLandingPage=true&id=${this.props.match.params.id}`
			};

			welcomeLandlord(data).catch(err => {
				Swal.fire('Ops!', 'An error ocurred with our servers, please try again.', 'error');
			}).then(res => {
				this.fetchData();
			});

			let dataNoLP = {
				authenticate_to: this.state.data?.user_id,
				data: {
					raw_announcement: this.state.data,
				},
				path: `?id=${this.props.match.params.id}`
			};

			welcomeLandlord(dataNoLP).catch(err => {
				Swal.fire('Ops!', 'An error ocurred with our servers, please try again.', 'error');
			}).then(res => {
				this.fetchData();
			});
		}
	};

	showClickableApproval = approval => {
		if (this.state.data?.block) {
			return false;
		} else {
			if (this.state.data?.approved == null) {
				return true;
			}

			if (this.state.data?.approved != approval) {
				return true;
			}

			return false;
		}
	};

	/*openWhatsapp = () => {
		let url = `https://api.whatsapp.com/send?phone=55${this.state.data.editable.landlord_phone}`;

		window.open(url, '_blank');
	};*/

	/* Vitor pediu para remover [ML-10886] 
	createLink = () => {
		let data = {
			authenticate_to: this.state.data?.user_id,
			data: {
				raw_announcement: this.state.data,
			},
			path: `?id=${this.props.match.params.id}`
		};

		welcomeLandlord(data).catch(err => {
			Swal.fire('Ops!', 'An error ocurred with our servers, please try again.', 'error');
		}).then(res => {
			this.fetchData();
		});
	}; */

	render() {
		return (
			<React.Fragment>
				<BetterCircularProgress loading={this.state.loading}>
					<div className="page-content container-page">
						<Container fluid>
							<Breadcrumbs breadcrumbItems={[{ link: '/crawler/index', title: 'Crawler' }, { link: `/crawler/show/${this.props.match.params.id}`, title: 'Visualizar' }]} />

							<div className="row align-right mb-30">
								<Link to={`/crawler/edit/${this.props.match.params.id}`} className="mr-20"><i className="ri-pencil-line align-middle mr-1"></i>EDITAR</Link>

								{/*<a onClick={() => { if (this.state.data?.editable?.landlord_phone) { this.openWhatsapp() } }} className="mr-20 pointer-events">
									<i className="ri-whatsapp-line align-middle mr-1"></i>WHATSAPP
		</a>*/}

								<a onClick={() => { if (this.showClickableApproval(true)) { this.setApproval(true) } }} className={`mr-20 ${this.showClickableApproval(true) ? 'pointer-events' : 'disabled'}`}>
									<i className="ri-thumb-up-fill align-middle mr-1"></i>APROVAR
								</a>

								<a onClick={() => { if (this.showClickableApproval(false)) { this.setApproval(false) } }} className={`mr-20 ${this.showClickableApproval(false) ? 'pointer-events' : 'disabled'}`}>
									<i className="ri-thumb-down-fill align-middle mr-1"></i>DESAPROVAR
								</a>

								{/* Vitor pediu para remover [ML-10886] 
                                <a onClick={() => this.createLink()} className={`mr-20 ${this.state.data?.approved === false ? 'disabled' : 'pointer-events'}`}>
                                    <i className="ri-link-m align-middle mr-1"></i>CREATE CONVERSION LINK
                                </a> */}
							</div>

							<Card>
								<CardBody>
									<Nav tabs className="nav-tabs-custom" role="tablist">
										<NavItem>
											<NavLink id="info-tab" className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggleTab('1'); }} >INFO</NavLink>
										</NavItem>
										<NavItem>
											<NavLink id="announcements-tab" className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggleTab('2'); }}>NOTIFICAÇÕES</NavLink>
										</NavItem>
										<NavItem>
											<NavLink id="negotiations-tab" className={classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.toggleTab('3'); }}>LINKS</NavLink>
										</NavItem>
									</Nav>
									<TabContent activeTab={this.state.activeTab} className="border border-top-0 p-4">
										<TabPane tabId="1" role="tabpanel">
											<div className="row">
												<Col md={6}>
													<FormGroup row>
														<Label className="col-md-4 col-form-label">ID</Label>
														<Col md={8} className="container-center-input">
															<Input className="form-control" type="text" value={this.state.data?.id} disabled />
														</Col>
													</FormGroup>
												</Col>

												<Col md={6}>
													<FormGroup row>
														<Label className="col-md-4 col-form-label">Fonte</Label>
														<Col md={8} className="container-center-input">
															<Input className="form-control" type="text" value={this.state.data?.source ? this.state.data?.source : 'N/A'} disabled />
														</Col>
													</FormGroup>
												</Col>

												<Col md={6}>
													<FormGroup row>
														<Label className="col-md-4 col-form-label">ID da fonte</Label>
														<Col md={8} className="container-center-input">
															<Input className="form-control" type="text" value={this.state.data?.source_id ? this.state.data?.source_id : 'N/A'} disabled />
														</Col>
													</FormGroup>
												</Col>

												<Col md={6}>
													<FormGroup row>
														<Label className="col-md-4 col-form-label">Link</Label>
														<Col md={8} className="container-center-input">
															<Input className="form-control" type="text" value={this.state.data?.link ? this.state.data?.link : 'N/A'} disabled />
														</Col>
													</FormGroup>
												</Col>

												<Col md={6}>
													<FormGroup row>
														<Label className="col-md-4 col-form-label">Nome</Label>
														<Col md={8} className="container-center-input">
															<Input className="form-control" type="text" value={this.state.data?.name ? this.state.data?.name : 'N/A'} disabled />
														</Col>
													</FormGroup>
												</Col>

												<Col md={6}>
													<FormGroup row>
														<Label className="col-md-4 col-form-label">Nome do dono</Label>
														<Col md={8} className="container-center-input">
															<Input className="form-control" type="text" value={this.state.data?.editable?.landlord_name ? this.state.data?.editable?.landlord_name : 'N/A'} disabled />
														</Col>
													</FormGroup>
												</Col>

												<Col md={6}>
													<FormGroup row>
														<Label className="col-md-4 col-form-label">Telefone do dono</Label>
														<Col md={8} className="container-center-input">
															<Input className="form-control" type="text" value={this.state.data?.editable?.landlord_phone ? this.state.data?.editable?.landlord_phone : 'N/A'} disabled />
														</Col>
													</FormGroup>
												</Col>

												<Col md={6}>
													<FormGroup row>
														<Label className="col-md-4 col-form-label">ID do dono</Label>
														<Col md={8} className="container-center-input">
															<Input className="form-control" type="text" value={this.state.data?.editable?.landlord_id ? this.state.data?.editable?.landlord_id : 'N/A'} disabled />
														</Col>
													</FormGroup>
												</Col>


												<Col md={6}>
													<FormGroup row>
														<Label className="col-md-4 col-form-label">Convertido</Label>
														<Col md={8} className="container-center-input">
															{this.state.data?.converted ?
																<i className="ri-checkbox-circle-line align-middle"></i>
																:
																<i className="ri-close-circle-line align-middle"></i>
															}
														</Col>
													</FormGroup>
												</Col>

												<Col md={6}>
													<FormGroup row>
														<Label className="col-md-4 col-form-label">Crawled at</Label>
														<Col md={8} className="container-center-input">
															<Input className="form-control" type="text" value={this.state.data?.created_at} disabled />
														</Col>
													</FormGroup>
												</Col>

												<Col md={6}>
													<FormGroup row>
														<Label className="col-md-4 col-form-label">Usuário</Label>
														<Col md={8} className="container-center-input">
															<Input className="form-control" type="text" value={this.state.user?.name ? this.state.user?.name : 'N/A'} disabled />
														</Col>
													</FormGroup>
												</Col>

												<Col md={6}>
													<FormGroup row>
														<Label className="col-md-4 col-form-label">Imóvel</Label>
														<Col md={8} className="container-center-input">
															<Input className="form-control" type="text" value={this.state.announcement?.address?.city ? this.state.announcement?.address?.city : 'N/A'} disabled />
														</Col>
													</FormGroup>
												</Col>

												<Col md={6}>
													<FormGroup row>
														<Label className="col-md-4 col-form-label">Próximo SMS em</Label>
														<Col md={8} className="container-center-input">
															<Input className="form-control" type="text" value={this.state.data?.metadata?.next_sms_notification ? this.state.data?.metadata?.next_sms_notification : 'N/A'} disabled />
														</Col>
													</FormGroup>
												</Col>

												<Col md={6}>
													<FormGroup row>
														<Label className="col-md-4 col-form-label">SMS link</Label>
														<Col md={8} className="container-center-input">
															<Input className="form-control" type="text" value={this.state.data?.metadata?.sms_link ? this.state.data?.metadata?.sms_link : 'N/A'} disabled />
														</Col>
													</FormGroup>
												</Col>

												<Col md={6}>
													<FormGroup row>
														<Label className="col-md-4 col-form-label">SMS link enviado depois de</Label>
														<Col md={8} className="container-center-input">
															<Input className="form-control" type="text" value={this.state.data?.metadata?.last_sms_notification ? this.state.data?.metadata?.last_sms_notification : 'N/A'} disabled />
														</Col>
													</FormGroup>
												</Col>

												<Col md={6}>
													<FormGroup row>
														<Label className="col-md-4 col-form-label">SMS link acessado em</Label>
														<Col md={8} className="container-center-input">
															<Input className="form-control" type="text" value={this.state.data?.metadata?.sms_notification_accessed ? this.state.data?.metadata?.sms_notification_accessed : 'N/A'} disabled />
														</Col>
													</FormGroup>
												</Col>

												<Col md={6}></Col>

												<Col md={12}>
													<FormGroup row>
														<Label className="col-md-12 col-form-label">Fotos</Label>
														<div className="container-img-crawler">
															{this.state.data?.photos?.map((obj, i) => {
																return (
																	<div key={i} className="mr-2">
																		<img src={obj.url} className="img-fluid mx-auto d-block tab-img rounded img-crawler" />
																	</div>
																)
															})}
														</div>
													</FormGroup>
												</Col>
											</div>
										</TabPane>
										<TabPane tabId="2" role="tabpanel">
											<div className="row">
												<Col md={12}>
													<Label className="col-md-4 col-form-label">Imóveis</Label>

													<DataTableAnnouncementsNotifications noDelete fetchEvent={this.fetchNotifications}
														crudButtons={true} crudUrl="/notifications" data={this.state.data} idRow={`id`} noShow />
												</Col>
											</div>
										</TabPane>
										<TabPane tabId="3" role="tabpanel">
											<div className="row">
												<Col md={12}>
													<Label className="col-md-4 col-form-label">Negociações como locatário</Label>

													<DataTableCrawlerLinks noDelete fetchEvent={this.fetchLinks} crudButtons={true}
														crudUrl="/public-links" idRow={`id`} noShow />
												</Col>
											</div>
										</TabPane>
									</TabContent>
								</CardBody>
							</Card>

							<Card>
								<CardBody className="container-space-between">
									<Link to='/crawler/index' className="btn btn-danger btn-bold"><i className="fa fa-arrow-left mr-2"></i>Voltar</Link>
								</CardBody>
							</Card>
						</Container>
					</div>
				</BetterCircularProgress>
			</React.Fragment>
		)
	}
}

export default CrawlerShow;