import React, { Component } from 'react';
import { Container, Col, Label, Row } from "reactstrap";
import { store as storeUser } from '../../crud/users.crud';
import { formatCPFInput, limparCPF, formatTelefoneInput, limparTelefone, getStates, buscaCep } from '../../helpers/Utils';
import { Link, Redirect } from "react-router-dom";
import { Formik } from "formik";
import BetterAlerts from '../../components/BetterAlerts';
import { validateFormUser } from '../../helpers/FormValidators';
import { TextField } from "@material-ui/core";
import BetterSelect from '../../components/BetterSelect';
import Breadcrumbs from '../../components/Breadcrumb';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faUserPlus } from '@fortawesome/pro-regular-svg-icons';

class UsersNew extends Component {

	state = {
		submitted: false,
		success: false,
		errors: [],
	}

	onSubmit = async (values) => {
		this.setState(({ submitted: true }));

		let body = {
			name: values.name,
			email: values.email,
			password: values.password,
			phone: limparTelefone(values.phone),
			cpf: limparCPF(values.cpf) ?? null,
			signup_source: 'organic',
			auto_created: true,
			address: {
				city: values?.city ?? null,
				complement: values?.complement ?? null,
				neighbourhood: values?.neighbourhood ?? null,
				number: values?.number ?? null,
				state: values?.state ?? null,
				street: values?.street ?? null,
				zip_code: values?.zip_code ?? null
			},
			type_account: 'PF'
		}

		await storeUser(body).catch((err) => {
			this.setState({ submitted: false, success: false, errors: err.response.data.error ? [this.processErrorMessage(err.response.data)] : [] });
		}).then((res) => {
			if (res?.status === 200) {
				this.setState({ submitted: false, success: !Boolean(res.data.error), errors: res.data.error ? [res.data.error] : [] })
			}
		});
	};

	processErrorMessage = (data) => {
		const message = data.message;

		switch (message) {
			case 'Duplicated phone':
				return "Número de telefone duplicado.";

			case "Duplicated Email":
				return "Endereço de e-mail duplicado.";

			case 'Invalid Data':
				if (data.details[0].message == 'Invalid CPF') {
					return "CPF inválido.";
				} else {
					return "Dados inválidos.";
				}

			default:
				return message;
		}
	}


	getInitialValues = () => {
		return {
			name: '',
			cpf: '',
			email: '',
			phone: '',
			password: '',

			zip_code: '',
			state: '',
			number: '',
			complement: '',
			neighbourhood: '',
			city: '',
			street: '',
		};
	};

	render() {
		return (
			<div className="page-content container-page userNew">
				<Container fluid>
					<BetterAlerts errors={this.state.errors} />

					{this.state.success ?
						<Redirect to={{ pathname: '/users/index', state: { success: ['Usuário cadastrado com sucesso.'] } }} />
						: null
					}

					<Formik initialValues={this.getInitialValues()} validate={values => validateFormUser(values, 'store')} onSubmit={(values) => this.onSubmit(values)}>
						{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
							<form noValidate={true} autoComplete="off" onSubmit={handleSubmit} className="form">
								<div style={{ width: '736px' }}>
									<div className='header' style={{ marginBottom: 0 }}>
										<h1>Criar usuário</h1>

										<Breadcrumbs breadcrumbItems={[{ link: '/users/index', title: 'Cadastros' }, { link: '/users/index', title: 'Usuários' }, { link: `/users/new`, title: 'Novo usuário' }]} />
									</div>

									<div className='box'>
										<h5>DADOS PESSOAIS</h5>

										<div className='formulario'>
											<Col md={12} className="p-0 m-0">
												<Label>Nome completo</Label>
												<TextField name="name" type="text" placeholder='Nome completo do usuário' variant="outlined" value={values.name} onBlur={handleBlur} onChange={handleChange}
													helperText={touched.name && errors.name} error={Boolean(touched.name && errors.name)} />
											</Col>

											<Col md={12} className="p-0 m-0">
												<Label>CPF</Label>
												<TextField name="cpf" type="text" placeholder='000.000.000-00' variant="outlined" value={values.cpf} onBlur={handleBlur} onChange={e => handleChange(formatCPFInput(e))}
													helperText={touched.cpf && errors.cpf} error={Boolean(touched.cpf && errors.cpf)} inputProps={{ maxLength: 14 }} />
											</Col>

											<Col md={12} className="p-0 m-0">
												<Label>Email</Label>

												<TextField name="email" type="email" placeholder='usuario@servidor.com.br' variant="outlined" value={values.email} onBlur={handleBlur} onChange={handleChange}
													helperText={touched.email && errors.email} error={Boolean(touched.email && errors.email)} />
											</Col>


											<Col md={12} className="p-0 m-0">
												<Label>Telefone (Whatsapp)</Label>

												<TextField name="phone" type="text" placeholder='(00) 00000-0000' variant="outlined" value={values.phone} onBlur={handleBlur} onChange={(e) => handleChange(formatTelefoneInput(e))}
													helperText={touched.phone && errors.phone} error={Boolean(touched.phone && errors.phone)} inputProps={{ maxLength: 15 }} />
											</Col>

											<Col md={12} className="p-0 m-0">
												<Label>Senha</Label>

												<TextField name="password" placeholder='********' type="password" variant="outlined" value={values.password} onBlur={handleBlur} onChange={handleChange}
													helperText={touched.password && errors.password} error={Boolean(touched.password && errors.password)} />
											</Col>
										</div>
									</div>

									<div className='box'>
										<h5>Endereço</h5>

										<div className='formulario'>
											<Row className="p-0 m-0">
												<Col lg={6} className="p-0 m-0">
													<Label>CEP</Label>

													<TextField name="zip_code" placeholder='88888-888' type="text" variant="outlined" value={values.zip_code} onBlur={handleBlur} onChange={(e) => handleChange(buscaCep(e, setFieldValue, "street", "neighbourhood", "city", "state"))}
														helperText={touched.zip_code && errors.zip_code} error={Boolean(touched.zip_code && errors.zip_code)} inputProps={{ maxLength: 9 }} />
												</Col>

												<Col lg={6} className="marginInputMobile">
													<Label>Estado</Label>

													<BetterSelect name="state" blankOption value={values.state} onBlur={handleBlur} onChange={handleChange}
														helperText={touched.state && errors.state} error={Boolean(touched.state && errors.state)} listItem={getStates()}>
													</BetterSelect>
												</Col>
											</Row>

											<Row className="p-0 m-0">
												<Col lg={3} md={12} className="p-0 m-0">
													<Label>Número</Label>

													<TextField name="number" type="text" placeholder='00000' variant="outlined" value={values.number} onBlur={handleBlur} onChange={handleChange}
														helperText={touched.number && errors.number} error={Boolean(touched.number && errors.number)} />
												</Col>

												<Col lg={9} md={12} className="marginInputMobile">
													<Label>Complemento</Label>

													<TextField name="complement" placeholder='Opcional' type="text" variant="outlined" value={values.complement} onBlur={handleBlur} onChange={handleChange}
														helperText={touched.complement && errors.complement} error={Boolean(touched.complement && errors.complement)} />
												</Col>
											</Row>

											<Row className="p-0 m-0">
												<Col lg={6} md={12} className="p-0 m-0">
													<Label>Bairro</Label>

													<TextField name="neighbourhood" placeholder='Nome do bairro' type="text" variant="outlined" value={values.neighbourhood} onBlur={handleBlur} onChange={handleChange}
														helperText={touched.neighbourhood && errors.neighbourhood} error={Boolean(touched.neighbourhood && errors.neighbourhood)} />
												</Col>

												<Col lg={6} md={12} className="marginInputMobile">
													<Label>Cidade</Label>

													<TextField name="city" placeholder='Nome da cidade' type="text" variant="outlined" value={values.city} onBlur={handleBlur} onChange={handleChange}
														helperText={touched.city && errors.city} error={Boolean(touched.city && errors.city)} />
												</Col>
											</Row>

											<Col lg={12} className="pl-0 marginInputMobileRua">
												<Label>Rua</Label>

												<TextField name="street" placeholder='Nome da rua' type="text" variant="outlined" value={values.street} onBlur={handleBlur} onChange={handleChange}
													helperText={touched.street && errors.street} error={Boolean(touched.street && errors.street)} />
											</Col>
										</div>
									</div>

									<div className='box-footer'>
										<Link to='/users/index' className='button-secundary'>
											<i className="fa fa-arrow-left" />
											VOLTAR
										</Link>

										<button type="submit" className='button-primary' disabled={this.state.submitted} style={{ width: 95 }}>
											{this.state.submitted ?
												<>
													<FontAwesomeIcon spin icon={faSync} />
												</> :
												<>
													CRIAR
													<FontAwesomeIcon icon={faUserPlus} />
												</>
											}
										</button>
									</div>
								</div>
							</form>
						)}
					</Formik>
				</Container>
			</div>
		)
	}
}

export default UsersNew;