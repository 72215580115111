import React, { Component, createRef } from 'react';
import { Container, Nav, NavItem, NavLink, TabPane, TabContent, Input, Label, Col, Row } from "reactstrap";
import Breadcrumbs from '../../components/Breadcrumb';
import BetterCircularProgress from '../../components/BetterCircularProgress';
import { Link } from "react-router-dom";
import { aprovarHumanizado, getByPropositionId as getCreditConsultByPropositionId, update as updateLaudo, laudosQuery } from '../../crud/credit-consut.crud';
import { get as getProposition, } from '../../crud/propositions.crud';
import { formatBRL, formatTelefone, limparMoeda } from '../../helpers/Utils';
import { getLaudoBadge, getLaudoBadgeInquilino } from './helpers';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowsRepeat, faCheck, faPen, faRotate, faSpinner, faUser, faUserHeadset } from '@fortawesome/pro-regular-svg-icons';
import DataTableAnaliseNegotiation from '../../components/datatables/analises/DataTableAnaliseNegotiation';
import { get as getUser } from '../../crud/users.crud';

class NegotiationLaudo extends Component {
	DataTableRef = createRef();

	state = {
		loading: false,
		data: null,
		activeTab: '1',
		errors: false,
		crudUrl: '/negotiation',
		contract: false,
		parties: [],
		submittingLaudo: false,
		laudo: null,
		showModalAprovarHumanizado: false,
		humanized_justification: '',
		submittingHumanizado: false,
		totalLaudos: 0,
		totalHistorico: 0,
	};

	componentDidMount = async () => {
		this.setState({ loading: true });

		this.fetchData();
	};

	fetchData = async () => {
		let laudo = null;
		let announcement = null;

		await this.fetchByFilter({ $limit: 0 }).then((res) => {
			this.setState({ totalHistorico: res.data.total });
		})

		await getCreditConsultByPropositionId(this.props.match.params.id).catch(err => { }).then(res => {
			if (res?.status == 200) {
				laudo = res.data.data;
			}
		});

		await getProposition(this.props.match.params.id).catch(err => {
			this.setState({ errors: true });
		}).then((res) => {
			announcement = res?.data?.data;

			laudosQuery({ tenant_id: res.data.data.tenant_id, created_at_gte: moment().subtract(30, 'days').format('YYYY-MM-DD') }).catch(error => {
				//err
			}).then(result => {
				if (result?.status == 200) {
					this.setState({ totalLaudos: result.data.total });
				}
			});
		});

		if (laudo) {
			const pretendentes = laudo?.fc_laudo?.pretendentes;
			const tenantCpf = announcement?.tenant?.cpf;
			const tenantId = announcement?.tenant?.id;

			const foundTenant = pretendentes.find(pre => pre.pessoa.cpf === tenantCpf);
			if (foundTenant) {
				foundTenant.pessoa.user_id = tenantId;
			}

			if (announcement?.parties?.length > 0) {
				announcement?.parties.forEach(part => {
					const foundPretendente = pretendentes.find(pre => pre.pessoa.cpf === part?.userParty.cpf);
					if (foundPretendente) {
						foundPretendente.pessoa.user_id = part.user_id;
					}
				});
			}

			for await (const pretendente of pretendentes) {
				if (pretendente.pessoa.user_id) {
					await getUser(pretendente.pessoa.user_id).catch(err => {
						pretendente.pessoa.userSystem = null;
					}).then(res => {
						if (res?.data?.data) {
							pretendente.pessoa.userSystem = res.data.data;
						}
					});
				}
			}
		}

		this.setState({ loading: false, laudo, data: announcement });
	};

	reprocessLaudo = () => {
		this.setState({ submittingLaudo: true });

		let data = { proposition_id: this.props.match.params.id };
		if (this.state.totalLaudos == 30) data.adminOverride = true;

		updateLaudo(data).catch(err => {
			this.setState({ errors: true, message: err?.response?.data?.message });
		}).then(res => {
			if (res?.status === 200) {
				window.location.reload();
			}
		}).finally(() => {
			this.setState({ submittingLaudo: false });
		});
	};

	htmlSugestao = (html) => {
		return <div dangerouslySetInnerHTML={{ __html: html }} />
	};

	getLaudoReasons = () => {
		if (this.state.laudo?.fc_laudo) {
			for (const pretendente of this.state.laudo.fc_laudo.pretendentes) {
				return (
					<div className='col-lg-12'>
						{pretendente.laudo.situacao_cpf.result == 'Irregular' || pretendente.laudo.situacao_cnpj.result == 'Irregular' ? (<p>CPF ou CNPJ em situação irregular - {pretendente.pessoa.nome}.</p>) : null}
						{pretendente.laudo.suspeita_obito.result ? (<p>Suspeita de óbito encontrada - {pretendente.pessoa.nome}.</p>) : null}
						{pretendente.laudo.restricoes_financeiras.result.pendencias.resumo.valor_total_pendencia > parseFloat(this.state.laudo?.fc_laudo?.locacao?.aluguel) ? (<p>As pendências dos inquilinos extrapolam o valor do aluguel do imóvel - {pretendente.pessoa.nome}.</p>) : null}
					</div>
				)
			}
		}
	}

	approveHumanized = () => {
		this.setState({ showModalAprovarHumanizado: true });
	};

	getIncomeXRent = (laudo) => {
		let aluguel = parseFloat(laudo?.fc_laudo?.locacao.aluguel) * 3;

		return aluguel;
	}

	getTotalIncome = (laudo) => {
		let totalIncome = 0;

		if (laudo?.fc_laudo) {
			for (const inquilino of laudo?.fc_laudo?.parecer.sintese) {
				let tenantIncome = parseFloat(inquilino.renda.principal.valor);
				totalIncome = totalIncome + tenantIncome;
			}

			return totalIncome
		}
	}

	getTotalPendencia = (laudo) => {

		let total = 0;

		if (laudo?.fc_laudo) {
			for (const pretendente of laudo?.fc_laudo?.pretendentes) {
				let tenantPendencia = pretendente.laudo.restricoes_financeiras?.result?.pendencias?.resumo?.valor_total_pendencia ?? 0
				total = total + tenantPendencia;
			}

			return total;

		}
	}

	getTotalProtestos = (laudo) => {

		let total = 0;

		if (laudo?.fc_laudo) {
			for (const pretendente of laudo?.fc_laudo?.pretendentes) {
				let tenantPendencia = pretendente.laudo.restricoes_financeiras?.result?.protestos?.resumo?.valor_total_pendencia ?? 0
				total = total + tenantPendencia;
			}

			return total;

		}
	}

	getSituacaoCpf = () => {
		let situacao = 'N / A';

		if (this.state.laudo?.fc_laudo) {
			situacao = 'Regular';

			this.state.laudo?.fc_laudo?.pretendentes?.forEach((obj, i) => {
				if (obj.laudo?.situacao_cpf?.result != 'Regular') situacao = 'Irregular';
			});
		}

		return situacao;
	};

	getDiffValues = (laudo) => {
		let incomeXrent = true;
		let aluguel = parseFloat(laudo?.fc_laudo?.locacao.aluguel);
		let quantoFalta = 0;

		let totalIncome = 0;

		if (laudo?.fc_laudo) {
			for (const inquilino of laudo?.fc_laudo?.parecer.sintese) {
				let tenantIncome = parseFloat(inquilino.renda.principal.valor);
				totalIncome = totalIncome + tenantIncome;
			}

			const minAccepted = (aluguel - aluguel * 0.2) * 3;

			if (totalIncome < minAccepted) {
				return false
			} else {
				return true;
			}
		}

	}

	getSuspeitaObito = () => {
		let suspeitaObito = 'N / A';

		if (this.state.laudo?.fc_laudo) {
			let positivo = true;

			this.state.laudo?.fc_laudo?.pretendentes?.forEach((obj, i) => {
				if (!obj.laudo?.suspeita_obito?.result) positivo = false;
			});

			suspeitaObito = positivo ? 'Sim' : 'Não';
		}

		return suspeitaObito;
	};

	getRendaPessoal = () => {
		let rendaPessoal = 'N / A';

		if (this.state.laudo?.fc_laudo) {
			let renda = 0;

			this.state.laudo?.fc_laudo?.pretendentes?.forEach((obj, i) => {
				if (obj.pessoa?.renda?.principal?.valor) {
					renda = renda + parseFloat(obj.pessoa.renda.principal.valor);
				}
			});

			rendaPessoal = formatBRL(renda);
		}

		return rendaPessoal;
	};

	getValorTotalPendente = () => {
		// let valorPendente = 'N / A';
		let soma = 0;
		let somaProtestos = 0;
		let somaPendencias = 0;

		if (this.state.laudo?.fc_laudo) {
			this.state.laudo?.fc_laudo?.pretendentes?.forEach((obj, i) => {
				if (obj.laudo?.restricoes_financeiras?.result?.protestos?.resumo?.valor_total_pendencia) {
					soma = soma + parseFloat(obj.laudo?.restricoes_financeiras?.result?.protestos?.resumo?.valor_total_pendencia);
					somaProtestos = somaProtestos + parseFloat(obj.laudo?.restricoes_financeiras?.result?.protestos?.resumo?.valor_total_pendencia);
				}
				if (obj.laudo?.restricoes_financeiras?.result?.pendencias?.resumo?.valor_total_pendencia) {
					soma = soma + parseFloat(obj.laudo?.restricoes_financeiras?.result?.pendencias?.resumo?.valor_total_pendencia);
					somaPendencias = somaPendencias + parseFloat(obj.laudo?.restricoes_financeiras?.result?.pendencias?.resumo?.valor_total_pendencia);
				}
			});

			// valorPendente = formatBRL(valor);
		}

		return { total: formatBRL(soma), totalProtestos: formatBRL(somaProtestos), totalPendencias: formatBRL(somaPendencias) };
	};

	valorPendenteXAluguel = () => {
		const aluguelTotal = parseFloat(this.state.laudo?.fc_laudo?.locacao?.aluguel) + parseFloat(this.state.laudo?.fc_laudo?.locacao?.iptu ?? 0) + parseFloat(this.state.laudo?.fc_laudo?.locacao?.condominio ?? 0);

		// let valorPendente = 'N / A';
		let soma = 0;
		let somaProtestos = 0;
		let somaPendencias = 0;

		if (this.state.laudo?.fc_laudo) {
			this.state.laudo?.fc_laudo?.pretendentes?.forEach((obj, i) => {
				if (obj.laudo?.restricoes_financeiras?.result?.protestos?.resumo?.valor_total_pendencia) {
					soma = soma + parseFloat(obj.laudo?.restricoes_financeiras?.result?.protestos?.resumo?.valor_total_pendencia);
					somaProtestos = somaProtestos + parseFloat(obj.laudo?.restricoes_financeiras?.result?.protestos?.resumo?.valor_total_pendencia);
				}
				if (obj.laudo?.restricoes_financeiras?.result?.pendencias?.resumo?.valor_total_pendencia) {
					soma = soma + parseFloat(obj.laudo?.restricoes_financeiras?.result?.pendencias?.resumo?.valor_total_pendencia);
					somaPendencias = somaPendencias + parseFloat(obj.laudo?.restricoes_financeiras?.result?.pendencias?.resumo?.valor_total_pendencia);
				}
			});

			// valorPendente = formatBRL(valor);
		}

		if (soma > aluguelTotal) {
			return true;
		} else {
			return false;
		}
	}

	getFraude = () => {
		let fraude = 'N / A';

		if (this.state.laudo?.fc_laudo) {
			let hasFraude = false;

			this.state.laudo?.fc_laudo?.pretendentes?.forEach((obj, i) => {
				if (obj.pessoa?.tem_alerta_fraude) hasFraude = true;
			});

			fraude = hasFraude ? 'Sim' : 'Nada consta';
		}

		return fraude;
	};

	getQtdeConsultasRecentes = (usuario) => {
		const qtdeConsultasRecentes = usuario?.laudo?.rede_ficha?.pesquisas_anteriores_api?.result?.total_consultas_credito

		return qtdeConsultasRecentes;
	};

	getInquilinoIdade = () => {
		return (
			<td>
				{this.state.laudo?.fc_laudo?.pretendentes.map((obj, i) => {
					return (
						<p key={`inq_idade_${i}`} style={{ color: obj.laudo.veracidade_data_nascimento.result.idade >= 65 ? '#1CBB8C' : '#EFA200' }}>{`[${obj.laudo.veracidade_data_nascimento.result.idade}] ${obj.laudo.veracidade_data_nascimento.result.idade >= 18 && obj.laudo.veracidade_data_nascimento.result.idade <= 25 ? '18 - 25 peça comprovante de renda' : ''}${obj.laudo.veracidade_data_nascimento.result.idade >= 26 && obj.laudo.veracidade_data_nascimento.result.idade <= 35 ? '26 - 35 peça comprovante de renda' : ''}`}</p>
					)
				})}
			</td>
		)
	};

	handleCloseModalAprovarHumanizado = () => {
		this.setState({ showModalAprovarHumanizado: false });
	};

	handleApproveModalAprovarHumanizado = () => {
		if (this.state.humanized_justification.trim() == '') {
			return Swal.fire('Ops', 'Você precisa preencher o motivo antes de continuar.', 'error');
		}

		this.setState({ submittingHumanizado: true });

		aprovarHumanizado({
			id: this.state.laudo.id,
			humanized_justification: this.state.humanized_justification
		}).catch(err => {
			return Swal.fire('Ops', err?.response?.data ?? 'Ocorreu um erro ao aprovar.', 'error');
		}).then(res => {
			if (res?.status == 200) {
				this.setState({ submittingHumanizado: false, loading: true, showModalAprovarHumanizado: false, humanized_justification: '' });
				this.fetchData();
			} else {
				this.setState({ submittingHumanizado: false });
				return Swal.fire('Ops', res?.data?.error ?? 'Ocorreu um erro ao aprovar.', 'error');
			}

		});
	};

	getAcoesDespejoInfo = () => {
		let retorno = '';

		if (this.state.laudo?.fc_laudo) {
			this.state.laudo?.fc_laudo?.inquilinos?.forEach((obj, i) => {
				if (obj.laudo?.restricoes_financeiras?.result?.acoes?.info != 'NADA CONSTA') {
					if (retorno == '') {
						retorno = `${obj.laudo?.restricoes_financeiras?.result?.acoes?.detalhes[0].tipo} - ${obj.laudo?.restricoes_financeiras?.result?.acoes?.detalhes[0].assunto}`;
					} else {
						retorno = `${retorno} | ${obj.laudo?.restricoes_financeiras?.result?.acoes?.detalhes[0].tipo} - ${obj.laudo?.restricoes_financeiras?.result?.acoes?.detalhes[0].assunto}`;
					}
				}
			});
		}

		return retorno == '' ? 'NADA CONSTA' : retorno;
	};

	getAcoesCivel = () => {
		let retorno = '';

		if (this.state.laudo?.fc_laudo) {
			this.state.laudo?.fc_laudo?.inquilinos?.forEach((obj, i) => {
				if (obj.laudo?.restricoes_financeiras?.result?.acoes_civeis?.info != 'NADA CONSTA') {
					if (retorno == '') {
						retorno = `${obj.laudo?.restricoes_financeiras?.result?.acoes_civeis?.detalhes[0].tipo} - ${obj.laudo?.restricoes_financeiras?.result?.acoes_civeis?.detalhes[0].assunto} Última movimentação: ${moment(obj.laudo?.restricoes_financeiras?.result?.acoes_civeis?.resumo?.data_ultima_ocorrencia).format('DD/MM/YYYY')}`;
					} else {
						retorno = `${retorno} | ${obj.laudo?.restricoes_financeiras?.result?.acoes_civeis?.detalhes[0].tipo} - ${obj.laudo?.restricoes_financeiras?.result?.acoes_civeis?.detalhes[0].assunto}  Última movimentação: ${moment(obj.laudo?.restricoes_financeiras?.result?.acoes_civeis?.resumo?.data_ultima_ocorrencia).format('DD/MM/YYYY')}`;
					}
				}
			});
		}

		return retorno == '' ? 'NADA CONSTA' : retorno;
	};

	getAcoesCivelColor = () => {
		let retorno = '#1CBB8C';

		if (this.state.laudo?.fc_laudo) {
			this.state.laudo?.fc_laudo?.inquilinos?.forEach((obj, i) => {
				if (obj.laudo?.restricoes_financeiras?.result?.acoes_civeis?.info != 'NADA CONSTA') {
					if (moment(obj.laudo?.restricoes_financeiras?.result?.acoes_civeis?.resumo?.data_ultima_ocorrencia).isAfter(moment().subtract(18, 'months'))) {
						retorno = '#FF3D60';
					}
				}
			});
		}

		return retorno;
	};

	getAcoesCriminal = () => {
		let retorno = '';

		if (this.state.laudo?.fc_laudo) {
			this.state.laudo?.fc_laudo?.inquilinos?.forEach((obj, i) => {
				if (obj.laudo?.restricoes_financeiras?.result?.acoes_criminais?.info != 'NADA CONSTA') {
					if (retorno == '') {
						retorno = `${obj.laudo?.restricoes_financeiras?.result?.acoes_criminais?.detalhes[0].tipo} - ${obj.laudo?.restricoes_financeiras?.result?.acoes_criminais?.detalhes[0].assunto}`;
					} else {
						retorno = `${retorno} | ${obj.laudo?.restricoes_financeiras?.result?.acoes_criminais?.detalhes[0].tipo} - ${obj.laudo?.restricoes_financeiras?.result?.acoes_criminais?.detalhes[0].assunto}`;
					}
				}
			});
		}

		return retorno == '' ? 'NADA CONSTA' : retorno;
	};

	enableDisableHumanized = () => {
		if (this.state.submittingLaudo) return this.state.submittingLaudo;

		if (this.state.laudo) {
			let hasAcoesDespejo = false;
			let veryHighRisk = false;

			for (const pretendente of this.state.laudo?.fc_laudo?.pretendentes) {
				if (pretendente.laudo.restricoes_financeiras.result.acoes.info !== 'NADA CONSTA') {
					hasAcoesDespejo = true;
					// -------> Possui ação de despejo: pretendente.laudo.restricoes_financeiras.result.acoes.info
				}

				if (pretendente.laudo.parecer_sistemico[0].parecer_key === 'PARECER_NIVEL_5') {
					veryHighRisk = true;
					// -------> Inquilino com risco muito alto encontrado
				}
			}

			return (hasAcoesDespejo || veryHighRisk);
		}

		return false;
	};

	handleClose = () => {
		this.setState({ showModalAprovarHumanizado: false });
	}

	toggleTab(tab) {
		if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
	};

	getTagRegularidade = (obj) => {
		if (obj?.status != "regular") return <div className='tag' style={{ backgroundColor: '#FF3D60' }}>Irregular</div>
		else return <div className='tag' style={{ backgroundColor: '#1CBB8C' }}>Nada consta</div>
	}

	fetchByFilter = async (data) => {
		let params = { ...data };

		params.proposition_id = this.props.match.params.id;

		return laudosQuery(params);
	};

	getAnaliseTag = (parecer_key) => {
		let color = '';
		let text = '';

		if (parecer_key) {
			switch (parecer_key) {
				case 'APPROVED':
					text = "Aprovado";
					color = '#1CBB8C';
					break;
				case 'REFUSED':
					text = "Recusada";
					color = '#FF3D60';
					break;
				case 'AWAITING':
					text = "Aguardando";
					color = '#FCB92C';
					break;
				case 'NOT_CONSULTED':
					text = "Não consultado";
					color = '#8590A5';
					break;
				case 'APPROVED_SYSTEM':
					text = "Aprovado sistêmico";
					color = '#4AA3FF';
					break;
				case 'APPROVED_HUMANIZED':
					text = "Aprovado humanizado";
					color = '#5664D2';
					break;
				case 'EXAUSTED_ANALYSIS':
					text = "Consultas esgotadas";
					color = '#EFA200';
					break;
				case 'FRAUD':
					text = "Fraude";
					color = '#852032';
					break;
				case 'PARECER_SUSPEITA_OBITO':
					text = "Suspeita de óbito";
					color = '#FF3D60';
					break;
				case 'PARECER_NAO_APURADO':
					text = "Não apurado";
					color = '#FF3D60';
					break;
				case 'PARECER_NIVEL_1':
					text = "Risco muito baixo";
					color = '#1CBB8C';
					break;
				case 'PARECER_NIVEL_2':
					text = "Risco baixo";
					color = '#1CBB8C';
					break;
				case 'PARECER_NIVEL_3':
					text = "Risco médio";
					color = '#4AA3FF';
					break;
				case 'PARECER_NIVEL_4':
					text = "Risco acima da média";
					color = '#FF3D60';
					break;
				case 'PARECER_NIVEL_5':
					text = "Risco muito alto";
					color = '#FF3D60';
					break;
				case 'ALERTA_FRAUDE':
					text = "Alerta de fraude";
					color = '#FF3D60';
					break;
				case 'ALERTA_FRAUDE_CRIMINAL':
					text = "Alerta de fraude criminal";
					color = '#FF3D60';
					break;
				case 'ALERTA_ACAO_CRIMINAL':
					text = "Alerta de ação criminal";
					color = '#FF3D60';
					break;
				case 'ALERTA_ACAO_CIVIL':
					text = "Alerta de ação civil";
					color = '#FF3D60';
					break;
				default:
					color = '#343A40';
					text = 'Não informado';
					break;
			}
		} else {
			color = '#FCB92C';
			text = 'Aguardando';
		}

		return (
			<div className="tag" style={{ textAlign: "center", backgroundColor: color }}>
				{text}
			</div>
		);
	}

	checkAcoesCivis = (acoes) => {
		const last = acoes?.resumo?.data_ultima_ocorrencia;

		if (acoes?.info === 'CONSTA') {
			if (moment(last).isBefore(moment().subtract(18, 'months'))) {
				return 'NADA CONSTA'
			} else {
				return 'CONSTA'
			}
		} else {
			return 'NADA CONSTA'
		}
	}

	render() {
		var tipoNegociacao = new URLSearchParams(document.location.search);
		tipoNegociacao = tipoNegociacao.get("typeNegociacao");

		const BoxInfo = ({ title, href, children, classNameBody, preenchido, noTag = false, indicadorNumero, comissionamento, cardComissionamento, noHeader, noEdit, center = false, width, marginLeft }) => {
			const style = {
				width: width ?? '',
				marginLeft: marginLeft ?? ''
			}

			if (center) {
				style.display = 'flex';
				style.justifyContent = 'center';
				style.alignItems = 'center';
			}

			return (
				<div className='boxInfoUser' style={style}>
					{noHeader ? null : (
						<div className='headerBoxInfoUser'>
							<div className='title-tag'>
								<h4>{title}</h4>

								{indicadorNumero && (
									<div className='indicadorUsuarios'>
										{(indicadorNumero) ?? 0}
									</div>
								)}

								{!cardComissionamento ? null :
									comissionamento ? (
										<div className='tag'>
											Sim
										</div>
									) :
										<div className='tag' style={{ backgroundColor: '#FCB92C' }}>
											Não
										</div>
								}

								{noTag ? null : preenchido ?
									<div className='tag'>
										Preenchido
									</div>
									:
									<div className='tag' style={{ backgroundColor: '#FCB92C' }}>
										Pendente
									</div>
								}
							</div>

							{noEdit ? null :
								<a href={href} className='btnSecundary'>
									EDITAR
									<FontAwesomeIcon icon={faPen} />
								</a>
							}
						</div>
					)}

					<div className={`${classNameBody} boxInfoBody`}>
						{children}
					</div>
				</div>
			)
		};

		const UserBox = ({ usuario }) => {
			const usuarioSistema = usuario.pessoa?.userSystem;

			const parecerKey = usuario?.laudo?.parecer_sistemico?.[0]?.parecer_key;

			return (
				<div className='boxInfos-container boxInfos-container-unico boxInfoUser boxUsuarioNegociacao usuarioConstituintes'>
					<div className='boxUser boxAnuncioNew'>
						<div className='headerUser'>
							<div className='container-imovel-imagem' style={{ alignItems: 'center' }}>
								<div className="boxUserTableUsers">
									{usuarioSistema?.profile_image?.medium?.url ?
										<div className='userPhoto' style={{ backgroundImage: `url(${usuarioSistema?.profile_image?.medium?.url})`, marginRight: 0 }} />
										:
										<div className='userPhotoNone' style={{ marginRight: 0 }}>
											<FontAwesomeIcon icon={faUser} />
										</div>
									}
								</div>

								<div className='tagsAssets groupAlign' style={{ flex: 'auto' }}>
									<div style={{ display: 'flex' }}>
										<h3 style={{ marginBottom: 0, marginRight: '8px' }}>{usuarioSistema?.name}</h3>
										{/* {this.getAnaliseTag(parecerKey)} */}
									</div>

									<div className="informationsDatas">
										{usuarioSistema?.birthdate && moment(usuarioSistema?.birthdate).isAfter(moment().subtract(35, 'years')) && moment(usuarioSistema?.birthdate).isBefore(moment().subtract(18, 'years')) ? (
											<div className='boxInformation'>
												<p>{moment().diff(moment(usuarioSistema?.birthdate), 'years')} anos</p>
											</div>
										) : null}

										<div className='boxInformation'>
											<p>{formatTelefone(usuarioSistema?.phone ?? '0')}</p>
										</div>

										<div className='boxInformation'>
											<p>{usuarioSistema?.email}</p>
										</div>
									</div>

									<div style={{ display: 'flex', marginTop: '16px' }}>
										{this.getAnaliseTag(parecerKey)}

										{usuarioSistema?.birthdate && moment(usuarioSistema?.birthdate).isAfter(moment().subtract(35, 'years')) && moment(usuarioSistema?.birthdate).isBefore(moment().subtract(18, 'years')) ? (
											<div className="tag" style={{ textAlign: "center", backgroundColor: '#FF3D60', marginLeft: '16px' }}>
												Peça comprovante de renda
											</div>
										) : null}
									</div>
								</div>
							</div>

							<div className="d-flex" style={{ gap: 24 }}>
								<div className='boxInformation'>
									<p>Renda pessoal</p>
									<b>{formatBRL(usuarioSistema?.employment?.gross_income ?? 0)}</b>
								</div>

								<div className='boxInformation'>
									<p>Situação de CPF</p>
									<div className='tag' style={usuario?.laudo?.situacao_cpf?.result == 'Regular' ? { background: '#1CBB8C' } : { background: '#FF3D60' }}>
										{usuario?.laudo?.situacao_cpf?.result}
									</div>
								</div>

								<div className='boxInformation'>
									<p>Suspeita de óbito</p>
									<div className='tag' style={usuario?.laudo?.suspeita_obito?.result ? { background: '#FF3D60' } : { background: '#1CBB8C' }}>
										{usuario?.laudo?.suspeita_obito?.result ? 'Sim' : 'Não'}
									</div>
								</div>

								<div className='boxInformation'>
									<p>Consultas recentes</p>
									<div className='' >
										<p style={{
											color: '#343A40', fontFamily: 'Inter', fontSize: '13.008px', fontStyle: 'normal', fontWeight: 600, lineHeight: 'normal', marginBottom: '0px'
										}}>	{this.getQtdeConsultasRecentes(usuario) ?? 1} consultas</p>
									</div>
								</div>

							</div>
						</div>

						<hr />

						<div className='acoes-box'>
							<div className='title' style={{ width: '20%' }}>Ações de despejo</div>
							<div className='tagBox'>
								{this.getTagRegularidade({ status: usuario?.laudo?.restricoes_financeiras?.result?.acoes?.info == 'NADA CONSTA' ? 'regular' : 'irregular' })}
							</div>
							<div className='text'>{usuario?.laudo?.restricoes_financeiras?.result?.acoes?.detalhes?.[0]?.tipo} - {usuario?.laudo?.restricoes_financeiras?.result?.acoes?.detalhes?.[0]?.assunto}</div>
						</div>

						<hr className='m-0' />

						<div className='acoes-box'>
							<div className='title' style={{ width: '20%' }}>Ações cível</div>
							<div className='tagBox'>
								{this.getTagRegularidade({ status: this.checkAcoesCivis(usuario?.laudo?.restricoes_financeiras?.result?.acoes_civeis) == 'NADA CONSTA' ? 'regular' : 'irregular' })}
							</div>
							<div className='text'>	{usuario?.laudo?.restricoes_financeiras?.result?.acoes_civeis?.resumo?.data_ultima_ocorrencia ? (
								<><span style={{
									color: '#505D69', fontFamily: 'Inter', fontSize: '13.008px', fontStyle: 'normal', fontWeight: 600, lineHeight: 'normal'
								}}>Última movimentação: </span>{moment(usuario?.laudo?.restricoes_financeiras?.result?.acoes_civeis?.resumo?.data_ultima_ocorrencia, 'YYYY-MM-DD').format('DD/MM/YYYY')}
									<br /></>
							) : null}

								{usuario?.laudo?.restricoes_financeiras?.result?.acoes_civeis?.detalhes?.[0]?.tipo} - {usuario?.laudo?.restricoes_financeiras?.result?.acoes_civeis?.detalhes?.[0]?.assunto}</div>
						</div>

						<hr className='m-0' />

						<div className='acoes-box'>
							<div className='title' style={{ width: '20%' }}>Ações de fraude</div>
							<div className='tagBox'>
								{this.getTagRegularidade({ status: usuario?.pessoa?.tem_alerta_fraude ? 'irregular' : 'regular' })}
							</div>
							<div className='text'>{usuario?.pessoa?.tem_alerta_fraude ? 'ALERTA FRAUDE' : '-'}</div>
						</div>
					</div>
				</div>
			)
		}

		return (
			<BetterCircularProgress loading={this.state.loading}>
				<div className="page-content container-page analise">
					<Container fluid>
						<div className='header'>
							<h1>Análise de crédito</h1>

							<Breadcrumbs breadcrumbItems={[{ link: '/negotiation/index', title: 'Negociações' }, { link: '/negotiation/index', title: 'Negociações e contratos' }, { link: `/negotiation/show/${this.props.match.params.id}`, title: `ID ${this.props.match.params.id}` }, { title: `Análise de crédito` }]} />
						</div>

						<div className='box box-analise'>
							<Nav tabs className="nav-tabs-custom" role="tablist">
								<NavItem>
									<NavLink id="info-tab" className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggleTab('1'); }}>
										Resultado
									</NavLink>
								</NavItem>

								<NavItem>
									<NavLink id="announcements-tab" className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggleTab('2'); }}>
										<div className='tabName'>Histórico
											<div className='indicadorUsuarios'>
												{(Number(this.state.totalHistorico ?? 0)) ?? 0}
											</div>
										</div>
									</NavLink>
								</NavItem>
							</Nav>

							<BetterCircularProgress loading={this.state.loading}>
								<TabContent activeTab={this.state.activeTab}>
									<TabPane tabId="1" role="tabpanel">
										<div className='header'>
											<div className='left'>
												<h3>Resultado final</h3>
												{getLaudoBadge(this.state.laudo)}
											</div>

											<div className='right'>
												<Link className='btnSecundary' to={this.state.submittingLaudo ? `?typeNegociacao=${tipoNegociacao}` : `/negotiation/edit/${this.props.match.params.id}?typeNegociacao=${tipoNegociacao}`}>
													EDITAR NEGOCIAÇÃO <FontAwesomeIcon icon={faPen} />
												</Link>

												<button className='btnSecundary' disabled={this.state.submittingLaudo} onClick={() => this.setState({ showModalAprovarHumanizado: true })}>
													{this.state.submittingLaudo ?
														<FontAwesomeIcon spin icon={faSpinner} />
														:
														<>APROVAR HUMANIZADO <FontAwesomeIcon icon={faUserHeadset} /></>
													}
												</button>

												<button className='buttonPrimary' disabled={this.state.submittingLaudo} onClick={this.reprocessLaudo}>
													{this.state.submittingLaudo ?
														<FontAwesomeIcon spin icon={faSpinner} />
														:
														<>RETENTAR <FontAwesomeIcon icon={faArrowsRepeat} /></>
													}
												</button>
											</div>
										</div>

										<div className='boxInfos-container boxInfos-container-unico boxResultadoPrincipal' style={{ gridTemplateColumns: '1fr 1fr' }}>
											<BoxInfo noHeader noTag width='120%'>
												<Row className='content-box' style={this.state.laudo?.fc_laudo ? {} : { justifyContent: 'center', padding: '32px 0' }}>
													{this.state.laudo?.fc_laudo ?
														<div className='analiseReview'>
															<header style={{ alignItems: 'center' }}>
																CONSULTADO EM {moment(this.state.laudo.created_at).format('DD/MM/YY HH:mm:ss')}
															</header>

															<div className='body'>
																<div className='body-1'>
																	<div className='container-info1'>
																		<div className='boxInformation'>
																			<p>Renda pessoal</p>
																			<b>{formatBRL(this.state.laudo?.fc_laudo?.parecer?.sintese[0]?.renda?.principal?.valor ?? 0)}</b>
																		</div>

																		<div className='boxInformation'>
																			<p>Valor total pendente</p>
																			<b style={{ color: limparMoeda(this.getValorTotalPendente().total) > parseFloat(this.state.laudo?.fc_laudo?.locacao?.aluguel) ? '#FF3D60' : '#1CBB8C' }}>
																				{this.getValorTotalPendente().total}
																			</b>
																		</div>
																	</div>

																	<div className='container-info1'>
																		<div className='boxInformation'>
																			<p>Situação de CPF</p>
																			<div className='tag' style={this.state.laudo?.fc_laudo?.pretendentes?.[0]?.laudo?.situacao_cpf?.result == 'Regular' ? { background: '#1CBB8C' } : { background: '#FF3D60' }}>
																				{this.state.laudo?.fc_laudo?.pretendentes?.[0]?.laudo?.situacao_cpf?.result}
																			</div>
																		</div>

																		<div className='boxInformation'>
																			<p>Suspeita de óbito</p>
																			<div className='tag' style={this.state.laudo?.fc_laudo?.pretendentes?.[0]?.laudo?.suspeita_obito?.result ? { background: '#FF3D60' } : { background: '#1CBB8C' }}>
																				{this.state.laudo?.fc_laudo?.pretendentes?.[0]?.laudo?.suspeita_obito?.result ? 'Sim' : 'Não'}
																			</div>
																		</div>
																	</div>
																</div>

																<div className='body-2'>
																	<div className='boxInformation'>
																		<p>Sugestão da análise</p>
																		<b>{this.htmlSugestao(this.state.laudo?.fc_laudo?.parecer?.locacao?.parecer_inquilinos?.aprovados) ?? 'Aguardando'}</b>
																	</div>
																</div>
															</div>
														</div>
														:
														<div className='no-images-box'>
															Análise financeira pendente
														</div>
													}
												</Row>
											</BoxInfo>

											{this.state.laudo?.fc_laudo ?
												<BoxInfo noHeader noTag width='80%' marginLeft='20%'>
													<Row className='content-box' style={this.state.laudo?.fc_laudo ? {} : { justifyContent: 'center', padding: '32px 0' }}>
														<div className='analiseReview' style={{ width: '100%' }}>
															<div className='body' style={{ width: '100%', flexDirection: 'column', gap: '0px' }}>
																<header style={{ alignItems: 'center', marginBottom: '16px' }}>
																	DETALHES DO VALOR
																</header>

																<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', margin: '8px 0px' }}>
																	<p style={{ color: 'var(--Cinza-800, #343A40)', fontFamily: 'Inter', fontSize: '13px', fontStyle: 'normal', fontWeight: 400, lineHeight: 'normal', marginBottom: '0px' }}>Valor total do aluguel</p>
																	<p style={{ color: 'var(--Cinza-800, #343A40)', fontFamily: 'Inter', fontSize: '13.008px', fontStyle: 'normal', fontWeight: 600, lineHeight: 'normal', marginBottom: '0px' }}>{formatBRL(parseFloat(this.state.laudo?.fc_laudo?.locacao?.aluguel) + parseFloat(this.state.laudo?.fc_laudo?.locacao?.iptu ?? 0) + parseFloat(this.state.laudo?.fc_laudo?.locacao?.condominio ?? 0))}</p>
																</div>

																<hr style={{ backgroundColor: ' #DADBDD', border: 'none', height: '1px', margin: 0 }} />

																<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', margin: '8px 0px' }}>
																	<p style={{ color: 'var(--Cinza-800, #343A40)', fontFamily: 'Inter', fontSize: '13px', fontStyle: 'normal', fontWeight: 400, lineHeight: 'normal', marginBottom: '0px' }}>Renda necessária</p>
																	<p style={{ color: 'var(--Cinza-800, #343A40)', fontFamily: 'Inter', fontSize: '13.008px', fontStyle: 'normal', fontWeight: 600, lineHeight: 'normal', marginBottom: '0px' }}>{formatBRL(this.getIncomeXRent(this.state?.laudo))}</p>
																</div>

																<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', margin: '8px 0px' }}>
																	<p style={{ color: 'var(--Cinza-800, #343A40)', fontFamily: 'Inter', fontSize: '13px', fontStyle: 'normal', fontWeight: 400, lineHeight: 'normal', marginBottom: '0px' }}>Renda total</p>
																	<p style={{ color: this.getDiffValues(this.state?.laudo) ? '#1CBB8C' : '#FF3D60', fontFamily: 'Inter', fontSize: '13.008px', fontStyle: 'normal', fontWeight: 600, lineHeight: 'normal', marginBottom: '0px' }}>{formatBRL(this.getTotalIncome(this.state?.laudo))}</p>
																</div>

																<hr style={{ backgroundColor: ' #DADBDD', border: 'none', height: '1px', margin: 0 }} />

																<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', margin: '8px 0px' }}>
																	<p style={{ color: this.valorPendenteXAluguel() ? ' #FF3D60' : '#1CBB8C', fontFamily: 'Inter', fontSize: '13px', fontStyle: 'normal', fontWeight: 400, lineHeight: 'normal', marginBottom: '0px' }}>Total de pendências financeiras</p>
																	<p style={{ color: this.valorPendenteXAluguel() ? ' #FF3D60' : '#1CBB8C', fontFamily: 'Inter', fontSize: '13.008px', fontStyle: 'normal', fontWeight: 600, lineHeight: 'normal', marginBottom: '0px' }}>{formatBRL(this.getTotalPendencia(this.state?.laudo))}</p>
																</div>

																<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', margin: '8px 0px' }}>
																	<p style={{ color: this.valorPendenteXAluguel() ? ' #FF3D60' : '#1CBB8C', fontFamily: 'Inter', fontSize: '13px', fontStyle: 'normal', fontWeight: 400, lineHeight: 'normal', marginBottom: '0px' }}>Total de títulos protestados</p>
																	<p style={{ color: this.valorPendenteXAluguel() ? ' #FF3D60' : '#1CBB8C', fontFamily: 'Inter', fontSize: '13.008px', fontStyle: 'normal', fontWeight: 600, lineHeight: 'normal', marginBottom: '0px' }}>{formatBRL(this.getTotalProtestos(this.state?.laudo))}</p>
																</div>
															</div>
														</div>

													</Row>
												</BoxInfo>
												: null}
										</div>

										<div className='header'>
											<div className='left'>
												<h3>Constituintes</h3>
											</div>

											<div className='right'>
												<Link className='btnSecundary' to={`/negotiation/edit/${this.props.match.params.id}?typeNegociacao=${tipoNegociacao}`}>
													EDITAR  <FontAwesomeIcon icon={faPen} />
												</Link>
											</div>
										</div>

										{this.state.laudo?.fc_laudo.pretendentes?.length > 0 && this.state?.laudo?.fc_laudo?.pretendentes?.map((obj, i) => <UserBox usuario={obj} />)}
									</TabPane>

									<TabPane tabId="2" role="tabpanel">
										<BetterCircularProgress loading={this.state.loading}>
											<div className='datatable-box' style={{ marginTop: 32 }}>
												<DataTableAnaliseNegotiation ref={this.DataTableRef} fetchEvent={this.fetchByFilter} crudUrl={"/negotiation/laudo"} idRow={`proposition_id`} activeTab={this.state.activeTab} />
											</div>
										</BetterCircularProgress>
									</TabPane>
								</TabContent>
							</BetterCircularProgress>
						</div>
					</Container>
				</div>

				<Modal dialogClassName="modalCancelarNegociacao" centered show={this.state?.showModalAprovarHumanizado} onHide={this.handleClose}>
					<Modal.Header closeButton={false}>
						<Modal.Title>Aprovação humanizado</Modal.Title>

						<button onClick={this.handleClose} style={{ backgroundColor: 'transparent', color: '#8590A5', display: 'flex', alignItems: 'center', border: 'none', fontWeight: '900', fontSize: '18px' }}>
							X
						</button>
					</Modal.Header>
					<Modal.Body>
						<h3>Você tem certeza que deseja aprovar?</h3>

						<p>Ao continuar, o responsável pelo imóvel e todos os inquilinos serão notificados que foram aprovados.</p>

						<Label>Motivo da aprovação humanizada</Label>

						<Input placeholder='Detalhe o motivo da aprovação' value={this.state.humanized_justification} onChange={(e) => this.setState({ humanized_justification: e.target.value })} />

						<p style={{ marginTop: 24, marginBottom: 0 }}>Ao continuar, o responsável pelo imóvel e todos os inquilinos serão notificados que foram aprovados.</p>
					</Modal.Body>
					<Modal.Footer>
						<button className='btnSecundary' onClick={this.handleClose}>
							<FontAwesomeIcon icon={faArrowLeft} /> VOLTAR
						</button>

						<button className='buttonPrimary' disabled={this.state.humanized_justification.trim() == ''} onClick={this.handleApproveModalAprovarHumanizado}>
							APROVAR <FontAwesomeIcon icon={faCheck} />
						</button>
					</Modal.Footer>
				</Modal>
			</BetterCircularProgress>
		);
	}
}

export default NegotiationLaudo;