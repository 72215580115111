/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
import { get as getUser } from '../../../crud/users.crud';
import { Link } from "react-router-dom";
import { getClosureTermFile, get as getTermination, getTerminationUsers } from '../../../crud/termination.crud';
import { Input, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faClockRotateLeft, faFileSignature, faFileCirclePlus } from "@fortawesome/pro-regular-svg-icons";
import DataTableUsersProposition from "./DataTableUsersProposition";
import { formatBRL } from "../../../helpers/Utils";
import BetterCircularProgress from "../../BetterCircularProgress";
import { Constants } from "../../../helpers/Constants";
import { getUsersWithLaudoStatus } from "../../../crud/propositions.crud";
import { getContractFile, getContractualEdit } from "../../../crud/contracts.crud";
import { getFriendlyAgreementUsers } from "../../../crud/billings.crud";

export default class DataTableContractSignature extends Component {
	constructor() {
		super();

		this.state = {
			dataTable: [],
			loading: false,
			sortField: "",
			sortOrder: 1,
			checkedContract: {},
			checkedContractIndex: 1,
			contract_users: [],
			showModalHistorico: true,
		};
	}

	componentDidMount() {
		if (!this.props.modal) {
			this.fetchData();
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters !== this.props.filters) this.fetchData();
	}

	async processMainContract(contract, users) {
		const numeroDeUsuarios = users.filter(u => u.user_type === 'tenant').length + 1;
		const numeroDeAssinados = users.filter(u => u.signed).length;

		const participantesCompleto = [];
		for (const usuario of users) {
			const userResponse = await getUser(usuario.user_id).catch(() => null);
			if (userResponse?.data?.data) {
				participantesCompleto.push(userResponse.data.data);
			}
		}

		return {
			...contract,
			numeroDeUsuarios,
			numeroDeAssinados,
			usuarios: participantesCompleto,
			type: 'Contrato de locação',
			status: contract.proposition.status,
			index: 1
		};
	}

	async processTermination(termination, baseContract, index) {
		if (termination?.type !== 'TERMINATION_TERM') return null;

		const terminationResponse = await getTermination(termination.id).catch(() => null);
		if (!terminationResponse?.data) return null;

		const terminationObj = {
			...baseContract,
			...terminationResponse.data,
			type: 'Termo de recisão',
			status: terminationResponse.data.type,
			index,
			fileLink: `${Constants.baseUrl}/terminations/file/${termination.id}`
		};

		const usersResponse = await getTerminationUsers({
			termination_id: termination?.id,
			contract_id: baseContract?.id
		}).catch(() => null);

		if (usersResponse?.data) {
			const { data } = usersResponse.data;
			const numeroDeUsuariosTermination = data.length;
			const numeroDeAssinadosTermination = data.filter(u => u.signed).length;

			const responsableType = data.find(u => u.user_type === 'admin') ? 'admin' : 'landlord';
			let finalNumeroAssinados = numeroDeAssinadosTermination;

			if (responsableType === 'admin' && data.find(u => u.user_type === 'landlord')) {
				finalNumeroAssinados += 1;
			}

			const testemunhas = data
				.filter(user => user.user_type === 'witness')
				.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

			Object.assign(terminationObj, {
				numeroDeUsuarios: numeroDeUsuariosTermination,
				numeroDeAssinados: finalNumeroAssinados,
				signed: numeroDeUsuariosTermination === finalNumeroAssinados,
				terminationUsers: data,
				testemunhas
			});

			if (data.filter(obj =>
				obj.user_type === responsableType ||
				obj.user_type === 'tenant' ||
				obj.user_type === 'witness'
			).every(obj => obj.signed)) {
				terminationObj.fileLink = `${Constants.baseUrl}/terminations/file/${termination.id}?type=sign`;
			}
		}

		return terminationObj;
	}

	async processContractualEdit(contractualEdit, baseContract, index) {
		const editResponse = await getContractualEdit(contractualEdit.id).catch(() => null);
		if (!editResponse?.data) return null;

		const { data } = editResponse;
		const testemunhas = data.contractual_edit_users
			.filter(user => user.user_type === 'witness')
			.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

		const numeroDeUsuariosEdicao = data.contractual_edit_users.length;
		const numeroDeAssinadosEdicao = data.contractual_edit_users.filter(u => u.signed).length;
		const responsableType = data.contractual_edit_users.find(u => u.user_type === 'admin') ? 'admin' : 'landlord';

		let finalNumeroAssinados = numeroDeAssinadosEdicao;
		if (responsableType === 'admin' && data.contractual_edit_users.find(u => u.user_type === 'landlord')) {
			finalNumeroAssinados += 1;
		}

		const editObj = {
			...baseContract,
			...data,
			type: 'Edição contratual',
			status: contractualEdit?.status,
			index,
			fileLink: `${Constants.baseUrl}/contractual-edits/file/${contractualEdit.id}`,
			testemunhas,
			numeroDeUsuarios: numeroDeUsuariosEdicao,
			numeroDeAssinados: finalNumeroAssinados,
			signed: numeroDeUsuariosEdicao === finalNumeroAssinados
		};

		if (data.contractual_edit_users.filter(obj =>
			obj.user_type === responsableType ||
			obj.user_type === 'tenant' ||
			obj.user_type === 'witness'
		).every(obj => obj.signed)) {
			editObj.fileLink = `${Constants.baseUrl}/contractual-edits/file/${contractualEdit.id}?type=sign`;
		}

		return editObj;
	}

	processLetterAttorney(baseContract, letters_attorney, index) {
		if (letters_attorney.length === 0) return null;

		// const criado = Boolean(letters_attorney.find(({ description }) =>
		// 	description.includes('Data limite para assinatura')
		// ));

		const assinado = Boolean(letters_attorney.find(({ description }) =>
			description.includes('assinou')
		));

		return {
			...baseContract,
			type: 'Procuração',
			index,
			fileLink: assinado
				? `${Constants.baseUrl}/contracts/letter-of-attorney-file/${baseContract.id}?type=sign`
				: `${Constants.baseUrl}/contracts/letter-of-attorney-template/${baseContract.id}`,
			usuarios: [baseContract.usuarios.find((usuario) => usuario.id == baseContract.landlord_id)],
			status: assinado ? 'SIGNED' : 'AWAITING_SIGN',
			signed: assinado,
			numeroDeAssinados: assinado ? '1' : '0',
			numeroDeUsuarios: '1'
		};
	}

	async processFriendlyAgreement(friendlyAgreement, baseContract, index) {
		if (!friendlyAgreement) return null;

		// Verifica se friendlyAgreement é um array e tem elementos
		const agreement = Array.isArray(friendlyAgreement) ? friendlyAgreement[0] : friendlyAgreement;

		if (!agreement?.id) return null;

		const users = await getFriendlyAgreementUsers({ friendly_agreement_id: agreement.id }).catch(() => null);

		if (users?.data?.data) {
			const { data } = users.data;
			const dataWithoutWitness = data.filter(u => u.user_type !== 'witness' && u.user_type !== 'legal_representative');

			const numeroDeUsuarios = dataWithoutWitness.length || 0;
			const numeroDeAssinados = dataWithoutWitness.filter(u => u.signed).length || 0;
			const responsableType = dataWithoutWitness.find(u => u.user_type === 'admin') ? 'admin' : 'landlord';

			let finalNumeroAssinados = numeroDeAssinados;

			// Determina o status com base nas assinaturas
			const isSigned = numeroDeUsuarios === finalNumeroAssinados;
			const statusBasedOnSignatures = agreement.deleted_at || agreement.status == "expired" ? 'EXPIRED' : isSigned ? 'SIGNED' : 'AWAITING_SIGN';

			console.log(agreement.id, 'agreement.id');

			const agreementObj = {
				...baseContract,
				type: 'Confissão de dívida',
				friendly_agreement_id: agreement.id,
				status: statusBasedOnSignatures,
				index,
				fileLink: `${Constants.baseUrl}/friendly-agreements/file/${agreement.id}`,
				numeroDeUsuarios,
				numeroDeAssinados: finalNumeroAssinados,
				signed: isSigned,
				contract_users: data,
				friendly_agreement_users: dataWithoutWitness
			};

			if (data.filter(obj => obj.user_type === responsableType || obj.user_type === 'tenant').every(obj => obj.signed)) {
				agreementObj.fileLink = `${Constants.baseUrl}/friendly-agreements/file/${agreement.id}?type=sign`;
			}

			return agreementObj;
		}

		return null;
	}

	async fetchData() {
		try {
			const res = await this.props.fetchEvent();
			if (!res?.data) return;

			const contractAssinaturas = [];
			const contract = res.data.data.entity;
			const users = this.props?.state?.contract_users ?? [];

			// Processa contrato principal
			const mainContract = await this.processMainContract(contract, users);
			contractAssinaturas.push(mainContract);

			// Processa termos de rescisão
			for (const termination of this.props.terminations) {
				const terminationObj = await this.processTermination(
					termination,
					mainContract,
					contractAssinaturas.length + 1
				);
				if (terminationObj) contractAssinaturas.push(terminationObj);
			}

			// Processa edições contratuais
			for (const contractualEdit of this.props?.contractualEdits[0]) {
				const editObj = await this.processContractualEdit(
					contractualEdit,
					mainContract,
					contractAssinaturas.length + 1
				);
				if (editObj) contractAssinaturas.push(editObj);
			}

			// Processa acordo amigável
			if (this.props.friendlyAgreement) {
				for (const friendlyAgreement of this.props.friendlyAgreement) {
					const agreementObj = await this.processFriendlyAgreement(friendlyAgreement, mainContract, contractAssinaturas.length + 1);
					if (agreementObj) contractAssinaturas.push(agreementObj);
				}
			}

			// Processa procuração
			const letterAttorneyObj = this.processLetterAttorney(
				mainContract,
				this.props.letters_attorney,
				contractAssinaturas.length + 1
			);

			if (letterAttorneyObj) contractAssinaturas.push(letterAttorneyObj);

			this.setState({
				...this.props.state,
				dataTable: contractAssinaturas,
				checkedContract: contractAssinaturas[0]
			});
		} catch (e) {
			console.error(e);
		} finally {
			this.setState({ loading: false });
		}
	}

	sortTable = async (e) => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder, loading: true }, () => {
			this.fetchData();
		});
	};

	fetchUsersProposition = async () => {
		return getUsersWithLaudoStatus(this.props.match.params.id).catch(({ message }) => { })
	};

	handleClose = () => {
		this.setState({ showModalHistorico: false, showModalVerContrato: false, showModalCancelarNegociacao: false, showModalCriarContrato: false });
	}

	getUserName = (obj) => {
		if (obj?.usuarios?.length > 0) {
			return (
				<div style={{ textAlign: 'center' }}>
					{obj?.usuarios?.map((usuario, index) => (
						<Link to={`/users/show/${usuario?.id}`}>{usuario?.name}{obj?.usuarios?.length == index + 1 ? '' : ', '}</Link>
					))}
				</div>
			)
		}
	};

	handleSeeClosureTerm = async () => {
		const id = this.state?.terminations[0].id;

		try {
			await getClosureTermFile(id).then((data) => { window.open(data?.request?.responseURL, '_blank') });
		} catch (err) {
			this.setState({ errors: ['Houve um erro ao buscar o termo de encerramento, entre em contato com o suporte.'] });
		}
	}

	getStatusProposition = (obj) => {
		let status = '';
		let backgroundColor = '#4AA3FF'

		switch (obj?.status) {
			case 'SIGNED':
				status = 'Assinado'
				backgroundColor = '#1CBB8C';
				break;
			case 'CANCELED':
				status = 'Cancelado';
				backgroundColor = '#FF3D60';
				break;
			case 'EXPIRED':
				status = 'Expirado';
				backgroundColor = '#FF3D60';
				break;
			case 'Quebra de contrato':
				status = 'Quebra de contrato';
				backgroundColor = '#FF3D60';
				break;
			case 'TERMINATION_TERM':
				if (obj?.deleted_at) {
					status = 'Cancelado';
					backgroundColor = '#FF3D60';
				} else if (!obj?.signed) {
					status = 'Aguardando';
					backgroundColor = '#EFA200';
				} else if (obj?.signed) {
					status = 'Assinado';
					backgroundColor = '#1CBB8C';
				} else {
					status = 'Comum acordo';
					backgroundColor = '#1CBB8C';
				}
				break;
			case 'Comum acordo':
				status = 'Comum acordo';
				backgroundColor = '#1CBB8C';
				break;
			case 'Tempo previsto por contrato':
				status = 'Tempo previsto por contrato';
				backgroundColor = '#4AA3FF';
				break;
			case 'TERMINATION_SCHEDULED':
				status = 'Rescisão agendada';
				backgroundColor = '#4AA3FF';
				break;
			case 'SEM_MOTIVO':
				status = 'Rescindido pelo Admin';
				backgroundColor = '#4AA3FF';
				break;
			case 'AWAITING_COURT_APPROVAL':
				status = 'Aguardando jurídico';
				backgroundColor = '#4AA3FF';
				break;
			case 'EXTRAJUDICIAL':
				status = 'Aguardando jurídico';
				backgroundColor = '#4AA3FF';
				break;
			case 'PLACEHOLDER':
				status = 'Chat iniciado';
				backgroundColor = '#4AA3FF';
				break;
			case 'DRAFT':
				status = 'Criada integrações';
				backgroundColor = '#5664D2';
				break;
			case 'AWAITING_TENANT_LOCK':
				status = 'Aguardando trava inquilino';
				backgroundColor = '#5664D2';
				break;
			case 'AWAITING_CREDIT_REVIEW':
				status = 'Aguardando análise financeira';
				backgroundColor = '#EFA200';
				break;
			case 'APPROVED':
				status = 'Análise financeira aprovada';
				backgroundColor = '#1CBB8C';
				break;
			case 'FIRST_PROPOSAL':
				status = 'Criada Admin';
				backgroundColor = '#5664D2';
				break;
			case 'CREDIT_REFUSED':
				status = 'Análise financeira reprovada';
				backgroundColor = '#FF3D60';
				break;
			case 'AWAITING_SIGN':
				status = 'Aguardando assinaturas';
				backgroundColor = '#EFA200';
				break;
			case 'FINALIZED':
				status = 'Assinado';
				backgroundColor = '#1CBB8C';
				break;
			case 'FINISHED':
				status = 'Contrato encerrado';
				backgroundColor = '#4AA3FF';
				break;
			default:
				status = obj?.status;
				break;
		}
		return (
			<div className="tag" style={{ textAlign: "center", backgroundColor }}>
				{status}
			</div>
		);
	}

	columnOpcoesCrud = (rowData) => {
		const openModal = () => {
			this.props.handleShowVerContrato();
		}

		return (
			<a style={{ color: '#343a40', marginLeft: 'calc(50% - 4px)', cursor: 'pointer' }} onClick={openModal} className='showButton'>
				<i className='fa fa-chevron-right' />
			</a>
		);
	};

	checkContract = (obj) => {
		const markCheck = () => {
			this.setState({ checkedContractIndex: obj.index, checkedContract: obj }, () => {
				if (this.props.onContractSelected) {
					console.log(obj, 'obj');
					this.props.onContractSelected(obj);
				}
			});
		}

		return (
			<div onChange={markCheck} style={{ paddingLeft: 20, paddingBottom: 20 }}>
				<Input type="radio" style={{ cursor: 'pointer' }} value={'checkedContractIndex'} onChange={markCheck} color="primary" checked={[obj.index].includes(this.state.checkedContractIndex)} />
			</div>
		)
	}

	getWarranty = (key) => {
		switch (key) {
			case 'FREE':
				return 'Grátis';

			case 'WARRANTY':
				return 'Pagamento';


			case 'WARRANTY_GUARANTEE_DAMAGE':
				return 'Completa';

			default:
				return 'Não identificado';
		}
	};

	getStatusAssinatura = (contrato) => {
		let text = '';
		let backgroundColor = '#1CBB8C'

		if (contrato.numeroDeUsuarios == contrato.numeroDeAssinados) {
			text = 'Assinado';
		} else if (contrato?.status === 'CANCELED') {
			text = 'Cancelado';
			backgroundColor = '#FF3D60';
		} else if (contrato?.status === 'EXPIRED') {
			text = 'Expirado';
			backgroundColor = '#FF3D60';
		} else {
			text = 'Aguardando assinatura'
			backgroundColor = '#EFA200'
		}

		return (
			<div className="tag" style={{ textAlign: "center", backgroundColor }}>
				{text}
			</div>
		)
	}

	calcContractPercentage = (contract) => {
		const startDate = moment(contract?.details?.end_date, 'MM/DD/YYYY')
		const today = moment()

		var months = startDate.diff(today, 'months');
		today.add(months, 'months');

		var days = startDate.diff(today, 'days');

		const {
			//  start_date, 
			end_date,
			duration
		} = contract.details;

		const hoje = moment(new Date());
		// const inicio = moment(start_date, 'MM/DD/YYYY');
		const fim = moment(end_date, 'MM/DD/YYYY');

		let mesesFaltando = moment(fim).diff(hoje, 'M');
		let porcentagem = Math.round((mesesFaltando / duration) * 100)

		if (Math.sign(mesesFaltando) < 0) {
			mesesFaltando = 0;
		}

		if (porcentagem < 0) {
			porcentagem = 100;
		}


		return (
			<div className="box-purple">
				{months}m {days}d {porcentagem}%
			</div>
		)
	}

	getLastUpdate = (obj) => {
		if (obj?.contract_users) {
			let lastUpdate = obj.updated_at;
			for (const u of obj.contract_users) {
				if (moment(u.signed_date).isAfter(moment(lastUpdate))) {
					lastUpdate = u.signed_date
				}
			}

			return moment(lastUpdate).format('DD/MM/YYYY HH:mm:ss')
		} else {
			return moment(obj.updated_at).format('DD/MM/YYYY HH:mm:ss')
		}
	}

	render() {
		const contract = this.state?.dataTable?.[0];
		// const contratoSelecionado = this.state?.checkedContract;

		const BoxInfo = ({ title, href, children, classNameBody, preenchido, noTag = false, indicadorNumero, comissionamento, cardComissionamento, noHeader, noEdit, center = false }) => {
			return (
				<div className='boxInfoUser' style={center ? { display: 'flex', justifyContent: 'center', alignItems: 'center' } : {}}>
					{noHeader ? null : (
						<div className='headerBoxInfoUser'>
							<div className='title-tag'>
								<h4>{title}</h4>

								{indicadorNumero && (
									<div className='indicadorUsuarios'>
										{(indicadorNumero) ?? 0}
									</div>
								)}

								{!cardComissionamento ? null :
									comissionamento ? (
										<div className='tag'>
											Sim
										</div>
									) :
										<div className='tag' style={{ backgroundColor: '#FCB92C' }}>
											Não
										</div>
								}

								{noTag ? null : preenchido ?
									<div className='tag'>
										Preenchido
									</div>
									:
									<div className='tag' style={{ backgroundColor: '#FCB92C' }}>
										Pendente
									</div>
								}
							</div>

							{noEdit ? null :
								<a href={href} className='btnSecundary'>
									EDITAR
									<FontAwesomeIcon icon={faPen} />
								</a>
							}
						</div>
					)}

					<div className={`${classNameBody} boxInfoBody`}>
						{children}
					</div>
				</div>
			)
		};

		const usuariosProposition = [
			() => <Column style={{ width: 113, height: 91 }} sortable field="friendly_code" header="Sequencial" />,
			() => <Column style={{ width: 132 }} sortable field="status" body={(obj) => this.getStatusProposition(obj)} header="Status" />,
			() => <Column style={{ width: 218 }} sortable field="type" header="Tipo" />,
			() => <Column style={{ width: 218 }} sortable field="user.name" body={(obj) => this.getUserName(obj)} header="Envolvidos" />,
			() => <Column style={{ width: 108 }} sortable field="created_at" header="Criado em" body={(obj) => moment(obj.created_at).format('DD/MM/YYYY HH:mm:ss')} />,
			() => <Column style={{ width: 131 }} sortable field="updated_at" header="Atualizado em" body={(obj) => obj?.type === 'Termo de encerramento' ? '--' : (
				this.getLastUpdate(obj)
			)} />,
			() => <Column style={{ width: 118 }} sortable field="signatures.signed" header="Assinaturas" body={(obj) => (<div style={obj?.numeroDeAssinados == 0 ? { color: '#FF3D60' } : obj?.numeroDeAssinados == obj?.numeroDeUsuarios ? { color: '#1CBB8C' } : { color: '#EFA200' }}>{obj.type === 'Termo de encerramento' ? `--` : `${obj?.numeroDeAssinados} de ${obj?.numeroDeUsuarios}`}</div>)} />,
		]

		const usuariosPropositionModal = [
			() => <Column style={{ width: 132 }} field="proposition.status" body={(obj) => this.getStatusProposition(obj)} header="Status" />,
			() => <Column style={{ width: 108 }} sortable field="created_at" header="Criado em" body={(obj, i) => moment(obj.created_at).format('DD/MM/YYYY HH:mm:ss')} />,
			() => <Column style={{ width: 131 }} sortable field="updated_at" header="Atualizado em" body={(obj, i) => moment(obj.updated_at).format('DD/MM/YYYY HH:mm:ss')} />,
			() => <Column style={{ width: 218 }} sortable field="user.name" body={(obj, i) => this.getUserName(obj)} header="Envolvidos" />,
			() => <Column header="" body={this.columnOpcoesCrud} style={{ width: '34px', padding: 0 }} />
		]

		const ContractInfo = () => {
			const contratoSelecionado = this.state?.checkedContract;

			return (
				<BoxInfo noHeader noTag>
					<Row className='content-box' style={{ padding: '24px' }}>
						<div className='d-flex' style={{ alignItems: 'center', gap: 16, flex: 1 }}>
							{this.getStatusAssinatura(contratoSelecionado)}

							<div>
								<b>{contract?.friendly_code} {contratoSelecionado?.type === 'Contrato de locação' ? `Contrato de locação de imóvel residencial` : contratoSelecionado.type}</b>

								<div className='d-flex' style={{ gap: 4 }}>
									<div className='tag' style={{ backgroundColor: '#DADBDD', color: '#505D69', marginTop: 0 }}>
										{contract?.friendly_code}
									</div>

									<span style={{ alignSelf: 'center' }}>ID {contratoSelecionado?.id}</span>
								</div>
							</div>
						</div>

						<div className='d-flex' style={{ gap: 8 }}>
							{(contratoSelecionado?.type == 'Termo de recisão' || contratoSelecionado?.type == 'Edição contratual') && (
								<>
									<button className='btnSecundary' type='button' onClick={() => window.open(contratoSelecionado?.fileLink, '_blank').focus()}>
										VER TERMO
										<FontAwesomeIcon icon={faFileSignature} />
									</button>
								</>
							)}

							{(contratoSelecionado?.type == 'Edição contratual' && contratoSelecionado.status === 'AWAITING_SIGN') && (
								<>
									<button className='btnRed ml-auto' type='button' onClick={async () => await this.props.handleOpenCancelCheckEdition(contratoSelecionado)}>
										CANCELAR EDIÇÃO
										<FontAwesomeIcon icon={faFileCirclePlus} />
									</button>
								</>
							)}

							{['Contrato de locação', 'Procuração'].includes(contratoSelecionado?.type) && (
								<>
									<button className='btnSecundary' type='button' onClick={() => this.props.handleOpenModalHistorico(contratoSelecionado)}>
										VER HISTÓRICO
										<FontAwesomeIcon icon={faClockRotateLeft} />
									</button>

									<button className='btnSecundary' type='button' onClick={() =>
										window.open((contratoSelecionado?.type == 'Procuração' ? contratoSelecionado.fileLink : this.state.contract_link_sign), '_blank').focus()
									}>
										VER CONTRATO
										<FontAwesomeIcon icon={faFileSignature} />
									</button>

									{(contratoSelecionado?.type == 'Confissão de dívida' && contratoSelecionado.status != "EXPIRED") && (
										<Link className='btnRed ml-auto' onClick={() => this.props.handleOpenModalCancelFriendlyAgreement(contratoSelecionado)}>
											CANCELAR ACORDO
											<FontAwesomeIcon icon={faFileCirclePlus} />
										</Link>
									)}
								</>
							)}

							{['Confissão de dívida'].includes(contratoSelecionado?.type) && (
								<>
									<button className='btnSecundary' type='button' onClick={() => this.props.handleOpenModalHistorico(contratoSelecionado)}>
										VER HISTÓRICO
										<FontAwesomeIcon icon={faClockRotateLeft} />
									</button>

									<button className='btnSecundary' type='button' onClick={async () =>
										await getContractFile(contratoSelecionado?.friendly_agreement_id, contratoSelecionado?.signed ? 'sign' : null).then(res => {
											console.log(res); window.open(res?.request?.responseURL, '_blank');
										})
									}>
										VER CONTRATO
										<FontAwesomeIcon icon={faFileSignature} />
									</button>

									{contratoSelecionado.status != "EXPIRED" && (
										<Link className='btnRed ml-auto' onClick={() => this.props.handleOpenModalCancelFriendlyAgreement(contratoSelecionado)}>
											CANCELAR ACORDO
											<FontAwesomeIcon icon={faFileCirclePlus} />
										</Link>
									)}
								</>
							)}

							{contratoSelecionado?.type == 'Termo de encerramento' && (
								<>
									<button className='btnSecundary' type='button' onClick={() => this.props.handleOpenModalHistorico(contratoSelecionado)}>
										VER HISTÓRICO
										<FontAwesomeIcon icon={faClockRotateLeft} />
									</button>

									<button className='btnSecundary' type='button' onClick={async (e) => {
										e.preventDefault();
										await this.handleSeeClosureTerm();
									}}>
										VER CONTRATO
										<FontAwesomeIcon icon={faFileSignature} />
									</button>
								</>
							)}
						</div>
					</Row>

					<hr className='lineBox' />

					<div className='bodyAssets d-flex' style={{ gap: 24 }}>
						<div className='boxInformation'>
							<p>Criada em</p>
							<b>
								{['Procuração'].includes(contratoSelecionado?.type) ?
									moment(contratoSelecionado?.created_at).format('DD/MM/YY HH:mm:ss')
									:
									moment(contratoSelecionado?.created_at).add(3, 'hours').format('DD/MM/YY HH:mm:ss')
								}
							</b>
						</div>

						{contratoSelecionado?.signed && contratoSelecionado?.type !== 'Termo de encerramento' && (
							<div className='boxInformation'>
								<p>Assinado em</p>
								<b>{moment(contratoSelecionado?.signed_date ?? contratoSelecionado?.updated_at).format('DD/MM/YY HH:mm:ss')}</b>
							</div>
						)}

						{contratoSelecionado?.type !== 'Procuração' && (
							<div className='boxInformation'>
								<p>Entrada no imóvel</p>
								<b>{moment(contract?.details?.start_date, 'MM/DD/YYYY').format('DD/MM/YY')}</b>
							</div>
						)}

						{['Contrato de locação', 'Procuração'].includes(contratoSelecionado?.type) && (
							<>
								<div className='boxInformation'>
									<p style={{ marginBottom: '10px' }}>Prazo de contrato</p>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<b>{contract?.details?.duration} meses</b>
										<>
											{this.calcContractPercentage(contract)}
										</>
									</div>
								</div>

								<div className='boxInformation'>
									<p style={{ marginBottom: '10px' }}>Garantia</p>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<b>{this.getWarranty(contract?.plan?.name, contract?.plan)}</b>
										<div className="box-purple">
											{contract.plan.percentage}%
										</div>
									</div>
								</div>

								<div className='boxInformation'>
									<p>Valor total</p>
									<b>{formatBRL(contract?.details?.values?.total || 0)}</b>
								</div>
							</>
						)}

						{contratoSelecionado?.type == 'Termo de recisão' && (
							<>
								<div className='boxInformation'>
									<p>Valor base</p>
									<b>{formatBRL(contract?.details?.values?.total || 0)}</b>
								</div>

								<div className='boxInformation'>
									<p>Valor próx. aluguel</p>
									<b>{formatBRL(contract?.details?.values?.total || 0)}</b>
								</div>

								<div className='boxInformation'>
									<p>Data de vencimento</p>
									<b>{moment(contract?.details?.start_date, 'MM/DD/YYYY').format('DD/MM/YY HH:mm:ss')}</b>
								</div>
							</>
						)}

						{contratoSelecionado?.type == 'Termo de encerramento' && (
							<>
								<div className='boxInformation'>
									<p>Prazo de contrato</p>
									<b>{contract?.details?.duration} meses</b>
								</div>

								<div className='boxInformation'>
									<p>Garantia</p>
									<b>{this.getWarranty(contract?.plan?.name, contract?.plan)}</b>
								</div>

								<div className='boxInformation'>
									<p>Valor total</p>
									<b>{formatBRL(contract?.details?.values?.total || 0)}</b>
								</div>
							</>
						)}
					</div>

					{contratoSelecionado?.type !== 'Termo de encerramento' && (
						<>
							<h4 className="negotiation-subtitle-1">Assinaturas</h4>

							<BetterCircularProgress loading={this.state.loading}>
								<DataTableUsersProposition state={this.state} usuariosSignature contratoSelecionado={contratoSelecionado} contract_id={contract?.id} fetchEvent={this.fetchUsersProposition} />
							</BetterCircularProgress>
						</>
					)}
				</BoxInfo >
			)
		}

		return (
			<>
				<DataTable ref={(el) => this.dt = el} style={{ marginBottom: 16 }} value={(this.props?.modal && this.props.state?.contratoSelecionado?.id) ? [this.props?.state?.contratoSelecionado] : this.state.dataTable} responsive={true} lazy={true} sortField={this.state.sortField} sortOrder={this.state.sortOrder}
					loading={this.state.loading} loadingIcon={`fas fa-sync fa-spin`} emptyMessage="Nenhum registro encontrado!" onSort={(e) => this.sortTable(e)}>
					{!this.props.modal && (
						<Column style={{ width: 34 }} header="" body={(obj) => this.checkContract(obj)} />
					)}

					{this.props.modal ?
						usuariosPropositionModal.map((coluna) => coluna())
						:
						usuariosProposition.map((coluna) => coluna())
					}
				</DataTable>

				{
					!this.props.modal && (
						<ContractInfo />
					)
				}
			</>
		);
	}
}
