import React, { Component } from 'react';
import { Container, Card, CardBody, Col, FormGroup, Label, Input, } from "reactstrap";
import BetterCircularProgress from '../../components/BetterCircularProgress';
import { getById } from '../../crud/publicLinks.crud';
import { Link } from "react-router-dom";
import Breadcrumbs from '../../components/Breadcrumb';

class PublicLinksShow extends Component {

    state = {
        loading: false,
        data: null,
    }

    componentDidMount = () => {
        this.setState({ loading: true });

        getById(this.props.match.params.id).catch(err => {
            alert('An error occurred, please contact the support.');
        }).then(async res => {
            if (res?.status == 200) {
                if (res.data) {
                    this.setState({ data: res.data.data });
                }
            }

            this.setState({ loading: false });
        });
    };

    render() {
        return (
            <React.Fragment>
                <BetterCircularProgress loading={this.state.loading}>
                    <div className="page-content container-page">
                        <Container fluid>
                            <Breadcrumbs title="Visualizar link" breadcrumbItems={[]} />

                            <Card>
                                <CardBody>
                                    <div className="row">
                                        <Col md={6}>
                                            <FormGroup row>
                                                <Label className="col-md-4 col-form-label">ID</Label>
                                                <Col md={8} className="container-center-input">
                                                    <Input className="form-control" type="text" value={this.state.data?.id} disabled />
                                                </Col>
                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>
                                            <FormGroup row>
                                                <Label className="col-md-4 col-form-label">Host</Label>
                                                <Col md={8} className="container-center-input">
                                                    <Input className="form-control" type="text" value={this.state.data?.host ? this.state.data?.host : 'N/A'} disabled />
                                                </Col>
                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>
                                            <FormGroup row>
                                                <Label className="col-md-4 col-form-label">Path</Label>
                                                <Col md={8} className="container-center-input">
                                                    <Input className="form-control" type="text" value={this.state.data?.path ? this.state.data?.path : 'N/A'} disabled />
                                                </Col>
                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>
                                            <FormGroup row>
                                                <Label className="col-md-4 col-form-label">Short link</Label>
                                                <Col md={8} className="container-center-input">
                                                    <Input className="form-control" type="text" value={this.state.data?.short_link ? this.state.data?.short_link : 'N/A'} disabled />
                                                </Col>
                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>
                                            <FormGroup row>
                                                <Label className="col-md-4 col-form-label">Long link</Label>
                                                <Col md={8} className="container-center-input">
                                                    <Input className="form-control" type="text" value={this.state.data?.long_link ? this.state.data?.long_link : 'N/A'} disabled />
                                                </Col>
                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>
                                            <FormGroup row>
                                                <Label className="col-md-4 col-form-label">Total de acessos</Label>
                                                <Col md={8} className="container-center-input">
                                                    <Input className="form-control" type="text" value={this.state.data?.access_data?.total ? this.state.data?.access_data?.total : 'N/A'} disabled />
                                                </Col>
                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>
                                            <FormGroup row>
                                                <Label className="col-md-4 col-form-label">Último acesso em</Label>
                                                <Col md={8} className="container-center-input">
                                                    <Input className="form-control" type="text" value={this.state.data?.access_data?.last_accessed_at ? this.state.data?.access_data?.last_accessed_at : 'N/A'} disabled />
                                                </Col>
                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>
                                            <FormGroup row>
                                                <Label className="col-md-4 col-form-label">Criado em</Label>
                                                <Col md={8} className="container-center-input">
                                                    <Input className="form-control" type="text" value={this.state.data?.created_at ? this.state.data?.created_at : 'N/A'} disabled />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </div>
                                </CardBody>
                            </Card>

                            <Card>
                                <CardBody className="container-space-between">
                                    <Link to='/public-links/index' className="btn btn-danger btn-bold"><i className="fa fa-arrow-left mr-2"></i>Voltar</Link>
                                </CardBody>
                            </Card>
                        </Container>
                    </div>
                </BetterCircularProgress>
            </React.Fragment>
        )
    }
}

export default PublicLinksShow;