import React, { Component } from 'react';
import { Container, Card, CardBody, Row, Col, FormGroup, Label, Input, Button, ButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from "reactstrap";
import { Link } from "react-router-dom";
import DataTableCrawlerConfig from '../../components/datatables/DataTableCrawlerConfig';
import { getAllJobs } from '../../crud/jobs.crud';
import BetterAlerts from '../../components/BetterAlerts';
import Breadcrumbs from '../../components/Breadcrumb';

class CrawlerConfigIndex extends Component {

	constructor(props) {
		super(props);

		this.goBack = this.goBack.bind(this);
	}

	state = {
		errors: [],
		success: [],
		filters: {
			state: '',
			name: '',
		},
		flags: {
			show_state: false,
			name_state: false,
		},
		is_filter_open: false,
	};

	goBack() {
		this.props.history.goBack();
	}

	saveFilters = () => {
		localStorage.setItem('crawlerConfigFilters', JSON.stringify(this.state.filters));
		localStorage.setItem('crawlerConfigFlags', JSON.stringify(this.state.flags));
	};

	fetchData = () => {
		return getAllJobs().then(response => {
			if (this.state.filters.state) {
				response.data = response.data.filter(({ state }) => state === this.state.filters.state);
			}
			if (this.state.filters.name) {
				response.data = response.data.filter(({ name }) => name.toLowerCase().includes(this.state.filters.name.toLowerCase()));
			}
			return response;
		});
	};

	render() {
		return (
			<React.Fragment>
				<div className="page-content container-page">
					<Container fluid>
						<Breadcrumbs title="Configuração do Crawler" breadcrumbItems={[]} />
						<BetterAlerts errors={this.state.errors} success={this.state.success} />

						<Row className="align-right mb-30">
							<ButtonDropdown direction="left" className="mr-10 wm-100" isOpen={this.state.is_filter_open} toggle={() => this.setState({ is_filter_open: !this.state.is_filter_open })} >
								<DropdownToggle className="btn-index-action" color="primary" caret>
									<i className="ri-filter-3-line align-middle mr-2"></i> {"ADD FILTRO"}
								</DropdownToggle>
								<DropdownMenu>
									{!this.state.flags.show_state ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_state: true } })}>Estado</DropdownItem> : null}
									{!this.state.flags.show_name ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_name: true } })}>Cidade</DropdownItem> : null}
								</DropdownMenu>
							</ButtonDropdown>
							<Link to={`/crawlerConfig/new`} className="btn-index-action button btn btn-success waves-effect waves-light mr-10">
								<i className="ri-add-line align-middle mr-2"></i> CRIAR
							</Link>
							<Link to={`/crawlerConfig/hsm`} className="btn-index-action button btn btn-primary waves-effect waves-light mr-10">
								<i className="ri-tools-line align-middle mr-2"></i> Configurar HSM
							</Link>
							<Link to={`/crawlerConfig/sms`} className="btn-index-action button btn btn-primary waves-effect waves-light mr-10">
								<i className="ri-tools-line align-middle mr-2"></i> Configurar SMS
							</Link>
						</Row>

						<Row className="container-center-input">
							{this.state.flags.show_state ?
								<Col md={6}>
									<FormGroup row>
										<Label className="col-md-2 col-form-label">Estado</Label>
										<div className="col-10 col-sm-8 container-center-input">
											<select value={this.state.filters.state} onChange={(e) => { this.setState({ filters: { ...this.state.filters, state: e.target.value } }, () => this.saveFilters()); }} className="custom-select">
												<option value=""></option>
												<option value="DISABLED">DISABLED</option>
												<option value="ENABLED">ENABLED</option>
											</select>
										</div>
										<Button onClick={() => this.setState({ flags: { ...this.state.flags, show_state: false }, filters: { ...this.state.filters, state: '' } }, () => this.saveFilters())} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
											<i className="ri-close-circle-line align-middle"></i>
										</Button>
									</FormGroup>
								</Col>
								: false
							}
						</Row>


						<Row className="container-center-input">
							{this.state.flags.show_name ?
								<Col md={6}>
									<FormGroup row>
										<Label className="col-md-2 col-form-label">Cidade</Label>
										<div className="col-10 col-sm-8 container-center-input">
											<Input className="form-control" type="text" value={this.state.filters.name}
												onChange={(e) => this.setState({ filters: { ...this.state.filters, name: e.target.value } }, () => this.saveFilters())} />
										</div>
										<Button onClick={() => this.setState({ flags: { ...this.state.flags, show_name: false }, filters: { ...this.state.filters, name: '' } }, () => this.saveFilters())} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
											<i className="ri-close-circle-line align-middle"></i>
										</Button>
									</FormGroup>
								</Col>
								: false
							}

							<Col lg={12}>
								<Card>
									<CardBody>
										<DataTableCrawlerConfig filters={this.state.filters} noDelete fetchEvent={this.fetchData} crudButtons={true}
											crudUrl="/crawlerConfig" idRow={`name`} noShow />
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}

export default CrawlerConfigIndex;