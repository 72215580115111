import axios from "axios";
import { Constants } from '../helpers/Constants';
import { parseQuery } from '../helpers/Utils';

export function getAllByParams(params) {
	return axios.get(`${Constants.baseUrl}/propositions`, { params: parseQuery(params) });
}

export function getAllPlaces() {
	return axios.get(`${Constants.baseUrl}/propositions/get-all-places`);
}

export function get(id) {
	return axios.get(`${Constants.baseUrl}/propositions/${id}`);
}

export function update(id, data) {
	return axios.patch(`${Constants.baseUrl}/propositions/${id}`, data);
}

export function store(data) {
	return axios.post(`${Constants.baseUrl}/propositions`, data);
}

export function remove(id) {
	return axios.delete(`${Constants.baseUrl}/propositions/${id}`);
}

export function approveRefuseCredit(id, data) {
	return axios.post(`${Constants.baseUrl}/propositions/update-credit-approval/${id}`, data);
}

// comissionamento
export function getPropositionUsers(params) {
	return axios.get(`${Constants.baseUrl}/propositions/proposition-users`, { params: parseQuery(params) });
}

export function getPropositionUser(user_id) {
	return axios.get(`${Constants.baseUrl}/propositions/proposition-users/${user_id}`);
}

export function storeComissioningNegotiation(data) {
	return axios.post(`${Constants.baseUrl}/propositions/proposition-users`, data);
}

export function updatePropositionUser(id, data) {
	return axios.patch(`${Constants.baseUrl}/propositions/proposition-users/${id}`, data);
}

export function removePropositionUser(id) {
	return axios.delete(`${Constants.baseUrl}/propositions/proposition-users/${id}`);
}

export function removeFromParty(id, user_id) {
	return axios.post(`${Constants.baseUrl}/propositions/${id}/remove-party`, {
		user_id
	});
}
//
export function resendEmailTenantLock(id) {
	return axios.get(`${Constants.baseUrl}/propositions/${id}/resend-email-tenant-lock`);
}

//
export function resendEmailCreditRefused(id) {
	return axios.get(`${Constants.baseUrl}/propositions/${id}/resend-email-credit-refused`);
}

export function getAllPropositionsUser(params) {
	return axios.get(`${Constants.baseUrl}/propositions/current-user`, { params: parseQuery(params) });
}

export function getTotalNegociacoes(params) {
	return axios.get(`${Constants.baseUrl}/propositions/total`, { params: parseQuery(params) });
}

export function getUsersWithLaudoStatus(proposition_id) {
	return axios.get(`${Constants.baseUrl}/propositions/laudo-users/${proposition_id}`)
}