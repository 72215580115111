import React, { Component, createRef } from 'react';
import { Container, Card, Row, Col, Label, Input, } from "reactstrap";
import Breadcrumbs from '../../../components/Breadcrumb';
import BetterAlerts from '../../../components/BetterAlerts';

import BetterCircularProgress from '../../../components/BetterCircularProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faFilter, faMagnifyingGlass, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faArrowRotateRight, faUserMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';

import { getSecBillings, getSecReturnById } from '../../../crud/billings.crud';
import { formatBRL } from '../../../helpers/Utils';

import { Link } from "react-router-dom";
import moment from 'moment';
import DataTableReturnBillings from '../../../components/datatables/DataTableReturnBilings';

class SecuritizationReturnShow extends Component {
	dataTableRef = createRef();

	state = {
		flags: {
			created_at_lte: null,
			created_at_gte: null,
			status: null,
			landlord: null
		},
		filters: {
			created_at_lte: null,
			created_at_gte: null,
			status: null,
			landlord: null
		},
		is_filter_open: false,
		errors: [],
		success: [],
		activeTab: '1',
		filtersCount: 0,
		newFilters: [],
		indicadores: {},
		totalAtual: 0,
		list_users: [],
		termo_user: '',
		shipment_billings: [],
		shipment_id: null,
		secReturn: null
	};

	componentDidMount = () => {
		if (this.props?.location?.state?.success) {
			this.setState({ success: this.props.location.state.success });
		}

		getSecReturnById(this.props.match.params.id).then((response) => {
			if (response.status == 200) {
				this.setState({ secReturn: response.data })
			}
		})
	};

	saveFilters = () => {

	};

	fetchByFilter = (data) => {
		var params = {
			...data,
			return_id: this.props.match.params.id
		};

		return getSecBillings(params);
	};


	calcFaceVolume = () => {
		let volume = 0;

		this.state.shipment_billings.map((billing) => {
			volume += billing.value;
		})

		return formatBRL(volume);
	}

	FiltersModule = ({ filtroModular, index }) => {
		const getFilterChoised = (filter) => {
			let component = <></>

			if (filter == 'due_date') {
				component = (
					<>
						<div className='d-flex'>
							<Label htmlFor="due_date_gte" className="col-form-label">De</Label>
							<Input className="form-control" type="date" id="due_date_gte" value={this.state.filters.due_date_gte} style={{ width: 200, margin: '0 16px' }}
								onChange={(e) => this.setState({ filters: { ...this.state.filters, due_date_gte: e.target.value } }, () => this.saveFilters())} />
						</div>

						<div className='d-flex'>
							<Label htmlFor="due_date_lte" className="col-form-label">Até</Label>
							<Input className="form-control" type="date" id="due_date_lte" value={this.state.filters.due_date_lte} style={{ width: 200, margin: '0 16px' }}
								onChange={(e) => this.setState({ filters: { ...this.state.filters, due_date_lte: e.target.value } }, () => this.saveFilters())} />
						</div>
					</>
				)
			} else if (filter == 'shipment_batch') {
				component = (
					<div className='d-flex'>
						<Input className="form-control" type="text" value={this.state.filters.shipment_batch} style={{ width: 400, margin: '0 16px' }} placeholder="Pesquisar por lote de remessa"
							onChange={(e) => this.setState({ filters: { ...this.state.filters, shipment_batch: e.target.value } }, () => this.saveFilters())} />
					</div>
				)
			} else {
				component = <></>
			}

			return component;
		}

		const onSetFilter = (filtro) => {
			let stateFilter = this.state.newFilters.slice();
			stateFilter[index] = filtro;

			this.setState({ newFilters: stateFilter })
		}

		const getSelectOptions = (nameFiltro) => {
			const stateFilter = this.state.newFilters;

			if (stateFilter.includes(nameFiltro)) {
				return 'd-none'
			} else {
				return ''
			}
		}

		const removeFilter = (filterUnit, filterName) => {
			let stateFilter = this.state.newFilters;

			stateFilter.splice(filterUnit, 1);

			switch (filterName) {
				case 'due_date':
					this.setState({
						flags: { ...this.state.flags, due_date_gte: false, due_date_lte: false },
						filters: { ...this.state.filters, due_date_gte: null, due_date_lte: null }
					}, () => this.saveFilters())

					break;

				case 'shipment_batch':
					this.setState({
						flags: { ...this.state.flags, shipment_batch: false },
						filters: { ...this.state.filters, shipment_batch: null }
					}, () => this.saveFilters())

					break;

				default:
					break;
			}

			this.setState({ newFilters: stateFilter })
		}

		return (
			<Col md={12} className="d-flex m-0 p-0 filtroUnit">
				<div className='d-flex'>
					<select onChange={({ target }) => onSetFilter(target.value)} value={this.state.newFilters[index]} className="custom-select" style={{ width: 300 }}>
						<option value="">Selecione um filtro</option>
						<option className={getSelectOptions('due_date')} value="due_date">Vencimento</option>
						<option className={getSelectOptions('shipment_batch')} value="shipment_batch">Lote</option>
					</select>

					{this.state.newFilters[index] != '' && (
						<div className='divisionArrow'>
							<FontAwesomeIcon icon={faChevronRight} />
						</div>
					)}

					{getFilterChoised(this.state.newFilters[index])}
				</div>

				<div onClick={() => removeFilter(index, this.state.newFilters[index])}>
					<FontAwesomeIcon icon={faTimes} />
				</div>
			</Col>
		)
	}


	render() {

		return (
			<React.Fragment>
				<div className="page-content container-page userIndex sec-shipment">
					<Container fluid>
						<div className='header'>
							<h1>Remessas e Retornos</h1>

							<Breadcrumbs breadcrumbItems={[{ link: '/pagamento/cobrancas/index', title: 'Pagamentos' }, { link: '/pagamento/securitizacao/index', title: 'Remessas e Retornos' }, { link: '/pagamento/securitizacao/return/show' + this.state.secReturn?.id, title: `Retorno ${this.state.secReturn?.id}` }]} />
						</div>

						<BetterAlerts errors={this.state.errors} success={this.state.success} />
						<div className="userShow userNew  anuncio negociacao">
							<div className='boxUser'>
								<div className='headerUser'>
									<div className='tagsAssets groupAlign'>
										<h3>{this.state.secReturn?.file?.initial_name ?? 'ARQUIVO.RET'}</h3>

										<div className='tagGroup'>
											{this.state.secReturn?.status == 'WAITING_PROCESS' && (
												<div className='tag' style={{ backgroundColor: '#EFA200' }}>Gerado</div>
											)}

											{this.state.secReturn?.status == 'PROCESSED' && (
												<div className='tag' style={{ backgroundColor: '#1CBB8C' }}>Conciliado</div>
											)}
										</div>
									</div>

									<div className='tagsAssets bodyAssets groupAlign'>
										<div className='boxInformation'>
											<p>Usuário responsável</p>
											<div>
												<FontAwesomeIcon icon={faUserMagnifyingGlass} style={{ color: '#4AA3FF', marginRight: 8 }} />
												<Link to={`/users/show/${this.state?.secReturn?.responsable_id}`} style={{ color: "#4AA3FF", fontWeight: 'bold' }}>{this.state?.secReturn?.responsable_infos?.name}</Link>
											</div>
										</div>
									</div>

									<div className='tagsAssets groupAlign'>
										<div className='boxInformation'>
											<p>Data do upload</p>
											<p style={{ fontWeight: 600, fontFamily: 'Inter', color: '#343A40' }}>{moment(this.state?.secReturn?.uploaded_at).format('DD/MM/YYYY HH:mm:ss')}</p>
										</div>
									</div>
								</div>

								<div className='bodyAssets'>
									<div className='boxInformation'>
										<p>Cobranças securitizadas</p>
										<b>{this.state.secReturn?.billings_securitized}</b>
									</div>

									<div className='boxInformation'>
										<p>Volume face securitizado</p>
										<b>{formatBRL(this.state.secReturn?.securitized_face ?? 0)}</b>
									</div>
								</div>
							</div>
						</div>

						<Col md={12} className="box-filtros">
							<Col md={12} className="d-flex m-0 p-0">
								<div className='generalSearch'>
									<Input className="inputCustomNew" type="text" value={this.state.filters.searchAll} placeholder="Busque por contrato, CPF ou CNPJ do sacado"
										onChange={(e) => this.setState({ filters: { ...this.state.filters, searchAll: e.target.value } }, () => this.saveFilters())} />

									<FontAwesomeIcon icon={faMagnifyingGlass} />
								</div>

								<button onClick={() => this.setState({ newFilters: [...this.state.newFilters, ''] })} className="btnSecundary" style={{ width: 'max-content', margin: '0px 8px', padding: '8px 12px' }}>
									FILTRO
									<FontAwesomeIcon icon={faFilter} />
								</button>

								<button onClick={() => this.dataTableRef.current.refreshTable()} className="btnSecundary" style={{ width: 'max-content', margin: '0px 8px', padding: '8px 12px' }}>
									ATUALIZAR
									<FontAwesomeIcon icon={faArrowRotateRight} />
								</button>
							</Col>

							{this.state.newFilters.map((filtroModular, index) => {
								return <this.FiltersModule filtroModular={filtroModular} key={index} index={index} />
							})}
						</Col>

						<Row>
							<Col lg={12}>
								<Card className='card-general'>
									<h3 style={{ marginBottom: 40 }}>Cobranças do retorno validadas</h3>

									<div className='datatable-box'>
										<BetterCircularProgress loading={this.state.loading}>
											<DataTableReturnBillings filters={this.state.filters} noDelete fetchEvent={this.fetchByFilter} crudButtons={true}
												crudUrl="/pagamento/negativacao/index" idRow={`id`} export={this.state.export} ref={this.dataTableRef} state={this.state.shipment_billings} setState={this.setState.bind(this)} />
										</BetterCircularProgress>
									</div>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}

export default SecuritizationReturnShow;