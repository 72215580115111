import React, { Component } from 'react';
import { Container, Card, CardBody, Alert } from "reactstrap";
import BetterCircularProgress from '../../components/BetterCircularProgress';
import { formatBRL } from '../../helpers/Utils';
import Breadcrumbs from '../../components/Breadcrumb';
import moment from 'moment';
import { get } from '../../crud/doc-users.crud';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';

class docUserShow extends Component {

	state = {
		loading: false,
		success: [],
		message: null,
		data: null,
	}

	componentDidMount = async () => {
		this.setState({ loading: true });

		get(this.props.match.params.id).catch(err => {
			return Swal.fire('Ops', 'Ocorreu um erro ao buscar a cobrança jurídica.', 'error');
		}).then(res => {
			if (res?.status === 200) {
				console.log(res.data.data);
				if (res.data) {
					this.setState({ data: res.data.data })
				}
			}
		}).finally(() => this.setState({ loading: false }));
	};

	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	};

	formatDate(date) {
		const newDate = date?.split('/')[1] + '/' + date?.split('/')[0] + '/' + date?.split('/')[2];
		return newDate;
	}

	render() {
		return (
			<React.Fragment>
				<BetterCircularProgress loading={this.state.loading}>
					<div className="page-content container-page">
						<Container fluid>
							<Breadcrumbs breadcrumbItems={[{ link: '/dashboard', title: 'Dashboard' }, { link: `/coupons/show/${this.state.data?.entity?.id}`, title: 'Visualizar' }]} />
							{/* <BetterAlerts success={this.state.success} /> */}

							{this.state.success && this.state.message ? <Alert color="success">{this.state.message}</Alert> : null}

							<Card>
								<CardBody>
									<div>
										<h1 style={{ fontFamily: 'Inter', fontWeight: '600', fontSize: '22px', lineHeight: '27px', color: '#505D69' }}>Cobranças jurídicas</h1>
									</div>

									<div style={{ marginTop: '30px' }}>
										<div style={{ display: 'flex' }}>
											<div style={{ width: '254px', marginRight: '12px' }}>

												<p style={{ color: '#4AA3FF', fontFamily: 'Inter', fontWeight: '400', fontSize: '13px', lineHeight: '15px' }}>Contrato</p>
												<p>{this.state?.data?.friendly_contract_code}</p>
											</div>
											<div>
												<p style={{ color: '#4AA3FF', fontFamily: 'Inter', fontWeight: '400', fontSize: '13px', lineHeight: '15px' }}>Documento</p>
												<a href={this.state?.data?.file_desc?.url} target="_blank" rel="noopener noreferrer" style={{ color: '#5664D2', textDecoration: 'underline !important' }}>{this.state?.data?.file_desc.url.match(/\/([^\/?#]+)$/)[1]}</a>
											</div>
										</div>

										<div style={{ display: 'flex', marginTop: '20px' }}>
											<div style={{ width: '254px', marginRight: '12px' }}>

												<p style={{ color: '#4AA3FF', fontFamily: 'Inter', fontWeight: '400', fontSize: '13px', lineHeight: '15px' }}>Decisão</p>
												<div>
													<div style={{ width: '72px', height: '24px', color: '#FFFFFF', borderRadius: '2px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: this.state?.data?.status === 'WON' ? '#1CBB8C' : '#FF3D60' }}>{this.state?.data?.status === 'WON' ? 'Ganho' : 'Perdido'}</div>
												</div>
											</div>
											<div>
												<p style={{ color: '#4AA3FF', fontFamily: 'Inter', fontWeight: '400', fontSize: '13px', lineHeight: '15px' }}>Data da sentença</p>
												<p>{moment(this.state?.data?.sentence_date).add(3, 'hours').format('DD/MM/YYYY')}</p>
											</div>
										</div>

										<div style={{ display: 'flex', marginTop: '20px' }}>
											<div style={{ width: '254px', marginRight: '12px' }}>

												<p style={{ color: '#4AA3FF', fontFamily: 'Inter', fontWeight: '400', fontSize: '13px', lineHeight: '15px' }}>Valor final</p>
												<div>
													<div style={{ fontFamily: 'Inter', fontWeight: '400', fontSize: '13px', lineHeight: '15px' }}>{formatBRL(this.state?.data?.final_value)}</div>
												</div>
											</div>
											<div>
												<p style={{ color: '#4AA3FF', fontFamily: 'Inter', fontWeight: '400', fontSize: '13px', lineHeight: '15px' }}>{this.state?.data?.fees?.type === 'PERCENTAGE' ? 'Porcentagem' : 'Valor fixo'} dos honorários</p>
												<p>{this.state?.data?.fees?.type === 'PERCENTAGE' ? `${this.state?.data?.fees?.value} %` : formatBRL(this.state?.data?.fees?.value)}</p>
											</div>
										</div>

										<div style={{ display: 'flex', marginTop: '20px' }}>
											<div style={{ width: '254px', marginRight: '12px' }}>
												<p style={{ color: '#4AA3FF', fontFamily: 'Inter', fontWeight: '400', fontSize: '13px', lineHeight: '15px' }}>Descrição</p>
												<p style={{ fontFamily: 'Inter', fontWeight: '400', fontSize: '13px', lineHeight: '15px' }}>{this.state?.data?.description}</p>
											</div>
										</div>
									</div>
								</CardBody>

								<CardBody className="row">
									<button onClick={() => window.history.back()} className="btn btn-secondary btn-bold ml-2"><i className="fa fa-arrow-left mr-2"></i>Voltar</button>
								</CardBody>
							</Card>
						</Container>
					</div>
				</BetterCircularProgress>
			</React.Fragment>
		)
	}
}

export default docUserShow;