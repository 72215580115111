/* eslint-disable eqeqeq */
import React, { Component, createRef } from 'react';
import { Container, Col, Label, TabContent, TabPane, Nav, NavItem, NavLink, Input } from "reactstrap";
import BetterCircularProgress from '../../components/BetterCircularProgress';
import classnames from 'classnames';
import moment from 'moment';
import DataTableUserAnnouncements from '../../components/datatables/DataTableUserAnnouncements';
import Breadcrumbs from '../../components/Breadcrumb';
import BetterAlerts from '../../components/BetterAlerts';
import { allowOnlyNumbersInput, allowOnlyPercentageInput, formatBRL, formatCNPJ, formatCPF, formatTelefone } from '../../helpers/Utils';
import { get as getUser, getAccountBank, getExternalAuth, queryFiscalDocuments } from '../../crud/users.crud';
import { getAllByParams as getPropositions, getAllPropositionsUser, getPropositionUser } from '../../crud/propositions.crud';
import { getComissioningParticipants, getProperties } from '../../crud/announcements.crud';
import { getAccountVerification, resendEmail, getDocumentStatus, getFinancialTransactions } from '../../crud/account.crud';
import DataTableContrato from '../../components/datatables/afiliados/DataTableContrato';
import DataTableIndicado from '../../components/datatables/afiliados/DataTableIndicado';
import DataTableHistorico from '../../components/datatables/afiliados/DataTableHistorico';
import { getByFilterUsersAffiliate, getContractAffiliate, transactionsAfiliado, updateConfigRepasse, getAffiliateBalance, addUserAffiliate } from '../../crud/affiliate.crud';
import { UserFilterComponent } from './components/user.component';
import { InputAdornment, TextField } from '@material-ui/core';
import DataTableUserContracts from '../../components/datatables/DataTableUserContracts';
import DataTableUsersHistoricoFinanceiro from '../../components/datatables/DataTableUsersHistoricoFinanceiro';
import DataTableLogsUsuario from '../../components/datatables/logs/DataTableLogsUsuario';
import { createContractAdminLogs, getContractAdminLogs } from '../../crud/contracts.crud';
import { getBankTypes } from '../../helpers/bankHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPen, faUnlockKeyhole, faUser, faUserUnlock } from '@fortawesome/pro-regular-svg-icons';
import DataTableUserAnnouncementsNegotiation from '../../components/datatables/DataTableUserAnnouncementsNegotiation';
import DataTableUsersNotificacoes from '../../components/datatables/DataTableUsersNotificacoes';
import { getAllByParams as getAllNotifications } from '../../crud/notifications.crud'
import { faLink } from '@fortawesome/pro-solid-svg-icons';
import { Constants } from '../../helpers/Constants';
import DataTableFiscalDocs from '../../components/datatables/fiscalDocs/DataTableFiscalDocs';
import { getAnticipations, getAnticipationsCounters } from '../../crud/billings.crud';
import DataTableAnticipations from '../../components/datatables/DataTableAnticipations';
import newAnticipationIcon from "../../assets/images/icons-custom/anticipation.svg";

class UsersShow extends Component {

	DataTableUserAnnouncementsRef = createRef();
	DataTableUserAnnouncementsNegotiationRef = createRef();
	DataTableUserContractsRef = createRef();

	DataTableUsersHistoricoFinanceiroRef = createRef();
	DataTableUsersNotificacoesRef = createRef();
	dataTableLogsUsuarioRef = createRef();
	dataTableFiscalDocsRef = createRef();

	DataTableHistoricoRef = createRef();
	dataTableIndicadoRef = createRef();
	dataTableContratosRef = createRef();

	DataTableAnticipations = createRef();

	state = {
		loading: true,
		activeTab: '1',
		data: null,
		businessData: null,
		associateData: null,
		associates: [],
		accountBankData: null,
		accountVerification: null,
		document: {},
		onboardingUrl: null,
		properties: null,
		income: null,
		raw: null,
		success: [],
		errors: [],
		newIndicado: '',
		financialTransactions: null,
		totalIndicados: 0,
		totalContratosAtivos: 0,
		affiliateBalance: 0,
		totalAnticipations: 0,
		is_filter_open: false,
		is_filterHistorico_open: false,
		comentario: '',
		isCommissioned: false,
		cpfEmpresa: '',
		filters: {
			depoisDe: '',
			antesDe: '',
		},
		flags: {
			show_depoisDe: false,
			show_antesDe: false,
		},
		requestUser: null,
		antecipatedValuesPaid: 0,
		antecipatedValuesPending: 0
	};

	getCompanyFriendlyName = (key) => {
		const companyTypes = [
			{
				label: 'Micro Empreendedor Individual (MEI)',
				key: 'MEI'
			},
			{
				label: 'Empresa Limitada',
				key: 'LIMITED'
			},
			{
				label: 'Empresa Individual',
				key: 'INDIVIDUAL'
			},
			{
				label: 'Associação',
				key: 'ASSOCIATION'
			},
		];

		const company = companyTypes.find(obj => obj.key === key)

		return company.label ?? key;
	}

	componentDidMount = () => {
		this.setState({ loading: true });

		if (localStorage.getItem("authUser")) {
			const obj = JSON.parse(localStorage.getItem("authUser"));
			this.setState({ requestUser: obj?.user })
		}

		getUser(this.props.match.params.id).catch(err => {
			alert('Ocorreu um erro ao carregar o usuario, entre em contato com o suporte.');
		}).then(async res => {
			if (res?.status === 200) {
				if (res.data) {
					this.setState({
						data: res.data.data,
						periodo: res.data.data.affiliate_period,
						recorrencia: res.data.data.affiliate_recurrence,
						porcentagemAfiliado: res.data.data.affiliate_percentage
					});

					if (res.data.data.cpf) {
						this.setState({ cpfEmpresa: res.data.data.cpf })
					}
				}

				this.fetchUserDatas();
			}
		}).finally(() => {
			this.setState({ loading: false });

			if (this.props.location.state) {
				if (this.props.location.state.success) {
					this.setState({ success: this.props.location.state.success });
				}

				if (this.props.location.state.errors) {
					this.setState({ errors: this.props.location.state.errors });
				}
			}
		});
	};

	fetchUserDatas = () => {
		getAccountBank(this.props.match.params.id).then(res => {
			if (res?.status === 200) {
				if (res.data) {
					this.setState({ accountBankData: res.data.data });
				}
			}
		}).catch((err) => {
			console.log(err, 'erro dados bancarios');
		});

		getAffiliateBalance(this.props.match.params.id).then(res => {
			if (res?.status === 200) {
				if (res.data) {
					this.setState({ affiliateBalance: res.data.data });
				}
			}
		}).catch(err => {
			console.log(err, 'erro balanço de afiliado');
		});

		getAnticipationsCounters(this.props.match.params.id).then(res => {
			if (res?.status === 200) {
				if (res.data) {
					this.setState({
						antecipatedValuesPaid: res.data.data.paidAnticipatedValueTotal,
						antecipatedValuesPending: res.data.data.pendingAnticipatedValueTotal
					});
				}
			}
		}).catch(err => {
			console.log(err, 'erro contadores de antecipações');
		})

		getAccountVerification(this.props.match.params.id).then(res => {
			if (res?.status === 200) {
				if (res.data) {
					this.setState({ accountVerification: res.data.data });
				}
			}
		}).catch(err => {
			console.log(err, 'erro dados de verificação de perfil');
		});

		getDocumentStatus(this.props.match.params.id).then(res => {
			if (res?.status === 200) {
				if (res.data) {
					this.setState({ document: res.data.data });
					// Todas as urls retornadas são a mesma mas por algum motivo alguns objetos docs retornam sem a url
					// Verifica qual possui a url e seta o onboarding com base nele
					for (const doc of res.data.data.data) {
						if (doc.onboardingUrl !== null) {
							this.setState({ onboardingUrl: doc.onboardingUrl });
						}
					}
				}
			}
		}).catch(err => {
			console.log(err, 'erro dados dos documentos de verificação de perfil');
		});

		getComissioningParticipants({ user_id: this.props.match.params.id }).then(res => {
			if (res?.status === 200) {
				if (res.data?.total > 0) {
					this.setState({ isCommissioned: true });
				}
			}
		}).catch(err => {
			console.log(err, 'erro verificação de comissionamento ativo imovel');
		});

		getPropositionUser(this.props.match.params.id).then(res => {
			if (res?.status === 200) {
				if (res.data?.total > 0) {
					this.setState({ isCommissioned: true });
				}
			}
		}).catch(err => {
			console.log(err, 'erro verificação de comissionamento ativo negociacao');
		});
	}

	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	};

	fetchContracts = (data) => {
		let params = { ...data };

		params.user_id = this.props.match.params.id;
		params.signed = true;

		return getContractAffiliate(params).catch((err) => {
			console.log(err);
		});
	}

	fetchAnnouncementsProperties = (data) => {
		let params = { ...data };

		params.user_id = this.props.match.params.id;

		return getProperties(params).catch((err) => {
			console.log(err);
		});
	};

	fetchPropositionsUser = (data) => {
		let params = { ...data };

		params.user_id = this.props.match.params.id;
		// params.signed = false;

		return getAllPropositionsUser(params).catch((err) => {
			console.log(err);
		});
	};

	fetchPropositionsTenant = (data) => {
		let params = { ...data };

		params.tenant_id = this.props.match.params.id;

		return getPropositions(params).catch((err) => {
			console.log(err);
		});
	};

	fetchPropositionsLandlord = (data) => {
		let params = { ...data };

		params.landlord_id = this.props.match.params.id;

		return getPropositions(params).catch((err) => {
			console.log(err);
		});
	};

	fetchFiscalDocuments = (data) => {
		let params = { ...data };

		return queryFiscalDocuments(params).catch((err) => {
			console.log(err);
		});
	};

	getTypeAccount = (account) => {
		if (account === 'checking') {
			return 'Corrente';
		} else {
			return 'Poupança';
		}
	};

	resendEmail = () => {
		this.setState({ loading: true });

		resendEmail({ user_id: this.props.match.params.id }).catch(err => {
			this.setState({ loading: false, error: ['Erro ao enviar e-mail!'] });
		}).then(res => {
			if (res?.status == 200) {
				this.setState({ loading: false, success: ['E-mail enviado com sucesso!'] });
			}
		});
	}

	handleChangeRepasse = (form, type) => {
		let data = {};

		if (type == 'recorrencia') {
			data.affiliate_recurrence = form;
		}

		if (type == 'periodo') {
			data.affiliate_period = form;
		}

		if (type == 'porcentagem') {
			data.affiliate_percentage = this.state.porcentagemAfiliado;

			if (this.state.porcentagemAfiliado > 50) {
				this.setState({ errors: ['A porcentagem não pode ser maior que 50%'] })
				return;
			}
		}

		updateConfigRepasse(this.state.data.id, data).then(({ data }) => {
			this.setState({ ...this.state, data: { ...this.state.data, ...data.data } });
		}).catch(() => {
			//err
		}).finally(() => {
			this.setState({ ...this.state, loading: false, newIndicado: '' });
		});
	}

	handleSetIndicados = (indicados) => {
		this.setState({ totalIndicados: indicados })
	}

	handleSetContratosAtivos = (contratos) => {
		this.setState({ totalContratosAtivos: contratos })
	}

	handleSetErrors = (errors) => {
		window.scroll(0, 0);

		this.setState({ errors: errors })
	}

	handleNewIndicado = () => {
		addUserAffiliate({
			user_id: this.state.newIndicado.id,
			affiliate_id: this.state.data.id
		}).then(({ data }) => {
			this.setState({
				success: ['Indicado adicionado com sucesso. O afiliado receberá comissões dos próximos contratos fechados desse indicado.']
			});

			this.dataTableIndicadoRef.current.fetchData()
		}).catch(() => {

		}).finally(() => {
			this.setState({ ...this.state, loading: false, newIndicado: '' });
		});
	}

	handleCopyClipboard = () => {
		navigator.clipboard.writeText(this.state.data?.affiliate_link);

		let successMsgs = this.state.success;

		successMsgs.push('Link copiado para área de transferência')

		this.setState({ success: successMsgs });
	}

	fetchHistoricoFinanceiroByFilter = async (data) => {
		let params = { ...data };

		if (this.state.flags.show_depoisDe && this.state.filters.depoisDe) params.startDate = this.state.filters.depoisDe;
		if (this.state.flags.show_antesDe && this.state.filters.antesDe) params.finishDate = this.state.filters.antesDe;

		return getFinancialTransactions(this.props.match.params.id, params).catch((err) => {
			console.log(err);
		});
	}

	saveFilters = () => {
		localStorage.setItem('userHistoricoFinanceiroFilters', JSON.stringify(this.state.filters));
		localStorage.setItem('userHistoricoFinanceiroFlags', JSON.stringify(this.state.flags));

		this.fetchHistoricoFinanceiroByFilter();
	};

	fetchUsuarioAdminLogs = (data) => {
		let params = { ...data };

		params.table = 'users'
		if (this.state.flags.show_depoisDe && this.state.filters.depoisDe) params.created_at_gte = this.state.filters.depoisDe;
		if (this.state.flags.show_antesDe && this.state.filters.antesDe) params.created_at_lte = this.state.filters.antesDe;
		if (this.state.flags.show_informacao && this.state.filters.informacao) params.log_iLike = this.state.filters.informacao;

		return getContractAdminLogs(this.props.match.params.id, params);
	}

	handleSendComentarioLog = () => {
		createContractAdminLogs({ id: this.props.match.params.id, comment: this.state.comentario, table: 'users' }).then(() => {
			this.setState({
				comentario: '',
				success: [`Comentário adicionado com sucesso!`]
			});

			this.dataTableLogsUsuarioRef.current.fetchData();
		}).catch((e) => {
			console.log(e);
		});
	}

	saveFiltersLog = () => {
		localStorage.setItem('dataTableLogsUsersFilters', JSON.stringify(this.state.filters));
		localStorage.setItem('dataTableLogsUsersFlags', JSON.stringify(this.state.flags));

		this.fetchUsuarioAdminLogs();
	};

	getOrigemCadastro = (text) => {
		switch (text) {
			case 'affiliate':
				return 'Afiliado'

			case 'organic':
				return 'Orgânico'

			default:
				return text;
		}
	}

	getOrigensRendaText = (text) => {
		switch (text) {
			case 'clt':
				return 'Funcionário Privado ou Público Registrado (CLT)'

			case 'public_agent':
				return 'Funcionário Público (Estatutário)'

			case 'legal_person':
				return 'Empresário'

			case 'liberal_professional':
				return 'Profissional liberal'

			case 'autonomous':
				return 'Autônomo'

			case 'retired':
				return 'Aposentado ou Pensionista'

			case 'intern':
				return 'Estagiário ou Bolsista'

			default:
				return 'Não definido'
		}
	};

	getBankName = (bankCode) => {
		let bankName = 'Banco não encontrado';

		getBankTypes().map((obj, i) => {
			if (obj.code == bankCode) {
				bankName = `${obj?.code} - ${obj?.name}`;
			}
		});

		return bankName;
	};

	getCpfOrCnpj = () => {
		if (this?.state?.data?.type_account == 'PF') {
			if (this?.state?.data?.cpf) {
				return formatCPF(this?.state?.data?.cpf);
			} else {
				return 'Não preenchido'
			}
		} else if (this?.state?.data?.type_account == 'PJ') {
			if (this?.state?.data?.cnpj) {
				return formatCNPJ(this?.state?.data?.cnpj);
			} else {
				return 'Não preenchido'
			}
		} else {
			if (this?.state?.data?.cpf) {
				return formatCPF(this?.state?.data?.cpf);
			}

			if (this?.state?.data?.cnpj) {
				return formatCNPJ(this?.state?.data?.cnpj);
			}

			return '--'
		}
	}

	fetchUserNotification = (data) => {
		let params = {
			...data,
			user_id: this.props.match.params.id,
		};

		return getAllNotifications(params);
	};

	externalAuthRedirect = () => {
		this.setState({ loading: true });
		const user_id = this.props.match.params.id

		getExternalAuth({ user_id }).then(res => {
			if (res.status == 200) {
				const baseURL = Constants.baseUrl;

				this.setState({ loading: false });

				if (baseURL.includes('dev')) {
					window.open(`https://dev-app.mellro.com/ext-auth?token=${res.data?.token}&user_id=${user_id}`, '_blank');
				} else {
					window.open(`https://mellro.com/ext-auth?token=${res.data?.token}&user_id=${user_id}`, '_blank');
				}
			}
		}).catch(err => {
			this.setState({ loading: false });
		})
	}

	renderTags = () => {
		const { rd_roles, context } = this?.state?.data || {};

		const roleTranslate = (role) => {
			switch (role) {
				case 'rd_affiliate':
					return { label: 'Afiliado', color: '#4AA3FF' }

				case 'rd_commissioned':
					return { label: 'Comissionado', color: '#4AA3FF' }

				case 'rd_admin':
					return { label: 'Responsável', color: '#5664D2' }

				case 'rd_landlord':
					return { label: 'Dono de imóvel', color: '#4AA3FF' }

				case 'rd_tenant':
					return { label: 'Inquilino', color: '#5664D2' }

				default:
					break;
			}
		}

		if (rd_roles?.length > 0) {
			return rd_roles.map((role, index) => {
				const { label, color } = roleTranslate(role);

				return (
					<div key={index} className="tag" style={{ backgroundColor: color }}>
						{label}
					</div>
				)
			})
		} else if (context) {
			return (
				<div className="tag" style={{ backgroundColor: '#5664D2' }}>
					{context === 'Landlord' ? 'Proprietário' : 'Inquilino'}
				</div>
			);
		} else {
			return null;
		}
	}

	render() {
		const BoxInfo = ({ title, href, children, classNameBody, preenchido, noTag = false, style, iniciado }) => {
			return (
				<div className='boxInfoUser' style={style}>
					<div className='headerBoxInfoUser'>
						<div className='title-tag'>
							<h4>{title}</h4>

							{(noTag || title === 'VERIFICAÇÃO DE PERFIL') ? null : preenchido ?
								<div className='tag'>
									Preenchido
								</div>
								:
								<div className='tag' style={{ backgroundColor: '#FCB92C' }}>
									Pendente
								</div>
							}

							{title === 'VERIFICAÇÃO DE PERFIL' && this?.state?.data?.verified ?
								<div className='tag'>
									Aprovada
								</div>
								:
								title === 'VERIFICAÇÃO DE PERFIL' && this?.state?.accountVerification?.documentStatus === "general_rejected" ?
									<div className='tag' style={{ backgroundColor: '#FF3D60' }}>
										Reprovado
									</div>
									:
									title === 'VERIFICAÇÃO DE PERFIL' && <div className='tag' style={{ backgroundColor: '#8590A5' }}>
										Aguardando
									</div>
							}
						</div>


						<a href={href} className='btnSecundary'>
							EDITAR
							<FontAwesomeIcon icon={faPen} />
						</a>
					</div>

					<div className={`${classNameBody} boxInfoBody`}>
						{children}
					</div>
				</div>
			)
		};

		const isAdmin = this?.state?.data?.roles?.includes('admin');
		const address = this?.state?.data?.address;
		const renda = this?.state?.data?.employment;
		const banco = this?.state?.accountBankData;

		const enderecoPreenchido = !!address?.street && !!address?.number && !!address?.complement && !!address?.neighbourhood && !!address?.city;
		const rendaPreenchida = !!renda?.type && (renda?.gross_income != '');
		const dadosBancariosPreenchido = !!banco?.bank && !!banco?.agency && !!banco?.account && !!banco?.account_type;

		return (
			<BetterCircularProgress loading={this.state.loading}>
				<div className="page-content container-page userShow userNew userIndex">
					<Container fluid>
						<BetterAlerts errors={this.state.errors} success={this.state.success} />

						<div className='header'>
							<h1>Usuário</h1>

							<Breadcrumbs breadcrumbItems={[{ link: '/users/index', title: 'Usuários' }, { link: `/users/show/${this.state.data?.id}`, title: `ID ${this.props.match.params.id}` }]} />
						</div>

						<div className='boxUser'>
							<div className='headerUser'>
								{this.state.data?.profile_image?.medium?.url ?
									<div className='userPhoto' style={{ backgroundImage: `url(${this.state.data?.profile_image?.medium?.url})` }} />
									:
									<div className='userPhotoNone'>
										<FontAwesomeIcon icon={faUser} />
									</div>
								}

								<div className='tagsAssets'>
									<h3>{this?.state?.data?.name}</h3>

									<div className='lastAssetsMobile'>
										<p>{!!this?.state?.data?.phone ? formatTelefone(this?.state?.data?.phone ?? 0) : 'Sem telefone'}</p>
										<p>{this?.state?.data?.email}</p>
									</div>

									<div className='tagGroup'>
										{this.renderTags()}

										{/* <div className='tag' style={{ backgroundColor: '#5664D2' }}>{this?.state?.data?.context == "Landlord" ? 'Proprietário' : 'Inquilino'}</div> */}

										{this?.state?.data?.verified &&
											<div className='tag' >Perfil Aprovado</div>
										}

										{(this.state?.accountVerification?.documentStatus == "rejected" || this.state?.accountVerification?.documentStatus == "general_rejected") &&
											<div className='tag' style={{ backgroundColor: '#FF3D60' }}>Perfil reprovado</div>
										}

										{isAdmin &&
											<div className='tag' style={{ backgroundColor: '#4AA3FF' }}>Admin</div>
										}

										{(!this?.state?.data?.verified && this?.state?.isCommissioned) &&
											<div className='tag' style={{ backgroundColor: '#4AA3FF' }}>Comissionado sem VP</div>
										}
									</div>
								</div>

								<div className='lastAssets'>
									<p>{!!this?.state?.data?.phone ? formatTelefone(this?.state?.data?.phone ?? 0) : 'Sem telefone'}</p>
									<p>{this?.state?.data?.email}</p>
								</div>
							</div>

							<div className='bodyAssets'>
								<div className='boxInformation'>
									<p>Local de origem</p>
									<b>{this.getOrigemCadastro(this.state?.data?.signup_source)}</b>
								</div>

								<div className='boxInformation'>
									<p>Data de cadastro</p>
									<b>{moment(this?.state?.data?.createdAt).format('DD/MM/YY HH:mm:ss')}</b>
								</div>

								{this?.state?.document?.data?.map(({ title, status }) => {
									let color = ''
									let text = ''

									switch (status) {
										case 'NOT_SENT':
											color = '#FCB92C';
											text = 'Não enviado';
											break;

										case 'REJECTED':
											color = '#FF3D60';
											text = 'Recusado';

											break;
										case 'PENDING':
											color = '#FCB92C';
											text = 'Em análise';

											break;
										case 'APPROVED':
											color = '#1CBB8C';
											text = 'Aprovado';

											break;
										default:
											color = '#8590A5';
											text = 'Não iniciada'

											break;
									}

									// if (this.state?.accountVerification?.documentStatus == "rejected") {
									// 	color = '#FF3D60';
									// 	text = 'Recusado';
									// }

									// if (this.state?.accountVerification?.documentStatus == "general_rejected") {
									// 	color = '#FF3D60';
									// 	text = 'Recusado';
									// }

									// if (this?.state?.data?.verified) {
									// 	color = '#1CBB8C';
									// 	text = 'Aprovado';
									// }

									return (
										<div className='boxInformation'>
											<p>{title == 'Documentos de identificação' ? 'Doc. de identificação' : title}</p>
											<div className='tag' style={{ backgroundColor: color }}>{text}</div>
										</div>
									)
								})}

								{this?.state?.onboardingUrl && (
									<div className='boxInformation'>
										<p>Envio de documentos</p>
										<a href={this?.state?.onboardingUrl}>{this?.state?.onboardingUrl}</a>
									</div>
								)}
							</div>

							<div className='footerAssets'>
								<a className='btnSecundary' href={`/users/edit/${this.state.data?.id}`}>
									Alterar senha
									<FontAwesomeIcon icon={faUnlockKeyhole} />
								</a>

								{/* <button className='btnSecundary'>
									CRM
									<FontAwesomeIcon icon={faArrowUpRightFromSquare} />
								</button> */}

								{this.state?.requestUser?.roles?.includes('external_auth') ?
									<button className='btnSecundary' onClick={() => { this.externalAuthRedirect() }}>
										Acessar conta
										<FontAwesomeIcon icon={faUserUnlock} />
									</button> : null}
							</div>
						</div>

						<div className='box'>
							<Nav tabs className="nav-tabs-custom" role="tablist">
								<NavItem>
									<NavLink id="info-tab" className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggleTab('1'); }}>
										Cadastro
									</NavLink>
								</NavItem>

								<NavItem>
									<NavLink id="announcements-tab" className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggleTab('2'); }}>
										<div className='tabName'>Imóvel
											<div className='indicadorUsuarios'>
												{(
													Number(this.DataTableUserAnnouncementsRef?.current?.state?.totalRecords) +
													Number(this.DataTableUserAnnouncementsNegotiationRef?.current?.state?.totalRecords) +
													Number(this.DataTableUserContractsRef?.current?.state?.totalRecords)
												) ?? 0}
											</div>
										</div>
									</NavLink>
								</NavItem>

								<NavItem>
									<NavLink id="negotiations-tab" className={classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.toggleTab('3'); }}>
										<div className='tabName'>Histórico
											<div className='indicadorUsuarios'>
												{(
													Number(this.DataTableUsersHistoricoFinanceiroRef?.current?.state?.totalRecords) +
													Number(this.dataTableLogsUsuarioRef?.current?.state?.totalRecords)
												) ?? 0}
											</div>
										</div>
									</NavLink>
								</NavItem>

								<NavItem>
									<NavLink id="negotiations-tab" className={classnames({ active: this.state.activeTab === '4' })} onClick={() => { this.toggleTab('4'); }}>
										<div className='tabName'>Afiliado
											<div className='indicadorUsuarios'>
												{this.state.data?.verified ? ((
													Number(this.DataTableHistoricoRef?.current?.state?.totalRecords) +
													Number(this.dataTableIndicadoRef?.current?.state?.totalRecords) +
													Number(this.dataTableContratosRef?.current?.state?.totalRecords)
												) ?? 0) : 0}
											</div>
										</div>
									</NavLink>
								</NavItem>
							</Nav>

							<BetterCircularProgress loading={this.state.loading}>
								<TabContent activeTab={this.state.activeTab}>
									<TabPane tabId="1" role="tabpanel">
										<h2>Informações cadastrais</h2>

										<div className='boxInfos-container'>
											{this?.state?.data?.type_account == 'PJ' ?
												<BoxInfo title="EMPRESA" noTag href={`/users/edit/${this.state.data?.id}?tab=1`} classNameBody="dadosPessoaisInfo">
													<div className='boxInformationRow'>
														<div className='boxInformation'>
															<p>CNPJ</p>
															<b>{this.state.data?.cnpj ? formatCNPJ(this.state.data?.cnpj) : '-'}</b>
														</div>

														<div className='boxInformation'>
															<p>Nome fantasia</p>
															<b>{this.state.data?.name ?? '-'}</b>
														</div>
													</div>

													<div className='boxInformationRow' >
														<div className='boxInformation'>
															<p>Razão social</p>
															<b>{this.state?.data?.razao_social ?? '-'}</b>
														</div>
													</div>

													<div className='boxInformationRow' style={{ gap: 8 }}>
														<div className='boxInformation'>
															<p>Email</p>
															<b>{this?.state?.data?.email}</b>
														</div>

														<div className='tag' style={!this.state.data?.email_confirmed ? { backgroundColor: '#FCB92C' } : {}}>
															{this.state.data?.email_confirmed ? "Verificado" : "Não Verificado"}
														</div>
													</div>

													<div className='boxInformationRow' style={{ gap: 8 }}>
														<div className='boxInformation'>
															<p>Telefone</p>
															<b>{!!this?.state?.data?.phone ? formatTelefone(this?.state?.data?.phone ?? 0) : 'Sem telefone'}</b>
														</div>

														<div className='tag' style={!this.state.data?.phone_confirmed ? { backgroundColor: '#FCB92C' } : {}}>
															{this.state.data?.phone_confirmed ? "Verificado" : "Não Verificado"}
														</div>
													</div>
												</BoxInfo>
												:
												<BoxInfo title="DADOS PESSOAIS" noTag href={`/users/edit/${this.state.data?.id}?tab=1`} classNameBody="dadosPessoaisInfo">
													<div className='boxInformationRow'>
														<div className='boxInformation'>
															<p>Nome completo</p>
															<b>{this?.state?.data?.name}</b>
														</div>

														<div className='boxInformation'>
															<p>CPF</p>
															<b>{this?.state?.data?.cpf ? formatCPF(this?.state?.data?.cpf) : '-'}</b>
														</div>
													</div>

													<div className='boxInformationRow' style={{ gap: 8 }}>
														<div className='boxInformation'>
															<p>Email</p>
															<b>{this?.state?.data?.email}</b>
														</div>

														<div className='tag' style={!this.state.data?.email_confirmed ? { backgroundColor: '#8590A5' } : {}}>
															{this.state.data?.email_confirmed ? "Verificado" : "Não Verificado"}
														</div>
													</div>

													<div className='boxInformationRow' style={{ gap: 8 }}>
														<div className='boxInformation'>
															<p>Telefone</p>
															<b>{!!this?.state?.data?.phone ? formatTelefone(this?.state?.data?.phone ?? 0) : 'Sem telefone'}</b>
														</div>

														<div className='tag' style={!this.state.data?.phone_confirmed ? { backgroundColor: '#8590A5' } : {}}>
															{this.state.data?.phone_confirmed ? "Verificado" : "Não Verificado"}
														</div>
													</div>
												</BoxInfo>
											}

											<BoxInfo title="ENDEREÇO" preenchido={enderecoPreenchido} href={`/users/edit/${this.state.data?.id}?tab=2`}>
												<div className='boxInformationRow'>
													<div className='boxInformation'>
														<p>Endereço</p>
														<b>{this?.state?.data?.address?.street}</b>
													</div>
												</div>

												<div className='boxInformationRow'>
													<div className='boxInformation'>
														<p>Número</p>
														<b>{this?.state?.data?.address?.number}</b>
													</div>

													<div className='boxInformation'>
														<p>Complemento</p>
														<b>{this?.state?.data?.address?.complement}</b>
													</div>
												</div>

												<div className='boxInformationRow'>
													<div className='boxInformation'>
														<p>Bairro</p>
														<b>{this?.state?.data?.address?.neighbourhood}</b>
													</div>

													<div className='boxInformation'>
														<p>Cidade</p>
														<b>{this?.state?.data?.address?.city}</b>
													</div>
												</div>
											</BoxInfo>

											<BoxInfo title="RENDA" preenchido={rendaPreenchida} href={`/users/edit/${this.state.data?.id}?tab=3`}>
												{/* <div className='boxInformationRow'>
													<div className='boxInformation'>
														<p>Data de preenchimento</p>
														<b>24/08/2021 16:42:51</b>
													</div>
												</div> */}

												<div className='boxInformationRow'>
													<div className='boxInformation'>
														<p>Origem da renda</p>
														<b>{this.getOrigensRendaText(this?.state?.data?.employment?.type)}</b>
													</div>

													<div className='boxInformation'>
														<p>Valor da renda</p>
														<b>{formatBRL(this?.state?.data?.employment?.gross_income ?? 0)}</b>
													</div>
												</div>
											</BoxInfo>

											<BoxInfo title="DADOS BANCÁRIOS" preenchido={dadosBancariosPreenchido} href={`/users/edit/${this.state.data?.id}?tab=4`}>
												<div className='boxInformationRow'>
													<div className='boxInformation'>
														<p>Tipo de transação</p>
														<b>{this?.state?.accountBankData?.pix_key ? 'PIX' : 'Transferência Bancária'}</b>
													</div>
												</div>

												<div className='boxInformationRow' style={{ gap: 8 }}>
													<div className='boxInformation'>
														<p>Em nome de</p>
														<b>{(this?.state?.accountBankData?.is_owner ? this?.state?.data?.name : this?.state?.accountBankData?.third_party_name ?? '-')}</b>
													</div>

													{this?.state?.accountBankData?.is_owner &&
														<div className='tag' style={{ backgroundColor: '#5664D2' }}>
															Mesmo que o usuário
														</div>
													}

												</div>

												<div className='boxInformationRow'>
													<div className='boxInformation'>
														<p>Chave PIX</p>
														<b>{this?.state?.accountBankData?.pix_key ?? '-'}</b>
													</div>
												</div>

												<div className='boxInformationRow'>
													<div className='boxInformation'>
														<p>Banco</p>
														<b>{this.getBankName(this?.state?.accountBankData?.bank)}</b>
													</div>
												</div>

												<div className='boxInformationRow'>
													<div className='boxInformation'>
														<p>Agência</p>
														<b>{this?.state?.accountBankData?.agency ?? '-'}</b>
													</div>

													<div className='boxInformation'>
														<p>Conta</p>
														<b>{this?.state?.accountBankData?.account ?? '-'}</b>
													</div>

													{this?.state?.accountBankData?.account &&
														<div className='tag' style={{ backgroundColor: '#5664D2' }}>
															{this?.state?.accountBankData?.account_type == 'checking' ? 'Conta corrente' : 'Conta poupança'}
														</div>
													}
												</div>
											</BoxInfo>
										</div>

										<h2>Permissões</h2>

										<div className='boxInfos-container' style={{ marginBottom: '16px', width: '100%', gridTemplateColumns: '1fr' }}>
											<BoxInfo title="VERIFICAÇÃO DE PERFIL" noTag href={`/users/edit/${this.state.data?.id}?tab=5`} iniciado={this.state.accountVerification} preenchido={this?.state?.data?.verified} style={{ width: '100%' }}>
												<div className='boxInformationRow'>
													<div className='boxInformation'>
														<p>Tipo da conta</p>
														<b>{this?.state?.data?.cpf && this.state?.data?.type_account !== 'PJ' ? 'Pessoa fisíca (PF)' : this?.state?.data?.cnpj ? 'Pessoa jurídica (PJ)' : '--'}</b>
													</div>

													<div className='boxInformation'>
														<p>{this?.state?.data?.cpf && this.state?.data?.type_account !== 'PJ' ? 'Nome completo do usuário' : this?.state?.data?.cnpj ? 'Nome fantasia' : '--'}</p>
														<b>{this?.state?.data?.name}</b>
													</div>

													<div className='boxInformation'>
														<p>{this?.state?.data?.cpf && this.state?.data?.type_account !== 'PJ' ? 'CPF' : this?.state?.data?.cnpj ? 'CNPJ' : '--'}</p>
														<b>{this.getCpfOrCnpj()}</b>
													</div>

													<div className='boxInformation'>
														<p>{this?.state?.data?.cpf && this.state?.data?.type_account !== 'PJ' ? 'Valor da renda' : this?.state?.data?.cnpj ? 'Faturamento médio mensal' : '--'}</p>
														<b>{this?.state?.data?.employment?.gross_income ? formatBRL(this?.state?.data?.employment?.gross_income ?? 0) : 'Não informado'}</b>
													</div>

													{this?.state?.data?.cpf && this.state?.data?.type_account !== 'PJ' ? (
														<div className='boxInformation'>
															<p>Data de nascimento</p>
															<b>{this.state?.data?.birthdate ? moment(this.state?.data?.birthdate).add(3, 'hours').format('DD/MM/YYYY') : 'Não preenchido'}</b>
														</div>
													) : this?.state?.data?.cnpj ? (
														<div className='boxInformation'>
															<p>Tipo de empresa</p>
															<b>{this.state?.accountVerification?.data?.companyType ? this.getCompanyFriendlyName(this.state?.accountVerification?.data?.companyType) : 'Não encontrado'}</b>
														</div>
													) : null}

												</div>

												<div className='boxInformationRow' style={{ gap: 8 }}>

												</div>

												<div className='boxInformationRow'>
													{this?.state?.document?.data?.map(({ title, status }) => {
														let color = ''
														let text = ''

														switch (status) {
															case 'NOT_SENT':
																color = '#FCB92C';
																text = 'Não enviado';
																break;

															case 'REJECTED':
																color = '#FF3D60';
																text = 'Recusado';

																break;
															case 'PENDING':
																color = '#FCB92C';
																text = 'Em análise';

																break;
															case 'APPROVED':
																color = '#1CBB8C';
																text = 'Aprovado';

																break;
															default:
																color = '#8590A5';
																text = 'Não iniciada'

																break;
														}

														return (
															<div className='boxInformation'>
																<p>{title == 'Documentos de identificação' ? 'Doc. de identificação' : title}</p>
																<div className='tag' style={{ backgroundColor: color }}>{text}</div>
															</div>
														)
													})}

													<div className='boxInformation'>
														<p>Envio de documentos</p>
														<a href={this?.state?.onboardingUrl} style={{ color: '#4AA3FF', fontFamily: 'Inter', fontSize: '13px' }}>{this?.state?.onboardingUrl ?? 'Url não encontrada'} 	<FontAwesomeIcon icon={faLink} /></a>
													</div>
												</div>

												{this?.state?.document?.rejectReasons ? <div className='boxInformation' style={{ marginTop: '20px' }}>
													<p style={{ color: '#343A40', fontFamily: 'Inter', fontSize: '13px' }}>Motivo da recusa:</p>
													<p style={{ color: '#505D69', fontFamily: 'Inter', fontSize: '13px' }}>{this?.state?.document?.rejectReasons}</p>
												</div> : null}
											</BoxInfo>
										</div>

										<div className='boxInfos-container' style={{ width: '100%', gridTemplateColumns: '1fr' }}>
											<BoxInfo title="ADMIN MELLRO" noTag href={`/users/edit/${this.state.data?.id}?tab=5`} style={{ width: '100%' }}>
												<div className='boxInformationRow' style={{ gap: 8 }}>
													<div className='boxInformation'>
														<p>Permissão</p>

														<div className='tag' style={!isAdmin ? { backgroundColor: '#8590A5' } : { backgroundColor: '#5664D2' }}>
															{isAdmin ? 'Ativo' : 'Inativo'}
														</div>
													</div>

													<p className='detailAdmin'>O usuário poderá fazer login, acessar e editar registros na plataforma Admin da Mellro</p>
												</div>
											</BoxInfo>
										</div>
									</TabPane>

									<TabPane tabId="2" role="tabpanel">
										<Col md={12}>
											<div className='datatable-box' style={{ marginTop: 32 }}>
												<DataTableUserAnnouncements noDelete fetchEvent={this.fetchAnnouncementsProperties} crudButtons={true}
													crudUrl="/announcements" idRow={`id`} noShow titleTable={'Imóveis anunciados'} ref={this.DataTableUserAnnouncementsRef} />
											</div>
										</Col>

										<Col md={12}>
											<div className='datatable-box' style={{ marginTop: 32 }}>
												<DataTableUserAnnouncementsNegotiation noDelete fetchEvent={this.fetchPropositionsUser} crudButtons={true} user={this.props.match.params.id}
													crudUrl="/negotiation" idRow={`id`} noShow titleTable={'Negociações'} ref={this.DataTableUserAnnouncementsNegotiationRef} />
											</div>
										</Col>

										<Col md={12}>
											<div className='datatable-box' style={{ marginTop: 32 }}>
												<DataTableUserContracts noDelete fetchEvent={this.fetchContracts} crudButtons={true}
													crudUrl="/negotiation" idRow={`id`} noShow titleTable={'Contratos'} user={this.props.match.params.id} ref={this.DataTableUserContractsRef} />
											</div>
										</Col>
									</TabPane>

									<TabPane tabId="3" role="tabpanel">
										<div style={{ marginBottom: 24 }} className='container-historico'>
											<section>
												Saldo da subconta
												<b style={{ color: '#FF3D60' }}>{formatBRL(this.state.accountVerification?.account_balance ?? 0)}</b>
											</section>
										</div>

										<div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', margin: '32px 0 16px 0' }}>
											<h3 className='title-session'>Pagamentos</h3>
											<div style={{ marginLeft: 8 }} className='indicadorUsuarios'>{this.DataTableUsersHistoricoFinanceiroRef?.current?.state?.totalRecords ?? 0}</div>
										</div>

										<div className='datatable-box'>
											<DataTableUsersHistoricoFinanceiro filters={this.state.filters} fetchEvent={this.fetchHistoricoFinanceiroByFilter} crudUrl="/users" idRow={`id`} ref={this.DataTableUsersHistoricoFinanceiroRef} />
										</div>

										<div className='d-flex '>
											<div style={{ marginBottom: 24, marginRight: 32 }} className='container-historico'>
												<section>
													VALORES ANTECIPADOS PAGOS
													<b style={{ color: '#1CBB8C' }}>{formatBRL(this.state.antecipatedValuesPaid ?? 0)}</b>
												</section>
											</div>

											<div style={{ marginBottom: 24 }} className='container-historico'>
												<section>
													VALORES ANTECIPADOS PENDENTES
													<b style={{ color: '#FE932F' }}>{formatBRL(this.state.antecipatedValuesPending ?? 0)}</b>
												</section>
											</div>
										</div>

										<div className='d-flex justify-content-between align-items-center'>
											<div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', margin: '32px 0 16px 0' }}>
												<h3 className='title-session'>Antecipações</h3>
												<div style={{ marginLeft: 8 }} className='indicadorUsuarios'>{this.state.totalAnticipations}</div>
											</div>

											<div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', margin: '32px 0 16px 0' }}>
												<a className='btnSecundary' href={`/anticipations/new/${this.state.data?.id}`} >
													NOVA ANTECIPAÇÃO
													<img src={newAnticipationIcon} />
												</a>
											</div>
										</div>

										<div className='datatable-box'>
											<DataTableAnticipations filters={this.state.filters} userId={this.props.match.params.id} fetchEvent={getAnticipations} crudUrl="/users" idRow={`id`} ref={this.DataTableAnticipations} setTotalAnticipations={(total) => { this.setState({ totalAnticipations: total }) }} />
										</div>

										<div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', margin: '32px 0 16px 0' }}>
											<h3 className='title-session'>Notificações</h3>
											<div style={{ marginLeft: 8 }} className='indicadorUsuarios'>{this.DataTableUsersNotificacoesRef?.current?.state?.totalRecords ?? 0}</div>
										</div>

										<div className='datatable-box'>
											<DataTableUsersNotificacoes filters={this.state.filters} fetchEvent={this.fetchUserNotification} crudUrl="/notifications" idRow={`id`} ref={this.DataTableUsersNotificacoesRef} />
										</div>

										<div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', margin: '32px 0 16px 0' }}>
											<h3 className='title-session'>Comentários</h3>
											<div style={{ marginLeft: 8 }} className='indicadorUsuarios'>{this.dataTableLogsUsuarioRef?.current?.state?.totalRecords ?? 0}</div>
										</div>

										<div style={{ marginTop: 24, marginBottom: 8, padding: 24, gap: 36, borderRadius: 8, backgroundColor: '#fff', border: '1px solid #DADBDD' }}>
											<div style={{ gap: 12 }}>
												<h4 style={{ color: '#343A40', fontFamily: 'Inter', fontSize: 13 }}>Adicionar comentário</h4>
											</div>

											<div style={{ display: 'flex', gap: 24 }}>
												<div className='d-flex' style={{ flex: 2 }}>
													<TextField style={{ backgroundColor: '#FFF', flex: 1 }} type="text" variant="outlined" value={this.state.comentario}
														placeholder='Escreva' onChange={e => this.setState({ comentario: e.target.value })} />

													<button style={{ height: '41.09px', marginLeft: 8 }} onClick={this.handleSendComentarioLog} disabled={this.state.comentario == ''} className="btnSecundary">
														Adicionar
													</button>
												</div>

												<div className='d-flex' style={{ alignItems: 'center' }}>
													<p style={{ color: '#505D69', fontFamily: 'Inter', fontSize: 13, margin: 0 }}>Comentários adicionados não podem ser excluídos ou editados</p>
												</div>
											</div>
										</div>

										<div className='datatable-box'>
											<DataTableLogsUsuario ref={this.dataTableLogsUsuarioRef} filters={this.state.filters} fetchEvent={this.fetchUsuarioAdminLogs} crudUrl="/contracts" idRow={`id`} />
										</div>

										<div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', margin: '32px 0 16px 0' }}>
											<h3 className='title-session'>Documentos fiscais</h3>
											<div style={{ marginLeft: 8 }} className='indicadorUsuarios'>{this.dataTableFiscalDocsRef?.current?.state?.totalRecords ?? 0}</div>
										</div>

										{this.state?.data?.type_account == 'PJ' && (
											<div style={{ marginTop: 24, marginBottom: 8, padding: 24, gap: 36, borderRadius: 8, backgroundColor: '#F1F5F7', border: '1px solid #DADBDD' }}>
												<div style={{ gap: 12 }}>
													<h4 style={{ color: '#343A40', fontFamily: 'Inter', fontSize: 13 }}>CPF do representante legal</h4>
												</div>

												<div style={{ display: 'flex', gap: 24 }}>
													<div className='d-flex' style={{ flex: 2, maxWidth: 240 }}>
														<TextField style={{ backgroundColor: '#FFF', flex: 1 }} type="text" variant="outlined" value={this.state.cpfEmpresa}
															placeholder='000.000.000-00' onChange={e => this.setState({ cpfEmpresa: formatCPF(e.target.value) })} />
													</div>

													<div className='d-flex' style={{ alignItems: 'center', maxWidth: 336 }}>
														<p style={{ color: '#505D69', fontFamily: 'Inter', fontSize: 13, margin: 0 }}>Para realizar o download do Dimob, informe o CPF do representante legal do CNPJ verificado deste usuário.</p>
													</div>
												</div>
											</div>
										)}

										<div className='datatable-box'>
											<DataTableFiscalDocs ref={this.dataTableFiscalDocsRef} filters={this.state.filters} handleSetErrors={this.handleSetErrors} cpfEmpresa={this.state.cpfEmpresa} userId={this.props.match.params.id} user={this.state.data} fetchEvent={this.fetchFiscalDocuments} crudUrl="/contracts" idRow={`id`} />
										</div>
									</TabPane>

									<TabPane tabId="4" role="tabpanel">
										{this.state.data?.verified ?
											<div className='afiliado'>
												<div className='caixaAfiliados'>
													<section>
														<label>Porcentagem do afiliado</label>

														<div className='d-flex'>
															<TextField style={{ width: 388 }} type="text" variant="outlined" value={this.state.porcentagemAfiliado}
																onChange={e => this.setState({ porcentagemAfiliado: allowOnlyPercentageInput(e) })}
																InputProps={{ endAdornment: <InputAdornment position='end'>%</InputAdornment> }} />

															<button onClick={() => this.handleChangeRepasse(null, 'porcentagem')}
																disabled={this.state.porcentagemAfiliado > 50 || this.state.porcentagemAfiliado < 0.1}
																className="buttonPrimary" style={{ height: '41.02px', marginLeft: 12 }}>
																Salvar <FontAwesomeIcon icon={faCheck} />
															</button>
														</div>

														<p className='erro-porcentagem-afiliado'>
															{this.state.porcentagemAfiliado > 50 ? 'A porcentagem não pode ser maior que 50%' : null}
															{this.state.porcentagemAfiliado < 0.1 ? 'A porcentagem não pode ser menor que 0,1%' : null}
														</p>

														<p className='details-link mt-3'>
															A porcentagem é referente ao valor da garantia da Mell.ro.
														</p>
													</section>

													<section>
														<label>Recorrência</label>

														<Label className="w-100 input-radio-group" style={{ paddingLeft: 20 }}>
															<Input type="radio" name={'recorrencia'} id={`recorrencia-1`} value={false} onChange={() => { this.handleChangeRepasse(false, 'recorrencia'); this.setState({ recorrencia: false }) }} color="primary" checked={["false", false].includes(this.state.recorrencia)} />
															<Label style={{ padding: "3px" }} for={`recorrencia-1`}>
																<b>Sem recorrência:</b> o afiliado recebe o valor da porcentagem apenas do primeiro pagamento.
															</Label>
														</Label>

														<Label className="w-100 input-radio-group" style={{ paddingLeft: 20 }}>
															<Input type="radio" name={'sem-recorrencia'} id={`recorrencia-2`} value={true} onChange={() => { this.handleChangeRepasse(true, 'recorrencia'); this.setState({ recorrencia: true }) }} color="primary" checked={["true", true].includes(this.state.recorrencia)} />
															<Label style={{ padding: "3px" }} for={`recorrencia-2`}>
																<b>Com recorrência:</b> o afiliado recebe o valor da porcentagem todos os meses que o inquilino pagar o aluguel.
															</Label>
														</Label>
													</section>

													<section>
														<label>Período:</label>

														<Label className="w-100 input-radio-group" style={{ paddingLeft: 20 }}>
															<Input type="radio" name={'periodo'} id={`periodo-1`} value={'12_MONTHS'} onChange={() => { this.handleChangeRepasse('12_MONTHS', 'periodo'); this.setState({ periodo: '12_MONTHS' }) }} color="primary" checked={this.state.periodo == '12_MONTHS'} />
															<Label style={{ padding: "3px" }} for={`periodo-1`}>
																<b>12 meses:</b>  Recebe a comissão configurada por 12 meses do cadastro do indicado.
															</Label>
														</Label>

														<Label className="w-100 input-radio-group" style={{ paddingLeft: 20 }}>
															<Input type="radio" name={'sem-periodo'} id={`periodo-2`} value={'FULL_CONTRACT'} onChange={() => { this.handleChangeRepasse('FULL_CONTRACT', 'periodo'); this.setState({ periodo: 'FULL_CONTRACT' }) }} color="primary" checked={this.state.periodo == 'FULL_CONTRACT'} />
															<Label style={{ padding: "3px" }} for={`periodo-2`}>
																<b>Todo contrato:</b> Recebe a comissão configurada por todos os contratos fechados do usuário sem prazo de término.
															</Label>
														</Label>
													</section>
												</div>

												<div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', margin: '32px 0 16px 0' }}>
													<h3 className='title-session'>Indicadores</h3>
												</div>

												<div style={{ marginBottom: 24 }} className='container-historico'>
													<section>
														Saldo total
														<b>{formatBRL(this.state?.affiliateBalance?.balance ?? 0)}</b>
													</section>

													<section>
														Indicados
														<b>{this?.state?.totalIndicados ?? 0}</b>
													</section>

													<section>
														Contratos ativos
														<b>{this?.state?.totalContratosAtivos ?? 0}</b>
													</section>

													<section>
														Link de afiliado
														<b style={{ cursor: 'pointer', color: '#4AA3FF', fontSize: 13 }} onClick={this.handleCopyClipboard}>
															{this.state.data?.affiliate_link ?? ''}
														</b>
													</section>
												</div>

												<div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', margin: '32px 0 16px 0' }}>
													<h3 className='title-session'>Histórico de conversão</h3>
													<div style={{ marginLeft: 8 }} className='indicadorUsuarios'>{this.DataTableHistoricoRef?.current?.state?.totalRecords ?? 0}</div>
												</div>

												<DataTableHistorico filters={this.state.filters} noDelete fetchEvent={transactionsAfiliado} crudButtons={true}
													crudUrl="/user/show" idRow={`id`} user_id={this.props.match.params.id} ref={this.DataTableHistoricoRef} />

												<div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', margin: '32px 0 16px 0' }}>
													<h3 className='title-session'>Indicados</h3>
													<div style={{ marginLeft: 8 }} className='indicadorUsuarios'>{this.dataTableIndicadoRef?.current?.state?.totalRecords ?? 0}</div>
												</div>

												<div className='adicionar-indicado-box'>
													<div>
														<label>Adicionar indicado</label>

														<div className='d-flex'>
															<UserFilterComponent onChange={(e, value) => { this.setState({ newIndicado: value }); }} value={this.state.newIndicado} />

															<button style={{ height: '41.09px', marginLeft: 8 }} onClick={() => this.handleNewIndicado(this.state.newIndicado)} className="btnSecundary">
																Adicionar
															</button>
														</div>
													</div>

													<p className='details-link mt-3' style={{ textAlign: 'end' }}>
														O Afiliado vai receber sua comissão configurada a partir de novos contratos<br /> fechados pelo indicado adicionado
													</p>
												</div>

												<DataTableIndicado filters={this.state.filters} noDelete fetchEvent={getByFilterUsersAffiliate} crudButtons={true} handleSetErrors={this.handleSetErrors}
													crudUrl="/user/show" idRow={`id`} handleSetIndicados={this.handleSetIndicados} user_id={this.props.match.params.id} ref={this.dataTableIndicadoRef}
												/>

												<div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', margin: '32px 0 16px 0' }}>
													<h3 className='title-session'>Contratos</h3>
													<div style={{ marginLeft: 8 }} className='indicadorUsuarios'>{this.state.totalContratosAtivos ?? 0}</div>
												</div>

												<DataTableContrato filters={this.state.filters} noDelete fetchEvent={getContractAffiliate} crudButtons={true}
													crudUrl="/user/show" idRow={`id`} handleSetContratosAtivos={this.handleSetContratosAtivos} user_id={this.props.match.params.id} ref={this.dataTableContratosRef} />
											</div>
											:
											<div className='afiliado'>
												<p className='notAfiliado'>O usuário precisa ter seu perfil verificado como aprovado para poder começar a usar o programa de afiliados.</p>
											</div>
										}
									</TabPane>
								</TabContent>
							</BetterCircularProgress>
						</div>
					</Container>
				</div>
			</BetterCircularProgress>
		);
	}
}

export default UsersShow;