import React, { Component } from 'react';
import { Container, Card, CardBody, Col, FormGroup, Label, Input } from "reactstrap";
import BetterCircularProgress from '../../components/BetterCircularProgress';
import { getJob } from '../../crud/jobs.crud';
import { Link } from "react-router-dom";
import Breadcrumbs from '../../components/Breadcrumb';
import { TextareaAutosize } from '@material-ui/core';

class ContractsShow extends Component {

    state = {
        loading: true,
        data: {},
    };

    jobComponents = [];
    targetComponents = [];

    componentDidMount = () => {
        this.setState({ loading: true });
        getJob(this.props.match.params).catch(err => {
            alert('An error occurred, please contact the support.');
        }).then(async res => {
            if (res?.status == 200) {
                this.setState({ data: res.data[0] });
                this.objectToComponentsJob(res.data[0]);
                this.setState({ loading: false });
            }
        });
    };

    objectToComponentsJob = (object) => {
        let i = 0;
        for (const [key, value] of Object.entries(object)) {
            if (key === 'targets') {
                this.objectToComponentsTarget(value);
                continue;
            }
            i++
            this.jobComponents.push(
                <Col md={6} key={i} >
                    <FormGroup row>
                        <Label className="col-md-4 col-form-label">{key.charAt(0).toUpperCase() + key.slice(1)}</Label>
                        <Col md={8} className="container-center-input">
                            <Input className="form-control" type="text" value={value} disabled />
                        </Col>
                    </FormGroup>
                </Col >);

        }
    };

    objectToComponentsTarget = (array) => {
        for (let i = 0; i < array.length; i++) {
            this.targetComponents.push(
                <Col key={i} >
                    <Label className="col-md-4 col-form-label">{i + '-Target'}</Label>
                    <Col className="container-center-input">
                        <TextareaAutosize className="form-control mb-30" type="text" defaultValue={JSON.stringify(array[0], null, 4)} disabled />
                    </Col>
                </Col >);
        }
    };

    render() {
        return (
            <React.Fragment>
                <BetterCircularProgress loading={this.state.loading}>
                    <div className="page-content container-page">
                        <Container fluid>
                            <Breadcrumbs title="Visualizar Detalhes do Job" breadcrumbItems={[]} />
                            <Card>
                                <CardBody>
                                    <div className="row">
                                        {
                                            (!this.state.loading) ?
                                                this.jobComponents
                                                : null
                                        }
                                    </div>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <div>
                                        {
                                            (!this.state.loading) ?
                                                this.targetComponents
                                                : null
                                        }
                                    </div>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody className="container-space-between">
                                    <Link to='/crawlerConfig/index' className="btn btn-danger btn-bold"><i className="fa fa-arrow-left mr-2"></i>Voltar</Link>
                                </CardBody>
                            </Card>
                        </Container>
                    </div>
                </BetterCircularProgress>
            </React.Fragment>
        );
    }
}

export default ContractsShow;