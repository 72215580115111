import React, { Component } from 'react';
import { Container, Card, CardBody, Row, Col, Button, ButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, Label, FormGroup, Input } from "reactstrap";
import Breadcrumbs from '../../../components/Breadcrumb';
import { getAllByParams } from '../../../crud/negativacao.crud';
import BetterAlerts from '../../../components/BetterAlerts';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { UserFilterComponentObsolete } from '../../negotiation/components/user.component';
import { formatBRL } from '../../../helpers/Utils';
import DataTableNegativacao from '../../../components/datatables/DataTableNegativacao';

class NegativacaoIndex extends Component {

	state = {
		filters: {
			landlord: {},
			tenant: {},
		},
		flags: {
			show_landlord: false,
			show_tenant: false,
			show_status: false,
		},
		is_filter_open: false,
		export: false
	};

	componentDidMount = () => {
		let filters = this.state.filters;
		let flags = this.state.flags;

		if (localStorage.getItem('cobrancaNegativacaoFilters')) {
			filters = JSON.parse(localStorage.getItem('cobrancaNegativacaoFilters'));
		};

		if (localStorage.getItem('cobrancaNegativacaoFlags')) {
			flags = JSON.parse(localStorage.getItem('cobrancaNegativacaoFlags'));
		};

		if (this.props.location.state) {
			if (this.props.location.state.success) {
				this.setState({ success: this.props.location.state.success });
			}
		}

		this.setState({ filters, flags });
	};

	saveFilters = () => {
		localStorage.setItem('cobrancaNegativacaoFilters', JSON.stringify(this.state.filters));
		localStorage.setItem('cobrancaNegativacaoFlags', JSON.stringify(this.state.flags));
	};

	fetchByFilter = (data) => {
		var params = {
			...data,
			landlord_id: this.state.flags.show_landlord ? this.state.filters.landlord?.id : undefined,
			user_id: this.state.flags.show_tenant ? this.state.filters.tenant?.id : undefined,
			status: this.state.flags.show_status ? this.state.filters.status : undefined,
		};

		this.saveFilters();
		return getAllByParams(params);
	};

	render() {
		return (
			<React.Fragment>
				<div className="page-content container-page">
					<Container fluid>
						<Breadcrumbs breadcrumbItems={[{ link: '/pagamento/negativacao/index', title: 'Pagamentos' }, { link: '/pagamento/negativacao/index', title: 'Negativação' }]} />

						<Row className="align-right mb-30">
							<ButtonDropdown className="mr-10 wm-100" isOpen={this.state.is_filter_open} toggle={() => this.setState({ is_filter_open: !this.state.is_filter_open })} >
								<DropdownToggle color="primary" caret>
									<i className="ri-filter-2-fill align-middle mr-2"></i> {"ADD FILTRO"}
								</DropdownToggle>
								<DropdownMenu className="col-sm btn-index-action dropdown-menu-right">
									{!this.state.flags.show_landlord ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_landlord: true } })}>Proprietário</DropdownItem> : null}
									{!this.state.flags.show_tenant ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_tenant: true } })}>Inquilino</DropdownItem> : null}
									{!this.state.flags.show_status ? <DropdownItem onClick={() => this.setState({ flags: { ...this.state.flags, show_status: true } })}>Status</DropdownItem> : null}
								</DropdownMenu>
							</ButtonDropdown>
						</Row>

						<BetterAlerts errors={this.state.errors} success={this.state.success} />
						<Row className="container-center-input">
							{this.state.flags.show_landlord ?
								<UserFilterComponentObsolete label="Proprietário" onChange={(e, value) => { this.setState({ filters: { ...this.state.filters, landlord: value } }); this.saveFilters(); }} value={this.state.filters.landlord}
									onClick={() => this.setState({ flags: { ...this.state.flags, show_landlord: false }, filters: { ...this.state.filters, landlord: {} } },
										() => this.saveFilters())} />
								: false
							}

							{this.state.flags.show_tenant ?
								<UserFilterComponentObsolete label="Inquilino" onChange={(e, value) => { this.setState({ filters: { ...this.state.filters, tenant: value } }); this.saveFilters(); }} value={this.state.filters.tenant}
									onClick={() => this.setState({ flags: { ...this.state.flags, show_tenant: false }, filters: { ...this.state.filters, tenant: {} } }, () => this.saveFilters())} />
								: false
							}

							{this.state.flags.show_status ?
								<Col md={4}>
									<Label className="col-form-label" style={{ color: '#343A40' }}>
										Status
									</Label>
									<FormGroup row>
										<Col>
											<select value={this.state.filters.status} onChange={(e) => this.setState({ filters: { ...this.state.filters, status: e.target.value } }, () => this.saveFilters())} className="custom-select">
												<option value=""></option>
												<option value="PENDING">Pendente</option>
												<option value="AWAITING_APPROVAL">Aguardando aprovação</option>
												<option value="AWAITING_CANCELLATION">Aguardando cancelamento</option>
												<option value="PROCESSED">Em negativação</option>
												<option value="PAID">Recuperado</option>
												<option value="PARTIALLY_PAID">Pagamento iniciado</option>
												<option value="DENIED">Negado</option>
												<option value="CANCELLED">Cancelada</option>
											</select>
										</Col>
										<Button onClick={() => this.setState({ flags: { ...this.state.flags, show_status: false }, filters: { ...this.state.filters, plan: '' } }, () => this.saveFilters())} color="light" type="button" className="waves-effect waves-light btn-rounded btn-close-input">
											<i className="ri-close-circle-line align-middle"></i>
										</Button>
									</FormGroup>
								</Col>
								: false
							}
						</Row>

						<Row className="container-center-input">
							<Col lg={12}>
								<Card>
									<CardBody>
										<DataTableNegativacao filters={this.state.filters} noDelete fetchEvent={this.fetchByFilter} crudButtons={true}
											crudUrl="/pagamento/negativacao/index" idRow={`id`} export={this.state.export} />
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}

export default NegativacaoIndex;