import React, { Component } from 'react';
import { Col, Label, Input } from "reactstrap";

export class RadioComponent extends Component {
  render() {
    const { onBlur, value, onChange, name, disabled, label } = this.props;
    return (
      < React.Fragment >
        <Col md={12}>
            <Label className="col-form-label" style={{ color: "#343A40" }}>
              {label}
            </Label>
            <Col md={12}>
                <Label className="pl-2 w-100 input-radio-group">
                  <Input
                    disabled={disabled}
                    type="radio"
                    name={name}
                    id={`${name}-1`}
                    value={false}
                    onChange={onChange}
                    onBlur={onBlur}
                    color="primary"
                    checked={["false", false].includes(value)}
                  />{" "}
                  <Label
                    style={{ padding: "3px" }}
                    for={`${name}-1`}
                  >
                    Proprietário paga
                  </Label>
                </Label>
                <Label className="pl-2 w-100 input-radio-group">
                  <Input
                    disabled={disabled}
                    type="radio"
                    name={name}
                    id={`${name}-2`}
                    value={true}
                    onChange={onChange}
                    onBlur={onBlur}
                    color="primary"
                    checked={["true", true].includes(value)}
                  />{" "}
                  <Label
                    style={{ padding: "3px" }}
                    for={`${name}-2`}
                  >
                    Inquilino paga
                  </Label>
                </Label>
            </Col>
        </Col>
      </React.Fragment >
    );
  }
}

