import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link } from "react-router-dom";
import moment from "moment";
import { getLaudoTag } from "../../../pages/negotiation/helpers";

export default class DataTableAnaliseNegotiation extends Component {
	constructor() {
		super();

		this.state = {
			property: {},
			data: [],
			rows: 10,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: "created_at",
			sortOrder: -1,
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		let DataTableAnaliseNegotiation = JSON.parse(localStorage.getItem("DataTableAnaliseNegotiation"));

		if (DataTableAnaliseNegotiation) {
			this.setState(
				{
					data: DataTableAnaliseNegotiation.data,
					rows: DataTableAnaliseNegotiation.rows,
					totalRecords: DataTableAnaliseNegotiation.totalRecords,
					first: DataTableAnaliseNegotiation.first,
					last: DataTableAnaliseNegotiation.last,
					sortField: DataTableAnaliseNegotiation.sortField,
					sortOrder: DataTableAnaliseNegotiation.sortOrder,
					loading: false
				},
			);
		} else {
			this.fetchData();
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters !== this.props.filters) this.fetchData();
	}

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = async (event) => {
		this.setState({ loading: true });

		if (event.data) event.data = null;

		let DataTableAnaliseNegotiation = {
			rows: event.rows,
			totalRecords: this.state.totalRecords,
			data: this.data,
			first: event.first,
			last: event.rows + event.first,
			loading: this.state.loading,
			sortField: this.state.sortField,
			sortOrder: this.state.sortOrder,
		};

		localStorage.setItem("DataTableAnaliseNegotiation", JSON.stringify(DataTableAnaliseNegotiation));

		this.setState({ ...this.state, rows: event.rows });
		this.fetchData(event.rows, event.first);
	};

	fetchData = async (limit, skip) => {
		this.setState({ loading: true });

		let options = {
			$limit: limit ? limit : this.state.rows,
			$skip: skip != null ? skip : this.state.first,
			$sort: { [this.state.sortField]: this.state.sortOrder },
		};

		return this.props.fetchEvent(options).then(async (res) => {
			this.setState({ data: res.data.data, totalRecords: res.data.total, first: skip, last: skip + res.data.limit, loading: false });
		});
	};

	sortTable = async (e) => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder, loading: true }, () => {
			let DataTableAnaliseNegotiation = {
				rows: this.state.rows,
				totalRecords: this.state.totalRecords,
				first: this.state.first,
				last: this.state.last,
				loading: this.state.loading,
				sortField: this.state.sortField,
				sortOrder: this.state.sortOrder,
				data: this.state.data
			};
			localStorage.setItem("DataTableAnaliseNegotiation", JSON.stringify(DataTableAnaliseNegotiation));

			this.fetchData();
		});
	};

	columnOpcoesCrud = (rowData) => {
		let idRow = this.props.idRow ? this.props.idRow : 'id';

		if (this.props.crudUrl) {
			return (
				<Link to={`${this.props.crudUrl}/${rowData[idRow]}`} className='showButton'>
					<i className='fa fa-chevron-right' />
				</Link>
			);
		} else {
			return null;
		}
	};

	render() {
		return (
			<DataTable ref={(el) => this.dt = el} value={this.state.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={[10, 25, 50]}
				first={this.state.first} last={this.state.last} responsive={true} totalRecords={this.state.totalRecords} lazy={true} sortField={this.state.sortField}
				loading={this.state.loading} loadingIcon={`fas fa-sync fa-spin`} emptyMessage="Nenhum registro encontrado!" onSort={(e) => this.sortTable(e)}
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" onPage={this.onPage}
				currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" sortOrder={this.state.sortOrder}>
				<Column style={{ width: 138 }} sortable field="created_at" body={(obj, i) => moment(obj.created_at).format('DD/MM/YYYY HH:mm:ss')} header="Requisitado em" />
				<Column style={{ width: 123 }} sortable field="status" header="Status" body={(laudo) => getLaudoTag(laudo)} />
				<Column style={{ width: 777 }} sortable field="fc_laudo.parecer.locacao.parecer_inquilinos.aprovados" header="Mensagem" />

				<Column header="" body={this.columnOpcoesCrud} style={{ width: '34px', padding: 0 }} />
			</DataTable>
		);
	}
}
