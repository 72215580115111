import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { get as getUser } from '../../crud/users.crud';
import { get as getContract } from '../../crud/contracts.crud';
import { formatBRL } from "../../helpers/Utils";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import moment from "moment";
import { Button } from "reactstrap";
import { createRentBoleto, getById as getBillingById } from "../../crud/billings.crud";
import smile from '../../assets/images/icons-custom/face-smile.png';

export default class DataTableExtrajudiciais extends Component {
	constructor() {
		super();

		this.state = {
			property: {},
			data: null,
			rows: 5,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: 'due_date',
			sortOrder: 1,
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		this.fetchData(this.props.rows);

		if (this.props.rows) {
			this.setState({ rows: this.props.rows })
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters !== this.props.filters) {
			this.fetchData();
		}
	}

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = async (event) => {
		this.setState({
			loading: true,

		});
		if (event.data) {
			event.data = null;
		}

		let inadimplenteDataTable = {
			rows: event.rows,
			totalRecords: this.state.totalRecords,
			data: this.data,
			first: event.first,
			last: event.rows + event.first,
			loading: this.state.loading,
			sortField: this.state.sortField,
			sortOrder: this.state.sortOrder,
		};

		localStorage.setItem("inadimplenteDataTable", JSON.stringify(inadimplenteDataTable));

		this.setState({
			...this.state,
			rows: event.rows,
		});
		this.fetchData(event.rows, event.first);
	};

	fetchData = async (limit, skip) => {
		this.setState({ loading: true });

		console.log(limit);

		let options = {
			$limit: limit ? limit : this.state.rows,
			$skip: skip != null ? skip : this.state.first,
			$sort: { ['is_paid']: 1, [this.state.sortField]: this.state.sortOrder },
		};

		return this.props.fetchEvent(options).then(async (res) => {
			if (res.data.data.length == 0) {
				this.setState({
					...this.state,
					data: [],
					totalRecords: res.data.total,
					first: skip,
					last: skip + res.data.limit,
					loading: false
				});
			}

			await res.data.data.map(async (obj, i) => {
				if (obj.contract_id) {
					obj.loading_contract = true;
					await getContract(obj.contract_id).catch(err => {
						obj.contract = null;
						obj.loading_contract = false;
					}).then(res => {
						if (res?.data?.data) {
							obj.contract = res.data.data;
						}
					});
				}

				this.setState({
					...this.state,
					data: res.data.data,
					totalRecords: res.data.total,
					first: skip,
					last: skip + res.data.limit,
					loading: false
				});
			});
		}).finally(() => {
			this.setState({ loading: false });
		});
	};

	sortTable = async (e) => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder, loading: true }, () => {
			let inadimplenteDataTable = {
				rows: this.state.rows,
				totalRecords: this.state.totalRecords,
				first: this.state.first,
				last: this.state.last,
				loading: this.state.loading,
				sortField: this.state.sortField,
				sortOrder: this.state.sortOrder,
				data: this.state.data
			};
			localStorage.setItem("inadimplenteDataTable", JSON.stringify(inadimplenteDataTable));

			this.fetchData();
		});
	};

	getValue = (obj) => {
		return (
			<div >
				<p>{formatBRL(obj || 0)}</p>
			</div>
		);
	};

	getUserName = (obj, type) => {
		if (type === "landlord" && obj?.landlord) {
			return (
				<div style={{ textAlign: "center" }}>
					<Link to={`/users/show/${obj.landlord?.id}`}>
						{obj.landlord?.name}
					</Link>
				</div>
			);
		}

		if (type === "tenant" && obj?.tenant) {
			return (
				<div style={{ textAlign: "center" }}>
					<Link to={`/users/show/${obj.tenant?.id}`}>{obj.tenant?.name}</Link>
				</div>
			);
		}

		if (type === "contract" && obj?.contract) {
			return (
				<div style={{ textAlign: "center" }}>
					<Link to={`/contracts/show/${obj?.contract?.entity?.id}`}>{obj?.contract?.entity?.plan?.friendly_name}</Link>
				</div>
			);
		}

		return (
			<div style={{ textAlign: "center" }}>
				Não encontrado
			</div>
		);
	};

	getCity = (obj) => {
		if (obj?.property) {
			return (
				<div style={{ textAlign: "center" }}>
					<Link to={`/announcements/show/${obj.property?.id}`}>{obj.property?.address?.city}</Link>
				</div>
			);
		} else {
			return (
				<div style={{ textAlign: "center" }}>
					Não encontrado
				</div>
			)
		}
	};

	getNeighbourhood = (obj) => {
		return (
			<div style={{ textAlign: "center" }}>
				<Link to={`/announcements/show/${obj.property?.id}`}>
					{obj.property?.address?.neighbourhood}
				</Link>
			</div>
		);
	};

	getStatus = (obj) => {
		const dueDate = moment(obj.due_date).add(3, "hours").format('YYYYMMDD');
		const currentDate = moment().format('YYYYMMDD');

		if (obj?.receipts?.rent?.paid === true && (obj?.receipts?.rent?.paid_for_insurer === false || !obj?.receipts?.rent?.paid_for_insurer)) {
			return (
				<div className="bg-success d-flex flex-row" style={{ width: '98px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center' }}>
					<p className="col-form-label text-white">{obj?.receipts?.rent?.paid ? 'Fatura paga' : 'N/A'}</p>
				</div>
			)
		}
		if (currentDate > dueDate) {
			return (
				<div className="bg-danger d-flex flex-row" style={{ width: '120px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center' }}>
					<p className="col-form-label text-white">Fatura atrasada</p>
				</div>
			)
		}

		if (currentDate <= dueDate && obj?.boleto !== null) {
			return (
				<div className="bg-warning d-flex flex-row" style={{ width: '131px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center' }}>
					<p className="col-form-label text-white">Fatura em aberto</p>
				</div>
			)
		}

		if (currentDate <= dueDate && obj?.boleto === null) {
			return (
				<div className="bg-secondary d-flex flex-row" style={{ width: '98px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center' }}>
					<p className="col-form-label text-white">Aguardando</p>
				</div>
			)
		}
	};

	getDateFormatted = (date) => {
		return (
			<p>
				{date ? moment(date).add(1, 'day').format("DD/MM/YYYY") : '-'}
			</p>
		);
	};

	getStatusProposition = (obj) => {
		let status = '';
		switch (obj.status) {
			case 'FIRST_PROPOSAL':
				status = 'Criada Admin';
				break;
			case 'AWAITING_SIGN':
				status = 'Aguardando sinal';
				break;
			case 'FINALIZED':
				status = 'Finalizado';
				break;
			default:
				status = obj.status;
				break;
		}
		return (
			<div style={{ textAlign: "center" }}>
				<p>{status}</p>
			</div>
		);
	}

	onBoletoCreation = async (obj) => {
		this.setState({ ...this.state, loading: true, });

		createRentBoleto(obj.id).then((data) => {
			setTimeout(() => {
				getBillingById(obj.id).then((data) => {
					navigator.clipboard.writeText(data.data?.boleto?.pdf_url);
					this.setState({ ...this.state, loading: false, });
				});
			}, 5000);
		}).catch(err => {
			this.setState({ ...this.state, boletoError: true, loading: false, });

			setTimeout(() => {
				this.setState({ ...this.state, boletoError: false, });
			}, 3000);
		}).finally(() => {
			this.setState({ ...this.state, loading: false, });
		})
	}

	columnOptionsCrud = (obj) => {
		if (this.props.crudUrl) {
			return (
				<div style={{ textAlign: "center" }}>
					{obj?.boleto?.pdf_url ?
						<Button href={obj?.boleto?.pdf_url} target="_blank" className="mr-2" style={{ background: 'transparent', borderColor: 'transparent' }} onClick={() => { navigator.clipboard.writeText(obj?.boleto?.pdf_url) }}>
							<i className="far fa-file" style={{ color: '#5664D2' }} />
						</Button>
						: null}

					<Button className="mr-2" style={{ background: 'transparent', borderColor: 'transparent' }} onClick={() => this.onBoletoCreation(obj)}>
						<i className="fas fa-redo" style={{ color: '#5664D2' }} />
					</Button>

					<Link to={`/billing/show/${obj.id}`} className="btn btn-table-action mr-2">
						<i className="fas fa-eye" style={{ color: "#5664D2" }} />
					</Link>
				</div>
			);
		} else {
			return "a";
		}
	};

	export() {
		this.dt.exportCSV();
	}

	emptyMessageReturn() {
		return (
			<div className="boxImage">
				<img src={smile} alt="smile" width={48} height={48} />

				<p className="subtitleNoItems">{this?.props?.type === 'agreement' ? 'Nenhum acordo em inadimplência encontrado' : 'Nenhuma cobrança em inadimplência encontrada'}</p>
			</div>
		)
	}

	getStatusProposition = (obj) => {
		let status = '';
		switch (obj?.details?.warranty_type?.trim()) {
			case 'SECURITY_DEPOSIT':
			case 'SECURITY_DEPOSITY':
				status = 'Caução';
				break;
			case 'FREE':
				status = 'Gratuito';
				break;
			case 'WARRANTY':
				status = 'Garantia';
				break;
			case 'WARRANTY_GUARANTEE_DAMAGE':
				status = 'Garantia de danos';
				break;

			default:
				status = obj?.status;
				break;
		}
		if (!status) {
			switch (obj?.property?.warranty?.type.trim()) {
				case 'SECURITY_DEPOSIT':
				case 'SECURITY_DEPOSITY':
					status = 'Caução';
					break;
				case 'FREE':
					status = 'Gratuito';
					break;
				case 'WARRANTY':
					status = 'Garantia';
					break;
				case 'WARRANTY_GUARANTEE_DAMAGE':
					status = 'Garantia de danos';
					break;

				default:
					status = obj?.status;
					break;
			}
		}

		return (
			<div style={{ textAlign: 'center' }}>
				<p>{status}</p>
			</div>
		);
	};

	render() {
		return (
			<DataTable ref={(el) => this.dt = el} value={this.state.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={this.props?.rows ? [10, 25, 50] : [5]} first={this.state.first} last={this.state.last}
				totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} responsive={true} loadingIcon={`fas fa-sync fa-spin`} onSort={(e) => this.sortTable(e)}
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" emptyMessage={this.emptyMessageReturn}
				currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" sortField={this.state.sortField} sortOrder={this.state.sortOrder}>
				{this.props.export ? this.export() : false}
				<Column field="tenant.name" body={(obj) => this.getUserName(obj, 'tenant')} header="Responsável" />
				<Column field="landlord.name" body={(obj) => this.getUserName(obj, 'landlord')} header="Inquilino" />
				{this?.props?.type === 'agreement' ? null : <Column field="plano" body={(obj) => this.getStatusProposition(obj?.contract?.entity)} header="Plano" style={{ width: '160px' }} />}
				<Column field="status" body={(obj) => this.getStatus(obj)} header="Status" style={{ width: '160px' }} />
				<Column field="due_date" body={(obj) => this.getDateFormatted(obj?.due_date)} header="Vencimento" />
				<Column field="receipts.rent.value" body={(obj) => this.getValue(obj?.receipts?.rent?.value)} header="Valor" />
				<Column header="Ações" body={(obj) => this.columnOptionsCrud(obj)} style={{ width: '200px' }} />
			</DataTable>
		);
	}
}
