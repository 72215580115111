import React, { Component } from "react";
import { Container, Col, FormGroup, Label, Button, Card, CardBody } from "reactstrap";
import BetterCircularProgress from "../../../components/BetterCircularProgress";
import { getTemplate, updateTemplate } from "../../../crud/templates.crud";
import Breadcrumbs from "../../../components/Breadcrumb";
import { Formik } from "formik";
import { TextField } from "@material-ui/core";

const ignoreFields = new Set([
  "created_at",
  "updated_at",
  "created_by",
  "deleted_at",
  "createdAt",
  "updatedAt",
  "deletedAt",
]);

const editableFields = new Set(["text"]);

class TemplateSMSEdit extends Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
  }

  state = {
    loading: true,
    submitted: false,
    templateComponent: [],
    data: {},
  };

  goBack() {
    this.props.history.goBack();
  }

  componentDidMount = () => {
    this.setState({ loading: true });
    getTemplate(this.props.match.params.id)
      .catch((err) => {
        alert("An error occurred, please contact the support.");
      })
      .then(async (res) => {
        if (res?.status === 200) {
          this.setState({ data: res.data.data });
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  onSubmit = (values) => {
    console.log(values);
    this.setState({ submitted: true, loading: true });
    updateTemplate(this.props.match.params.id, values)
      .catch((err) => {
        this.setState({
          loading: false,
          submitted: false,
          success: false,
          errors: err.response.data.error ? [err.response.data.error] : [],
        });
      })
      .then((res) => {
        if (res?.status === 200) {
          this.setState({
            loading: false,
            submitted: false,
            success: !Boolean(res.data.error),
            errors: res.data.error ? [res.data.error] : [],
          });
        }
      })
      .finally(() => {
        this.goBack();
      });
  };

  objectToComponents = (object, handleBlur, handleChange, mark = "a") => {
    let i = 0;
    const component = [];
    if (!object) return [];
    for (const [key, value] of Object.entries(object)) {
      if (ignoreFields.has(key)) {
        continue;
      }

      if (typeof value === "object" && !Array.isArray(value)) {
        component.push(...this.objectToComponents(value, handleBlur, handleChange, "b"));
        continue;
      }

      const tittle = key.charAt(0).toUpperCase() + key.slice(1) + ": ";

      i++;

      component.push(
        <Col md={6} key={mark + i}>
          <FormGroup row>
            <Label className="col-md-10 col-form-label">{tittle}</Label>
            <Col md={8} className="container-center-input">
              <TextField
                name={key}
                type={"text"}
                fullWidth
                disabled={!editableFields.has(key)}
                variant="outlined"
                value={value}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Col>
          </FormGroup>
        </Col>,
      );
    }
    return component;
  };

  render() {
    return (
      <React.Fragment>
        <BetterCircularProgress loading={this.state.loading}>
          <div className="page-content container-page">
            <Container fluid>
              <Breadcrumbs title="Template Details" breadcrumbItems={[]} />
              <Formik
                initialValues={{ ...this.state.data }}
                onSubmit={(values) => this.onSubmit(values)}
              >
                {({ values, handleSubmit, handleBlur, handleChange }) => (
                  <form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>
                    <Card>
                      {(() => {
                        return this.objectToComponents(values, handleBlur, handleChange);
                      })()}
                    </Card>
                    <Card>
                      <CardBody className="container-space-between">
                        <Button onClick={this.goBack} className="btn btn-danger btn-bold">
                          <i className="fa fa-arrow-left mr-2"></i>Voltar
                        </Button>
                        <Button
                          color="success"
                          type="submit"
                          className="waves-effect waves-light mr-10"
                        >
                          <i
                            className={`mr-2 align-middle ${
                              this.state.submitted ? "fas fa-sync fa-spin" : "ri-save-3-line"
                            }`}
                          ></i>
                          Salvar
                        </Button>
                      </CardBody>
                    </Card>
                  </form>
                )}
              </Formik>
            </Container>
          </div>
        </BetterCircularProgress>
      </React.Fragment>
    );
  }
}

export default TemplateSMSEdit;
