import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Link } from "react-router-dom";
import { Column } from 'primereact/column';
import _ from 'lodash';
import { CircularProgress } from "@material-ui/core";
import moment from 'moment';

export default class DataTableCrawlerLinks extends Component {
	constructor() {
		super();

		this.state = {
			data: [],
			rows: 10,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: 'id',
			sortOrder: '-1',
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		this.onPage(this.state);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters != this.props.filters) {
			this.onFilterChanged();
		}
	}

	onFilterChanged = _.debounce(() => {
		if (!this.state.isLoading) {
			this.setState({
				loading: true,
				rows: 10,
				totalRecords: 0,
				first: 0,
				last: 0,
			}, () => { this.fetchData() });
		}
	}, 700);

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = async (event) => {
		if (event.data) {
			event.data = null;
		}

		this.setState({
			...this.state,
			rows: event.rows,
			loading: true
		});

		this.fetchData(event.rows, event.first);
	};

	fetchData = (limit, skip) => {
		let data = {
			$limit: limit ? limit : this.state.rows,
			$skip: skip != null ? skip : this.state.first,
			$sort: { [this.state.sortField]: this.state.sortOrder }
		};

		this.props.fetchEvent(data).then(res => {
			this.setState({
				...this.state,
				data: res.data.data,
				totalRecords: res.data.total,
				first: res.data.skip,
				last: res.data.skip + res.data.limit,
				loading: false
			});
		});
	};

	columnOpcoesCrud = (rowData, column) => {
		let idRow = this.props.idRow ? this.props.idRow : 'id';

		if (this.props.crudUrl) {
			return (
				<div style={{ textAlign: 'center' }}>
					<Link to={`${this.props.crudUrl}/show/${rowData[idRow]}`} className="btn btn-primary btn-table-action mr-2"><i className="fas fa-eye"></i></Link>
				</div>
			);
		} else {
			return null;
		}
	};

	export() {
		this.dt.exportCSV();
	};

	getUserName = (obj) => {
		if (obj.user) {
			return (
				<div style={{ textAlign: 'center' }}>
					<p>{obj.user.name}</p>
				</div>
			)
		} else {
			if (obj.loading_user) {
				return (
					<div style={{ textAlign: 'center' }}>
						<CircularProgress color="inherit" style={{ color: "#4AA3FF" }} size={15} />
					</div>
				)
			}
		}
	};

	getAnnouncement = (obj) => {
		if (obj.announcement) {
			return (
				<div style={{ textAlign: 'center' }}>
					<p>{obj.address.city}</p>
				</div>
			)
		} else {
			if (obj.loading_announcement) {
				return (
					<div style={{ textAlign: 'center' }}>
						<CircularProgress color="inherit" style={{ color: "#4AA3FF" }} size={15} />
					</div>
				)
			}
		}
	};

	getConverted = (obj) => {
		if (obj.converted) {
			return (
				<div style={{ textAlign: 'center' }}>
					<i className="ri-checkbox-circle-line align-middle ri-2x"></i>
				</div>
			)
		} else {
			return (
				<div style={{ textAlign: 'center' }}>
					<i className="ri-close-circle-line align-middle ri-2x"></i>
				</div>
			)
		}
	};

	getSetApproval = (obj) => {
		return (
			<div style={{ textAlign: 'center' }}>
				<i className="ri-thumb-up-line align-middle ri-2x"></i><p>APPROVE</p>
				<i className="ri-thumb-down-line align-middle ri-2x"></i><p>DISAPPROVE</p>
			</div>
		)
	};

	sortTable = e => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder }, () => this.fetchData());
	};

	getDateFormatted = (date) => {
		if (date) {
			return (
				<p>{moment(date).format('DD/MM/YYYY HH:mm:ss')}</p>
			)
		} else {
			return <p>N/A</p>
		}
	}

	render() {
		const header = (
			<div style={{ float: 'right' }}>
				<Button className="tableHeaderItem" type="button" style={{ marginRight: '5px', marginLeft: '5px' }} icon="fas fa-file-csv" iconPos="left" label="CSV" onClick={() => this.export()} />
				<Button className="tableHeaderItem" icon={!this.state.loading ? "fas fa-sync" : "fas fa-sync fa-spin"} disabled={this.state.loading ? "disabled" : ""} onClick={() => this.refreshTable()} />
			</div>
		);

		return (
			<DataTable ref={(el) => this.dt = el} value={this.state.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={[10, 25, 50]}
				first={this.state.first} last={this.state.last} header={this.props.noHeader ? null : header} responsive={true}
				totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} loadingIcon={`fas fa-sync fa-spin`}
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
				currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" emptyMessage="Nenhum registro encontrado!"
				onSort={(e) => this.sortTable(e)} sortField={this.state.sortField} sortOrder={this.state.sortOrder} >
				<Column sortable field="host" header="Host" />
				<Column sortable field="path" header="Path" />
				<Column field="short_link" header="Short link" />
				<Column field="redirect_to" header="Long link" />
				<Column sortable field="access_data.total" header="Total accesses" />
				<Column field="access_data.last_accessed_at" body={(obj, i) => this.getDateFormatted(obj.access_data.last_accessed_at)} header="Last accessed at" />
				<Column sortable field="created_at" body={(obj, i) => this.getDateFormatted(obj.created_at)} header="Created at" />
				<Column header="Opções" body={this.columnOpcoesCrud} style={{ width: '130px' }} />
			</DataTable>
		);
	}
}