import React, { Component } from 'react';
import { Container, Card, CardBody, Table } from "reactstrap";
import BetterCircularProgress from '../../components/BetterCircularProgress';
import { Link } from "react-router-dom";
import moment from 'moment';
import Breadcrumbs from '../../components/Breadcrumb';
import 'moment/locale/pt-br';
import BetterAlerts from '../../components/BetterAlerts';
import DataTablePropertyInspectionItems from '../../components/datatables/DataTablePropertyInspectionItems';
import { getVistoria, getVistoriaFile } from '../../crud/property-inspection.crud';
import { get as getContract } from '../../crud/contracts.crud';
import arrowDownToLine from '../../assets/images/icons-custom/arrow-down-to-line.svg';

moment.locale('pt-br');

class PropertyInspectionShow extends Component {

	state = {
		loading: false,
		data: null,
		landlord: null,
		tenant: null,
		repayments: [],
		success: [],
		errors: [],
		transfer: null,
		vistoria: null,
		contract: null,
		disableVistoria: false
	}

	componentDidMount = async () => {
		this.setState({ loading: true });

		getVistoria(this.props.match.params.id).catch(err => { }).then(res => {
			if (res?.status === 200) {
				this.setState({ vistoria: res.data });

				getContract(res.data.contract_id).catch(err => {
					alert('An error occurred, please contact the support.');
				}).then(res => {
					if (res?.status == 200) {
						this.setState({ contract: res.data.data.entity });
					}
				});
			}
		}).finally(() => this.setState({ loading: false }));


	};

	getLink = () => {
		const currentUrl = window.location.href;
		const announcementId = this.state.contract.property_announcement_id;
		const vistoriaId = this.props.match.params.id;
		const contractId = this.state.vistoria.contract_id;

		const localEnvironment = currentUrl.toLowerCase().includes('localhost');
		const devEnvironment = currentUrl.toLowerCase().includes('dev-admin');

		let preUrl = (localEnvironment || devEnvironment) ? 'https://dev-app.mellro.com' : 'https://mellro.com';
		let complement = `/perfil/gerenciamento/vistoria/${announcementId}/${vistoriaId}?contractId=${contractId}`;


		navigator.clipboard.writeText(preUrl + complement);
		this.setState({ success: [...this.state.success, 'Link da vistoria copiado!'] });
	};

	handleDownloadVistoria = async () => {
		this.setState({ disableVistoria: true })
		try {
			await getVistoriaFile(this.state?.vistoria?.id).then((data) => { console.log(data); window.open(data?.request?.responseURL, '_blank'); this.setState({ disableVistoria: false }) });
		} catch (err) {
			console.log(err);
			this.setState({ errors: ['Pdf da vistoria não encontrado, entre em contato com o suporte.'] });
		}
	}

	render() {

		return (
			<React.Fragment>
				<BetterCircularProgress loading={this.state.loading}>
					<div className="page-content container-page">
						<Container fluid>
							<Breadcrumbs breadcrumbItems={[
								{ link: `/contracts/index`, title: 'Contrato' },
								{ link: `/contracts/show/${this.state.vistoria?.contract_id}`, title: 'Visualizar' },
								{ link: '', title: `Vistoria` }]} />

							<BetterAlerts success={this.state.success} errors={this.state.errors} />

							<Card>
								<CardBody>
									<Table striped style={{ marginBottom: '30px' }}>
										<tbody>
											<tr>
												<th>Vistoria</th>
												<th>Data de início</th>
												<th>Último dia de edição</th>
												<th>Nº de itens</th>
											</tr>
											<tr>
												<td style={{ verticalAlign: 'middle' }}>{`Vistoria de ${this.state?.vistoria?.type === 'ENTRY' ? 'entrada' : 'saída'}`}</td>
												<td style={{ verticalAlign: 'middle' }}>{moment(this.state?.vistoria?.start_date).format('DD/MM/YYYY')}</td>
												<td style={{ verticalAlign: 'middle' }}>{moment(this.state?.vistoria?.end_date).format('DD/MM/YYYY')}</td>
												<td style={{ verticalAlign: 'middle' }}>{this.state?.vistoria?.totalItems}</td>
											</tr>
										</tbody>
									</Table>

									<DataTablePropertyInspectionItems propertyInspectionId={this.props.match.params.id} />
								</CardBody>
							</Card>

							<Card>
								<CardBody>
									<Link to={`/contracts/show/${this.state.vistoria?.contract_id}`} className="btn btn-secondary btn-bold ml-2">
										<i className="fa fa-arrow-left mr-2"></i>Voltar
									</Link>

									{moment().isAfter(moment(this.state?.vistoria?.end_date).subtract(1, 'days')) ? (
										<button disabled={this?.state?.disableVistoria} onClick={async () => { await this.handleDownloadVistoria(); }} className="btn btn-primary btn-bold ml-2">
											Baixar vistoria de {this.state?.vistoria?.type === 'ENTRY' ? 'entrada' : 'saída'}	<img src={arrowDownToLine} alt="download" className='ml-2' width={12} height={20} />
										</button>
									) : null}

									<button onClick={this.getLink} className="btn btn-primary btn-bold ml-2">
										Copiar link vistoria<i className="fa fa-link ml-2"></i>
									</button>
								</CardBody>
							</Card>

						</Container>
					</div>
				</BetterCircularProgress>
			</React.Fragment>
		)
	}
}

export default PropertyInspectionShow;