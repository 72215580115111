import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link } from "react-router-dom";
import moment from "moment";

import { getPropertiesById } from '../../../crud/announcements.crud';
import { get as getUser } from '../../../crud/users.crud';
import { get as getContract } from '../../../crud/contracts.crud';
import { get as getTermination, query as queryTermination } from '../../../crud/termination.crud';
import { get as getAllBillingsParams, getTotalAdmin } from '../../../crud/billings.crud';
import { get as getNegativacao } from '../../../crud/negativacao.crud';
import { formatBRL } from "../../../helpers/Utils";
import { createRentBoleto, getById as getBillingById, getFriendlyAgreement, getFriendlyAgreementParcela } from "../../../crud/billings.crud";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/pro-solid-svg-icons";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";

export default class DataTableCobranca extends Component {
	constructor() {
		super();

		this.state = {
			property: {},
			data: null,
			rows: 10,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: 'id',
			sortOrder: 1,
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		let cobrancaDataTable = JSON.parse(localStorage.getItem("cobrancaDataTable"));

		if (cobrancaDataTable) {
			this.setState(
				{
					data: cobrancaDataTable.data,
					rows: cobrancaDataTable.rows,
					totalRecords: cobrancaDataTable.totalRecords,
					first: cobrancaDataTable.first,
					last: cobrancaDataTable.last,
					sortField: cobrancaDataTable.sortField,
					sortOrder: cobrancaDataTable.sortOrder,
					loading: false
				},
			);
		} else {
			this.fetchData();
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters !== this.props.filters) {
			this.fetchData();
		}
	}

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = async (event) => {
		this.setState({
			loading: true,

		});
		if (event.data) {
			event.data = null;
		}

		let cobrancaDataTable = {
			rows: event.rows,
			totalRecords: this.state.totalRecords,
			data: this.data,
			first: event.first,
			last: event.rows + event.first,
			loading: this.state.loading,
			sortField: this.state.sortField,
			sortOrder: this.state.sortOrder,
		};

		localStorage.setItem("cobrancaDataTable", JSON.stringify(cobrancaDataTable));

		this.setState({
			...this.state,
			rows: event.rows,
		});
		this.fetchData(event.rows, event.first);
	};

	fetchData = async (limit, skip) => {
		this.setState({ loading: true });

		let options = {
			$limit: limit ? limit : this.state.rows,
			$skip: skip != null ? skip : this.state.first,
			$sort: { [this.state.sortField]: this.state.sortOrder },
		};

		return this.props.fetchEvent(options).then(async (res) => {
			for (const obj of res.data.data) {
				if (obj.property_announcement_id) {
					obj.loading_announcement = true;
					await getPropertiesById(obj.property_announcement_id).catch(err => {
						obj.property = null;
						obj.loading_announcement = false;
					}).then(res => {
						if (res?.data?.data) {
							obj.property = res.data.data;
						}
					});
				}

				if (obj.contract?.entity?.landlord_id) {
					obj.loading_landlord = true;
					await getUser(obj.contract.entity.landlord_id).catch(err => {
						obj.landlord = null;
						obj.loading_landlord = false;
					}).then(res => {
						if (res?.data?.data) {
							obj.landlord = res.data.data;
						}
					});
				}

				if (obj.contract?.entity?.tenant_id) {
					obj.loading_tenant = true;
					await getUser(obj.contract.entity.tenant_id).catch(err => {
						obj.tenant = null;
						obj.loading_tenant = false;
					}).then(res => {
						if (res?.data?.data) {
							obj.tenant = res.data.data;
						}
					});
				}

				if (this.props.activeTab == '3') {
					await getFriendlyAgreementParcela(obj?.friendly_agreement_id).then(({ data: { data: friendlyAgreement } }) => {
						if (friendlyAgreement) {
							obj.friendlyAgreement = friendlyAgreement;
						} else {
							obj.friendlyAgreement = null;
						}
					}).catch((err) => {
						console.log(err);
					});
				}

				if (this.props.activeTab == '4') {
					const terminations = await getAllBillingsParams({ contract_id: obj?.contract_id, type: 'termination' });

					if (terminations?.status === 200) {
						await terminations.data.data.map((billing, index) => {
							if (billing.id == obj?.id) {
								obj.termination = {
									number: index + 1,
									total: terminations.data.total,
								};
							}
						})

					}
				}

				if (this.props.activeTab == '5') {
					await getNegativacao(obj?.id).then(({ data: { data: negativacao } }) => {
						if (negativacao) {
							obj.negativacao = negativacao
						} else {
							obj.negativacao = null;
						}
					}).catch((err) => {
						console.log(err);
					});
				}

				if (this.props.activeTab == '6') {
					await queryTermination({ contract_id: obj.contract_id, '$sort[created_at]': -1 }).then(({ data: { data: terminations } }) => {
						if (terminations) {
							obj.termination = terminations[0]
						} else {
							obj.termination = null;
						}
					}).catch((err) => {
						console.log(err);
					});
				}
			}

			this.setState({
				data: res.data.data,
				totalRecords: res.data.total,
				first: skip,
				last: skip + res.data.limit,
			});
		}).finally(() => {
			this.setState({ loading: false });
		});
	};

	sortTable = async (e) => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder, loading: true }, () => {
			let cobrancaDataTable = {
				rows: this.state.rows,
				totalRecords: this.state.totalRecords,
				first: this.state.first,
				last: this.state.last,
				loading: this.state.loading,
				sortField: this.state.sortField,
				sortOrder: this.state.sortOrder,
				data: this.state.data
			};
			localStorage.setItem("cobrancaDataTable", JSON.stringify(cobrancaDataTable));

			this.fetchData();
		});
	};

	getValue = (obj) => {
		return (
			<div style={{ textAlign: "center" }}>
				<p>{formatBRL(obj || 0)}</p>
			</div>
		);
	};

	getUserName_ = (obj, type) => {
		const contract = obj?.contract?.entity;
		if (type === "landlord" && contract?.landlord) {
			return (
				<div style={{ textAlign: "center" }}>
					<Link to={`/users/show/${contract?.landlord?.id}`}>
						{contract?.landlord?.name}
					</Link>
				</div>
			);
		}

		if (type === "tenant" && contract?.tenant) {
			return (
				<div style={{ textAlign: "center" }}>
					<Link to={`/users/show/${contract?.tenant?.id}`}>{contract?.tenant?.name}</Link>
				</div>
			);
		}

		if (type === "contract" && obj?.contract) {
			return (
				<div style={{ textAlign: "center" }}>
					<Link to={`/contracts/show/${obj?.contract?.entity?.id}`}>{contract?.plan?.friendly_name}</Link>
				</div>
			);
		}

		return (
			<div style={{ textAlign: "center" }}>
				Não encontrado
			</div>
		);
	};

	getValueRent = (obj) => {
		return (
			<div>
				<p className="col-form-label">{obj.type == 'termination' ? formatBRL(obj.values.value) : obj?.receipts?.rent?.value ? formatBRL(obj?.receipts?.rent?.value) : 'R$ -'}</p>
			</div>
		);
	};

	getAddress(property) {
		if (property?.address) {
			return (
				<Link to={`/announcements/show/${property?.id}`}>
					<div className='negotiation-active' style={{ textAlign: 'left' }}>
						<FontAwesomeIcon icon={faBuilding} style={{ marginRight: '8.5px' }} />

						{property.address.street} {property.address.number}, {property.address?.complement ?? ''} {property.address.neighbourhood ? `, ${property.address.neighbourhood}` : ''},  - {property.address.city}/{property.address.state}
					</div>
				</Link>
			)
		}
	}

	getNeighbourhood = (obj) => {
		return (
			<div style={{ textAlign: "center" }}>
				<Link to={`/announcements/show/${obj.property?.id}`}>
					{obj.property?.address?.neighbourhood}
				</Link>
			</div>
		);
	};

	getStatus = (obj) => {
		if (obj?.type === 'monthly' && obj?.termination_id) {
			return (
				<div className="tag" style={{ backgroundColor: '#5664D2' }}>
					Acerto de rescisão
				</div>
			)
		}

		if (obj?.receipts?.rent?.paid === true && (obj?.receipts?.rent?.paid_for_insurer === false || !obj?.receipts?.rent?.paid_for_insurer)) {
			return (
				<>
					<div className="tag" style={{ backgroundColor: '#1CBB8C' }}>
						Pago
					</div>

					{obj?.anticipated ?
						<div className="tag" style={{ backgroundColor: '#5664D2', marginTop: 4 }}>
							Antecipado
						</div>
						: null}
				</>
			);
		} else if (obj?.receipts?.rent?.paid_for_insurer == true && obj?.receipts?.rent?.paid == true) {
			return (
				<div>
					<div className="tag" style={{ backgroundColor: '#1CBB8C' }}>
						Pago
					</div>

					<div className="tag" style={{ backgroundColor: '#4AA3FF', marginTop: 4 }}>
						Garantido
					</div>

					{obj?.anticipated ?
						<div className="tag" style={{ backgroundColor: '#5664D2', marginTop: 4 }}>
							Antecipado
						</div>
						: null}
				</div>
			);
		} else if (obj?.receipts?.rent?.paid_for_insurer == true && obj?.receipts?.rent?.paid == false) {
			return (
				<div>
					<div className="tag" style={{ backgroundColor: '#FF3D60' }}>
						Atrasado
					</div>

					<div className="tag" style={{ backgroundColor: '#4AA3FF', marginTop: 4 }}>
						Garantido
					</div>

					{obj?.anticipated ?
						<div className="tag" style={{ backgroundColor: '#5664D2', marginTop: 4 }}>
							Antecipado
						</div>
						: null}
				</div>
			);
		} else if (moment().isSameOrBefore(obj.due_date, 'day') && obj.boleto) {
			return (
				<>
					<div className="tag" style={{ backgroundColor: '#FCB92C' }}>
						Em aberto
					</div>

					{obj?.anticipated ?
						<div className="tag" style={{ backgroundColor: '#5664D2', marginTop: 4 }}>
							Antecipado
						</div>
						: null}
				</>
			);
		} else if (moment().isAfter(moment(obj.due_date).add(1, 'd'), 'day')) {
			return (
				<>
					<div className="tag" style={{ backgroundColor: '#FF3D60' }}>
						Atrasado
					</div>

					{obj?.anticipated ?
						<div className="tag" style={{ backgroundColor: '#5664D2', marginTop: 4 }}>
							Antecipado
						</div>
						: null}
				</>
			);
		} else {
			return (
				<>
					<div className="tag" style={{ backgroundColor: '#8590A5' }}>
						Aguardando
					</div>

					{obj?.anticipated ?
						<div className="tag" style={{ backgroundColor: '#5664D2', marginTop: 4 }}>
							Antecipado
						</div>
						: null}
				</>
			);
		}
	};

	getDateFormatted = (date) => {
		return (
			<p>
				{date ? moment(date).add(1, 'day').format("DD/MM/YYYY") : '-'}
			</p>
		);
	};

	onBoletoCreation = async (obj) => {
		this.setState({ ...this.state, loading: true, });

		createRentBoleto(obj.id).then((data) => {
			setTimeout(() => {
				getBillingById(obj.id).then((data) => {
					navigator.clipboard.writeText(data.data?.boleto?.pdf_url);
					this.setState({ ...this.state, loading: false, });
				});
			}, 5000);
		}).catch(err => {
			this.setState({ ...this.state, boletoError: true, loading: false, });

			setTimeout(() => {
				this.setState({ ...this.state, boletoError: false, });
			}, 3000);
		}).finally(() => {
			this.setState({ ...this.state, loading: false, });
		})
	}

	columnOpcoesCrud = (rowData) => {
		let idRow = this.props.idRow ? this.props.idRow : 'id';

		if (this.props.crudUrl) {
			return (
				<Link to={`${this.props.crudUrl}/show/${rowData[idRow]}`} className='showButton'>
					<i className='fa fa-chevron-right' />
				</Link>
			);
		} else {
			return null;
		}
	};

	getWarranty = (key, plan = {}) => {
		let garantia = '';

		if (key?.plan?.name == 'FREE') {
			garantia = 'Grátis';
		} else if (key?.plan?.name == 'WARRANTY') {
			garantia = 'Pagamento';
		} else if (key?.plan?.name == 'WARRANTY_GUARANTEE_DAMAGE') {
			garantia = 'Completa';
		} else {
			garantia = 'Não identificado';
		}

		return (
			<Link to={`/negotiation/show/${key?.proposition_id}`}>
				<span style={{ color: '#4AA3FF' }}>{garantia}</span>

				{plan?.percentage ?
					<div className="tag" style={{ backgroundColor: '#4AA3FF', marginTop: 4 }}>
						{plan.percentage}%
					</div>
					: null}
			</Link>
		)
	};

	getUserName = (obj, type) => {
		if (type === 'only_landlord') {
			return (
				<div style={{ textAlign: 'center' }}>
					<Link to={`/users/show/${obj.landlord?.id}`}>{obj.landlord?.name}</Link>
				</div>
			)
		}

		if (obj.landlord && obj.tenant) {
			return (
				<div style={{ textAlign: 'center' }}>
					<Link to={`/users/show/${obj.landlord?.id}`}>{obj.landlord?.name}, </Link> <Link to={`/users/show/${obj.tenant?.id}`} >{obj.tenant?.name}</Link>
				</div>
			)
		} else if (obj.loading_tenant || obj.loading_landlord) {
			return <FontAwesomeIcon icon={faSpinner} spin />
		} else {
			return null;
		}
	};

	getStatusNegativacao = (obj) => {
		if (obj?.status === 'PENDING') {
			return (
				<div className="tag" style={{ backgroundColor: '#8590A5' }}>
					Pendente
				</div>
			);
		} else if (obj?.status === 'AWAITING_APPROVAL') {
			return (
				<div className="tag" style={{ backgroundColor: '#FCB92C' }}>
					Aguardando aprovação
				</div>
			);
		} else if (obj?.status === 'AWAITING_CANCELLATION') {
			return (
				<div className="tag" style={{ backgroundColor: '#FF3D60' }}>
					Aguardando cancelamento
				</div>
			);
		} else if (obj?.status === 'PROCESSED') {
			return (
				<div className="tag" style={{ backgroundColor: '#4AA3FF' }}>
					Em negativação
				</div>
			);
		} else if (obj?.status === 'PAID') {
			return (
				<div className="tag" style={{ backgroundColor: '#1CBB8C' }}>
					Recuperado
				</div>
			);
		} else if (obj?.status === 'PARTIALLY_PAID') {
			return (
				<div className="tag" style={{ backgroundColor: '#5664D2' }}>
					Pagamento iniciado
				</div>
			);
		} else if (obj?.status === 'DENIED') {
			return (
				<div className="tag" style={{ backgroundColor: '#852032' }}>
					Negada
				</div>
			);
		} else if (obj?.status === 'CANCELLED') {
			return (
				<div className="tag" style={{ backgroundColor: '#FF3D60' }}>
					Cancelada
				</div>
			);
		} else {
			return (
				<div className="tag" style={{ backgroundColor: '#FCB92C' }}>
					Aguardando
				</div>
			);
		}
	};

	getParcelaNumber = (obj) => {
		if (obj?.paid_at) {
			return `${obj?.number + 1 ?? obj?.friendlyAgreement?.installments}º de ${obj?.friendlyAgreement?.installments ?? obj?.number + 1}`
		} else if (obj?.number && obj?.friendlyAgreement?.installments) {
			return `${obj?.number + 1}º de ${obj?.friendlyAgreement?.installments}`
		} else if (obj?.termination?.number && obj?.termination?.total) {
			return `${obj?.termination?.number}º de ${obj?.termination?.total}`
		} else {
			return <FontAwesomeIcon spin icon={faSpinner} />
		}
	}

	getParcelaNumber = (obj) => {
		if (obj?.termination) {
			return `${obj?.number + 1 ?? obj?.termination?.retroactive_billings_guaranteed?.installments}º de ${obj?.termination?.retroactive_billings_guaranteed?.installments ?? obj?.number + 1}`
		} else {
			return <FontAwesomeIcon spin icon={faSpinner} />
		}
	}

	export() {
		this.dt.exportCSV();
	}

	render() {
		const primeiroPagamento = [
			() => <Column style={{ width: 106 }} sortable body={(obj) => obj?.due_date ? moment(obj?.due_date).add(3, "hours").format('DD/MM/YYYY') : '--'} field="due_date" header="Vence em" />,
			() => <Column style={{ width: 120 }} body={(obj) => this.getStatus(obj)} field="status" header="Status" />,
			() => <Column style={{ width: 104 }} sortable body={(obj) => obj?.paid_at ? moment(obj?.paid_at).format('DD/MM/YYYY HH:mm:ss') : '--'} field="paid_at" header="Pago em" />,
			() => <Column style={{ width: 298 }} sortable field="proprietario" body={(obj => this.getUserName(obj))} header="Constituintes" />,
			() => <Column style={{ width: 298 }} sortable field="details.place" header="Imóvel" body={({ property }) => this.getAddress(property)} />,
			() => <Column style={{ width: 102 }} sortable field="plan.name" body={(obj) => this.getWarranty(obj?.contract?.entity, obj?.contract?.entity?.plan)} header="Contrato" />,
			() => <Column style={{ width: 105 }} sortable body={(obj) => this.getValueRent(obj)} field="receipts.rent.value" header="Valor" />,
		]

		const mensal = [
			() => <Column style={{ width: 106 }} sortable body={(obj) => obj?.due_date ? moment(obj?.due_date).add(3, "hours").format('DD/MM/YYYY') : '--'} field="due_date" header="Vence em" />,
			() => <Column style={{ width: 120 }} body={(obj) => this.getStatus(obj)} field="status" header="Status" />,
			() => <Column style={{ width: 104 }} sortable body={(obj) => obj?.paid_at ? moment(obj?.paid_at).add(3, "hours").format('DD/MM/YYYY HH:mm:ss') : '--'} field="paid_at" header="Pago em" />,
			() => <Column style={{ width: 298 }} sortable field="proprietario" body={(obj => this.getUserName(obj))} header="Constituintes" />,
			() => <Column style={{ width: 298 }} sortable field="details.place" header="Imóvel" body={({ property }) => this.getAddress(property)} />,
			() => <Column style={{ width: 102 }} sortable field="plan.name" body={(obj) => this.getWarranty(obj?.contract?.entity, obj?.contract?.entity?.plan)} header="Contrato" />,
			() => <Column style={{ width: 105 }} sortable body={(obj) => this.getValueRent(obj)} field="receipts.rent.value" header="Valor" />,
		]

		const parcelaAcordo = [
			() => <Column style={{ width: 92 }} sortable body={(obj) => this.getParcelaNumber(obj)} field="number" header="Parcela" />,
			() => <Column style={{ width: 106 }} sortable body={(obj) => obj?.due_date ? moment(obj?.due_date).add(3, "hours").format('DD/MM/YYYY') : '--'} field="due_date" header="Vence em" />,
			() => <Column style={{ width: 120 }} body={(obj) => this.getStatus(obj)} field="status" header="Status" />,
			() => <Column style={{ width: 104 }} sortable body={(obj) => obj?.paid_at ? moment(obj?.paid_at).add(3, "hours").format('DD/MM/YYYY HH:mm:ss') : '--'} field="paid_at" header="Pago em" />,
			() => <Column style={{ width: 298 }} sortable field="proprietario" body={(obj => this.getUserName(obj))} header="Constituintes" />,
			() => <Column style={{ width: 298 }} sortable field="details.place" header="Imóvel" body={({ property }) => this.getAddress(property)} />,
			() => <Column style={{ width: 102 }} sortable field="plan.name" body={(obj) => this.getWarranty(obj?.contract?.entity, obj?.contract?.entity?.plan)} header="Contrato" />,
			() => <Column style={{ width: 105 }} sortable body={(obj) => this.getValueRent(obj)} field="receipts.rent.value" header="Valor" />,
		]

		const rescisao = [
			() => <Column style={{ width: 92 }} sortable body={(obj) => this.getParcelaNumber(obj)} field="number" header="Parcela" />,
			() => <Column style={{ width: 106 }} sortable body={(obj) => obj?.due_date ? moment(obj?.due_date).add(3, "hours").format('DD/MM/YYYY') : '--'} field="due_date" header="Vence em" />,
			() => <Column style={{ width: 120 }} body={(obj) => this.getStatus(obj)} field="status" header="Status" />,
			() => <Column style={{ width: 104 }} sortable body={(obj) => obj?.paid_at ? moment(obj?.paid_at).add(3, "hours").format('DD/MM/YYYY HH:mm:ss') : '--'} field="paid_at" header="Pago em" />,
			() => <Column style={{ width: 298 }} sortable field="proprietario" body={(obj => this.getUserName(obj))} header="Constituintes" />,
			() => <Column style={{ width: 298 }} sortable field="details.place" header="Imóvel" body={({ property }) => this.getAddress(property)} />,
			() => <Column style={{ width: 102 }} sortable field="plan.name" body={(obj) => this.getWarranty(obj?.contract?.entity, obj?.contract?.entity?.plan)} header="Contrato" />,
			() => <Column style={{ width: 105 }} sortable body={(obj) => this.getValueRent(obj)} field="receipts.rent.value" header="Valor" />,
		]

		const negativado = [
			() => <Column style={{ width: 115 }} sortable body={(obj) => obj?.due_date ? moment(obj?.due_date).add(3, "hours").format('DD/MM/YYYY') : '--'} field="due_date" header="Iniciado em" />,
			() => <Column style={{ width: 178 }} body={(obj) => obj?.negativacao?.creditNegative?.id ? this.getStatusNegativacao(obj?.negativacao?.creditNegative) : this.getStatus(obj)} field="status" header="Status de negativação" />,
			() => <Column style={{ width: 317 }} sortable field="proprietario" body={(obj => this.getUserName(obj))} header="Constituintes" />,
			() => <Column style={{ width: 317 }} sortable field="details.place" header="Imóvel" body={({ property }) => this.getAddress(property)} />,
			() => <Column style={{ width: 102 }} sortable field="plan.name" body={(obj) => this.getWarranty(obj?.contract?.entity, obj?.contract?.entity?.plan)} header="Contrato" />,
			() => <Column style={{ width: 105 }} sortable body={(obj) => this.getValueRent(obj)} field="receipts.rent.value" header="Valor" />,
		]

		const devolucaoGarantia = [
			() => <Column style={{ width: 92 }} sortable body={(obj) => this.getParcelaNumber(obj)} field="number" header="Parcela" />,
			() => <Column style={{ width: 106 }} sortable body={(obj) => obj?.due_date ? moment(obj?.due_date).add(3, "hours").format('DD/MM/YYYY') : '--'} field="due_date" header="Vence em" />,
			() => <Column style={{ width: 120 }} body={(obj) => this.getStatus(obj)} field="status" header="Status" />,
			() => <Column style={{ width: 104 }} sortable body={(obj) => obj?.paid_at ? moment(obj?.paid_at).add(3, "hours").format('DD/MM/YYYY HH:mm:ss') : '--'} field="paid_at" header="Pago em" />,
			() => <Column style={{ width: 298 }} sortable body={(obj => this.getUserName(obj, 'only_landlord'))} header="Constituintes" />,
			() => <Column style={{ width: 298 }} sortable field="details.place" header="Imóvel" body={({ property }) => this.getAddress(property)} />,
			() => <Column style={{ width: 102 }} sortable field="plan.name" body={(obj) => this.getWarranty(obj?.contract?.entity, obj?.contract?.entity?.plan)} header="Contrato" />,
			() => <Column style={{ width: 105 }} sortable body={(obj) => this.getValueRent(obj)} field="receipts.rent.value" header="Valor" />,
		]

		return (
			<DataTable ref={(el) => this.dt = el} value={this.state.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={[10, 25, 50]} first={this.state.first} last={this.state.last}
				totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} responsive={true} loadingIcon={`fas fa-sync fa-spin`} onSort={(e) => this.sortTable(e)}
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" emptyMessage="Nenhum registro encontrado!"
				currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" sortField={this.state.sortField} sortOrder={this.state.sortOrder}>
				{this.props.export ? this.export() : false}

				{this.props.activeTab == '1' && (
					primeiroPagamento.map((coluna) => coluna())
				)}

				{this.props.activeTab == '2' && (
					mensal.map((coluna) => coluna())
				)}

				{this.props.activeTab == '3' && (
					parcelaAcordo.map((coluna) => coluna())
				)}

				{this.props.activeTab == '4' && (
					rescisao.map((coluna) => coluna())
				)}

				{this.props.activeTab == '5' && (
					negativado.map((coluna) => coluna())
				)}

				{this.props.activeTab == '6' && (
					devolucaoGarantia.map((coluna) => coluna())
				)}

				<Column style={{ width: 34 }} header="" body={(obj) => this.columnOpcoesCrud(obj)} />
			</DataTable>
		);
	}
}
