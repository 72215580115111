import React, { Component } from 'react';
import { Container, Card, CardBody, Alert, Table } from "reactstrap";
import Breadcrumbs from '../../components/Breadcrumb';
import BetterCircularProgress from '../../components/BetterCircularProgress';
import { Link, Redirect } from "react-router-dom";
import { getByPropositionId as getCreditConsultByPropositionId } from '../../crud/credit-consut.crud';
import { get as getProposition } from '../../crud/propositions.crud';
import { formatBRL, getLaudoBadge, getLaudoBadgeInquilino, limparMoeda } from './helpers';
import moment from 'moment';

class NegotiationLaudoInquilino extends Component {

	state = {
		loading: false,
		errors: false,
		laudo: null,
		pretendente: null,
		restricoes_financeiras: null
	};

	componentDidMount = async () => {
		this.setState({ loading: true });

		let laudo = null;
		let announcement = null;
		let pretendente = null
		let restricoes_financeiras = null
		await getCreditConsultByPropositionId(this.props.match.params.id).catch(err => { }).then(res => {
			if (res?.status == 200) {
				laudo = res.data.data;
			}
		});

		await getProposition(this.props.match.params.id).catch(err => {
			this.setState({ errors: true });
		}).then(({ data }) => {
			announcement = data.data;
		});

		if (laudo) {
			laudo.fc_laudo.pretendentes.forEach((pre, idxPre) => {
				if (announcement.tenant.cpf === pre.pessoa.cpf) {
					pre.pessoa.user_id = announcement.tenant.id;
				} else {
					if (announcement.parties?.length > 0) {
						announcement.parties.forEach((part, idxPart) => {
							if (part.cpf === pre.pessoa.cpf) {
								pre.pessoa.user_id = part.user_id;
							}
						});
					}
				}
			});

			laudo.fc_laudo.inquilinos.forEach((pre, _) => {
				if (pre.pessoa.id === parseInt(this.props.match.params.inquilinoId)) {
					pretendente = pre;
				}
			});
			console.log(pretendente)
		}

		this.setState({ loading: false, laudo, data: announcement, pretendente, restricoes_financeiras: pretendente?.laudo?.restricoes_financeiras?.result });

		setTimeout(() => {
			console.log(this.state)
		}, 2000);
	};

	getValorTotalPendente = () => {
		let soma = 0;
		let somaProtestos = 0;
		let somaPendencias = 0;

		if (this.state.restricoes_financeiras?.protestos?.resumo?.valor_total_pendencia) {
			soma = soma + this.state.restricoes_financeiras?.protestos?.resumo?.valor_total_pendencia;
			somaProtestos = somaProtestos + this.state.restricoes_financeiras?.protestos?.resumo?.valor_total_pendencia;
		}

		if (this.state.restricoes_financeiras?.pendencias?.resumo?.valor_total_pendencia) {
			soma = soma + this.state.restricoes_financeiras?.pendencias?.resumo?.valor_total_pendencia;
			somaPendencias = somaPendencias + this.state.restricoes_financeiras?.pendencias?.resumo?.valor_total_pendencia;
		}

		return { total: formatBRL(soma), totalProtestos: formatBRL(somaProtestos), totalPendencias: formatBRL(somaPendencias) };
	};

	getAcoesCivelColor = () => {
		let retorno = '#1CBB8C';

		if (this.state.restricoes_financeiras?.acoes_civeis?.info != 'NADA CONSTA') {
			if (moment(this.state?.restricoes_financeiras?.acoes_civeis?.resumo?.data_ultima_ocorrencia).isAfter(moment().subtract(18, 'months'))) {
				retorno = '#FF3D60';
			}
		}

		return retorno;
	};

	render() {
		return (
			<React.Fragment>
				<BetterCircularProgress loading={this.state.loading}>
					<div className="page-content container-page">
						<Container fluid>
							<Breadcrumbs title="" breadcrumbItems={[{ link: '/negotiation/index', title: 'Negotiation' }, { link: `/negotiation/edit/${this.props.match.params.id}`, title: 'Visualizar' }, { link: '', title: 'Resultado' }]} />
							{this.state.errors && !this.state.message ? <Redirect to="/negotiation/index" /> : null}
							{/* {console.log(this.state)} */}

							<Card>
								<CardBody>
									<div className="row">
										<div className='col-lg-12'>
											<div style={{ marginBottom: '30px', alignItems: 'center', display: 'flex', flexDirection: 'row' }}>
												<p style={{ marginRight: '10px', marginBottom: '0', fontWeight: '600', fontSize: '23px', color: '#343A40' }}>{this.state.pretendente?.pessoa?.nome}</p>

												{getLaudoBadgeInquilino(this.state.laudo?.fc_laudo?.parecer?.locacao?.parecer_inquilinos?.parecer_key ?? null)}
											</div>
										</div>
									</div>

									<div className='row'>
										<div className='col-lg-12'>
											<Table striped style={{ maxWidth: 522 }}>
												<tbody>
													<tr>
														<td style={{ fontWeight: '600', fontSize: '16px' }}>Situação de CPF</td>
														<td style={{ color: this.state.pretendente?.laudo?.situacao_cpf?.result == 'Regular' ? '#1CBB8C' : '#FF3D60' }}>{this.state.pretendente?.laudo?.situacao_cpf?.result}</td>
													</tr>
													<tr>
														<td style={{ fontWeight: '600', fontSize: '16px' }}>Suspeita de óbito</td>
														<td style={{ color: this.state.pretendente?.laudo?.suspeita_obito?.result == 'true' ? '#1CBB8C' : '#FF3D60' }}>{this.state.pretendente?.laudo?.suspeita_obito?.result == 'true' ? 'Sim' : 'Não'}</td>
													</tr>
													<tr>
														<td style={{ fontWeight: '600', fontSize: '16px' }}>Renda pessoal</td>
														<td style={{ color: '#1CBB8C' }}>{formatBRL(this.state.pretendente?.pessoa?.renda?.principal?.valor)}</td>
													</tr>
													<tr>
														<td style={{ fontWeight: '600', fontSize: '16px' }}>
															<p style={{ lineHeight: '20px', fontWeight: '600', fontSize: '16px', color: '##505D69', marginBottom: '16px' }}>Valor total pendente</p>
															<p style={{ lineHeight: '20px', fontWeight: '400', fontSize: '13px', color: '##505D69', marginBottom: '16px' }}>Pendências financeiras</p>
															<p style={{ lineHeight: '20px', fontWeight: '400', fontSize: '13px', color: '##505D69', marginBottom: '16px' }}>Títulos protestados</p>
														</td>
														<td>
															<p style={{ lineHeight: '20px', color: (limparMoeda(this.getValorTotalPendente().total) > parseFloat(this.state.laudo?.fc_laudo?.locacao?.aluguel)) ? '#FF3D60' : '#1CBB8C', fontWeight: '600', marginBottom: '16px' }}>{this.getValorTotalPendente().total}</p>
															<p style={{ lineHeight: '20px', color: (limparMoeda(this.getValorTotalPendente().totalPendencias) > parseFloat(this.state.laudo?.fc_laudo?.locacao?.aluguel)) ? '#FF3D60' : '#1CBB8C', fontWeight: '400', marginBottom: '16px' }}>{this.getValorTotalPendente().totalPendencias}</p>
															<p style={{ lineHeight: '20px', color: (limparMoeda(this.getValorTotalPendente().totalProtestos) > parseFloat(this.state.laudo?.fc_laudo?.locacao?.aluguel)) ? '#FF3D60' : '#1CBB8C', fontWeight: '400', marginBottom: '16px' }}>{this.getValorTotalPendente().totalProtestos}</p>
														</td>
													</tr>
													<tr onClick={() => console.log(this.state)}>
														<td style={{ fontWeight: '600', fontSize: '16px' }}>Ações de despejo</td>
														<td style={{ color: this.state.restricoes_financeiras?.acoes?.info == 'NADA CONSTA' ? '#1CBB8C' : '#FF3D60' }}>{this.state.restricoes_financeiras?.acoes?.info == 'NADA CONSTA' ? 'Nada consta' : `${this.state?.restricoes_financeiras?.acoes?.detalhes[0].tipo} - ${this.state?.restricoes_financeiras?.acoes?.detalhes[0].assunto}`}</td>
													</tr>
													<tr>
														<td style={{ fontWeight: '600', fontSize: '16px' }}>Ações cível</td>
														<td style={{ color: this.getAcoesCivelColor() }}>{this.state.restricoes_financeiras?.acoes_civeis?.info == 'NADA CONSTA' ? 'Nada consta' : `${this.state?.restricoes_financeiras?.acoes_civeis?.detalhes[0].tipo} - ${this.state?.restricoes_financeiras?.acoes_civeis?.detalhes[0].assunto} Última movimentação: ${moment(this.state?.restricoes_financeiras?.acoes_civeis?.resumo?.data_ultima_ocorrencia).format('DD/MM/YYYY')}`}</td>
													</tr>
													<tr>
														<td style={{ fontWeight: '600', fontSize: '16px' }}>Ações criminal</td>
														<td style={{ color: this.state.restricoes_financeiras?.acoes_criminais?.info == 'NADA CONSTA' ? '#1CBB8C' : '#FF3D60' }}>{this.state.restricoes_financeiras?.acoes_criminais?.info == 'NADA CONSTA' ? 'Nada consta' : `${this.state?.restricoes_financeiras?.acoes_criminais?.detalhes[0].tipo} - ${this.state?.restricoes_financeiras?.acoes_criminais?.detalhes[0].assunto}`}</td>
													</tr>
													<tr>
														<td style={{ fontWeight: '600', fontSize: '16px' }}>Fraude</td>
														<td style={{ color: this.state.pretendente?.pessoa?.tem_alerta_fraude ? '#FF3D60' : '#1CBB8C' }}>{this.state.pretendente?.pessoa?.tem_alerta_fraude ?? 'Nada consta'}</td>
													</tr>
													<tr>
														<td style={{ fontWeight: '600', fontSize: '16px' }}>Consultadas recentes</td>
														<td style={{ color: this.state.pretendente?.laudo?.rede_ficha?.pesquisas_anteriores_api?.result?.total_consultas_credito < 6 ? '#1CBB8C' : '#FF3D60' }}>{`${this.state.pretendente?.laudo?.rede_ficha?.pesquisas_anteriores_api?.result?.total_consultas_credito} consultas recentes`}</td>
													</tr>
													<tr>
														<td style={{ fontWeight: '600', fontSize: '16px' }}>Idade</td>
														<td style={{ color: this.state.pretendente?.laudo?.veracidade_data_nascimento?.result?.idade >= 65 ? '#1CBB8C' : '#EFA200' }}>{`[${this.state.pretendente?.laudo?.veracidade_data_nascimento?.result?.idade}] ${this.state.pretendente?.laudo?.veracidade_data_nascimento?.result?.idade >= 18 && this.state.pretendente?.laudo?.veracidade_data_nascimento?.result?.idade <= 25 ? '18 - 25 peça comprovante de renda' : ''}${this.state.pretendente?.laudo?.veracidade_data_nascimento?.result?.idade >= 26 && this.state.pretendente?.laudo?.veracidade_data_nascimento?.result?.idade <= 35 ? '26 - 35 peça comprovante de renda' : ''}`}</td>
													</tr>
												</tbody>
											</Table>
										</div>
									</div>
								</CardBody>
							</Card>

							<Card>
								<CardBody className="row">
									<Link to={`/negotiation/laudo/${this.props.match.params.id}`} className="btn btn-secondary btn-bold"><i className="fa fa-arrow-left mr-2" />Voltar</Link>
								</CardBody>
							</Card>
						</Container>
					</div>
				</BetterCircularProgress>
			</React.Fragment>
		);
	}
}

export default NegotiationLaudoInquilino;