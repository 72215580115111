import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import _ from 'lodash';
import moment from 'moment';
import { get as getUser } from '../../../crud/users.crud';
import { faUserMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

export default class DataTableOccurrences extends Component {
	constructor() {
		super();

		this.state = {
			data: [],
			rows: 10,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: 'id',
			sortOrder: 1,
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		let DataTableOccurrencesTb = JSON.parse(localStorage.getItem('DataTableOccurrencesTb'));

		if (DataTableOccurrencesTb) {
			this.setState({
				rows: DataTableOccurrencesTb.rows,
				totalRecords: DataTableOccurrencesTb.totalRecords,
				first: DataTableOccurrencesTb.first,
				last: DataTableOccurrencesTb.last,
				loading: DataTableOccurrencesTb.loading,
				sortField: DataTableOccurrencesTb.sortField,
				sortOrder: DataTableOccurrencesTb.sortOrder,
			}, () => {
				this.fetchData();
			});
		} else {
			this.fetchData();
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters != this.props.filters) this.onFilterChanged();
	}

	onFilterChanged = _.debounce(() => {
		if (!this.state.isLoading) this.fetchData();
	}, 700);

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = (event) => {
		if (event.data) {
			event.data = null;
		}

		let DataTableOccurrencesTb = {
			rows: event.rows,
			totalRecords: this.state.totalRecords,
			first: event.first,
			last: event.rows + event.first,
			loading: this.state.loading,
			sortField: this.state.sortField,
			sortOrder: this.state.sortOrder,
		}

		localStorage.setItem('DataTableOccurrencesTb', JSON.stringify(DataTableOccurrencesTb));

		this.setState({ ...this.state, rows: event.rows, loading: true });

		this.fetchData(event.rows, event.first);
	};

	fetchData = (limit, skip) => {
		let data = {
			$limit: limit ? limit : this.state.rows,
			$skip: skip != null ? skip : this.state.first,
			$sort: { [this.state.sortField]: this.state.sortOrder }
		};

		this.props.fetchEvent(data).then(async res => {
			this.props.handleTotalOcorrencias(res.data.total);

			this.setState({
				...this.state,
				data: res.data.data,
				totalRecords: res.data.total,
				first: res.data.skip,
				last: res.data.skip + res.data.limit,
				loading: false
			});
		}).catch((err) => {
			console.log(err)
		});
	}

	sortTable = e => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder }, () => {
			let DataTableOccurrencesTb = {
				rows: this.state.rows,
				totalRecords: this.state.totalRecords,
				first: this.state.first,
				last: this.state.last,
				loading: this.state.loading,
				sortField: this.state.sortField,
				sortOrder: this.state.sortOrder,
			};

			localStorage.setItem('DataTableOccurrencesTb', JSON.stringify(DataTableOccurrencesTb));

			this.fetchData();
		});
	};

	getStatus = (obj) => {
		let status = obj.status;
		let backgroundColor = '#4AA3FF'

		switch (status) {
			case 'Finalizada':
				backgroundColor = '#1CBB8C';
				break;
			case 'Em andamento':
				backgroundColor = '#5664D2';
				break;
			case 'Aberta':
				backgroundColor = '#4AA3FF';
				break;
			default:
				break;
		}
		return (
			<div className="tag" style={{ textAlign: "center", backgroundColor }}>
				{status}
			</div>
		);
	}

	getType = (obj) => {
		let backgroundColorContext = '#E1E3F7'
		let colorContext = '#5664D2';

		switch (obj?.context) {
			case 'Geral':
				backgroundColorContext = '#E1E3F7';
				colorContext = '#5664D2';
				break;
			case 'Financeiro':
				backgroundColorContext = '#FEF2D9';
				colorContext = '#EFA200';
				break;
			case 'Jurídico':
				backgroundColorContext = '#FFC9D2';
				colorContext = '#852032';
				break;
			case 'Contrato':
				backgroundColorContext = '#D6F3EA';
				colorContext = '#1CBB8C';
				break;
			default:
				break;
		}

		return (
			<div className='d-flex' style={{ gap: 4 }}>
				{obj.context && (
					<div className="tag" style={{ textAlign: "center", fontWeight: '600', backgroundColor: backgroundColorContext, color: colorContext }}>
						{obj.context}
					</div>
				)}

				{obj.reason && (
					<div className="tag" style={{ textAlign: "center", backgroundColor: '#DADBDD', color: '#505D69' }}>
						{obj.reason}
					</div>
				)}

				{obj.type && (
					<div className="tag" style={{ textAlign: "center", backgroundColor: '#DADBDD', color: '#505D69' }}>
						{obj.type}
					</div>
				)}

				{obj.result && (
					<div className="tag" style={{ textAlign: "center", backgroundColor: '#4AA3FF' }}>
						{obj.result}
					</div>
				)}
			</div>

		);
	}

	getUserName = (obj) => (
		<div className='negotiation-active' style={{ textAlign: 'center' }}>
			<FontAwesomeIcon icon={faUserMagnifyingGlass} style={{ marginRight: '8.5px' }} />

			<Link to={`/users/show/${obj?.administrator?.id}`} >{obj?.administrator?.name}</Link>
		</div>
	);

	columnOpcoesCrud = (rowData) => {
		let idRow = this.props.idRow ? this.props.idRow : 'id';

		return (
			<Link to={`${this.props.crudUrl}/show/${rowData[idRow]}/${this.props.negotiation_id}`} className='showButton'>
				<i className='fa fa-chevron-right' />
			</Link>
		);
	};

	render() {
		return (
			<DataTable ref={(el) => this.dt = el} value={this.state.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={[10, 25, 50]} sortOrder={this.state.sortOrder}
				first={this.state.first} last={this.state.last} responsive={true} loading={this.state.loading} loadingIcon={`fas fa-sync fa-spin`}
				totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} onSort={(e) => this.sortTable(e)} sortField={this.state.sortField}
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
				currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" emptyMessage="Nenhuma ocorrência vinculada">
				<Column style={{ width: 131 }} sortable field="updated_at" body={({ updated_at }, _) => <div style={{ padding: 30 }}>{moment(updated_at).format('DD/MM/YYYY HH:mm:ss')}</div>} header="Atualizado em" />
				<Column style={{ width: 148 }} sortable field="status" body={(obj, i) => this.getStatus(obj)} header="Status" />
				<Column style={{ width: 402 }} sortable field="context" body={(obj, i) => this.getType(obj)} header="Tipo" />
				<Column style={{ width: 137 }} sortable field="created_at" body={({ created_at }, _) => moment(created_at).format('DD/MM/YYYY HH:mm:ss')} header="Cadastrado em" />
				<Column style={{ width: 220 }} sortable field="administrator.name" header="Admin responsável" body={(obj, i) => this.getUserName(obj)} />

				<Column header="" body={this.columnOpcoesCrud} style={{ width: '34px', padding: 0 }} />
			</DataTable>
		);
	}
}