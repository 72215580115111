import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";
import { FiltersModuleCommissionIndicators, FiltersModulePropertyPerLandlord } from "../filters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullseye, faEllipsisVertical, faEyeSlash, faGripVertical, faMaximize, faMinimize, faUserBountyHunter } from '@fortawesome/pro-solid-svg-icons';
import { Checkbox, ListItemText, Menu, MenuItem } from "@material-ui/core";
import { formatBRL } from "../../../helpers/Utils";
import Chart from "react-google-charts";
import { getPropertiesPerLandlord } from "../../../crud/dashboard.crud";

export default class PropertyPerLandlordIndicatorSection extends Component {
	constructor() {
		super();

		this.state = {
			metrics: [],
			propertiesPerLandlordList: [],
			filtersPropertyPerLandlord: {
				"property_type": "all",
				data_cadastro: 'always'
			},
			dropdownKey: null,
			anchorEl: null,
		};
	}

	componentDidMount() {
		getPropertiesPerLandlord().then(res => {
			if (res.status == 200) {
				const list = res?.data?.data;
				// list[0] = [`0-0`, 0];
				this.setState({ propertiesPerLandlordList: list })
			}
		})
	}

	handleBarClick = (propertyCont) => {
		this.props.history.push({
			pathname: `/users/index`,
			state: { propertyCont: propertyCont, filtersPropertyPerLandlord: this.state.filtersPropertyPerLandlord }
		});
	};

	render() {
		return (
			<Card>
				<CardBody className="dashboard-item">
					<div className="box-header">
						<div className="">
							<h3 className="title">Indicador de imóveis por <br style={{ display: this.props.layout?.w === 12 ? 'none' : '' }} />responsável</h3>
							<div className="box-info" style={{ marginLeft: 20 }}>
								Aquisição
							</div>

							{this.props.layout?.w === 12 ? (
								<div className="box-filter" style={{ marginLeft: 16 }}>
									<FiltersModulePropertyPerLandlord setState={this.setState.bind(this)} state={this.state} />
								</div>
							) : null}
						</div>

						<div>
							<FontAwesomeIcon icon={this.props.layout?.w === 6 ? faMaximize : faMinimize} style={{ color: '#5664D2', padding: '12px', cursor: 'pointer' }} onClick={() => this.props.toggleMaximize(this.props?.itemKey)} />
							<FontAwesomeIcon icon={faEyeSlash} style={{ color: '#5664D2', padding: '12px', cursor: 'pointer' }} onClick={() => this.props.changeWidgetValue(this.props.itemKey)} />
							<FontAwesomeIcon icon={faGripVertical} style={{ color: '#DADBDD', padding: '12px', cursor: 'pointer' }} />
						</div>
					</div>

					{this.props.layout?.w === 6 ? (
						<div className="box-filter">
							<FiltersModulePropertyPerLandlord setState={this.setState.bind(this)} state={this.state} />
						</div>
					) : null}

					<div style={{ position: 'relative' }}>
						<hr className="divisor" />
					</div>

					<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '436px' }}>
						<div style={{ overflow: 'auto', border: '1px solid #DADBDD', padding: 24, borderRadius: 6, width: '100%' }}>
							<Chart
								key={this.props.layout?.w}
								chartType="Bar"
								chartLanguage="pt-br"
								data={[["Quantidade de imóveis", ""],
								...this.state?.propertiesPerLandlordList
								]}
								width={this.props.layout?.w === 6 ? '450px' : '1110px'}
								height="280px"
								options={{
									colors: ["#5664D2"],
									legend: {
										position: "none",
									},
								}}
								chartEvents={[{
									callback: ({ chartWrapper, google }) => {
										const chart = chartWrapper.getChart();
										google.visualization.events.addListener(chart, "select", () => {
											const selection = chart.getSelection();
											if (selection.length === 0) return;
											const selectedItem = selection[0];
											const data = chartWrapper.getDataTable();
											const landlord = data.getValue(selectedItem.row, 0);
											const quantity = data.getValue(selectedItem.row, 1);
											this.handleBarClick(landlord);
										});
									},
									eventName: "ready"
								}]}
							/>
						</div>
					</div>
				</CardBody>
			</Card>
		)
	}
}


