/* eslint-disable no-fallthrough */
/* eslint-disable array-callback-return */
import React, { Component, createRef } from 'react';
import { Container, Card, Row, Col, Label, Input } from "reactstrap";
import Breadcrumbs from '../../../components/Breadcrumb';
import BetterAlerts from '../../../components/BetterAlerts';
import BetterCircularProgress from '../../../components/BetterCircularProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCheck, faChevronRight, faCircleX, faFilter, faMagnifyingGlass, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faArrowDownToLine, faArrowRotateRight, faUserMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { createNewShipment, getSecBillings, getSecShipmentById, downloadRemFile, deleteSecShipment } from '../../../crud/billings.crud';
import { formatBRL } from '../../../helpers/Utils';
import DataTableListSecBillings from '../../../components/datatables/DataTableListSecBillings';
import { Link } from "react-router-dom";
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';

class SecuritizationShipmentShow extends Component {
	dataTableRef = createRef();

	state = {
		flags: {
			created_at_lte: null,
			created_at_gte: null,
			status: null,
			landlord: null
		},
		filters: {
			created_at_lte: null,
			created_at_gte: null,
			status: null,
			landlord: null
		},
		is_filter_open: false,
		errors: [],
		success: [],
		activeTab: '1',
		filtersCount: 0,
		newFilters: [],
		indicadores: {},
		totalAtual: 0,
		list_users: [],
		termo_user: '',
		shipment_billings: [],
		shipment_id: null,
		shipment: null,
		isLatestShipment: false,
		isDeletable: false,
		showModalDeleteShipment: false,
		agreeCancelar: false
	};

	componentDidMount = () => {
		getSecShipmentById(this.props.match.params.id).then((response) => {
			if (response.status == 200) {
				this.setState({ shipment: response.data }, () => {
					this.checkIfDeletable();
				});
			}
		})

		if (this.props?.location?.state?.success) {
			this.setState({ success: [this.props.location.state.success] })
		}
	};

	saveFilters = () => {
		localStorage.setItem('shipmentFilter', JSON.stringify(this.state.filters));
		localStorage.setItem('shipmentFlags', JSON.stringify(this.state.flags));
	};

	fetchByFilter = (data) => {
		var params = {
			...data,
			shipment_id: this.props.match.params.id
		};

		return getSecBillings(params);
	};

	createShipment = async () => {
		this.setState({ loading: true });
		if (this.state.shipment_billings.length == 0) {
			alert('Não é possível criar uma remessa sem cobranças');
			return;
		}

		const body = this.state.shipment_billings.map((billing) => {
			return billing.id
		})

		await createNewShipment({ shipment_billings: body }).catch((error) => { }).then((response) => {
			if (response.status == 200) {
				this.setState({ loading: false });
				this.setState({ shipment_id: response.data?.data?.id })
				this.setState({ success: ['Remessa criada com sucesso'] })
			}
		});
	}

	checkIfDeletable = async () => {
		try {
			const response = await getSecBillings({ $limit: 1, $sort: { created_at: '-1' } });
			console.log(response.data.data);

			if (response.status === 200 && response.data.data.length > 0) {
				const isLatest = response.data.data[0].shipment_id === this.props.match.params.id;
				const isDeletable = isLatest && (this.state.shipment?.status === 'GENERATED' || this.state.shipment?.status === 'SENT');

				this.setState({ isLatestShipment: isLatest, isDeletable: isDeletable });
			}
		} catch (error) {
			console.error('Erro ao verificar status da remessa', error);
		}
	}

	deleteShipment = async () => {
		if (!this.state.isDeletable) {
			this.setState({ errors: ['Apenas a última remessa com status "Gerada" ou "Enviada" pode ser excluída.'] });
			return;
		}

		if (window.confirm('Tem certeza que deseja excluir esta remessa? Todos os pagamentos inclusos ficarão disponíveis novamente.')) {
			await deleteSecShipment(this.props.match.params.id).catch((error) => {
				this.setState({ errors: ['Erro ao excluir remessa. Tente novamente.'] });
			}).then((response) => {
				if (response.status == 200) {
					this.setState({ success: ['Remessa deletada com sucesso'] });

					setTimeout(() => {
						this.props.history.push('/pagamento/securitizacao/index');
					}, 2000);
				}
			});
		}
	}

	calcFaceVolume = () => {
		let volume = 0;

		this.state.shipment_billings.map((billing) => {
			volume += billing.value;
		})

		return formatBRL(volume);
	}

	handleDownloadRem = async () => {
		try {
			const response = await downloadRemFile(this.props.match.params.id);

			const currentDate = new Date();
			const day = String(currentDate.getDate()).padStart(2, '0');
			const month = String(currentDate.getMonth() + 1).padStart(2, '0');
			const suffix = this.state?.shipment?.batch?.toString().padStart(2, '0');
			const fileName = `CB${day}${month}${suffix}.REM`;

			const blob = new Blob([response.data], { type: 'text/plain' });
			const blobURL = URL.createObjectURL(blob);

			const link = document.createElement('a');
			link.href = blobURL;
			link.download = fileName;
			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
			URL.revokeObjectURL(blobURL);
		} catch (error) {
			console.error('Falha ao baixar a remessa', error);
		} finally {
			this.setState({ success: ['Download do arquivo .REM teve sucesso!'] })
		}
	}

	FiltersModule = ({ filtroModular, index }) => {
		const getFilterChoised = (filter) => {
			let component = <></>

			if (filter == 'due_date') {
				component = (
					<>
						<div className='d-flex'>
							<Label htmlFor="due_date_gte" className="col-form-label">De</Label>
							<Input className="form-control" type="date" id="due_date_gte" value={this.state.filters.due_date_gte} style={{ width: 200, margin: '0 16px' }}
								onChange={(e) => this.setState({ filters: { ...this.state.filters, due_date_gte: e.target.value } }, () => this.saveFilters())} />
						</div>

						<div className='d-flex'>
							<Label htmlFor="due_date_lte" className="col-form-label">Até</Label>
							<Input className="form-control" type="date" id="due_date_lte" value={this.state.filters.due_date_lte} style={{ width: 200, margin: '0 16px' }}
								onChange={(e) => this.setState({ filters: { ...this.state.filters, due_date_lte: e.target.value } }, () => this.saveFilters())} />
						</div>
					</>
				)
			} else if (filter == 'status') {
				component = (
					<div className='d-flex'>
						<select onChange={(e) => this.setState({ filters: { ...this.state.filters, status: e.target.value } }, () => this.saveFilters())} style={{ width: 400, margin: '0 16px' }} className="custom-select">
							<option value="" hidden>Selecionar</option>
							<option value="WAITING_CNAB">Esperando CNAB</option>
							<option value="SECURITIZED">Securitizado</option>
							<option value="REJECTED">Rejeitada</option>
						</select>
					</div>
				)
			} else if (filter == 'landlord') {
				component = (
					<div className='d-flex'>
						<select onChange={(e) => this.setState({ filters: { ...this.state.filters, landlord: e.target.value } }, () => this.saveFilters())} style={{ width: 400, margin: '0 16px' }} className="custom-select">
							<option value="" hidden>Selecionar</option>
							<option value="mellro_tec">Mell.ro TEC</option>
							<option value="mellro_sec">Mell.ro SEC</option>
							<option value="landlord">Responsável</option>
						</select>
					</div>
				)
			} else {
				component = <></>
			}

			return component;
		}

		const onSetFilter = (filtro) => {
			let stateFilter = this.state.newFilters.slice();
			stateFilter[index] = filtro;

			this.setState({ newFilters: stateFilter })
		}

		const getSelectOptions = (nameFiltro) => {
			const stateFilter = this.state.newFilters;

			if (stateFilter.includes(nameFiltro)) {
				return 'd-none'
			} else {
				return ''
			}
		}

		const removeFilter = (filterUnit, filterName) => {
			let stateFilter = this.state.newFilters;

			stateFilter.splice(filterUnit, 1);

			switch (filterName) {
				case 'due_date':
					this.setState({
						flags: { ...this.state.flags, due_date_gte: false, due_date_lte: false },
						filters: { ...this.state.filters, due_date_gte: null, due_date_lte: null }
					}, () => this.saveFilters())

					break;

				case 'status':
					this.setState({
						flags: { ...this.state.flags, status: false },
						filters: { ...this.state.filters, status: null }
					}, () => this.saveFilters())

					break;

				case 'landlord':
					this.setState({
						flags: { ...this.state.flags, landlord: false },
						filters: { ...this.state.filters, landlord: null }
					}, () => this.saveFilters())

				default:
					break;
			}

			this.setState({ newFilters: stateFilter })
		}

		return (
			<Col md={12} className="d-flex m-0 p-0 filtroUnit">
				<div className='d-flex'>
					<select onChange={({ target }) => onSetFilter(target.value)} value={this.state.newFilters[index]} className="custom-select" style={{ width: 300 }}>
						<option value="">Selecione um filtro</option>
						<option className={getSelectOptions('due_date')} value="due_date">Vencimento</option>
						<option className={getSelectOptions('status')} value="status">Status</option>
						<option className={getSelectOptions('landlord')} value="landlord">Dono da cobrança</option>
					</select>

					{this.state.newFilters[index] != '' && (
						<div className='divisionArrow'>
							<FontAwesomeIcon icon={faChevronRight} />
						</div>
					)}

					{getFilterChoised(this.state.newFilters[index])}
				</div>

				<div onClick={() => removeFilter(index, this.state.newFilters[index])}>
					<FontAwesomeIcon icon={faTimes} />
				</div>
			</Col>
		)
	}


	render() {

		return (
			<React.Fragment>
				<div className="page-content container-page userIndex sec-shipment">
					<Container fluid>
						<div className='header'>
							<h1>Remessas e Retornos</h1>

							<Breadcrumbs breadcrumbItems={[{ link: '/pagamento/cobrancas/index', title: 'Pagamentos' }, { link: '/pagamento/securitizacao/index', title: 'Remessas e Retornos' }, { link: '/pagamento/securitizacao/remessa/show', title: `Detalhes da remessa ${this.state.shipment?.batch}` }]} />
						</div>

						<BetterAlerts errors={this.state.errors} success={this.state.success} />
						<div className="userShow userNew  anuncio negociacao">
							<div className='boxUser'>
								<div className='headerUser' style={{ justifyContent: 'normal', gap: '32px', alignItems: 'flex-start', border: 'none' }}>
									<div className='tagsAssets groupAlign' style={{ flex: 'none' }}>
										<h3>Sequencial da Remessa {this.state.shipment?.batch}</h3>

										<div className='tagGroup'>
											{this.state.shipment?.status == 'GENERATED' && (
												<div className='tag' style={{ backgroundColor: '#EFA200' }}>Gerado</div>
											)}

											{this.state.shipment?.status == 'SENT' && (
												<div className='tag' style={{ backgroundColor: '#5664D2' }}>Enviado</div>
											)}

											{this.state.shipment?.status == 'PROCESSED' && (
												<div className='tag' style={{ backgroundColor: '#1CBB8C' }}>Processado</div>
											)}
										</div>
									</div>

									<div className='tagsAssets groupAlign' style={{ border: 'none', flex: 'none' }}>
										<div className='boxInformation'>
											<p>Usuário responsável</p>
											<div>
												<FontAwesomeIcon icon={faUserMagnifyingGlass} style={{ color: '#4AA3FF', marginRight: 8 }} />
												<Link to={`/users/show/${this.state?.shipment?.responsable_id}`} style={{ color: "#4AA3FF", fontWeight: 'bold' }}>{this.state?.shipment?.responsable_infos?.name}</Link>
											</div>
										</div>
									</div>

									<div className='tagsAssets groupAlign' style={{ flex: 'none' }}>
										<div className='boxInformation'>
											<p>Data de criação</p>
											<p style={{ fontWeight: 600, fontFamily: 'Inter', color: '#343A40' }}>{moment(this.state?.shipment?.created_at).format('DD/MM/YYYY HH:mm:ss')}</p>
										</div>
									</div>

									<div className='tagsAssets groupAlign' style={{ flex: 'none' }}>
										<div className='boxInformation'>
											<p>Atualizado em</p>

											<p style={{ fontWeight: 600, fontFamily: 'Inter', color: '#343A40' }}>{moment(this.state?.shipment?.updated_at).format('DD/MM/YYYY HH:mm:ss')}</p>

										</div>
									</div>
								</div>

								<hr style={{ width: 'calc(100% + 48px)', margin: '0 -24px', border: 'none', height: '1px', background: '#EEE' }} />

								{this.state?.shipment?.billings_securitized ? (
									<div className='bodyAssets'>
										<div className='boxInformation'>
											<p>Categoria</p>

											<div className='tagGroup'>
												<div className='tag' style={{ backgroundColor: '#FF3D60', height: '24px', width: '101px', fontSize: 13 }}>Dívida</div>
											</div>
										</div>

										<div className='boxInformation'>
											<p style={{ marginBottom: 10 }}>Sequencial da remessa</p>
											<b>{this.state.shipment?.batch}</b>
										</div>

										<div className='boxInformation'>
											<p style={{ marginBottom: 10 }}>Cobranças totais</p>
											<b>{this.state.shipment?.total_billings}</b>
										</div>

										<div className='boxInformation'>
											<p style={{ marginBottom: 10 }}>Cobranças securitizados</p>
											<b>{this.state.shipment?.billings_securitized}</b>
										</div>

										<div className='boxInformation'>
											<p style={{ marginBottom: 10 }}>Volume face total</p>
											<b>{formatBRL(this.state.shipment?.total_value ?? 0)}</b>
										</div>

										<div className='boxInformation'>
											<p style={{ marginBottom: 10 }}>Volume face securitizado</p>
											<b>{formatBRL(this.state.shipment?.securitized_face ?? 0)}</b>
										</div>
									</div>
								) : null}

								{this.state.shipment?.category && this.state.shipment?.category?.includes('ANTICIPATION') ? (
									<div className='bodyAssets'>
										<div className='boxInformation'>
											<p>Categoria</p>

											<div className='tagGroup'>
												<div className='tag' style={{ backgroundColor: '#5664D2', height: '24px', width: '101px', fontSize: 13 }}>Antecipação</div>
											</div>
										</div>

										<div className='boxInformation'>
											<p style={{ marginBottom: 10 }}>Sequencial da remessa</p>
											<b>{this.state.shipment?.batch}</b>
										</div>

										<div className='boxInformation'>
											<p style={{ marginBottom: 10 }}>Antecipações totais</p>
											<b>{this.state.shipment?.anticipations_total_billings}</b>
										</div>

										<div className='boxInformation'>
											<p style={{ marginBottom: 10 }}>Antecipações securitizados</p>
											<b>{this.state.shipment?.anticipations_billings_securitized}</b>
										</div>

										<div className='boxInformation'>
											<p style={{ marginBottom: 10 }}>Volume face total</p>
											<b>{formatBRL(this.state.shipment?.anticipations_total_value ?? 0)}</b>
										</div>

										<div className='boxInformation'>
											<p style={{ marginBottom: 10 }}>Volume face securitizado</p>
											<b>{formatBRL(this.state.shipment?.anticipations_securitized_face ?? 0)}</b>
										</div>
									</div>
								) : null}

								<hr style={{ width: 'calc(100% + 48px)', margin: '0 -24px', border: 'none', height: '1px', background: '#EEE' }} />

								<div className='footerAssets'>
									<button className='btnSecundary' onClick={() => this.handleDownloadRem()}>
										DOWNLOAD REM
										<FontAwesomeIcon icon={faArrowDownToLine} />
									</button>

									<button
										className='btnRed'
										onClick={() => this.deleteShipment()}
										disabled={!this.state.isDeletable}
										style={{ opacity: this.state.isDeletable ? 1 : 0.5 }}
									>
										EXCLUIR REMESSA
										<FontAwesomeIcon icon={faCircleX} />
									</button>
								</div>
							</div>
						</div>

						<Col md={12} className="box-filtros">
							<Col md={12} className="d-flex m-0 p-0">
								<div className='generalSearch'>
									<Input className="inputCustomNew" type="text" value={this.state.filters.searchAll} placeholder="Busque por contrato, CPF ou CNPJ do sacado"
										onChange={(e) => this.setState({ filters: { ...this.state.filters, searchAll: e.target.value } }, () => this.saveFilters())} />

									<FontAwesomeIcon icon={faMagnifyingGlass} />
								</div>

								<button onClick={() => this.setState({ newFilters: [...this.state.newFilters, ''] })} className="btnSecundary" style={{ width: 'max-content', margin: '0px 8px', padding: '8px 12px' }}>
									FILTRO
									<FontAwesomeIcon icon={faFilter} />
								</button>

								<button onClick={() => this.dataTableRef.current.refreshTable()} className="btnSecundary" style={{ width: 'max-content', margin: '0px 8px', padding: '8px 12px' }}>
									ATUALIZAR
									<FontAwesomeIcon icon={faArrowRotateRight} />
								</button>
							</Col>

							{this.state.newFilters.map((filtroModular, index) => {
								return <this.FiltersModule filtroModular={filtroModular} key={index} index={index} />
							})}
						</Col>

						<Row>
							<Col lg={12}>
								<Card className='card-general'>
									<div className='datatable-box'>
										<BetterCircularProgress loading={this.state.loading}>
											<DataTableListSecBillings filters={this.state.filters} noDelete fetchEvent={this.fetchByFilter} crudButtons={true}
												crudUrl="/pagamento/negativacao/index" idRow={`id`} export={this.state.export} ref={this.dataTableRef} state={this.state.shipment_billings} setState={this.setState.bind(this)} />
										</BetterCircularProgress>
									</div>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>

				<Modal dialogClassName="modalCancelarNegociacao" centered show={this.state?.showModalDeleteShipment} onHide={() => this.setState({ showModalDeleteShipment: false, agreeCancelar: false })}>
					<Modal.Header closeButton={false}>
						<Modal.Title>Excluir Remessa</Modal.Title>

						<button onClick={() => this.setState({ showModalDeleteShipment: false, agreeCancelar: false })} style={{ backgroundColor: 'transparent', color: '#8590A5', display: 'flex', alignItems: 'center', border: 'none', fontWeight: '900', fontSize: '18px' }}>
							X
						</button>
					</Modal.Header>
					<Modal.Body>
						<div style={{ marginTop: 16 }}>
							<h3>Você tem certeza que deseja excluir esta remessa?</h3>

							<p>Ao continuar, esta remessa será excluída, e as cobranças nela contidas poderão ser securitizadas novamente.</p>

							<Label className="pl-2 input-radio-group">
								<Input type="radio" name={'agreeCancelar'} id={`agreeCancelar`} value={!this.state.agreeCancelar} onChange={() => this.setState({ agreeCancelar: !this.state.agreeCancelar })} color="primary" checked={this.state.agreeCancelar} />
								<Label style={{ padding: "3px" }} for={`agreeCancelar`}>
									Confirmo que desejo excluir esta remessa.
								</Label>
							</Label>
						</div>
					</Modal.Body >
					<Modal.Footer>
						<button className='btnSecundary' onClick={this.handleClose}>
							<FontAwesomeIcon icon={faArrowLeft} />
							VOLTAR
						</button>

						<button className='btnRed' disabled={!this.state.agreeCancelar} style={{ opacity: this.state.agreeCancelar ? 1 : 0.5 }} onClick={this.deleteShipment}>
							EXCLUIR REMESSA
							<FontAwesomeIcon icon={faCheck} />
						</button>
					</Modal.Footer>
				</Modal>
			</React.Fragment>
		);
	}
}

export default SecuritizationShipmentShow;