import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatBRL } from "../../helpers/Utils";
import { Link } from "react-router-dom";
import moment from "moment";
import smile from '../../assets/images/icons-custom/face-smile.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faRedo } from "@fortawesome/pro-regular-svg-icons";
import { faUserMagnifyingGlass } from "@fortawesome/pro-solid-svg-icons";

export default class DataTableReturn extends Component {
	constructor() {
		super();

		this.state = {
			property: {},
			data: null,
			rows: 10,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: 'id',
			sortOrder: 1,
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters !== this.props.filters) {
			this.fetchData();
		}
	}

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = async (event) => {
		this.setState({
			loading: true,

		});
		if (event.data) {
			event.data = null;
		}

		this.setState({
			...this.state,
			rows: event.rows,
		});
		this.fetchData(event.rows, event.first);
	};

	fetchData = async (limit, skip) => {
		this.setState({ loading: true });

		let options = {
			$limit: limit ? limit : this.state.rows,
			$skip: skip != null ? skip : this.state.first,
			$sort: { [this.state.sortField]: this.state.sortOrder },
		};

		if (this.props.filters.status) options.status = this.props.filters.status;
		if (this.props.filters.created_at_gte) options.created_at_gte = this.props.filters.created_at_gte;
		if (this.props.filters.created_at_lte) options.created_at_lte = moment(this.props.filters.created_at_lte).add(1, 'days').format('YYYY-MM-DD');

		if (this.props.filters?.searchAll) {
			options.searchAll = this.props.filters.searchAll;
		}

		return this.props.fetchEvent(options).then(async (res) => {
			// this.props.updateCounter(res?.data?.total);

			if (res.data.data.length == 0) {
				this.setState({
					...this.state,
					data: [],
					totalRecords: res.data.total,
					first: skip,
					last: skip + res.data.limit,
					loading: false
				});
			}

			this.props.setState({ totalReturn: res.data.total });

			this.setState({
				...this.state,
				data: res.data.data,
				totalRecords: res.data.total,
				first: skip,
				last: skip + res.data.limit,
				loading: false
			});
		}).finally(() => {
			this.setState({ loading: false });
		});
	};

	sortTable = async (e) => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder, loading: true }, () => {
			let shipmentoDataTable = {
				rows: this.state.rows,
				totalRecords: this.state.totalRecords,
				first: this.state.first,
				last: this.state.last,
				loading: this.state.loading,
				sortField: this.state.sortField,
				sortOrder: this.state.sortOrder,
				data: this.state.data
			};
			localStorage.setItem("shipmentoDataTable", JSON.stringify(shipmentoDataTable));

			this.fetchData();
		});
	};

	getFileName = (obj) => {
		return (
			<div>
				<p>{obj?.file?.initial_name ?? 'ARQUIVO.RET'}</p>
			</div>
		)
	}

	getDateFormatted = (date) => {
		return (
			<p>
				{date ? moment(date).format("DD/MM/YYYY") : '-'}
			</p>
		);
	};

	getResponsable = (obj) => {
		return (
			<div>
				<FontAwesomeIcon icon={faUserMagnifyingGlass} style={{ color: '#4AA3FF', marginRight: 8 }} />
				<Link to={`/users/show/${obj?.responsable_id}`} style={{ color: "#4AA3FF" }}>{obj?.responsable_infos?.name}</Link>
			</div>
		)
	}

	export() {
		this.dt.exportCSV();
	}

	getOptions = (obj) => {
		return (
			<div className="d-flex justify-content-center p-2 align-items-center">
				<div>
					<Link to={obj?.status === 'PROCESSED' ? `/pagamento/securitizacao/retorno/show/${obj.id}` : `/pagamento/securitizacao/retorno/preview/${obj.id}`} style={{ width: 34 }}>
						<FontAwesomeIcon icon={faChevronRight} style={{ color: '#5664D2', fontSize: 14 }} />
					</Link>
				</div>
			</div>
		)
	}

	emptyMessageReturn() {
		return (
			<div className="boxImage">
				<img src={smile} alt="smile" width={48} height={48} />

				<p className="subtitleNoItems">Nenhum registro encontrado</p>
			</div>
		)
	}

	render() {
		return (
			<div style={{ position: 'relative' }}>
				<DataTable ref={(el) => this.dt = el} value={this.state.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={[10, 15, 30]} first={this.state.first} last={this.state.last}
					totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} responsive={true} loadingIcon={`fas fa-sync fa-spin`} onSort={(e) => this.sortTable(e)}
					paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" emptyMessage={this.emptyMessageReturn}
					currentPageReportTemplate="" sortField={this.state.sortField} sortOrder={this.state.sortOrder} pageLinkSize={3} id="custom-table-1">

					<Column sortable field="file" body={(obj) => this.getFileName(obj)} style={{ width: '74px', padding: '22px 16px 22px 16px' }} header="Arquivo" />

					<Column sortable field="uploaded_at" body={(obj) => this.getDateFormatted(obj?.uploaded_at)} style={{ width: '104px', padding: '22px 16px 22px 16px' }} header="Upload" />

					<Column field="securitized_face" sortable body={(obj) => formatBRL(obj?.securitized_face ?? 0)} header="Securitizado face" style={{ width: '320px', textAlign: "flex-start" }} />

					<Column sortable field="responsable_id" body={(obj) => this.getResponsable(obj)} style={{ width: '170px', padding: '22px 16px 22px 16px' }} header="Usuário responsável" />

					<Column header="" body={(obj) => this.getOptions(obj)} style={{ width: '34px', padding: 0 }} />
				</DataTable>

				<div className="paginator-new-items">
					<p>Registros por página</p>
					<Link className="custom-see-all-table" to={`/pagamento/cobrancas/index`} >
						Ver todos
					</Link>
				</div>
			</div>
		);
	}
}
