import React, { Component } from 'react';
import { Container, Card, CardBody, Col, FormGroup, Label, TabContent, TabPane, Nav, NavItem, NavLink, Input, } from "reactstrap";
import BetterCircularProgress from '../../components/BetterCircularProgress';
import classnames from 'classnames';
import { getById as getNotification } from '../../crud/notifications.crud';
import { Link } from "react-router-dom";
import Breadcrumbs from '../../components/Breadcrumb';

class NotificationsShow extends Component {

    state = {
        loading: false,
        activeTab: '1',
        data: null,
    }

    componentDidMount = () => {
        this.setState({ loading: true });

        getNotification(this.props.match.params.id).catch(err => {
            alert('An error occurred, please contact the support.');
        }).then(res => {
            if (res?.status == 200) {
                if (res.data) {
                    this.setState({ data: res.data.data });
                }
            }

            this.setState({ loading: false });
        });
    };

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    };

    render() {
        return (
            <React.Fragment>
                <BetterCircularProgress loading={this.state.loading}>
                    <div className="page-content container-page">
                        <Container fluid>
                            <Breadcrumbs title="Visualizar notificação" breadcrumbItems={[]} />

                            <Card>
                                <CardBody>
                                    <Nav tabs className="nav-tabs-custom" role="tablist">
                                        <NavItem>
                                            <NavLink id="info-tab" className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggleTab('1'); }} >INFO</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink id="announcements-tab" className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggleTab('2'); }}>DADOS</NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={this.state.activeTab} className="border border-top-0 p-4">
                                        <TabPane tabId="1" role="tabpanel">
                                            <div className="row">
                                                <Col md={6}>
                                                    <FormGroup row>
                                                        <Label className="col-md-4 col-form-label">ID</Label>
                                                        <Col md={8} className="container-center-input">
                                                            <Input className="form-control" type="text" value={this.state.data?.id} disabled />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>

                                                <Col md={6}>
                                                    <FormGroup row>
                                                        <Label className="col-md-4 col-form-label">Categoria</Label>
                                                        <Col md={8} className="container-center-input">
                                                            <Input className="form-control" type="text" value={this.state.data?.category} disabled />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>

                                                <Col md={6}>
                                                    <FormGroup row>
                                                        <Label className="col-md-4 col-form-label">Canal</Label>
                                                        <Col md={8} className="container-center-input">
                                                            <Input className="form-control" type="text" value={this.state.data?.channel} disabled />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>

                                                <Col md={6}>
                                                    <FormGroup row>
                                                        <Label className="col-md-4 col-form-label">Título</Label>
                                                        <Col md={8} className="container-center-input">
                                                            <Input className="form-control" type="text" value={this.state.data?.title} disabled />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>

                                                <Col md={6}>
                                                    <FormGroup row>
                                                        <Label className="col-md-4 col-form-label">Texto</Label>
                                                        <Col md={8} className="container-center-input">
                                                            <Input className="form-control" type="text" value={this.state.data?.text} disabled />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>

                                                <Col md={6}>
                                                    <FormGroup row>
                                                        <Label className="col-md-4 col-form-label">Usuário</Label>
                                                        <Col md={8} className="container-center-input">
                                                            <Input className="form-control" type="text" value={this.state.data?.user.name} disabled />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>

                                                <Col md={6}>
                                                    <FormGroup row>
                                                        <Label className="col-md-4 col-form-label">Criado em</Label>
                                                        <Col md={8} className="container-center-input">
                                                            <Input className="form-control" type="text" value={this.state.data?.created_at} disabled />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>

                                                <Col md={6}>
                                                    <FormGroup row>
                                                        <Label className="col-md-4 col-form-label">Enviado em</Label>
                                                        <Col md={8} className="container-center-input">
                                                            <Input className="form-control" type="text" value={this.state.data?.dispatched_at} disabled />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>

                                                <Col md={6}>
                                                    <FormGroup row>
                                                        <Label className="col-md-4 col-form-label">Entrega adiada até</Label>
                                                        <Col md={8} className="container-center-input">
                                                            <Input className="form-control" type="text" value={this.state.data?.delay_delivery_until} disabled />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="2" role="tabpanel">
                                            <div className="row">

                                            </div>
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>

                            <Card>
                                <CardBody className="container-space-between">
                                    <Link to='/notifications/index' className="btn btn-danger btn-bold"><i className="fa fa-arrow-left mr-2"></i>Voltar</Link>
                                </CardBody>
                            </Card>
                        </Container>
                    </div>
                </BetterCircularProgress>
            </React.Fragment>
        )
    }
}

export default NotificationsShow;