import { store as storeProposition, update as updateProposition } from "../../../crud/propositions.crud";
import { convertToBool, getPayDay, safeJsonParser, snakeToNormalCase, messageError } from './';

const onSubmit = (values, update, commissioning = false) => {
	let plan = safeJsonParser(values.plan);

	const base_rent = Number(values.announcement.values.base_rent) || 0;

	let security_deposit_months = 0;
	let security_deposit_value = 0;
	if (typeof values.security_deposit_months == 'undefined') values.security_deposit_months = 0;

	if (values?.security_deposit_months !== values.announcement.warranty?.security_deposit_months) {
		security_deposit_months = Number(values.security_deposit_months);
		security_deposit_value = values.announcement.values.base_rent * values.security_deposit_months;
	} else {
		security_deposit_months = values.announcement.warranty.security_deposit_months ? Number(values.announcement.warranty.security_deposit_months) : 0;
		security_deposit_value = values.announcement.values.base_rent && values.announcement.warranty.security_deposit_months ?
			values.announcement.values.base_rent * values.announcement.warranty.security_deposit_months
			: 0;
	}

	if (values.percentage) {
		plan.percentage = values.percentage;
	}

	const data = {
		tenant_id: values?.tenant?.id,
		property_announcement_id: values?.announcement?.id,
		plan_id: plan?.id,
		plan: plan,
		status: values?.status ?? '',
		tenant_parties: values?.tenant_parties?.map(({ id }) => (id)),
		origin: 'ADMIN',
		details: {
			payday: String(getPayDay(values?.start_date)),
			duration: Number(values?.duration),
			start_date: values?.start_date,
			security_deposit_months,
			security_deposit_value,
			warranty_type: plan?.name,
			values: {
				...values?.announcement?.values,
				iptu: Number(values?.announcement?.values?.iptu) || 0,
				condo: Number(values?.announcement?.values?.condo) || 0,
				rent: base_rent,
				base_rent
			},
			tenant_pays: {
				...values.announcement.tenant_pays,
				iptu: convertToBool(values?.announcement?.tenant_pays?.iptu),
				condo: convertToBool(values?.announcement?.tenant_pays?.condo),
				other: false
			},
		},
	};

	if (commissioning) {
		data.commissioning = true;
	}

	return (update ? updateProposition(values.id, data) : storeProposition(data))
		.catch(err => {
			const error = [];

			if (err?.response?.status === 500) {
				error.push("Existe um contrato em vigor para esse imóvel.");
			} else {
				error.push(snakeToNormalCase(err.message));

				if (err.response?.data) {
					error.push(snakeToNormalCase(messageError(err.response.data?.message)));
				}

				if (Array.isArray(err.response.data?.details)) {
					err.response.data.details.forEach(detail => {
						error.push(messageError(detail?.message));
					});
				}
			}

			throw error;
		});
};


export default onSubmit;