import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Link } from "react-router-dom";
import { Column } from 'primereact/column';
import _ from 'lodash';
import moment from 'moment';
import { CSVLink } from "react-csv";
import { formatBRL } from '../../helpers/Utils';

export default class DataTableCoupons extends Component {
	constructor() {
		super();

		this.state = {
			data: [],
			rows: 10,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: 'id',
			sortOrder: 1,
			fetchingCsvData: false,
			csvData: [],
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		let couponsDataTable = JSON.parse(localStorage.getItem('couponsDataTable'));

		if (couponsDataTable) {
			this.setState({
				rows: couponsDataTable.rows,
				totalRecords: couponsDataTable.totalRecords,
				first: couponsDataTable.first,
				last: couponsDataTable.last,
				loading: couponsDataTable.loading,
				sortField: couponsDataTable.sortField,
				sortOrder: couponsDataTable.sortOrder,
			}, () => {
				this.fetchData();
			});
		} else {
			this.fetchData();
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters != this.props.filters) {
			this.onFilterChanged();
		}
	}

	onFilterChanged = _.debounce(() => {
		if (!this.state.isLoading) {
			this.fetchData();
		}
	}, 700);

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = (event) => {
		if (event.data) {
			event.data = null;
		}

		let couponsDataTable = {
			rows: event.rows,
			totalRecords: this.state.totalRecords,
			first: event.first,
			last: event.rows + event.first,
			loading: this.state.loading,
			sortField: this.state.sortField,
			sortOrder: this.state.sortOrder,
		}

		localStorage.setItem('couponsDataTable', JSON.stringify(couponsDataTable));

		this.setState({
			...this.state,
			rows: event.rows,
			loading: true
		});

		this.fetchData(event.rows, event.first);
	};

	fetchData = (limit, skip) => {
		let data = {
			$limit: limit ? limit : this.state.rows,
			$skip: skip != null ? skip : this.state.first,
			$sort: { [this.state.sortField]: this.state.sortOrder }
		};

		this.props.fetchEvent(data).catch(() => { }).then(res => {
			this.setState({
				...this.state,
				data: res?.data?.data,
				totalRecords: res?.data?.total,
				first: res?.data?.skip,
				last: res?.data?.skip + res?.data?.limit,
				loading: false
			});
		});
	}

	columnOpcoesCrud = (rowData) => {
		let idRow = this.props.idRow ? this.props.idRow : 'id';

		if (this.props.crudUrl) {
			return (
				<div style={{ textAlign: 'center' }}>
					<Link to={`${this.props.crudUrl}/edit/${rowData[idRow]}`} className="btn btn-success btn-table-action mr-2"><i className="fa fa-edit"></i></Link>
					<Link to={`${this.props.crudUrl}/show/${rowData[idRow]}`} className="btn btn-primary btn-table-action mr-2"><i className="fas fa-eye"></i></Link>
				</div>
			);
		} else {
			return null;
		}
	};

	// export() {
	// 	this.setState({ fetchingCsvData: true });

	// 	let data = {
	// 		$limit: this.state.totalRecords,
	// 		$skip: 0,
	// 		$sort: { [this.state.sortField]: this.state.sortOrder }
	// 	};

	// 	this.props.fetchEvent(data).then(res => {
	// 		let csvData = [['Nome', 'Email', 'Telefone', 'Origem do cadastro', 'Cidade', 'Último login', 'Contexto']];

	// 		res.data.data.forEach((obj, i) => {
	// 			csvData.push([
	// 				obj.name ?? '',
	// 				obj.email ?? '',
	// 				obj.phone ?? '',
	// 				obj.signup_source ?? '',
	// 				obj.address?.city ?? '',
	// 				obj.updated_at ? moment(obj.updated_at).format('DD/MM/YYYY HH:mm:ss') : '',
	// 			])
	// 		});

	// 		this.setState({ csvData }, () => document.getElementById('csv_button').click());
	// 	}).finally(() => this.setState({ fetchingCsvData: false }));
	// };

	sortTable = e => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder }, () => {
			let couponsDataTable = {
				rows: this.state.rows,
				totalRecords: this.state.totalRecords,
				first: this.state.first,
				last: this.state.last,
				loading: this.state.loading,
				sortField: this.state.sortField,
				sortOrder: this.state.sortOrder,
			};

			localStorage.setItem('couponsDataTable', JSON.stringify(couponsDataTable));

			this.fetchData();
		});
	};

	getDateFormatted = (date) => {
		return (
			<p>{date ? moment(date).format('DD/MM/YYYY HH:mm:ss') : 'N/A'}</p>
		)
	};

	getDiscountValue = (obj) => {
		let value = '';

		if (obj.discount_type === 'PERCENTAGE') value = `${obj.discount_amount}%`;
		if (obj.discount_type === 'FIXED_VALUE') value = formatBRL(obj.discount_amount);

		return (
			<p>{value}</p>
		)
	};

	getPlan = (obj) => {
		return (
			<p>{obj?.plan?.friendly_name ?? 'N/A'}</p>
		)
	};

	getUsers = (coupon) => {
		let coupon_users = [];
		let added_users = [];

		if (coupon.users) {
			coupon.users.forEach((obj, i) => {
				if (!added_users.includes(obj.user_id)) {
					coupon_users.push(obj);
					added_users.push(obj.user_id);
				}
			});
		}

		if (coupon_users?.length > 2) {
			return (
				<p>Múltiplos</p>
			)
		} else if (coupon_users?.length <= 2 && coupon_users?.length > 0) {
			return (
				<div>
					{coupon_users.map((obj, i) => {
						return (
							<Link style={{ marginRight: 5 }} key={`user_${i}`} to={`/users/show/${obj.user_id}`}>{obj.user.name};</Link>
						)
					})}
				</div>
			)
		} else {
			return (
				<p>Geral</p>
			)
		}
	};

	render() {
		const header = (
			<div style={{ float: 'right' }}>
				<Button className="tableHeaderItem" icon={!this.state.loading ? "fas fa-sync" : "fas fa-sync fa-spin"} disabled={this.state.loading ? "disabled" : ""} onClick={() => this.refreshTable()} />
			</div>
		);

		return (
			<DataTable ref={(el) => this.dt = el} value={this.state.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={[10, 25, 50]}
				first={this.state.first} last={this.state.last} header={this.props.noHeader ? null : header} responsive={true}
				totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} loadingIcon={`fas fa-sync fa-spin`}
				paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
				currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} registros" emptyMessage="Nenhum registro encontrado!"
				onSort={(e) => this.sortTable(e)} sortField={this.state.sortField} sortOrder={this.state.sortOrder} >
				<Column sortable field="code" header="Código" />
				<Column field="users" body={(obj) => this.getUsers(obj)} style={{ width: '120px' }} header="Usuários" />
				<Column sortable field="application_count" style={{ width: '120px' }} header="Aplicações" />
				<Column field="users" body={(obj) => this.getPlan(obj)} style={{ width: '120px' }} header="Plano" />
				<Column sortable field="discount_amount" body={(obj) => this.getDiscountValue(obj)} style={{ width: '120px' }} header="Desconto" />
				<Column sortable field="expiration_date" body={(obj) => this.getDateFormatted(obj.expiration_date)} style={{ width: '120px' }} header="Data de expiração" />
				<Column header="Ações" body={this.columnOpcoesCrud} style={{ width: '130px' }} />
			</DataTable>
		);
	}
}