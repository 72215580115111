import React, { Component } from 'react';
import { Container, Card, CardBody, Col, FormGroup, Label, Input } from "reactstrap";
import BetterCircularProgress from '../../components/BetterCircularProgress';
import { get as getUser } from '../../crud/users.crud';
import { getById } from '../../crud/vouchers.crud';
import { Link } from "react-router-dom";
import Breadcrumbs from '../../components/Breadcrumb';

class VouchersShow extends Component {

    state = {
        loading: false,
        data: null,
        password: '',
        user: null
    }

    componentDidMount = () => {
        this.setState({ loading: true });

        getById(this.props.match.params.id).catch(err => {
            alert('An error occurred, please contact the support.');
        }).then(response => {
            if (response?.status === 200) {
                if (response.data) {
                    this.setState({ data: response.data.data });

                    getUser(response.data.data.owner_id).catch(err => {
                        alert('An error occurred, please contact the support.');
                    }).then(res => {
                        if (res?.status === 200) {
                            if (res.data) {
                                this.setState({ user: res.data.data });
                            }
                        }
                    });
                }
            }

            this.setState({ loading: false });
        });
    };

    render() {
        return (
            <React.Fragment>
                <BetterCircularProgress loading={this.state.loading}>
                    <div className="page-content container-page">
                        <Container fluid>
                            <Breadcrumbs title="Visualizar voucher" breadcrumbItems={[]} />

                            <Card>
                                <CardBody>
                                    <div className="row">
                                        <Col md={6}>
                                            <FormGroup row>
                                                <Label className="col-md-2 col-form-label">Código</Label>
                                                <Col md={9} className="container-center-input">
                                                    <Input className="form-control" type="text" value={this.state.data?.code ? this.state.data?.code : 'N/A'} disabled />
                                                </Col>
                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>
                                            <FormGroup row>
                                                <Label className="col-md-2 col-form-label">Usuário</Label>
                                                <Col md={9} className="container-center-input">
                                                    <Input className="form-control" type="text" value={this.state.user?.name ? this.state.user?.name : 'N/A'} disabled />
                                                </Col>
                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>
                                            <FormGroup row>
                                                <Label className="col-md-2 col-form-label">Data de validade</Label>
                                                <Col md={9} className="container-center-input">
                                                    <Input className="form-control" type="text" value={this.state.data?.expiration_date ? this.state.data?.expiration_date : 'N/A'} disabled />
                                                </Col>
                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>
                                            <FormGroup row>
                                                <Label className="col-md-2 col-form-label">Foi aplicado</Label>
                                                <Col md={9} className="container-center-input">
                                                    {this.state.data?.is_applied ?
                                                        <i className="ri-checkbox-circle-line align-middle"></i>
                                                        :
                                                        <i className="ri-close-circle-line align-middle"></i>
                                                    }
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </div>
                                </CardBody>
                            </Card>

                            <Card>
                                <CardBody className="container-space-between">
                                    <Link to='/vouchers/index' className="btn btn-danger btn-bold"><i className="fa fa-arrow-left mr-2"></i>Voltar</Link>
                                </CardBody>
                            </Card>
                        </Container>
                    </div>
                </BetterCircularProgress>
            </React.Fragment>
        )
    }
}

export default VouchersShow;