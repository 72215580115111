import axios from "axios";
import { Constants } from '../helpers/Constants';
import { parseQuery } from "../helpers/Utils";

export function getLetterOfAttorney(contract_id, params) {
	return axios.get(`${Constants.baseUrl}/contracts/letter-of-attorney-logs/${contract_id}`, { params: parseQuery(params) });
}

export function getLetterOfAttorneyTemplate(contract_id) {
	return axios.get(`${Constants.baseUrl}/contracts/letter-of-attorney-template/${contract_id}`);
}
