import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";
import { FiltersModuleDueDateListIndicators, transformDataToBarChart } from "../filters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faGripVertical, faMaximize, faMinimize } from '@fortawesome/pro-solid-svg-icons';
import Chart from "react-google-charts";

export default class DueDateDistIndicatorSection extends Component {
	constructor() {
		super();

		this.state = {
			metrics: [],
			dueDateDistIndicatorsFilters: {
				"billing_type": "all"
			},
			dropdownKey: null,
			anchorEl: null,
		};
	}

	componentDidMount() {
		if (this?.props?.metrics) {
			this.setState({ metrics: this.props.metrics })
		}
	}

	render() {
		return (
			<Card>
				<CardBody className="dashboard-item">
					<div className="box-header">
						<div className="">
							<h3 className="title">Gráfico de distribuição de<br /> datas de vencimento</h3>
							<div className="box-info">
								Financeiro
							</div>
						</div>

						<div>
							<FontAwesomeIcon icon={this.props.layout?.w === 6 ? faMaximize : faMinimize} style={{ color: '#5664D2', padding: '12px', cursor: 'pointer' }} onClick={() => this.props.toggleMaximize(this.props?.itemKey)} />
							<FontAwesomeIcon icon={faEyeSlash} style={{ color: '#5664D2', padding: '12px', cursor: 'pointer' }} onClick={() => this.props.changeWidgetValue(this.props.itemKey)} />
							<FontAwesomeIcon icon={faGripVertical} style={{ color: '#DADBDD', padding: '12px', cursor: 'pointer' }} />
						</div>
					</div>

					<div className="box-filter">
						<FiltersModuleDueDateListIndicators setState={this.setState.bind(this)} state={this.state.dueDateDistIndicatorsFilters} />
					</div>

					<div style={{ position: 'relative', marginTop: '10px' }}>
						<hr className="divisor" />
					</div>
					<div className="chart-wrapper graphic" style={{ marginBottom: '55px', marginTop: '90px' }}>

						<div className="chart-graphic-wrapper" style={{ padding: '24px 8px' }}>
							{this.state.metrics.find(obj => obj.filters.includes(this.state.dueDateDistIndicatorsFilters.billing_type))?.value ? (
								<Chart
									key={this.props.layout?.w}
									chartType="Bar"
									chartLanguage="pt-br"
									data={transformDataToBarChart(this.state.metrics.find(obj => obj.filters.includes(this.state.dueDateDistIndicatorsFilters.billing_type))?.value)}
									width={this.props.layout?.w === 6 ? '420px' : '1000px'}
									height="262px"
									options={{
										colors: ["#5664D2"],
										legend: {
											position: "none",
										},
										vAxis: {
											format: '#,##0 Mil', // Formata os valores com a tag "Mil"
										},
									}}
								/>
							) : null}
						</div>
					</div>

				</CardBody>
			</Card>
		)
	}
}

