import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatBRL } from "../../helpers/Utils";
import { Link } from "react-router-dom";
import moment from "moment";
import smile from '../../assets/images/icons-custom/face-smile.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { faArrowDownToLine, } from "@fortawesome/pro-solid-svg-icons";
import { Checkbox } from "@material-ui/core";

export default class DataTableSelectBillings extends Component {
	constructor() {
		super();

		this.state = {
			property: {},
			data: null,
			rows: 10,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: 'id',
			sortOrder: 1,
			checkAllBillings: false,
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters !== this.props.filters) {
			this.fetchData();
		}
	}

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = async (event) => {
		this.setState({
			loading: true,

		});
		if (event.data) {
			event.data = null;
		}

		this.setState({
			...this.state,
			rows: event.rows,
		});
		this.fetchData(event.rows, event.first);
	};

	fetchData = async (limit, skip) => {
		this.setState({ loading: true });

		let options = {
			$limit: limit ? limit : this.state.rows,
			$skip: skip != null ? skip : this.state.first,
			$sort: { [this.state.sortField]: this.state.sortOrder },
		};

		if (this.props.filters.status === 'waiting') {
			options.is_paid = false;
			options.due_date_gte = moment().add(1, 'days').format('YYYY-MM-DD');
			options.boletoIncluded = false;
		}
		if (this.props.filters.status === 'open') {
			options.is_paid = false;
			options.due_date_gte = moment().add(1, 'days').format('YYYY-MM-DD');
			options.boletoIncluded = true;
		}
		if (this.props.filters.status === 'warranty_and_late') {
			options.is_paid = false;
			options.in_agreement = true;
			options = {
				...options,
				receipts: { rent: { paid: false, paid_for_insurer: true } }
			}
		}
		if (this.props.filters.status === 'anticipated') {
			options.anticipated = true;
		}

		if (this.props.filters.due_date_gte) options.due_date_gte = this.props.filters.due_date_gte;
		if (this.props.filters.due_date_lte) options.due_date_lte = this.props.filters.due_date_lte;

		if (this.props.filters.landlord === 'mellro_tec') {
			options.is_paid = false;
			options.due_date_lte = moment().subtract(1, 'days').format('YYYY-MM-DD');
		}

		if (this.props.filters.landlord === 'landlord') {
			options.is_paid = false;
			options.due_date_gte = moment().subtract(1, 'days').format('YYYY-MM-DD');
		}

		if (this.props.filters?.searchAll) {
			options.searchAll = this.props.filters.searchAll;
		}

		return this.props.fetchEvent(options).then(async (res) => {
			// this.props.updateCounter(res?.data?.total);

			if (res.data.data.length == 0) {
				this.setState({
					...this.state,
					data: [],
					totalRecords: res.data.total,
					first: skip,
					last: skip + res.data.limit,
					loading: false
				});
			}

			this.setState({
				...this.state,
				data: res.data.data,
				totalRecords: res.data.total,
				first: skip,
				last: skip + res.data.limit,
				loading: false
			});

		}).finally(() => {
			this.setState({ loading: false });
		});
	};

	sortTable = async (e) => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder, loading: true }, () => {
			let shipmentoDataTable = {
				rows: this.state.rows,
				totalRecords: this.state.totalRecords,
				first: this.state.first,
				last: this.state.last,
				loading: this.state.loading,
				sortField: this.state.sortField,
				sortOrder: this.state.sortOrder,
				data: this.state.data
			};
			localStorage.setItem("shipmentoDataTable", JSON.stringify(shipmentoDataTable));

			this.fetchData();
		});
	};

	getValue = (obj) => {
		return (
			<div style={{ textAlign: "center", width: '105px' }}>
				<p>{formatBRL(obj || 0)}</p>
			</div>
		);
	};

	getStatus = (obj) => {
		if (obj?.receipts?.rent?.paid === true && (obj?.receipts?.rent?.paid_for_insurer === false || !obj?.receipts?.rent?.paid_for_insurer)) {
			return (
				<div className="bg-success d-flex flex-row" style={{ width: '98px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center', borderRadius: '2px' }}>
					<p className="col-form-label text-white" style={{ marginBottom: 0 }}>{obj?.receipts?.rent?.paid ? 'Fatura paga' : 'N/A'}</p>
				</div>
			)
		} else if (obj?.receipts?.rent?.paid_for_insurer == true && obj?.receipts?.rent?.paid == true) {
			return (
				<div className="d-flex flex-row" style={{ width: '140px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center', borderRadius: '2px', backgroundColor: '#5664D2' }}>
					<p className="col-form-label text-white" style={{ marginBottom: 0 }}>{obj?.receipts?.rent?.paid_for_insurer ? 'Garantido e pago' : 'N/A'}</p>
				</div>
			)
		} else if (obj?.receipts?.rent?.paid_for_insurer == true && obj?.receipts?.rent?.paid == false) {
			return (
				<div className="d-flex flex-row" style={{ width: '140px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center', borderRadius: '2px', backgroundColor: '#F06595' }}>
					<p className="col-form-label text-white" style={{ marginBottom: 0 }}>{obj?.receipts?.rent?.paid_for_insurer ? 'Garantido e atrasado' : 'N/A'}</p>
				</div>
			)
		} else if ((!obj?.receipts?.rent?.paid && !obj?.receipts?.rent?.paid_for_insurer) && moment().isAfter(obj?.due_date)) {
			return (
				<div className="bg-danger d-flex flex-row" style={{ width: '120px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center', borderRadius: '2px' }}>
					<p className="col-form-label text-white" style={{ marginBottom: 0 }}>Fatura atrasada</p>
				</div>
			)
		} else if (moment().isBefore(obj?.due_date) && obj?.boleto !== null) {
			return (
				<div className="bg-warning d-flex flex-row" style={{ width: '131px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center', borderRadius: '2px' }}>
					<p className="col-form-label text-white" style={{ marginBottom: 0 }}>Fatura em aberto</p>
				</div>
			)
		} else {
			return (
				<div className="bg-secondary d-flex flex-row" style={{ width: '98px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center', borderRadius: '2px' }}>
					<p className="col-form-label text-white" style={{ marginBottom: 0 }}>Aguardando</p>
				</div>
			)
		}
	};

	getAnticipationStatus = (obj) => {
		if (obj?.anticipated) {
			return <div className="d-flex flex-row" style={{ width: '98px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center', borderRadius: '2px', backgroundColor: '#5664D2' }}>
				<p className="col-form-label text-white" style={{ marginBottom: 0 }}>Antecipado</p>
			</div>
		}
	}

	getBillValue = (obj) => {
		if (obj.anticipated) {
			let anticipatedSum = obj.anticipated_values.reduce((acc, curr) => acc + curr.retained_value, 0);
			return formatBRL(anticipatedSum);
		} else {
			return formatBRL(obj.values.start_value);
		}
	}

	getDateFormatted = (date) => {
		return (
			<p>
				{date ? moment(date).add(3, 'hours').format("DD/MM/YYYY") : '-'}
			</p>
		);
	};

	getResponsable = (obj) => {
		return (
			<div>
				{/* <FontAwesomeIcon icon={faUserMagnifyingGlass} style={{ color: '#4AA3FF', marginRight: 8 }} /> */}
				<Link to={`/users/show/${obj?.landlord?.id}`} style={{ color: "#4AA3FF" }}>{obj?.landlord?.name}, </Link>
				<Link to={`/users/show/${obj?.tenant?.id}`} style={{ color: "#4AA3FF" }}>{obj?.tenant?.name}</Link>
			</div>
		)
	}

	getContractFriendlyName = (obj) => {
		return (
			<div>
				{/* <FontAwesomeIcon icon={faUserMagnifyingGlass} style={{ color: '#4AA3FF', marginRight: 8 }} /> */}
				<Link to={`/contracts/show/${obj?.contract_id}`} style={{ color: "#4AA3FF" }}>{obj?.contract?.entity?.friendly_code}</Link>
			</div>
		)
	}

	getSelectBill = (obj) => {
		if (this.props?.state) {
			return <>
				<Checkbox name="check_landlord_data" color="primary" checked={this.props.state.find((bill) => bill.id === obj.id) ? true : false} onChange={() => {
					if (this.props.state.find((bill) => bill.id === obj.id)) {
						this.props.setState({
							shipment_billings: this.props.state.filter((bill) => bill.id !== obj.id)
						});
					} else {
						this.props.setState({
							shipment_billings: [...this.props.state, { id: obj.id, value: obj.values.start_value, anticipated_values: obj?.anticipated_values }]
						});
					}
				}} />
			</>
		}
	}

	selectAllBills = () => {
		return <>
			<Checkbox name="check_landlord_data" color="primary" checked={this.state.checkAllBillings ? true : false} onChange={() => {
				if (this.state.checkAllBillings) {
					this.props.setState({
						shipment_billings: []
					});
					this.setState({ checkAllBillings: false });
				} else {
					this.props.setState({
						shipment_billings: this.state.data.map((obj) => {
							return { id: obj.id, value: obj.values.start_value, anticipated_values: obj.anticipated_values }
						})
					});
					this.setState({ checkAllBillings: true });
				}
			}} />
		</>
	}

	export() {
		this.dt.exportCSV();
	}

	getOptions = (obj) => {
		return (
			<div className="d-flex justify-content-center p-2 align-items-center">
				<div>
					<button onClick={() => alert("TO DO dowload .rem")} style={{ background: 'transparent', border: 'none', cursor: 'pointer', width: 34, marginRight: 20 }}>
						<FontAwesomeIcon icon={faArrowDownToLine} style={{ color: '#5664D2', fontSize: 14 }} />
					</button>
				</div>
				<div>
					<Link to={`pagamento/securitizacao/show/${obj.id}`} style={{ width: 34 }}>
						<FontAwesomeIcon icon={faChevronRight} style={{ color: '#5664D2', fontSize: 14 }} />
					</Link>
				</div>
			</div>
		)
	}

	emptyMessageReturn() {
		return (
			<div className="boxImage">
				<img src={smile} alt="smile" width={48} height={48} />

				<p className="subtitleNoItems">Nenhum registro encontrado</p>
			</div>
		)
	}

	render() {
		return (
			<div style={{ position: 'relative' }}>
				<DataTable ref={(el) => this.dt = el} value={this.state.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={[10, 15, 30]} first={this.state.first} last={this.state.last}
					totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} responsive={true} loadingIcon={`fas fa-sync fa-spin`} onSort={(e) => this.sortTable(e)}
					paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" emptyMessage={this.emptyMessageReturn}
					currentPageReportTemplate="" sortField={this.state.sortField} sortOrder={this.state.sortOrder} pageLinkSize={3} id="custom-table-2">

					<Column field="" body={(obj) => this.getSelectBill(obj)} style={{ width: '74px', padding: '22px 16px 22px 16px' }} header={this.selectAllBills()} />

					<Column field="contract" body={(obj) => this.getContractFriendlyName(obj)} header="Contrato" style={{ width: '100px', padding: '22px 16px 22px 16px' }} />

					<Column field="status" body={(obj) => {
						return <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
							{this.getStatus(obj)}
							{this.getAnticipationStatus(obj)}
						</div>
					}} header="Status" style={{ width: '129px', padding: '22px 16px 22px 16px' }} />

					<Column sortable field="due_date" body={(obj) => this.getDateFormatted(obj?.due_date)} style={{ width: '104px', padding: '22px 16px 22px 16px' }} header="Vencimento" />

					<Column field="receipts.rent.value" sortable body={(obj) => this.getBillValue(obj)} header="Valor face" style={{ width: '320px', textAlign: "flex-start" }} />

					<Column sortable field="responsable_id" body={(obj) => this.getResponsable(obj)} style={{ width: '170px', padding: '22px 16px 22px 16px' }} header="Constituintes" />
				</DataTable>

				<div className="paginator-new-items">
					<p>Registros por página</p>
					{/* <Link className="custom-see-all-table" to={`/pagamento/cobrancas/index`} >
						Ver todos
					</Link> */}
				</div>
			</div>
		);
	}
}
