import axios from "axios";
import { Constants } from '../helpers/Constants';
import { parseQuery } from '../helpers/Utils';

export function getAllByFilter(params) {
	return axios.get(`${Constants.baseUrl}/doc-users`, { params: parseQuery(params) });
}

export function get(id) {
	return axios.get(`${Constants.baseUrl}/doc-users/${id}`);
}

export function store(data) {
	return axios.post(`${Constants.baseUrl}/doc-users`, data);
}

export function deleteDoc(id) {
	return axios.delete(`${Constants.baseUrl}/doc-users/${id}`);
}

export function update(id, data) {
	return axios.patch(`${Constants.baseUrl}/doc-users/${id}`, data);
}

export function getInfos(params) {
	return axios.get(`${Constants.baseUrl}/doc-users/get-infos`, { params: parseQuery(params) });
}