import React, { Component } from 'react';
import { Container, Card, CardBody, Col, Label, Button, Input } from "reactstrap";
import BetterCircularProgress from '../../../components/BetterCircularProgress';
import { get as getUser, getAccountBank, storeBankAccount } from '../../../crud/users.crud';
import { getBankTypes } from '../../../helpers/bankHelper';
import { Link, Redirect } from "react-router-dom";
import { Formik } from "formik";
import Breadcrumbs from '../../../components/Breadcrumb';
import { TextField } from "@material-ui/core";
import { formatCPFInput } from '../../../helpers/Utils';

class BankAccountEdit extends Component {

	state = {
		loading: false,
		activeTab: '1',
		data: null,
		accountBankData: null,
		properties: null,
		raw: null,
		success: false,
		error: false,
		arrayBank: []
	}

	componentDidMount = () => {
		this.setState({ loading: true });

		getUser(this.props.match.params.id).catch(err => {
			alert('An error occurred, please contact the support.');
		}).then(res => {
			if (res?.status === 200) {
				if (res.data) {
					this.setState({ data: res.data.data });
				}
			}
		});

		getAccountBank(this.props.match.params.id).then(res => {
			if (res?.status === 200) {
				if (res.data) {
					this.setState({ accountBankData: res.data.data });
				}
			}

			this.setState({ loading: false });
		}).catch(err => {
			this.setState({ loading: false });
		});

		const data = getBankTypes();
		this.setState({ arrayBank: data });
	};

	onSubmit = (values) => {
		if (values.agency.length < 4) {
			alert('Agência invalida, mínimo 4 números.');
			return;
		}

		this.setState({ loading: true });

		let data = {
			is_owner: values.is_owner,
			bank: values.bank,
			agency: values.agency,
			account_type: values.account_type,
			account: values.account,
			pix_key: values.pix_key
		};

		if (!values.is_owner) {
			data.third_party_name = values.third_party_name;
			data.third_party_cpf = values.third_party_cpf;
		}

		storeBankAccount(this.props.match.params.id, data).catch(err => {
			this.setState({ loading: false, error: true });
			// props.addMessage({ type: 'danger', text: 'Ocorreu um erro ao alterar seus dados bancários.' });
		}).then(res => {
			if (res?.status === 200) {
				this.setState({ loading: false, success: true });
			}

			this.setState({ loading: false });
		});
	};

	getInitialValues = () => {
		return {
			bank: this.state?.accountBankData?.bank ? this.state?.accountBankData?.bank : '',
			agency: this.state?.accountBankData?.agency ? this.state?.accountBankData?.agency : '',
			account_type: this.state?.accountBankData?.account_type ? this.state?.accountBankData?.account_type : '',
			account: this.state?.accountBankData?.account ? this.state?.accountBankData?.account : '',
			pix_key: this.state?.accountBankData?.pix_key ? this.state?.accountBankData?.pix_key : '',
			is_owner: this.state?.accountBankData?.is_owner != null ? this.state?.accountBankData?.is_owner : true,
			third_party_name: this.state?.accountBankData?.third_party_name ? this.state?.accountBankData?.third_party_name : '',
			third_party_cpf: this.state?.accountBankData?.third_party_cpf ? this.state?.accountBankData?.third_party_cpf : '',
		};
	};

	render() {
		return (
			<React.Fragment>
				<BetterCircularProgress loading={this.state.loading}>
					<div className="page-content container-page">
						<Container fluid>
							<Breadcrumbs breadcrumbItems={[{ link: '/users/index', title: 'Usuários' }, { link: `/users/show/${this.state.data?.id}`, title: this.state.data?.name }, { link: `/bank-account/edit/${this.state.data?.id}`, title: 'Dados bancários' }]} />

							<Card>
								<CardBody>
									{this.state.error ?
										<Redirect to={{ pathname: `/users/show/${this.props.match.params.id}`, state: { errors: ['Erro ao adicionados dados bancários.'] } }} />
										: null
									}
									{this.state.success ?
										<Redirect to={{ pathname: `/users/show/${this.props.match.params.id}`, state: { success: ['Dados bancários adicionados com sucesso!'] } }} />
										: null
									}
									<Formik initialValues={this.getInitialValues()} onSubmit={(values) => this.onSubmit(values)} enableReinitialize={true}>
										{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
											return (
												<>
													<div className="row">
														<Col md={12} style={{ marginTop: "20px" }}>
															<Label className="col-md-4 col-form-label" style={{ color: '#343A40', fontSize: '13px' }}>A conta é do dono?</Label>
															<Col md={4} style={{ marginLeft: "20px" }}>
																<Input
																	id="isOwner"
																	type="radio"
																	name="is_owner"
																	value={true}
																	onChange={() => setFieldValue('is_owner', true)}
																	onBlur={handleBlur}
																	color="primary"
																	checked={values.is_owner}
																/>{" "}
																<Label style={{ padding: "3px" }} for="isOwner" >
																	Sim, a conta é do dono
																</Label>
															</Col>
															<Col md={4} style={{ marginLeft: "20px" }}>
																<Input
																	id="notOwner"
																	type="radio"
																	name="is_owner"
																	value={false}
																	onChange={() => setFieldValue('is_owner', false)}
																	onBlur={handleBlur}
																	color="primary"
																	checked={!values.is_owner}
																/>{" "}
																<Label style={{ padding: "3px" }} for="notOwner" >
																	Não, a conta é de um beneficiário
																</Label>
															</Col>
														</Col>
														{!values.is_owner &&
															<>
																<Col md={12} style={{ marginTop: "10px" }}>
																	<Label className="col-md-4 col-form-label" style={{ color: '#343A40', fontSize: '13px' }}>Nome do beneficiário</Label>
																	<Col md={4}>
																		<TextField
																			style={{ background: "#FFF", height: "40px" }}
																			name='third_party_name'
																			variant="outlined"
																			value={values.third_party_name}
																			onBlur={handleBlur}
																			onChange={handleChange}
																		/>
																	</Col>
																</Col>

																<Col md={12} style={{ marginTop: "10px" }}>
																	<Label className="col-md-4 col-form-label" style={{ color: '#343A40', fontSize: '13px' }}>CPF do beneficiário</Label>
																	<Col md={4}>
																		<TextField
																			style={{ background: "#FFF", height: "40px" }}
																			name='third_party_cpf'
																			variant="outlined"
																			value={values.third_party_cpf}
																			onBlur={handleBlur}
																			inputProps={{ maxLength: 14 }}
																			onChange={e => handleChange(formatCPFInput(e))}
																		/>
																	</Col>
																</Col>
															</>
														}
														<Col md={12}>
															<Label className="col-md-4 col-form-label" style={{ color: '#343A40', fontSize: '13px' }}>Banco</Label>
															<Col md={4}>
																<select
																	style={{ background: "#FFF", height: "40px" }}
																	name='bank'
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.bank}
																	className="custom-select">
																	<option key={null} value={null} hidden></option>
																	{this.state?.arrayBank
																		.map((bank) => {
																			return (
																				<option key={bank.code} value={bank.code}>{bank.name}</option>
																			);
																		})}
																</select>
															</Col>
														</Col>
														<Col md={12} style={{ marginTop: "20px" }}>
															<Label className="col-md-4 col-form-label" style={{ color: '#343A40', fontSize: '13px' }}>Agência</Label>
															<Col md={4}>
																<TextField
																	style={{ background: "#FFF", height: "40px" }}
																	name='agency'
																	variant="outlined"
																	value={values.agency}
																	onBlur={handleBlur}
																	placeholder="0000"
																	onChange={handleChange}
																/>
															</Col>
														</Col>
														<Col md={12} style={{ marginTop: "20px" }}>
															<Label className="col-md-4 col-form-label" style={{ color: '#343A40', fontSize: '13px' }}>Conta</Label>
															<Col md={4} style={{ marginLeft: "20px" }}>
																<Input
																	id="checking"
																	type="radio"
																	name="account_type"
																	value="checking"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	color="primary"
																	checked={values.account_type === 'checking' ? true : false}
																/>{" "}
																<Label style={{ padding: "3px" }} for="checking" >
																	Corrente
																</Label>
															</Col>
															<Col md={4} style={{ marginLeft: "20px" }}>
																<Input
																	id="savings"
																	type="radio"
																	name="account_type"
																	value="savings"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	color="primary"
																	checked={values.account_type === 'savings' ? true : false}
																/>{" "}
																<Label style={{ padding: "3px" }} for="savings" >
																	Poupança
																</Label>
															</Col>
														</Col>
														<Col md={12} style={{ marginTop: "10px" }}>
															<Label className="col-md-4 col-form-label" style={{ color: '#343A40', fontSize: '13px' }}>Número da conta</Label>
															<Col md={4}>
																<TextField
																	style={{ background: "#FFF", height: "40px" }}
																	name='account'
																	variant="outlined"
																	value={values.account}
																	onBlur={handleBlur}
																	placeholder="00000-0"
																	inputProps={{ maxLength: 10 }}
																	onChange={handleChange}
																/>
															</Col>
														</Col>
														<Col md={12} style={{ marginTop: "10px" }}>
															<Label className="col-md-4 col-form-label" style={{ color: '#343A40', fontSize: '13px' }}>Chave Pix</Label>
															<Col md={4}>
																<TextField
																	style={{ background: "#FFF", height: "40px" }}
																	name='pix_key'
																	variant="outlined"
																	value={values.pix_key}
																	onBlur={handleBlur}
																	onChange={handleChange}
																/>
															</Col>
														</Col>
													</div>
													<div className="row mt-4 ml-1">
														<Col md={6}>
															<Link to={`/users/show/${this.props.match.params.id}`} className="btn btn-secondary btn-bold"><i className="fa fa-arrow-left mr-2"></i>Voltar</Link>
															<Button
																color="success"
																type="submit"
																className="waves-effect waves-light ml-3"
																style={{ width: '158px' }}
																onClick={handleSubmit}
															>
																Salvar
															</Button>
														</Col>
													</div>
												</>
											);
										}}
									</Formik>
								</CardBody>
							</Card>
						</Container>
					</div>
				</BetterCircularProgress>
			</React.Fragment>
		)
	}
}

export default BankAccountEdit;