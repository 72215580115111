import axios from "axios";
import { Constants } from '../helpers/Constants';
import { parseQuery } from '../helpers/Utils';

export function getAllByFilter(params) {
	return axios.get(`${Constants.baseUrl}/contracts`, { params: parseQuery(params) });
}

export function get(id) {
	return axios.get(`${Constants.baseUrl}/contracts/${id}`);
}

export function store(data) {
	return axios.post(`${Constants.baseUrl}/contracts`, data);
}

export function restoreContract(id) {
	return axios.post(`${Constants.baseUrl}/contracts/restore/${id}`);
}

export function getContractAdminLogs(id, params) {
	return axios.get(`${Constants.baseUrl}/contracts/logs/${id}`, { params: parseQuery(params) });
}

export function createContractAdminLogs(data) {
	return axios.post(`${Constants.baseUrl}/contracts/logs/create`, data);
}

export function deleteContract(id) {
	return axios.delete(`${Constants.baseUrl}/contracts/${id}`);
}

export function cancelContract(id) {
	return axios.post(`${Constants.baseUrl}/contracts/cancel/${id}`);
}

export const getContractFile = async (contractFileId, type) => {
	const options = {
		method: 'get',
		baseURL: `${Constants.baseUrl}/contracts/file/${contractFileId}`,
		params: parseQuery({ type })
	};

	const interceptor = {
		response: (request) => {
			return request;
		},
		error: (error) => {
			if (error.response.status === 400) {
				return error.response.request.responseURL;
			}
			throw error;
		}
	};

	const id = axios.interceptors.response.use(
		interceptor.request,
		interceptor.error
	);

	return axios.request(options).finally(() => {
		axios.interceptors.response.eject(id);
	});
};

export const getContractFileSign = async (contractFileId) => {
	const options = {
		method: 'get',
		baseURL: `${Constants.baseUrl}/contracts/file/${contractFileId}`,
		params: parseQuery({ type: 'sign' })
	};

	const interceptor = {
		response: (request) => {
			console.log(request);
			return request;
		},
		error: (error) => {
			if (error.response.status === 400) {
				return error.response.request.responseURL;
			}
			throw error;
		}
	};

	const id = axios.interceptors.response.use(
		interceptor.request,
		interceptor.error
	);

	return axios.request(options).finally(() => {
		axios.interceptors.response.eject(id);
	});
};

export function terminateContract(data) {
	return axios.post(`${Constants.baseUrl}/contracts/termination/admin`, data);
}

export const listContractsWithUsersData = () => {
	return axios.get(`${Constants.baseUrl}/contracts/get-contract-and-users`);
}

export function updateContractPercentage(data, contract_id) {
	return axios.post(`${Constants.baseUrl}/contracts/update-plan/${contract_id}`, data);
}

export function updateStopGuarantee(data, contract_id) {
	return axios.post(`${Constants.baseUrl}/contracts/update-stop-guarantee/${contract_id}`, data);
}

export function updateRetainValues(data, contract_id) {
	return axios.post(`${Constants.baseUrl}/contracts/update-retain-value/${contract_id}`, data);
}

export function getContractUsers(contract_id) {
	return axios.get(`${Constants.baseUrl}/contracts/contract-users?contract_id=${contract_id}`);
}

export function queryContractualEdits(params) {
	return axios.get(`${Constants.baseUrl}/contractual-edits`, {
		params: parseQuery(params)
	});
}

export function getContractualEdit(id) {
	return axios.get(`${Constants.baseUrl}/contractual-edits/${id}`);
}

export function cancelContractualEdit(id) {
	return axios.delete(`${Constants.baseUrl}/contractual-edits/${id}`);
}

export function createContractualEdit(data) {
	return axios.post(`${Constants.baseUrl}/contractual-edits`, data);
}


export function deleteContractualEdit(id) {
	return axios.delete(`${Constants.baseUrl}/contractual-edits/${id}`);
}

export function getContractualEditFile(editId) {
	const options = {
		method: 'get',
		baseURL: `${Constants.baseUrl}/contractual-edits/file/${editId}`,
		params: parseQuery({ type: 'sign' })
	};

	const interceptor = {
		response: (request) => {
			console.log(request);
			return request;
		},
		error: (error) => {
			if (error.response.status === 400) {
				return error.response.request.responseURL;
			}
			throw error;
		}
	};

	const id = axios.interceptors.response.use(
		interceptor.request,
		interceptor.error
	);

	return axios.request(options).finally(() => {
		axios.interceptors.response.eject(id);
	});
}

export function resendContractualEditNotifications(id) {
	return axios.post(`${Constants.baseUrl}/contractual-edits/resend-notifications/${id}`);
}
export function updateCommissionOnContract(id, data) {
	return axios.post(`${Constants.baseUrl}/contracts/update-commission/${id}`, data);
}

export function getContractsToBeJudialized(params) {
	return axios.get(`${Constants.baseUrl}/contracts/contracts-to-be-judicialized`, { params: parseQuery(params) });
}

export function getContractsOfUser(params) {
	return axios.get(`${Constants.baseUrl}/contracts/contract-users`, { params: parseQuery(params) });
}