import axios from "axios";
import { Constants } from '../helpers/Constants';

export function get() {
    return axios.get(`${Constants.baseUrl}/plans`);
}

export async function getPlanById(id) {
    return axios.get(`${Constants.baseUrl}/plans/${id}`);
}

export function update(id, data) {
    return axios.patch(`${Constants.baseUrl}/plans/${id}`, data);
}

export function store(data) {
    return axios.post(`${Constants.baseUrl}/plans`, data);
}

export function remove(id) {
    return axios.delete(`${Constants.baseUrl}/plans/${id}`);
}
