import React, { Component, createRef } from 'react';
import { Container, Card, Row, Col } from "reactstrap";
import Breadcrumbs from '../../../components/Breadcrumb';
import BetterAlerts from '../../../components/BetterAlerts';
import BetterCircularProgress from '../../../components/BetterCircularProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, } from '@fortawesome/pro-regular-svg-icons';
import { faUserMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { getAnticipation, getAnticipationsFile, get as getBillings } from '../../../crud/billings.crud';
import { formatBRL } from '../../../helpers/Utils';
import { Link } from "react-router-dom";
import moment from 'moment';
import { get as getUser } from '../../../crud/users.crud';
import DataTableAnticipatedBillings from '../../../components/datatables/DataTableAnticipatedBillings';

class AnticipationShow extends Component {
	dataTableRef = createRef();

	state = {
		is_filter_open: false,
		errors: [],
		success: [],
		anticipation: null,
		responsable: null,
		retained_billings: 0,
		total_billings: 0,
		contracts: [],
		anticipationFile: null,
	};

	componentDidMount = () => {
		getAnticipation(this.props.match.params.id).then(async (response) => {
			if (response.status == 200) {
				this.setState({ anticipation: response.data.data })

				await getAnticipationsFile(this.state.anticipation?.id).then((response) => {
					this.setState({ anticipationFile: response.data })
				})

				await getUser(response.data.data.created_by).then((response) => {
					if (response.status == 200) {
						this.setState({ responsable: response.data.data })
					}
				})
			}
		})

		getBillings({ anticipations_ids_arraycts: this.props.match.params.id, anticipated: true, is_paid: true }).then((response) => {
			if (response.status == 200) {
				this.setState({ retained_billings: response.data.total })
			}
		})

		if (this.props?.location?.state?.success) {
			this.setState({ success: [this.props.location.state.success] })
		}
	};

	saveFilters = () => {

	};

	fetchByFilter = (data) => {
		var params = {
			...data,
			anticipations_ids_arraycts: this.props.match.params.id,
			anticipated: true
		};

		return getBillings(params);
	};

	render() {

		return (
			<React.Fragment>
				<div className="page-content container-page userIndex">
					<Container fluid>
						<div className='header'>
							<h1>Detalhes da antecipação</h1>

							<Breadcrumbs breadcrumbItems={[{ link: '/users/index', title: 'Usuários' }, { link: `/users/show/${this.state.anticipation?.user_id}`, title: this.state.anticipation?.user_id?.length > 6 ? 'ID ' + this.state.anticipation?.user_id.slice(0, 6) + "..." : 'ID ' + this.state.anticipation?.user_id }, { link: '/pagamento/securitizacao/remessa/show', title: `Detalhes da antecipação` }]} />
						</div>

						<BetterAlerts errors={this.state.errors} success={this.state.success} fullWidth={true} />
						<div className="userShow userNew  anuncio negociacao">
							<div className='boxUser' style={{ paddingLeft: 0, paddingRight: 0 }}>
								<div className='headerUser' style={{ paddingTop: '10px', justifyContent: 'left', gap: 32, paddingLeft: 24, paddingRight: 24 }}>
									<div className='boxInformation'>
										<p style={{ marginBottom: '0px' }}>Data de registro</p>

										<p style={{ fontWeight: 600, fontFamily: 'Inter', color: '#343A40', marginBottom: '0px' }}>{moment(this.state?.anticipation?.updated_at).format('DD/MM/YYYY HH:mm:ss')}</p>

									</div>


									<div className='boxInformation'>
										<p style={{ marginBottom: '0px' }}>Pagamento</p>

										<p style={{ fontWeight: 600, fontFamily: 'Inter', color: '#343A40', marginBottom: '0px' }}>{moment(this.state?.anticipation?.anticipation_date).add(3, 'hours').format('DD/MM/YYYY')}</p>
									</div>

									<div className='boxInformation'>
										<p style={{ marginBottom: '0px' }}>Data de transferência</p>

										<p style={{ fontWeight: 600, fontFamily: 'Inter', color: '#343A40', marginBottom: '0px' }}>{this.state?.anticipation?.transfered_at ? moment(this.state?.anticipation?.transfered_at).format('DD/MM/YYYY') : '--'}</p>
									</div>

									<div className='boxInformation'>
										<p style={{ marginBottom: '0px' }}>Início da retenção</p>

										<p style={{ fontWeight: 600, fontFamily: 'Inter', color: '#343A40', marginBottom: '0px' }}>{moment(this.state?.anticipation?.start_date).add(3, 'hours').format('DD/MM/YYYY')}</p>
									</div>

									<div className='boxInformation'>
										<p style={{ marginBottom: '0px' }}>Cobranças a reter</p>

										<p style={{ fontWeight: 600, fontFamily: 'Inter', color: '#343A40', marginBottom: '0px' }}>{this.state.retained_billings}/{this.state.total_billings}</p>
									</div>

									<div className='boxInformation'>
										<p style={{ marginBottom: '0px' }}>Solicitante</p>
										<div>
											<FontAwesomeIcon icon={faUserMagnifyingGlass} style={{ color: '#4AA3FF', marginRight: 8 }} />
											<Link to={`/users/show/${this.state?.responsable?.id}`} style={{ color: "#4AA3FF", fontWeight: 'bold' }}>{this.state?.responsable?.name}</Link>
										</div>
									</div>
								</div>

								<div className='bodyAssets' style={{ paddingLeft: 24, paddingRight: 24 }}>
									<div className='boxInformation'>
										<p>Mensais de</p>
										<b>{formatBRL(this.state.anticipation?.monthly_retained_value ?? 0)}</b>
									</div>

									<div className='boxInformation'>
										<p>Período retido</p>
										<b>{this.state.anticipation?.anticipation_period} {this.state.anticipation?.anticipation_period > 1 ? 'meses' : 'mês'}</b>
									</div>

									<div className='boxInformation'>
										<p>Valor total retido</p>
										<b>{formatBRL(this.state.anticipation?.total_retained_value ?? 0)}</b>
									</div>

									<div className='boxInformation'>
										<p>Valor antecipado</p>
										<b>{formatBRL(this.state.anticipation?.anticipation_value ?? 0)}</b>
									</div>
								</div>

								<div className='footerAssets' style={{ paddingLeft: 24, paddingRight: 24 }}>
									<a className='buttonPrimary' href={this.state.anticipationFile} target='_blank' rel="noreferrer">
										VER TERMO
										<FontAwesomeIcon icon={faFile} />
									</a>
								</div>
							</div>
						</div>

						<Row>
							<Col lg={12}>
								<Card className='card-general'>
									<p style={{ color: '#8590A5', fontSize: '16px', fontWeight: 'bold', fontFamily: 'Inter', marginBottom: 30 }}>COBRANÇAS ANTECIPADAS</p>
									<div className='datatable-box'>
										<BetterCircularProgress loading={this.state.loading}>
											<DataTableAnticipatedBillings filters={this.state.filters} noDelete fetchEvent={this.fetchByFilter} crudButtons={true}
												crudUrl="/pagamento/negativacao/index" idRow={`id`} export={this.state.export} ref={this.dataTableRef} state={this.state.shipment_billings} setState={this.setState.bind(this)} />
										</BetterCircularProgress>
									</div>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}

export default AnticipationShow;