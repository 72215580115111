import React, { Component } from 'react';
import { Container, Card, CardBody, Col, FormGroup, Label, Button, Nav, NavItem, NavLink } from "reactstrap";
import classnames from 'classnames';
import BetterCircularProgress from '../../components/BetterCircularProgress';
import { get as getContract, updateContractPercentage } from '../../crud/contracts.crud';
import { getPlanById } from "../../crud/plans.crud";
import { Link } from "react-router-dom";
import Breadcrumbs from '../../components/Breadcrumb';
import BetterAlerts from '../../components/BetterAlerts';
import { Formik } from "formik";
import { contractPercentageEdit } from "../../helpers/FormValidators";
import { TextField, InputAdornment } from '@material-ui/core';

class ContractsEdit extends Component {
	state = {
		loading: true,
		success: [],
		errors: [],
		data: null,
		activeTab: '1',
		planName: '',
		max_percentage: 10,
		min_percentage: 5
	}

	componentDidMount = () => {
		this.setState({ loading: true });

		if (this.props.location.state) {
			if (this.props.location.state.success) {
				this.setState({ success: this.props.location.state.success });
			}
		}

		getContract(this.props.match.params.id).catch(err => {
			alert('Ocorreu um erro ao recuperar os dados do contrato.');
		}).then(res => {
			if (res?.status == 200) {
				if (res.data) {
					this.setState({ data: res.data.data });
					let contract = res.data.data;

					if (contract.entity?.plan?.name === 'WARRANTY') {
						this.setState({ min_percentage: 5, max_percentage: 7 });
					} else if (contract.entity?.plan?.name === 'WARRANTY_GUARANTEE_DAMAGE') {
						this.setState({ min_percentage: 7, max_percentage: 10 });
					} else {
						alert('Ocorreu um erro ao recuperar os dados do plano.');
					}

					if (this.state.data?.entity?.proposition?.plan_id) {
						getPlanById(this.state.data?.entity?.proposition?.plan_id).then((res) => {
							if (res?.status == 200) {
								const planName = res.data.data.friendly_name[0].toUpperCase() + res.data.data.friendly_name.slice(1).toLowerCase();
								this.setState({ ...this.state, planName: planName });
							}
						});
					}
				}
			}
		}).finally(() => {
			this.setState({ loading: false });
		});
	};

	isNumber(n) {
		return !isNaN(parseFloat(n)) && isFinite(n);
	}

	onSubmit = (values) => {
		this.setState({ loading: true, errors: [] });

		if (this.state.data?.entity?.plan?.name === 'WARRANTY' && (values.percentage < 5 || values.percentage > 7)) {
			return this.setState({ loading: false, errors: ['Selecione uma porcentagem entre 5% e 7%.'] });
		} else if (this.state.data?.entity?.plan?.name === 'WARRANTY_GUARANTEE_DAMAGE' && (values.percentage < 7 || values.percentage > 10)) {
			return this.setState({ loading: false, errors: ['Selecione uma porcentagem entre 7% e 10%.'] });
		}

		if (!this.isNumber(values.percentage)) {
			return this.setState({ loading: false, errors: ['Apenas números são aceitos.'] });
		}

		updateContractPercentage({ percentage: values.percentage }, this.state.data.entity.id).catch((err) => {
			return this.setState({ errors: ['Ocorreu um erro, entre em contato com o suporte.'] });
		}).then((res) => {
			if (res?.status == 200) {
				this.setState({ errors: [], success: ['Dados salvos.'] });
				this.setState({
					data: {
						...this.state.data,
						entity: {
							...this.state.data.entity,
							plan: {
								...this.state.data.entity.plan,
								percentage: values.percentage
							}
						}
					}
				});
			}
		}).finally(() => {
			this.setState({ loading: false });
		})
	};

	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({ activeTab: tab });
		}
	};

	formatDate(date) {
		const newDate = date?.split('/')[1] + '/' + date?.split('/')[0] + '/' + date?.split('/')[2];
		return newDate;
	}

	renderPlan = () => {
		return (
			this.state.data?.entity?.property?.warranty?.security_deposit_months > 0 ?
				<p className="col-form-label" style={{ fontSize: '13px', lineHeight: '16px' }}>{"- " + this.state.data?.entity?.property?.warranty?.security_deposit_months + " caução de entrada"}</p>
				:
				<p className="col-form-label" style={{ fontSize: '13px', lineHeight: '16px' }}>Sem garantia</p>
		)
	}

	getInitialValues = () => {
		return {
			percentage: this.state?.data?.entity?.plan?.percentage ? this.state.data?.entity?.plan?.percentage : ''
		};
	};

	render() {
		return (
			<React.Fragment>
				<BetterCircularProgress loading={this.state.loading}>
					<div className="page-content container-page">
						<Container fluid>
							<Breadcrumbs breadcrumbItems={[{ link: '/contracts/index', title: 'Contrato' }, { link: `/contracts/show.${this.state.data?.entity?.id}`, title: 'Editar' }]} />
							<BetterAlerts success={this.state.success} errors={this.state.errors} />
							{this.state.data?.entity?.proposition?.status === "FINALIZED" || this.state.data?.entity?.proposition?.status === "AWAITING_SIGN" ?
								<>
									<p>Não é possível editar uma negociação com contrato já assinado ou aguardando assinatura. Status atual: {this.state.currentStatus}</p>
								</>
								:
								<Card>
									<CardBody>
										<Nav tabs className="nav-tabs-custom" role="tablist">
											<NavItem>
												<NavLink id="info-tab" className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggleTab('1'); }} >Detalhes</NavLink>
											</NavItem>
										</Nav>
										<Formik initialValues={this.getInitialValues()} validate={(values) => contractPercentageEdit(values)} onSubmit={this.onSubmit}>
											{({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
												<form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>
													<div style={{ background: '#F9F9F9', width: '100%', height: '100%', borderRadius: '5px', padding: 24, flex: 'auto' }}>
														<Label className="col-form-label mb-2" style={{ color: '#8590A5', fontSize: '16px' }}>Editar porcentagem do plano</Label>
														<p>O plano escolhido para esse contrato é o {this.state.data?.entity?.plan?.friendly_name}, selecione uma porcentagem entre {this.state.min_percentage}% e {this.state.max_percentage}%</p>

														<FormGroup row>
															<Col md={8} className="container-center-input">
																<TextField name="percentage" type="text" variant="outlined" value={values.percentage} onBlur={handleBlur} onChange={handleChange}
																	helperText={touched.percentage && errors.percentage} error={Boolean(touched.percentage && errors.percentage)}
																	InputProps={{ endAdornment: <InputAdornment position='end'>%</InputAdornment> }} />
															</Col>
														</FormGroup>

														<Button color="success" type="submit" className="waves-effect waves-light">
															Salvar <i className="fas fa-check align-middle ml-2"></i>
														</Button>
													</div>
												</form>
											)}
										</Formik>

									</CardBody>
									<CardBody className="row">
										<Link to='/contracts/index' className="btn btn-secondary btn-bold ml-2"><i className="fa fa-arrow-left mr-2"></i>Voltar</Link>
									</CardBody>
								</Card>
							}
						</Container>
					</div>
				</BetterCircularProgress>
			</React.Fragment >
		)
	}
}

export default ContractsEdit;