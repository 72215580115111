import React, { Component, createRef } from 'react';
import { Container, Card, Row, Col, Button, ButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, Label, FormGroup, Input, Nav, NavItem, NavLink } from "reactstrap";
import Breadcrumbs from '../../components/Breadcrumb';
import { getAllByParams as GetPropositions, getTotalNegociacoes } from '../../crud/propositions.crud';
import DataTableAnalise from '../../components/datatables/analises/DataTableAnalise';
import BetterAlerts from '../../components/BetterAlerts';
// import { UserFilterComponent } from './components/user.component';
// import { AnnouncementFilterComponent } from './components/announcement.component';
import { Link } from "react-router-dom";
import { getAllByFilter as GetContracts } from '../../crud/contracts.crud';
import { getByFilter as getUserByFilter } from "../../crud/users.crud";

import { getTotalImoveis } from '../../crud/announcements.crud';
import DataTableAnnouncements from '../../components/datatables/announcements/DataTableAnnouncements';
import BetterCircularProgress from '../../components/BetterCircularProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { faChevronRight, faFilter, faHouseCircleCheck, faMagnifyingGlass, faMessagePlus, faRotateRight, faTimes, faUserPlus } from '@fortawesome/pro-regular-svg-icons';
import { getTotalAnalises, laudosQuery } from '../../crud/credit-consut.crud';
import { getSelectFilterAnalise } from '../negotiation/helpers';
import { Checkbox } from '@material-ui/core';

class AnaliseIndex extends Component {
	dataTableRef = createRef();

	state = {
		filters: {
			created_after: undefined,
			created_before: undefined,
			searchAll: '',
			searchAllObject: {}
		},
		is_filter_open: false,
		errors: [],
		success: [],
		activeTab: '1',
		filtersCount: 0,
		newFilters: [],
		indicadores: {},
		totalAtual: 0,
		list_users: [],
		termo_user: '',
		apenasRecente: false,
	};

	componentDidMount = () => {
		let filters = this.state.filters;
		let flags = this.state.flags;

		this.setState({ filters, flags });

		if (this.props.location.state) {
			if (this.props.location.state.success) {
				this.setState({ success: this.props.location.state.success });
			}
		}

		getTotalAnalises().catch(err => {
			alert('Ocorreu um erro ao carregar o número de imóveis, entre em contato com o suporte.');
		}).then(async res => {
			if (res?.status === 200) {
				if (res.data) {
					this.dataTableRef?.current?.fetchData();

					this.setState({ indicadores: res.data });
				}
			}
		});
	};

	saveFilters = () => {
		localStorage.setItem('analiseFilters', JSON.stringify(this.state.filters));
		localStorage.setItem('analiseFlags', JSON.stringify(this.state.flags));
	};

	fetchByFilter = async (data) => {
		let params = { ...data };
		let ids = [];

		if (this.state.filters.signup_after && this.state.filters.signup_after?.trim() !== '') {
			params.created_at_gte = this.state.filters.signup_after;
		}

		if (this.state.filters.signup_before && this.state.filters.signup_before?.trim() !== '') {
			params.created_at_lte = this.state.filters.signup_before;
		}

		if (this.state.filters.analysis_status?.trim() != '') params.analysis_status = this.state.filters.analysis_status;
		if (['ok', 'faltante'].includes(this.state?.filters?.renda)) {
			if (this.state.filters.renda == 'ok') {
				params = { ...params, fc_laudo: { parecer: { locacao: { parecer_inquilinos: { groupedTimesRentalResult_gte: 1 } } } } }
			} else {
				params = { ...params, fc_laudo: { parecer: { locacao: { parecer_inquilinos: { groupedTimesRentalResult_lte: 1 } } } } }
			}
		}

		if (this.state.filters.searchAll?.trim() != '') {
			// const { data } = await getUserByFilter({
			// 	$skip: 0,
			// 	$sort: { name: 1, email: 1 },
			// 	$select: 'id',
			// 	name_email_or: this.state.filters.searchAll,
			// });

			// if (data.total > 0) {
			// 	for (const usuario of data.data) {
			// 		ids.push(usuario.id);
			// 	}

			// 	params.searchAll = {
			// 		search: this.state.filters.searchAll,
			// 		ids: ids
			// 	}

			// } else {

			// }

			params.searchAll = {
				search: this.state.filters.searchAll,
			}
		}

		if (!params.analysis_status) {
			if (this.state.activeTab === '1') {
				// aguardando
				params.analysis_status = ['AWAITING', 'NOT_CONSULTED', 'EXAUSTED_ANALYSIS'];
			}

			if (this.state.activeTab === '2') {
				// aprovado
				params.analysis_status = ['APPROVED', 'APPROVED_SYSTEM', 'APPROVED_HUMANIZED', 'PARECER_NIVEL_1', 'PARECER_NIVEL_2', 'PARECER_NIVEL_3'];
			}

			if (this.state.activeTab === '3') {
				// reprovado
				params.analysis_status = ['REFUSED', 'FRAUD', 'PARECER_SUSPEITA_OBITO', 'PARECER_NAO_APURADO', 'PARECER_NIVEL_4', 'PARECER_NIVEL_5', 'ALERTA_FRAUDE', 'ALERTA_FRAUDE_CRIMINAL', 'ALERTA_ACAO_CRIMINAL', 'ALERTA_ACAO_CIVIL'];
			}
		}

		if (this.state.apenasRecente) params.recentes = true;

		return laudosQuery(params);
	};

	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({ activeTab: tab }, () => {
				this.setState({ totalAtual: 0 });
				this.dataTableRef?.current?.fetchData();
			});
		}
	};

	FiltersModule = ({ filtroModular, index }) => {
		const getFilterChoised = (filter) => {
			let component = <></>

			if (filter == 'data_cadastro') {
				component = (
					<>
						<div className='d-flex'>
							<Label htmlFor="signup_after" className="col-form-label">De</Label>
							<Input className="form-control" type="date" id="signup_after" value={this.state.filters.signup_after} style={{ width: 200, margin: '0 16px' }}
								onChange={(e) => this.setState({ filters: { ...this.state.filters, signup_after: e.target.value } }, () => this.saveFilters())} />
						</div>

						<div className='d-flex'>
							<Label htmlFor="signup_before" className="col-form-label">Até</Label>
							<Input className="form-control" type="date" id="signup_before" value={this.state.filters.signup_before} style={{ width: 200, margin: '0 16px' }}
								onChange={(e) => this.setState({ filters: { ...this.state.filters, signup_before: e.target.value } }, () => this.saveFilters())} />
						</div>
					</>
				)
			} else if (filter == 'analysis_status') {
				component = (
					<div className='d-flex'>
						<select onChange={(e) => this.setState({ filters: { ...this.state.filters, analysis_status: e.target.value } }, () => this.saveFilters())} style={{ width: 400, margin: '0 16px' }} className="custom-select">
							<option value="" hidden>Selecionar</option>
							{getSelectFilterAnalise(this.state.activeTab).map(({ label, value }, index) => (
								<option key={index} value={value} >{label}</option>
							))}
						</select>
					</div>
				)
			} else if (filter == 'renda') {
				component = (
					<div className='d-flex'>
						<select onChange={(e) => this.setState({ filters: { ...this.state.filters, renda: e.target.value } }, () => this.saveFilters())} style={{ width: 400, margin: '0 16px' }} className="custom-select">
							<option value="" hidden>Selecionar</option>
							<option value={'ok'}>ok</option>
							<option value={'faltante'}>faltante</option>
						</select>
					</div>
				)
			} else {
				component = <></>
			}

			return component;
		}

		const onSetFilter = (filtro) => {
			let stateFilter = this.state.newFilters.slice();
			stateFilter[index] = filtro;

			this.setState({ newFilters: stateFilter })
		}

		const getSelectOptions = (nameFiltro) => {
			const stateFilter = this.state.newFilters;

			if (stateFilter.includes(nameFiltro)) {
				return 'd-none'
			} else {
				return ''
			}
		}

		const removeFilter = (filterUnit, filterName) => {
			let stateFilter = this.state.newFilters;

			stateFilter.splice(filterUnit, 1);

			switch (filterName) {
				case 'data_cadastro':
					this.setState({
						flags: { ...this.state.flags, show_signup_after: false, show_signup_before: false },
						filters: { ...this.state.filters, signup_after: '', signup_before: '' }
					}, () => this.fetchByFilter())
					break;

				case 'analysis_status':
					this.setState({
						flags: { ...this.state.flags, analysis_status: false },
						filters: { ...this.state.filters, analysis_status: '' }
					}, () => this.fetchByFilter())
					break;

				case 'renda':
					this.setState({
						flags: { ...this.state.flags, renda: false },
						filters: { ...this.state.filters, renda: '' }
					}, () => this.fetchByFilter())
					break;
				default:
					break;
			}

			this.setState({ newFilters: stateFilter })
		}

		return (
			<Col md={12} className="d-flex m-0 p-0 filtroUnit">
				<div className='d-flex'>
					<select onChange={({ target }) => onSetFilter(target.value)} value={this.state.newFilters[index]} className="custom-select" style={{ width: 300 }}>
						<option value="">Selecione um filtro</option>
						<option className={getSelectOptions('data_cadastro')} value="data_cadastro">Requisitado em</option>
						<option className={getSelectOptions('analysis_status')} value="analysis_status">Status</option>
						<option className={getSelectOptions('renda')} value="renda">Status de renda</option>
					</select>

					{this.state.newFilters[index] != '' && (
						<div className='divisionArrow'>
							<FontAwesomeIcon icon={faChevronRight} />
						</div>
					)}

					{getFilterChoised(this.state.newFilters[index])}
				</div>

				<div onClick={() => removeFilter(index, this.state.newFilters[index])}>
					<FontAwesomeIcon icon={faTimes} />
				</div>
			</Col>
		)
	}

	handleSetIndicadorAtual = (indicador) => {
		this.setState({ totalAtual: indicador })
	}

	render() {
		return (
			<>
				<React.Fragment>
					<div className="page-content container-page userIndex">
						<Container fluid>
							<div className='header'>
								<h1>Análises de crédito</h1>

								<Breadcrumbs breadcrumbItems={[{ link: '/negotiation/index', title: 'Negociações' }, { link: '/analise/index', title: 'Análises de crédito' }]} />
							</div>

							<BetterAlerts errors={this.state.errors} success={this.state.success} />

							<Col md={12} className="box-filtros">
								<Col md={12} className="d-flex m-0 p-0">
									<div className='generalSearch'>
										<Input className="inputCustomNew" type="text" value={this.state.filters.searchAll} placeholder="Busque por Endereço, Nome, Email, Telefone ID ou documento"
											onChange={(e) => this.setState({ filters: { ...this.state.filters, searchAll: e.target.value } }, () => this.saveFilters())} />

										<FontAwesomeIcon icon={faMagnifyingGlass} />
									</div>

									<button onClick={() => this.setState({ newFilters: [...this.state.newFilters, ''] })} className="btnSecundary" style={{ width: 'max-content', margin: '0px 8px', padding: '8px 12px' }}>
										FILTRO
										<FontAwesomeIcon icon={faFilter} />
									</button>
								</Col>

								{this.state.newFilters.map((filtroModular, index) => {
									return <this.FiltersModule filtroModular={filtroModular} key={index} index={index} />
								})}

								<Col md={12} className="d-flex m-0 p-0 filtroUnit">
									<FormGroup className="w-100 pb-0 mb-0" style={{ display: 'flex', alignItems: 'baseline', paddingLeft: 16 }}>
										<Label className="pl-2 input-radio-group pb-0 mb-0">
											<Input type="radio" name={'apenasRecente'} id={`apenasRecente`} value={false} onClick={() => this.setState({ apenasRecente: !this.state.apenasRecente }, () => this.dataTableRef?.current?.fetchData())} color="primary" checked={this.state.apenasRecente} />
											<Label className='radioLabel' style={{ padding: "3px 3px 0 3px", marginBottom: 0 }} for={`apenasRecente`}>
												Mostrar apenas a versão mais recente da análise de crédito
											</Label>
										</Label>
									</FormGroup>
								</Col>
							</Col>

							<Row>
								<Col lg={12}>
									<Card className='card-general'>
										<Nav tabs className="nav-tabs-custom" role="tablist" style={{ marginBottom: '32px' }}>
											<NavItem>
												<NavLink id="info-tab" className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggleTab('1'); }}>
													<div className='tabName'>Aguardando <div className='indicadorUsuarios'>{this.state.activeTab === '1' ? this.state?.totalAtual : this.state?.indicadores?.aguardando}</div></div>
												</NavLink>
											</NavItem>

											<NavItem>
												<NavLink id="announcements-tab" className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggleTab('2'); }}>
													<div className='tabName'>Aprovados <div className='indicadorUsuarios'>{this.state.activeTab === '2' ? this.state?.totalAtual : this.state?.indicadores?.aprovados}</div></div>
												</NavLink>
											</NavItem>

											<NavItem>
												<NavLink id="negotiations-tab" className={classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.toggleTab('3'); }}>
													<div className='tabName'>Reprovados <div className='indicadorUsuarios'>{this.state.activeTab === '3' ? this.state?.totalAtual : this.state?.indicadores?.reprovados}</div></div>
												</NavLink>
											</NavItem>
										</Nav>

										<div className='datatable-box table-responsive'>
											<BetterCircularProgress loading={this.state.loading}>
												<DataTableAnalise ref={this.dataTableRef} filters={this.state.filters} noDelete fetchEvent={this.fetchByFilter} crudButtons={true}
													crudUrl={"/negotiation/laudo"} idRow={`proposition_id`} activeTab={this.state.activeTab} handleSetIndicadorAtual={this.handleSetIndicadorAtual} />
											</BetterCircularProgress>
										</div>
									</Card>
								</Col>
							</Row>
						</Container>
					</div>
				</React.Fragment>
			</>
		);
	}
}

export default AnaliseIndex;