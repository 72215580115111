import axios from "axios";
import { Constants } from '../helpers/Constants';
import { parseQuery } from "../helpers/Utils";

export function getVistoriasByContract(id) {
	return axios.get(`${Constants.baseUrl}/property-inspections/contract/${id}`);
}

export function getVistoriasItems(id, page, params) {
	return axios.get(`${Constants.baseUrl}/property-inspections/${id}/items?page=${page}&pending=false`, { params: parseQuery(params) });
}

export function getVistoria(id) {
	return axios.get(`${Constants.baseUrl}/property-inspections/${id}`);
}

export function getVistoriaFile(vistoriaId) {
	const options = {
		method: 'get',
		baseURL: `${Constants.baseUrl}/property-inspections/file/${vistoriaId}`,
	};

	const interceptor = {
		response: (request) => {
			return request;
		},
		error: (error) => {
			if (error.response.status === 400) {
				return error.response.request.responseURL;
			}
			throw error;
		}
	}

	const id = axios.interceptors.response.use(
		interceptor.request,
		interceptor.error
	);

	console.log(id);

	return axios.request(options).finally(() => {
		axios.interceptors.response.eject(id);
	});
}

export function updateVistoria(vistoriaId, data) {
	return axios.put(`${Constants.baseUrl}/property-inspections/${vistoriaId}`, data);
}