import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatBRL } from "../../helpers/Utils";
import { Link } from "react-router-dom";
import moment from "moment";
import { downloadRemFile, } from "../../crud/billings.crud";
import smile from '../../assets/images/icons-custom/face-smile.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { faArrowDownToLine, faUserMagnifyingGlass } from "@fortawesome/pro-solid-svg-icons";

export default class DataTableGratifications extends Component {
	constructor() {
		super();

		this.state = {
			property: {},
			data: null,
			rows: 10,
			totalRecords: 0,
			first: 0,
			last: 0,
			loading: false,
			sortField: 'id',
			sortOrder: 1,
		};

		this.onPage = this.onPage.bind(this);
	}

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.filters !== this.props.filters) {
			this.fetchData();
		}
	}

	refreshTable() {
		this.onPage(this.state);
	}

	onPage = async (event) => {
		this.setState({
			loading: true,

		});
		if (event.data) {
			event.data = null;
		}

		let shipmentoDataTable = {
			rows: event.rows,
			totalRecords: this.state.totalRecords,
			data: this.data,
			first: event.first,
			last: event.rows + event.first,
			loading: this.state.loading,
			sortField: this.state.sortField,
			sortOrder: this.state.sortOrder,
		};

		localStorage.setItem("shipmentoDataTable", JSON.stringify(shipmentoDataTable));

		this.setState({
			...this.state,
			rows: event.rows,
		});
		this.fetchData(event.rows, event.first);
	};

	fetchData = async (limit, skip) => {
		this.setState({ loading: true });

		let options = {
			$limit: limit ? limit : this.state.rows,
			$skip: skip != null ? skip : this.state.first,
			$sort: { [this.state.sortField]: this.state.sortOrder },
		};

		if (this.props.filters.status) options.status = this.props.filters.status;
		if (this.props.filters.created_at_gte) options.created_at_gte = this.props.filters.created_at_gte;
		if (this.props.filters.created_at_lte) options.created_at_lte = moment(this.props.filters.created_at_lte).add(1, 'days').format('YYYY-MM-DD');

		if (this.props.filters?.searchAll) {
			options.searchAll = this.props.filters.searchAll;
		}

		return this.props.fetchEvent(options).then(async (res) => {
			// this.props.updateCounter(res?.data?.total);

			if (res.data.data.length == 0) {
				this.setState({
					...this.state,
					data: [],
					totalRecords: res.data.total,
					first: skip,
					last: skip + res.data.limit,
					loading: false
				});
			}

			this.props.setState({ totalShipment: res.data.total });

			this.setState({
				...this.state,
				data: res.data.data,
				totalRecords: res.data.total,
				first: skip,
				last: skip + res.data.limit,
				loading: false
			});
		}).finally(() => {
			this.setState({ loading: false });
		});
	};

	sortTable = async (e) => {
		this.setState({ sortField: e.sortField, sortOrder: e.sortOrder, loading: true }, () => {
			let shipmentoDataTable = {
				rows: this.state.rows,
				totalRecords: this.state.totalRecords,
				first: this.state.first,
				last: this.state.last,
				loading: this.state.loading,
				sortField: this.state.sortField,
				sortOrder: this.state.sortOrder,
				data: this.state.data
			};
			localStorage.setItem("shipmentoDataTable", JSON.stringify(shipmentoDataTable));

			this.fetchData();
		});
	};

	getValue = (obj) => {
		return (
			<div style={{ textAlign: "center", width: '105px' }}>
				<p>{formatBRL(obj || 0)}</p>
			</div>
		);
	};

	getStatus = (obj) => {
		if (obj === 'ACTIVE') {
			return (
				<div className="d-flex flex-row" style={{ width: '98px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center', backgroundColor: '#1CBB8C' }}>
					<p className="col-form-label text-white">Ativo</p>
				</div>
			)
		} else if (obj === 'REMOVED') {
			return (
				<div className="bg-secondary d-flex flex-row" style={{ width: '98px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center' }}>
					<p className="col-form-label text-white">Removido</p>
				</div>
			)
		} else {
			return (
				<div className="bg-secondary d-flex flex-row" style={{ width: '98px', fontSize: '11px', lineHeight: '16px', height: '24px', alignItems: 'center' }}>
					<p className="col-form-label text-white">Não encontrado</p>
				</div>
			)
		}
	};

	getDateFormatted = (date) => {
		return (
			<p>
				{date ? moment(date).format('DD/MM/YYYY') : '--'}
				<br />
				{date ? moment(date).format('HH:mm:ss') : '--'}
			</p>
		);
	};

	getResponsable = (obj) => {
		return (
			<div>
				<FontAwesomeIcon icon={faUserMagnifyingGlass} style={{ color: '#4AA3FF', marginRight: 8 }} />
				<Link to={`/users/show/${obj?.user_id}`} style={{ color: "#4AA3FF" }}>{obj?.user?.name ?? 'Nome não encontrado'}</Link>
			</div>
		)
	}

	export() {
		this.dt.exportCSV();
	}

	handleDownloadRem = async (obj) => {
		try {
			const response = await downloadRemFile(obj.id);

			const currentDate = new Date();
			const day = String(currentDate.getDate()).padStart(2, '0');
			const month = String(currentDate.getMonth() + 1).padStart(2, '0');
			const suffix = obj.batch.toString().padStart(2, '0');
			const fileName = `CB${day}${month}${suffix}.REM`;

			const blob = new Blob([response.data], { type: 'text/plain' });
			const blobURL = URL.createObjectURL(blob);

			const link = document.createElement('a');
			link.href = blobURL;
			link.download = fileName;
			document.body.appendChild(link);
			link.click();

			document.body.removeChild(link);
			URL.revokeObjectURL(blobURL);
		} catch (error) {
			console.error('Falha ao baixar o boleto', error);
		} finally {
			this.setState({ loadingBaixarBoleto: false });
		}
	}

	getOptions = (obj) => {
		return (
			<div className="d-flex justify-content-center p-2 align-items-center">
				<div>
					<Link to={`/pagamento/gratificacao/show/${obj.id}`} style={{ width: 34 }}>
						<FontAwesomeIcon icon={faChevronRight} style={{ color: '#5664D2', fontSize: 14 }} />
					</Link>
				</div>
			</div>
		)
	}

	emptyMessageReturn() {
		return (
			<div className="boxImage">
				<img src={smile} alt="smile" width={48} height={48} />

				<p className="subtitleNoItems">Nenhum registro encontrado</p>
			</div>
		)
	}

	render() {
		return (
			<div style={{ position: 'relative' }}>
				<DataTable ref={(el) => this.dt = el} value={this.state.data} paginator={true} rows={this.state.rows} rowsPerPageOptions={[10, 15, 30]} first={this.state.first} last={this.state.last}
					totalRecords={this.state.totalRecords} lazy={true} onPage={this.onPage} loading={this.state.loading} responsive={true} loadingIcon={`fas fa-sync fa-spin`} onSort={(e) => this.sortTable(e)}
					paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" emptyMessage={this.emptyMessageReturn}
					currentPageReportTemplate="" sortField={this.state.sortField} sortOrder={this.state.sortOrder} pageLinkSize={3} id="custom-table-2">

					<Column sortable field="status" body={(obj) => this.getStatus(obj?.status)} header="Status" style={{ width: '124px', padding: '22px 16px 22px 16px' }} />

					<Column sortable field="user_id" body={(obj) => this.getResponsable(obj)} style={{ width: '410px', padding: '22px 16px 22px 16px' }} header="Usuário" />

					<Column sortable field="role" body={(obj) => obj.role} header="Cargo" style={{ width: '176px', padding: '22px 16px 22px 16px' }} />

					<Column sortable field="created_at" body={(obj) => this.getDateFormatted(obj?.created_at)} style={{ width: '128px', padding: '22px 16px 22px 16px', textAlign: 'end' }} header="Adição" />

					<Column sortable field="updated_at" body={(obj) => this.getDateFormatted(obj?.updated_at)} style={{ width: '128px', padding: '22px 16px 22px 16px', textAlign: 'end' }} header="Atualizado em" />

					<Column header="" body={(obj) => this.getOptions(obj)} style={{ width: '34px', padding: 0 }} />
				</DataTable>

				<div className="paginator-new-items">
					<p>Registros por página</p>
					{/* <Link className="custom-see-all-table" to={`/pagamento/cobrancas/index`} >
						Ver todos
					</Link> */}
				</div>
			</div>
		);
	}
}
